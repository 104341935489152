class PublicationInfoView {
  constructor() {
    this.state = [];
    this.map = {};
  }
  toJSON() {
    return JSON.stringify({
      state: this.state,
      map: this.map
    });
  }

  getById(id) {
    const index = this.map[id];
    return this.state[index];
  }

  update(id, publication) {
    const index = this.map[id];
    if (index) {
      this.state[index] = publication;
    } else {
      this.map[id] = this.state.length;
      this.state.push(publication);
    }
  }
}

export default PublicationInfoView;
