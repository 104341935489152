<template>
  <div class="no-data">
    <div class="no-data__image">
      <img :src="contentImage" />
    </div>
    <p class="no-data__header">{{ contentTitle }}</p>
    <p class="no-data__message">{{ contentMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoDataItem',
  props: {
    contentImage: {
      type: String,
      default: ''
    },
    contentTitle: {
      type: String,
      default: ''
    },
    contentMessage: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="less" src="./NoDataItem.less" scoped></style>
