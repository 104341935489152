<template>
  <ContentListBlock
    v-if="bookView.toc.length"
    :content-title-label="$t('Content')"
    :contents="bookView.toc"
    :book-id="bookView.id"
    :is-compilation-open="isCompilationOpen"
    :selected-chapter-index="selectedChapterIndex"
    @contentListBlockEvent="onChapterClick"
  />
  <NoDataItem
    v-else
    :content-image="contentImage"
    :content-title="contentTitle"
    :content-message="contentMessage"
  ></NoDataItem>
</template>

<script>
import ContentListBlock from '@/components/views/Extras/ContentListBlock/ContentListBlock';
import NoDataItem from './../NoDataItem/NoDataItem';
import PopupEventEnum from '@/enums/PopupEventEnum';

import { mapGetters } from 'vuex';
import images from '@/assets/images';

import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import findLastIndex from 'lodash/findLastIndex';

export default {
  name: 'TableOfContentsTab',
  components: { ContentListBlock, NoDataItem },
  data() {
    return {
      selectedChapterIndex: 0,
      noDataContentImage: images.picBookmark
    };
  },
  computed: {
    ...mapGetters({
      getReadingArea: 'ProgressStore/getReadingArea',
      isContentReady: 'PublicationStore/isContentReady'
    }),
    bookView() {
      return this.$store.getters['PublicationStore/getBookInfoView']();
    },
    readingArea() {
      return this.getReadingArea;
    },
    isCompilationOpen() {
      return this.$store.getters['PublicationStore/isCompilationOpen'];
    },
    wide() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].wide;
    },
    contentImage() {
      return this.isCompilationOpen ? images.picTocEmpty : images.picBookmark;
    },
    contentTitle() {
      return this.isCompilationOpen
        ? this.$t('TableOfContentsPopup.compilation.contentMessage')
        : this.$t('TableOfContentsPopup.book.contentMessage');
    },
    contentMessage() {
      return this.isCompilationOpen
        ? ''
        : this.$t('TableOfContentsTab.noData.content.message');
    }
  },
  watch: {
    readingArea(newReadingArea) {
      if (!newReadingArea) {
        return;
      }
      this.setSelectedChapterIndex(newReadingArea);
    }
  },
  async mounted() {
    this.setSelectedChapterIndex(this.readingArea);
    this.scrollToSelected();
  },
  methods: {
    scrollToSelected() {
      this.$nextTick(function() {
        const selectedElement = document.querySelector('.selected');
        if (selectedElement) {
          selectedElement.scrollIntoView({ block: 'center' });
        }
      });
    },
    setSelectedChapterIndex(readingArea) {
      if (!this.readingArea) {
        this.selectedChapterIndex = 0;
        return;
      }
      const topParaId = readingArea.startLocator.paragraphId;
      const chapterIndex = findLastIndex(this.bookView.toc, content => {
        return (
          MarkerUtils.getParaNum(content.id) <=
          MarkerUtils.getParaNum(topParaId)
        );
      });
      this.selectedChapterIndex = chapterIndex > -1 ? chapterIndex : 0;
    },
    onChapterClick({ index }) {
      if (!this.isContentReady) {
        return;
      }
      if (this.limitedAccess) {
        this.$store.dispatch('UserStore/openLimitedAccessPopup');
        return;
      }
      this.selectedChapterIndex = index;
      this.$store.commit(
        'OpenParameterStore/changeParaId',
        this.bookView.toc[index].id
      );

      if (!this.wide) {
        this.$emit('closeHandler', { type: PopupEventEnum.CLOSE });
      }
    }
  }
};
</script>

<style></style>
