'use strict';
import uaParser from 'ua-parser-js';

const mobilePlatforms = {
  ANDROID: 'Android',
  IOS: 'iOS'
};
const OsEnum = {
  Windows: {
    and: [
      { or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] },
      { not: /\bWindows Phone\b/ }
    ]
  },
  iOS: { or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] },
  OSX: /\bMac OS\b/,
  Android: /\bAndroid\b/,
  Linux: /\bLinux\b/,
  Other: /./
};
const MOBILE_MAX_TOUCH_POINTS_BORDER = 2;
const navigatorMock = { userAgent: 'nuxt' };
const windowMock = {
  navigator: { userAgent: 'nuxt' },
  screen: { width: 1280, height: 720 }
};
let windowObj = process.browser ? window : windowMock;
let navigator = process.browser ? windowObj.navigator : navigatorMock;
let context = {};
/* --- api --- */
context.userAgentParams = uaParser(windowObj.navigator.userAgent);
context.userAgentInfo = windowObj.navigator.userAgent;
context.native = _isNative();
context.isDevice = _isDevice();
context.platformType = _platformDetector();
context.os = _osDetector();
context.runtimeEngine = _runtimeEngineEnum();
context.screenWidth = windowObj.screen.width;
context.screenHeight = windowObj.screen.height;
context.devicePixelRation = _detectDevicePixelRatio();
context.origin = '';
context.isElectron = _isElectron();
context.isSafari = _isSafari(context.userAgentParams);
context.isEdge = _isEdge();
context.isChrome = _isChrome();
context.isAndroid = _isAndroid();
context.isAndroidBrowser = _isAndroidBrowser();
context.isIos = _isIos();
context.isIosBrowser = _isIosBrowser();
context.isIos12_4 = _isIos12_4();
context.isIos13 = _isIos13();
context.isIos15_4 = _isIos15_4();
context.isSafariVersion15_4 = _isSafariVersion15_4();
context.iosVersion = _iosVersion();
context.isIpad = _detectIsIpad();
context.iFrameDetection = process.client ? window !== window.parent : false;

export default context;

/* --- impl --- */

function test(string, regex) {
  if (regex instanceof RegExp) {
    return regex.test(string);
  } else if (regex && Array.isArray(regex.and)) {
    return regex.and.every(_subTest);
  } else if (regex && Array.isArray(regex.or)) {
    return regex.or.some(_subTest);
  } else if (regex && regex.not) {
    return !test(string, regex.not);
  } else if (_isFunction(regex)) {
    return regex(windowObj);
  } else {
    return false;
  }

  function _subTest(item) {
    return test(string, item);
  }
}

function _isFunction(fn) {
  return fn && {}.toString.call(fn) === '[object Function]';
}

function _detector(_enum) {
  return Object.keys(_enum).reduce(function(memo, key) {
    return !memo && test(windowObj.navigator.userAgent, _enum[key])
      ? key
      : memo;
  }, '');
}

function _detectDevicePixelRatio() {
  // To account for zoom, change to use deviceXDPI instead of systemXDPI
  if (
    windowObj.screen.systemXDPI &&
    windowObj.screen.logicalXDPI &&
    windowObj.screen.systemXDPI > windowObj.screen.logicalXDPI
  ) {
    // Only allow for values > 1
    return windowObj.screen.systemXDPI / windowObj.screen.logicalXDPI;
  }
  return windowObj.devicePixelRatio || 1;
}

function _osDetector() {
  // for cordova application
  if (_isDevice()) {
    return _isAndroid() ? mobilePlatforms.ANDROID : mobilePlatforms.IOS;
  }

  const detectedOS = _detector(OsEnum);
  const isIpad = detectedOS === 'OSX' && _isTouchDevice();
  if (isIpad) {
    return 'iOS';
  }
  return detectedOS;
}

function _detectIsIpad() {
  const detectedOS = _detector(OsEnum);
  const isIpad = detectedOS === 'OSX' && !!_isTouchDevice();
  return isIpad;
}

function _isTouchDevice() {
  return (
    windowObj.navigator.maxTouchPoints &&
    windowObj.navigator.maxTouchPoints > MOBILE_MAX_TOUCH_POINTS_BORDER
  );
}

function _platformDetector() {
  var MobileDeviceEnum = {
    Android: /\bAndroid\b/,
    Ipad: /\biPad\b/,
    Iphone: /\biPhone\b/,
    Ipod: /\biPod\b/,
    BlackBerry: /\bblackberry\b/,
    FireFoxOs: { and: [/\bFirefox\b/, /\bMobile\b/] },
    WinPhone: /\bIEMobile\b/,
    PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
    PSVita: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/
  };

  var PlatformTypeEnum = {
    Mobile: { or: Object.values(MobileDeviceEnum) },
    Desktop: /./
  };

  return _detector(PlatformTypeEnum);
}

function _runtimeEngineEnum() {
  var RuntimeEngineEnum = {
    Web() {
      return !windowObj.cordova;
    },
    iOS: { or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] },
    Android: /\bAndroid\b/,
    WinPhone: /\bIEMobile\b/,
    Windows: {
      and: [
        { or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] },
        { not: /\bWindows Phone\b/ }
      ]
    },
    OSX: /\bMac OS\b/,
    Linux: /\bLinux\b/,
    Other: /./
  };

  return _detector(RuntimeEngineEnum);
}

// function _uuidGenerator() {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, _replacer);

//   function _replacer(c) {
//     /*jshint bitwise: false*/
//     /*jshint eqeqeq:  false*/
//     var r = (Math.random() * 16) | 0,
//       v = c == 'x' ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   }
// }

function _isElectron() {
  return test(context.userAgentInfo, /[Ee]lectron/);
}

function _isNative() {
  return !!(windowObj.cordova || _isElectron());
}

function _isDevice() {
  return _isAndroid() || _isIos() || false;
}

function _isSafari(userAgentParams) {
  const browserName = userAgentParams?.browser?.name;
  return browserName === 'Safari' || browserName === 'Mobile Safari';
}

function _isEdge() {
  return context.userAgentInfo.toLowerCase().indexOf('edge/') !== -1;
}

function _isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

function _isAndroid() {
  return (
    windowObj.cordova && windowObj.device.platform === mobilePlatforms.ANDROID
  );
}

function _isAndroidBrowser() {
  return !windowObj.cordova && context.os === mobilePlatforms.ANDROID;
}

function _isIos() {
  return windowObj.cordova && windowObj.device.platform === mobilePlatforms.IOS;
}

function _isIosBrowser() {
  return !windowObj.cordova && context.os === mobilePlatforms.IOS;
}

function _isIos12_4() {
  const ios12_4 = 'OS 12_4';
  return _isIos() && windowObj.navigator.userAgent.includes(ios12_4);
}

function _isIos13() {
  const ios13 = 'OS 13_';
  return _isIos() && window.navigator.userAgent.includes(ios13);
}

function _isIos15_4() {
  const ios15_4 = 'OS 15_4';
  return _isIos() && window.navigator.userAgent.includes(ios15_4);
}

function _isSafariVersion15_4() {
  const safariVersion15_4 = 'Version/15.4';
  return _isSafari() && window.navigator.userAgent.includes(safariVersion15_4);
}

function _iosVersion() {
  if (!_isIos()) {
    return 0;
  }
  const iosVersionRE = new RegExp(/OS (\d*)/);
  return parseInt(navigator.userAgent.match(iosVersionRE)[1]);
}

// function isSDPathAvailable() {
//    return windowObj.cordova && windowObj.cordova.getSDPath
// }
