import Http from '@/services/utils/Http';
import PromiseUtil from '@/services/utils/PromiseUtil';

import CustomErrorEnum from '@/enums/CustomErrorEnum';
let initDefer = PromiseUtil.createDeferred();

const requestContext = {
  restConfig: {},
  serverUrl: '',
  runid: ''
};

function init(Context) {
  initDefer.resolve();
  Object.assign(requestContext, {
    restConfig: Context.parameters.EpubConfig,
    serverUrl: Context.serverUrl
  });
}

function setRunId(runId) {
  requestContext.runid = runId;
}

function getRunId() {
  return requestContext.runid;
}

async function createLink(requestParams) {
  return Http.getUri(requestParams);
}

async function createRequestParams(method, controller, action, data, params) {
  await initDefer.promise;
  if (typeof action === 'object') {
    data = action;
    action = '';
  }
  const url = getUrlString(controller, action);
  const timeout = process.server ? 5 * 60 * 1000 : params?.timeout || 0;
  const conf = {
    timeout,
    method: method,
    url: url
  };
  if (data) {
    if (['post', 'put'].indexOf(method) > -1) {
      conf.data = data;
      if (params && params.hasOwnProperty('fileType')) {
        // conf.transformRequest = angular.identity;
        conf.headers = { 'Content-Type': undefined };
        conf.params = params;
      }
    } else {
      conf.params = data;
    }
    if (params && params.hasOwnProperty('swBlockUserInput')) {
      conf.swBlockUserInput = params.swBlockUserInput;
    }
  }
  return conf;
}

function getUrlString(controller, action) {
  let url = requestContext.serverUrl;
  const restConfig = requestContext.restConfig;
  const runid = requestContext.runid;
  if (!restConfig.hasOwnProperty(controller + 'Path')) {
    const newError = new Error(
      `Incorrect controller passed: ${controller} action: ${action}`
    );
    newError.type = CustomErrorEnum.UNSUPPORTED_REST_REQUEST;
    throw newError;
  }
  url += restConfig[controller + 'Path'];
  url += '/' + action;
  if (runid) {
    url += (url.indexOf('?') > -1 ? '&' : '?') + 'RunId=' + runid;
  }
  return url;
}

async function restRequest(method, controller, action, data, params) {
  var conf = await createRequestParams(
    method,
    controller,
    action,
    data,
    params
  );
  const promise = sendReq(conf);
  return promise;
}

function sendReq(conf) {
  return Http.http(conf);
}

// function restSwHttpRequest (method, controller, action, data, params) {
//    var conf = createRequestParams(method, controller, action, data, params);
//    return swHttp.invoke(conf);
// };

// function call(method, controller, action, data, params) {
//    var conf = createRequestParams(method, controller, action, data, params);
//    conf.data = conf.data || conf.params;
//    conf.method = conf.method.toUpperCase();
//    delete conf.params;
//    return swHttp.call(conf);
// };

export default {
  init,
  setRunId,
  getRunId,
  getUrlString,
  restRequest,
  createRequestParams,
  createLink,
  sendReq
};
