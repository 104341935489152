import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';

import { AssetsManagerConstants } from '@shared/enums/AssetsManagerConstants.mjs';
import FileTransport from '@/services/AssetsManager/FileTransport/FileTransport';

import TrackingItem from './TrackingItem';
import ContentTable from './ContentTable';
import { AbstractAssetReader } from './AbstractAssetReader';

class RemoteAssetReader extends AbstractAssetReader {
  constructor(coverExtension, audioExtension) {
    super(coverExtension, audioExtension);
    this.allResources = [AssetResourcesEnum.REMOTE];
  }

  async initBookAssets(bookId) {
    const publicationTrackingItem = TrackingItem.createPublicationTrackingItem(
      bookId
    );
    this._addOrUpdateTrackingItemsMapObj(bookId);
    const mapSourcePath = publicationTrackingItem.createFileMapPath();

    const fileAccess = this._createFileAccess(
      mapSourcePath + '?_=' + new Date().getTime(),
      AssetResourcesEnum.REMOTE
    );
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    const fileMap = await FileTransport.readFile(fileAccess, readOptions);
    const updateFileMap = fileMap;
    const contentsTable = ContentTable.createContentTable(
      {},
      AssetResourcesEnum.REMOTE
    );
    publicationTrackingItem.initFileMap(fileMap, updateFileMap, [
      contentsTable
    ]);
    this._setTrackingItem(bookId, publicationTrackingItem);
    return publicationTrackingItem;
  }

  async readBookMetaFile(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    const meta = await this._readRemoteFullFile(
      bookId,
      AssetsManagerConstants.bookMeta,
      readOptions
    );
    return meta;
  }

  readAudioFile(bookId, fileName) {
    const self = this;
    return Promise.resolve().then(function() {
      const fullFileName = self._createFullFileNameByAsset(
        fileName,
        AssetTypeEnum.AUDIO
      );
      const readOptions = self.readOptionsHelper.getBlobOptions();
      return self._readRemoteFullFile(bookId, fullFileName, readOptions);
    });
  }

  readAlignmentIndex(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return this._readRemoteFullFile(
      bookId,
      AssetsManagerConstants.alignmentMap,
      readOptions
    );
  }

  readerPauseMapFile(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return this._readRemoteFullFile(
      bookId,
      AssetsManagerConstants.pauseMapFileName,
      readOptions
    );
  }

  isAssetDownloaded() {
    return false;
  }

  downloadAsset() {}

  readContentIndexes(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return this._readRemoteFullFile(
      bookId,
      AssetsManagerConstants.contentIndexName,
      readOptions
    );
  }

  bookContentByChunk(bookId, chunkOffset) {
    const readOptions = this.readOptionsHelper.getArrayBufferChunkOptions(
      chunkOffset
    );
    return this._readRemoteChunkFile(
      bookId,
      AssetsManagerConstants.contentFileName,
      readOptions
    );
  }

  bookAlignmentByChunk(bookId, chunkOffset) {
    const readOptions = this.readOptionsHelper.getArrayBufferChunkOptions(
      chunkOffset
    );
    return this._readRemoteChunkFile(
      bookId,
      AssetsManagerConstants.alignmentFileName,
      readOptions
    );
  }

  getFileSourcePath(bookId, fileName, assetType) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        const fullFileName = self._createFullFileNameByAsset(
          fileName,
          assetType
        );
        return self._getFilePath(fullFileName, publicationTrackingItem);
      });
  }

  async _readRemoteFullFile(bookId, fileName, readOptions) {
    const publicationTrackingItem = await this._getTrackingItemById(bookId);
    const filePath = publicationTrackingItem.createAssetFilePath(fileName);
    const fileAccess = this._createFileAccess(
      filePath,
      AssetResourcesEnum.REMOTE
    );
    return FileTransport.readFile(fileAccess, readOptions);
  }

  _readRemoteChunkFile(bookId, fileName, readOptions) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        const contentFilePath = publicationTrackingItem.createAssetFilePath(
          fileName
        );

        const fileAccess = self._createFileAccess(
          contentFilePath,
          AssetResourcesEnum.REMOTE
        );
        return FileTransport.readFile(fileAccess, readOptions);
      });
  }

  _getFilePath(fullFileName, publicationTrackingItem) {
    const filePath = publicationTrackingItem.createAssetFilePath(fullFileName);
    const fileAccess = this._createFileAccess(
      filePath,
      AssetResourcesEnum.REMOTE
    );
    const readOptions = this.readOptionsHelper.getBinaryOptions();
    return FileTransport.createFileSourcePath(fileAccess, readOptions);
  }

  readFile(bookId, fileName) {
    const readOptions = this.readOptionsHelper.getUtf8Options();
    return this._readRemoteFullFile(bookId, fileName, readOptions);
  }
  readFileByChunk(bookId, fileName, chunkOffset) {
    const readOptions = this.readOptionsHelper.getUtf8ChunkOptions(chunkOffset);
    return this._readRemoteChunkFile(bookId, fileName, readOptions);
  }
}

export { RemoteAssetReader };
