<template>
  <div class="table-of-contents-block">
    <div class="info-title" @click="isHidden = !isHidden">
      <span>{{ contentTitleLabel }}</span>
    </div>
    <ol class="toc-body" :class="isHidden ? 'hidden' : ''">
      <li
        v-for="(content, index) in preparedContents"
        :key="index"
        :class="[
          content.indentationClass,
          { selected: isContentSelected(index) }
        ]"
        :dir="content.dir"
        @click="onContentClick(index)"
      >
        <ContentItem
          :item-number="content.itemNumber"
          :text="content.text"
          :is-read-chapter="content.isReadChapter"
          :is-content-selected="isContentSelected(index)"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ContentListBlock.vue');
import ContentItem from '@/components/views/Extras/ContentListBlock/ContentItem/ContentItem';

const INDENTATION_CLASSES = {
  1: '',
  2: 'indentation-second',
  3: 'indentation-third',
  4: 'indentation-fourth'
};

class ContentView {
  constructor(content) {
    this.text = content.text || content.title;
    const indentation = content.indentation;
    this.indentationClass = indentation ? INDENTATION_CLASSES[indentation] : '';
    this.itemNumber = content.itemNumber || '';
    this.dir = 'ltr';
    this.isReadChapter = false;
  }

  setIsReadChapter(isRead) {
    this.isReadChapter = isRead;
    return this;
  }

  setDir(dir) {
    this.dir = dir;
    return this;
  }
}

export default {
  name: 'ContentListBlock',
  components: { ContentItem },
  props: {
    contentTitleLabel: {
      type: String,
      required: true
    },
    contents: {
      type: Array,
      required: true
    },
    bookId: {
      type: String,
      required: true
    },
    selectedChapterIndex: {
      type: Number,
      required: false,
      default: 0
    },
    isCompilationOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isHidden: false,
      preparedContents: []
    };
  },
  computed: {},
  mounted() {
    this.preparedContents = this.$_prepareContents(this.contents);
  },
  methods: {
    isContentSelected(index) {
      return index === this.selectedChapterIndex;
    },
    onContentClick(index) {
      this.$emit('contentListBlockEvent', {
        index
      });
    },
    $_prepareContents(contents) {
      return contents.reduce((filtered, content) => {
        if (content.text || content.title) {
          const contentView = new ContentView(content)
            .setIsReadChapter(this.$_isReadChapter(content))
            .setDir(this.$_getDirection(content.id));
          filtered.push(contentView);
        }
        return filtered;
      }, []);
    },
    $_getDirection(paraId) {
      let dir = 'auto';
      try {
        dir = this.$store.getters['BookStore/getParaDirection'](paraId);
      } catch (error) {
        logger.error(
          `Get error on get direction for paraId:${paraId} error:${error}`
        );
      }
      return dir;
    },
    $_isReadChapter(content) {
      if (this.isCompilationOpen) {
        return false;
      }

      return this.$store.getters['ProgressStore/isReadChapter']({
        bookId: this.bookId,
        paraId: content.id
      });
    }
  }
};
</script>

<style scoped lang="less">
@import 'ContentListBlock.less';
</style>
