<template>
  <div class="collect-user-information-block">
    <div class="user-name-block">
      <BaseTextField
        v-model.trim="$v.userName.$model"
        class="mt-4"
        :label="$t('UserDataCollection.name.label')"
        type="text"
        tabindex="0"
        name="yourName"
        :disabled="isOffline"
        :maxlength="nameMaxLength"
        :error-messages="userNameError"
        @change="checkFormValidity"
      />
    </div>
    <div>
      <BaseSelect
        v-model="language"
        :items="languages"
        :item-text="getConcatLanguage"
        item-value="language"
        :label="$t('UserDataCollection.language')"
        :placeholder="$t('UserDataCollection.language.placeholder')"
        :hint="$t('UserDataCollection.language.hint')"
        persistent-hint
        :disabled="isOffline"
        :class="'language-select flex-grow-1'"
        :menu-props="{ bottom: true, offsetY: true }"
        @change="checkFormValidity()"
      >
        <template #selection="{ item }">
          <span>{{ item.name }} ({{ item.language.toUpperCase() }})</span>
        </template>
        <template #item="{ item }">
          <span>{{ item.name }} ({{ item.language.toUpperCase() }})</span>
        </template>
      </BaseSelect>
    </div>
    <div class="mb-4">
      <div class="item-label mb-1">
        {{ $t('UserDataCollection.role.block.label') }}
      </div>
      <div class="roles-block">
        <v-radio-group
          v-model="selectedRoles"
          :disabled="isOffline"
          hide-details
        >
          <template v-for="(item, index) of roles">
            <div
              :key="index"
              :for="'item-' + index"
              class="radio-group-cell"
              @click="simulateRadioClick(index)"
            >
              <div class="role-text">
                <div class="role-title">{{ item.title }}</div>
                <div class="role-description">{{ item.description }}</div>
              </div>
              <v-radio
                :id="'item-' + index"
                :ref="'radioRef' + index"
                color="primary"
                :value="item.value"
              ></v-radio>
            </div>
          </template>
        </v-radio-group>
      </div>
    </div>
    <div>
      <div class="item-label mb-2">
        {{ $t('UserDataCollection.age.block.label') }}
      </div>
      <v-radio-group
        v-model="selectedAgeRange"
        row
        hide-details
        :disabled="isOffline"
        class="age-block"
        @change="checkFormValidity"
      >
        <v-radio
          v-for="(item, index) of ages"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></v-radio>
      </v-radio-group>
    </div>

    <div v-if="false">
      <div class="collect-user-information-description py-2 px-3">
        <BaseSpriteIcon
          icon-name="ico-info"
          color="primary"
          custom-class="me-2"
        />{{ $t('UserDataCollection.info.label') }}
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrorMessages from '@/enums/ValidationErrorMessagesEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseSelect from '@/components/base/BaseSelect/BaseSelect';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import { required } from 'vuelidate/lib/validators';
import { nameMaxLength, isNameValid } from '@/services/ValidationService';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('Translation.vue');

export default {
  name: 'UserDataCollection',
  components: { BaseSpriteIcon, BaseSelect, BaseTextField },
  data() {
    const ageRanges = this.$store.getters[
      'ContextStore/getSegmentationAgeRanges'
    ];
    const roles = this.$store.getters['ContextStore/getSegmentationRoles'];

    return {
      nameMaxLength,
      roles: Object.keys(roles).map(key => {
        return {
          title: this.$t(`UserDataCollection.role.${key}.title`),
          description: this.$t(`UserDataCollection.role.${key}.description`),
          value: roles[key]
        };
      }),
      ages: this.$_segmentationAgeRangesMap(ageRanges),
      ageRangeError: '',
      languageError: '',
      rolesError: ''
    };
  },
  validations: {
    userName: {
      required,
      isNameValid
    }
  },
  computed: {
    userName: {
      get: function() {
        const user = this.$store.getters['UserStore/getTempUserData'];
        return user.name;
      },
      set: function(name) {
        this.$store.commit('UserStore/setTempUserData', { name });
      }
    },
    selectedAgeRange: {
      get: function() {
        return this.$store.getters['UserSegmentationDataStore/getUserAgeRange'];
      },
      set: function(age) {
        this.$store.commit('UserSegmentationDataStore/setUserAgeRange', age);
      }
    },
    selectedRoles: {
      get: function() {
        const roles = this.$store.getters[
          'UserSegmentationDataStore/getUserRoles'
        ];
        return Array.isArray(roles) ? roles[0] : roles;
      },
      set: function(role) {
        this.$store.commit('UserSegmentationDataStore/setUserRoles', [role]);
      }
    },
    language: {
      get: function() {
        return this.$store.getters['UserSegmentationDataStore/getUserLanguage'];
      },
      set: function(language) {
        this.$store.commit(
          'UserSegmentationDataStore/setUserLanguage',
          language
        );
      }
    },
    languages() {
      return this.$store.getters['WikiStore/getSupportedLanguages'];
    },
    userNameError() {
      if (!this.$v.userName.$invalid) {
        return '';
      }

      return this.$v.userName.required
        ? this.$t(ValidationErrorMessages.NAME_IS_NON_LATIN)
        : this.$t(ValidationErrorMessages.NAME_IS_EMPTY);
    },
    isOffline() {
      return !this.$store.getters['ContextStore/isOnline'];
    }
  },
  async created() {
    await this.$store.dispatch(
      'UserSegmentationDataStore/initUserSegmentationData'
    );
    this.setCurrentUserName();
  },
  async mounted() {
    if (!this.languages?.length) {
      await this.reInitLanguages();
    }
  },
  methods: {
    setCurrentUserName() {
      const user = this.$store.getters['UserStore/getUser'];
      this.$store.commit('UserStore/setTempUserData', { name: user.name });
    },
    getConcatLanguage(language) {
      return `${language.name}`;
    },
    async selectLang(targetLang) {
      try {
        await this.$store.dispatch('WikiStore/addTranslation', {
          targetLang
        });
      } catch (err) {
        logger.error(`Error on translate text in Translation.vue: ${err}`);
      }
    },
    $_segmentationAgeRangesMap(ageRanges) {
      return Object.keys(ageRanges).map(key => {
        return {
          label: this.$t(`UserSegmentationData.ageRanges.${key}`),
          value: ageRanges[key]
        };
      });
    },
    validateAgeRange() {
      if (!this.selectedAgeRange || this.selectedAgeRange.length == 0) {
        this.ageRangeError = this.$t('UserDataCollection.age.error');
      } else {
        this.ageRangeError = '';
      }
    },
    validateLanguage() {
      if (!this.language) {
        this.languageError = this.$t('UserDataCollection.language.error');
      } else {
        this.languageError = '';
      }
    },
    validateRoles() {
      if (!this.selectedRoles || this.selectedRoles.length == 0) {
        this.rolesError = this.$t('UserDataCollection.role.error');
      } else {
        this.rolesError = '';
      }
    },
    checkFormValidity() {
      this.validateAgeRange();
      this.validateLanguage();
      this.validateRoles();

      const isFormValid =
        !this.userNameError &&
        !this.ageRangeError &&
        !this.languageError &&
        !this.rolesError;

      this.$store.dispatch(
        'UserStore/setUserSegmentationFormValid',
        isFormValid
      );
    },
    simulateRadioClick(index) {
      this.$refs[`radioRef${index}`][0].$el.click();
      this.checkFormValidity();
    },
    async reInitLanguages() {
      try {
        await this.$store.dispatch('WikiStore/initTranslatorLanguages');
        await this.$store.dispatch(
          'UserSegmentationDataStore/initUserLanguage'
        );
      } catch (error) {
        logger.error(`Get error on get translatorLanguages error:${error}`);
      }
    }
  }
};
</script>
<style lang="less" src="./UserDataCollection.less"></style>
