import LoggerFactory from '@/services/utils/LoggerFactory';

const logger = LoggerFactory.getLogger('ExternalBlogStore.js');

import ExternalBlogTypesEnum from '@/enums/ExternalBlogTypesEnum';

import BloggerStore from '@/store/BloggerStore';
import ExternalBlogService from '@/services/ExternalBlogService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

const initState = () => ({
  storeName: ''
});

const storeGetters = {
  getExternalBlogTypes(state, getters, rootState, rootGetters) {
    const externalBlogsConfig =
      rootGetters['ContextStore/getExternalBlogsConfig'];
    return Object.keys(externalBlogsConfig).filter(item => item.enabled);
  },
  getExternalBlogsList(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getExternalBlogsList`];
  },
  getAppMenuBlogDescription(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getAppMenuBlogDescription`];
  },
  getExternalBlogUrl(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getExternalBlogUrl`];
  },
  getStoreName(state) {
    return state.storeName;
  },
  getExternalBlogSettings(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    if (storeName) {
      return rootGetters[`${storeName}/getExternalBlogSettings`];
    }
    return {};
  },
  getSelectedBlog(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getSelectedBlog`];
  },
  getCachedBlogPost(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getCachedBlogPost`];
  },
  getBlogPostEditContext(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getBlogPostEditContext`];
  }
};

const actions = {
  async initBlogSettings({ dispatch, getters }) {
    let blogSettings = await ExternalBlogService.getExternalBlogSettings();

    // now only one blog available and no possibility change blog type
    dispatch('setStoreNameByBlogType', ExternalBlogTypesEnum.BLOGGER);

    const storeName = getters.getStoreName;
    dispatch(`${storeName}/initBlogSettings`, blogSettings, { root: true });
  },
  initBlogPost({ dispatch, getters }, context) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/initBlogPost`, context, { root: true });
  },
  performExternalBlogLogin({ dispatch, getters }, query) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/performLogin`, query, { root: true });
  },
  setStoreNameByBlogType({ commit }, blogType) {
    let storeName = '';
    switch (blogType) {
      case ExternalBlogTypesEnum.BLOGGER:
        storeName = BloggerStore.name;
        break;
      default:
        logger.error(`Get unsupported blog type: ${blogType}`);
        break;
    }
    commit('setStoreName', storeName);
  },
  retrieveExternalBlogsList({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/retrieveExternalBlogsList`, null, { root: true });
  },
  saveExternalBlogSettings({ getters, dispatch }, settings) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/saveExternalBlogSettings`, settings, { root: true });
  },
  saveSelectedBlogSettings({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/saveSelectedBlogSettings`, null, { root: true });
  },
  changeAccount({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/changeAccount`, null, { root: true });
  },
  createNewBlog({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/createNewBlog`, null, { root: true });
  },
  handleConnectFromAccountSettings({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/handleConnectFromAccountSettings`, null, {
      root: true
    });
  },
  handleDisconnectFromAccountSettings({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/handleDisconnectFromAccountSettings`, null, {
      root: true
    });
  },
  async handleAppMenuClick({ getters, dispatch }) {
    const isLoginOrBuySubscriptionNeeded = await dispatch('loginNeeded', '');
    if (isLoginOrBuySubscriptionNeeded) {
      return;
    }

    const storeName = getters.getStoreName;
    dispatch(`${storeName}/handleAppMenuClick`, null, { root: true });
  },
  async openBlogSharing({ getters, dispatch }, popupContext) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/openBlogSharing`, popupContext, {
      root: true
    });
  },
  loginNeeded({ dispatch, rootGetters }, bookId) {
    if (!bookId) {
      return openConnectIfNeeded(dispatch, rootGetters);
    }
    return (
      openConnectIfNeeded(dispatch, rootGetters) ||
      openSubscribeIfNeeded(dispatch, rootGetters, bookId)
    );
  },
  setSelectedBlog({ getters, dispatch }, selectedBlog) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/setSelectedBlog`, selectedBlog, {
      root: true
    });
  },
  async saveBlogPost({ getters, dispatch }) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/saveBlogPost`, null, {
      root: true
    });
  },
  cacheBlogPost({ getters, dispatch }, blogPost) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/cacheBlogPost`, blogPost, {
      root: true
    });
  }
};

const mutations = {
  setStoreName(state, storeName) {
    state.storeName = storeName;
  }
};

function openConnectIfNeeded(dispatch, rootGetters) {
  const isGuestUser = rootGetters['UserStore/isGuestUser'];
  if (isGuestUser) {
    dispatch(
      'ManagePopupStore/openPopup',
      {
        name: PopupNamesEnum.CONNECT
      },
      { root: true }
    );
    return true;
  }
  return false;
}

function openSubscribeIfNeeded(dispatch, rootGetters, bookId) {
  const isLimitedAccess = rootGetters['PublicationStore/isLimitedAccess'](
    bookId
  );
  if (isLimitedAccess) {
    dispatch('UserStore/openLimitedAccessPopup', null, { root: true });
    return true;
  }
  return false;
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
