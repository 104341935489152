class Table {
  constructor(tableName, indexProp) {
    this.tableName = tableName;
    this.indexProp = indexProp;
    this.table = {};
  }

  put(data) {
    if (!data.hasOwnProperty(this.indexProp) && !this.table[indexVal]) {
      throw new Error(
        `Can't put data in table ${this.tableName} because did not find ${this.indexProp} in data`
      );
    }
    const indexVal = data[this.indexProp];
    this.table[indexVal] = this._copy(data);
  }
  _copy(data) {
    return JSON.parse(JSON.stringify(data));
  }

  delete(indexVal) {
    delete this.table[indexVal];
  }

  clear() {
    this.table = {};
  }

  add(data) {
    if (!data.hasOwnProperty(this.indexProp)) {
      throw new Error(
        `Can't add data in table ${this.tableName} because did not find ${this.indexProp} in data`
      );
    }
    const indexVal = data[this.indexProp];
    this.table[indexVal] = this._copy(data);
  }

  get(indexVal) {
    return this.table[indexVal];
  }

  anyOf(indexVals) {
    return indexVals.reduce((resp, indVal) => {
      if (this.table.hasOwnProperty(indVal)) {
        resp.push(this.table[indVal]);
      }
      return resp;
    }, []);
  }

  bulkPut(items) {
    items.forEach(item => {
      this.add(item);
    });
  }

  toArray() {
    return Object.values(this.table);
  }
}

class InMemoryDB {
  constructor() {
    this.db = {};
    return this;
  }

  initTables(tables) {
    Object.keys(tables).forEach(table => {
      const indexProp = tables[table];
      this.db[table] = new Table(table, indexProp);
    });
    return this;
  }

  async putData(tableName, data) {
    const table = this.db[tableName];
    table.put(data);
  }

  async removeById(tableName, id) {
    const table = this.db[tableName];
    table.delete(id);
  }

  async addData(tableName, data) {
    const table = this.db[tableName];
    table.add(data);
    return;
  }

  async delete(tableName) {
    this.db[tableName].clear();
  }

  async get(tableName, id) {
    const table = this.db[tableName];
    return table.get(id);
  }

  getByIds(tableName, ids) {
    const table = this.db[tableName];
    return table.anyOf(ids);
  }

  putRows(tableName, items) {
    const table = this.db[tableName];
    table.bulkPut(items);
  }

  async getTableData(tableName) {
    return this.db[tableName].toArray();
  }
}

export default InMemoryDB;
