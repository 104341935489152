import publicationUtils from '@/services/utils/publicationUtils';

class SerializedRecentItem {
  constructor(buildData) {
    this.author = buildData.author;
    this.cover = buildData.cover;
    this.difficulty = buildData.difficulty;
    this.lastReadingTime = buildData.lastReadingTime;
    this.name = buildData.name;
    this.progress = buildData.progress;
    this.publicationId = buildData.publicationId;
    this.publicationType = buildData.publicationType;
    this.readingPosition = buildData.readingPosition;
    this.readingTime = buildData.readingTime;
    this.studyGuideCopyId = buildData.studyGuideCopyId;
    this.studyGuideId = buildData.studyGuideId;
    this.type = buildData.type;
    this._id = buildData._id;
  }
}

class SerializedRecentItemBuilder {
  setAuthor(author) {
    this.author = author;
    return this;
  }
  setCover(cover) {
    this.cover = cover;
    return this;
  }
  setDifficulty(difficulty) {
    this.difficulty = difficulty;
    return this;
  }
  setLastReadingTime(lastReadingTime) {
    this.lastReadingTime = lastReadingTime;
    return this;
  }
  setName(name) {
    this.name = name;
    return this;
  }
  setProgress(progress) {
    this.progress = progress;
    return this;
  }
  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }
  setPublicationType(publicationType) {
    this.publicationType = publicationType;
    return this;
  }
  setReadingPosition(readingPosition) {
    this.readingPosition = readingPosition;
    return this;
  }
  setReadingTime(readingTime) {
    this.readingTime = readingTime;
    return this;
  }
  setStudyGuideCopyId(studyGuideCopyId) {
    this.studyGuideCopyId = studyGuideCopyId;
    return this;
  }
  setStudyGuideId(studyGuideId) {
    this.studyGuideId = studyGuideId;
    return this;
  }
  setType(type) {
    this.type = type;
    return this;
  }
  setId(id) {
    this._id = id;
    return this;
  }

  build() {
    return new SerializedRecentItem(this);
  }
}

function createSerializedRecentBookItemFromMeta(meta) {
  const builder = new SerializedRecentItemBuilder();
  const lastOpenedAt = new Date().getTime();
  return builder
    .setAuthor(meta.author)
    .setCover(meta.cover)
    .setDifficulty(meta.difficulty)
    .setLastReadingTime(lastOpenedAt)
    .setName(meta.originalFileName)
    .setProgress(0)
    .setPublicationId(meta.fileName)
    .setPublicationType(meta.type)
    .setReadingPosition(0)
    .setReadingTime(0)
    .setStudyGuideCopyId(null)
    .setStudyGuideId(null)
    .setType(meta.type)
    .setId(publicationUtils.guid())
    .build();
}

export default {
  createSerializedRecentBookItemFromMeta
};
