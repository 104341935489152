import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/i18n';
import AppModeEnum from '@/enums/AppModeEnum';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import GoolgeAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import RestService from '@/services/RestService';
import OfflineModeUtils from '@/services/utils/OfflineModeUtils';
import AppSettingsService from '@/services/AppSettingsService';
import AppConfigService from '@/services/AppConfigService';
import ElectronService from '@/services/Electron/ElectronService';
import clientNodeContext from '@/context/ClientNodeContext';
import AppStateEnum from '@/enums/AppStateEnum';
import OpenAppStrategyFactory from '@/classes/factories/OpenAppStrategyFactory';
import AboutContextFactory from '@/classes/factories/AboutContextFactory';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import MetaInfoConfig from '@/services/utils/MetaInfoConfig';
const LOCAL_CONFIG = 'localConfig';
const configName = 'ArtifactConfig';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ContextStore.js');

// initial state

const initState = () => {
  const clonedClientNodeContext = cloneDeep(clientNodeContext);
  return {
    bookViewMode: false,
    artifactUrlConfig: UnifiedSettingsService.getSetting(
      LOCAL_CONFIG,
      configName
    ),
    userAgentParams: clonedClientNodeContext.userAgentParams,
    Context: {},
    serviceClientInit: false,
    storesInit: false,
    openStrategy: {},
    nextRoute: '',
    previousRoute: '',
    previousBookRoute: '',
    previousNotBookRoute: '',
    previousNotGenreRoute: '',
    previousNotAuthorRoute: '',
    previousNotBookAndNotCollectionRoute: '',
    isMetaBlockInViewport: false,
    isScrolling: false,
    isProgressbarCompact: false,
    isBookSearchShown: false,
    isFromSearchShown: false,
    isPresentPublicationMounted: false,
    isStableContent: false,
    currentLanguage: null,
    systemLanguage: AppSettingsService.getSystemLanguage(),
    isOnline: OfflineModeUtils.checkIsOnline(),
    isAppInBackground: false,
    isWebBackground: false,
    clientNodeContext: clonedClientNodeContext,
    checkoutStatus: null,
    localizeLanguage: 'en',
    serverUrl: 'https://current.build.ocean.isddesign.com/develop-ffa/',
    parameters: {
      EpubConfig: {
        oauthPath: 'rest/oauth'
      }
    },
    platformClassList: {
      html: [],
      body: []
    },
    isDefaultLayoutInitiated: false,
    isSearchLoadingStopped: false,
    isSearchLoaded: false,
    vuetifyDarkMode: false,
    searchSegmentScroll: null,
    searchPageResultsInitialized: false
  };
};

// getters
const storeGetter = {
  isAllowedFreeRole(state, getters) {
    return getters.isPurchaseEnabled;
  },
  getTermOfUseLink(state, getters) {
    const clientReaderUrl = getters.getClientReaderUrl;
    return `${clientReaderUrl}terms-of-use`;
  },
  getPrivacyPolicyLink(state, getters) {
    const clientReaderUrl = getters.getClientReaderUrl;
    return `${clientReaderUrl}privacy`;
  },
  isViewMode(state) {
    return state.bookViewMode;
  },
  getPreviewConfig(state) {
    return state?.Context?.parameters?.bookPreview || {};
  },
  getPreviewRange: (state, getters) => previewKey => {
    const hasValidKey = getters.hasValidPreviewKey(previewKey);
    const previewConfig = getters.getPreviewConfig;
    return hasValidKey ? previewConfig.paraRange : {};
  },
  hasValidPreviewKey: (state, getters) => previewKey => {
    const previewConfig = getters.getPreviewConfig;
    const currentKey = previewConfig?.previewKey || null;
    const hasValidKey = Boolean(
      previewKey && currentKey && previewKey === currentKey
    );
    return hasValidKey;
  },
  isServicesReady(state) {
    return state.serviceClientInit;
  },
  isStoresReady(state) {
    return state.storesInit;
  },
  getBrandClass() {
    return process.env.BODY_CLASSES;
  },
  getBranchName(state) {
    return state?.Context?.parameters?.branch || '';
  },
  getMaxWordsNumberTts(state) {
    return state?.Context?.parameters?.polly?.maxWordNumber || 2;
  },
  getSupportedTtsLangs(state) {
    return state?.Context?.parameters?.polly?.supportedLanguages || [];
  },
  isPollyEnabled(state, getters) {
    const isOnline = getters.isOnline;
    return isOnline && (state?.Context?.parameters?.polly?.enabled || false);
  },
  initErrors(state) {
    return state.Context.initErrors;
  },
  getCategoriesSettings(state) {
    return state?.Context?.parameters?.CategoriesSettings || {};
  },
  showSupport(state, getters, rootState, rootGetters) {
    const user = rootGetters['UserStore/getUser'];
    return (
      user.adminRole &&
      get(state, 'Context.parameters.supportPopup.showSupport', false)
    );
  },
  supportPopupLink(state) {
    return get(state, 'Context.parameters.supportPopup.supportPopupLink', '');
  },
  showDifficulty(state) {
    return state.Context.parameters.showDifficulty;
  },
  librarySortStrategy(state) {
    return state.Context.parameters.librarySortStrategy;
  },
  isResetProgressSupported(state) {
    return get(state, 'Context.parameters.isResetProgressSupported', false);
  },
  isNeedAutoLogin(state) {
    return state.openStrategy.isNeedGuestAutoLogin();
  },
  getBrowserName(state) {
    return state.userAgentParams.browser.name;
  },
  isNeedShowConfirmState(state) {
    return state.openStrategy.isNeedShowConfirmState();
  },
  getDefaultRoute(state, getters, rootState, rootGetters) {
    const brand = rootGetters['ContextStore/brand'];
    const isGuest = rootGetters['UserStore/isGuestUser'];
    const isUserEmpty = rootGetters['UserStore/isUserEmpty'];
    return state.openStrategy.getDefaultRoute(isGuest, brand, isUserEmpty);
  },
  getAfterLoginRoute(state) {
    return state.openStrategy.getAfterLoginRoute();
  },
  getAfterConfirmResetRoute(state) {
    return state.openStrategy.getAfterConfirmResetRoute();
  },
  getConfirmRoute(state) {
    return state.openStrategy.getConfirmationRoute();
  },
  getAfterOauthRoute(state) {
    return state.openStrategy.getAfterOauthRoute();
  },
  getAfterBlogOauthRoute(state) {
    return state.openStrategy.getAfterBlogOauthRoute();
  },
  isAllowedPage: state => name => {
    return state.openStrategy.isAllowedPage(name);
  },
  isLibrarySetSupported(state) {
    return state.Context.parameters.isLibrarySetSupported;
  },
  isSessionsAmountHidden(state) {
    return !state.Context.parameters.userMaxSessionsAmount;
  },
  isActivityGoalEnabled(state) {
    return state.Context.parameters.isActivityGoalEnabled;
  },
  getAppDescription(state) {
    return state.Context.parameters?.description || '';
  },
  getLibraryLanguages(state) {
    return get(
      state.Context,
      'parameters.storedLibraryParameters.libraryLanguages',
      []
    );
  },
  getIlmUrl(state) {
    const url = state.Context.parameters.ilmUrl || '';
    return url.replace(/\/+$/, '');
  },
  getSystemLanguage(state) {
    return state.systemLanguage;
  },
  isOnline(state) {
    return state.isOnline;
  },
  getArtifactUrl(state) {
    return state.Context.parameters.artifactUrl;
  },
  getArtifactUrlConfig(state) {
    return state.artifactUrlConfig;
  },
  isProd(state) {
    return state.Context.parameters.isProd;
  },
  getAppStoreLinks(state) {
    return {
      ios: state.Context.parameters.iosStoreUrl,
      android: state.Context.parameters.androidStoreUrl
    };
  },
  portalPageUrl(state) {
    return state.Context.parameters.portalPageUrl;
  },
  getArtifactsUrls(state) {
    const brand = get(state, 'Context.parameters.brand', '');
    return state.Context.parameters[brand];
  },
  contextGetter(state) {
    return state.Context;
  },
  brandName(state) {
    return get(state, 'Context.parameters.brandName', '');
  },
  brand(state) {
    return get(state, 'Context.parameters.brand', '');
  },
  getPlatform(state) {
    return get(state, 'clientNodeContext.os', '');
  },
  isIpad(state) {
    return get(state, 'clientNodeContext.isIpad', false);
  },
  parameters(state) {
    return state.Context.parameters;
  },
  aboutContext(state) {
    const parameters = state.Context.parameters;
    const brand = parameters.brand;
    return AboutContextFactory.create(brand, parameters);
  },
  clientNodeContextGetter(state) {
    return state.clientNodeContext;
  },
  getClientReaderUrl(state) {
    return get(state, 'Context.parameters.clientReaderUrl', '');
  },
  getClientReaderHost(state, getters) {
    return new URL(getters.getClientReaderUrl).host;
  },
  getSupportEmail(state) {
    return get(state, 'Context.parameters.supportEmail', '');
  },
  getClientEditorUrl(state) {
    return get(state, 'Context.parameters.clientEditorUrl', '');
  },
  getClientBlogUrl(state) {
    return get(state, 'Context.parameters.clientBlogUrl', '');
  },
  getClientCoursesUrl(state) {
    return get(state, 'Context.parameters.clientCoursesUrl', '');
  },
  isDeviceBrowser(state) {
    const _clientNodeContext = state.clientNodeContext;
    return (
      _clientNodeContext.isAndroidBrowser || _clientNodeContext.isIosBrowser
    );
  },
  inIframe(state) {
    return state.clientNodeContext.iFrameDetection;
  },
  isOsx(state) {
    return state.clientNodeContext.os === 'OSX';
  },
  isIosBrowser(state) {
    return state.clientNodeContext.isIosBrowser;
  },
  isChrome(state) {
    return state.clientNodeContext.isChrome;
  },
  isSafari(state) {
    return state.clientNodeContext.isSafari;
  },
  isLinux(state) {
    return state.clientNodeContext.os === 'Linux';
  },
  supportedLanguages(state) {
    return state.Context.parameters.supportedLanguages;
  },
  supportedLanguagesByName(state) {
    const langsList = [];
    const supportedLanguageNames =
      state.Context.parameters.supportedLanguageNames;
    const langs = state.Context.parameters.supportedLanguages;

    langs.forEach(lang => {
      if (!supportedLanguageNames || !supportedLanguageNames[lang]) {
        return;
      }

      langsList.push({ name: lang, title: supportedLanguageNames[lang] });
    });
    return langsList;
  },
  getSupportedLanguageByCode: state => lang => {
    return state.Context.parameters.supportedLanguageNames[lang];
  },
  localizeLanguageGetter(state) {
    return state.localizeLanguage;
  },
  currentLanguageGetter(state) {
    return state.currentLanguage || 'en';
  },
  appModeGetter(state) {
    return state.Context.mode;
  },
  appState(state) {
    return state.nextRoute;
  },
  allowSETTOEFLVocabularyGetter(state) {
    return state.Context.parameters.allowSATTOEFLVocabulary;
  },
  fontsGetter(state) {
    return state.Context.parameters.fonts;
  },
  assessmentContextGetter(state) {
    return state.Context.parameters.AssessmentSettings;
  },
  getBaseDeeplink(state) {
    return state.Context.parameters.deeplink;
  },
  getServerUrl(state) {
    return state.Context.parameters.serverUrl;
  },
  isEditorApp(state) {
    return state.Context.mode === AppModeEnum.EDITOR;
  },
  isBlogApp(state) {
    return state.Context.mode === AppModeEnum.BLOG;
  },
  isReaderApp(state) {
    return state.Context.mode === AppModeEnum.READER;
  },
  isAppInBackground(state) {
    return state.isAppInBackground;
  },
  isWebBackground(state) {
    return state.isWebBackground;
  },
  isDevice(state) {
    return state.clientNodeContext.isDevice;
  },
  isAndroid(state) {
    return state.clientNodeContext.isAndroid;
  },
  isIos(state) {
    return state.clientNodeContext.isIos;
  },
  isIos12_4(state) {
    return state.clientNodeContext.isIos12_4;
  },
  isIos15_4(state) {
    return state.clientNodeContext.isIos15_4;
  },
  isSafariVersion15_4(state) {
    return state.clientNodeContext.isSafariVersion15_4;
  },
  iosVersion(state) {
    return state.clientNodeContext.iosVersion;
  },
  isElectron(state) {
    return state.clientNodeContext.isElectron;
  },
  isNative(state) {
    return state.clientNodeContext.native;
  },
  buildVersion(state) {
    return `${state.Context.parameters.version}.${state.Context.parameters.build_number}`;
  },
  buildDate(state) {
    return state.Context.parameters.build_date;
  },
  getIsMetaBlockInViewport(state) {
    return state.isMetaBlockInViewport;
  },
  getIsScrolling(state) {
    return state.isScrolling;
  },
  getIsProgressbarCompact(state) {
    return state.isProgressbarCompact;
  },
  shouldNotTrackProgress(state, getters) {
    return getters.getIsBookSearchShown || getters.getIsFromSearchShown;
  },
  getIsBookSearchShown(state) {
    return state.isBookSearchShown;
  },
  getIsFromSearchShown(state) {
    return state.isFromSearchShown;
  },
  getIsStableContent(state) {
    return state.isStableContent;
  },
  getIsPresentPublicationMounted(state) {
    return state.isPresentPublicationMounted;
  },
  env(state) {
    return state.Context.parameters.env;
  },
  showPublicationProgress(state) {
    return get(state, 'Context.parameters.showPublicationProgress', false);
  },
  getIsCampaignLinksEnabled(state) {
    return get(state, 'Context.parameters.campaignLinks.enabled', false);
  },
  getSocialSharingConfig(state) {
    return get(state, 'Context.parameters.socialSharing', {});
  },
  isOneTapEnabled(state) {
    return get(state, 'Context.parameters.oneTapSignIn.enabled', false);
  },
  oneTapClientId(state) {
    return get(state, 'Context.parameters.oneTapSignIn.clientId', '');
  },
  isPurchaseEnabled(state) {
    return get(state, 'Context.parameters.isPurchaseAvailable', false);
  },
  isPurchaseAvailable(state, getters) {
    return (
      !getters.isDevice && getters.isReaderApp && getters.isPurchaseEnabled
    );
  },
  isLimitedGuestAccess(state) {
    return get(state, 'Context.parameters.isLimitedGuestAccess', false);
  },
  isSuggestionsEnabled(state) {
    return get(state, 'Context.parameters.isSuggestionsEnabled', false);
  },
  getExternalBlogsConfig(state) {
    return get(state, 'Context.parameters.externalBlogs', {});
  },
  getAppleWebClientId(state) {
    return get(state, 'Context.parameters.appleWebClientId', '');
  },
  isRegistrationAllowed(state, getters) {
    return (
      getters.isEditorApp ||
      !getters.isPurchaseEnabled ||
      getters.isPurchaseAvailable
    );
  },
  bloggerWebClientId(state) {
    return get(state, 'Context.parameters.bloggerWebClientId', '');
  },
  isAssessmentAllowed(state, getters, rootState, rootGetters) {
    const isGuestUser = rootGetters['UserStore/isGuestUser'];
    const isAssessmentAllowed = get(
      state,
      'Context.parameters.isAssessmentAllowed'
    );
    return isAssessmentAllowed && !isGuestUser && getters.isOnline;
  },
  getPlatformHtmlClassList(state) {
    return state.platformClassList.html;
  },
  getPlatformBodyClassList(state) {
    return state.platformClassList.body;
  },
  getPageCanonicalLinkSetting: (state, getters) => pageName => {
    const clientReaderUrl = getters.getClientReaderUrl;
    return MetaInfoConfig.canonicalLinkSetting(clientReaderUrl, pageName);
  },
  getBookPageEmptyHeadInfo() {
    return MetaInfoConfig.emptyHeadInfo();
  },
  getIsDefaultLayoutInitiated(state) {
    return state.isDefaultLayoutInitiated;
  },
  getSharedErrorMessage: state => key => {
    return get(state, `Context.parameters.errorMessages.${key}`, '');
  },
  isSearchLoadingStopped(state) {
    return state.isSearchLoadingStopped;
  },
  isSearchLoaded(state) {
    return state.isSearchLoaded;
  },
  getPreviousBookRoute(state) {
    return state.previousBookRoute;
  },
  getPreviousNotBookRoute(state) {
    return state.previousNotBookRoute;
  },
  getSegmentationAgeRanges(state) {
    return get(state, `Context.parameters.userSegmentationData.ageRanges`, {});
  },
  isDarkMode(state) {
    return state.vuetifyDarkMode;
  },
  getSegmentationRoles(state) {
    return get(state, `Context.parameters.userSegmentationData.roles`, {});
  },
  getSearchSegmentScroll(state) {
    return state.searchSegmentScroll;
  },
  getDonationUrl: state => email => {
    const donationUrl = get(state, 'Context.parameters.donationUrl', '');
    if (!donationUrl) {
      logger.error('Donation url is not found, check the config');
      return;
    }
    const url = new URL(donationUrl);
    if (email) {
      url.searchParams.set('prefilled_email', email);
    }
    return url.href;
  }
};

const CONTEXT_STORE_OFFLINE_ID = 'contextStoreOfflineId';
// actions
const actions = {
  async loadArtifactUrlConfig({ commit, getters }) {
    const artifactUrlConfig = await AppConfigService.loadArtifactUrlConfig(
      getters.getArtifactUrl
    );
    commit('setArtifactUrlConfig', artifactUrlConfig);
    return artifactUrlConfig;
  },
  subscribeOffline({ commit }) {
    OfflineModeUtils.register({
      id: CONTEXT_STORE_OFFLINE_ID,
      notifyFn: function(isOnline) {
        commit('updateIsOnline', isOnline);
      }
    });
  },
  openUrl({ getters }, payload) {
    const isElectron = getters.isElectron;
    const { url, unsecure, openTarget } = payload;
    let openedWindow = null;
    if (isElectron) {
      openedWindow = ElectronService.openExternal(url);
    } else {
      const isDevice = getters.isDevice;
      const target = openTarget || (isDevice ? '_system' : '_blank');
      const params = isDevice || unsecure ? '' : 'noopener,noreferrer';
      openedWindow = window.open(url, target, params);
    }
    return openedWindow;
  },
  initDefaultSystemLanguage({ commit }) {
    const systemLanguage = AppSettingsService.getSystemLanguage();
    commit('setSystemLanguage', systemLanguage);
  },
  setDefaultSystemLanguage({ dispatch, getters }) {
    const systemLanguage =
      AppSettingsService.getSystemLanguage() || getters.getSystemLanguage;
    return dispatch('changeSystemLanguage', {
      lang: systemLanguage
    });
  },
  async changeSystemLanguage({ commit, getters }, payload) {
    const { lang } = payload;
    const brand = getters.brand;
    const mode = getters.appModeGetter;
    AppSettingsService.setSystemLanguage(lang);
    await i18n.changeLanguage(lang, brand, mode);
    commit('setSystemLanguage', lang);
  },
  setIsEnabledGoogleAnalytics({ commit }, payload) {
    commit('setIsEnabledGoogleAnalytics', payload);
    GoolgeAnalyticsUtils.changeIsAnalyticsEnabled(payload);
  },
  unsubscribeOffline() {
    OfflineModeUtils.unregister(CONTEXT_STORE_OFFLINE_ID);
  },
  setContext({ commit }, Context) {
    commit('setCheckoutStatus', Context);
    commit('setContext', Context);
    return Context;
  },
  updateContext: function({ commit }, val) {
    commit('updateContext', val);
    return Promise.resolve();
  },
  setLocalizeLanguage: function({ commit }, lang) {
    commit('setLocalizeLanguage', lang);
    return Promise.resolve();
  },
  updateServerUrl({ commit }, newServerUrl) {
    commit('setServerUrl', newServerUrl);
  },
  toolbarLoaded() {},
  initPlatformClasses({ getters, commit }) {
    const platform = getters['getPlatform'];
    const platformBodyClasses = [`sw-user-agent-${platform.toLowerCase()}`];
    const platformHtmlClasses = [];

    const isDeviceBrowser = getters['isDeviceBrowser'];
    const isDevice = getters['isDevice'];
    const inIframe = getters['inIframe'];

    if (isDevice || isDeviceBrowser) {
      platformHtmlClasses.push(AppConstantsUtil.HTML_IS_MOBILE_CLASS);
      platformBodyClasses.push(AppConstantsUtil.SAFE_AREA_INSERT_CLASS_NAME);
    }

    if (isDeviceBrowser) {
      platformHtmlClasses.push(AppConstantsUtil.DEVICE_BROWSER_CLASS);
    }

    if (!inIframe) {
      platformBodyClasses.push(AppConstantsUtil.IN_APP_MODE_CLASS);
    }

    commit('setPlatformBodyClassList', platformBodyClasses);
    commit('setPlatformHtmlClassList', platformHtmlClasses);
  },
  async getBookPageHeadInfo({ getters }, bookMeta) {
    try {
      const clientReaderUrl = getters['getClientReaderUrl'];
      const appDescription = getters['getAppDescription'];
      return await MetaInfoConfig.bookPageHeadInfo(
        clientReaderUrl,
        bookMeta,
        appDescription,
        bookMeta.id
      );
    } catch (error) {
      logger.warn(`Get error on load meta error ${error}`);
      return getters['getBookPageEmptyHeadInfo'];
    }
  },
  async getCategoryPageHeadInfo({ getters }, categoryName) {
    try {
      const clientReaderUrl = getters['getClientReaderUrl'];
      const category = AppStateEnum.CATEGORY.split('-')[0];
      return await MetaInfoConfig.categoryPageHeadInfo(
        `${clientReaderUrl}/${category}`,
        categoryName
      );
    } catch (error) {
      logger.warn(`Get error on load category page meta ${error}`);
    }
  },
  getGenrePageHeadInfo(_, genreName) {
    try {
      return MetaInfoConfig.genrePageHeadInfo(genreName);
    } catch (error) {
      logger.warn(`Get error on load genre page meta ${error}`);
    }
  },
  getAuthorPageHeadInfo(_, authorHash) {
    try {
      return MetaInfoConfig.authorPageHeadInfo(authorHash);
    } catch (error) {
      logger.warn(`Get error on load author page meta ${error}`);
    }
  },
  async getPricingPageHeadInfo({ getters }) {
    try {
      const clientReaderUrl = getters['getClientReaderUrl'];
      return await MetaInfoConfig.pricingPageHeadInfo(
        clientReaderUrl,
        AppStateEnum.PRICING
      );
    } catch (error) {
      logger.warn(`Get error on load category page meta ${error}`);
    }
  },
  setIsDefaultLayoutInitiated({ commit }, isInitiated) {
    commit('setIsDefaultLayoutInitiated', isInitiated);
  },
  stopSearchLoading({ commit }, isSearchLoadingStopped) {
    commit('stopSearchLoading', isSearchLoadingStopped);
  },
  setSearchLoaded({ commit }, isSearchLoaded) {
    commit('setSearchLoaded', isSearchLoaded);
  },
  setVuetifyDarkMode({ commit }, isDarkMode) {
    commit('setVuetifyDarkMode', isDarkMode);
  },
  setSearchSegmentScroll({ commit }, scroll) {
    commit('setSearchSegmentScroll', scroll);
  }
};

// mutations
const mutations = {
  setBookViewMode(state, val) {
    state.bookViewMode = val;
  },
  updateIsOnline(state, isOnline) {
    state.isOnline = isOnline;
  },
  updateSupportedLanguages(state, supportedLanguages) {
    state.Context.parameters.supportedLanguages = supportedLanguages;
  },
  setContext(state, Context) {
    state.Context = Context;
  },
  setServiceClientInit(state, val) {
    state.serviceClientInit = val;
  },
  setStoresInit(state, val) {
    state.storesInit = val;
  },
  setOpenStrategy(state, type) {
    state.openStrategy = OpenAppStrategyFactory.getOpenAppStrategy(type);
  },
  updateContext(state, val) {
    state.Context = Object.assign(state.Context, val);
  },
  setArtifactUrlConfig(state, val) {
    state.artifactUrlConfig = val;
  },
  setCheckoutStatus(state, status) {
    state.checkoutStatus = status;
  },

  setLocalizeLanguage(state, lang) {
    state.localizeLanguage = lang;
  },

  setCurrentLanguage(state, lang) {
    state.currentLanguage = lang;
  },

  setBackgroundMode(state, isAppInBackground) {
    state.isAppInBackground = isAppInBackground;
  },

  setWebBackgroundMode(state, isWebBackground) {
    state.isWebBackground = isWebBackground;
  },

  setAppState(state, payload) {
    const { from, to } = payload;
    const fromRoute = {
      name: from.name,
      query: from.query,
      params: from.params
    };
    state.previousRoute = from?.name ? fromRoute : null;
    state.previousBookRoute =
      from?.name === AppStateEnum.PRESENT_PUBLICATION
        ? fromRoute
        : state.previousBookRoute;
    state.previousNotAuthorRoute =
      from?.name !== AppStateEnum.AUTHOR_PAGE
        ? fromRoute
        : state.previousNotAuthorRoute;
    state.previousNotGenreRoute =
      from?.name !== AppStateEnum.GENRE_PAGE &&
      from?.name !== AppStateEnum.AUTHOR_PAGE
        ? fromRoute
        : state.previousNotGenreRoute;
    state.previousNotBookRoute =
      from?.name !== AppStateEnum.PRESENT_PUBLICATION &&
      !to.params?.staticRedirectFromAbout
        ? fromRoute
        : state.previousNotBookRoute;
    state.previousNotBookAndNotCollectionRoute =
      from?.name !== AppStateEnum.PRESENT_PUBLICATION &&
      from?.name !== AppStateEnum.COLLECTION &&
      from?.name !== AppStateEnum.AUTHOR_PAGE
        ? fromRoute
        : state.previousNotBookAndNotCollectionRoute;
    state.nextRoute = to.name;
  },

  setServerUrl(state, serverUrl) {
    state.Context.serverUrl = serverUrl;
    AssetsManager.init(state.Context);
    RestService.init(state.Context);
  },

  setSystemLanguage(state, lang) {
    state.systemLanguage = lang;
  },

  setIsEnabledGoogleAnalytics(state, isEnabledGA) {
    state.Context.parameters.googleAnalyitics.enabled = isEnabledGA;
  },

  setIsMetaBlockInViewport(state, isMetaBlockInViewport) {
    state.isMetaBlockInViewport = isMetaBlockInViewport;
  },

  setIsScrolling(state, isScrolling) {
    state.isScrolling = isScrolling;
  },

  setIsProgressbarCompact(state, isProgressbarCompact) {
    state.isProgressbarCompact = isProgressbarCompact;
  },

  setIsBookSearchShown(state, val) {
    state.isBookSearchShown = val;
  },
  setIsFromSearchShown(state, val) {
    state.isFromSearchShown = val;
  },
  setIsPresentPublicationMounted(state, isPresentPublicationMounted) {
    state.isPresentPublicationMounted = isPresentPublicationMounted;
  },
  setIsStableContent(state, isStableContent) {
    state.isStableContent = isStableContent;
  },
  updateClientNodeContext(state) {
    const clonedClientNodeContext = cloneDeep(clientNodeContext);
    state.userAgentParams = clonedClientNodeContext.userAgentParams;
    state.clientNodeContext = clonedClientNodeContext;
  },
  setPlatformBodyClassList(state, classList) {
    state.platformClassList.body = classList;
  },
  setPlatformHtmlClassList(state, classList) {
    state.platformClassList.html = classList;
  },
  setIsDefaultLayoutInitiated(state, isInitiated) {
    state.isDefaultLayoutInitiated = isInitiated;
  },
  stopSearchLoading(state, isSearchLoadingStopped) {
    state.isSearchLoadingStopped = isSearchLoadingStopped;
  },
  setSearchLoaded(state, isSearchLoaded) {
    state.isSearchLoaded = isSearchLoaded;
  },
  setVuetifyDarkMode(state, isDarkMode) {
    state.vuetifyDarkMode = isDarkMode;
  },
  setSearchSegmentScroll(state, scroll) {
    state.searchSegmentScroll = scroll;
  },
  setSearchPageResultsInitialized(state, isInitialized) {
    state.searchPageResultsInitialized = isInitialized;
  }
};

export default {
  state: initState,
  getters: storeGetter,
  actions,
  mutations
};
