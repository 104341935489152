const maxBy = require('lodash/maxBy');

module.exports = class AchievementCalculator {
  constructor(data = {}) {
    this.totalRead = data.totalRead || 0;
    this.mostReading = data.mostReading || 0;
    this.bestStreak = data.bestStreak || 0;
    this.completedBooks = new Set(data.completedBooks || []);
  }
  calcAchievements(activity) {
    // for now leave forceRecalc, but it needs to be refactored (optimized)
    this.bestStreak = this._forceRecalcBestStreak(activity);
    this.totalRead = this._calcTotalReading(activity);
    this.mostReading = this._calcMostReading(activity);
    return this;
  }
  setCompletedBooks(completedBooks) {
    this.completedBooks = new Set(completedBooks);
    return this;
  }
  decrementBastStreak() {
    this.bestStreak = Math.max(0, this.bestStreak - 1);
  }
  _calcBestStreak(activities) {
    const prevBestStreak = this.bestStreak;
    const activityLastIndex = activities.length - 1;
    let bestStreak = 0;
    for (let i = activityLastIndex; i >= 0; i--) {
      const activity = activities[i];
      if (activity.goal && !activity.achievedGoal) {
        break;
      }
      if (activity.achievedGoal) {
        bestStreak++;
      }
    }
    return bestStreak > prevBestStreak ? bestStreak : prevBestStreak;
  }
  _forceRecalcBestStreak(activities) {
    let bestStreak = 0;
    let currentStreak = 0;
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      if (!activity.goal) {
        continue;
      }
      if (activity.achievedGoal) {
        currentStreak++;
        bestStreak = Math.max(bestStreak, currentStreak);
      } else {
        currentStreak = 0;
      }
    }
    return bestStreak;
  }
  _calcTotalReading(activity) {
    return activity.reduce((total, a) => total + a.minutesRead, 0);
  }
  _calcMostReading(activity) {
    const newMostReading = maxBy(activity, 'minutesRead');
    return newMostReading ? newMostReading.minutesRead : this.mostReading;
  }
  build() {
    return {
      totalRead: this.totalRead,
      mostReading: this.mostReading,
      bestStreak: this.bestStreak,
      completedBooks: [...this.completedBooks]
    };
  }
};
