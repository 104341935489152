import DB from '@/services/Agent/dao/DB';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserProgressDao.js');
const errorCodes = {
  NOT_FOUND: 404
};

function putDocument(id, data, revision) {
  Object.assign(data, { _id: id, _rev: revision });
  return DB.userRW().put(data);
}

function findDocument(id) {
  return DB.userRW()
    .get(id)
    .catch(err => {
      if (err.status === errorCodes.NOT_FOUND) {
        return null;
      }
      logger.error(err);
    });
}

function getBookId(bookId) {
  return DB.id.bookProgress(bookId);
}

async function updateDocument(id, data) {
  const doc = await findDocument(id);
  if (!doc) {
    return putDocument(id, data);
  } else {
    return putDocument(id, data, doc._rev);
  }
}

function getBookProgress(bookId) {
  const _id = getBookId(bookId);
  return findDocument(_id);
}

function updateBookProgress(bookId, updateData) {
  const _id = getBookId(bookId);
  return updateDocument(_id, updateData);
}

async function resetBookProgress(bookId) {
  const _id = getBookId(bookId);
  const data = await findDocument(_id);
  if (data) {
    data.ranges.forEach(range => {
      range.ignore = true;
    });
    data.audioCurrentLocator = '';
    data.readingCurrentLocator = '';
    return putDocument(_id, data, data._rev);
  }
  return Promise.resolve();
}

function getProgressForAllBooks() {
  const prefix = DB.prefix.bookProgress;
  return DB.userRW()
    .getAllByPrefix(prefix)
    .then(allBooksProgress => {
      const publicationsProgress = {};
      allBooksProgress.map(bookProgress => {
        const bookId = bookProgress._id.substr(prefix.length + 1);
        publicationsProgress[bookId] = bookProgress;
      });
      return Promise.resolve(publicationsProgress);
    });
}

function getProgressForBookIds(bookIds) {
  const keys = (bookIds || []).map(bookId => getBookId(bookId));

  return DB.userRW()
    .allDocs({
      include_docs: true,
      keys: keys
    })
    .then(response => response.rows || [])
    .catch(() => []);
}

export default {
  updateBookProgress,
  getBookProgress,
  resetBookProgress,
  getProgressForAllBooks,
  getProgressForBookIds
};
