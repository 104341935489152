function authorFormatting(author) {
  //TODO: remove this function after fix on ILM side
  if (!author) {
    return '';
  }
  if (Array.isArray(author)) {
    return author.join(', ').replace(/\s{2,}/g, ' ');
  }
  return author
    .split(',')
    .join(', ')
    .replace(/\s{2,}/g, ' ');
}
module.exports = {
  authorFormatting
};
