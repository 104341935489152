<template>
  <Popup
    :content-class="'popup-delete-compilation'"
    scrollable
    persistent
    :content-image="contentImage"
    :content-title="
      compilation.default
        ? $t('CompilationDelete.popup.default.title')
        : $t('CompilationDelete.popup.regular.title', {
            compilationTitle: compilation.title
          })
    "
    :content-message="
      compilation.default
        ? $t('CompilationDelete.popup.default.description')
        : $t('CompilationDelete.popup.regular.description')
    "
    :primary-label="$t('Popup.button.delete.label')"
    :secondary-label="$t('Popup.button.cancel.label')"
    :primary-footer-handler="deleteCompilation"
    :secondary-footer-handler="cancelDeleteCompilation"
    :header-divider="false"
    :footer-divider="false"
    width="588"
    @popupEvent="popupEventHandler"
  ></Popup>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CompilationRemoveDefaultPopup.vue');

import Popup from '@/components/base/Popup/Popup';

import images from '@/assets/images';
import AssetsManager from '@/services/AssetsManager/AssetsManager';

import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';

export default {
  name: 'CompilationRemoveDefaultPopup',
  components: { Popup },
  props: {
    popupContext: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      contentImage: images.picGarbage,
      compilation: {}
    };
  },
  mounted() {
    this.compilation = this.popupContext.compilation || {};
  },
  methods: {
    async deleteCompilation() {
      const compilationId = this.compilation?.id;
      if (!compilationId) {
        this.$_close();
        return;
      }

      try {
        const appMode = this.$store.getters['ContextStore/appModeGetter'];
        await this.$store.dispatch('CompilationsStore/remove', compilationId);
        await this.$store.dispatch('RecentBookStore/removeRecentItem', {
          publicationId: compilationId,
          appMode
        });
        await this.$store.dispatch('LibraryStore/removePublication', {
          publicationId: compilationId,
          managePublicationsState: ManagePublicationsStates.COMPILATIONS
        });
        if (
          this.compilation.isAudioDownloaded ||
          this.compilation.isContentDownloaded
        ) {
          await AssetsManager.removePublication(compilationId);
        }
      } catch (error) {
        logger.error(`Error while deleting compilation: ${error.stack}`);
      } finally {
        this.$_close();
      }
    },
    cancelDeleteCompilation() {
      this.$_close();
    },
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
        default:
          break;
      }
    },
    $_close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.COMPILATION_DELETE_POPUP }
      });
    }
  }
};
</script>

<style lang="less" src="./CompilationDeletePopup.less"></style>
