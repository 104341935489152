import AppService from '@/services/AppService';

export default async function(context) {
  await AppService.init(
    context.store,
    context.router ?? context.app.router,
    context.i18n,
    context.$vuetify
  );
}
