import { Country } from '@/classes/factories/Assessment/UserDemographicDataFactory';

import countryList from '@shared/enums/CountryList.json';

import VocabularyAssessmentService from '@/services/Assessment/VocabularyAssessmentService';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserDemographicDataStore.js');

const initState = () => ({
  countryList: [],
  userData: {
    birthYear: null,
    language: null,
    gender: null,
    country: new Country()
  },
  isDemographicDataSaved: null
});

const storeGetters = {
  getCountryList: state => state.countryList,
  getUserBirthYear: state => state.userData.birthYear,
  getUserLanguage: state => state.userData.language,
  getUserGender: state => state.userData.gender,
  getUserCountry: state => state.userData.country,
  getFlagUrl: (state, getters, rootState, rootGetters) => countryCode => {
    const serverUrl = rootGetters['ContextStore/getServerUrl'];
    return `${serverUrl}country-flags/${countryCode.toLowerCase()}.svg`;
  },
  getBirthYearsRange: () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push(i);
    }
    return years;
  },
  isDemographicDataFilled: state => {
    return (
      state.userData.birthYear &&
      state.userData.language &&
      state.userData.gender &&
      state.userData.country?.countryCode
    );
  },
  isDemographicDataSaved: state => state.isDemographicDataSaved
};

const actions = {
  async initCountryList({ getters, commit }) {
    const listOfCountries = countryList.map(country => {
      country.flag = getters['getFlagUrl'](country.countryCode);

      if (!country.flag) {
        logger.error(`Couldn't find flag for Country: ${country.countryCode}`);
      }
      return country;
    });
    commit('setCountryList', listOfCountries);
  },
  async initUserCountry({ commit }) {
    const countryCode = await VocabularyAssessmentService.getUserCountryCode();
    if (countryCode === 'Unrecognized') {
      return;
    }
    const country = countryList.find(item => item.countryCode === countryCode);
    commit('setUserCountry', country);
  },
  async saveDemographicData({ state, commit, getters }) {
    if (!getters.isDemographicDataFilled) {
      return;
    }

    const userData = { ...state.userData };
    userData.countryCode = userData.country.countryCode;

    const id = await VocabularyAssessmentService.saveDemographicData({
      userData
    });
    if (id) {
      commit('setIsDemographicDataSaved', true);
    }
  },
  async fetchIsDemographicDataSaved({ commit }) {
    const isDemographicDataSaved = await VocabularyAssessmentService.fetchIsDemographicDataSaved();
    if (isDemographicDataSaved) {
      commit('setIsDemographicDataSaved', true);
    }
  }
};

const mutations = {
  setCountryList(state, listOfCountries) {
    state.countryList = listOfCountries;
  },
  setUserBirthYear(state, birthYear) {
    state.userData.birthYear = birthYear;
  },
  setUserLanguage(state, language) {
    state.userData.language = language;
  },
  setUserGender(state, gender) {
    state.userData.gender = gender;
  },
  setUserCountry(state, country) {
    state.userData.country =
      country instanceof Country ? country : new Country(country);
  },
  setIsDemographicDataSaved(state, isSaved) {
    state.isDemographicDataSaved = isSaved;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
