import DB from '@/services/Agent/dao/DB';

async function get() {
  const docId = _getDocId();
  return DB.userRW().get(docId);
}

function getDefaultDoc() {
  return {
    _id: _getDocId(),
    type: 'userSettings',
    userSettings: {},
    createdAt: Date.now(),
    modifiedAt: Date.now()
  };
}

/**
 *
 * @param {Object} settings
 */
function write(settings) {
  Object.assign(settings, { _id: settings._id, _rev: settings?._rev });
  return DB.userRW().put(settings);
}

function _getDocId() {
  const userId = DB.userId();
  return DB.id.userSettings(userId);
}

export default {
  get,
  getDefaultDoc,
  write
};
