<template>
  <BaseButton
    v-bind="$attrs"
    class="px-3"
    :text="text"
    @click.native.prevent="toCompilations"
  >
    <slot name="back-to-compilations">Compilations</slot>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToCompilationControl',
  components: {
    BaseButton
  },
  props: {
    text: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toRoute() {
      this.$router.push({ name: AppStateEnum.MANAGE_COMPILATION });
    },
    toLink() {
      const clientReaderUrl = this.$store.getters[
        'ContextStore/getClientReaderUrl'
      ];
      this.$store.dispatch('ContextStore/openUrl', {
        url: `${clientReaderUrl}extracts`
      });
    },
    toCompilations() {
      if (this.isBlogApp) {
        this.toLink();
        return;
      }
      this.toRoute();
    }
  }
};
</script>

<style lang="less" src="./ToCompilationControl.less"></style>
