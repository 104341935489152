<template>
  <div class="no-data">
    <div>
      <img :src="contentImage" />
    </div>
    <div class="no-data__header">{{ contentTitle }}</div>
  </div>
</template>

<script>
import images from '@/assets/images';

export default {
  name: 'FilterNoItems',
  props: {
    contentTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return { contentImage: images.picSearch };
  }
};
</script>

<style lang="less" src="./FilterNoItems.less" scoped></style>
