import SuggestionsProgressDao from '@/services/Agent/dao/SuggestionsProgressDao';

export default {
  POST: {},
  GET: {
    suggestionsProgress
  }
};

function suggestionsProgress({ suggestionIds, userId, dateFrom }) {
  return SuggestionsProgressDao.getProgressForSuggestions(
    suggestionIds,
    userId,
    dateFrom
  );
}
