// import LoggerFactory from '@/services/utils/LoggerFactory';
// const logger = LoggerFactory.getLogger('StudyClassDao.js');

import StudyClassTypes from '@/enums/StudyClassTypes';

import DB from '@/services/Agent/dao/DB';
import tools from '@/services/Agent/tools';
import AgentContext from '@/services/Agent/AgentContext';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('StudyClassDao.js');

const prefix = DB.prefix.course + '-';

function getClassById(classId) {
  const _id = _prefix(classId);
  return DB.userRW()
    .get(_id)
    .catch(_notFoundErrorHandler);
}

async function readMergedDoc(courseId) {
  const { docPublic, docUser } = await _getCourseDocs(courseId);
  const mergedDoc = _mergeDoc(docPublic, docUser);
  return mergedDoc;
}

function createMergedDoc(studyClassDiff) {
  const docUser = createDefaultStudyClass(studyClassDiff);
  const docPublic = null;
  const mergedDoc = _mergeDoc(docPublic, docUser);
  return mergedDoc;
}

function writeUserDoc(studyClass) {
  return DB.userRW().put(studyClass);
}

async function deleteMergedDoc(mergedDoc, comment) {
  mergedDoc.status = AgentContext.getCancelledState();
  mergedDoc.messageComment = comment;
  await writeUserDoc(mergedDoc);
  return DB.destroyLocalDB(DB.name.course(mergedDoc.classId));
}
/**
 * get unmerged docs from public and user db
 * @ param  {string} courseId course id
 * @return {array}          0 - from public, 1 - from user
 */
function _getCourseDocs(courseId) {
  const id = _prefix(courseId);
  return tools.Promise.all([
    DB.query()
      .byId(id)
      .catch(function() {
        return null;
      }),
    DB.userRW()
      .get(id)
      .catch(function() {
        return null;
      })
  ]).then(function(data) {
    if (!data[0] && !data[1]) {
      throw tools.pouch.notFound(courseId);
    } else {
      return {
        docPublic: data[0],
        docUser: data[1]
      };
    }
  });
}

function _notFoundErrorHandler(err) {
  if (err.status === 404) {
    return null;
  }
  logger.error(err);
}

function createDefaultStudyClass(studyClassDiff) {
  const errorFiles = _checkRequiredField(studyClassDiff);
  if (errorFiles.length !== 0) {
    throw new Error(
      `Does not get required field ${errorFiles.join(
        ', '
      )} in studyClassDiff: ${JSON.stringify(
        studyClassDiff
      )}, for create default study class`
    );
  }
  const now = Date.now();
  const classId = studyClassDiff.classId || DB.generateId();
  const _id = _prefix(classId);
  const defaultStudyClass = {
    _id: _id,
    type: 'StudyClass',

    classId: classId,
    publicationId: studyClassDiff.publicationId,
    publicationType: studyClassDiff.publicationType,
    registeredAt: now,
    modifiedAt: now,
    classType: StudyClassTypes.INSTITUTIONAL,
    name: 'StudyClass.newStudyProject',
    description: 'thisProjectWasCreatedForStudying',

    studyWeekDays: AgentContext.getDaysOfWeek(),
    allowDiscussions: !!studyClassDiff.allowDiscussions,
    allowSkipBooks: !!studyClassDiff.allowSkipBooks,
    expectedDuration: 1800000,
    cover: null,
    students: {},
    teachers: {},
    status: getProperty('studyClassStatus.active')
  };

  defaultStudyClass.teachers[DB.userId()] = {
    registeredAt: now,
    modifiedAt: now,
    active: true,
    actions: [
      {
        performedAt: now,
        actionType: getActionTypes('Teacher').classWasCreatedByTeacher,
        comment: ''
      }
    ]
  };
  return defaultStudyClass;
}

function _checkRequiredField(studyClassDiff) {
  const requiredFields = ['publicationId', 'publicationType'];
  return requiredFields.reduce((errorFiles, requiredField) => {
    if (!studyClassDiff.hasOwnProperty(requiredField)) {
      errorFiles.push(requiredField);
    }
    return errorFiles;
  }, []);
}

function getProperty(prop) {
  return tools.getValue(AgentContext.getStudyProjectConfig(), prop);
}

function getActionTypes(role) {
  return getProperty('class' + role + 'ActionTypeEnum');
}

function _prefix(id) {
  if (!('' + id).startsWith(prefix)) {
    id = prefix + id;
  }
  return id;
}

function _mergeDoc(docPublic, docUser) {
  // calculate difference between doc and docUser needed to update docPublic
  const diff = tools.diff.diff(docUser, docPublic); // the current diff
  const mergedDoc = tools.diff.joinPatches(diff, docPublic); // the actual state of document (public + user)
  return mergedDoc;
}

async function getAll() {
  const keys = [DB.userId()];
  const viewName = 'courseByUser';
  const response = await tools.Promise.all([
    DB.query().byView(DB.prefix.course, viewName, keys),
    DB.userRW().getAllByPrefix(DB.prefix.course)
  ]);
  const publicDocs = response[0];
  const userDocs = response[1];
  const mergerdStudyClasses = _mergeStudyClasses(publicDocs, userDocs);
  return mergerdStudyClasses;
}

/**
 * userDoc should be some sort of diff, actually - full record
 */
function _mergeStudyClasses(publicDocs, userDocs) {
  // join arrays
  // concat order is very important here!
  const courses = publicDocs.concat(userDocs);
  const result = courses.reduce(function(res, item) {
    if (!res[item._id]) {
      res[item._id] = item;
    } else {
      res[item._id] = tools.diff.apply(
        item,
        res[item._id],
        tools.diff.original
      );
    }
    return res;
  }, {});

  // transform object to array
  return Object.keys(result)
    .map(function(item) {
      return result[item];
    })
    .filter(function(obj) {
      return obj.type === 'StudyClass';
    });
}

//TODO move logic to the controller and the agent
export default {
  readMergedDoc: readMergedDoc,
  createMergedDoc: createMergedDoc,
  deleteMergedDoc: deleteMergedDoc,
  writeUserDoc: writeUserDoc,
  getClassById,
  getAll: getAll
};
