<template>
  <v-card-actions
    class="popup-footer"
    :class="{ divider: footerDivider, 'no-footer': noFooter }"
  >
    <BaseButton
      v-if="secondaryFooterHandler"
      class="secondary-action"
      :class="secondaryActionClass"
      :color="isNightTheme ? '#f8fafc' : secondaryFooterHandlerColor"
      :text="secondaryFooterHandlerTypeText"
      :outlined="secondaryFooterHandlerTypeOutlined"
      large
      elevation="0"
      @click="secondaryFooterHandler"
    >
      {{ secondaryLabel }}
    </BaseButton>
    <BaseButton
      v-if="primaryFooterHandler"
      :color="primaryColor"
      large
      elevation="0"
      :class="{ solo: !secondaryFooterHandler }"
      :disabled="primaryFooterHandlerDisabled"
      @click="primaryFooterHandler"
    >
      {{ primaryLabel }}
    </BaseButton>
  </v-card-actions>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

export default {
  components: { BaseButton },

  props: {
    footerDivider: {
      type: Boolean,
      required: false,
      default: true
    },
    secondaryFooterHandler: {
      type: Function,
      required: false
    },
    primaryFooterHandler: {
      type: Function,
      required: false
    },
    secondaryLabel: {
      type: String,
      required: false
    },
    primaryLabel: {
      type: String,
      required: false
    },
    primaryColor: {
      type: String,
      require: false,
      default: 'primary'
    },
    primaryFooterHandlerDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryActionClass: {
      type: String,
      required: false,
      default: ''
    },
    secondaryFooterHandlerColor: {
      type: String,
      required: false,
      default: 'black'
    },
    secondaryFooterHandlerTypeText: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryFooterHandlerTypeOutlined: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    noFooter() {
      return !this.secondaryFooterHandler && !this.primaryFooterHandler;
    },
    isNightTheme() {
      return this.$vuetify.theme.dark;
    }
  }
};
</script>

<style lang="less" src="./PopupFooter.less"></style>
