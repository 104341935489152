export default {
  ADD_TEACHER_EMAIL: 'addTeacherEmail',
  ADD_STUDENT_EMAIL: 'addStudentEmail',
  DELETE_TEACHER_EMAIL: 'deleteTeacherEmail',
  ADD_SELF_NOTIFY: 'addSelfNotify',
  ADD_NEWS_NOTIFY: 'addNewsNotify',
  SAVE_STATE: 'saveState',
  CHANGE_SELF_NOTIFY_REPORT_TYPE: 'changeSelfNotifyReportType',
  CHANGE_NEWS_NOTIFY_REPORT_TYPE: 'changeNewsNotifyReportType',
  CHANGE_TEACHER_REPORT_TYPE: 'changeTeacherReportType'
};
