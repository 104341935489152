import MaterialsUtils from '@/services/utils/MaterialsUtils';
import RestService from '@/services/RestService';
import RequestService from '@/services/RequestService';

function queryBuilder(materials, materialReqParams) {
  const query = materialReqParams.toJSON();
  query.materials = materials;
  return query;
}

function processCategoriesRequest({ categories, reqParams }) {
  const _materials = {};
  _materials.categories = getRequestMaterials(categories);
  const query = queryBuilder(_materials, reqParams);
  return RequestService.request('post', 'Materials', 'update', query);
}

function processAnnotationRequest({ annotations, reqParams }) {
  const _materials = {};
  _materials.annotations = getRequestMaterials(annotations);
  const query = queryBuilder(_materials, reqParams);
  return RequestService.request('post', 'Materials', 'update', query);
}

function processParaNoteRequest({ materials, reqParams }) {
  const _materials = {};
  _materials.comments = getRequestMaterials(materials.paragraphNotes);
  const query = queryBuilder(_materials, reqParams);
  return RestService.restRequest('post', 'Materials', 'update', query);
}

function processEssayTaskRequest({ essay }) {
  const rawEssayTask = essay.createRawMaterial();
  return RestService.restRequest(
    'post',
    'ManageEssayTask',
    'persistEssayTask',
    rawEssayTask
  );
}

function _createTtsConfig({ text, lang }) {
  return RestService.createRequestParams(
    'get',
    'Publications',
    'createTtsAudio',
    {
      text,
      lang
    }
  );
}

async function sendRequestToCreateTts({ text, lang }) {
  const requestParams = await _createTtsConfig({ text, lang });
  requestParams.responseType = 'blob';
  return RestService.sendReq(requestParams);
}

function processTestChangeRequest({ test }) {
  const rawTest = test.createRawMaterial();
  return RestService.restRequest('post', 'ManageTests', 'persistTest', rawTest);
}

function getRequestMaterials(materials) {
  const flattenedMaterials = MaterialsUtils.fromMapToArray(materials);
  return flattenedMaterials.map(material => material.createRawMaterial());
}

export default {
  queryBuilder,
  processCategoriesRequest,
  processAnnotationRequest,
  processParaNoteRequest,
  processEssayTaskRequest,
  processTestChangeRequest,
  sendRequestToCreateTts
};
