<template>
  <v-dialog
    v-if="compilation"
    v-model="isShown"
    hide-overlay
    persistent
    :width="300"
    :content-class="'book-not-downloaded-popup'"
  >
    <v-card>
      <v-card-text>
        <div class="book-not-downloaded-content">
          <div>{{ $t('CreateCompilation.addedToCompilation.label') }}</div>
          <div class="compilation-link" @click="openCompilation">
            {{ compilation.title }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ToasterEvents from '@/enums/ToasterEvents';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

const DEFAULT_POPUP_CLASS = 'book-not-downloaded-popup';
const HIDDEN_POPUP_CLASS = 'hidden-error-toaster';

const TOASTER_STATES = {
  HIDDEN: 'hidden',
  PRESENTED: 'presented'
};

export default {
  name: 'SwitchCompilationToaster',
  props: {
    toasterContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      compilationId: null,
      toasterState: TOASTER_STATES.HIDDEN
    };
  },
  computed: {
    contentClass() {
      return (
        DEFAULT_POPUP_CLASS + (!this.isShown ? ` ${HIDDEN_POPUP_CLASS}` : '')
      );
    },
    isShown() {
      return this.toasterState === TOASTER_STATES.PRESENTED;
    },
    compilation() {
      if (!this.compilationId) {
        return {};
      }
      return this.$store.getters['LibraryStore/getPublicationById'](
        this.compilationId
      );
    }
  },
  watch: {
    toasterContext(context) {
      this.$_appyContext(context);
    },

    toasterState(state) {
      switch (state) {
        case TOASTER_STATES.PRESENTED:
          this.$_triggerToasterDisapear();
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.$_appyContext(this.toasterContext);
  },
  methods: {
    async openCompilation() {
      await PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        {
          publicationId: this.compilationId
        }
      );
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.EXTRAS_POPUP
      });
    },
    $_appyContext(context) {
      const { compilationId, show } = context;
      this.compilationId = compilationId;
      this.toasterState = show
        ? TOASTER_STATES.PRESENTED
        : TOASTER_STATES.HIDDEN;
    },
    $_triggerToasterDisapear() {
      setTimeout(() => {
        this.toasterState = TOASTER_STATES.HIDDEN;
        this.$emit('toasterEvent', {
          type: ToasterEvents.WAS_HIDDEN,
          data: {}
        });
      }, 3000);
    }
  }
};
</script>

<style lang="less" src="./SwitchCompilationToaster.less"></style>
