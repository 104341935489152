const DELAY_BEFORE_NEXT_QUESTION = 1000;

class AssessmentSettings {
  constructor(settings) {
    this.timeForAnswer = settings.timeForAnswer;
    this.headerTitle = settings.headerTitle || '';
    this.delayBeforeNextQuestion = DELAY_BEFORE_NEXT_QUESTION;
    this.testId = settings.testId;
    this.isWithTimer = settings.isWithTimer;
    this.isShownProgressInsidePopup = settings.isShownProgressInsidePopup;
    this.isShownProgressInsideHeader = settings.isShownProgressInsideHeader;
    this.maxWidth = settings.maxWidth;
    this.isShownAssessmentProgress = settings.isShownAssessmentProgress;
    this.isShownButtonNext = settings.isShownButtonNext;
  }
}

class AssessmentSettingsBuilder {
  setTestId(testId) {
    this.testId = testId;
    return this;
  }

  setHeaderTitle(headerTitle) {
    this.headerTitle = headerTitle;
    return this;
  }

  setIsWithTimer(isWithTimer) {
    this.isWithTimer = isWithTimer;
    return this;
  }

  setIsShownProgressInsidePopup(isShownProgressInsidePopup) {
    this.isShownProgressInsidePopup = isShownProgressInsidePopup;
    return this;
  }

  setIsShownProgressInsideHeader(isShownProgressInsideHeader) {
    this.isShownProgressInsideHeader = isShownProgressInsideHeader;
    return this;
  }

  setMaxWidth(maxWidth) {
    this.maxWidth = maxWidth;
    return this;
  }

  setTimeForAnswer(timeForAnswer) {
    this.timeForAnswer = timeForAnswer;
    return this;
  }

  setIsShownAssessmentProgress(isShownAssessmentProgress) {
    this.isShownAssessmentProgress = isShownAssessmentProgress;
    return this;
  }

  setIsShownButtonNext(isShownButtonNext) {
    this.isShownButtonNext = isShownButtonNext;
    return this;
  }

  build() {
    return new AssessmentSettings(this);
  }
}

class AssessmentParameters {
  constructor(parameters) {
    this.id = parameters.id;
    this.type = parameters.type;
    this.vocabularyType = parameters.vocabularyType;
  }
}

class AssessmentParametersBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setType(type) {
    this.type = type;
    return this;
  }

  setVocabularyType(vocabularyType) {
    this.vocabularyType = vocabularyType;
    return this;
  }

  build() {
    return new AssessmentParameters(this);
  }
}

function createSettingsBuilder() {
  return new AssessmentSettingsBuilder();
}

function createParametersBuilder() {
  return new AssessmentParametersBuilder();
}

export default {
  createSettingsBuilder,
  createParametersBuilder
};
