import Http from '@/services/utils/Http';
import { addTrailingSlash } from '@shared/utils/url';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ShortenerService.js');

let shortenerUrl;
let branch;
let target;

function init({
  parameters: { urlShortenerDomain, branch: branchName, target: targetValue }
}) {
  shortenerUrl = urlShortenerDomain;
  branch = branchName;
  target = targetValue;
}

async function getShortUrl(url, shortenQueryParamsOnly = false) {
  try {
    const response = await Http.http({
      method: 'post',
      url: shortenerUrl,
      data: {
        url: addTrailingSlash(url),
        shortenQueryParamsOnly,
        branch,
        target
      }
    });

    return response.data.url;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}
async function getFullLink(url) {
  try {
    const reqUrl = new URL('url/', shortenerUrl).href;
    const response = await Http.http({
      method: 'get',
      url: reqUrl,
      params: { url }
    });

    return response.data.url;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

export default { init, getShortUrl, getFullLink };
