<template>
  <BaseButton v-if="buttonShown" large outlined @click="previousState"
    ><BaseSpriteIcon icon-name="ico-arrow-left-line"
  /></BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import AppStateEnum from '@/enums/AppStateEnum';
import AssessmentStatesEnum from '@/enums/AssessmentStatesEnum';

export default {
  name: 'VocabularyAssessmentPreviousButtonControl',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  computed: {
    isAssessment() {
      return this.$route.name === AppStateEnum.ASSESSMENT;
    },
    buttonShown() {
      // return (
      //   this.getCurrentAssessmentState ===
      //   AssessmentStatesEnum.USER_DATA_COLLECTION
      // );
      return false;
    },
    getCurrentAssessmentState() {
      return this.$store.getters[
        'VocabularyAssessmentStore/getCurrentAssessmentState'
      ];
    }
  },
  methods: {
    previousState() {
      const state = this.getCurrentAssessmentState;

      switch (state) {
        case AssessmentStatesEnum.USER_DATA_COLLECTION: {
          this.setStateInStore(AssessmentStatesEnum.WELCOME);
          break;
        }
      }
    },
    setStateInStore(state) {
      this.$store.dispatch(
        'VocabularyAssessmentStore/setCurrentAssessmentState',
        state
      );
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/base.less';
.vocabulary-assessment-previous-button {
  a,
  button {
    border-color: #cbd5e1;

    &.v-btn:not(.v-btn--round).v-size--large {
      padding: 0;
      min-width: 40px;

      @media @narrow {
        border-color: transparent;
      }
    }
  }
}
</style>
