import HashGenerator from '@/services/utils/HashGenerator';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LibraryStoreService.js');
import Dexie from 'dexie';

class IndexedDB {
  constructor(name, suffix = '') {
    this.db = new Dexie(`irls${HashGenerator.CRC32(name)}${suffix}`);
    return this;
  }

  initTables(tables, version) {
    this.db.version(version).stores(tables);
    return this;
  }

  async putData(tableName, data) {
    const table = this.db[tableName];
    return this.db
      .transaction('rw', table, () => {
        table.put(data);
      })
      .catch(e => {
        logger.error(e.stack || e);
      });
  }

  async removeById(tableName, id) {
    const table = this.db[tableName];
    return table.delete(id).catch(e => {
      logger.error(e.stack || e);
    });
  }

  async addData(tableName, data) {
    const table = this.db[tableName];
    return this.db
      .transaction('rw', table, () => {
        table.add(data);
      })
      .catch(e => {
        logger.error(e.stack || e);
      });
  }

  async delete(tableName) {
    const table = this.db[tableName];

    return table.clear().catch(e => {
      logger.error(e.stack || e);
    });
  }

  async get(tableName, id) {
    const table = this.db[tableName];
    return table.get(id);
  }

  getByIds(tableName, ids) {
    const table = this.db[tableName];
    return table
      .where('id')
      .anyOf(ids)
      .toArray();
  }

  putRows(tableName, items) {
    const table = this.db[tableName];
    return this.db
      .transaction('rw', table, () => table.bulkPut(items))
      .catch(e => {
        logger.error(e.stack || e);
      });
  }

  async getTableData(tableName) {
    return this.db[tableName].toArray();
  }
}

export default IndexedDB;
