import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FilesHandling.js');

import FileAccessTypeEnum from '@/enums/FileAccessTypeEnum';

const state = {
  rootPath: null,
  avaiable: true
};

function init() {
  try {
    const avaiable = process.client;
    if (!avaiable || !window.cordova) {
      state.avaiable = avaiable;
      return Promise.resolve();
    }
    return _initCordova();
  } catch (error) {
    logger.error(error);
  }
}

function _initCordova() {
  return _hasSDcardPermisions()
    .then(function() {
      return _getSDPath();
    })
    .then(function(_path) {
      state.rootPath = _path;
    })
    .catch(function(err) {
      logger.warn(err);
    });
}

function _getSDPath() {
  return new Promise(function(resolve, reject) {
    window.cordova.getSDPath('', resolve, reject);
  });
}

function _hasSDcardPermisions() {
  return new Promise(function(resolve, reject) {
    const permissions = window.cordova.plugins.permissions;
    permissions.requestPermission(
      permissions.READ_EXTERNAL_STORAGE,
      resolve,
      function(err) {
        logger.error(err);
        reject(err);
      }
    );
  });
}

function isAvailable() {
  return state.avaiable;
}

function setNamespacePrefix(prefix) {
  state.namespacePrefix = prefix;
  if (!/\/^/.test(state.namespacePrefix)) {
    state.namespacePrefix += '/';
  }
}

function getLocalPath() {
  return state.localFileSystemPrefix + state.namespacePrefix;
}

function createFileSourcePath() {}

function readFile(fileAccess, readOptions) {
  return Promise.resolve().then(function() {
    const fileSourcePath = createFileSourcePath(fileAccess);
    switch (readOptions.accessType) {
      case FileAccessTypeEnum.FULL:
        return _readFile(fileSourcePath);
      case FileAccessTypeEnum.CHUNKED:
        return _readFileByChunk(fileSourcePath, readOptions);
      default:
        throw new Error(
          `Get unsupported accessType: ${readOptions.accessType} in readFile`
        );
    }
  });
}

function _readFile(fileSourcePath) {
  return Promise.reject(
    new Error(
      `unsupported _readFile from sd card args (fileSourcePath:${fileSourcePath})`
    )
  );
}
function _readFileByChunk(fileSourcePath, readOptions) {
  return Promise.reject(
    new Error(
      `unsupported _readFileByChunk from sd card args (fileSourcePath:${fileSourcePath}, readOptions:${readOptions})`
    )
  );
}

function writeFile() {
  return Promise.reject(new Error('unsupported writeFile from sd card'));
}
function removeFile() {
  return Promise.reject(new Error('unsupported removeFile from sd card'));
}

export default {
  init,
  isAvailable,
  createFileSourcePath,
  readFile,
  writeFile,
  removeFile,
  setNamespacePrefix,
  getLocalPath
};
