<template>
  <div>
    <SectionsMenu
      :menu-sections="filteredItems"
      @sectionsMenuEvent="sectionsMenuEventHandler"
    />
  </div>
</template>

<script>
import AppMenuItemsEnum from '@/enums/AppMenuItemsEnum';

import SectionsMenu from '@/components/views/AppMenu/SectionsMenu/SectionsMenu';
import MenuItemBuilderService from '@/services/MenuItemBuilderService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import SectionsMenuEventEnum from '@/enums/SectionsMenuEventEnum';

const menuSectionsSchema = [
  AppMenuItemsEnum.LOGIN,

  AppMenuItemsEnum.PROFILE,
  AppMenuItemsEnum.HOME,
  AppMenuItemsEnum.LIBRARY_READER,
  AppMenuItemsEnum.COMPILATIONS,
  AppMenuItemsEnum.PRICING,
  // AppMenuItemsEnum.BLOG,
  AppMenuItemsEnum.ACTIVITY_DASHBOARD,
  AppMenuItemsEnum.ASSESSMENTS,
  AppMenuItemsEnum.EXPLORE,
  AppMenuItemsEnum.FAVORITES,
  AppMenuItemsEnum.SHARE,
  AppMenuItemsEnum.EXTERNAL_BLOG,

  AppMenuItemsEnum.OVERVIEW,
  AppMenuItemsEnum.EXTRAS,

  // AppMenuItemsEnum.LIBRARY_VIEW,
  // AppMenuItemsEnum.RESUME_READING,
  AppMenuItemsEnum.AFFILIATE_DASHBOARD,
  AppMenuItemsEnum.DASHBOARD,
  AppMenuItemsEnum.ACTIVITY_REPORT,

  AppMenuItemsEnum.DICTIONARY,
  AppMenuItemsEnum.FLASHCARDS,
  AppMenuItemsEnum.NEW_STUDY_PROJECT,
  AppMenuItemsEnum.CREATE_NEW_COURSE_SYLLABUS,

  AppMenuItemsEnum.MANAGE_CONTENT,
  AppMenuItemsEnum.APPLICATION_SETTINGS,
  AppMenuItemsEnum.CONTACT_US,
  // AppMenuItemsEnum.ABOUT,
  AppMenuItemsEnum.LOGOUT,
  AppMenuItemsEnum.AFFILIATES,
  AppMenuItemsEnum.LIBRARY_CONTENT_EDITOR,
  AppMenuItemsEnum.SHORTENER_EDITOR
];

export default {
  name: 'AppReaderMenu',
  components: {
    SectionsMenu
  },
  props: {
    displayContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allMenuItems: []
    };
  },
  computed: {
    filteredItems() {
      const allMenuItems = MenuItemBuilderService.createMenuSections(
        menuSectionsSchema,
        this.displayContext
      );
      const filteredItems = allMenuItems.reduce((sections, menuItem) => {
        if (menuItem.isVisible) {
          sections.push(menuItem);
        }
        return sections;
      }, []);
      return filteredItems;
    }
  },
  methods: {
    sectionsMenuEventHandler(event) {
      switch (event.type) {
        case SectionsMenuEventEnum.ITEM_SELECTED:
          this.$store.dispatch('ManagePopupStore/closePopup', {
            name: PopupNamesEnum.APP_MENU_WRAPPER
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import 'AppReaderMenu.less';
</style>
