<template>
  <div class="textarea-wrapper">
    <div class="textarea-title mb-1">
      <label :for="modifiedAttributes.labelName"
        ><span v-if="modifiedAttributes.labelName">{{
          modifiedAttributes.label
        }}</span></label
      >
      <div v-if="modifiedAttributes.counter" class="textarea-counter">
        {{ modifiedAttributes.inputTextLength || inputTextLength }} /
        {{ modifiedAttributes.counter }}
      </div>
    </div>
    <v-textarea
      class="base-text-area"
      v-bind="computedAttributes"
      v-on="inputListeners"
      @blur="trimByMaxLength"
      @keyup="trimByMaxLength"
      @paste="trimByMaxLength"
    >
      <template v-for="slot in parentSlots" #[slot]>
        <slot :name="slot" :props="props" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
import { VTextarea } from 'vuetify/lib';
import { ModifiedAttributes } from '@/classes/factories/BaseComponentsFactory';
import VTextareaOverrides from './VTextareaOverrides';

export default {
  name: 'BaseTextarea',
  components: {
    VTextarea: VTextarea.extend(VTextareaOverrides)
  },
  props: {
    props: {
      type: Object,
      required: false,
      default: () => ({})
    },
    trim: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      inputTextLength: 0,
      defaultAttributes: {
        outlined: true,
        dense: true
      },
      modifiedAttributes: new ModifiedAttributes()
    };
  },
  computed: {
    computedAttributes() {
      const incomingAttrs = this.$attrs;

      if (incomingAttrs?.label) {
        this.modifiedAttributes.setLabel(incomingAttrs.label);
        this.modifiedAttributes.setLabelName(incomingAttrs.label);
        delete incomingAttrs.label;
      }

      if (incomingAttrs?.counter) {
        this.modifiedAttributes.setCounter(incomingAttrs.counter);
        delete incomingAttrs.counter;
      }

      if (incomingAttrs?.hasOwnProperty('input-text-length')) {
        this.modifiedAttributes.setInputTextLength(
          incomingAttrs['input-text-length']
        );
        delete incomingAttrs.inputTextLength;
      }

      return {
        ...this.defaultAttributes,
        ...incomingAttrs
      };
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: value => {
          const trimVal =
            this.trim && typeof value === 'string' ? value.trim() : value;
          this.$emit('input', trimVal);
        }
      });
    },
    parentSlots() {
      return Object.keys(this.$slots);
    },
    textareaMaxLength() {
      return +this.computedAttributes?.maxlength || Infinity;
    }
  },
  mounted() {
    this.inputTextLength = this.$attrs.value?.length || 0;
  },
  methods: {
    trimByMaxLength(e) {
      let inputLength = e.target.value.length;
      if (inputLength >= this.textareaMaxLength) {
        let trimmedValue = e.target.value.substring(0, this.textareaMaxLength);
        e.target.value =
          this.trim && typeof value === 'string'
            ? trimmedValue.trim()
            : trimmedValue;
        this.$emit('input', e.target.value);
        inputLength = trimmedValue.length;
      }

      this.inputTextLength = inputLength;
    }
  }
};
</script>

<style lang="less" src="./BaseTextarea.less"></style>
