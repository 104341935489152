'use strict';

class PublicationVisibilityStatuses {
  constructor() {
    this.enum = {
      PRIMARY: 'Primary',
      BEGINNER: 'Beginner',
      ELEMENTARY: 'Elementary',
      INTERMEDIATE: 'Intermediate',
      ADVANCED: 'Advanced'
    };
    this.sortWeight = {
      [this.enum.PRIMARY]: 0,
      [this.enum.BEGINNER]: 1,
      [this.enum.ELEMENTARY]: 2,
      [this.enum.INTERMEDIATE]: 3,
      [this.enum.ADVANCED]: 4
    };

    this.difficultyRanges = [
      {
        from: 1,
        to: 1.99,
        type: this.enum.PRIMARY
      },
      {
        from: 2,
        to: 3.99,
        type: this.enum.BEGINNER
      },
      {
        from: 4,
        to: 7.99,
        type: this.enum.ELEMENTARY
      },
      {
        from: 7,
        to: 9.99,
        type: this.enum.INTERMEDIATE
      },
      {
        from: 10,
        to: 20,
        type: this.enum.ADVANCED
      }
    ];

    this.defaultRange = this.difficultyRanges[this.difficultyRanges.length - 1];
  }

  getStatusesArray() {
    return [
      this.enum.PRIMARY,
      this.enum.BEGINNER,
      this.enum.ELEMENTARY,
      this.enum.INTERMEDIATE,
      this.enum.ADVANCED
    ];
  }

  getLabelByStatus(publicationStatus, props, $t = k => k) {
    const labels = {
      [this.enum.PRIMARY]: $t('DifficultyStatus.primary.label', props),
      [this.enum.BEGINNER]: $t('DifficultyStatus.beginner.label', props),
      [this.enum.ELEMENTARY]: $t('DifficultyStatus.elementary.label', props),
      [this.enum.INTERMEDIATE]: $t(
        'DifficultyStatus.intermediate.label',
        props
      ),
      [this.enum.ADVANCED]: $t('DifficultyStatus.advanced.label', props)
    };
    return labels[publicationStatus];
  }
  getEnum() {
    return this.enum;
  }

  getRange(difficultyVal) {
    const difficultyRange = this.difficultyRanges.find(_difficultyRange => {
      return (
        difficultyVal >= _difficultyRange.from &&
        difficultyVal <= _difficultyRange.to
      );
    });
    return difficultyRange;
  }

  getSortWeight(val) {
    const difficultyRange = this.getRange(val);
    const weight = difficultyRange ? val : this.getDefaultVal();
    return weight;
  }

  getDefaultType() {
    return this.defaultRange.type;
  }

  getDefaultVal() {
    return this.defaultRange.to;
  }
}

module.exports = new PublicationVisibilityStatuses();
