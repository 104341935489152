import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('SubscriptionStore.js');

const EMAIL_LIST_LIMIT = 5;
import SubscribeFactory from '@/classes/factories/SubscribeFactory';
import RestService from '@/services/RestService';
import SubscriptionEventEnum from '@/enums/SubscriptionEventEnum';
// import PostMessagesEnum from '@shared/enums/PostMessagesEnum';
// initial state

const initState = () => {
  return {
    pristine: true,
    userId: '',
    emailList: [],
    selfNotify: false,
    newsNotify: false,
    selfNotifyReportType: '',
    newsNotifyReportType: '',
    unsubscribePageUrl: ''
  };
};

function putDataForSubscription(updateRequestData) {
  return RestService.restRequest(
    'put',
    'Subscribe',
    'userSubscribe',
    updateRequestData
  );
}

function isAllowAddEmail(emailList, email) {
  return (
    emailList.length < EMAIL_LIST_LIMIT &&
    !emailList.some(item => item.email === email)
  );
}

// getters
const storeGetters = {
  getSubscriptionUserId(state) {
    return state.userId;
  },
  isPristine(state) {
    return state.pristine;
  },
  getUserSubscriptionView(state) {
    const builder = SubscribeFactory.getUserSubscriptionViewBuilder();
    return builder
      .setEmails(state.emailList)
      .setSelfNotify(state.selfNotify)
      .setNewsNotify(state.newsNotify)
      .setSelfNotifyReportType(state.selfNotifyReportType)
      .setNewsNotifyReportType(state.newsNotifyReportType)
      .build();
  },
  getUnsubscribePageUrl(state) {
    return state.unsubscribePageUrl;
  }
};

// actions
const actions = {
  async updateSubscribe({ state }, payload) {
    const { type, data } = payload;
    let updateRequest;
    const userId = state.userId;
    switch (type) {
      case SubscriptionEventEnum.ADD_TEACHER_EMAIL:
        updateRequest = SubscribeFactory.createAddEmailRequest(
          [data.email],
          userId
        );
        break;
      case SubscriptionEventEnum.ADD_STUDENT_EMAIL:
        updateRequest = SubscribeFactory.createAddEmailRequest(
          [data.email],
          data.userId
        );
        break;
      case SubscriptionEventEnum.DELETE_TEACHER_EMAIL:
        updateRequest = SubscribeFactory.createDeleteEmailRequest(
          [data.email],
          userId
        );
        break;
      case SubscriptionEventEnum.ADD_SELF_NOTIFY:
        updateRequest = SubscribeFactory.createSelfNotifyRequest(
          data.selfNotify,
          userId
        );
        break;
      case SubscriptionEventEnum.ADD_NEWS_NOTIFY:
        updateRequest = SubscribeFactory.createNewsNotifyRequest(
          data.newsNotify,
          userId
        );
        break;
      case SubscriptionEventEnum.CHANGE_SELF_NOTIFY_REPORT_TYPE:
        updateRequest = SubscribeFactory.createSelfNotifyReportTypeRequest(
          data.reportType,
          userId
        );
        break;
      case SubscriptionEventEnum.CHANGE_NEWS_NOTIFY_REPORT_TYPE:
        updateRequest = SubscribeFactory.createNewsNotifyReportTypeRequest(
          data.reportType,
          userId
        );
        break;
      case SubscriptionEventEnum.CHANGE_TEACHER_REPORT_TYPE:
        updateRequest = SubscribeFactory.createTeacherReportTypeRequest(
          data.email,
          data.reportType,
          userId
        );
        break;
      case SubscriptionEventEnum.SAVE_STATE:
        updateRequest = SubscribeFactory.createUserSubscribeRequest(
          state.emailList,
          state.selfNotify,
          state.newsNotify,
          userId
        );
        break;
      default:
        logger.error(`Unsupported subscribe event:${type}`);
        break;
    }
    if (!updateRequest) {
      return Promise.reject();
    }

    return putDataForSubscription(updateRequest);
  },
  changeSelfNotify({ commit }, selfNotify) {
    commit('setSelfNotify', selfNotify);
    commit('setPristine', false);
  },
  changeNewsNotify({ commit }, newsNotify) {
    commit('setNewsNotify', newsNotify);
    commit('setPristine', false);
  },
  changeSelfNotifyReportType({ commit }, reportType) {
    commit('setSelfNotifyReportType', reportType);
    commit('setPristine', false);
  },
  changeNewsNotifyReportType({ commit }, reportType) {
    commit('setNewsNotifyReportType', reportType);
    commit('setPristine', false);
  },
  changeTeacherReportType({ commit }, payload) {
    const { email, reportType } = payload;
    commit('setTeacherReportType', { email, reportType });
    commit('setPristine', false);
  },
  async fillStore({ commit }, userId) {
    const subscriptionEmails = await RestService.restRequest(
      'get',
      'Subscribe',
      'userSubscribe',
      { userId }
    );
    const preparedEmailList = subscriptionEmails.data.teacherSubscribe.map(
      subscriptionData => {
        return {
          email: subscriptionData.email,
          reportType: subscriptionData.reportType
        };
      }
    );
    const selfNotify = subscriptionEmails.data.selfSubscribe.length !== 0;
    const selfNotifyReportType =
      (selfNotify && subscriptionEmails.data.selfSubscribe[0].reportType) || '';
    const newsNotify = subscriptionEmails.data.newsSubscribe.length !== 0;
    const newsNotifyReportType =
      (newsNotify && subscriptionEmails.data.newsSubscribe[0].reportType) || '';
    commit('setUserId', userId);
    commit('setEmailList', preparedEmailList);
    commit('setSelfNotify', selfNotify);
    commit('setNewsNotify', newsNotify);
    commit('setSelfNotifyReportType', selfNotifyReportType);
    commit('setNewsNotifyReportType', newsNotifyReportType);
  },
  deleteEmail({ commit }, email) {
    commit('deleteItemEmailList', email);
  },
  addEmail({ state, commit }, email) {
    let isValid = false;
    const isAllow = isAllowAddEmail(state.emailList, email);
    if (isAllow) {
      commit('addEmailList', email);
      isValid = true;
    }
    commit('setPristine', false);
    return isValid;
  },
  addTask(_, payload) {
    return RestService.restRequest(
      'post',
      'Subscribe',
      'subscribeTask',
      payload
    );
  },
  async openEmailPreferences({ dispatch, getters }) {
    if (!getters.getUnsubscribePageUrl) {
      await dispatch('fetchEmailPreferencesLink');
    }

    dispatch(
      'ContextStore/openUrl',
      { url: getters.getUnsubscribePageUrl, unsecure: true },
      { root: true }
    );
    // addCloseEmailPreferencesListener(
    //   emailPreferencesWindow,
    //   () => {
    //     dispatch('fillStore', userId);
    //   },
    //   isDevice
    // );
  },
  async fetchEmailPreferencesLink({ commit, rootGetters }) {
    const userId = rootGetters['UserStore/getUserId'];
    const response = await RestService.restRequest(
      'get',
      'Subscribe',
      'unsubscribePageUrl',
      { userId }
    );
    const unsubscribePageUrl = response?.data?.url;
    if (unsubscribePageUrl) {
      commit('setUnsubscribePageUrl', unsubscribePageUrl);
    }
  }
};

// mutations
const mutations = {
  setEmailList(state, emailList) {
    state.emailList = emailList;
  },
  addEmailList(state, email) {
    const buildEmail = SubscribeFactory.getTeacherSubscriptionViewBuilder()
      .setEmail(email)
      .build();
    state.emailList.push(buildEmail);
  },
  deleteItemEmailList(state, email) {
    const index = state.emailList.findIndex(item => item.email === email);
    const newState = [...state.emailList];
    newState.splice(index, 1);
    state.emailList = newState;
  },
  setSelfNotifyReportType(state, reportType) {
    state.selfNotifyReportType = reportType || '';
  },
  setNewsNotifyReportType(state, reportType) {
    state.newsNotifyReportType = reportType || '';
  },
  setSelfNotify(state, selfNotify) {
    state.selfNotify = selfNotify;
  },
  setNewsNotify(state, newsNotify) {
    state.newsNotify = newsNotify;
  },
  setTeacherReportType(state, payload) {
    const { email, reportType } = payload;
    const index = state.emailList.findIndex(item => item.email === email);
    const newState = [...state.emailList];
    newState[index].reportType = reportType;
    state.emailList = newState;
  },
  setUserId(state, userId) {
    state.userId = userId;
  },
  setPristine(state, pristine) {
    state.pristine = pristine;
  },
  resetStore(state) {
    const newSate = initState();
    Object.keys(state).forEach(key => {
      state[key] = newSate[key];
    });
  },
  setUnsubscribePageUrl(state, url) {
    state.unsubscribePageUrl = url;
  }
};

// function addCloseEmailPreferencesListener(windowName, callback, isDevice) {
//   const listener = event => {
//     if (event) {
//       const eventData = event.data || event.detail;
//       if (eventData.type === PostMessagesEnum.EMAIL_PREFERENCES_CHANGED) {
//         callback();
//       }
//     }
//   };
//
//   window.addEventListener('message', listener);
//
//   const windowOpenChecker = setInterval(() => {
//     if (!isDevice && (!windowName || windowName.closed)) {
//       clearInterval(windowOpenChecker);
//       window.removeEventListener('message', listener);
//     }
//   }, 1000);
// }

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
