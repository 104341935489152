<template>
  <a v-if="isBlogApp" :id="uniqueId" @click="toAbout">
    <slot name="back-to-about">{{ $t('ManagePublications.about.label') }}</slot>
  </a>
  <NuxtLink
    v-else-if="link"
    :to="{ name: MANAGE_ABOUT }"
    :aria-label="$t('Accessibility.home')"
    @click.native.prevent
  >
    <slot name="back-to-about">{{ $t('ManagePublications.about.label') }}</slot>
  </NuxtLink>
  <BaseButton
    v-else
    :to="{ name: MANAGE_ABOUT }"
    class="px-3"
    text
    nuxt
    @click.native.prevent="toAbout"
  >
    <slot name="back-to-about">{{ $t('ManagePublications.about.label') }}</slot>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'BackToAboutControl',
  components: {
    BaseButton
  },
  props: {
    link: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const isDevice = this.$store.getters['ContextStore/isDevice'];
    const isElectron = this.$store.getters['ContextStore/isElectron'];

    return {
      MANAGE_ABOUT: AppStateEnum.MANAGE_ABOUT,
      isDevice,
      isElectron,
      uniqueId: `about-link-${this._uid}`
    };
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toAbout() {
      if (!this.isBlogApp) {
        this.$router.push({ name: AppStateEnum.MANAGE_ABOUT });
        return;
      }

      const clientReaderUrl = this.$store.getters[
        'ContextStore/getClientReaderUrl'
      ];
      const aboutLink = `${clientReaderUrl}about`;

      if (this.isDevice || this.isElectron) {
        this.$store.dispatch('ContextStore/openUrl', {
          url: aboutLink
        });
        return;
      }

      const aboutLinkEl = document?.getElementById(this.uniqueId);
      if (aboutLinkEl) {
        aboutLinkEl.href = aboutLink;
        aboutLinkEl.target = '_blank';
      }
    }
  }
};
</script>

<style lang="less" src="./BackToAboutControl.less"></style>
