import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('Annotation.js');
const NS = 'nota';

/**
 * get styles for all categories
 * @param {Array} categories
 * @param {Function} annotationClassGetter - func to get class by category
 * @returns {Array} - all annotations styles
 */
function getCategoryStyles(categories, annotationClassGetter) {
  let styles = {};
  categories.forEach(category => {
    const className = '.' + annotationClassGetter(category.id);
    const style = getCategoryStyle(category, className);
    styles = { ...style, ...styles };
  });
  return styles;
}

function getCategoryStyle(category, className) {
  const styles = {};
  const readerClass = getReaderAnnotationClass();
  const classNightName = '.night-theme-template ' + className;
  const textColor = _getTextColor(category.color.slice(1));
  const textNightColor = _getTextColor(category.nightColor.slice(1));
  const underline = _getUnderline(category);

  styles[className] = {};
  styles[classNightName] = {};
  if (category.underline) {
    styles[`${className}.${readerClass}:not(li)`] = {
      background: 'transparent'
    };
    styles[className] = {
      boxShadow: underline + category.color
    };
    styles[`.night-theme-template .present-publication ${className}`] = {
      boxShadow: underline + category.nightColor
    };
    styles[`${className}.icon`] = {
      background: category.color
    };
    styles[`.night-theme-template ${className}.icon`] = {
      background: category.nightColor
    };
  } else {
    styles[className].boxShadow = 'none';
    styles[className].color = 'black';
    styles[className].backgroundColor = category.color;
    styles[`.night-theme-template .present-publication ${className}`] = {};
    styles[`.night-theme-template .present-publication ${className}`].color =
      '#bfbfbf';
    let gradientFirstLetter =
      'linear-gradient(to bottom,rgba(X,1) 0%,rgba(X,1) 15%,rgba(X,1) 35%,rgba(X,1) 80%,rgba(X,1) 95%,rgba(X,1) 100%)';
    let gradient =
      'linear-gradient(to bottom,rgba(X,1) 0%,rgba(X,1) 25%,rgba(X,1) 45%,rgba(X,1) 60%,rgba(X,1) 85%,rgba(X,1) 100%)';

    styles[`${className}.${readerClass}:not(li)`] = {
      background: gradient.replace(/X/g, textColor.rgb.join(','))
    };
    styles[`.bh-first-letter ${className}.${readerClass}`] = {
      background: gradientFirstLetter.replace(/X/g, textColor.rgb.join(','))
    };
    styles[`${classNightName}.${readerClass}:not(li)`] = {
      background: gradient.replace(/X/g, textNightColor.rgb.join(','))
    };
    styles[
      `.night-theme-template .bh-first-letter ${className}.${readerClass}`
    ] = {
      background: gradientFirstLetter.replace(
        /X/g,
        textNightColor.rgb.join(',')
      )
    };
  }
  styles[`.annotation-icon ${className}`] = {
    color: category.color,
    boxShadow: 'none',
    background: 'none'
  };
  styles[`.night-theme-template .annotation-icon ${className}`] = {
    color: category.nightColor
  };
  styles[`${className} .icon`] = {
    color: 'none',
    boxShadow: 'none',
    background: category.color
  };
  styles[`.night-theme-template ${className}`] = {
    background: category.nightColor,
    boxShadow: underline + category.nightColor
  };
  styles[`.night-theme-template ${className} .icon`] = {
    background: category.nightColor
  };

  return styles;
}

function _getTextColor(backgroundColor) {
  const rgb = backgroundColor.split(/(?=(?:..)+$)/).map(function(color) {
    return parseInt(color, 16);
  });
  // counting the perceptive luminance - human eye favors green color
  const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
  const color = luminance > 0.5 ? 'black' : 'white';
  return {
    rgb: rgb,
    color: color
  };
}

function _getUnderline(item) {
  const markerlUnderline = 'inset 0 -3px 0 ';
  const pencilUnderline = 'inset 0 -2px 0 ';
  const penUnderline = 'inset 0 -1px 0 ';
  switch (item.underline) {
    case 'penUnderline':
      return penUnderline;
    case 'pencilUnderline':
      return pencilUnderline;
    case 'markerlUnderline':
      return markerlUnderline;
  }
}

function initMaterialsByBlockId(materialItems) {
  const materialItemsByBlockId = {};
  materialItems.forEach(rawMaterialItem => {
    const materialItem = MaterialsFactory.createMaterialByType({
      type: rawMaterialItem.type,
      material: rawMaterialItem
    });
    if (!materialItem) {
      logger.warn(
        `Get unsupported material type ${rawMaterialItem.type} id: ${rawMaterialItem.id}`
      );
      return;
    }
    const blockId = materialItem.blockId;
    if (!materialItemsByBlockId[blockId]) {
      materialItemsByBlockId[blockId] = [];
    }
    materialItemsByBlockId[blockId].push(materialItem);
  });
  return materialItemsByBlockId;
}

function indexToAddAnnotation(annToAdd, annotations) {
  const isEmpty = !annotations.length;
  const index = isEmpty
    ? 0
    : annotations.findIndex(
        annotation =>
          annotation.start.logicalCharOffset > annToAdd.start.logicalCharOffset
      );
  return index >= 0 ? index : annotations.length;
}

function getGenericAnnotationClass() {
  return NS + '-annotation';
}

function getReaderAnnotationClass() {
  return getGenericAnnotationClass() + '-reader';
}

export default {
  getCategoryStyles,
  getCategoryStyle,
  initMaterialsByBlockId,
  indexToAddAnnotation,
  getReaderAnnotationClass,
  getGenericAnnotationClass
};
