import { RemoteAssetReader } from './RemoteAssetReader';
import { LocalAssetReader } from './LocalAssetReader';
import TrackingItem from './TrackingItem';

function createRemoteAssetReader(coverExtension, audioExtension) {
  return new RemoteAssetReader(coverExtension, audioExtension);
}

function createLocalAssetReader(coverExtension, audioExtension) {
  return new LocalAssetReader(coverExtension, audioExtension);
}

function createTrackingItem(bookId) {
  return TrackingItem.createPublicationTrackingItem(bookId);
}

function getPublicationTrackingItemViewBuilder() {
  return TrackingItem.getPublicationTrackingItemViewBuilder();
}

export default {
  createRemoteAssetReader,
  createLocalAssetReader,
  getPublicationTrackingItemViewBuilder,
  createTrackingItem
};
