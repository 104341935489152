<template>
  <BackToAboutControl :title="$t('Accessibility.logo.tooltip')">
    <template #back-to-about>
      <BaseSpriteIcon custom-class="logo-icon" :icon-name="iconName" />
    </template>
  </BackToAboutControl>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BackToAboutControl from '@/components/controls/BackToAboutControl/BackToAboutControl';

export default {
  name: 'LogoControl',
  components: {
    BaseSpriteIcon,
    BackToAboutControl
  },
  computed: {
    isNarrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    iconName() {
      const brand = this.$store.getters['ContextStore/brand'];
      return `ico-logo-${brand}`;
    }
  }
};
</script>

<style lang="less" src="./LogoControl.less"></style>
