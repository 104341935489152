import EnvConfig from '../../../config/product/env/env-config-client.json';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AppConfigService.js');

import Http from './utils/Http';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';

const LOCAL_CONFIG = 'localConfig';

const CLIENT_CONFIG = 'app-client.json';
const SHARED_CONFIG = 'app-shared.json';

function loadEnvConfig(serverUrl, fileName, application) {
  const host = serverUrl || '../';
  const path = application.toLowerCase() + '/';
  return _loadRemoteConfig(host + path + 'config/product/env/' + fileName);
}

function loadSharedParameters(serverUrl, application) {
  return loadConfig(serverUrl, application, SHARED_CONFIG);
}

async function loadArtifactUrlConfig(artifactUrl) {
  const artifactConfig = 'ArtifactConfig';
  let config;
  try {
    config = await _loadRemoteConfig(artifactUrl);
  } catch (error) {
    config = _getLocalConfig(artifactConfig) || _buildDummyArtifactsConfig();
  }
  _setLocalConfig(artifactConfig, config);
  return config;
}

function _buildDummyArtifactsConfig() {
  const config = {};
  const brands = ['ffa', 'ocean'];
  brands.forEach(brand => {
    config[brand] = {
      artifacts: {
        android: '',
        ios: '',
        electron_win: '',
        electron_linux: '',
        electron_mac: ''
      }
    };
  });

  return config;
}

function loadClientParameters(serverUrl, application) {
  return loadConfig(serverUrl, application, CLIENT_CONFIG);
}

function loadEnvParams(envParameters) {
  return Promise.resolve(envParameters || EnvConfig);
}

function getClientConfig() {
  return EnvConfig;
}

async function loadConfig(serverUrl, application, configName) {
  let config;
  try {
    config = await _loadAppRemoteConfig(serverUrl, application, configName);
    _setLocalConfig(configName, config);
  } catch (error) {
    config = _getLocalConfig(configName);
  }
  return config;
}

function _loadAppRemoteConfig(serverUrl, application, configName) {
  const host = serverUrl || '../';
  const path = application.toLowerCase() + '/';
  return _loadRemoteConfig(host + path + 'config/product/app/' + configName);
}

function _loadRemoteConfig(configUrl) {
  return Http.get(configUrl).then(function(response) {
    return response.data;
  });
}

function _getLocalConfig(configName) {
  return UnifiedSettingsService.getSetting(LOCAL_CONFIG, configName);
}

function _setLocalConfig(configName, config) {
  UnifiedSettingsService.setSetting(LOCAL_CONFIG, configName, config);
}

async function sendHealthCheck(serverUrl) {
  const healthcheckUrl = `${serverUrl}healthcheck`;
  try {
    await Http.get(healthcheckUrl);
  } catch (error) {
    logger.fatal(`Healthcheck error: ${error}`);
    throw error;
  }
}

export default {
  sendHealthCheck,
  loadSharedParameters,
  loadClientParameters,
  loadEnvParams,
  loadEnvConfig,
  getClientConfig,
  loadArtifactUrlConfig
};
