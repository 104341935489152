import PwaEventsEnum from '@/enums/PwaEventsEnum.js';
import Utils from '@/services/utils/Utils';

import LoggerFactory from '@/services/utils/LoggerFactory.js';
const logger = LoggerFactory.getLogger('PwaService.js');

const cachableRoutes = [
  'compilations',
  'extracts',
  'pricing',
  'about',
  'search'
];

function cachePage({ route, store }) {
  if (!process.client || !cachableRoutes.includes(route.name)) {
    return;
  }

  const isOnline = store.getters['ContextStore/isOnline'];
  if (isOnline && _isServiceWorkerAccessAllowed()) {
    const clientReaderUrl = store.getters['ContextStore/getClientReaderUrl'];
    const url = _getPageUrl(clientReaderUrl, route.name);
    _sendMessageToServiceWorker({
      type: PwaEventsEnum.CACHE_URLS,
      payload: {
        urlsToCache: [url]
      }
    });
  }
}

function cachePageOnServiceWorkerInstalled({ route, store }) {
  if (!process.client) {
    return;
  }

  const isOnline = store.getters['ContextStore/isOnline'];
  if (isOnline && _isServiceWorker()) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      cachePage({
        route,
        store
      });
    });
  }
}

function _isServiceWorker() {
  try {
    return 'serviceWorker' in navigator;
  } catch (error) {
    logger.error(`Check service worker support failed with error: ${error}`);
    return false;
  }
}

function _isServiceWorkerAccessAllowed() {
  return _isServiceWorker() && navigator?.serviceWorker?.controller;
}

function _sendMessageToServiceWorker(message) {
  try {
    navigator?.serviceWorker?.controller?.postMessage(message);
  } catch (error) {
    logger.error(`Send message to service worker failed with error: ${error}`);
  }
}

function _getPageUrl(clientReaderUrl, pageName) {
  const urlObj = new URL(clientReaderUrl);
  urlObj.pathname = Utils.pathJoin([urlObj.pathname, pageName]);
  return urlObj.href + '/';
}

export default {
  cachePage,
  cachePageOnServiceWorkerInstalled
};
