<template>
  <div ref="progress-line" class="progress-line"></div>
</template>

<script>
import FakeProgressLineTaskEnum from '@/enums/FakeProgressLineTaskEnum';

export default {
  name: 'FakeProgressLine',
  props: {
    task: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    task(newTask) {
      switch (newTask) {
        case FakeProgressLineTaskEnum.START_PROGRESS:
          this.startProgress();
          break;
        case FakeProgressLineTaskEnum.STOP_PROGRESS:
          this.stopProgress();
          break;
        default:
          break;
      }
    }
  },
  methods: {
    startProgress() {
      const progressEl = this.$_getProgressElement();
      progressEl.style.height = '4px';
      const duration = 20 * 1000;
      const step = 100;
      const numIterations = Math.round(duration / step);
      const progressBorder = 80;
      const deltaWidth = progressBorder / numIterations;
      let count = 0;
      this.progressInterval = setInterval(() => {
        count += 1;
        if (count === numIterations) {
          clearInterval(this.progressInterval);
          return;
        }
        let currentWidth = parseFloat(
          (progressEl.style.width || '').replace('%', '')
        );
        currentWidth = isNaN(currentWidth) ? 0 : currentWidth;
        currentWidth += deltaWidth;
        progressEl.style.width = `${currentWidth}%`;
      }, step);
    },

    async stopProgress() {
      const progressEl = this.$_getProgressElement();
      clearInterval(this.progressInterval);
      progressEl.style.width = '100%';
      await this.$nextTick();
      progressEl.style.width = '0%';
      progressEl.style.height = '1px';
    },

    $_getProgressElement() {
      return this.$refs['progress-line'];
    }
  }
};
</script>

<style lang="less" src="./FakeProgressLine.less"></style>
