import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PublicationScrollLogicService.js');

import Locator from '@shared/publication/locator.mjs';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import BookUtils from '@shared/publication/book-utils.mjs';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import scrollUtils from '@/components/views/BookScroll/scrollUtils.js';
import Utils from '@/services/utils/Utils';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';

function createScrubberLocatorStruct(store, serializedLocator) {
  const locator = Locator.deserialize(serializedLocator);
  const wordElement = BookUtils.getWordDomWrapper(locator);
  const para = document.getElementById(
    locator.startLocator.prefixedParagraphId
  );
  const element = wordElement ? wordElement : para;
  const range = document.createRange();
  const emptyRect = range.getBoundingClientRect();

  const rect = element ? element.getBoundingClientRect() : emptyRect;
  const getIsInsideReadingArea = loc =>
    store.getters['ProgressStore/isInsideReadingArea'](loc);

  const locatorScruct = {
    serializedLocator,
    locator,
    element,
    rect,
    getIsInsideReadingArea,
    getParaId() {
      return this.locator.startLocator.prefixedParagraphId;
    },
    getTop() {
      return Math.ceil(this.rect.top);
    },
    getHeight() {
      return Math.round(this.rect.height);
    },
    getTopDist(toLocatorStruct) {
      return Math.abs(this.getTop()) - Math.abs(toLocatorStruct.getTop());
    },
    getTopDistNoRound(toLocatorStruct) {
      return Math.abs(this.rect.top) - Math.abs(toLocatorStruct.rect.top);
    },
    getLocator() {
      return this.locator;
    },
    isInsideViewPort() {
      return this.isInsideBottomBorder() && this.isInsideTopBorder();
    },
    isInsideBottomBorder() {
      const safeAreaBottom = Utils.getSafeAreaInsetBottom();

      const currentProgressToolbarHeight = getProgressToolbarHeight();
      const isInsideBottomBorder =
        window.innerHeight -
          currentProgressToolbarHeight -
          safeAreaBottom -
          this.getTop() -
          this.getHeight() >
        0;
      return isInsideBottomBorder;
    },
    isInsideTopBorder() {
      const safeAreaTop = Utils.getSafeAreaInsetTop();
      const isInsideTopBorder =
        this.getTop() - SelectionConstantUtils.TOOLBAR_HEIGHT - safeAreaTop >=
        0;
      return isInsideTopBorder;
    },
    isInsideReadingArea() {
      return this.getIsInsideReadingArea(this.locator.startLocator);
    },
    isPartiallyInsideViewPortTop() {
      return (
        this.getTop() + this.getHeight() >
          SelectionConstantUtils.TOOLBAR_HEIGHT &&
        this.getTop() < SelectionConstantUtils.TOOLBAR_HEIGHT
      );
    }
  };
  return locatorScruct;
}

function getProgressToolbarHeight() {
  const progressToolbar = document.querySelector(
    `.${AppConstantsUtil.PROGRESS_TOOLBAR_CLASS_NAME}`
  );
  const currentProgressToolbarHeight = progressToolbar
    ? progressToolbar.getBoundingClientRect().height
    : SelectionConstantUtils.PROGRESS_TOOLBAR_HEIGHT;
  return currentProgressToolbarHeight;
}

function stopAnimateScroll({ getters, commit }) {
  const animateScroll = getters['PublicationStore/getAnimateScroll'];
  if (animateScroll) {
    animateScroll.stop();
    commit('PublicationStore/setAnimateScroll', null);
  }
}

function getDelayMs(delta) {
  const SCROLL_SPEED = 0.2;
  const MAX_DELAY_TIME = 5000;
  let delayMs = Math.abs(Math.round(delta / SCROLL_SPEED));
  return Math.min(delayMs, MAX_DELAY_TIME);
}

function animatedScrollToDelta(store, delta, scrollSpeed) {
  const animationElementSelector =
    store.getters['PublicationStore/getScrollElementSelector'];
  const safeAreaInsetTop = Utils.getSafeAreaInsetTop();

  const bookScroll = _getScrollElement(store);
  const offset = bookScroll.scrollTop + delta - safeAreaInsetTop;

  const { promise, animateScroll } = scrollUtils.animatedScroll(
    animationElementSelector,
    offset,
    scrollSpeed || getDelayMs(delta)
  );
  store.commit('PublicationStore/setAnimateScroll', animateScroll);
  return promise;
}

function _getScrollElement(store) {
  const scrollClassName = store.getters['PublicationStore/getScrollClassName'];
  return document.querySelector(`.${scrollClassName}`);
}

function getViewportHeight(store) {
  const { height } = store.getters['PublicationStore/getViewportRect']();
  const safeAreaTop = Utils.getSafeAreaInsetTop();
  const safeAreaBottom = Utils.getSafeAreaInsetBottom();
  const currentProgressToolbarHeight = getProgressToolbarHeight();
  return (
    height -
    SelectionConstantUtils.TOOLBAR_HEIGHT -
    currentProgressToolbarHeight -
    safeAreaTop -
    safeAreaBottom
  );
}

function getPartlyVisibleElHeight(elTop, elHeight) {
  return window.innerHeight - elTop - elHeight;
}

function getScrollHeightForNextParaLine(store, newLocatorStruct) {
  const nextElHeight = newLocatorStruct.getHeight();
  const nextElTop = newLocatorStruct.getTop();
  const isNextElPartlyVisible =
    nextElTop < window.innerHeight - SelectionConstantUtils.TOOLBAR_HEIGHT;
  const elHeight = isNextElPartlyVisible
    ? getPartlyVisibleElHeight(nextElTop, nextElHeight)
    : nextElHeight;

  const viewportHeight = getViewportHeight(store);
  return viewportHeight - elHeight;
}

function getScrollHeightForNextParaFarInDOM(newLocatorStruct) {
  const safeAreaTop = Utils.getSafeAreaInsetTop();
  return (
    newLocatorStruct.getTop() -
    SelectionConstantUtils.TOOLBAR_HEIGHT -
    safeAreaTop
  );
}

async function applyScrollOnScrubberChange(
  store,
  router,
  newScrubberLocator,
  oldScrubberLocator,
  isOutOfBackground
) {
  const isAudioStartPlaying =
    store.getters['PublicationStore/getIsAudioStartPlaying'];
  const skipCurrentLocatorChange =
    store.getters['PublicationStore/getSkipCurrentLocatorChange'];
  _processAnnSelectionWhileListening(store);
  const isAudioPlaying = store.getters['PlaybackStore/isAudioPlaying'];
  const isAppInBackground = store.getters['ContextStore/isAppInBackground'];
  if (
    !isAudioPlaying ||
    isAppInBackground ||
    isOutOfBackground ||
    !newScrubberLocator
  ) {
    return;
  }

  const paraPlayButtonPressed =
    store.getters['PublicationStore/getParaPlayButtonPressed'];
  if (paraPlayButtonPressed) {
    store.commit('PublicationStore/setParaPlayButtonPressed', false);
    return;
  }

  const isFabButtonPressed =
    store.getters['PublicationStore/getFabButtonPressed'];
  if (isFabButtonPressed) {
    store.commit('PublicationStore/setFabButtonPressed', false);
    return;
  }

  const scrollContainer = store.getters['PublicationStore/getScrollContainer'];
  if (!scrollContainer) {
    logger.error(
      `scrollContainer does not inited before position scrubber inside book`
    );
    return;
  }
  const newLocatorStruct = createScrubberLocatorStruct(
    store,
    newScrubberLocator
  );

  const newParaId = newLocatorStruct.getParaId();
  const isParagraphDisabled = store.getters[
    'PublicationStore/isParagraphDisabled'
  ](newParaId);
  if (isParagraphDisabled) {
    store.dispatch('PlaybackStore/hideScrubber');
    store.dispatch('PlaybackStore/pause');
    store.dispatch('UserStore/openLimitedAccessPopup');
    return;
  }

  if (
    newLocatorStruct.isInsideReadingArea() &&
    isAudioStartPlaying &&
    skipCurrentLocatorChange
  ) {
    store.commit('PublicationStore/setIsAudioStartPlaying', false);
    store.commit('PublicationStore/setSkipCurrentLocatorChange', false);

    return;
  }

  if (isAudioStartPlaying) {
    store.commit('PublicationStore/setIsAudioStartPlaying', false);

    return;
  }

  const publicationId = _getPublicationId(store);
  oldScrubberLocator =
    oldScrubberLocator ||
    _tryRestorePrevLocator(
      store,
      publicationId,
      newLocatorStruct.locator.startLocator
    ) ||
    _getThirdScreenAudioRangeLocator(store);

  if (!oldScrubberLocator) {
    return;
  }
  let oldLocatorStruct = createScrubberLocatorStruct(store, oldScrubberLocator);

  const isPageInDefaultScrollingMode =
    store.getters['ReadingSettingsStore/getIsPageInDefaultScrollingMode'];
  if (isPageInDefaultScrollingMode) {
    if (store.getters['ContextStore/getIsScrolling']) {
      return;
    }
    if (
      (oldLocatorStruct.isInsideViewPort() &&
        !newLocatorStruct.isInsideViewPort()) ||
      (!oldLocatorStruct.isInsideBottomBorder() &&
        !newLocatorStruct.isInsideBottomBorder()) ||
      (!oldLocatorStruct.isInsideTopBorder() &&
        !newLocatorStruct.isInsideTopBorder())
    ) {
      const oldParaId = oldLocatorStruct.getParaId();
      const isSamePara = oldParaId === newParaId;
      const delta = isSamePara
        ? getScrollHeightForNextParaLine(store, newLocatorStruct)
        : getScrollHeightForNextParaFarInDOM(newLocatorStruct);

      const isAnyNotAudioContent =
        Math.abs(
          newLocatorStruct.locator.startLocator._paragraphNumber -
            oldLocatorStruct.locator.startLocator._paragraphNumber
        ) > 1;
      const scrollSpeed = isAnyNotAudioContent
        ? AppConstantsUtil.NOT_AUDIO_CONTENT_SCROLL_SPEED
        : AppConstantsUtil.TEXT_SCROLL_SPEED;

      updateIsScrolling(store, true);
      await animatedScrollToDelta(store, delta, scrollSpeed);
      updateIsScrolling(store, false);
    }

    return;
  }

  if (
    !newLocatorStruct.isInsideReadingArea() &&
    !oldLocatorStruct.isInsideReadingArea()
  ) {
    return;
  }

  const handlers = [
    _handlingScrubberSameLine,
    _handlingScrubberNextLine,
    _handlingScrubberNextLineFarInDom,
    _handlingScrubberNextPara,
    _handlingScrubberNextParaFarInDom,
    _handlingScrubberNextParaFarOutsideDom
  ];
  for (let handler of handlers) {
    const handled = await handler(
      store,
      router,
      newLocatorStruct,
      oldLocatorStruct
    );
    if (handled) {
      store.commit('PublicationStore/setIsAudioStartPlaying', false);
      return;
    }
  }

  logger.error(
    `Did not find handler for positioning book and scrubber by locators from  ${oldScrubberLocator} to ${newScrubberLocator}`
  );
}

function _getPublicationId(store) {
  return store.getters['OpenParameterStore/getPublicationId'];
}

function _processAnnSelectionWhileListening(store) {
  const currentScrubberLocator =
    store.getters['PlaybackStore/getCurrentLocator'];
  const publicationId = _getPublicationId(store);

  const annsIdsByLocator = store.getters[
    'AnnotationsStore/annotationsIdsByLocator'
  ](currentScrubberLocator, publicationId);

  if (!annsIdsByLocator.length) {
    return false;
  }
  const locator = Locator.deserialize(currentScrubberLocator);
  const targetParaId = locator.startLocator.prefixedParagraphId;
  store.commit('AnnotationsStore/selectActiveAnnotation', {
    annIds: annsIdsByLocator,
    targetParaId
  });
}

function _tryRestorePrevLocator(store, bookId, locator) {
  return store.getters['PublicationStore/getPreviousLocator'](bookId, locator);
}

function _getThirdScreenAudioRangeLocator(store) {
  const publicationId = _getPublicationId(store);

  const locator = store.getters['PublicationStore/getThirdScreenLocator']();
  return store.getters['PublicationStore/getClosestAlignmentLocator'](
    publicationId,
    locator
  );
}

function _handlingScrubberSameLine(
  store,
  router,
  newLocatorStruct,
  oldLocatorStruct
) {
  const isSameLine = newLocatorStruct.getTopDist(oldLocatorStruct) === 0;
  let handled = false;
  if (isSameLine) {
    handled = true;
  }
  return handled;
}

async function _handlingScrubberNextLine(
  store,
  router,
  newLocatorStruct,
  oldLocatorStruct
) {
  const rawFontSize = window.getComputedStyle(oldLocatorStruct.element)
    .fontSize;
  const fontSize = parseFloat(rawFontSize.replace('px', ''));
  const locatorTopDelta = newLocatorStruct.getTopDist(oldLocatorStruct);
  const toLineHeightCoefficient = 1.66;
  const lineHeight = toLineHeightCoefficient * fontSize;
  const isNextLine = locatorTopDelta > 0 && locatorTopDelta <= lineHeight;
  let handled = false;
  if (isNextLine) {
    scrollBy(store, locatorTopDelta);
    handled = true;
  }

  return handled;
}

function _handlingScrubberNextLineFarInDom(
  store,
  router,
  newLocatorStruct,
  oldLocatorStruct
) {
  const samePara =
    newLocatorStruct.getParaId() === oldLocatorStruct.getParaId();
  let handled = false;
  if (samePara) {
    const locatorTopDelta = newLocatorStruct.getTopDist(oldLocatorStruct);
    updateIsScrolling(store, true);
    scrollBy(store, locatorTopDelta);
    updateIsScrolling(store, false);
    handled = true;
  }
  return handled;
}

async function _handlingScrubberNextPara(
  store,
  router,
  newLocatorStruct,
  oldLocatorStruct
) {
  const newLocatorParaId = newLocatorStruct.getParaId();
  const oldLocatorParaId = oldLocatorStruct.getParaId();

  const isNextPara = newLocatorParaId !== oldLocatorParaId;
  const newPara = document.querySelector(`#${newLocatorParaId}`);
  const oldPara = document.querySelector(`#${oldLocatorParaId}`);
  const locatorTopDelta = newLocatorStruct.getTopDist(oldLocatorStruct);

  const hasImage =
    (newPara && newPara.querySelector('img') !== null) ||
    (oldPara && oldPara.querySelector('img') !== null);
  const paraDistance =
    newPara && oldPara
      ? newPara.getBoundingClientRect().top -
        oldPara.getBoundingClientRect().bottom
      : Infinity;

  let handled = false;
  if (isNextPara && !hasImage && paraDistance < 200) {
    scrollBy(store, locatorTopDelta);
    handled = true;
  }
  return handled;
}

async function _handlingScrubberNextParaFarInDom(
  store,
  router,
  newLocatorStruct,
  oldLocatorStruct
) {
  store.dispatch('PlaybackStore/pause');
  const paraId = newLocatorStruct.getParaId();

  const locatorTopDelta = _calibrateScrubberPosition(
    store,
    oldLocatorStruct,
    newLocatorStruct
  );

  const para = window.document.querySelector(`#${paraId}`);
  const isInsideDom = para !== null;
  let handled = false;

  const isOldInsideViewPort = oldLocatorStruct.isInsideViewPort();
  const isNewInsideViewPort = newLocatorStruct.isInsideViewPort();

  let deltaToNewLocator = 0;
  const isStartPlayParaOutViewPort =
    !isOldInsideViewPort &&
    !isNewInsideViewPort &&
    newLocatorStruct.serializedLocator === oldLocatorStruct.serializedLocator; //big description in metablock in mobile
  if (isStartPlayParaOutViewPort) {
    deltaToNewLocator =
      newLocatorStruct.getTop() - SelectionConstantUtils.TOOLBAR_HEIGHT;
  }
  if (isInsideDom) {
    updateIsScrolling(store, true);
    await animatedScrollToDelta(store, locatorTopDelta + deltaToNewLocator);
    updateIsScrolling(store, false);
    handled = true;
  }
  store.dispatch('PlaybackStore/playFromLocator', {
    locator: newLocatorStruct.getLocator()
  });
  return handled;
}

function _handlingScrubberNextParaFarOutsideDom(
  store,
  router,
  newLocatorStruct
) {
  const paragraphId = newLocatorStruct.getParaId();
  PublicationNavigateLogicService.openPublication(store, router, {
    paragraphId
  });
}

function _calibrateScrubberPosition(store, oldLocatorStruct, newLocatorStruct) {
  const viewportHeight = getViewportHeight(store);
  let deltaScrubberPositionLineByLineMode = 0;
  const scrubberPositionLineByLineMode =
    store.getters['PublicationStore/getScrubberPositionLineByLineMode'];
  const localScrubberPositionLineByLineMode = oldLocatorStruct.rect.top;

  if (
    scrubberPositionLineByLineMode !== null &&
    scrubberPositionLineByLineMode > 0 &&
    scrubberPositionLineByLineMode < viewportHeight
  ) {
    deltaScrubberPositionLineByLineMode =
      localScrubberPositionLineByLineMode - scrubberPositionLineByLineMode;
  } else {
    store.commit(
      'PublicationStore/updateScrubberPositionLineByLineMode',
      localScrubberPositionLineByLineMode
    );
  }

  const decimalRemainder =
    store.getters['PublicationStore/getFractionalScrollRemainder'];
  const rawLocatorTopDelta =
    newLocatorStruct.getTopDistNoRound(oldLocatorStruct) +
    decimalRemainder +
    deltaScrubberPositionLineByLineMode;
  const locatorTopDelta = Math.round(rawLocatorTopDelta);
  const newDecimalRemainderRemainder = rawLocatorTopDelta - locatorTopDelta;
  store.commit(
    'PublicationStore/updateFractionalScrollRemainder',
    newDecimalRemainderRemainder
  );

  return locatorTopDelta;
}

function scrollBy(store, delta) {
  const scrollElement = _getScrollElement(store);
  scrollElement.scrollBy(0, delta);
}

function updateIsScrolling(store, isScrolling) {
  store.commit('ContextStore/setIsScrolling', isScrolling);
}

function animatedScrollToPara(store, paraId) {
  const para = window.document.querySelector(`#${paraId}`);

  const paraRect = para.getBoundingClientRect();
  const delta = paraRect.top - SelectionConstantUtils.TOOLBAR_HEIGHT;
  return animatedScrollToDelta(store, delta);
}

async function scrollToParaAndPlay(store, router, fromParaId, toParaId) {
  const logicalOffset = 0;
  const newLocator = MarkerUtils.prefixedParaIdToLocator(
    toParaId,
    logicalOffset
  );
  const oldLocator = MarkerUtils.prefixedParaIdToLocator(
    fromParaId,
    logicalOffset
  );
  const newLocatorStruct = createScrubberLocatorStruct(store, newLocator);
  const oldLocatorStruct = createScrubberLocatorStruct(store, oldLocator);
  const handlers = [
    _handlingScrubberNextPara,
    _handlingScrubberNextParaFarInDom,
    _handlingScrubberNextParaFarOutsideDom
  ];
  for (let handler of handlers) {
    const handled = await handler(
      store,
      router,
      newLocatorStruct,
      oldLocatorStruct
    );
    if (handled) {
      break;
    }
  }
  const isAudioPlaying = store.getters['PlaybackStore/isAudioPlaying'];
  if (!isAudioPlaying) {
    store.dispatch('PlaybackStore/playParagraph', {
      paraId: toParaId
    });
  }
}

function scrollByLocator(store, router, locator) {
  const wordByLocator = BookUtils.getWordDomWrapper(locator);
  if (!wordByLocator) {
    return PublicationNavigateLogicService.openPublication(store, router, {
      paragraphId: locator.startLocator.prefixedParagraphId
    });
  }
  const thirdOfScreen = window.screen.height / 3;
  const wordRect = wordByLocator.getBoundingClientRect();
  const delta =
    wordRect.top - SelectionConstantUtils.TOOLBAR_HEIGHT - thirdOfScreen;
  scrollBy(store, delta);
}

export default {
  createScrubberLocatorStruct,
  animatedScrollToDelta,
  animatedScrollToPara,
  stopAnimateScroll,
  scrollBy,
  scrollByLocator,
  scrollToParaAndPlay,
  applyScrollOnScrubberChange,
  updateIsScrolling
};
