import has from 'lodash/has';
import zipObject from 'lodash/zipObject';

function parse(source, parseFn) {
  var url = parseFn(source);

  var hash = url.fragment;
  var params =
    hash.indexOf('/') === 0 || hash.indexOf('=') === -1
      ? false
      : zipObject(
          hash.split('&').map(function(el) {
            return el.split('=');
          })
        );
  if (params) {
    url = parseFn(url.withoutFragment);
    url.extraParams = params;
  }
  if (has(params, 'access_token') || has(params, 'oauth_token')) {
    url.oauth = params;
  } else if (
    has(params, 'taskConfirmationHashCode') &&
    has(params, 'confirm')
  ) {
    url.confirmationInfo = params;
  } else if (has(params, 'stem') && has(params, 'quote')) {
    url.searchHighlightInfo = params;
  }
  return url;
}

export default {
  parse
};
