/**
 * AudioPlayer component subscribed on play, pause and ext events.
 * So, despite these methods have no code, they still have sense.
 */
import Vue from 'vue';
import Locator from '@shared/publication/locator.mjs';
import TextUtils from '@shared/publication/dom-utils/text-utils.mjs';
import PlaybackStateEnum from '@/enums/PlaybackStateEnum';
import ContentRequestFactory from '@/classes/factories/ContentRequestFactory';
const PRELOAD_COUNT = 3;

const initState = () => ({
  preloadCount: PRELOAD_COUNT,
  playbackState: PlaybackStateEnum.NONE,
  displayScrubber: false,
  isDragging: false,
  currentBookId: null,
  currentParaId: null,
  currentLocator: null,
  currentTime: 0,
  timeToStop: null,
  isPlaying: false,
  isHighlightPlaying: false,
  isUserPlayAudio: false,
  isPreloading: false,
  playParaIdOnStable: null,
  playSinglePara: false,
  isTriggeredFromMeta: false
});

export default {
  state: initState,
  mutations: {
    setPlayParaIdOnStable(state, paraId) {
      state.playParaIdOnStable = paraId;
    },
    setIsDragging(state, val) {
      state.isDragging = val;
    },
    setCurrentBookId: (state, currentBookId) =>
      (state.currentBookId = currentBookId),
    reinitState: state => {
      const newState = initState();
      Object.keys(state).forEach(key => {
        state[key] = newState[key];
      });
    },
    setTimeToStop: (state, timeToStop) => {
      state.timeToStop = timeToStop;
    },
    setIsPreloading: (state, isPreloading) => {
      state.isPreloading = isPreloading;
    },
    setCurrentParaId: (state, currentParaId) => {
      if (state.currentParaId !== currentParaId) {
        state.currentTime = 0;
      }
      state.currentParaId = currentParaId;
    },
    setCurrentTime: (state, currentTime) => {
      state.currentTime = currentTime;
    },
    setIsPlaying: (state, isPlaying) => (state.isPlaying = isPlaying),
    setIsHighlightPlaying: (state, isPlaying) =>
      (state.isHighlightPlaying = isPlaying),
    setIsUserPlayAudio: (state, val) => {
      state.isUserPlayAudio = val;
    },
    setDisplayScrubber: (state, displayScrubber) =>
      (state.displayScrubber = displayScrubber),
    setPlaybackState: (state, playbackState) =>
      (state.playbackState = playbackState),
    setPlaySinglePara: (state, playSinglePara) => {
      state.playSinglePara = !!playSinglePara;
    },
    setIsTriggeredFromMeta: (state, triggeredFromMeta) => {
      state.isTriggeredFromMeta = triggeredFromMeta;
    }
  },
  actions: {
    playPostponedParagraph({ commit, dispatch, getters }) {
      const paraId = getters.getPlayParaIdOnStable;
      if (paraId) {
        dispatch('playParagraph', paraId);
      }
      commit('setPlayParaIdOnStable', null);
    },
    setPlayInitParagraphOnStable({ commit, rootGetters }) {
      const paraRange = rootGetters['OpenParameterStore/getParagraphRange'];
      const openParaId = rootGetters['OpenParameterStore/getParaId'];
      const paraId = paraRange?.start || openParaId;
      commit('setPlayParaIdOnStable', { paraId });
    },
    prepareAudio({ commit }, payload) {
      const { paraId } = payload;
      commit('setCurrentParaId', paraId);
    },
    playAudioFromMetaBlock({ commit }) {
      commit('setIsTriggeredFromMeta', true);
    },
    reinit: ({ commit }) => {
      commit('reinitState');
    },
    continue: ({ commit }) => {
      commit('setPlaybackState', PlaybackStateEnum.PLAY);
      commit('setDisplayScrubber', true);
    },
    async playParagraph({ state, commit, dispatch }, payload) {
      const { paraId, showScrubber = true, playSinglePara = false } = payload;
      let time = payload.time;
      if (!time) {
        time = await dispatch('calcCurrentTime', {
          paraId,
          bookId: state.currentBookId
        });
      }

      commit('setPlaySinglePara', playSinglePara);
      commit('setCurrentParaId', paraId);
      commit('setCurrentTime', time);
      commit('setPlaybackState', PlaybackStateEnum.PLAY);
      commit('setDisplayScrubber', showScrubber);
    },
    async calcCurrentTime({ dispatch, rootGetters }, payload) {
      const { paraId, bookId } = payload;
      await dispatch('preloadAlignment', {
        bookId,
        paraId
      });
      const currentTime = rootGetters['PublicationStore/getStartParaTime'](
        bookId,
        paraId
      );
      return currentTime;
    },
    async playOnThirdScreen({ dispatch, rootGetters }, payload) {
      const { locatorOnThird, bookId, showScrubber } = payload;
      const paraId = locatorOnThird.prefixedParagraphId;
      const isVoicedPara = rootGetters['PublicationStore/isElementVoiced'](
        bookId,
        paraId
      );
      if (isVoicedPara) {
        await dispatch('preloadAlignment', { paraId, bookId });
        dispatch('playFromLocator', {
          showScrubber,
          locator: new Locator.InTextRangeLocator(
            locatorOnThird,
            locatorOnThird
          )
        });
      } else {
        const nextVoicedParaId = rootGetters[
          'PublicationStore/getNextVoicedParaId'
        ](bookId, paraId);
        if (!nextVoicedParaId) {
          return;
        }
        dispatch('playParagraph', {
          showScrubber,
          paraId: nextVoicedParaId
        });
      }
    },
    playLocator: async (
      { commit, dispatch, rootGetters, state },
      { locator, showScrubber }
    ) => {
      const endLocator = locator.endLocator;
      await dispatch('preloadAlignment', {
        paraId: endLocator.prefixedParagraphId,
        bookId: state.currentBookId
      });
      const timeToStop = rootGetters['PublicationStore/getEndTimeByLocator'](
        state.currentBookId,
        endLocator
      );
      commit('setTimeToStop', timeToStop);
      dispatch('playFromLocator', { locator, showScrubber });
    },
    playFromLocator: ({ state, commit, rootGetters }, payload) => {
      const { locator, showScrubber = true } = payload;
      commit('setPlaybackState', PlaybackStateEnum.PLAY);
      commit('setCurrentParaId', locator.startLocator.prefixedParagraphId);
      const currentTime = rootGetters['PublicationStore/getTimeByLocator'](
        state.currentBookId,
        locator.startLocator
      );
      commit('setCurrentTime', currentTime);
      commit('setDisplayScrubber', showScrubber);
    },
    pause: ({ commit }) => {
      commit('setPlaybackState', PlaybackStateEnum.PAUSE);
    },
    next: () => {},
    hideScrubber: ({ commit }) => {
      commit('setDisplayScrubber', false);
    },
    async showScrubberByLocator(
      { commit, dispatch, rootGetters },
      { bookId, InTextLocator }
    ) {
      const currentParaId = InTextLocator.prefixedParagraphId;
      await dispatch('preloadAlignment', { paraId: currentParaId, bookId });
      const currentTime = rootGetters['PublicationStore/getTimeByLocator'](
        bookId,
        InTextLocator
      );
      commit('setDisplayScrubber', true);
      commit('setCurrentBookId', bookId);
      commit('setCurrentParaId', currentParaId);
      commit('setCurrentTime', currentTime);
    },
    rerenderScrubber: ({ state, commit }) => {
      if (!state.displayScrubber) {
        return;
      }
      commit('setDisplayScrubber', false);
      Vue.nextTick(() => {
        commit('setDisplayScrubber', true);
      });
    },
    preloadAlignment: async (
      { state, dispatch, commit, rootGetters },
      { paraId, bookId, preloadCount }
    ) => {
      let isLoadedAlignment = false;
      if (!preloadCount) {
        isLoadedAlignment = Boolean(
          rootGetters['PublicationStore/getParagraphAlignment'](bookId, paraId)
        );
      } else {
        let currentParaId = paraId;
        isLoadedAlignment = Array.apply(null, Array(preloadCount)).every(() => {
          const isLastVoiced = currentParaId === null;
          if (isLastVoiced) {
            return true;
          }
          const paraAlignment = rootGetters[
            'PublicationStore/getParagraphAlignment'
          ](bookId, currentParaId);
          if (!paraAlignment) {
            return false;
          }
          currentParaId = rootGetters['PublicationStore/getNextVoicedParaId'](
            bookId,
            currentParaId
          );
          return true;
        });
      }

      if (isLoadedAlignment) {
        return Promise.resolve();
      }

      const contentRequest = ContentRequestFactory.topBottomContentRequest(
        bookId,
        paraId,
        preloadCount || state.preloadCount
      );
      commit('setIsPreloading', true);
      await dispatch(
        'PublicationStore/getBookAlignmentByContentRequest',
        contentRequest,
        { root: true }
      );
      commit('setIsPreloading', false);
    },
    playTts: () => {},
    playHighlight: () => {},
    stopPlayingHighlight: () => {}
  },
  getters: {
    isDragging: state => () => {
      return state.isDragging;
    },
    isUserPlayAudio: state => {
      return state.isUserPlayAudio;
    },
    isHighlightPlaying: state => {
      return state.isHighlightPlaying;
    },
    isAudioPlaying: state => {
      return state.playbackState === PlaybackStateEnum.PLAY;
    },
    isAudioPaused: state => {
      return state.playbackState === PlaybackStateEnum.PAUSE;
    },
    isDisplayScrubber: state => {
      return state.displayScrubber;
    },
    getCurrentLocator: (state, getters, rootState, rootGetters) => {
      const currentLocator = rootGetters['PublicationStore/getLocatorByTime'](
        state.currentBookId,
        state.currentParaId,
        state.currentTime
      );
      return currentLocator;
    },
    getCurrentBookId: state => {
      return state.currentBookId;
    },
    getCurrentParaId: state => {
      return state.currentParaId;
    },
    getCurrentTime: state => {
      return state.currentTime;
    },
    getPreloadCount: state => {
      return state.preloadCount;
    },
    getPlayParaIdOnStable: state => {
      return state.playParaIdOnStable;
    },
    getPlaySinglePara: state => {
      return state.playSinglePara;
    },
    getIsTriggeredFromMeta: state => {
      return state.isTriggeredFromMeta;
    },
    getIsTtsCase: (state, getters, rootState, rootGetters) => (
      paraId,
      quote
    ) => {
      if (!paraId) {
        return false;
      }
      const lang = rootGetters['BookStore/getParaLanguage'](paraId);
      const supportedLanguages =
        rootGetters['ContextStore/getSupportedTtsLangs'];
      if (supportedLanguages && !supportedLanguages.includes(lang)) {
        return false;
      }
      const maxWordsNumber = rootGetters['ContextStore/getMaxWordsNumberTts'];

      const isTtsCase = TextUtils.validateTtsText(quote, lang, {
        maxWordsNumber
      }).valid;
      return isTtsCase;
    }
  }
};
