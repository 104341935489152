class SelectionQuery {
  constructor(buildData) {
    this.locator = buildData.locator;
    this.audio = buildData.audio;
    this.type = buildData.type;
    this.content = buildData.content;
    this.wordsCount = buildData.wordsCount;
    this.language = buildData.language;
    this.alignment = buildData.alignment;
    this.location = buildData.location;
    this.publication = buildData.publication;
    this.blockId = buildData.blockId;
  }
}

class SelectionQueryBuilder {
  setLocator(val) {
    this.locator = val;
    return this;
  }
  setAudio(val) {
    this.audio = val;
    return this;
  }
  setType(val) {
    this.type = val;
    return this;
  }
  setContent(val) {
    this.content = val;
    return this;
  }
  setWordsCount(val) {
    this.wordsCount = val;
    return this;
  }
  setLanguage(val) {
    this.language = val;
    return this;
  }
  setAlignment(val) {
    this.alignment = val;
    return this;
  }
  setLocation(val) {
    this.location = val;
    return this;
  }
  setPublication(publicationParaInfo) {
    this.publication = publicationParaInfo;
    return this;
  }
  setBlockId(blockId) {
    this.blockId = blockId;
    return this;
  }
  build() {
    return new SelectionQuery(this);
  }
}

function getSelectionQueryBuilder() {
  return new SelectionQueryBuilder();
}

class NewCompilation {
  constructor(data = {}) {
    this.title = data.title || '';
    this.description = data.description || '';
  }
}

function createNewCompilation() {
  return new NewCompilation();
}

export default {
  getSelectionQueryBuilder,
  createNewCompilation
};
