<template>
  <div class="quiz">
    <div class="quiz-icon">
      <BaseSpriteIcon icon-name="pic-essay" view-box="0 0 32 32" />
    </div>
    <div class="quiz-text">
      <div class="quiz-title">
        {{ materialView.topic }}
      </div>
      <div class="text-description">
        {{ materialView.comment }}
      </div>
      <div class="footer-icons">
        <div />
        <div class="icons-block">
          <span @click="editEssayTask()">
            <BaseSpriteIcon icon-name="ico-edit" />
          </span>
          <span @click="deleteEssayTask()">
            <BaseSpriteIcon icon-name="ico-delete" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import MaterialTypes from '@shared/enums/MaterialTypes';
import MenuTasks from '@/enums/MenuTasks';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'EssayTask',
  components: { BaseSpriteIcon },
  props: {
    materialView: Object
  },
  methods: {
    editEssayTask() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.ESSAY_TASK_POPUP,
        shown: false,
        popupContext: {
          data: this.materialView
        }
      });
    },
    deleteEssayTask() {
      this.$emit('materialEvent', {
        materialType: MaterialTypes.ESSAY_TASK,
        materialData: this.materialView,
        task: MenuTasks.DELETE
      });
    }
  }
};
</script>

<style scoped lang="less">
@import 'EssayTask.less';
</style>
