import CampaignsService from '@/services/CampaignsService';

class Campaign {
  constructor(params = {}) {
    this.id = params._id || params.id;
    this.title = params.title || '';
    this.createdAtFormatted = CampaignsService.getFormattedDate(
      params.createdAt
    );
    this.createdAt = params.createdAt;
    this.shortUrl = params.shortUrl;
    this.sessionsByDate = params.sessionsByDate || {};
    this.totalSessions = CampaignsService.getTotalUsersCount(
      this.sessionsByDate
    );
  }
}

class UserHasCampaign {
  constructor(params) {
    this.id = params._id;
    this.name = params.name;
    this.email = params.email;
    this.totalSessions = params.totalSessions;
    this.photo = params.photo || {};
  }
}

function createCampaign(params) {
  return new Campaign(params);
}

function createUserHasCampaign(params) {
  return new UserHasCampaign(params);
}

export default {
  createCampaign,
  createUserHasCampaign
};
