import isNil from 'lodash/isNil';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

class BookOpenParams {
  constructor(buildData) {
    this.slug = buildData.slug;
    this.publicationId = buildData.publicationId;
    this.publicationType = buildData.publicationType;
    this.materialId = buildData.materialId;
    this.paraId = buildData.paraId || '';
    this.requiredLocator = buildData.requiredLocator || '';
    this.showMetaBlock = isNil(buildData.showMetaBlock)
      ? true
      : buildData.showMetaBlock;
    this.highlight = buildData.highlight || null;
    this.openOffset = buildData.openOffset || 0;
    this.reload = buildData.reload || false;
    this.selection = buildData.selection || null;
    this.isOpenFromLink = buildData.isOpenFromLink || false;
    this.paraRange = buildData.paraRange || null;
    this.editMode = buildData.editMode || false;
    this.previewMode = buildData.previewMode || false;
    this.searchNavigation = buildData.searchNavigation || false;
    this.renderedParaNum =
      buildData.renderedParaNum || AppConstantsUtil.RENDERED_PARA_NUM;
    this.startRenderedParaCount =
      buildData.startRenderedParaCount ||
      AppConstantsUtil.START_RENDERED_PARA_COUNT;
  }

  toJSON() {
    return {
      slug: this.slug,
      publicationId: this.publicationId,
      publicationType: this.publicationType,
      materialId: this.materialId,
      paraId: this.paraId,
      requiredLocator: this.requiredLocator,
      showMetaBlock: this.showMetaBlock,
      highlight: this.highlight,
      openOffset: this.openOffset,
      reload: this.reload,
      selection: this.selection,
      isOpenFromLink: this.isOpenFromLink,
      editMode: this.editMode,
      paraRange: this.paraRange,
      previewMode: this.previewMode,
      searchNavigation: this.searchNavigation,
      renderedParaNum: this.renderedParaNum,
      startRenderedParaCount: this.startRenderedParaCount
    };
  }

  changeParaId(paraId) {
    this.paraId = paraId;
  }

  changeShowMetaBlock(showMetaBlock) {
    this.showMetaBlock = showMetaBlock;
  }
}

class StudyGuideOpenParams extends BookOpenParams {
  constructor(buildData) {
    super(buildData);
    this.studyGuideId = buildData.studyGuideId;
  }
}

class BookOpenParamsBuilder {
  setPublicationId(val) {
    this.publicationId = val;
    return this;
  }

  setSlug(slug) {
    this.slug = slug;
    return this;
  }

  setPublicationType(publicationType) {
    this.publicationType = publicationType;
    return this;
  }

  setMaterialId(materialId) {
    this.materialId = materialId;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setRequiredLocator(requiredLocator) {
    this.requiredLocator = requiredLocator;
    return this;
  }

  setHighlight(highlight) {
    this.highlight = highlight;
    return this;
  }

  setShowMetaBlock(showMetaBlock) {
    this.showMetaBlock = showMetaBlock;
    return this;
  }

  setOpenOffset(openOffset) {
    this.openOffset = openOffset;
    return this;
  }

  setSelection(selection) {
    this.selection = selection;
    return this;
  }

  setReload(reload) {
    this.reload = reload;
    return this;
  }

  setIsOpenFromLink(val) {
    this.isOpenFromLink = val;
    return this;
  }

  setEditMode(editMode) {
    this.editMode = editMode;
    return this;
  }

  setParagraphRange(val) {
    this.paraRange = val;
    return this;
  }

  setRenderedParaNum(val) {
    this.renderedParaNum = val;
    return this;
  }

  setStartRenderedParaCount(val) {
    this.startRenderedParaCount = val;
    return this;
  }
  setPreviewMode(val) {
    this.previewMode = val;
    return this;
  }

  setSearchNavigation(val) {
    this.searchNavigation = val;
    return this;
  }

  build() {
    return new BookOpenParams(this);
  }
}

class StudyGuideOpenParamsBuilder extends BookOpenParamsBuilder {
  constructor() {
    super();
  }

  setStudyGuideId(studyGuideId) {
    this.studyGuideId = studyGuideId;
    return this;
  }

  build() {
    return new StudyGuideOpenParams(this);
  }
}

function createPublicationOpenParamsBuilder() {
  return new BookOpenParamsBuilder();
}

function createStudyGuideOpenParamsBuilder() {
  return new StudyGuideOpenParamsBuilder();
}

export default {
  createPublicationOpenParamsBuilder,
  createStudyGuideOpenParamsBuilder
};
