import { $t } from '@/i18n';
import PublicationStatusEnum from '@/enums/PublicationStatusEnum';

const labels = {
  [PublicationStatusEnum.DRAFT]: $t('PublicationStatus.draft.label'),
  [PublicationStatusEnum.PUBLISHED]: $t('PublicationStatus.published.label'),
  [PublicationStatusEnum.UNPUBLISHED]: $t('PublicationStatus.unpublished.label')
};

const statusClasses = {
  [PublicationStatusEnum.DRAFT]: 'draft',
  [PublicationStatusEnum.PUBLISHED]: 'published',
  [PublicationStatusEnum.UNPUBLISHED]: 'unpublished'
};

const icons = {
  [PublicationStatusEnum.DRAFT]: 'ico-exclamation',
  [PublicationStatusEnum.PUBLISHED]: 'ico-checked',
  [PublicationStatusEnum.UNPUBLISHED]: 'ico-exclamation'
};

function getPublicationStatusLabel(publicationStatus) {
  return labels[publicationStatus];
}

function getPublicationStatusClass(publicationStatus) {
  return statusClasses[publicationStatus];
}

function getPublicationStatusIcon(publicationStatus) {
  return icons[publicationStatus];
}

export default {
  getPublicationStatusLabel,
  getPublicationStatusIcon,
  getPublicationStatusClass
};
