<template>
  <BaseButton
    v-bind="computedAttributes"
    class="px-3"
    :to="{ name: MANAGE_PUBLICATION }"
    text
    @click.native.prevent="toLibrary"
  >
    <slot name="back-to-library"
      >{{ $t('ApplicationMenuItem.Library.label') }}
    </slot>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'BackToLibraryControl',
  components: {
    BaseButton
  },
  props: {
    to: {
      type: Object
    },
    text: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      MANAGE_PUBLICATION: AppStateEnum.MANAGE_PUBLICATION,
      defaultAttributes: {
        text: true
      }
    };
  },
  computed: {
    computedAttributes() {
      return { ...this.defaultAttributes, ...this.$attrs };
    },
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toRoute() {
      if (this.to) {
        this.$router.push({ ...this.to });
        return;
      }
      const lang = this.$store.getters['ContextStore/currentLanguageGetter'];
      const route =
        lang === 'en'
          ? { name: this.MANAGE_PUBLICATION }
          : {
              name: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
              params: { pathMatch: lang }
            };
      this.$router.push(route);
    },
    toLibrary() {
      if (this.isBlogApp) {
        const clientReaderUrl = this.$store.getters[
          'ContextStore/getClientReaderUrl'
        ];
        this.$store.dispatch('ContextStore/openUrl', {
          url: `${clientReaderUrl}library`
        });
        return;
      }
      this.toRoute();
    }
  }
};
</script>

<style lang="less" src="./BackToLibraryControl.less"></style>
