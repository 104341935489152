class PublicationPresentation {
  constructor(params = {}) {
    this.content = params.content || [];
    this.details = params.details || {};
    this.materials = params.materials || {};
    this.audio = params.audio || {};
  }

  setContent(content) {
    this.content = content;
    return this;
  }

  setDetails(details) {
    this.details = details;
    return this;
  }

  setMaterials(materials) {
    this.materials = materials;
    return this;
  }

  setAudio(audio) {
    this.audio = audio;
    return this;
  }
}

class PublicationPresentationBuilder {
  setContent(content) {
    this.content = content;
    return this;
  }

  setDetails(details) {
    this.details = details;
    return this;
  }

  setMaterials(materials) {
    this.materials = materials;
    return this;
  }

  setAudio(audio) {
    this.audio = audio;
    return this;
  }

  build() {
    return new PublicationPresentation(this);
  }
}

function create(params) {
  return new PublicationPresentation(params);
}

function createBuilder() {
  return new PublicationPresentationBuilder();
}

export default {
  create,
  createBuilder
};
