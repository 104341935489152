import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('agentConfigBuilder.js');
import ClientNodeContext from '@/context/ClientNodeContext';

function createAgentConfig(agentEnvClientConfig) {
  const agentConfig = {};
  /**
   * @type {AgentConfig}
   */
  if (agentEnvClientConfig.enabled && !agentEnvClientConfig.url) {
    logger.warn('Configuration missing: "url"');
    agentConfig.enabled = false;
  }
  agentConfig.useMemoryAdapter = ClientNodeContext.isIos;
  agentConfig.adapter = agentConfig.useMemoryAdapter ? 'memory' : 'idb';
  /**
   * Interval between running pouch-couch sync.
   * This value is used when previous attempt was succeeded
   * It does't matter one or two way sync process take place
   * @type {number}
   * @default 5 min
   */
  agentConfig.syncInterval = agentEnvClientConfig.syncInterval || 5 * 60 * 1000; // 5 min

  /**
   * The multiplier that changes sync interval in idle mode
   * (e.g. when the app is minimized)
   * @type {number}
   * @default 5
   */
  agentConfig.idleMultiplier = agentEnvClientConfig.idleMultiplier || 5;

  /**
   * Interval between running pouch-couch sync.
   * This value is used when previous attempt was failed
   * It does't matter one or two way sync process take place
   * @type {number}
   * @default 2 min
   */
  agentConfig.retryInterval =
    agentEnvClientConfig.syncInterval / 2 || 2 * 60 * 1000; // 2 min

  /**
   * @type {number}
   * @default 2 min
   */
  agentConfig.queryInvalidateInterval =
    agentEnvClientConfig.queryInvalidateInterval || 2 * 60 * 1000; // 2 min
  /**
   * Cookie life time.
   * Do not set bigger than cloudant has (24h)
   * @type {number}
   * @const
   * @more https://docs.cloudant.com/authentication.html#cookie-authentication
   */
  agentEnvClientConfig.cookieLifetime = (24 * 60 - 30) * 60 * 1000; // 24h -> 23:30
  /**
   * Cookie expiration time
   * @type {number}
   */
  agentConfig.cookieExpiresAt = -1;

  /**
   * @type {boolean}
   */
  agentConfig.initialized = false;
  /**
   * @type {boolean}
   */
  agentConfig._useBasicHeaderAuthorization = true;
  /**
   * @type {boolean}
   */
  agentConfig._useCookieAuth =
    typeof agentEnvClientConfig.cookieAuth !== 'undefined'
      ? agentEnvClientConfig.cookieAuth
      : true;
  // branch and brand create db prefix
  agentConfig.dbPrefix = '';
  /**
   * @typedef {string} dbAlias
   */
  /**
   * Set of {dbAlias:dbName}
   * @type {Object<string>}
   */
  agentConfig.dbNames = {
    public: 'public',
    query: 'query',
    user: 'user',
    userRW: 'user_rw'
  };
  agentConfig.coursePrefix = 'course'; //TODO use DB.prefix.course

  agentConfig.cred = {
    user: null,
    pass: null
  };

  /**
   * userId - owner of the storage.
   * Filled during initialization process
   * @type {?string}
   */
  agentConfig.ownerId = null;
  agentConfig.isGuestMode = false;
  agentConfig.guestPostfix = '_guest';

  return Object.assign({}, agentEnvClientConfig, agentConfig);
}

export default {
  createAgentConfig
};
