module.exports = {
  BOOK: 'Book',
  NOTE: 'Note',
  UNPUBLISHED_DEFAULT_BOOK: 'unpublishedDefaultBook',
  SUGGESTED_BOOK: 'SuggestedBook',
  COLLECTION: 'Collection',
  STUDY_GUIDE: 'StudyGuide',
  SYLLABUS: 'StudyCourse',
  LIBRARY_SET: 'LibrarySet',
  STUDY_COURSE: 'StudyClass',
  COMPILATION: 'Compilation',
  BLOG_ARTICLE: 'BlogArticle',
  DICTIONARY: 'Dictionary',
  VOCABULARY: 'Vocabulary',
  WORD_LIST: 'WordList'
};
