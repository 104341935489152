<template>
  <RecentBookControl>
    <template #recent-book>
      <BaseButton
        elevation="0"
        fab
        small
        :aria-label="$t('Accessibility.recentBooks')"
      >
        <BaseSpriteIcon view-box="0 0 24 24" icon-name="ico-tab-books" />
      </BaseButton>
    </template>
  </RecentBookControl>
</template>

<script>
import RecentBookControl from '@/components/controls/RecentBookControl/RecentBookControl';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'RecentBookIconControl',
  components: {
    RecentBookControl,
    BaseSpriteIcon,
    BaseButton
  }
};
</script>

<style lang="less" src="./RecentBookIconControl.less"></style>
