import get from 'lodash/get';
import dayJS from '@/dayJS';

import PublicationStatusEnum from '@/enums/PublicationStatusEnum';

import publicationStatusesUtils from '@/services/utils/publicationStatusesUtils';
import publicationUtils from '@/services/utils/publicationUtils';

import Utils from '@/services/utils/Utils';
import CompilationsService from '@/services/CompilationsService';

class BookDetailsView {
  constructor(publication) {
    this.id = publication.id || '';
    this.audio = publication.audio;
    this.author = publication.author;
    this.title = publication.name;
    this.coverUrl = null;
    this.isAuthorInTitle = publicationUtils.isAuthorInTitle(publication);
    this.readingTime = publication.readingTime;
    this.category = publication.category;
    this.level = publication.level || '';
    this.description =
      publication.shortDescription || publication.description || '';
    this.difficulty = publication.difficulty || '';
  }

  setCoverUrl(coverUrl) {
    this.coverUrl = coverUrl;
  }
}

class ReaderBookDetailsView extends BookDetailsView {
  constructor(publication) {
    super(publication);
    this.difficulty = publication.difficulty;
  }
}

class EditorBookDetailsView extends BookDetailsView {
  constructor(publication, relatedStudyGuides) {
    super(publication);
    this.ilmId = publication.ilmId;
    this.version = get(publication, 'publish.version.value', '');
    const date = publication.date;
    this.date = date ? dayJS.get(date).format('DD MMM YYYY') : '';

    this.weight = publication.weight;
    this.accessStatus = publication.accessStatus;
    this.language = publication.language;

    this.defaultStudyGuideId = publication.defaultStudyGuideId;
    this.relatedStudyGuides = createRelatedStudyGuides(
      relatedStudyGuides,
      publication.defaultStudyGuideId
    );
  }
}

class RelatedStudyGuide {
  constructor(rawRelatedStudyGuide, isDefault) {
    this.id = rawRelatedStudyGuide.id;
    this.name = rawRelatedStudyGuide.name;
    this.author = rawRelatedStudyGuide.author;
    this.readingTime = rawRelatedStudyGuide.readingTime;
    this.isDefault = isDefault;
    this.exercises = {
      numberEssays: rawRelatedStudyGuide.exercises.numberEssays,
      numberQuizzes: rawRelatedStudyGuide.exercises.numberQuizzes,
      flashcards: rawRelatedStudyGuide.exercises.numberQuizzes,
      notes: rawRelatedStudyGuide.notes.length,
      comments: rawRelatedStudyGuide.comments.length
    };
    this.publicationStatusLabel = publicationStatusesUtils.getPublicationStatusLabel(
      rawRelatedStudyGuide.publicationStatus
    );
    this.statusClass = publicationStatusesUtils.getPublicationStatusClass(
      rawRelatedStudyGuide.publicationStatus
    );
    this.isDraft = checkIsDraft(rawRelatedStudyGuide.publicationStatus);
  }
}

function checkIsDraft(publicationStatus) {
  return publicationStatus === PublicationStatusEnum.DRAFT;
}

function checkIsAuthor(authorIds, userId) {
  return authorIds.indexOf(userId) !== -1;
}

class EditorStudyGuideDetailsView extends BookDetailsView {
  constructor(studyGuide, book, userId) {
    super(studyGuide);
    this.bookId = book.id;
    this.bookAuthor = book.author;
    this.bookTitle = book.name;
    this.showDifficulty = true;
    this.statusLabel = publicationStatusesUtils.getPublicationStatusLabel(
      studyGuide.publicationStatus
    );
    this.status = studyGuide.publicationStatus;
    this.isPublished =
      PublicationStatusEnum.PUBLISHED === studyGuide.publicationStatus;
    this.isAuthor = checkIsAuthor(studyGuide.userIds, userId);
    this.studyGuideCopyId = studyGuide.studyGuideCopyId;
    this.hasChanges = Boolean(studyGuide.studyGuideCopyId);
  }
}

class EditorSyllabusDetailsView extends BookDetailsView {
  constructor(publication, syllabus, userId) {
    super(publication);
    this.studyCourseItems = syllabus.studyCourseItems || [];
    this.itemsNumber = (syllabus.studyCourseItems || []).length;
    this.difficulty = syllabus.difficulty || '0.0';
    this.status = publication.publicationStatus || PublicationStatusEnum.DRAFT;
    this.isAuthor = checkIsAuthor([publication.userId || ''], userId);
    this.showDifficulty = true;
    this.hasChanges = false;
  }
}

class EditorLibrarySetDetailsView extends BookDetailsView {
  constructor(publication, librarySet) {
    super(publication);
    this.wordsCount = librarySet.wordsCount;
    this.bookNumber = (publication.publicationIds || []).length;
    this.publications = librarySet.publications || [];
    this.status = publication.publicationStatus || PublicationStatusEnum.DRAFT;
    this.hasChanges = Boolean(publication.copyId);
    this.isAllowEdit = false;
    this.price = librarySet.price;
  }

  setIsAllowedEdit(user) {
    this.isAllowEdit = user && user.adminRole;
  }
}

class ReaderCompilationDetailsView {
  constructor(publication) {
    this.id = publication.id;
    this.audio = publication.audio;
    this.title = publication.title;
    this.modifiedAt = publication.modifiedAt;
    this.default = publication.default;
    const items = publication.items.filter(item => !!item);
    this.selectionsCount = CompilationsService.getSelectionsCount(items);
    this.readingTime =
      publication.readingTime ||
      Utils.estimateReadingTime(publication.wordsCount);
    this.description = publication.description;
    this.customClass = publication.isShared ? 'compilation-shared-item' : '';
  }

  getModifiedAtLocaleString() {
    return CompilationsService.convertModifiedAtToLocaleString(this.modifiedAt);
  }
}

class ReaderCollectionDetailsView {
  constructor(publication) {
    this.id = publication.id;
    this.name = publication.name;
    this.author = publication.author;
    this.category = publication.category;
    this.readingTime = publication.readingTime;
    this.numberRelatedPublications = publication.items.length;
  }
}

class PublicationDetailsContext {
  constructor(params = {}) {
    this.bookId = params.bookId || '';
    this.mode = params.mode;
    this.isShown = params.isShown;
  }

  toJSON() {
    return JSON.stringify({
      bookId: this.bookId,
      mode: this.mode,
      isShown: this.isShown
    });
  }

  changeBookId(bookId) {
    this.bookId = bookId;
  }

  changeIsShown(isShown) {
    this.isShown = isShown;
  }

  deserialize(serializeData) {
    this.bookId = serializeData.bookId;
    this.mode = serializeData.mode;
    this.isShown = serializeData.isShown;
  }
}

function createRelatedStudyGuides(relatedStudyGuides, defaultStudyGuideId) {
  return relatedStudyGuides.map(rawRelatedStudyGuide => {
    const isDefault = rawRelatedStudyGuide.id === defaultStudyGuideId;
    return new RelatedStudyGuide(rawRelatedStudyGuide, isDefault);
  });
}

function createReadBookDetailsView(publication) {
  return new ReaderBookDetailsView(publication);
}

function createEditBookDetailsView(publication, extendData) {
  return new EditorBookDetailsView(publication, extendData.relatedStudyGuides);
}

function createReadCompilationDetailsView(publication) {
  return new ReaderCompilationDetailsView(publication);
}

function createReadCollectionDetailsView(publication) {
  return new ReaderCollectionDetailsView(publication);
}

function createStudyGuideDetailsView(publication, book, userId) {
  return new EditorStudyGuideDetailsView(publication, book, userId);
}

function createSyllabusDetailsView(publication, syllabus, userId) {
  return new EditorSyllabusDetailsView(publication, syllabus, userId);
}

function createLibrarySetViewDetailsView(publication, librarySet) {
  return new EditorLibrarySetDetailsView(publication, librarySet);
}

export default {
  createReadBookDetailsView,
  createEditBookDetailsView,
  createStudyGuideDetailsView,
  createSyllabusDetailsView,
  createLibrarySetViewDetailsView,
  createReadCompilationDetailsView,
  createReadCollectionDetailsView,
  PublicationDetailsContext
};
