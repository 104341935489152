'use strict';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(isToday);
dayjs.extend(weekday);

import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fa';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';

export default {
  get: options => {
    return dayjs(options);
  },
  // you may use dayJS.getWeekdays(this.$store.getters['ContextStore/getSystemLanguage']) for reactively switch weekdays localization
  getWeekdays() {
    return _getWeekdays();
  },
  getWeekdaysShort() {
    return _getWeekdays('weekdaysShort');
  },
  getWeekdaysMin() {
    return _getWeekdays('weekdaysMin');
  },
  getWeekday() {
    return dayjs().weekday();
  },
  getDuration(options) {
    return dayjs.duration(options);
  },
  diff(options) {
    return dayjs.diff(options);
  },
  isToday(date) {
    return dayjs(date).isToday();
  },
  setLocale: locale => {
    dayjs.locale(locale || 'en');
  },
  updateLocale: (locale, options) => {
    dayjs.updateLocale(locale, options);
  }
};

/**
 * @typedef {Object} Weekday
 * @property {String} name - localized name
 * @property {Number} dayIndex - original JS day index
 * @property {String} date - date in format 'YYYY-MM-DD'
 * @property {Boolean} today - true for the current day of the week
 * @property {Number} timestamp - start of the day in ms
 */
/**
 * @returns {Weekday[]}
 */
function _getWeekdays(method = 'weekdays') {
  const dayjsLocaleData = dayjs.localeData();
  const weekdays = dayjsLocaleData[method]();
  const startOfDay = dayjs().startOf('day');
  const currentDay = dayjs().weekday();
  const localizedWeek = weekdays.reduce((result, day, index) => {
    const localeIndex = dayjs()
      .day(index)
      .weekday();
    const date = startOfDay.weekday(localeIndex);
    result[localeIndex] = {
      name: day,
      dayIndex: index,
      date: date.format('YYYY-MM-DD'),
      today: localeIndex === currentDay,
      timestamp: date.valueOf()
    };
    return result;
  }, new Array(7));
  return localizedWeek;
}
