<template>
  <BaseButton
    elevation="0"
    class="mini-navigate-icon"
    fab
    outlined
    @click="toPricing"
  >
    <BaseSpriteIcon icon-name="ico-money-dollar-circle-line"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToPricingIconControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  methods: {
    toPricing() {
      this.$router.push({ name: AppStateEnum.PRICING });
    }
  }
};
</script>
