<template>
  <v-dialog
    v-model="isShown"
    hide-overlay
    persistent
    :width="300"
    :content-class="contentClass"
  >
    <v-card>
      <v-card-text>
        <div class="book-not-downloaded-content">
          <div class="book-not-downloaded-message">
            {{ toasterContext.message }}
          </div>
          <button
            v-if="toasterContext.clickHandler"
            @click="toasterContext.clickHandler"
          >
            {{ toasterContext.clickLabel }}
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ToasterEvents from '@/enums/ToasterEvents';

const DEFAULT_POPUP_CLASS = 'book-not-downloaded-popup';
const HIDDEN_POPUP_CLASS = 'hidden-error-toaster';

const TOASTER_STATES = {
  HIDDEN: 'hidden',
  PRESENTED: 'presented'
};

export default {
  name: 'ErrorToaster',
  props: {
    toasterContext: {
      type: Object,
      default: () => ({
        shouldPresent: false,
        message: '',
        customClass: ''
      })
    }
  },
  data() {
    return {
      toasterState: TOASTER_STATES.HIDDEN,
      isShown: false
    };
  },
  computed: {
    contentClass() {
      return (
        DEFAULT_POPUP_CLASS +
        ` ${this.toasterContext.customClass || ''}` +
        (this.isHidden ? ` ${HIDDEN_POPUP_CLASS}` : '')
      );
    },
    isHidden() {
      return this.toasterState === TOASTER_STATES.HIDDEN;
    },
    isPresented() {
      return this.toasterState === TOASTER_STATES.PRESENTED;
    }
  },
  watch: {
    isPresented(val) {
      if (val) {
        this.$_triggerToasterDisapear();
      }
    },
    isShown(val) {
      if (val) {
        this.$_triggerToasterPresentation();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.isShown = this.toasterContext.shouldPresent;
    });
  },
  methods: {
    $_triggerToasterPresentation() {
      setTimeout(() => {
        this.toasterState = TOASTER_STATES.PRESENTED;
      }, 100);
    },
    $_triggerToasterDisapear() {
      setTimeout(() => {
        this.toasterState = TOASTER_STATES.HIDDEN;
        this.$emit('toasterEvent', {
          type: ToasterEvents.WAS_HIDDEN,
          data: {}
        });
      }, 4000);
    }
  }
};
</script>

<style lang="less" src="./ErrorToaster.less"></style>
