<template>
  <Popup
    content-class="popup-daily-goal"
    scrollable
    :header-title="headerTitle"
    :header-divider="true"
    :footer-divider="true"
    :secondary-footer-handler="close"
    :secondary-label="$t('DailyGoalPopup.cancel.label')"
    :primary-footer-handler="saveSettings"
    :primary-label="$t('DailyGoalPopup.save.label')"
    @popupEvent="eventHandler"
  >
    <template #custom-content>
      <v-card-text>
        <div class="content-default-width daily-goal-container-wrapper mb-6">
          <div class="daily-goal-title my-6">
            {{ $t('DailyGoalPopup.title.label') }}
          </div>
          <div class="daily-time-selection">
            <BaseSelect
              v-model="select"
              :items="selectItems"
              :label="$t('DailyGoalPopup.select.label')"
              :disabled="false"
              outlined
              dense
              hide-details
              prepend-inner-icon="$clockOutline"
              :menu-props="{
                offsetY: true,
                contentClass: 'daily-goal-time-select-dropdown'
              }"
            >
              <!-- item-color="#F1F5F9" -->
              <template #selection="{ item }">
                <div class="daily-time-select-item">
                  <span>{{ item.text }}</span>
                </div>
              </template>
              <template #item="{ item }">
                <div
                  class="daily-time-select-item d-flex justify-space-between align-content-center"
                >
                  <span>{{ item.text }}</span>
                  <div class="d-flex">
                    <span v-if="item.recommended" class="special-label">{{
                      $t('DailyGoalPopup.select.special.label')
                    }}</span>
                    <BaseSpriteIcon
                      custom-class="select-item-icon ms-2"
                      icon-name="ico-check"
                      color="var(--primary-color)"
                    />
                  </div>
                </div>
              </template>
            </BaseSelect>
          </div>

          <div class="daily-goal-weekdays-label mt-6">
            {{ $t('DailyGoalPopup.weekdays.label') }}
          </div>
          <div class="exceptions-block">
            <div
              v-for="item in exceptions"
              :key="item.index"
              class="exceptions-item pb-1"
            >
              <v-checkbox
                v-model="selectedDays[item.index]"
                :name="item.label"
                dense
                :label="item.label"
                hide-details
              />
            </div>
          </div>

          <div class="info-block mt-2">
            <BaseSpriteIcon icon-name="ico-info" color="#fc6539" />
            <div v-html="$t('DailyGoalPopup.description.label')"></div>
          </div>
        </div>
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex';
import dayJS from '@/dayJS';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup.vue';
import {
  DashboardSettings,
  Weekday
} from '@/classes/factories/Dashboard/DashboardSettingsFactory';

import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

const SELECT_MINUTES_LIST = [10, 20, 30, 45, 60, 90, 120, 150, 180];
const RECOMMENDED_TIME = SELECT_MINUTES_LIST[1];

export default {
  name: PopupNamesEnum.DAILY_GOAL_POPUP,
  components: { Popup, BaseSelect, BaseSpriteIcon },
  props: {
    popupContext: Object
  },
  data() {
    const weekdays = dayJS.getWeekdaysShort();
    const selectItems = this.getSelectItems();
    const defaultSelectedDays = weekdays.reduce((result, val) => {
      result[val.dayIndex] = true;
      return result;
    }, {});
    return {
      weekdays,
      headerTitle: this.$t('DailyGoalPopup.header.label'),
      select: selectItems[1].value,
      selectItems,
      selectedDays: defaultSelectedDays
    };
  },
  computed: {
    ...mapGetters('ActivityStore', { settings: 'getUserActivitySettings' }),
    exceptions() {
      return this.weekdays.map(d => ({ label: d.name, index: d.dayIndex }));
    }
  },
  mounted() {
    this.fillSettings();
  },
  methods: {
    eventHandler() {
      this.close();
    },
    async saveSettings() {
      const weekdays = Object.entries(this.selectedDays).reduce(
        (result, [key, val]) => {
          if (val) {
            const weekday = new Weekday()
              .setDay(parseInt(key))
              .setGoal(this.select)
              .build();
            result.push(weekday);
          }
          return result;
        },
        []
      );
      const settingsBuilder = new DashboardSettings();
      const settings = settingsBuilder
        .setGoal(this.select)
        .setChangedAt(
          dayJS
            .get()
            .subtract(4, 'hours')
            .valueOf()
        )
        .setWeekdays(weekdays)
        .build();
      await this.$store.dispatch(
        'ActivityStore/saveUserActivitySettings',
        settings
      );
      this.close();
    },
    close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.DAILY_GOAL_POPUP }
      });
    },
    getSelectItems() {
      return SELECT_MINUTES_LIST.map(m => ({
        text: this.$t('DailyGoalPopup.select.min', { minutes: m }),
        value: m,
        recommended: m === RECOMMENDED_TIME
      }));
    },
    fillSettings() {
      if (!this.settings) {
        return;
      }
      this.select = this.settings.goal;
      let days = this.settings.weekdays || [];
      this.selectedDays = days.reduce((result, weekday) => {
        result[weekday.day] = true;
        return result;
      }, {});
    }
  }
};
</script>

<style src="./DailyGoalPopup.less" lang="less"></style>
