class ReadingSettingsContext {
  constructor({ isCompilation, isAudioBook }) {
    this.isCompilation = isCompilation;
    this.isAudioBook = isAudioBook;
  }
}

function create(context) {
  return new ReadingSettingsContext(context);
}

export default {
  create
};
