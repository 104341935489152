class ContentRequest {
  constructor(bookId, paraId, directionRange) {
    this.bookId = bookId;
    this.paraId = paraId;
    this.directionRange = directionRange;
  }
}

class DirectionRange {
  constructor(from, to) {
    this.from = from;
    this.to = to;
  }
}

function topBottomContentRequest(bookId, paraId, paraNum, includeParaId) {
  const directionRange = _createTopBottomDirectionRange(paraNum, includeParaId);
  return new ContentRequest(bookId, paraId, directionRange);
}

function topContentRequest(bookId, paraId, paraNum, includeParaId) {
  const directionRange = _createTopDirectionRange(paraNum, includeParaId);
  return new ContentRequest(bookId, paraId, directionRange);
}

function bottomContentRequest(bookId, paraId, paraNum, includeParaId) {
  const directionRange = _createBottomDirectionRange(paraNum, includeParaId);
  return new ContentRequest(bookId, paraId, directionRange);
}

function _createTopBottomDirectionRange(paraNum, includeParaId) {
  return includeParaId
    ? new DirectionRange(-paraNum, paraNum)
    : new DirectionRange(-paraNum - 1, paraNum - 1);
}

function _createBottomDirectionRange(paraNum, includeParaId) {
  return includeParaId
    ? new DirectionRange(0, paraNum)
    : new DirectionRange(1, paraNum + 1);
}

function _createTopDirectionRange(paraNum, includeParaId) {
  return includeParaId
    ? new DirectionRange(-paraNum, 0)
    : new DirectionRange(-paraNum - 1, -1);
}

export default {
  topBottomContentRequest,
  topContentRequest,
  bottomContentRequest
};
