<template>
  <Popup
    v-click-outside="clickHandler"
    :width="popupWidth"
    :content-class="`${currentPopupClass} ${popupHiddenClass} ${isPopupHidden}`"
    :fullscreen="false"
    hide-overlay
  >
    <template #custom-header><div></div></template>

    <template #custom-content>
      <div class="popup-content">
        <span class="content-title">
          {{ $t('TagsPopup.tags.system.label') }}
        </span>
        <div v-if="categories.length" class="categories-block">
          <div
            v-for="category in categories"
            :key="category.id"
            class="tag-container"
            :class="{ 'full-width': !Boolean(customCategories.length) }"
          >
            <div
              class="tag-block"
              :class="{ active: isActive(category.id) }"
              @mousedown.stop="onCategoryClick(category)"
            >
              <div
                class="marker"
                :style="{ 'background-color': getBackgroundColor(category) }"
              />
              <span class="tag-name">{{ getCategoryName(category) }}</span>
            </div>
          </div>
        </div>
        <span v-if="customCategories.length" class="content-title">
          {{ $t('TagsPopup.tags.custom.label') }}
        </span>
        <div v-if="customCategories.length" class="categories-block">
          <div
            v-for="category in customCategories"
            :key="category.id"
            class="tag-container"
          >
            <div
              class="tag-block"
              :class="{ active: isActive(category.id) }"
              @mousedown.stop="onCategoryClick(category)"
            >
              <div
                class="marker"
                :class="getStyleByAnnCategory(category.id)"
                :style="{ 'background-color': getBackgroundColor(category) }"
              />
              <div class="custom-tag-block">
                <span class="tag-name">{{ getCategoryName(category) }}</span>
              </div>
            </div>
            <button class="d-flex" @click="deleteCategory(category)">
              <BaseSpriteIcon
                custom-class="icon-trash"
                icon-name="ico-delete"
              />
            </button>
            <button class="d-flex" @click="openCategoryPopup(category)">
              <BaseSpriteIcon icon-name="ico-edit-line" />
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #custom-footer>
      <v-card-actions class="popup-footer">
        <button class="btn-add-tag" @click="openCategoryPopup()">
          <BaseSpriteIcon icon-name="ico-plus" />
          <span class="btn-name">{{ $t('TagsPopup.primary.label') }} </span>
        </button>
      </v-card-actions>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/base/Popup/Popup.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PromiseUtil from '@/services/utils/PromiseUtil';

export default {
  name: PopupNamesEnum.TAGS_POPUP,
  components: { Popup, BaseSpriteIcon },
  data() {
    return {
      popup: null,
      subscribeMutation: null,
      currentPopupClass: 'tags-popup',
      defaultWidth: AppConstantsUtil.SMALL_POPUP_NARROW_WIDTH
    };
  },
  computed: {
    categories() {
      return this.$store.getters['CategoriesStore/getAllDefaultCategories']();
    },
    customCategories() {
      return this.$store.getters['CategoriesStore/getCustomCategories']();
    },
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.TAGS_POPUP
      );
    },
    popupHiddenClass() {
      return this.popupContext?.popupHidden ? 'hidden' : '';
    },
    themeName() {
      return this.$store.getters['ReadingSettingsStore/getThemeName'];
    },
    currentEditingAnnotationData() {
      return this.$store.getters['AnnotationsStore/getEditingAnnotationData'];
    },
    isPopupHidden() {
      return this.popupContext?.popupHidden ? 'd-none' : '';
    },
    popupWidth() {
      const element = document.querySelector(
        `.${this.popupContext?.attachedToElClass}`
      );
      return element?.getBoundingClientRect().width || this.defaultWidth;
    }
  },
  async mounted() {
    await this.$nextTick();
    this.subscribe();
    this.popup = document.querySelector(`.${this.currentPopupClass}`);
    if (this.popup) {
      this.popup.style['opacity'] = `0`;
    }
    this.setCorrectPosition();
  },
  destroyed() {
    this.unsubscribe();
    this.close();
  },
  methods: {
    subscribe() {
      this.subscribeMutation = this.$store.subscribe(({ type }) => {
        switch (type) {
          case 'MediaDetectorStore/setMedia':
            this.setCorrectPosition();
            break;
        }
      });
    },
    unsubscribe() {
      this.subscribeMutation = null;
    },
    clickHandler(e) {
      if (
        (this.dialog && e.target.closest(`.${this.currentPopupClass}`)) ||
        this.popupContext?.popupHidden ||
        !e.target.clientHeight
      ) {
        return;
      }
      this.close();
    },
    close() {
      if (this.isTagsPopupOpened()) {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.TAGS_POPUP
        });
      }
    },
    getStyleByAnnCategory(categoryId) {
      return this.$store.getters['CategoriesStore/getAnnClassByCategoryId'](
        categoryId
      );
    },
    isTagsPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.TAGS_POPUP
      );
    },
    onCategoryClick(category) {
      let annotationData = {
        ...this.currentEditingAnnotationData,
        category,
        categoryId: category.id
      };
      this.$store.dispatch(
        'AnnotationsStore/changeEditingAnnotationData',
        annotationData
      );
      this.close();
    },
    isActive(categoryId) {
      return this.popupContext.activeCategoryId === categoryId;
    },
    async setCorrectPosition() {
      await PromiseUtil.wait(200);
      const filterEl = document.querySelector('.tag-select');
      if (!this.popup || !filterEl) {
        return;
      }
      const { bottom, height, left, width } = filterEl.getBoundingClientRect();
      const popupBottom = window.innerHeight - bottom + height;

      this.popup.style['bottom'] = `${popupBottom}px`;
      this.popup.style['left'] = `${left}px`;
      this.popup.style['width'] = `${width}px`;
      this.popup.style['opacity'] = `1`;
    },
    async deleteCategory(category) {
      this.$store.dispatch(
        'ManagePopupStore/hidePopup',
        PopupNamesEnum.TAGS_POPUP
      );

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.DELETE_TAG,
        popupContext: {
          category,
          parentPopup: PopupNamesEnum.TAGS_POPUP
        }
      });
    },
    hideCurrentPopup() {
      this.$store.dispatch(
        'ManagePopupStore/hidePopup',
        PopupNamesEnum.TAGS_POPUP
      );
    },
    openCategoryPopup(category = null) {
      this.hideCurrentPopup();

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CREATE_CATEGORY,
        popupContext: {
          isEditMode: !!category,
          attachedToElClass: this.popupContext?.attachedToElClass,
          parentPopup: PopupNamesEnum.TAGS_POPUP,
          categoryToEdit: category || {}
        }
      });
    },
    getBackgroundColor(category) {
      const isNightMode = this.isNightMode();
      if (category?.underline && !isNightMode) {
        return;
      }
      return isNightMode ? category.nightColor : category.color;
    },
    isNightMode() {
      return (
        this.$store.getters['ReadingSettingsStore/getThemeName'] ===
        ThemeClassNamesEnum.NIGHT
      );
    },
    getCategoryName(category) {
      return this.$t(category.label) || category.name;
    }
  }
};
</script>

<style lang="less" src="./TagsPopup.less"></style>
