import EncodingEnum from '@/enums/EncodingEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import parseUtils from './parseUtils';

function isObject(value) {
  return !!(
    value && Object.prototype.toString.call(value) === '[object Object]'
  );
}

function isArray(value) {
  return Array.isArray(value);
}

function isHtml(value) {
  return /<\/?[a-z][\s\S]*>/i.test(value);
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function parse(data, readOptions) {
  if (!readOptions) {
    return data;
  }
  let error;
  switch (readOptions.encoding) {
    case EncodingEnum.PARSED_JSON:
      if (isObject(data) || isArray(data)) {
        return data;
      } else if (typeof data === 'string') {
        return parseUtils.parseStringToJson(data);
      }
      break;
    case EncodingEnum.UTF8:
      if (data instanceof ArrayBuffer) {
        return parseUtils.utf8ArrayToStr(data);
      }
      if (typeof data === 'string') {
        return data;
      }
      if (isObject(data) || isArray(data)) {
        return parseUtils.objToString(data);
      }
      break;
    case EncodingEnum.ARRAY_BUFFER:
      if (data instanceof ArrayBuffer) {
        return data;
      } else if (data instanceof Buffer) {
        return parseUtils.bufferToArrayBuffer(data);
      }

      break;
    case EncodingEnum.BLOB:
      if (data instanceof ArrayBuffer) {
        return new Blob([new Uint8Array(data)], {
          type: 'audio/x-m4a'
        });
      }
      if (data instanceof Blob) {
        return data;
      }
      if (isHtml(data) || isJson(data)) {
        return parseUtils.htmlToBlob(data);
      }
      if (isObject(data) || isArray(data)) {
        return parseUtils.objToBlob(data);
      }
      break;
    default:
      error = new Error(
        `Get unsuported read option type ${readOptions.encoding} in remote file handling`
      );
      error.type = CustomErrorEnum.PARSE_ERROR;
      throw error;
  }
  error = new Error(
    `Get parse remote resp to ${readOptions.encoding} unsuported data: ${data}`
  );
  error.type = CustomErrorEnum.PARSE_ERROR;
  throw error;
}

export default {
  parse
};
