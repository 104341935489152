import LoggerFactory from '@/services/utils/LoggerFactory';

const logger = LoggerFactory.getLogger('PublicationLogicService.js');

import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';

import PopupNamesEnum from '@/enums/PopupNamesEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PublicationScrollLogicService from '@/services/PublicationLogic/PublicationScrollLogicService';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import PublicationErrorHandlingLogicService from '@/services/PublicationLogic/PublicationErrorHandlingLogicService';
import publicationScrollLogicService from '@/services/PublicationLogic/PublicationScrollLogicService';
import Locator from '@shared/publication/locator.mjs';

async function playAudioFromParaId(store, data) {
  try {
    const { paraId } = data;

    store.commit('PublicationStore/setParaPlayButtonPressed', true);
    const locator = MarkerUtils.prefixedParaIdToLocator(paraId);
    const locatorStruct = PublicationScrollLogicService.createScrubberLocatorStruct(
      store,
      locator
    );
    const isInsideViewPort = locatorStruct.isInsideViewPort();
    const isPartiallyInsideViewportTop = locatorStruct.isPartiallyInsideViewPortTop();
    store.dispatch('PlaybackStore/prepareAudio', {
      paraId
    });

    let delta = 0;

    if (isPartiallyInsideViewportTop) {
      delta = -locatorStruct.getHeight();
    } else if (!isInsideViewPort) {
      delta = window.innerHeight / 3;
    }

    if (delta !== 0) {
      await PublicationScrollLogicService.animatedScrollToDelta(store, delta);
    }

    await store.dispatch('PlaybackStore/playParagraph', {
      paraId
    });
  } catch (error) {
    store.dispatch('PlaybackStore/pause');
    store.commit('PlaybackStore/setCurrentParaId', null);
    throw error;
  }
}

async function playAudioFromInTextRangeLocator(store, router, locator) {
  try {
    const paraIdsFromStartToEnd = store.getters[
      'PublicationStore/getParaIdFromStartToEnd'
    ](
      locator.startLocator.prefixedParagraphId,
      locator.endLocator.prefixedParagraphId
    );
    const publicationId = store.getters['OpenParameterStore/getPublicationId'];

    let firstVoicedParaId = null;
    let startLocator = null;

    for (const paraId of paraIdsFromStartToEnd) {
      const isParaVoiced = store.getters['PublicationStore/isElementVoiced'](
        publicationId,
        paraId
      );
      if (isParaVoiced) {
        firstVoicedParaId = paraId;
        const logicalCharOffset =
          paraId === locator.startLocator.prefixedParagraphId
            ? locator.startLocator.logicalCharOffset
            : 0;
        startLocator = new Locator.InTextLocator(paraId, logicalCharOffset);
        break;
      }
    }

    if (!firstVoicedParaId) {
      throw new Error('No voiced paragraphs found');
    }

    store.dispatch('PlaybackStore/prepareAudio', {
      paraId: firstVoicedParaId
    });

    const rangeLocator = new Locator.InTextRangeLocator(
      startLocator,
      locator.endLocator
    );
    await _poistionByStartLocator(store, router, rangeLocator);

    store.dispatch('PlaybackStore/playFromLocator', {
      bookId: publicationId,
      locator: rangeLocator
    });
  } catch (error) {
    logger.error(
      `Get error on play audio from in text range locator: ${error}`
    );
  }
}

function _poistionByStartLocator(store, router, locator) {
  const isInsideReadingArea = _isInsideReadingArea(store, locator);

  const paragraphId = locator.startLocator.prefixedParagraphId;
  const para = window.document.querySelector(`#${paragraphId}`);
  const isInsideDom = para !== null;
  if (isInsideDom && !isInsideReadingArea) {
    return PublicationScrollLogicService.animatedScrollToPara(
      store,
      paragraphId
    );
  } else if (!isInsideDom) {
    return PublicationNavigateLogicService.openPublication(store, router, {
      paragraphId
    });
  }
  return Promise.resolve();
}

function _isInsideReadingArea(store, locator) {
  return store.getters['ProgressStore/isInsideReadingArea'](
    locator.startLocator
  );
}

async function playOnLastPosition(store, router, currentScrubberLocator) {
  const locatorScruct = PublicationScrollLogicService.createScrubberLocatorStruct(
    store,
    currentScrubberLocator
  );
  const isInsideViewPort = locatorScruct.isInsideViewPort();
  const isInsideReadingArea = locatorScruct.isInsideReadingArea();
  if (isInsideViewPort) {
    store.dispatch('PlaybackStore/continue');
  } else if (isInsideReadingArea) {
    const playLocator = locatorScruct.getLocator();
    await poistionToLocator(store, router, playLocator);
    store.dispatch('PlaybackStore/playFromLocator', {
      locator: playLocator
    });
  } else {
    playOnThirdOfScreen(store, router);
  }
}

function poistionToLocator(store, router, locator) {
  if (!locator) {
    return;
  }

  const startLocator = locator.startLocator;
  const paragraphId = startLocator.prefixedParagraphId;
  const para = window.document.querySelector(`#${paragraphId}`);
  const isInsideDom = para !== null;

  if (isInsideDom) {
    const locatorStruct = PublicationScrollLogicService.createScrubberLocatorStruct(
      store,
      locator.toJSON()
    );
    const delta = locatorStruct.getTop() - Math.round(window.innerHeight / 3);
    return PublicationScrollLogicService.animatedScrollToDelta(store, delta);
  }
  return PublicationNavigateLogicService.openPublication(store, router, {
    paragraphId
  });
}

async function playOnThirdOfScreen(store, router) {
  const locatorOnThird = store.getters[
    'PublicationStore/getThirdScreenLocator'
  ]();
  const paraId = locatorOnThird.prefixedParagraphId;
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];

  const isVoiced = store.getters['PublicationStore/isElementVoiced'](
    publicationId,
    paraId
  );
  if (isVoiced) {
    const isDisabled = _applyDisabledLogic(store, paraId);
    if (isDisabled) {
      return;
    }
    store.dispatch('PlaybackStore/playOnThirdScreen', {
      bookId: publicationId,
      locatorOnThird
    });
    return;
  }

  const nextParaId = store.getters['PublicationStore/getNextVoicedParaId'](
    publicationId,
    paraId
  );
  if (!nextParaId) {
    return;
  }
  const isNextParaDisabled = _applyDisabledLogic(store, nextParaId);
  if (isNextParaDisabled) {
    return;
  }
  await PublicationScrollLogicService.scrollToParaAndPlay(
    store,
    router,
    paraId,
    nextParaId
  );
}

function _applyDisabledLogic(store, paraId) {
  const isDisabled = store.getters['PublicationStore/isParagraphDisabled'](
    paraId
  );
  if (isDisabled) {
    store.dispatch('PlaybackStore/pause');
    store.dispatch('UserStore/openLimitedAccessPopup');
  }
  return isDisabled;
}

function startFromThirdOfScreenOrContinuePlay(store, router) {
  const currentScrubberLocator =
    store.getters['PlaybackStore/getCurrentLocator'];
  if (currentScrubberLocator) {
    playOnLastPosition(store, router, currentScrubberLocator);
  } else {
    playOnThirdOfScreen(store, router);
  }
}

function openPlayPopup(store, $t) {
  try {
    _openPlayButtonPopup(store);
    _playParagraphOnOpen(store);
  } catch (error) {
    PublicationErrorHandlingLogicService.openBookErrorHandling(
      store,
      $t,
      error
    );
  }
}

function _openPlayButtonPopup(store) {
  const highlight = store.getters['OpenParameterStore/getTextHighlight'];
  const paragraphContainer = document.querySelector(
    `.${AppConstantsUtil.SCROLL_BLOCK_CLASS}`
  );
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];

  const meta = store.getters['PublicationStore/getMeta'](publicationId);
  const isOpenFromLink = store.getters['OpenParameterStore/isOpenFromLink'];

  if (
    highlight &&
    !highlight.isEmpty &&
    paragraphContainer &&
    meta?.audio &&
    isOpenFromLink
  ) {
    const { startLocator, endLocator } = _getLocators(
      highlight,
      paragraphContainer,
      store
    );
    if (!startLocator || !endLocator) {
      store.commit('OpenParameterStore/clearHighlight');
      return;
    }
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.PLAY_BUTTON_POPUP,
      popupContext: {
        startLocator,
        endLocator
      }
    });
  }
}

function _getLocators(highlight, paragraphContainer, store) {
  try {
    return {
      startLocator: highlight.getRangeLocator(paragraphContainer, store),
      endLocator: highlight.getEndLocator(paragraphContainer, store)
    };
  } catch (error) {
    logger.error(`get error on build locator from selection error: ${error}`);
    return {};
  }
}

function _playParagraphOnOpen(store) {
  store.dispatch('PlaybackStore/playPostponedParagraph');
}

async function playAudioFromStartRange(store, router) {
  await PublicationNavigateLogicService.openToStartParagraphRange(
    store,
    router
  );
  store.dispatch('PlaybackStore/setPlayInitParagraphOnStable', null, {
    root: true
  });
}

async function immediatePlayAudioFromStartRange(store, router) {
  await playAudioFromStartRange(store, router);
  publicationScrollLogicService.animatedScrollToDelta(store, 0);
  store.dispatch('PlaybackStore/playPostponedParagraph');
}

async function loadUserMaterials(store) {
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];
  const meta = store.getters['PublicationStore/getMeta'](publicationId);
  if (!meta) {
    return;
  }
  try {
    const reqParams = store.getters['MaterialsStore/getReqParams'];
    if (!reqParams?.bookId) {
      return;
    }
    const currentMode = store.getters['ContextStore/appModeGetter'];
    await store.dispatch('MaterialsStore/initMaterials', {
      paraNum: meta.paragraphsNumber,
      appMode: currentMode,
      reqParams: reqParams
    });
    const styleContainer = window.document.head;
    await store.dispatch('AnnotationsStore/injectAnnotationStyles', {
      styleContainer,
      bookId: publicationId
    });
    await store.dispatch('BookmarkStore/injectBookmarkStyles', {
      styleContainer,
      bookId: publicationId
    });
  } catch (error) {
    logger.error(`get error on load user materials error: ${error}`);
  }
}

function shortTapHandler(store, event) {
  const rects =
    event && event.target
      ? event.target.getBoundingClientRect()
      : new DOMRect(0, 0, 0, 0);

  const isPlayButtonClicked = MarkerUtils.checkClassByCoordinate(
    AppConstantsUtil.PLAY_BUTTON_CLASS,
    rects.x,
    rects.y
  );
  if (isPlayButtonClicked) {
    return;
  }
  store.dispatch('PlaybackStore/pause');
}

export default {
  playAudioFromStartRange,
  playAudioFromParaId,
  playAudioFromInTextRangeLocator,
  immediatePlayAudioFromStartRange,
  startFromThirdOfScreenOrContinuePlay,
  openPlayPopup,
  shortTapHandler,
  loadUserMaterials
};
