'use strict';

import TextUtils from './dom-utils/text-utils.mjs';
import Highlighter from './highlighter.mjs';

var SearchHighlighter = {
  /**
   *
   * @param {Array.<string>} words
   * @param {Element} element
   * @param {string} decoratorClassName
   */
  decorateSearchWords: function decorateSearchWords(
    words,
    element,
    decoratorClassName
  ) {
    var preparedText = TextUtils.extractContent(element);
    var searchTermsRealOffsets = TextUtils.locateSearchTermsInText(
      words,
      preparedText
    );
    var searchTermsStableOffsets = TextUtils.turnIntoStableOffsets(
      searchTermsRealOffsets,
      preparedText
    );

    Highlighter.decorateStableOffsets(
      searchTermsStableOffsets,
      element,
      decoratorClassName
    );
  },
  /**
   * @param  {Array.<string>} quotes
   * @param  {Element} element
   * @param  {string} decoratorClassName
   */
  decorateSearchQuotes: function decorateSearchQuotes(
    quotes,
    element,
    decoratorClassName
  ) {
    var preparedText = TextUtils.extractContent(element);
    var quotesRealOffsets = [];
    quotes.forEach(function(quote) {
      [].push.apply(
        quotesRealOffsets,
        TextUtils.searchQuoteRealOffsets(preparedText, quote)
      );
    });
    quotesRealOffsets = TextUtils.normalizingOverLoops(quotesRealOffsets);
    var searchTermsStableOffsets = TextUtils.turnIntoStableOffsets(
      quotesRealOffsets,
      preparedText
    );

    Highlighter.decorateStableOffsets(
      searchTermsStableOffsets,
      element,
      decoratorClassName
    );
  },
  decorateSearchWordsByOffsets: function(
    stableOffsets,
    element,
    decoratorClassName
  ) {
    Highlighter.decorateStableOffsets(
      stableOffsets,
      element,
      decoratorClassName
    );
  },
  decorateSearchQuotesByOffsets: function(
    stableOffsets,
    element,
    decoratorClassName
  ) {
    var quotesRealOffsets = TextUtils.normalizingOverLoops(stableOffsets);
    Highlighter.decorateStableOffsets(
      quotesRealOffsets,
      element,
      decoratorClassName
    );
  },
  undecorateByClass: Highlighter.undecorateByClass.bind(Highlighter)
};

export default SearchHighlighter;
