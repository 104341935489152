import MenuStates from '@/enums/MenuStates';
import MaterialTypes from '@shared/enums/MaterialTypes';

const DEFAULT_MENU_POSITION = {
  top: '0px',
  'margin-left': '0px'
};

const MIN_OFFSET_FROM_BORDER = 0;

class MenuPosition {
  constructor(params = {}) {
    this.resetPosition();
    this._fillPosition(params);
  }

  resetPosition() {
    delete this.top;
    delete this['margin-left'];
  }

  changePosition(params = {}) {
    this._fillPosition(params);
  }

  _fillPosition({ top, left, width = 0 }) {
    this.top = this._getTopWithoutCollision(top);
    if (left >= 0) {
      this['margin-left'] = this._getLeftWithoutCollisions(left, width);
    }

    if (left === null) {
      delete this['margin-left'];
    }
  }

  _getTopWithoutCollision(top) {
    if (top === null) {
      return this.top;
    }
    return (top < MIN_OFFSET_FROM_BORDER ? MIN_OFFSET_FROM_BORDER : top) + 'px';
  }

  _getLeftWithoutCollisions(left, width) {
    if (left === null) {
      return this['margin-left'];
    }
    if (left < 0) {
      return DEFAULT_MENU_POSITION['margin-left'];
    }
    const totalWidth = window.innerWidth;
    const rightMenuBorder = left + width;
    const delta = rightMenuBorder - totalWidth + MIN_OFFSET_FROM_BORDER;
    return (delta > 0 ? left - delta : left) + 'px';
  }
}

class ParaNoteContextMenuParams {
  constructor(params = {}) {
    this.paragraphId = params.paragraphId || '';
    this.paragraphNote = params.paragraphNote || {};
    this.menuMode = params.menuMode || '';
    this.reqParams = params.reqParams;
    this.menuPosition =
      params.menuPosition || Object.assign({}, DEFAULT_MENU_POSITION);
    this.isNoteExtraOpened = params.isNoteExtraOpened || false;
  }

  toJSON() {
    return {
      paragraphId: this.paragraphId,
      paragraphNote: this.paragraphNote,
      menuMode: this.menuMode,
      reqParams: this.reqParams,
      menuPosition: this.menuPosition,
      isNoteExtraOpened: this.isNoteExtraOpened
    };
  }

  changeParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  changeParagraphNote(paraNote) {
    this.paragraphNote = paraNote;
    return this;
  }

  setEditMode() {
    this.menuMode = MenuStates.EDIT;
    return this;
  }

  setAddMode() {
    this.menuMode = MenuStates.ADD;
    return this;
  }

  changeMenuPosition({ top, left }) {
    this.menuPosition.top = top + 'px';
    this.menuPosition['margin-left'] = left + 'px';
    return this;
  }
}

class EssayTaskPopupParams {
  constructor(params) {
    this.paragraphId = params.paragraphId;
    this.menuMode = params.menuMode || '';
    this.reqParams = params.reqParams;
    this.isMenuShown = params.isMenuShown || true;
    this.essay = params.essay || {};
  }

  toJSON() {
    return {
      paragraphId: this.paragraphId,
      menuMode: this.menuMode,
      reqParams: this.reqParams,
      isMenuShown: this.isMenuShown,
      essay: this.essay
    };
  }

  changeParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  setEditMode() {
    this.menuMode = MenuStates.EDIT;
    return this;
  }

  setAddMode() {
    this.menuMode = MenuStates.ADD;
    return this;
  }

  changeEssay(essay) {
    this.essay = essay;
    return this;
  }

  changeIsMenuShown(isShown) {
    this.isMenuShown = isShown;
    return this;
  }
}

class TestEditorParams {
  constructor(params) {
    this.testId = params.testId || '';
    this.materialsId = params.materialsId;
    this.testType = params.testType || '';
    this.paragraphId = params.paragraphId || '';
  }

  toJSON() {
    return {
      testId: this.testId,
      materialsId: this.materialsId,
      testType: this.testType,
      paragraphId: this.paragraphId
    };
  }

  changeTestId(testId) {
    this.testId = testId;
    return this;
  }

  changeParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  setQuizType() {
    this.testType = MaterialTypes.QUIZ;
    return this;
  }

  setFlashcardType() {
    this.testType = MaterialTypes.FLASHCARD;
    return this;
  }
}

function createMenuPosition(params) {
  return new MenuPosition(params);
}

function createParaNoteMenuParams(params) {
  return new ParaNoteContextMenuParams(params);
}

function createEssayTaskPopupParams(params) {
  return new EssayTaskPopupParams(params);
}

function createTestEditorParams(params) {
  return new TestEditorParams(params);
}

export default {
  createMenuPosition,
  createParaNoteMenuParams,
  createEssayTaskPopupParams,
  createTestEditorParams
};
