import get from 'lodash/get';
import UserProgressDao from '@/services/Agent/dao/UserProgressDao';
import DB from '@/services/Agent/dao/DB';

async function getProgressForSuggestions(suggestionIds, userId, dateFrom) {
  dateFrom = _getDateInMs(dateFrom);
  const prefix = DB.prefix.bookProgress;

  const progressForBookIds = await UserProgressDao.getProgressForBookIds(
    suggestionIds,
    userId
  );

  let suggestionsProgress = {};

  progressForBookIds.forEach(progressForBookId => {
    if (!progressForBookId || !progressForBookId.id) {
      return;
    }

    const bookId = progressForBookId.id.replace(prefix + '-', '');
    suggestionsProgress[bookId] = {
      readWords: 0
    };

    let ranges = get(progressForBookId, 'doc.ranges', []);
    ranges.forEach(range => {
      if (_getDateInMs(range.date) >= dateFrom) {
        suggestionsProgress[bookId].readWords += range.wordsCount;
      }
    });
  });
  return suggestionsProgress;
}

function _getDateInMs(date) {
  return new Date(date).getTime();
}

export default {
  getProgressForSuggestions
};
