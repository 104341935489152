<template>
  <PopupWrapper
    v-model="dialog"
    v-click-outside="clickOutside"
    :height="height"
    :popup-styles="popupStyles"
    v-bind="computedAttributes"
    class="popup-wrapper"
  >
    <template #header>
      <slot name="custom-header">
        <PopupHeader v-bind="computedAttributes" :close-handler="close">
        </PopupHeader>
      </slot>
    </template>
    <template #content>
      <slot name="custom-content">
        <PopupContent v-bind="computedAttributes">
          <template #apply-additional-block>
            <slot name="additional-block" />
          </template>
          <template #apply-additional-content>
            <slot name="additional-content" />
          </template>
        </PopupContent>
      </slot>
    </template>

    <template #footer>
      <slot name="custom-footer">
        <PopupFooter v-bind="computedAttributes"></PopupFooter>
      </slot>
    </template>
  </PopupWrapper>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('Popup.vue');

import PopupEventEnum from '@/enums/PopupEventEnum';

import Utils from '@/services/utils/Utils';

import PopupWrapper from '@/components/base/PopupWrapper/PopupWrapper.vue';
import PopupHeader from '@/components/base/PopupHeader/PopupHeader.vue';
import PopupContent from '@/components/base/PopupContent/PopupContent.vue';
import PopupFooter from '@/components/base/PopupFooter/PopupFooter.vue';

export default {
  components: { PopupWrapper, PopupHeader, PopupContent, PopupFooter },

  props: {
    height: {
      type: String,
      default: 'auto'
    },
    popupVisible: {
      type: Boolean,
      default: true
    },
    popupStyles: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data() {
    return {
      dialog: true,
      popupId: `popup-id-${Utils.shortUuid()}`,
      defaultAttributes: {
        width: 600
      }
    };
  },

  computed: {
    isPersistent() {
      const isEmptyAttr =
        this.$attrs.hasOwnProperty('persistent') &&
        this.$attrs.persistent === '';

      return isEmptyAttr || this.$attrs.persistent;
    },
    deleteDefaultWidth() {
      return (
        this.$attrs.hasOwnProperty('disable-width') &&
        this.$attrs['disable-width']
      );
    },
    computedAttributes() {
      const currentContentClass = this.$attrs['content-class'] || '';
      const contentClass = `${currentContentClass} ${this.popupId}`;
      if (this.deleteDefaultWidth) {
        delete this.defaultAttributes.width;
      }
      return {
        ...this.defaultAttributes,
        ...this.$attrs,
        ...{
          'content-class': contentClass
        }
      };
    }
  },
  watch: {
    popupVisible: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      }
    }
  },
  errorCaptured(error) {
    logger.error(
      `Get error on open popup error: ${error?.message} ${error?.stack}`
    );
    return false;
  },
  methods: {
    clickOutside(event) {
      if (!event || !event.target) {
        return;
      }
      const isDeletedElement = event.target.closest('body') === null;
      const isTextHighlightedInsideBaseEditor = document
        ?.getSelection()
        ?.anchorNode?.parentNode?.closest('.ql-editor');

      const isTargetOpenElement =
        event.target.closest(`.${this.popupId}`) ||
        event.target.closest('.v-menu__content') ||
        event.target.closest('.v-dialog') ||
        event.target.closest('.close-prevent');
      if (
        this.dialog &&
        !isTargetOpenElement &&
        !this.isPersistent &&
        !isDeletedElement &&
        !isTextHighlightedInsideBaseEditor
      ) {
        this.close();
      }
    },
    close() {
      this.$emit('popupEvent', { type: PopupEventEnum.CLOSE });
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/base.less';

.popup-wrapper {
  .content-default-width {
    @media @normal, @wide {
      max-width: 384px;
      margin: 0 auto;
    }
  }
}
</style>
