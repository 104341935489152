import dayJS from '@/dayJS';
import { localize as $t } from '@/i18n';
import RequestService from '@/services/RequestService.js';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

const MethodsEnum = { GET: 'get', POST: 'post' };
const CONTROLLER_NAME = 'UserProgress';

function loadUserActivity(userId) {
  return RequestService.request(
    MethodsEnum.GET,
    CONTROLLER_NAME,
    'userActivity',
    { userId },
    {}
  );
}

function updateActivitySettings(userId, settings) {
  return RequestService.request(
    MethodsEnum.POST,
    CONTROLLER_NAME,
    'updateActivitySettings',
    { userId, settings },
    {}
  );
}

export default {
  name: 'ActivityStore',
  state: () => ({
    userActivity: null,
    isNoResult: true
  }),
  getters: {
    getUserAchievements(state) {
      return state.userActivity?.achievement;
    },
    getUserActivity: state => (date = '') => {
      const activity = state.userActivity?.activity ?? [];
      return activity.filter(a => a.date >= date && a.minutesRead >= 1);
    },
    getUserActivitySettings(state) {
      return state.userActivity?.settings;
    },
    isNoResult: state => state.isNoResult
  },
  actions: {
    async fillUserActivity({ rootGetters, commit }, activity) {
      const userId = rootGetters['UserStore/getUserId'];
      let response;
      if (!activity) {
        response = await loadUserActivity(userId);
      }
      commit('setUserActivity', activity || response.data || {});
    },
    async saveUserActivitySettings({ commit, rootGetters }, settings) {
      const userId = rootGetters['UserStore/getUserId'];
      const { data } = await updateActivitySettings(userId, settings);
      commit('setUserActivity', data);
    },
    setUserActivity({ state, commit, dispatch, rootGetters }, userActivity) {
      const formattedDate = dayJS
        .get()
        .subtract(4, 'hours')
        .format(AppConstantsUtil.USER_ACTIVITY_DATE_FORMAT);
      const isInited = !!state.userActivity;
      const oldTodayActivity = (state.userActivity?.activity || []).find(
        a => a.date === formattedDate
      );
      const todayActivity = (userActivity?.activity || []).find(
        a => a.date === formattedDate
      );
      commit('setUserActivity', userActivity);
      if (
        !rootGetters['ContextStore/isActivityGoalEnabled'] ||
        !isInited ||
        oldTodayActivity?.minutesRead === todayActivity?.minutesRead ||
        oldTodayActivity?.achievedGoal ||
        !todayActivity?.achievedGoal
      ) {
        return;
      }
      dispatch(
        'ManagePopupStore/openToaster',
        {
          text: $t('UserActivity.toaster.congratulation'),
          toasterIcon: 'ico-book-line'
        },
        { root: true }
      );
    }
  },
  mutations: {
    setUserActivity(state, activity) {
      state.userActivity = Object.freeze(activity);
    },
    setIsNoResult(state, value) {
      state.isNoResult = value;
    }
  }
};
