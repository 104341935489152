import CategoriesDao from './CategoriesDao';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CategoriesController.js');

const errorStatuses = {
  NOT_FOUND: 404
};

async function get(bookId) {
  if (!bookId) {
    logger.error(`Couldn't get category by bookId: ${bookId}`);
    return [];
  }
  const res = await _getByBookId(bookId);
  return res.values || [];
}

async function _getByBookId(bookId) {
  let res;
  try {
    res = await CategoriesDao.read(bookId);
  } catch (err) {
    res = _createEmptyDoc(bookId);
    if (err.status === errorStatuses.NOT_FOUND) {
      await CategoriesDao.write(res);
    } else {
      logger.error(`Couldn't get category: ${err}`);
    }
  }
  return res;
}

async function set(categories, bookId) {
  if (!bookId) {
    logger.error(`Couldn't set category without bookId: ${bookId}`);
    return;
  }
  const doc = await _getByBookId(bookId);
  doc.values = categories;
  return CategoriesDao.write(doc);
}

function _createEmptyDoc(bookId) {
  const emptyCategories = [];
  return CategoriesDao.createDefaultDoc(emptyCategories, bookId);
}

export default {
  get,
  set
};
