<template>
  <BaseButton large text color="primary" @click="toLibrary">
    <span class="button-text">{{
      $t('OnboardingBackButtonControl.text')
    }}</span>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import AppStateEnum from '@/enums/AppStateEnum';

export default {
  name: 'OnboardingBackButtonControl',
  components: {
    BaseButton
  },
  methods: {
    toLibrary() {
      this.$router.push({
        name: AppStateEnum.MANAGE_PUBLICATION
      });
    }
  }
};
</script>

<style></style>
