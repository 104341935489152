export default {
  methods: {
    setTitle(title) {
      if (title) {
        document.title = title;
      }
    },
    getTitle() {
      const { title } = this.$options;
      if (title) {
        return typeof title === 'function' ? title.call(this) : title;
      }
    }
  }
};
