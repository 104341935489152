export default {
  ACCOUNT_DELETED_SUCCESS_POPUP: 'AccountDeletedSuccessPopup',
  ADD_TO_COMPILATION_POPUP: 'AddToCompilationPopup',
  AFFILIATE_DETAILS_POPUP: 'AffiliateDetailsPopup',
  ANNOTATION_INFO_POPUP: 'AnnInfoPopup',
  APP_MENU_WRAPPER: 'AppMenuWrapper',
  ASSESSMENT_CONTAINER: 'AssessmentContainer',
  BOOK_DOWNLOADED_INCORRECT: 'BookDownloadedIncorrect',
  BOOK_INFO: 'BookInfo',
  BOOK_NOT_DOWNLOADED_POPUP: 'BookNotDownloadedPopup',
  BOOKMARK_POPUP: 'BookmarkPopup',
  CANCEL_DELETE_ACCOUNT_POPUP: 'CancelDeleteAccountPopup',
  CONFIRM_POPUP: 'ConfirmPopup',
  CONFIRMATION_TASK_POPUP: 'ConfirmationTaskPopup',
  CONGRATULATION_POPUP: 'CongratulationPopup',
  CONNECT: 'Connect',
  CONTACT_US_POPUP: 'ContactUsPopup',
  CREATE_ANNOTATION_POPUP: 'CreateAnnotationPopup',
  CREATE_CAMPAIGN_POPUP: 'CreateCampaignPopup',
  CREATE_CATEGORY: 'CategoryPopup',
  CREATE_COMPILATION_POPUP: 'CreateCompilationPopup',
  CREATE_SECTION_POPUP: 'CreateSectionPopup',
  CREATE_STUDY_COURSE: 'CreateStudyCourse',
  DELETE_TAG: 'DeleteTagPopup',
  DICTIONARY_POPUP: 'DictionaryPopup',
  ESSAY_TASK_POPUP: 'EssayTaskPopup',
  EXTENDING_MENU: 'ExtendingMenu',
  EXTERNAL_BLOG_POPUP: 'ExternalBlogPopup',
  EXTERNAL_BLOG_POST_POPUP: 'ExternalBlogPostPopup',
  EXTRAS_POPUP: 'ExtrasPopup',
  IMAGE_CROP_POPUP: 'ImageCropPopup',
  LANGUAGE_LIST: 'LanguageList',
  LANGUAGE_POPUP: 'LanguagePopup',
  LOOK_UP: 'LookUp',
  MOVE_TO_SECTION_POPUP: 'MoveToSectionPopup',
  PLAY_BUTTON_POPUP: 'PlayButtonPopup',
  PAYMENT_ACTION_POPUP: 'PaymentActionPopup',
  PAYMENT_OFFLINE_POPUP: 'PaymentOfflinePopup',
  PROFILE_POPUP: 'ProfilePopup',
  READING_SETTINGS_POPUP: 'ReadingSettingsPopup',
  READING_SETTINGS_BOTTOM_POPUP: 'ReadingSettingsBottomPopup',
  RECENT_BOOKS_WRAPPER: 'RecentBooksWrapper',
  SALES_POPUP: 'SalesPopup',
  SEARCH: 'Search',
  SEARCH_DUMMY: 'SearchDummyPopup',
  SOCIAL_SHARING_POPUP: 'SocialSharingPopup',
  SUPPORT_POPUP: 'SupportPopup',
  TABLE_OF_CONTENTS_POPUP: 'TableOfContentsPopup',
  TAGS_POPUP: 'TagsPopup',
  TEST_EDITOR: 'TestEditor',
  TRANSLATE_POPUP: 'TranslatePopup',
  TYPO_POPUP: 'TypoPopup',
  COMPILATION_COVER_CHANGER_POPUP: 'CompilationCoverChangerPopup',
  COMPILATION_ITEM_MENU: 'CompilationItemMenu',
  COMPILATION_DELETE_POPUP: 'CompilationDeletePopup',
  COMPILATION_MAKE_DEFAULT_POPUP: 'CompilationMakeDefaultPopup',
  DAILY_GOAL_POPUP: 'DailyGoalPopup',
  ACTIVITY_MENU: 'ActivityMenu',
  SHARE_ACTIVITY_POPUP: 'ShareActivityPopup',
  VOCABULARY_ASSESSMENT_DIALOG: 'VocabularyAssessmentDialog',
  USER_SEGMENTATION_POPUP: 'UserSegmentationPopup',
  SHORTENER_POPUP: 'ShortenerPopup'
};
