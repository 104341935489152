<template>
  <Popup
    :content-class="extrasClass"
    hide-overlay
    scrollable
    persistent
    no-click-animation
    width="455"
    :retain-focus="false"
    :header-title="extrasHeaderTitle"
    :header-divider="true"
    :footer-divider="false"
    transition="scroll-x-transition"
    @popupEvent="popupEventHandler"
  >
    <template #custom-content>
      <v-card-text>
        <component
          :is="extrasNameComponent"
          :popup-context="popupContext"
          @closeHandler="popupEventHandler"
        />
      </v-card-text>
    </template>
    <template #custom-footer></template>
  </Popup>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import Popup from '@/components/base/Popup/Popup.vue';
import ReadingSettingsTab from '@/components/views/ReadingSettings/ReadingSettingsComponent/ReadingSettingsComponent';
import NotesTab from '@/components/views/Extras/NotesTab/NotesTab';
import BookmarksTab from '@/components/views/Extras/BookmarksListTab/BookmarksListTab';
import TableOfContentsTab from '@/components/views/Extras/TableOfContentsTab/TableOfContentsTab';

const SocialSharingTab = () =>
  import('@/components/views/SocialSharingContent/SocialSharingContent');

export default {
  name: PopupNamesEnum.EXTRAS_POPUP,
  components: {
    Popup,
    ReadingSettingsTab,
    NotesTab,
    BookmarksTab,
    TableOfContentsTab,
    SocialSharingTab
  },
  props: {
    popupContext: {
      type: Object
    }
  },
  data() {
    return {
      extrasClass: AppConstantsUtil.EXTRAS_POPUP
    };
  },
  computed: {
    extrasContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    },
    extrasNameComponent() {
      return this.extrasContext.extrasComponentName;
    },
    extrasHeaderTitle() {
      return this.extrasContext.extrasHeaderTitle;
    }
  },
  destroyed() {
    this.$_closeExtrasPopup();
  },
  methods: {
    async popupEventHandler({ type }) {
      switch (type) {
        case PopupEventEnum.CLOSE:
          this.$_closeExtrasPopup();
          break;
      }
    },
    async $_changeCurrentChapterHandler({ paraId }) {
      const isParaInReadingArea = this.$store.getters[
        'ProgressStore/isParaInReadingArea'
      ](paraId);
      if (isParaInReadingArea) {
        return;
      }

      this.$store.dispatch('OpenParameterStore/openToParagraph', {
        paraId
      });
    },
    annInfoPopupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.ANNOTATION_INFO_POPUP
      );
    },
    $_closeExtrasPopup() {
      const annInfoPopupContext = this.annInfoPopupContext();
      if (annInfoPopupContext?.openFromExtras) {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.ANNOTATION_INFO_POPUP
        });
      }

      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.EXTRAS_POPUP
      });
    }
  }
};
</script>

<style src="./ExtrasPopup.less" lang="less"></style>
