import UserProgressDao from '@/services/Agent/dao/UserProgressDao';
import LibraryProgressDao from '@/services/Agent/dao/LibraryProgressDao';
import UserActivityDao from '@/services/Agent/dao/UserActivityDao';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
const taskTypes = { BOOK_PROGRESS: 'bookprogress' };

export default {
  POST: {
    saveProgress,
    resetBookProgress,
    updateActivitySettings
    // saveFlashCardProgress,
    // saveStudyGuideProgress,
    // saveStudyClassProgress
  },
  GET: {
    getProgress,
    libraryProgress,
    userActivity
    // getFlashCardProgress,
    // getStudyGuideProgress,
    // getStudyClassProgress
  }
};

class BookProgressData {
  constructor(progressData) {
    this.readingCurrentLocator = progressData.readingCurrentLocator;
    this.audioCurrentLocator = progressData.audioCurrentLocator;
    this.type = taskTypes.BOOK_PROGRESS;
    this.ranges = progressData.ranges;
    this.readingArea = progressData.readingArea;
    this.studyItemWordsCountByDates = progressData.studyItemWordsCountByDates;
    this.modifiedAt = new Date().getTime();
  }
}

function getProgress({ publicationId, type }) {
  switch (type) {
    case PublicationsTypesEnum.BOOK:
      return UserProgressDao.getBookProgress(publicationId);
    default:
      throw new Error(`Unknown publication type in getProgress - ${type}`);
  }
}

async function saveProgress({
  publicationId,
  type,
  progressData,
  summaryData
}) {
  switch (type) {
    case PublicationsTypesEnum.BOOK: {
      const updateData = new BookProgressData(progressData);
      const readWords = _calcReadWords(progressData.ranges);

      const readPercent = _calcReadPercent(summaryData.wordsCount, readWords);
      const [updatedBookProgress, updatedLibraryProgress] = await Promise.all([
        updateBookProgress(publicationId, updateData),
        _setLibraryProgress(
          publicationId,
          summaryData.userId,
          readPercent,
          summaryData.wordsCount,
          readWords
        )
      ]);
      const updatedUserActivity = await _updateUserActivity(
        summaryData.userId,
        publicationId,
        progressData.studyItemWordsCountByDates,
        updatedLibraryProgress.progressSummary
      );
      return [updatedBookProgress, updatedLibraryProgress, updatedUserActivity];
    }
    default:
      throw new Error(`Unknown publication type in saveProgress - ${type}`);
  }
}

async function updateBookProgress(publicationId, updateData) {
  await UserProgressDao.updateBookProgress(publicationId, updateData);
  return UserProgressDao.getBookProgress(publicationId);
}

async function _updateUserActivity(
  userId,
  publicationId,
  studyItemWordsCountByDates,
  progressSummary
) {
  await UserActivityDao.updateActivity(
    userId,
    publicationId,
    studyItemWordsCountByDates,
    progressSummary
  );
  return userActivity({ userId });
}

function userActivity({ userId }) {
  return UserActivityDao.getActivity(userId);
}

async function updateActivitySettings({ userId, settings }) {
  await UserActivityDao.updateActivitySettings(userId, settings);
  return userActivity({ userId });
}

async function _setLibraryProgress(
  publicationId,
  userId,
  readPercent,
  totalWordsPerBook,
  readWords
) {
  const libraryProgressDoc = await LibraryProgressDao.getLibraryProgress(
    userId
  );
  libraryProgressDoc.updatePublicationProgress(
    publicationId,
    readPercent,
    totalWordsPerBook,
    readWords
  );
  await LibraryProgressDao.updateLibraryProgress(userId, libraryProgressDoc);
  return LibraryProgressDao.getLibraryProgress(userId);
}

function _calcReadPercent(wordsCount, readWords) {
  return Math.round((readWords / wordsCount) * 10000) / 100;
}

function _calcReadWords(ranges) {
  return ranges.reduce((readWords, range) => {
    readWords += range.wordsCount;
    return readWords;
  }, 0);
}

function resetBookProgress({ publicationId, userId }) {
  return Promise.all([
    UserProgressDao.resetBookProgress(publicationId),
    _setLibraryProgress(publicationId, userId, 0, 0, 0)
  ]);
}

function libraryProgress({ userId }) {
  return LibraryProgressDao.getLibraryProgress(userId);
}
