export default {
  PRESENT_PUBLICATION: 'slug',
  COLLECTION: 'collection-id',
  NOT_RENDERED_BOOK: 'new_url',
  AUTHOR_PAGE: 'author-authorHash',
  MANAGE_PUBLICATION: 'library',
  MANAGE_PUBLICATION_LANGUAGE: 'library_language',
  MANAGE_COMPILATION: 'extracts',
  MANAGE_ABOUT: 'about',
  PRICING: 'pricing',
  BLOG: 'blog',
  BLOG_ARTICLE: 'articleSlug',
  FOR_PARENTS: 'forParents',
  FOR_STUDENTS: 'forStudents',
  FOR_TEACHERS: 'forTeachers',
  HELP_CENTER: 'helpCenter',
  LOGIN_SUBSCRIBE: 'login_subscribe',
  AFFILIATES: 'affiliates',
  AFFILIATE_DASHBOARD: 'affiliates-affiliateId',
  ASSESSMENT: 'assessment',
  RESET_PASSWORD: 'resetPassword',
  CONFIRMATION: 'confirmation',
  LOGIN: 'login',
  CATEGORY: 'category-name',
  GENRE_PAGE: 'genre-name',
  DELETE_ACCOUNT: 'delete-account',
  FAVORITES: 'category-favorites',
  ACCOUNT_SETTINGS: 'account',
  SAVED_FOR_OFFLINE: 'savedForOffline',
  ACTIVITY_DASHBOARD: 'dashboard',
  READING_GROUP: 'dashboard-reading-group',
  SEARCH: 'search',
  ONBOARDING: 'onboarding',
  THANK_YOU_FOR_REGISTRATION: 'thank_you_for_registration',
  VERIFY_EMAIL: 'verify_email',
  FREQUENTLY_ASKED_QUESTIONS: 'faq',

  // Subscription settings
  SETTINGS_PLANS: 'subscription',
  SETTINGS_CARDS: 'subscription-cards',
  SETTINGS_HISTORY: 'subscription-history',

  // Editor
  TESTS: 'tests',
  BOOKS_LIST: 'booksList',
  FORBIDDEN_IN_BROWSER: 'forbiddenInBrowser',
  DEVELOP_STUDYCOURSE: 'developstudycourse-syllabusId',
  LIBRARY_SET: 'library_set',
  DEVELOP_LIBRARY_SET: 'developlibraryset-setId',
  MANAGE_USERS: 'manageUsers',
  GOOGLE_ANALYTICS: 'google-analytics',

  MANAGE_VOCABULARY_ASSESSMENT: 'manageVocabularyAssessment',
  TASK_CONFIRMATION: 'taskConfirmation',
  STUDY_CLASS: 'studyClass',
  MY_CAMPAIGNS: 'My campaigns',
  DEFAULT_STATE: 'DefaultState'
};
