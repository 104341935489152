import UnifiedSettingsService from '@/services/UnifiedSettingsService';

const DOWNLOAD_INFO = 'downloadInfo';
const QUEUE = 'queue';

class QueueItem {
  constructor(publicationId) {
    this.publicationId = publicationId;
  }

  isEqual(queueItem) {
    return (
      queueItem.constructor === QueueItem &&
      this.publicationId === queueItem.publicationId
    );
  }

  getPubId() {
    return this.publicationId;
  }
}

class AssetQueueItem extends QueueItem {
  constructor(publicationId, assetName) {
    super(publicationId);
    this.assetName = assetName;
  }

  isEqual(queueItem) {
    return (
      queueItem.constructor === AssetQueueItem &&
      this.publicationId === queueItem.publicationId &&
      this.assetName === queueItem.assetName
    );
  }

  isPublicationItem(publicationId) {
    return this.publicationId === publicationId;
  }

  getAssetName() {
    return this.assetName;
  }
}

function getQueue() {
  return _getQueue();
}

function createQueueItem(publicationId, assetName) {
  return assetName
    ? new AssetQueueItem(publicationId, assetName)
    : new QueueItem(publicationId);
}

function putFileInQueue(publicationId, assetName) {
  const newQueueItem = createQueueItem(publicationId, assetName);
  let queue = _getQueue();
  const isItemInQueue = queue.some(queueItem =>
    newQueueItem.isEqual(queueItem)
  );
  if (!isItemInQueue) {
    queue.push(newQueueItem);
    _putQueue(queue);
  }
}

function deleteFileFromQueue(publicationId, assetName) {
  const deleteItem = createQueueItem(publicationId, assetName);
  let queue = _getQueue();
  const index = queue.findIndex(queueItem => deleteItem.isEqual(queueItem));
  if (index === -1) {
    return;
  }
  queue.splice(index, 1);
  _putQueue(queue);
}

function clearQueue() {
  const emptyQueue = [];
  _putQueue(emptyQueue);
}

function isPublicationInQueue(publicationId) {
  let queue = _getQueue();
  return queue.some(queueItem => queueItem.isPublicationItem(publicationId));
}

function _getQueue() {
  const serializedQueue =
    UnifiedSettingsService.getSetting(DOWNLOAD_INFO, QUEUE) || [];
  return serializedQueue.map(serializedQueueItem =>
    createQueueItem(
      serializedQueueItem.publicationId,
      serializedQueueItem.assetName
    )
  );
}

function _putQueue(queue) {
  UnifiedSettingsService.setSetting(DOWNLOAD_INFO, QUEUE, queue);
}

export default {
  getQueue,
  putFileInQueue,
  deleteFileFromQueue,
  clearQueue,
  isPublicationInQueue,
  QueueItem,
  AssetQueueItem
};
