module.exports = class PublicationStatus {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.status = data.status;
      this.language = data.language;
    }
  }
  setId(id) {
    this.id = id;
    return this;
  }
  setStatus(status) {
    this.status = status;
    return this;
  }
  setLanguage(lang) {
    this.language = lang;
    return this;
  }
  isValid() {
    return !!(this.id && this.status && this.language);
  }
  build() {
    return {
      id: this.id,
      status: this.status,
      language: this.language
    };
  }
};
