import { render, staticRenderFns } from "./VocabularyAssessmentPageTitleControl.vue?vue&type=template&id=4289f175&scoped=true"
import script from "./VocabularyAssessmentPageTitleControl.vue?vue&type=script&lang=js"
export * from "./VocabularyAssessmentPageTitleControl.vue?vue&type=script&lang=js"
import style0 from "./VocabularyAssessmentPageTitleControl.vue?vue&type=style&index=0&id=4289f175&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4289f175",
  null
  
)

export default component.exports