'use strict';

import farsiWordsUtils from './farsiWordsUtils.mjs';
import farsiStopWords from './farsiStopWords.mjs';

var exp = {};
[farsiWordsUtils, farsiStopWords].forEach(function(module) {
  Object.keys(module).forEach(function(methodName) {
    exp[methodName] = module[methodName];
  });
});

export default exp;
