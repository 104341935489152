import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('agentService.js');

import UserStatusesEnum from '@/enums/UserStatusesEnum';
import AgentErrorEnum from '@/enums/AgentErrorEnum';
import OfflineModeUtils from '@/services/utils/OfflineModeUtils';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import PromiseUtil from '@/services/utils/PromiseUtil';
import RestService from '@/services/RestService.js';

import tools from './tools';
import agentStorage from './agentStorage';
import AgentContext from './AgentContext';
import controllers from './controllers';

const Promise = tools.Promise;

const initState = {
  userAuthenticated: false,
  requestSeq: 0,
  storageInitialized: false
};
let extendState = {};

let agentServiceConfig = _getInitState();

function _getInitState() {
  return _copy(initState);
}

function _getExtendState() {
  return _copy(extendState);
}

function _copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}
let credentialsDefer = PromiseUtil.createDeferred();

let initPromise;

function init(Context) {
  try {
    Object.assign(agentServiceConfig, Context.parameters.agent);
    extendState = _copy(agentServiceConfig);
    AgentContext.init(Context);
    agentStorage.init(Context);
  } catch (err) {
    logger.fatal(`Error while initializing Agent service. Error: ${err}`);
  }
}

function syncUserData() {
  if (agentServiceConfig.enabled) {
    agentStorage.syncNow([agentStorage.dbNames.userRW]);
  }
}

async function getInitData() {
  try {
    const result = await RestService.restRequest('get', 'Couch', 'getInitDump');
    return result?.data;
  } catch (error) {
    logger.error(`Couldn't get init data from the server: ${error.message}`);
  }
}

/**
 * syncInfo - credentials for sync
 * @return {Promise} resolved when offline storage is ready
 */
async function setCredentials(user, { forceInitConnection, initData } = {}) {
  const { indexDB } = await FeatureDetectorService.getDetections();
  if (!indexDB) {
    const message = 'Agent functionality disabled, indexDB in not available';
    if (process.client) {
      logger.warn(message);
    }
    credentialsDefer.resolve(message);
    return;
  }
  var syncInfo = user.sync;
  if (!syncInfo) {
    const message = `Offline mode disabled due to invalid credentials userId: ${user.id}`;
    logger.fatal(message);
    agentServiceConfig.enabled = false;
    credentialsDefer.resolve(message);
    return;
  }
  if (forceInitConnection) {
    agentServiceConfig.enabled = true;
  }
  agentServiceConfig.userAuthenticated = true;

  if (agentServiceConfig.enabled) {
    initPromise = initAgent({ syncInfo, user, forceInitConnection, initData });
  } else {
    credentialsDefer.resolve('Agent disabled');
  }
}

async function initAgent({ syncInfo, user, forceInitConnection, initData }) {
  try {
    const initResponse = await agentStorage.initStorage(
      syncInfo.user,
      user.active === UserStatusesEnum.UNKNOWN,
      {
        forceInitConnection
      }
    );

    const { initialized } = initResponse;
    agentServiceConfig.storageInitialized = initialized;
    await agentStorage.setUserInfo(user);
    if (initData) {
      await agentStorage.fillStorage(initData);
    }
    if (agentServiceConfig.enabled && agentServiceConfig._sync_enabled) {
      await agentStorage.startSync(syncInfo);
    }
  } catch (error) {
    switch (error.type) {
      case AgentErrorEnum.INIT_STORE_ERROR:
      case AgentErrorEnum.SET_USER_INFO_ERROR:
      case AgentErrorEnum.POUCH_CONFIG_ERROR:
      case AgentErrorEnum.START_SYNC_ERROR:
        agentServiceConfig.enabled = false;
        logger.fatal(error, {
          sendError: true
        });
        break;
      default:
        logger.fatal('Unknown init error: ' + error.message, {
          sendError: true
        });
        break;
    }
  }
  credentialsDefer.resolve('Done');
}

function logout(isGuest) {
  if (!isAgentReady()) {
    return;
  }
  agentServiceConfig = _getExtendState();
  return agentStorage.logout(isGuest);
}

/**
 * Create ajax response structure
 *
 * @return {function(object):AjaxResponse}
 * @private
 */
function createFakeAjaxResponse() {
  return function(data) {
    return {
      status: 200,
      statusText: 'OK',
      //headers: function(name){}, // TODO: make it on demand
      data: data
    };
  };
}

function localRequest(method, controller, action, data) {
  const agentMethod = _convertToAgentMethod(method);
  // controller = controller.toLowerCase();

  var pseudoUrl = agentMethod + ' ' + controller + '/' + action;

  logger.trace('Offline request ' + pseudoUrl);
  return initPromise
    .then(function() {
      return controllers[controller][agentMethod][action](data);
    })
    .then(createFakeAjaxResponse())
    .catch(function(err) {
      logger.error(' response failed ' + pseudoUrl, err);
      if (err.name !== 'conflict') {
        throw err;
      }
    });
}

function _convertToAgentMethod(method) {
  return (method && method.toUpperCase()) || 'GET';
}

function isAgentReady() {
  return (
    agentServiceConfig.enabled &&
    agentServiceConfig.userAuthenticated &&
    agentServiceConfig.storageInitialized
  );
}

async function isAllowRequest(method, controller, action) {
  await credentialsDefer.promise;
  const isReady = isAgentReady();
  const exist = isExist(method, controller, action);
  const isAllow = isReady && exist;
  if (!isAllow) {
    logger.info(
      `enabled: ${agentServiceConfig.enabled} userAuthenticated: ${agentServiceConfig.userAuthenticated} storageInitialized: ${agentServiceConfig.storageInitialized} ` +
        `isExist: ${exist} method: ${method} controller: ${controller} action: ${action}`
    );
  }
  return isAllow;
}

function isExist(method, controller, action) {
  // jshint ignore:line
  const agentMethod = _convertToAgentMethod(method);
  // controller = controller.toLowerCase();
  action = action || '';
  const exist = Boolean(
    controllers[controller] &&
      controllers[controller][agentMethod] &&
      controllers[controller][agentMethod][action]
  );
  return exist;
}

async function syncData() {
  await credentialsDefer.promise;
  const isOnline = OfflineModeUtils.checkIsOnline();
  const canSync = isAgentReady() && isOnline;
  const response = {
    synced: false,
    progressEvent: false,
    isOnline
  };
  if (canSync) {
    try {
      const responses = await agentStorage.syncNow();
      response.synced = responses.every(info => {
        if (
          info &&
          info.hasOwnProperty('pull') &&
          info.hasOwnProperty('push')
        ) {
          return _checkSync(info.pull) && _checkSync(info.push);
        }

        return _checkSync(info);
        function _checkSync(_info) {
          return _info && _info.status === 'complete' && _info.ok;
        }
      });
    } catch (error) {
      if (error instanceof ProgressEvent) {
        logger.warn(`get progress event warn: ${error}`);
        response.progressEvent = true;
      } else {
        logger.error(`get error on sync data error: ${error}`);
      }
    }
  }
  return Promise.resolve(response);
}

export default {
  init,
  getInitData,
  isAllowRequest,
  syncUserData,
  setCredentials,
  on: agentStorage.on,
  off: agentStorage.off,
  syncData,
  logout,
  localRequest,
  createFakeAjaxResponse
};
