import RestService from '@/services/RestService';

import CustomErrorEnum from '@/enums/CustomErrorEnum';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('WikiService.js');

const cancelFn = {
  wiki: null,
  wiktionary: null,
  translator: null
};

async function getWiktionaryDefinition(termName) {
  let res = {};
  if (cancelFn.wiktionary) {
    cancelFn.wiktionary({
      message: 'Cancel wiktionary explanation',
      type: CustomErrorEnum.CANCEL_REQUEST
    });
  }

  const query = {
    termName
  };
  const definitionPromise = RestService.restRequest(
    'get',
    'Dict',
    'definition',
    query
  );
  cancelFn.wiktionary = definitionPromise.cancel;
  try {
    res = await definitionPromise;
  } catch (err) {
    logger.warn(`Error on process get definition request: ${err}`);
  }
  cancelFn.wiktionary = null;
  return res;
}

async function getWikiExplanation(term) {
  let res = {};
  if (cancelFn.wiki) {
    cancelFn.wiki({
      message: 'Cancel wiki explanation',
      type: CustomErrorEnum.CANCEL_REQUEST
    });
  }

  const query = {
    term
  };
  const wikiPromise = RestService.restRequest(
    'get',
    'Dict',
    'wikiExplanation',
    query
  );
  cancelFn.wiki = wikiPromise.cancel;
  try {
    res = await wikiPromise;
  } catch (err) {
    logger.warn(`Error on process get wiki explanation request: ${err}`);
  }
  cancelFn.wiki = null;
  return res;
}

async function getTranslation(term, targetLang, sourceLang) {
  let res = {};
  if (cancelFn.translator) {
    cancelFn.translator({
      message: 'Cancel translate',
      type: CustomErrorEnum.CANCEL_REQUEST
    });
  }

  const query = {
    term,
    targetLang,
    sourceLang
  };
  const translationPromise = RestService.restRequest(
    'get',
    'Dict',
    'translate',
    query
  );
  cancelFn.translator = translationPromise.cancel;
  try {
    res = await translationPromise;
  } catch (err) {
    logger.warn(`Error on process get translation request: ${err}`);
  }
  cancelFn.translator = null;
  return res;
}

function cancelAllRequests() {
  for (let cancel of Object.values(cancelFn)) {
    if (cancel) {
      cancel({
        message: 'Cancel all request',
        type: CustomErrorEnum.CANCEL_REQUEST
      });
    }
  }
}

async function getTranslatorLanguages() {
  let languages = [];
  try {
    const res = await RestService.restRequest(
      'get',
      'Dict',
      'getTranslatorLanguages',
      {}
    );
    languages = res.data.languages;
  } catch (error) {
    logger.error(
      `Get error on initTranslatorLanguages error ${error}, set empty languages array`
    );
  }
  return languages;
}

export default {
  getWiktionaryDefinition,
  getWikiExplanation,
  getTranslation,
  cancelAllRequests,
  getTranslatorLanguages
};
