import AuthTypeEnum from '@/enums/AuthTypeEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import LoginStatesEnum from '@/enums/LoginStatesEnum';

import Utils from '@/services/utils/Utils';
import QueryParamService from '@/services/QueryParamService';
import LocalStorageService from '@/services/LocalStorageService';
import LoggerFactory from '@/services/utils/LoggerFactory';
import ExternalBlogService from '@/services/ExternalBlogService';

import ExternalBlogTypesEnum from '@/enums/ExternalBlogTypesEnum';
const logger = LoggerFactory.getLogger('LinksHandlerService.js');

async function handle(store, router, route) {
  const confirmResult = await processConfirmationLink(store, route, router);
  if (confirmResult) {
    await _processConfirmationTask(store, route, router, confirmResult);
  }
  await _openApplyPromoCodePopup(store, router);
  _openConnectPopup(store, router);
}

async function processConfirmationLink(store, route, router) {
  const query = getQueryFromHashIfNeed(route, router);
  if (!_needHandleConfirmationHashCode(query)) {
    return;
  }
  route = { ...route, query };
  const response = await store.dispatch('UserStore/confirmTask', query);
  return response.data;
}

function checkIsBloggerOauth(router) {
  const query = QueryParamService.getAllParams(router);
  return query.state === AuthTypeEnum.BLOGGER;
}

function checkIsOauth(router) {
  const query = QueryParamService.getAllParams(router);
  return !!query.state;
}

async function _processConfirmationTask(store, route, router, confirmResult) {
  const query = getQueryFromHashIfNeed(route, router);
  route = { ...route, query };
  await handleLinkParams(store, route, router, confirmResult);
}

function getQueryFromHashIfNeed(route, router) {
  return route.hash && isOauthRedirect(route.hash)
    ? Utils.parseUrlQuery(Utils.removeHashIfNeeded(route.hash))
    : QueryParamService.getAllParams(router);
}

function _needHandleConfirmationHashCode(query) {
  return query.taskConfirmationHashCode || query.state || query.access_token;
}

function processUid(store, router) {
  const query = QueryParamService.getAllParams(router);

  if (!query?.uid && !query?.promocode) {
    return;
  }
  store.dispatch('UserStore/processAffiliateQuery', query);

  if (query?.uid && !query?.promocode) {
    QueryParamService.removeParams(router, ['uid']);
  }
}

function _openConnectPopup(store, router) {
  const query = QueryParamService.getAllParams(router);

  if (!query?.connect || !query?.redirect) {
    QueryParamService.removeParams(router, ['connect', 'redirect']);
    return;
  }
  const connect = query.connect;
  const redirectUrl = decodeURIComponent(query.redirect);
  LocalStorageService.set('redirectAfterLogin', redirectUrl);
  QueryParamService.removeParams(router, ['connect', 'redirect']);
  store.dispatch('ManagePopupStore/openPopup', {
    name: PopupNamesEnum.CONNECT,
    popupContext: {
      initState:
        connect === 'login'
          ? LoginStatesEnum.LOGIN_BY_EMAIL
          : LoginStatesEnum.REGISTRATION
    }
  });
}

function _openApplyPromoCodePopup(store, router) {
  const query = QueryParamService.getAllParams(router);

  if (!query?.promocode) {
    return;
  }

  const promoCode = query.promocode;
  QueryParamService.removeParams(router, ['promoCode']);

  return store.dispatch('PaymentsStore/getPromoCodeInfo', promoCode);
}

function isOauthRedirect(url) {
  return url.includes('state=');
}

function handleLinkParams(store, route, router, confirmResult) {
  const isBloggerOauth = checkIsBloggerOauth(router);
  const isOauth = checkIsOauth(router);
  const taskConfirmationHashCode = QueryParamService.getQueryParamByRoute(
    'taskConfirmationHashCode',
    route
  );
  const isLoginByHashcode = !!taskConfirmationHashCode;
  if (isBloggerOauth) {
    return handleBloggerOauth(route);
  } else if (isOauth) {
    return handleOauth(store, route, router);
  } else if (isLoginByHashcode) {
    return handleLoginByHashcode(store, route, router, confirmResult);
  } else {
    const isAuthenticated = store.getters['UserStore/isAuthenticated'];
    const isGuest = store.getters['UserStore/isGuestUser'];
    if (isGuest || isAuthenticated) {
      onAuthSuccess(store, router);
    }
  }
}

function handleBloggerOauth(route) {
  const query = Utils.parseUrlQuery(route.fullPath);
  return ExternalBlogService.handleExternalBlogLogin(
    ExternalBlogTypesEnum.BLOGGER,
    query
  );
}

async function handleOauth(store, route, router) {
  const query = QueryParamService.getAllParams(router);
  if (query?.error_code) {
    logger.error(`Get oauth error response by url: ${window.location.href}`);
    QueryParamService.removeAllParams(router);
    return;
  }
  try {
    const authResult = await store.dispatch('UserStore/performOauthLogin', {
      loginInfo: query
    });
    onAuthSuccess(store, router, null, authResult);
  } catch (error) {
    logger.error(`Get error on handle oauth error: ${error}`);
    QueryParamService.removeAllParams(router);
  }
}

function onAuthSuccess(store, router, confirmResult, authResult) {
  store.commit('ProgressStore/resetStore');

  const query = QueryParamService.getAllParams(router);
  store.dispatch('LoginStore/showCongratulationPopupIfNeed', {
    confirmResult,
    authResult,
    hashCode: query?.taskConfirmationHashCode
  });
  if (query?.isDevice === 'true') {
    return;
  }

  if (confirmResult?.data?.taskType === 'ResetPassword') {
    const defaultRoute = store.getters['ContextStore/getDefaultRoute'];
    const currentRoute = store.getters['ContextStore/appState'];
    const isEditorApp = store.getters['ContextStore/isEditorApp'];

    QueryParamService.removeAllParams(this.$router);
    if (isEditorApp && currentRoute !== defaultRoute) {
      router.replace({ name: defaultRoute });
    }
    return;
  }
  store.dispatch('LoginStore/routeAfterLogin', authResult);
}

async function handleLoginByHashcode(store, route, router, confirmResult) {
  let query;
  try {
    query = QueryParamService.getAllParams(router);
    if (confirmResult.data.taskType === 'RegisterUserProfile') {
      await store.dispatch('UserStore/loginByHashcode', {
        taskConfirmationHashCode: query.taskConfirmationHashCode
      });
    }
    onAuthSuccess(store, router, confirmResult);
    store.dispatch('AssessmentStore/startActualizeAssessmentHistory');
  } catch (error) {
    logger.error('Unable to perform login by hashcode (expired link)');
    QueryParamService.removeAllParams(router);
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.CONFIRMATION_TASK_POPUP,
      popupContext: {
        ...confirmResult,
        hashCode: query?.taskConfirmationHashCode
      }
    });
  }
}

export default {
  handle,
  processUid,
  onAuthSuccess,
  isOauthRedirect,
  handleLinkParams,
  getQueryFromHashIfNeed,
  processConfirmationLink
};
