import find from 'lodash/find';
import remove from 'lodash/remove';

const listeners = [];
let isOnline = process.client ? getNavigatorIsOnline() : true;

function register(listener) {
  const currentListener = find(listeners, function(_listener) {
    return _listener.id === listener.id;
  });
  if (currentListener) {
    return;
  }
  listeners.push(listener);
}

function unregister(id) {
  remove(listeners, function(listener) {
    return listener.id === id;
  });
}

function _modeChange(_isOnline) {
  isOnline = _isOnline;
  listeners.forEach(listener => {
    listener.notifyFn(isOnline);
  });
}

function setOnline() {
  _modeChange(true);
}

function setOffline() {
  _modeChange(false);
}

function startListen() {
  window.addEventListener('offline', setOffline, false);
  window.addEventListener('online', setOnline, false);
}

function stopListen() {
  window.removeEventListener('offline', setOffline);
  window.removeEventListener('online', setOnline);
}

function checkIsOnline() {
  return isOnline;
}

function getNavigatorIsOnline() {
  return navigator.onLine;
}

function forceUpdateOnlineStatus() {
  if (process.client) {
    const _isOnline = getNavigatorIsOnline();
    _modeChange(_isOnline);
  }
}

export default {
  startListen,
  stopListen,
  register,
  unregister,
  checkIsOnline,
  setOnline,
  setOffline,
  forceUpdateOnlineStatus
};
