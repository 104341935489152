import AnnotationCategoriesEnum from '../enums/AnnotationCategoriesEnum.mjs';
import { PENCIL_UNDERLINE } from '../enums/NotesStylesEnum.mjs';

export default class DefaultCategories {
  constructor(labels = {}) {
    /**
     * const defaultCategories = new DefaultCategories({
     *   [AnnotationCategoriesEnum.IMPORTANT]: $t('Annotations.type.Important')
     *   ...
     * }).getCategories()
     */
    this.labels = labels;
  }
  getCategories(includeEditorOnly = false) {
    const categories = [
      {
        id: 1,
        name: AnnotationCategoriesEnum.IMPORTANT,
        label:
          this.labels[AnnotationCategoriesEnum.IMPORTANT] ||
          AnnotationCategoriesEnum.IMPORTANT,
        color: '#fefe3c',
        nightColor: '#8b640e',
        isDefault: true,
        preset: true,
        editorOnly: false,
        defaultCategory: true
      },
      {
        id: 2,
        name: AnnotationCategoriesEnum.DATE_OR_NAME,
        label:
          this.labels[AnnotationCategoriesEnum.DATE_OR_NAME] ||
          AnnotationCategoriesEnum.DATE_OR_NAME,
        color: '#b9e7ff',
        nightColor: '#1f709a',
        isDefault: true,
        preset: true,
        editorOnly: false
      },
      {
        id: 3,
        name: AnnotationCategoriesEnum.QUESTION,
        label:
          this.labels[AnnotationCategoriesEnum.QUESTION] ||
          AnnotationCategoriesEnum.QUESTION,
        color: '#b8e5cb',
        nightColor: '#366d40',
        isDefault: true,
        preset: true,
        editorOnly: false
      },
      {
        id: 4,
        name: AnnotationCategoriesEnum.CRITICAL,
        label:
          this.labels[AnnotationCategoriesEnum.CRITICAL] ||
          AnnotationCategoriesEnum.CRITICAL,
        color: '#ffd6e8',
        nightColor: '#8c3d60',
        isDefault: true,
        preset: true,
        editorOnly: false
      },
      {
        id: 5,
        name: AnnotationCategoriesEnum.VOCABULARY,
        label:
          this.labels[AnnotationCategoriesEnum.VOCABULARY] ||
          AnnotationCategoriesEnum.VOCABULARY,
        underline: PENCIL_UNDERLINE,
        color: '#697fbe',
        isDefault: true,
        nightColor: '#697fbe',
        editorOnly: true
      }
    ];
    return categories.filter(
      category =>
        !category.editorOnly || (includeEditorOnly && category.editorOnly)
    );
  }
}
