import ProfileEnum from '@/enums/ProfileEnum';

class User {
  constructor(attrs) {
    this.email = attrs.email || '';
    this.registeredAt = attrs.registeredAt || 0;
    this.modifiedAt = attrs.modifiedAt || 0;
    this.externaluserid = attrs.externaluserid || [];
    this.emailConfirmationStatus = attrs.emailConfirmationStatus;
    this.name = attrs.name || '';
    this.editorRole = attrs.editorRole || false;
    this.adminRole = attrs.adminRole || false;
    this.studentRole = attrs.adminRole || false;
    this.salesPersonRole = attrs.salesPersonRole || false;
    this.affiliateRole = attrs.affiliateRole || false;
    this.freeRole = attrs.freeRole || false;
    this.freeRoleExpiredAt = attrs.freeRoleExpiredAt || 0;
    this.courseTeacherRole = attrs.courseTeacherRole || false;
    this.deleteAfter = attrs.deleteAfter || 0;
    this.active = attrs.active || '';
    this.type = attrs.type || '';
    this.sync = attrs.sync || null;
    this.photo = attrs.photo || {};
    this.id = attrs.id || '';
    this.userId = attrs.userId || '';
    this.hasPassword = attrs.hasPassword || false;
  }
}

class ProfilePersistingInfo {
  constructor(profilePersistingInfo) {
    this.newUser = false;
    this.passwordPersistingModeEnum =
      profilePersistingInfo.passwordPersistingModeEnum ||
      ProfileEnum.PASSWORD_WITHOUT_CHANGES;
    this.passwordConfirmation = '';
    this.newPassword = '';
    this.changeEmail = false;
  }

  setPasswordPersistingMode(mode) {
    this.passwordPersistingModeEnum = mode;
    return this;
  }

  setNewPassword(newPassword) {
    this.newPassword = newPassword;
    return this;
  }

  setPasswordConfirmation(passwordConfirmation) {
    this.passwordConfirmation = passwordConfirmation;
    return this;
  }

  setChangeEmail(isChanged) {
    this.changeEmail = isChanged;
    return this;
  }
}

function create(attrs) {
  return new User(attrs);
}

function createProfilePersistingInfo(profilePersistingInfo) {
  return new ProfilePersistingInfo(profilePersistingInfo);
}

export default {
  create,
  createProfilePersistingInfo
};
