import IndexedDB from '@/services/utils/IndexedDB';
import InMemoryDB from '@/services/utils/InMemoryDB';

class IndexedDBWrapper {
  constructor(name, suffix, indexDB) {
    this.name = name;
    this.db = indexDB ? new IndexedDB(name, suffix) : new InMemoryDB();
    return this;
  }

  initTables(tables, version) {
    const serviceTable = '_info';
    tables[serviceTable] = '_id';

    this.db.initTables(tables, version);
    this._saveServiceInfo(serviceTable);

    return this;
  }

  async savePublication(table, publication) {
    await this.db.putData(table, publication);
  }

  async savePublications(table, publications) {
    await this.db.putRows(table, publications);
  }

  async removeById(table, id) {
    await this.db.removeById(table, id);
  }

  async removeTable(table) {
    await this.db.delete(table);
  }

  async getTableData(table) {
    const data = await this.db.getTableData(table);
    return data;
  }

  getById(pubId, table) {
    return this.db.db[table].get(pubId);
  }

  getByIds(table, pubIds) {
    return this.db.getByIds(table, pubIds);
  }

  _saveServiceInfo(tableName) {
    this.db.putData(tableName, {
      _id: 'serverUrl',
      value: this.name
    });
  }
}

export default IndexedDBWrapper;
