import { render, staticRenderFns } from "./UserDataCollection.vue?vue&type=template&id=5f324c60"
import script from "./UserDataCollection.vue?vue&type=script&lang=js"
export * from "./UserDataCollection.vue?vue&type=script&lang=js"
import style0 from "./UserDataCollection.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
