export default {
  getSharedCompilationDataUrl,
  shortenId
};

function getSharedCompilationDataUrl({
  serverUrl,
  urlTemplate,
  branch,
  brand,
  shareId
}) {
  const dataUrl = urlTemplate
    .replace('##serverUrl##', serverUrl.replace(/\/+$/g, ''))
    .replace('##branch##', branch.replace(/-/g, ''))
    .replace('##brand##', brand)
    .replace(/##shareId##/g, shareId);

  return dataUrl;
}

function shortenId(compilationId) {
  return compilationId.replace(/-/g, '').replace(/^compilation/, '');
}
