import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ApplicationCacheStore.js');
import throttle from 'lodash/throttle';
import { ReadOptionsHelper } from '@/classes/factories/AssetReader/ReadOptions';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import LocalStorageService from '@/services/LocalStorageService';
import FileTransport from '@/services/AssetsManager/FileTransport/FileTransport';

const CACHE_LIMIT_KEY = 'CacheLimit';
const MANIFEST_PATH = 'cacheManifest.json';
let filesHandling = null;

const throttledAdjustState = throttle(_adjustState, 1000);

const readOptionsHelper = new ReadOptionsHelper();
const maxSpaceInBytes = 100 * 1024 * 1024;
const minSpaceInBytes = 5 * 1024 * 1024;

let cacheState = _createCacheState();

function _createCacheState() {
  return {
    files: {},
    size: 0,
    limit: Math.min(_fetchLimit(), maxSpaceInBytes),
    maxSpaceInBytes,
    minSpaceInBytes,
    ready: false
  };
}

function _fetchLimit() {
  return LocalStorageService.get(CACHE_LIMIT_KEY);
}

/**
 * Save cache limit to Local Storage
 */
function _persistLimit(limit) {
  LocalStorageService.set(CACHE_LIMIT_KEY, limit);
}

/**
 * Get max space
 * @return {promise}
 */
async function _getMaxSpace() {
  const storage = window.navigator.storage;
  if (!storage) {
    return maxSpaceInBytes;
  }
  const estimate = await storage.estimate();
  return estimate.quota;
}

/**
 * Read cache manifest from filesystem
 * @return {promise}
 */
async function _readCacheManifest() {
  let manifest = '';
  try {
    const readOptions = readOptionsHelper.getParsedJsonOptions();
    manifest = await filesHandling.readFile(MANIFEST_PATH, readOptions);
  } catch (error) {
    const isFileMissing = error.code === 8;
    if (!isFileMissing) {
      logger.error(error);
    }
  }

  if (manifest) {
    return manifest;
  }
  manifest = await _createEmptyManifest();
  return manifest;
}

async function _createEmptyManifest() {
  const emptyManifest = {};
  await writeManifest(emptyManifest);
  return emptyManifest;
}

async function writeManifest(data) {
  try {
    await filesHandling.removeFile(MANIFEST_PATH);
  } catch (error) {
    const missingFile = error && error.code === 8;
    if (!missingFile) {
      logger.error(`get error on remove manifest ${error}`);
    }
  }
  try {
    await filesHandling.writeFile(MANIFEST_PATH, data);
  } catch (error) {
    logger.error(`get error on write manifest ${error}`);
  }
}

/**
 * Get sorted files by date
 * @return {Array} sortedFiles
 */
function _getSortedByDate(state) {
  return Object.keys(state.files).sort(function(prev, cur) {
    return state.files[prev].date > state.files[cur].date;
  });
}

/**
 * Get files to remove
 * @return {Array} filesToRemove
 */
function _getFilesToRemove(sortedFiles) {
  var filesToRemove = [];
  while (cacheState.limit < cacheState.size) {
    _deleteFile(sortedFiles[0]);
    filesToRemove.push(sortedFiles[0]);
    sortedFiles.shift();
  }
  return filesToRemove;
}

/**
 * Remove files from filesystem
 * @param  {Array} filesToRemove
 * @return {promise}
 */
function _removeFilesFromFs(filesToRemove) {
  var promises = filesToRemove.map(function(path) {
    return filesHandling.removeFile(path);
  });
  return Promise.all(promises);
}

/**
 * Persist state
 * @return {promise}
 */
function _persistState(state) {
  var dataToSave = state.size > 0 ? state.files : {};
  return writeManifest(dataToSave);
}

/**
 * Check is file in cache
 * @param  {string} path
 * @return {boolean}
 */
function isFileInCache(path) {
  return cacheState.files.hasOwnProperty(path);
}

// getters
function getOptions() {
  return {
    min: cacheState.minSpaceInBytes,
    max: cacheState.maxSpaceInBytes,
    occupied: cacheState.size,
    limit: cacheState.limit
  };
}

function isReady() {
  return cacheState.ready;
}

// actions
async function init() {
  try {
    cacheState = _createCacheState();
    cacheState.ready = false;
    filesHandling = await FileTransport.getFileAssetDriver(
      AssetResourcesEnum.FS_TEMP
    );
    const available = await filesHandling.isAvailable();
    if (!available) {
      return Promise.resolve();
    }
    const spaceInBytes = await _getMaxSpace();
    _setMaxSpaceInBytes(spaceInBytes);
    _setLimit(maxSpaceInBytes);

    const manifest = await _readCacheManifest();

    if (manifest) {
      _addFiles(manifest);
    }
    cacheState.ready = true;
  } catch (error) {
    if (error && error.type === CustomErrorEnum.FS_UNAVAILABLE) {
      logger.warn(error.message);
      return;
    }
    logger.error(error);
  }
}
async function downloadFileToCache(fileSourcePath) {
  const readOptions = readOptionsHelper.getBlobOptions();
  const remoteFilesHandling = await FileTransport.getFileAssetDriver(
    AssetResourcesEnum.REMOTE
  );
  const data = await remoteFilesHandling.readFile(fileSourcePath, readOptions);
  await saveFile(fileSourcePath, data);
}

function _getSize(data) {
  if (!(data instanceof Blob) && typeof data === 'object' && data !== null) {
    data = new Blob([JSON.stringify(data)], {
      type: 'application/json'
    });
    return data.size;
  }
  return data instanceof Blob ? data.size : data.byteLength;
}

async function saveFile(filePath, data) {
  try {
    await filesHandling.writeFile(filePath, data);
    if (!isFileInCache(filePath)) {
      const size = _getSize(data);
      const manifestItem = {
        [filePath]: { size, date: Date.now() }
      };
      _addFiles(manifestItem);
      return throttledAdjustState();
    }
  } catch (error) {
    logger.error(error);
  }
}
function readFile(fileSourcePath, readOptions) {
  return filesHandling.readFile(fileSourcePath, readOptions);
}
async function _adjustState() {
  const sortedFiles = _getSortedByDate(cacheState);
  const filesToRemove = _getFilesToRemove(sortedFiles);
  await _persistState(cacheState);
  if (filesToRemove.length > 0) {
    return _removeFilesFromFs(filesToRemove);
  }
}

function resetCacheLimit(payload) {
  const { limit } = payload;
  _setLimit(limit);
  if (limit < cacheState.size) {
    return _adjustState();
  }
}
async function updateDate(payload) {
  try {
    const { path, date } = payload;
    _updateDate({ path, date });
    const resp = await throttledAdjustState();
    return resp;
  } catch (error) {
    logger.error(error);
  }
}

// mutations
function _setMaxSpaceInBytes(spaceInBytes) {
  cacheState.maxSpaceInBytes = spaceInBytes;
}
function _setLimit(limit) {
  cacheState.limit = limit;
  _persistLimit(limit);
}
function _addFiles(manifest) {
  Object.keys(manifest).forEach(function(path) {
    const data = manifest[path];
    if (!isFileInCache(path, cacheState)) {
      cacheState.files[path] = data;
      cacheState.size += data.size;
    }
  });
}
function _updateDate(path, date) {
  cacheState.files[path].date = date;
}
function _deleteFile(path) {
  if (isFileInCache(path, cacheState)) {
    cacheState.size -= cacheState.files[path].size;
    delete cacheState.files[path];
  }
}

function getExcludeEntries() {
  return [MANIFEST_PATH];
}

export default {
  init,
  downloadFileToCache,
  saveFile,
  readFile,
  getOptions,
  isReady,
  resetCacheLimit,
  updateDate,
  isFileInCache,
  getExcludeEntries
};
