import RestService from '@/services/RestService';
import MaterialTypes from '@shared/enums/MaterialTypes';
import Locator from '@shared/publication/locator.mjs';
import MaterialPositions from '@/enums/MaterialPositions';
import ParagraphMaterialFactory from '@/classes/factories/Materials/ParagraphMaterialFactory';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import RawMaterialFactory from '@/classes/factories/Materials/RawMaterialFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('MaterialsUtils.js');

import isString from 'lodash/isString';

function getHeightByTypeMap() {
  return {
    [MaterialTypes.PARA_NOTE]: 67,
    [MaterialTypes.QUIZ]: 156,
    [MaterialTypes.ESSAY_TASK]: 156,
    [MaterialTypes.ESSAY]: 156,
    [MaterialTypes.FLASHCARD]: 156,
    [MaterialTypes.PARA_SUMMARY]: 44
  };
}

function wrapTextIntoClass(text, className) {
  return '<span class="' + className + '">' + text + '</span>';
}

function getStateMaterialPositionByPosition(position) {
  return position === MaterialPositions.BEFORE_PARAGRAPH
    ? 'beforeParaMaterials'
    : 'afterParaMaterials';
}

function isTestInfoComplete(test) {
  return test.testQuestions;
}

// eslint-disable-next-line no-unused-vars
function setMaterialsByParaId(materials) {
  if (!materials.length) {
    return {};
  }
  let materialsType = _getMaterialType(materials[0]);
  let getParaIdFn;
  const getTransformedDataFn = material =>
    MaterialsFactory.createMaterialByType({ material, type: materialsType });
  switch (materialsType) {
    case MaterialTypes.PARA_NOTE: {
      getParaIdFn = note => note.paragraphId;
      break;
    }
    case MaterialTypes.ANNOTATION: {
      getParaIdFn = annotation => {
        if (isString(annotation.start) && isString(annotation.end)) {
          annotation.start = Locator.deserialize(annotation.start);
          annotation.end = Locator.deserialize(annotation.end);
        }
        return annotation.start.prefixedParagraphId;
      };
      break;
    }
    case MaterialTypes.ESSAY_TASK: {
      getParaIdFn = essayTask => essayTask.locator.paragraphId;
      break;
    }
    case MaterialTypes.QUIZ: {
      getParaIdFn = quiz => quiz.locator;
      break;
    }
    case MaterialTypes.FLASHCARD: {
      getParaIdFn = flashcard => flashcard.locator;
      break;
    }
    default: {
      logger.warn(
        'Unsupported materials - ' + JSON.stringify(materials, null, 2)
      );
      return;
    }
  }
  return _fillMaterialMap({ materials, getParaIdFn, getTransformedDataFn });
}

function _getMaterialType(material) {
  if (material.note !== null) {
    if (material.paragraphId) {
      return MaterialTypes.PARA_NOTE;
    }
    return MaterialTypes.ANNOTATION;
  }
  if (material.locator && material.locator.paragraphId) {
    return MaterialTypes.ESSAY_TASK;
  }
  if (material.testType) {
    return material.testType;
  }
  if (material === '' || Number.isInteger(material)) {
    return MaterialTypes.PARA_SUMMARY;
  }

  return null;
}

function _fillMaterialMap({ materials, getParaIdFn, getTransformedDataFn }) {
  let materialsMap = {};
  [...materials].forEach(material => {
    const paraId = getParaIdFn(material);
    const transformedData = getTransformedDataFn(material);
    if (materialsMap[paraId]) {
      materialsMap[paraId].push(transformedData);
    } else {
      materialsMap[paraId] = [transformedData];
    }
  });
  return materialsMap;
}

function getMaterialView(material) {
  const type = material.type;
  let paraMaterial = ParagraphMaterialFactory.createParagraphMaterial(
    type,
    material
  );
  return paraMaterial;
}

function fromMapToArray(map) {
  let values = Object.values(map);
  return [].concat(...values);
}

function getDefaultCategories() {
  return MaterialsFactory.getDefaultCategories();
}

function getTestFileSource(fileId) {
  let url = RestService.getUrlString('ManageTests', 'getFile').split(
    '?RunId='
  )[0];
  url += '?fileId=' + fileId;
  return url;
}

function prepareTestQuestions(questions) {
  const preparedQuestions = [];
  questions.forEach(question => {
    const answer = question.answers[question.correctAnswerIndex];
    const incorrectAnswers = question.answers.filter(
      (el, index) => index !== question.correctAnswerIndex
    );
    let rawQuestionBuilder = RawMaterialFactory.createRawQuestionBuilder();
    rawQuestionBuilder
      .setTestId(question.id)
      .setAnswer(answer)
      .setQuestion(question.question)
      .setIncorrectAnswers(incorrectAnswers)
      .setType(question.type);
    preparedQuestions.push(rawQuestionBuilder.build());
  });
  return preparedQuestions;
}
function prepareTest(test, bookId) {
  const preparedQuestions = prepareTestQuestions(test.testQuestions);
  const rawTestBuilder = MaterialsFactory.createBuilderByType(test.testType);
  rawTestBuilder
    .setId(test.id)
    .setPublicationId(bookId)
    .setName(test.name)
    .setDescription(test.description)
    .setTestQuestions(preparedQuestions)
    .setQuestionsCount(preparedQuestions.length)
    .setCreatedAt(test.createdAt)
    .setParagraphId(test.locator);
  return rawTestBuilder.build();
}

function uploadAttachment({ blob }) {
  const _params = {
    fileType: blob.type
  };
  return RestService.restRequest(
    'post',
    'ManageTests',
    'uploadAttachment',
    blob.photoBuffer || blob,
    _params
  );
}

function deleteAttachment({ fileHash }) {
  return RestService.restRequest('post', 'ManageTests', 'deleteAttachment', {
    fileHash: fileHash
  });
}

export default {
  getHeightByTypeMap,
  wrapTextIntoClass,
  getStateMaterialPositionByPosition,
  isTestInfoComplete,
  setMaterialsByParaId,
  getMaterialView,
  fromMapToArray,
  getDefaultCategories,
  getTestFileSource,
  prepareTestQuestions,
  prepareTest,
  uploadAttachment,
  deleteAttachment
};
