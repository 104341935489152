class BlogPost {
  constructor(params = {}) {
    this.title = params.title || '';
    this.quote = params.quote || {};
    this.description = params.description || '';
    this.tags = params.tags || [];
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setDescription(description) {
    this.description = description;
    return this;
  }

  setQuote(quote) {
    this.quote = quote;
    return this;
  }

  setTags(tags) {
    this.tags = tags;
    return this;
  }
}

function createBlogPost(params) {
  return new BlogPost(params);
}

export default {
  createBlogPost
};
