<template>
  <div class="quiz-item exercises-item">
    <div class="item-icon-block">
      <BaseSpriteIcon view-box="0 0 32 32" icon-name="pic-quiz" />
    </div>
    <div class="item-text-block">
      <div class="item-para-number">
        {{ exerciseView.paraNum }}
      </div>
      <div class="item-title">
        {{ exerciseView.name }}
      </div>

      <div class="item-processing-type-block">
        {{ exerciseView.description }}
      </div>

      <div class="footer-icons">
        <div class="progress-wrapp">
          {{ $t('Exercises.questions.label') + ' ' + exerciseView.count }}
        </div>

        <div v-if="isShownEditableItems" class="button-wrapp" @click="editQuiz">
          <BaseSpriteIcon icon-name="ico-edit" />
        </div>
        <div v-if="!isShownEditableItems">
          <div v-if="!exerciseView.isCompleted">
            <button @click="startQuizAssesment">
              <span>{{ $t('Start') }}</span>
            </button>
          </div>
          <div v-if="exerciseView.isCompleted">
            <span>{{ $t('Exercises.completed.label') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import ExerciseTasks from '@/enums/ExerciseTasks';

export default {
  name: 'QuizItem',
  components: { BaseSpriteIcon },
  props: { exerciseView: Object, isShownEditableItems: Boolean },
  methods: {
    editQuiz() {
      this.$emit('exerciseEvent', {
        task: ExerciseTasks.EDIT,
        exerciseType: this.exerciseView.type,
        data: { id: this.exerciseView.id }
      });
    },
    startQuizAssesment() {
      this.$emit('exerciseEvent', {
        task: ExerciseTasks.START_ASSESSMENT,
        exerciseType: this.exerciseView.type,
        data: { quiz: this.exerciseView }
      });
    }
  }
};
</script>

<style lang="less" src="./QuizItem.less"></style>
