<template>
  <BaseMenu
    v-model="isShown"
    content-class="compilation-item-menu"
    :activator="activator"
    :close-on-content-click="false"
    left
  >
    <template #content>
      <div
        v-if="compilation.audio && !isAppStatePresentPublication"
        class="item-downloader"
      >
        <Downloader
          :book-id="compilation.id"
          text
          large
          color="#0f172a"
          @downloaderEvent="downloaderEventHandler"
        ></Downloader>
      </div>
      <SectionsMenu
        :menu-sections="menuSections"
        @click.prevent
        @sectionsMenuEvent="sectionsMenuEventHandler"
      ></SectionsMenu>
    </template>
  </BaseMenu>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseMenu from '@/components/base/BaseMenuWithControl/BaseMenu';
import SectionsMenu from '@/components/views/AppMenu/SectionsMenu/SectionsMenu';

import MenuFactory from '@/classes/factories/MenuFactory';

import CompilationMenuItemsEnum from '@/enums/CompilationMenuItemsEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import AppStateEnum from '@/enums/AppStateEnum';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CompilationItemMenu.js');

import { localize as $t } from '@/i18n';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';

const ORDERED_MENU_ITEMS = [
  CompilationMenuItemsEnum.EDIT,
  CompilationMenuItemsEnum.DOWNLOAD_AUDIO,
  CompilationMenuItemsEnum.PRINT,
  CompilationMenuItemsEnum.SHARE,
  CompilationMenuItemsEnum.CHANGE_COVER,
  CompilationMenuItemsEnum.TOGGLE_DEFAULT,
  CompilationMenuItemsEnum.DUPLICATE,
  CompilationMenuItemsEnum.DELETE
];

export default {
  name: 'CompilationItemMenu',
  components: {
    BaseMenu,
    SectionsMenu,
    Downloader: () => import('@/components/views/Downloader/Downloader.vue')
  },
  props: {
    popupContext: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isShown: false,
      activator: null,
      compilation: {},
      isDownloadingAudio: false
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isDevice']),
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    menuSections() {
      return ORDERED_MENU_ITEMS.reduce((result, sectionName) => {
        const section = this.createSectionItem(sectionName);
        if (section.isVisible) {
          result.push(section);
        }
        return result;
      }, []);
    },
    appState() {
      return this.$store.getters['ContextStore/appState'];
    },
    isAppStatePresentPublication() {
      return this.appState === AppStateEnum.PRESENT_PUBLICATION;
    }
  },
  mounted() {
    if (!this.popupContext.activatorSelector) {
      logger.info('Please, provide activatorSelector in popupContext');
      return;
    }
    this.activator = window?.document.querySelector(
      this.popupContext.activatorSelector
    );
    this.compilation = this.popupContext.compilation;
    this.isShown = true;
  },
  methods: {
    downloaderEventHandler() {},
    sectionsMenuEventHandler(item) {
      if (item.selectedItem.id !== CompilationMenuItemsEnum.DOWNLOAD_AUDIO) {
        this.$_close();
      }
    },
    createSectionItem(sectionName) {
      const builder = MenuFactory.getMenuItemBuilder();
      let label = $t(`CompilationMenuItem.${sectionName}.label`);
      let isVisible = true;
      let isDisabled = false;

      let downloadAudioIcon = this.isDownloadingAudio
        ? 'ico-spinner'
        : 'ico-download';
      let downloadAudioLabel = this.isDownloadingAudio
        ? $t(`CompilationMenuItem.downloadingAudio.label`)
        : $t(`CompilationMenuItem.downloadAudio.label`);
      let downloadAudioClass = this.isDownloadingAudio
        ? 'downloading-audio'
        : '';

      builder
        .setId(sectionName)
        .setLabel(label)
        .setIsVisible(isVisible);

      switch (sectionName) {
        case CompilationMenuItemsEnum.EDIT:
          isVisible = !this.isAppStatePresentPublication;
          builder
            .setIcon('ico-edit')
            .setHandler(this.$_editCompilation)
            .setIsVisible(isVisible);
          break;
        case CompilationMenuItemsEnum.DOWNLOAD_AUDIO:
          isVisible =
            this.isAppStatePresentPublication &&
            this.popupContext.audio &&
            !this.isDevice;
          isDisabled = !this.isOnline;
          builder
            .setIcon(downloadAudioIcon)
            .setLabel(downloadAudioLabel)
            .setHandler(this.$_downloadAudio)
            .setCustomClass(downloadAudioClass)
            .setIsVisible(isVisible)
            .setDisabled(isDisabled || this.isDownloadingAudio);
          break;
        case CompilationMenuItemsEnum.PRINT:
          isVisible = this.isAppStatePresentPublication && !this.isDevice;
          builder
            .setIcon('ico-printer')
            .setHandler(this.$_printCompilation)
            .setIsVisible(isVisible);
          break;
        case CompilationMenuItemsEnum.SHARE:
          isDisabled = !this.isOnline;
          builder
            .setIcon('ico-share-icon')
            .setHandler(this.$_shareCompilation)
            .setDisabled(isDisabled);
          break;
        case CompilationMenuItemsEnum.CHANGE_COVER:
          isVisible = !this.isAppStatePresentPublication;
          builder
            .setIcon('ico-image-line')
            .setHandler(this.$_changeCompilationCover)
            .setIsVisible(isVisible);
          break;
        case CompilationMenuItemsEnum.TOGGLE_DEFAULT:
          isVisible =
            this.$store.getters['UserStore/isUserAdmin'] &&
            this.isOnline &&
            !this.isAppStatePresentPublication;
          label = !this.compilation.default
            ? $t('CompilationMenuItem.makeDefault.label')
            : $t('CompilationMenuItem.makeNotDefault.label');
          builder
            .setIcon('ico-chat-quote-line')
            .setHandler(this.$_makeDefaultCompilation)
            .setLabel(label)
            .setIsVisible(isVisible);
          break;
        case CompilationMenuItemsEnum.DUPLICATE:
          isVisible = !this.isAppStatePresentPublication;
          builder
            .setIcon('ico-file-copy-line')
            .setHandler(this.$_duplicateCompilation)
            .setIsVisible(isVisible);
          break;
        case CompilationMenuItemsEnum.DELETE:
          isVisible = !this.isAppStatePresentPublication;
          builder
            .setIcon('ico-delete')
            .setHandler(this.$_deleteCompilation)
            .setColor('red')
            .setIsVisible(isVisible);
          break;
      }
      return builder.build();
    },
    $_close() {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.COMPILATION_ITEM_MENU
      });
    },
    $_duplicateCompilation() {
      this.$store.dispatch('CompilationsStore/copy', this.compilation.id);
    },
    async $_deleteCompilation() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.COMPILATION_DELETE_POPUP,
        popupContext: { compilation: this.compilation }
      });
    },
    async $_shareCompilation() {
      const linkToShare = await this.$store.dispatch(
        'CompilationsStore/share',
        this.compilation.id
      );
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.SOCIAL_SHARING_POPUP,
        popupContext: { linkToShare: linkToShare }
      });
    },
    $_changeCompilationCover() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.COMPILATION_COVER_CHANGER_POPUP,
        popupContext: { compilation: this.compilation }
      });
    },
    $_makeDefaultCompilation() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.COMPILATION_MAKE_DEFAULT_POPUP,
        popupContext: { compilation: this.compilation }
      });
    },
    $_editCompilation() {
      PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        {
          slug: this.compilation.slug,
          editMode: true
        }
      );
    },
    async $_downloadAudio() {
      this.isDownloadingAudio = true;
      try {
        const link = await this.$store.dispatch(
          'CompilationsStore/getCompilationAudioLink',
          this.popupContext.compilation.id
        );

        const anchor = window.document.createElement('a');
        anchor.setAttribute('href', link);
        anchor.setAttribute('download', 'audio.m4a');
        anchor.click();
      } catch (error) {
        logger.error(error);
      } finally {
        this.isDownloadingAudio = false;
      }
    },
    $_printCompilation() {
      try {
        if (!document.execCommand('print', false, null)) {
          window.print();
        }
      } catch {
        window.print();
      }
    }
  }
};
</script>

<style lang="less" src="./CompilationItemMenu.less"></style>
