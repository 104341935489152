import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';
import MaterialTypes from '@shared/enums/MaterialTypes.js';
import Locator from '@shared/publication/locator.mjs';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import CustomErrors from '@shared/enums/CustomErrorEnum.mjs';
import now from 'lodash/now';
import uuid from '@shared/utils/uuid.mjs';

const DEFAULT_CATEGORY_ID = 1;

class Annotation {
  constructor(annotation, publicationId) {
    this.id = annotation.id || uuid();
    this.publicationId = publicationId || '';
    this.type = MaterialTypes.ANNOTATION;
    this.userId = annotation.userId || '';
    this.studyGuideId = annotation.studyGuideId;
    this.start = this._getDeserializeLocator(annotation.start);
    this.end = this._getDeserializeLocator(annotation.end);
    this.paragraphId = annotation.paragraphId || this.start.prefixedParagraphId;
    this.blockId = annotation.blockId || this.start.prefixedParagraphId;

    this.categoryId = annotation.categoryId || annotation.category;
    this.category =
      annotation.category || annotation.tag || DEFAULT_CATEGORY_ID;
    this.categoryLabel = '';
    this.categoryName = '';
    this.categoryColor = '';

    this.note = annotation.note || '';
    this.quote = '';
    this.highlightedQuote = '';
    this.createdAt = annotation.createdAt || now();
    this.modifiedAt = annotation.modifiedAt || now();
    this.studyGuide = annotation.studyGuide || false;

    this.isDeleted = annotation.isDeleted || false;
    this.isHidden = false;
    this.isSelected = false;
    this.isStackable = false;

    this.initialTop = 0;
    this.top = this.initialTop;
    this.dir = 'ltr';
    this.chapter = '';
    this.annClass = '';

    this.noteHashtags = annotation.noteHashtags || [];
  }

  initAnnotation(container) {
    const annSelector = _getAnnSelector(this.id);
    try {
      const highlightedAnnParts = container.querySelectorAll(annSelector);
      if (!highlightedAnnParts.length) {
        throw CustomErrors.ANNOTATION_RANGE_ERROR;
      }
      this.quote = _getQuote(highlightedAnnParts);
      this.dir = highlightedAnnParts[0]
        .closest(`.${SelectionConstantUtils.SCROLL_ITEM_WRAPPER_CLASS}`)
        .getAttribute('dir');
      this.initialTop = _getAnnotationTopOffset(highlightedAnnParts);
      this.top = this.initialTop;
    } catch (err) {
      throw CustomErrors.ANNOTATION_RANGE_ERROR;
    }
  }

  reinitTopOffset(container) {
    const annSelector = _getAnnSelector(this.id);
    const highlightedParts = container.querySelectorAll(annSelector);
    this.initialTop = _getAnnotationTopOffset(highlightedParts);
    this.top = this.initialTop;
  }

  reinitLocatorPoints(paraId) {
    this.start = new Locator.InTextLocator(
      paraId,
      this.start.logicalCharOffset
    );
    this.end = new Locator.InTextLocator(paraId, this.end.logicalCharOffset);
  }

  changeNote(note) {
    this.note = note;
    return this;
  }

  changeCategory(category) {
    this.categoryId = category;
    this.category = category;
    return this;
  }

  changeCategoryColor(categoryColor) {
    this.categoryColor = categoryColor;
    return this;
  }

  changeCategoryLabel(categoryLabel) {
    this.categoryLabel = categoryLabel;
    return this;
  }

  changeCategoryName(categoryName) {
    this.categoryName = categoryName;
    return this;
  }

  changeCreationTime(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  changeModificationTime(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  createAnnotationView(publicationId) {
    return new Annotation(this, publicationId);
  }

  createRawMaterial() {
    return new RawAnnotation(this);
  }

  markAsDeleted() {
    this.isDeleted = true;
  }

  _getDeserializeLocator(locator) {
    return typeof locator === 'string' ? Locator.deserialize(locator) : locator;
  }

  getHighlightData() {
    return {
      prefix: 'ann',
      readerClass: 'nota-annotation-reader',
      idAttribute: 'data-annotation-id'
    };
  }
}

class RawAnnotation {
  constructor(annotation) {
    this.id = annotation.id || uuid();
    this.type = annotation.type || MaterialTypes.ANNOTATION;
    this.createdAt = annotation.createdAt || now();
    this.modifiedAt = annotation.modifiedAt || now();
    this.blockId = annotation.blockId;
    this.start = Locator.serialize(annotation.start);
    this.end = Locator.serialize(annotation.end);
    this.category = annotation.category;
    this.categoryId = annotation.categoryId || annotation.category;
    this.note = annotation.note;
    this.studyGuide = annotation.studyGuide;
    this.isDeleted = annotation.isDeleted;
    this.noteHashtags = annotation.noteHashtags || [];
  }
}

function _getAnnSelector(annId) {
  return `[${MarkerUtils.getAnnotationIdMarker()}="${annId}"]`;
}

function _getQuote(highlightedAnnParts) {
  let quote = '';
  const annotationParts = Array.from(highlightedAnnParts);
  const annPartsCount = annotationParts.length;
  for (let i = 0; i < annPartsCount; i++) {
    quote += annotationParts[i].textContent;
  }
  return quote;
}

function _getAnnotationTopOffset(highlightedAnnParts) {
  if (!highlightedAnnParts.length) {
    return;
  }
  let highlightedPart = highlightedAnnParts[0];
  const isFirstHighlightedInitialLetter = highlightedPart.closest(
    '[dropcap-dummy]'
  );
  if (isFirstHighlightedInitialLetter && highlightedAnnParts.length > 1) {
    highlightedPart = highlightedAnnParts[1];
  }
  const annTopOffest = Math.max(
    highlightedPart.offsetTop,
    highlightedPart.offsetParent.offsetTop
  );

  return annTopOffest;
}

export default Annotation;
