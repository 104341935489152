class ContentTable {
  constructor(serializedContentTable, source) {
    this.date = serializedContentTable.date || '';
    this.files = serializedContentTable.files || [];
    this.size = serializedContentTable.size || 0;
    this.version = serializedContentTable.version || '';
    this.source = source;
  }

  addFile(fileHash, size) {
    const index = this.files.indexOf(fileHash);
    if (index !== -1) {
      return;
    }
    this.size += size;
    this.files.push(fileHash);
  }

  getNumberFiles() {
    return this.files.length;
  }

  removeFile(fileHash, size) {
    const index = this.files.indexOf(fileHash);
    if (index === -1) {
      return;
    }
    this.files.splice(index, 1);
    this.size -= size;
  }

  updateAssetInfo(assetInfo) {
    this.date = assetInfo.date;
    this.version = assetInfo.version;
  }

  toJson() {
    const json = {
      date: this.date,
      files: this.files,
      size: this.size,
      version: this.version,
      source: this.source
    };
    return json;
  }
}

function createContentTable(serializedContentTable, source) {
  return new ContentTable(serializedContentTable, source);
}

export default {
  createContentTable
};
