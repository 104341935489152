<template>
  <BaseButton
    elevation="0"
    class="mini-navigate-icon"
    fab
    outlined
    :title="$t('Accessibility.advancedSearch.tooltip')"
    :aria-label="$t('Accessibility.header.search')"
    @click="openSearch"
  >
    <BaseSpriteIcon icon-name="ico-search" />
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import AppStateEnum from '@/enums/AppStateEnum';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';

export default {
  name: 'OpenSearchControl',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  methods: {
    openSearch() {
      if (
        !this.$store.getters['ContextStore/isSearchLoadingStopped'] &&
        !this.$store.getters['ContextStore/isSearchLoaded']
      ) {
        this.$store.dispatch('ContextStore/stopSearchLoading', true);
      }
      GoogleAnalyticsUtils.trackEvent('advancedSearch', 'openSearch');
      this.$store.dispatch('PlaybackStore/pause');
      this.$router.push({ name: AppStateEnum.SEARCH });
    }
  }
};
</script>
