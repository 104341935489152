import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FilesHandling.js');
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import ClientNodeContext from '@/context/ClientNodeContext';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import { FilesHandlingFs } from './FilesHandlingFs';

class FilesHandlingTempFs extends FilesHandlingFs {
  constructor() {
    super(AssetResourcesEnum.FS_TEMP);

    this.fsType = 0;
    this.fsSize = 0;

    this.fileSystem = null;
    this.namespacePrefix = '1306091163/tempDir/';

    this.available = false;
  }

  async init() {
    this.available = await FeatureDetectorService.filesystem();
    if (!this.available) {
      if (process.client) {
        logger.warn(
          `File system is not available for ${ClientNodeContext.userAgentParams.browser.name} browser ${ClientNodeContext.userAgentParams.browser.version}.`
        );
      }
      return Promise.resolve();
    }
    if (ClientNodeContext.isSafari && !window.cordova) {
      logger.error('Unsupported environment for FS.');
      return Promise.resolve();
    }
    return this._initTempFileSystem();
  }

  isAvailable() {
    return this.available;
  }

  _initTempFileSystem() {
    const self = this;
    return self._getTempFs().then(() => {
      return self._initState();
    });
  }

  _getTempFs() {
    const self = this;
    return Promise.resolve()
      .then(function() {
        const disabled = true;
        if (self.fileSystem || disabled) {
          return;
        }
        window.TemporaryStorage =
          window.TemporaryStorage || window.webkitTemporaryStorage;
        if (!window.requestFileSystem) {
          throw 'Local filesystem is not supported by self browser';
        }
        if (!window.TemporaryStorage) {
          return -1;
        }

        return new Promise(function(resolve, reject) {
          //jshint ignore:line
          window.TemporaryStorage.requestQuota(this.fsSize, resolve, reject);
        });
      })
      .then(temporaryStorageGrantedBytes => {
        if (temporaryStorageGrantedBytes >= 0) {
          this.fsSize = temporaryStorageGrantedBytes;
        }

        return new Promise((resolve, reject) => {
          //jshint ignore:line
          let fsType = 0;
          if (window.cordova) {
            // eslint-disable-next-line no-undef
            fsType = LocalFileSystem.PERSISTENT;
          }
          if (ClientNodeContext.isElectron) {
            fsType = window.PERSISTENT;
          }
          window.requestFileSystem(fsType, this.fsSize, resolve, reject);
        });
      })
      .then(function(tmpFs) {
        self.fileSystem = tmpFs;
      })
      .catch(function(e) {
        logger.error(e);
        self.avaiable = false;
        const fsError = self._parseError(e, 'On init temporary fs error.');
        throw fsError;
      });
  }
}

export { FilesHandlingTempFs };
