import dayJS from '@/dayJS';

export class Weekday {
  setDay(day) {
    this.day = day;
    return this;
  }
  setGoal(goal) {
    this.goal = goal;
    return this;
  }
  build() {
    return {
      day: this.day,
      goal: this.goal
    };
  }
}

const DEFAULT_GOAL = 20;
const DEFAULT_ACTIVITY = {
  goal: DEFAULT_GOAL,
  changedAt: Date.now(),
  weekdays: [0, 1, 2, 3, 4, 5, 6].map(d =>
    new Weekday()
      .setDay(d)
      .setGoal(DEFAULT_GOAL)
      .build()
  )
};

export class DashboardSettings {
  constructor(data = DEFAULT_ACTIVITY) {
    this.goal = data.goal;
    this.changedAt = data.changedAt;
    this.weekdays = data.weekdays;
  }
  getGoalForDate(date) {
    const dDate = dayJS.get(date);
    const currentDay = dDate.day();
    return this.weekdays.length
      ? this.weekdays.find(weekday => weekday.day === currentDay)
      : null;
  }
  getNextActivityDate(date) {
    if (!this.weekdays.length) {
      return null;
    }
    const dDate = dayJS.get(date);
    const currentDay = dDate.day();
    let nextIndex = this.weekdays.findIndex(d => d.day > currentDay);
    let addDays = 0;
    if (nextIndex < 0) {
      nextIndex = 0;
      addDays = 7;
    }
    return dDate.day(this.weekdays[nextIndex].day).add(addDays, 'days');
  }
  isValid() {
    return (
      Number.isSafeInteger(this.goal) &&
      Number.isSafeInteger(this.changedAt) &&
      Array.isArray(this.weekdays)
    );
  }
  setGoal(goal) {
    this.goal = goal;
    return this;
  }
  setChangedAt(changedAt) {
    this.changedAt = changedAt;
    return this;
  }
  setWeekdays(weekdays) {
    this.weekdays = weekdays;
    return this;
  }
  build() {
    return {
      goal: this.goal,
      weekdays: this.weekdays,
      changedAt: this.changedAt
    };
  }
}
