import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BuildValidateService.js');

function logNetworkError(error) {
  if (process.client) {
    return;
  }
  const url = error?.config?.url || '';
  const data = error?.config?.data || {};
  logger.fatal(`Get error on url: ${url}, error: ${error} data: ${data}`);
}

function checkLibraryView(response, retryNum) {
  const libraryView = response?.data?.libraryView || {};
  const langs = Object.keys(libraryView);
  if (langs.length === 0) {
    if (process.server && retryNum === 0) {
      logger.fatal('Did not find languages in library');
    } else {
      return false;
    }
  }
  let numberBooks = 0;
  for (let i = 0; i < langs.length; i++) {
    const lang = langs[i];
    const publNum = libraryView[lang].length;
    numberBooks += publNum;
  }
  if (numberBooks === 0) {
    if (process.server && retryNum === 0) {
      logger.fatal('Library empty');
    } else {
      return false;
    }
  }
  return true;
}

function isEmptyParagraphs(scrollItems = [], bookId) {
  if (!this.bookId) {
    return;
  }
  const isEmpty = scrollItems.every(item => item.paragraph.length === 0);
  if (isEmpty) {
    logger.fatal(`All scroll items empty for publication id: ${bookId}`);
  }
}

function checkCategoryState(searcher, publications, store) {
  const librarySummary = store.getters['LibraryStore/getLibrarySummary'];
  if (librarySummary.totalPublications === 0 && process.server) {
    logger.fatal(`Library empty for category: ${searcher.categories}`);
    return;
  }
}

export default {
  logNetworkError,
  checkLibraryView,
  isEmptyParagraphs,
  checkCategoryState
};
