<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    width="400"
    transition="slide-y-reverse-transition"
    :content-class="computedContentClass"
  >
    <v-card>
      <v-card-title class="popup-header" primary-title>
        <span class="popup-header-wrapper" @click="playAudio">
          <button class="button-play">
            <BaseSpriteIcon icon-name="ico-book-info-read-solid" />
          </button>
          <span class="popup-header-title">{{
            $t('PopupPlayButton.play')
          }}</span>
        </span>
        <span class="close-wrapper">
          <button class="button-close" @click="closePopup()">
            <BaseSpriteIcon icon-name="ico-close" />
          </button>
        </span>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import PopupNamesEnum from '@/enums/PopupNamesEnum';
const DEFAULT_CONTEXT = {};

export default {
  name: PopupNamesEnum.PLAY_BUTTON_POPUP,
  components: { BaseSpriteIcon },
  props: {
    popupContext: {
      type: Object,
      default: () => DEFAULT_CONTEXT
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    computedContentClass() {
      return this.popupContext.popupHidden
        ? 'play-button-popup hidden'
        : 'play-button-popup';
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closePopup();
      }
    }
  },
  mounted() {
    this.dialog = true;
  },
  methods: {
    playAudio() {
      const { startLocator, endLocator } = this.popupContext;
      this.$store.dispatch('PlaybackStore/playFromLocator', {
        locator: startLocator,
        endLocator
      });
      this.closePopup();
    },
    closePopup() {
      this.dialog = false;
      this.$store.commit('OpenParameterStore/clearHighlight');
      this.$emit('popupEventHandler', {
        name: PopupNamesEnum.PLAY_BUTTON_POPUP
      });
    }
  }
};
</script>

<style lang="less" src="./PlayButtonPopup.less"></style>
