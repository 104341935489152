export default {
  DOWNLOAD: 'download',
  ASSET_DOWNLOADED: 'assetDownloaded',
  ASSET_CANCELED: 'assetCanceled',
  ASSETS_REMOVED: 'assetsRemoved',
  UPDATED: 'updated',
  CANCEL: 'cancel',
  ERROR: 'error',
  REMOVE: 'remove'
};
