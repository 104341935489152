function createDeferred() {
  let resolve;
  let reject;

  const promise = new Promise(function(_res, _rej) {
    resolve = _res;
    reject = _rej;
  });

  return {
    promise,
    resolve,
    reject
  };
}

function wait(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function deferWait(time) {
  const defer = createDeferred();
  wait(time).then(defer.resolve, defer.reject);
  return defer;
}

function createAutoDropDefer(maxWaitTime, dropMessage) {
  const defer = createDeferred();
  const timeout = setTimeout(() => {
    defer.reject(new Error(dropMessage));
  }, maxWaitTime);
  const originResolve = defer.resolve;

  defer.timeout = timeout;
  defer.resolve = function(data) {
    clearTimeout(timeout);
    originResolve(data);
  };
  return defer;
}

export default {
  createDeferred,
  wait,
  deferWait,
  createAutoDropDefer
};
