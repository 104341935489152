import RequestService from '@/services/RequestService';

function processAddBookmarkRequest({ bookmark, reqParams }) {
  const query = reqParams.toJSON();
  query.materials = { bookmark: bookmark.createRawMaterial() };
  return RequestService.request('post', 'BookmarksController', 'set', query);
}

function processDeleteBookmarkRequest({ bookmark, reqParams }) {
  const query = reqParams.toJSON();
  query.materials = { bookmarkId: bookmark?.id };
  return RequestService.request(
    'delete',
    'BookmarksController',
    'deleteBookmark',
    query
  );
}

export default {
  processAddBookmarkRequest,
  processDeleteBookmarkRequest
};
