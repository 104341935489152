<template>
  <span>
    <v-dialog
      v-model="dialog"
      :content-class="'extending-popup'"
      :scrollable="true"
      :width="175"
      hide-overlay
    >
      <v-card>
        <ul class="menu-section-content">
          <li
            v-for="menuItem in menuItems"
            :key="menuItem.key"
            :disabled="menuItem.disabled"
            @click="onItemClick(menuItem)"
          >
            <span class="menu-item-icon">
              <BaseSpriteIcon :icon-name="menuItem.link" /> </span
            ><span class="menu-item-text">{{ menuItem.tooltipText }}</span>
          </li>
        </ul>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

export default {
  components: { BaseSpriteIcon },
  data() {
    return {
      dialog: false,
      menuItems: [],
      displayContext: null,
      endSubscription: null
    };
  },
  computed: {
    getPopupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.EXTENDING_MENU
      );
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close();
      }
    }
  },
  mounted() {
    this.menuItems = this.getPopupContext.extendedMenuItems;
    this.displayContext = this.getPopupContext.displayContext;
    this.dialog = true;
    this.endSubscription = this.$store.subscribeAction(action => {
      switch (action.type) {
        case 'ManagePopupStore/openPopup': {
          const popupNames = [
            PopupNamesEnum.PROFILE_POPUP,
            PopupNamesEnum.ASSESSMENT_CONTAINER,
            PopupNamesEnum.READING_SETTINGS_POPUP,
            PopupNamesEnum.EXTRAS_POPUP
          ];
          if (popupNames.includes(action.payload.name)) {
            this.close();
          }
          break;
        }
      }
    });
  },
  destroyed() {
    if (this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.displayContext = null;
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.EXTENDING_MENU
      });
    },
    onItemClick(menuItem) {
      menuItem.handler(this.displayContext);
      this.close();
    }
  }
};
</script>

<style lang="less" src="./ExtendingMenu.less"></style>
