import RequestService from '@/services/RequestService';
import StudyClassFactory from '@/classes/factories/StudyClass/StudyClassFactory';

import UserStore from './UserStore';
// import LibraryStore from './LibraryStore';
import ClassData from '@/components/pages/ManageStudyClass/StudyCourseData.json';
import UserStudyProgress from '@/components/views/Participants/UserStudyProgress.json';
import clone from 'lodash/clone';

const CONTROLLER_NAME = 'StudyClass';
const initState = () => ({
  classes: {}
});

class StudyClassData {
  constructor(classData) {
    this.classProperties = classData.class;
    this.teachers = classData.teachers;
    this.userRole = classData.userRole;
    this.course = Array.isArray(classData.studyCourseInfo.course)
      ? classData.studyCourseInfo.course
      : [classData.studyCourseInfo.course];
    this.participants = [];
    this.name = classData.class.name;
    this.description = classData.class.description;
  }
}

class ClassDetails {
  constructor(classProperties) {
    this.privacy = classProperties.classType;
    this.classDatesEnabled = !!classProperties.expectedDailyWork;
    this.classStartDate =
      classProperties.scheduledAt || new Date().toLocaleDateString();
    this.lastDayToEnroll = new Date().toLocaleDateString();
    this.readingDays = clone(classProperties.studyWeekDays);
    this.hoursPerDay = 0.5;
    this.allowDiscussions = classProperties.allowDiscussions;
    this.allowSkipBooks = classProperties.allowSkipBooks;
  }
}

class StudyClass {
  constructor(data, userId) {
    this.name = data.name;
    this.description = data.description;

    this.classType = data.classType;
    this.courseStartDate = data.scheduledAt;
    this.allowSkipBooks = data.allowSkipBooks;
    this.classDatesEnabled = !!data.expectedDailyWork;
    this.expectedDailyWork = data.expectedDailyWork;
    this.readingDays = data.studyWeekDays;
    this.lastDayToEnroll = new Date().toLocaleDateString();

    this.publicationId = data.publicationId;
    this.teachers = data.teachers;
    this.isTeacher = this.isUserTeacher(userId);
  }

  isUserTeacher(userId) {
    return Object.keys(this.teachers).includes(userId);
  }
}

function presistStudyClass(studyClass, type) {
  const data = {
    studyClass: studyClass,
    type: type
  };
  return RequestService.request('post', 'StudyClass', 'persist', data);
}

function cancelStudyClass(classId, comment) {
  const data = {
    classId,
    comment
  };
  return RequestService.request('post', 'StudyClass', 'cancelstudyclass', data);
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  createStudyClass({ state, commit }, { studyClass }) {
    const query = StudyClassFactory.createPersistQuery(studyClass);
    return presistStudyClass(query, 'createByClassId');
  },
  retrieveClassData: async function({ state, commit }, { classId }) {
    if (!state.classes[classId]) {
      await commit('fillClassData', { classId, classData: ClassData });
    }
    return Promise.resolve(state.classes[classId]);
  },
  retrieveParticipantsOfClass: ({ state, commit }, { classId }) => {
    const currentClass = state.classes[classId];
    if (!currentClass.participants.length) {
      commit('setClassParticipants', {
        participants: UserStudyProgress.usersProgress
      });
    }
    return Promise.resolve(currentClass.participants);
  },
  // eslint-disable-next-line no-unused-vars
  cancelStudyClass(store, { classId, comment = '' }) {
    return cancelStudyClass(classId, comment);
  }
};

const storeGetters = {
  getClassData: state => ({ classId }) => {
    if (!state.classes[classId]) {
      state.classes[classId] = new StudyClassData(ClassData);
    }
    return state.classes[classId];
  },
  getUserRole: state => ({ classId }) => {
    return state.classes[classId].userRole;
  },
  getClassParticipants: state => ({ classId }) => {
    return state.classes[classId].participants;
  },
  getTeachers: state => ({ classId }) => {
    return state.classes[classId].teachers;
  },
  getClassProperties: state => ({ classId }) => {
    return state.classes[classId].classProperties;
  },
  getStudentOfClass: state => ({ classId, studentId }) => {
    const participants = state.classes[classId].participants;
    const found = participants.find(p => p.id === studentId);
    return found || null;
  },
  getClassDetails: state => ({ classId }) => {
    return new ClassDetails(state.classes[classId].classProperties);
  },
  getReadingPlan: state => ({ classId }) => {
    return state.classes[classId].course;
  }
};

const mutations = {
  fillClassData: async function(state, { classId }) {
    let classInfo = await RequestService.request(
      'GET',
      CONTROLLER_NAME,
      'info',
      { classId }
    );
    const userId = UserStore.getters.getUserId();
    classInfo = classInfo ? classInfo.data : {};
    // eslint-disable-next-line no-unused-vars
    const classModel = new StudyClass(classInfo, userId);
    // state.classes[classId] = new StudyClassData(classInfo);
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
