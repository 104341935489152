'use strict';

var context = {};
/* --- api --- */
context.application = '';
context.serverUrl = '';
context.applicationUrl = '';
context.applicationReaderUrl = '';
context.applicationEditorUrl = '';
context.systemLevelNamespace = '';
context.applicationLevelNamespace = '';
context.localRepositoryAvailable = false;
context.localRepositoryType = '';
context.localFileSystemAvailable = false; //FilesHandlingFs.isAvailable();
context.localFileSystemUrl = false; //FilesHandlingFs.getLocalPath();

export default context;
