// loading some basic stuff
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('agentStorage.js');
const loggerQuery = LoggerFactory.getLogger('agentStorage.js.query');

import PouchDB from 'pouchdb';
import memoryAdapter from 'pouchdb-adapter-memory';
import AgentErrorEnum from '@/enums/AgentErrorEnum';
import agentConfigBuilder from './agentConfigBuilder';
import tools from './tools';
const Promise = tools.Promise;

let Context;
let agentConfig = {};

if (process.client) {
  window.PouchDB = PouchDB; // debug
}
// PouchDB.debug.enable('*')

// extend Event emitter
const emitter = tools.MicroEvent;
const bind = emitter.bind.bind(emitter);
const unbind = emitter.unbind.bind(emitter);
const trigger = emitter.trigger.bind(emitter);

/**
 * Store link to initialized dbs
 * @type {Object<CustomPouchDB>}
 * @example { "db name" : db object}
 * @private
 */
let dbPool = {};

/**
 * Store link to DataFlows {@see DataFlow}
 * @type {Object<DataFlow>}
 * @example { "db name" : DataFlow }
 */
const syncPool = {};

/**
 * Almost the same as {@link _syncConfigSystemData}
 */
const _syncConfigUserData = {}; // see _syncConfigSystemData

// ALIAS => sync info
// type = (sync|replicate)
// mode = (live|query|timer)
// score = (-1|<int>)  Score parameter applicable only for TIMER mode. Score -1 means that we ignore it in scoring system.
const _syncConfigSystemData = {
  // public: {
  //   type: 'replicate',
  //   mode: 'timer'
  // },
  // query: {
  //   type: 'replicate',
  //   mode: 'timer'
  // },
  user_rw: {
    // jshint ignore:line
    type: 'sync',
    mode: 'live'
    // mode: 'timer'
  }
};

var _queryTimeoutData = {
  user: {
    multiplier: 5
  },
  course: {},
  query: {}
};

/**
 * @type {Promise}
 * @private
 */
var _initPromise;

function updateInterval(value) {
  Object.keys(syncPool).forEach(function(key) {
    syncPool[key].updateInterval(value);
  });
}

function init(_Context) {
  Context = _Context;
  agentConfig = _createAgentConfig(Context);

  if (agentConfig.useMemoryAdapter) {
    PouchDB.plugin(memoryAdapter);
    PouchDB.preferredAdapters = ['memory', 'idb'];
  }
  _addListeners(agentConfig);
}

function _createAgentConfig(_Context) {
  return agentConfigBuilder.createAgentConfig(_Context.parameters.agent);
}

function _addListeners(_agentConfig) {
  if (window.cordova) {
    var listener = immediateSync.bind(this, [_agentConfig.dbNames.userRW]);
    document.addEventListener('pause', listener);
    document.addEventListener('resume', listener);

    document.addEventListener('pause', function() {
      updateInterval(_agentConfig.syncInterval * _agentConfig.idleMultiplier);
    });

    document.addEventListener('resume', function() {
      updateInterval(_agentConfig.syncInterval);
    });
  }
}

/**
 * Initialize offline storage
 * Note, that offline storage is not available until initialization process has been done.
 *
 * @param {string} userId logged-in user id
 * @param {boolean} isGuest is guest logged in
 * @return {Promise} resolves when offline storage ready.
 * @emit string#init
 */
function initStorage(userId, isGuest, options) {
  const { forceInitConnection } = options;
  const isOldGuest =
    agentConfig.ownerId === userId && agentConfig.isGuestMode && !isGuest;
  if (isOldGuest) {
    agentConfig.cred = {
      user: null,
      pass: null
    };
  }
  if (
    agentConfig.ownerId === userId &&
    _initPromise &&
    !isOldGuest &&
    !forceInitConnection
  ) {
    return _initPromise;
  }

  agentConfig.ownerId = userId;
  agentConfig.isGuestMode = !!isGuest;

  // get environment
  var brand = Context.parameters.brand || '';
  var branch = Context.parameters.branch || '';
  agentConfig.dbPrefix = brand && branch ? brand + '-' + branch : '';

  logger.info(
    'Initialize offline storage [' +
      (agentConfig.dbPrefix || '<no dbPrefix>') +
      '] for user:' +
      userId
  );

  const localNames = getLocalNames();
  const isClearPublicDb =
    localStorage['_pouch_db_prefix'] !== agentConfig.dbPrefix; //jshint ignore:line
  const isAnotherUser = localStorage['_pouch_owner'] !== userId; //jshint ignore:line
  let cleanPromise = Promise.resolve();
  if ((isAnotherUser && !agentConfig.isGuestMode) || isClearPublicDb) {
    logger.info(
      'Wrong user or agentConfig.dbPrefix detected. Clearing databases'
    );
    _initDb(agentConfig.dbNames.userRW);
    let names = getLocalNames();
    if (!isClearPublicDb) {
      logger.info('Keep public DB data');
      //keep public data for another user
      names = names.filter(function(i) {
        return i !== agentConfig.dbNames.public;
      });
    }
    cleanPromise = clearLocalDbs(names);
  }
  dbPool = {};
  _initPromise = cleanPromise
    .then(function() {
      return Promise.all(localNames.map(initDb));
    })
    .then(function() {
      localStorage['_pouch_owner'] = userId; //jshint ignore:line
      localStorage['_pouch_db_prefix'] = agentConfig.dbPrefix; //jshint ignore:line

      agentConfig.initialized = true;
      trigger('init', userId);
      logger.debug('Initialized!');
      return Promise.resolve({
        initialized: agentConfig.initialized
      });
    })
    .catch(function(e = {}) {
      logger.error(e);
      e.type = AgentErrorEnum.INIT_STORE_ERROR;
      throw e;
    });

  return _initPromise;
}

async function logout(isGuest = false) {
  if (!isGuest) {
    const localNames = getLocalNames();
    await clearLocalDbs(localNames);
  }
  agentConfig = _createAgentConfig(Context);
}

function clearLocalDbs(names) {
  return Promise.all(names.map(destroyDb));
}

function getLocalNames() {
  const localNames = Object.keys(agentConfig.dbNames).map(function(key) {
    return agentConfig.dbNames[key];
  });
  return localNames;
}

async function fillStorage(initData) {
  const localDb = getDb(agentConfig.dbNames.userRW);
  const docs = initData.map(d => {
    const doc = { ...d.doc };
    delete doc._rev;
    return doc;
  });
  await localDb.bulkDocs({
    docs: docs
  });
  trigger('init_data_filled', docs);
}

/**
 * @constructor
 */
function CustomHttpRequest() {
  var xhr = new XMLHttpRequest(); // call basic constructor
  if (!agentConfig._useCookieAuth) {
    var openOriginal = xhr.open;
    xhr.open = function(method, url, async, user, password) {
      user = agentConfig.cred.user;
      password = agentConfig.cred.pass;
      async = true;
      return openOriginal.call(this, method, url, async, user, password);
    };
  }
  return xhr;
}

/**
 * Start synchronization
 * Assume localStorage is available
 *
 * @param {{user:string, pass:string, prefix:string}} syncInfo - credentials for sync
 * @return {Promise}
 */
function startSync(syncInfo) {
  if (!syncInfo.user || !syncInfo.pass || !syncInfo.prefix) {
    const error = new Error(
      `Configuration missing: "user", "pass" or "prefix" with userId: ${syncInfo.user}`
    );
    error.type = AgentErrorEnum.POUCH_CONFIG_ERROR;
    return Promise.reject(error);
  }

  if (agentConfig.cred.user === syncInfo.user) {
    //was called already (for some reason _onAuth called twice)
    return;
  }

  agentConfig.cred = {
    user: syncInfo.user,
    pass: syncInfo.pass,
    prefix: syncInfo.prefix
  };

  try {
    if (agentConfig.initialized) {
      //noinspection JSIgnoredPromiseFromCall
      _startSync();
    } else {
      bind('init', _startSync);
    }
  } catch (error) {
    error.type = AgentErrorEnum.START_SYNC_ERROR;
    throw error;
  }
  return;
}

/**
 * Create all sync processes
 * @return {Promise}
 * @private
 */
function _startSync() {
  unbind('init', _startSync);
  return Promise.all(
    // run system and user data flows
    Object.keys(_syncConfigSystemData).map(initSync) //.concat( updatePriorities() )
  );
}

/**
 * Terminate sync process and remove it at all from schedule
 * @param {dbAlias} dbName - db alias
 * TODO: rename this method, because it doesn't correspond to startSync()
 */
function stopSync(dbName) {
  if (syncPool[dbName]) {
    syncPool[dbName].stop();
    delete syncPool[dbName];
  }
  return Promise.resolve();
}

/**
 * Get db by name
 * @param {dbAlias} name - db alias
 * @return {CustomPouchDB}
 */
function getDb(name) {
  if (!agentConfig.initialized) {
    throw new Error('offline storage is not initialized');
  }
  return initDb(name);
}

/**
 * initialize db
 * @param {dbAlias} name - db alias
 * @returns {CustomPouchDB}
 */
function initDb(name) {
  if (!dbPool[name]) {
    dbPool[name] = _initDb(name);
  }
  return dbPool[name];
}

/**
 * Initialize new {@link CustomPouchDB} instance
 * @param {dbAlias} name - database alias. it is not the same as real db name
 * @return {CustomPouchDB}
 * @private
 */
function _initDb(name) {
  logger.trace('Initialize pouch database: ' + name);

  /**
   * @typedef {PouchDB} CustomPouchDB
   */
  return new CustomPouchDB(name, {
    ajax: {
      withCredentials: agentConfig._useCookieAuth
    },
    auto_compaction: true, // jshint ignore:line
    adapter: agentConfig.adapter
  });
}

/**
 * @constructor
 * @param {dbAlias} alias
 * @param {object} options
 *
 * Here we wrap all pouch functions. It allow us not to modify it, so our solution become more stable
 */
function CustomPouchDB(alias, options) {
  /**
   * @type {PouchDB}
   * @protected
   * @memberOf CustomPouchDB#
   */
  this._db = new PouchDB(_localDbURI(alias), options);
  this.name = this._db.name;

  /**
   * @type {dbAlias}
   * @memberOf CustomPouchDB#
   */
  this.customDbName = alias;
  // this._alias = alias;

  // just add here any pouch function you want to use
  this.get = _pouchProxyErrorDetails(this._db.get, 'get').bind(this._db);
  this.put = _pouchProxyErrorDetails(this._db.put, 'put').bind(this._db);
  this.post = _pouchProxyErrorDetails(this._db.post, 'post').bind(this._db);
  this.bulkDocs = _pouchProxyErrorDetails(this._db.bulkDocs, 'bulkDocs').bind(
    this._db
  );
  this.allDocs = _pouchProxyErrorDetails(this._db.allDocs, 'allDocs').bind(
    this._db
  );
  this.info = _pouchProxyErrorDetails(this._db.info, 'info').bind(this._db);
  // db.post      = _pouchProxyErrorDetails(db.post).bind(this._db);

  // https://github.com/pouchdb/pouchdb/issues/7011
  // Don't know how to determine we are on WebSQL/SQLite. the issue with more than 1k keys
  // for cordova and keys more than 1k - use portioned requests
  if (window.cordova) {
    var keysLimitCount = 999;
    this._allDocs = this.allDocs;
    this.allDocs = function() {
      var that = this;
      var args = Array.prototype.slice.call(arguments);
      var argOptions = args[0];
      if (
        argOptions &&
        typeof argOptions === 'object' &&
        argOptions.hasOwnProperty('keys') &&
        Array.isArray(argOptions.keys) &&
        argOptions.keys.length > keysLimitCount
      ) {
        args.shift();
        var keysPortioned = [];
        while (argOptions.keys.length) {
          keysPortioned.push(argOptions.keys.splice(0, keysLimitCount));
        }
        var clonedOptionsObj = JSON.stringify(argOptions);
        return tools.Promise.all(
          keysPortioned.map(function(portion) {
            var _arguments = [JSON.parse(clonedOptionsObj)].concat(args);
            _arguments[0].keys = portion;
            return that._allDocs.apply(that, _arguments);
          })
        ).then(function(results) {
          var rows = results.reduce(function(ac, val) {
            return ac.concat(val.rows || []);
          }, []);
          results[0].rows = rows;
          return results[0];
        });
      } else {
        return this._allDocs.apply(this, args);
      }
    };
  }

  if (alias === agentConfig.dbNames.userRW) {
    if (agentConfig.ownerId) {
      // // proxy pouch operations
      this.get = _pouchProxyGet(this.get, agentConfig.ownerId);
      this.allDocs = _pouchProxyAllDocs(this.allDocs, agentConfig.ownerId);
      this.put = _pouchProxyPut(this.put, agentConfig.ownerId);
    }
  }
  if (alias.indexOf(agentConfig.coursePrefix) === 0) {
    var courseId = alias.split('_')[1];
    if (courseId) {
      // // proxy pouch operations
      this.get = _pouchProxyGet(this.get, courseId);
      this.allDocs = _pouchProxyAllDocs(this.allDocs, courseId);
    }
  }
}

/**
 * Destroy local db.
 * @param {dbAlias} dbName
 * @return {Promise} when db is destroyed
 */
function destroyDb(dbName) {
  stopSync(dbName);

  delete dbPool[dbName];
  return _initDb(dbName).destroy();
}

/**
 * Actually perform db deletion and log it
 * @return {Promise}
 */
CustomPouchDB.prototype.destroy = function() {
  var self = this;
  return self._db.destroy().then(function(any) {
    logger.trace('Database destroyed: ' + (self.customDbName || self.name)); //jshint ignore:line
    return any;
  });
};

///////////////////////////////////////////////////////////////////////////////
// Flow data

/**
 * Create data copy process
 * @param {dbAlias} dbName
 * @return {DataFlow}
 */
function initSync(dbName) {
  return _persistDataFlow(dbName);
}

/**
 * Create and run data flow process
 * @param {dbAlias} dbName
 * @param {object} [opts]
 * @return {DataFlow|undefined}
 */
async function _persistDataFlow(dbName, opts) {
  opts = opts || {};
  var config = _syncConfigSystemData[dbName] || _syncConfigUserData[dbName];
  if (!config) {
    // skip it
    logger.warn('unknown db config ', dbName);
    return;
  }

  if (dbName === agentConfig.dbNames.userRW && agentConfig.isGuestMode) {
    logger.log('Do not sync user data in guest mode');
    return;
  }

  //
  if (!syncPool[dbName]) {
    syncPool[dbName] = new DataFlow(
      config.type,
      config.mode,
      dbName,
      { interval: agentConfig.syncInterval, delay: config.delayMultiplier },
      opts
    );
    await syncPool[dbName].start();
  }

  // if(config.mode === 'timer'){
  //     // apply priority
  //     if( syncPool[dbName].getInterval() !== syncInterval ) {
  //         syncPool[dbName].updateInterval( syncInterval );
  //     }
  // }

  return syncPool[dbName];
}

/**
 * Get db name by alias
 * @param {dbAlias} localName
 * @return {string} real db name
 */
function _localDbURI(localName) {
  return (
    agentConfig.dbPrefix +
    localName +
    (agentConfig.isGuestMode ? agentConfig.guestPostfix : '')
  );
}

/**
 * get corresponding remote db uri
 * @param {dbAlias} localName
 * @return {string} url
 */
function _remoteDbURI(localName) {
  if (!agentConfig.cred.prefix) {
    throw new Error("no prefix available. don't send request");
  }

  var dbName = localName;
  switch (localName) {
    case agentConfig.dbNames.public:
      dbName = agentConfig.cred.prefix + agentConfig.dbNames.public;
      return agentConfig.url + '/' + dbName;
    //////
    case agentConfig.dbNames.query:
      dbName = agentConfig.cred.prefix + agentConfig.dbNames.query;
      return agentConfig.url + '/' + dbName;
    //////
    case agentConfig.dbNames.user:
      dbName = agentConfig.cred.prefix + 'user_' + agentConfig.cred.user;
      break;
    case agentConfig.dbNames.userRW:
      dbName =
        agentConfig.cred.prefix + 'user_' + agentConfig.cred.user + '_rw';
      break;
    default:
      dbName = agentConfig.cred.prefix + localName;
  }

  if (agentConfig._useCookieAuth || agentConfig._useBasicHeaderAuthorization) {
    return agentConfig.url + '/' + dbName;
  } else {
    // use basic auth.
    return (
      agentConfig.url.replace(
        /\w+:[/\\]{2}/,
        '$&' + agentConfig.cred.user + ':' + agentConfig.cred.pass + '@'
      ) +
      '/' +
      dbName
    );
  }
}

/////////////////////////////////////////////////////////////////////////
// pouch extensions
/**
 * @param {object} [opts]
 * @return {Promise<[CouchDoc]>}
 */
CustomPouchDB.prototype.getAll = function pouchGetAll(opts) {
  opts = opts || {};
  opts.include_docs = true; // jshint ignore:line
  return this.allDocs(opts) // jshint ignore:line
    .then(tools.pouch.extractDocs);
};

/**
 * @param {Array<string>} keys
 * @return {Promise<[CouchDoc]>}
 */
CustomPouchDB.prototype.getByKeys = function pouchGetByKeys(keys) {
  return this.getAll({ keys: keys }); // jshint ignore:line
};

/**
 * @param {string} prefix
 * @return {Promise<[CouchDoc]>}
 */
CustomPouchDB.prototype.getAllByPrefix = function pouchGetAllByPrefix(prefix) {
  return this.getAll({ startkey: prefix, endkey: prefix + '\uffff' }); // jshint ignore:line
};

/**
 * @param {CouchDoc} input
 * @param {string} type
 * @return {Promise<PouchResult>}
 */
CustomPouchDB.prototype.createTask = function pouchCreateTask(input, type) {
  if (this.customDbName !== agentConfig.dbNames.userRW) {
    return Promise.reject(
      'createTask allowed only for ' + agentConfig.dbNames.userRW + ' database'
    );
  }

  var self = this;
  var promise;
  if (input._id) {
    promise = self.get('task-' + input._id).catch(tools.pouch.default());
  } else {
    promise = Promise.resolve();
  }
  return promise.then(function(res) {
    return self.put({
      // jshint ignore:line
      _id: 'task-' + (input._id || tools.guid()),
      _rev: (res && res._rev) || undefined,
      type: 'task',
      name: type,
      data: input,
      created: Date.now()
    });
  });
};

//////////
// pouch proxy for clever errors

/**
 * Add error details for pouch error
 * @param {Function} originalFn
 * @param {string} [originalName] - original function name
 * @private
 */
function _pouchProxyErrorDetails(originalFn, originalName) {
  return __proxyCallback(function() {
    var that = this;
    var args = Array.prototype.slice.call(arguments);
    return originalFn.apply(this, args).catch(function(e) {
      e.args = args;
      e.fnName = originalName || /* originalFn.name ||*/ 'unknown';
      e.callee = that;
      throw e;
    });
  });
}

//////////
// pouch proxy for smart-ids

/**
 * @const
 * @type {string}
 */
var SMART_IDS_SEPARATOR = '_';

/**
 * Extract last argument (assuming it's a callback function) and call it as last
 * @param {function} fn - original pouch function Could be any function, which has callback as last argument
 * @private
 */
function __proxyCallback(fn) {
  return function() {
    var args = Array.prototype.slice.call(arguments);

    // pop custom callback if it's exist
    var customCallback = null;
    if (typeof args[args.length - 1] === 'function') {
      // assume it's callback
      customCallback = args.pop();
    }

    return (
      fn
        .apply(this, args)
        // make sure we have called custom callback. And one time only =)
        .then(function(data) {
          if (customCallback) {
            customCallback(null, data);
          }
          return data;
        })
        .catch(function(e) {
          if (customCallback) {
            customCallback(e);
          }
          throw e;
        })
    );
  };
}

/**
 * @param {function} pouchOriginalGetFn
 * @param {string} suffix
 * @return {function(string, ?object, ?function)}
 * @private
 *
 * @more https://pouchdb.com/api.html#fetch_document
 */
function _pouchProxyGet(pouchOriginalGetFn, suffix) {
  // var __get = _pouchProxyPromisifyGet(pouchOriginalGetFn);

  return __proxyCallback(function(/* docId, [options] */) {
    var that = this;
    var args = Array.prototype.slice.call(arguments);

    // transform id
    args[0] = __addSmSuffix(args[0], suffix);
    return pouchOriginalGetFn
      .apply(that, args)
      .then(function(doc) {
        // transform back id
        if (doc && doc._id) {
          doc._id = __removeSmSuffix(doc._id, suffix);
        }
        return doc;
      })
      .catch(
        tools.pouch.default(function() {
          // try without suffix
          args[0] = __removeSmSuffix(args[0], suffix);
          return pouchOriginalGetFn.apply(that, args);
        })
      );
  });
}

/**
 * @param {function} originalFn
 * @param {string} suffix
 * @return {function}
 * @private
 *
 * @more https://pouchdb.com/api.html#batch_fetch
 */
function _pouchProxyAllDocs(originalFn, suffix) {
  return __proxyCallback(function(/*args*/) {
    var that = this;
    var args = Array.prototype.slice.call(arguments);

    // transform all ids to smart-ids
    var opts = args[0];
    if (opts && typeof opts !== 'function') {
      if (opts.key) {
        opts.key = __addSmSuffix(opts.key, suffix);
      }
      if (opts.keys) {
        opts.keys = opts.keys.map(function(key) {
          return __addSmSuffix(key, suffix);
        });
      }
    }

    /**
     * transform back ids
     * @param {CouchMetaDoc} docMeta
     */
    var removeSuffix = function(docMeta) {
      if (docMeta.id) {
        docMeta.id = __removeSmSuffix(docMeta.id, suffix);
      }
      if (docMeta.key) {
        docMeta.key = __removeSmSuffix(docMeta.key, suffix);
      }
      if (docMeta.doc) {
        docMeta.doc._id = __removeSmSuffix(docMeta.doc._id, suffix);
      }
    };

    /**
     * @tyedef {object} docMeta
     * @property {string} id
     * @property {string} [rev]
     * @property {CouchDoc} [doc]
     */

    // get new docs and old docs
    return originalFn.call(that, opts).then(function(res0) {
      // mix records. prefer new one

      /**
       * id=>doc map for faster access
       * @type {Object<CouchMetaDoc>}
       */
      var recordsMap = {};

      if (res0 && res0.rows) {
        res0.rows.forEach(function(docMeta) {
          // "smart" records can trap here as well
          removeSuffix(docMeta);

          var key = docMeta.key || docMeta.id;
          if (!recordsMap[key] || recordsMap[key].error) {
            recordsMap[key] = docMeta;
          }
        });
      }

      // Don't rely on next values
      res0.total_rows = -1; // jshint ignore:line
      res0.offset = -1;
      return res0;
    });
  });
}

/**
 * @param {function} originalFn
 * @param {string} suffix
 * @return {function}
 * @private
 *
 * @more https://pouchdb.com/api.html#create_document
 */
function _pouchProxyPut(originalFn, suffix) {
  return __proxyCallback(function(/*args*/) {
    var that = this;
    var args = Array.prototype.slice.call(arguments);

    if (args[0] && args[0]._id) {
      args[0]._id = __addSmSuffix(args[0]._id, suffix);
    }
    return originalFn
      .apply(that, args)
      .catch(function(e) {
        if (e.status === 409) {
          // we might got into migration process. for. ex: when we red from 'mybooks' and trying to write to 'mybooks_suffix'
          // just drop revision and try again
          if (args[0] && args[0]._rev) {
            delete args[0]._rev;
            return originalFn.apply(that, args);
          }
        }
        throw e;
      })
      .then(function(result) {
        if (result && result.id) {
          result.id = __removeSmSuffix(result.id, suffix);
        }
        return result;
      });
  });
}

/**
 * Add smart suffix
 * @param {string} id
 * @param {string} suffix
 * @return {string} smart-id (even more smarter than guid)
 * @private
 */
function __addSmSuffix(id, suffix) {
  if (!id.endsWith(SMART_IDS_SEPARATOR + suffix)) {
    id += SMART_IDS_SEPARATOR + suffix;
  }
  return id;
}

/**
 * Remove smart suffix
 * @param {string} id
 * @param {string} suffix
 * @return {string} smart-id (even more smarter than guid)
 * @private
 */
function __removeSmSuffix(id, suffix) {
  var sfx = SMART_IDS_SEPARATOR + suffix;
  if (id.endsWith(sfx)) {
    id = id.substring(0, id.length - sfx.length);
  }
  return id;
}

// END: pouch proxy for smart-ids

//////// QUERY

/**
 * @type {Object<PouchDB>}
 */
var pouchRemoteCache = {};

/**
 * @param {dbAlias} dbName
 * @return {PouchDB}
 * @private
 */
function _getRemoteDb(dbName) {
  if (!pouchRemoteCache[dbName]) {
    pouchRemoteCache[dbName] = new PouchDB(_remoteDbURI(dbName), {
      ajax: {
        withCredentials: agentConfig._useCookieAuth,
        xhr: CustomHttpRequest
      },
      skip_setup: true //jshint ignore:line
    });
  }
  return pouchRemoteCache[dbName];
}

/**
 * @param {string} key
 * @return {Promise<PouchDoc>}
 */
CustomPouchDB.prototype.byId = function customQueryById(key) {
  var self = this; //jshint ignore:line
  return self.byIds([key]).then(function(res) {
    return res && res.length ? res[0] : null;
  });
};

/**
 * @param {Array<string>} keys
 * @return {Promise<Array<PouchDoc>>}
 */
CustomPouchDB.prototype.byIds = function customQueryByIds(keys) {
  var self = this; //jshint ignore:line
  return self._customRequest
    .call(self, null, null, keys)
    .catch(tools.pouch.default([]));
};

/**
 * @param {string} prefix
 * @param {boolean} [online=false]
 * @return {Promise<Array<PouchDoc>>}
 */
CustomPouchDB.prototype.byPrefix = function customQueryByPrefix(
  prefix,
  online
) {
  var self = this; //jshint ignore:line
  return self._customRequest
    .call(self, prefix, null, null, online)
    .catch(tools.pouch.default([]));
};

/**
 * @param {string} prefix
 * @param {string} view
 * @param {string} keys
 * @param {boolean} [online=false]
 * @return {Promise<Array<PouchDoc>>}
 */
CustomPouchDB.prototype.byView = function customQueryByView(
  prefix,
  view,
  keys,
  online
) {
  var self = this; //jshint ignore:line
  return self._customRequest
    .call(self, prefix, view, keys, online)
    .catch(tools.pouch.default([]));
};

/**
 * @param {String} prefix - prefix for Pouch DBs
 * @param {String} view   - view name on remote DB
 * @param {Array<string>} keys
 * @param {Boolean} online - set 'true' to always make online request
 * @returns {Promise<Array<PouchDoc>>}
 * @protected
 */
CustomPouchDB.prototype._customRequest = function(prefix, view, keys, online) {
  var self = this; //jshint ignore:line

  // firstly - make offline request
  return __getOffline().then(function(localData) {
    if (
      !localData ||
      !localData.length ||
      (keys && keys.length && keys.length !== localData.length)
    ) {
      loggerQuery.trace(
        'customRequest got empty or incomplete result. going online'
      );
      return __getOnline(prefix, view, keys, localData);
    }

    if (online) {
      loggerQuery.trace('forced online request');
      return __getOnline(prefix, view, keys, localData);
    }

    if (needOnlineRequest(self.customDbName, localData, prefix)) {
      loggerQuery.trace('customRequest data need to be updated. going online');
      __getOnline(prefix, view, keys, localData);
    }

    return tools.clone(localData); // TODO: add description why we need clone()
  });

  /**
   * @private
   * @returns {Promise<Array<PouchDoc>>}
   */
  function __getOffline() {
    var pouchReq;
    if (prefix) {
      pouchReq = self.getAllByPrefix(prefix);
    }
    if (!prefix && !view && keys) {
      pouchReq = self.getByKeys(keys);
    }
    return pouchReq;
  }

  /**
   * @private
   * @returns {Promise<Array<PouchDoc>>}
   */
  function __getOnline(_prefix, _view, _keys, localData) {
    return _customOnlineRequest(self.customDbName, _prefix, _view, _keys).then(
      function(remoteData) {
        updateCacheData(self, remoteData, localData);
        return remoteData;
      }
    );
  }
};

/**
 * @param {PouchDB} db
 * @param {Array<PouchDoc>} newDocs
 * @param {Array<PouchDoc>} oldDocs
 * @returns {Promise<Array<PouchDoc>>}
 * @return {*}
 */
function updateCacheData(db, newDocs, oldDocs) {
  var now = Date.now();
  newDocs.forEach(function(obj) {
    var oldObj = oldDocs.filter(function(i) {
      if (i.activity && i.activity.isClass) {
        return __addSmSuffix(i._id, i.meta.courseId) === obj._id;
      }
      return i._id === obj._id;
    })[0];

    obj._rev = (oldObj && oldObj._rev) || undefined;
    obj.last_update = now; //jshint ignore:line
  });

  return db.bulkDocs(newDocs);
}

/**
 * @param {dbAlias} dbName
 * @param {Array<PouchDoc>} data
 * @return {boolean} true when at least one record is expired
 */
function needOnlineRequest(dbName, data, skipCheckForQuery) {
  if (dbName === agentConfig.dbNames.query && !skipCheckForQuery) {
    return false;
  }
  var multiplier =
    (_queryTimeoutData[dbName] && _queryTimeoutData[dbName].multiplier) || 1;
  data = data || [];
  if (!data.length) {
    return true;
  }
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
      if (
        data[i].last_update &&
        data[i].last_update + agentConfig.queryInvalidateInterval * multiplier <
          Date.now()
      ) {
        //jshint ignore:line
        return true;
      }
    }
  }
  return false;
}

/**
 * @param {dbAlias} dbName
 * @param {String} prefix prefix for Pouch DBs
 * @param {String} view view name on remote DB
 * @param {Array<string>} keys
 * @returns {*}
 * @private
 */
function _customOnlineRequest(dbName, prefix, view, keys) {
  loggerQuery.trace(
    '_onlineRequest',
    dbName,
    prefix ? '<prefix> prefix=' + prefix : '',
    view ? '<view> view=' + view : '',
    keys ? '<ids> keys=' + keys : ''
  );
  return requestRemoteData(_getRemoteDb(dbName), prefix, view, keys);
}

function requestRemoteData(db, prefix, view, keys) {
  if (view) {
    return requestByView(db, view, keys);
  }
  if (prefix) {
    return requestByPrefix(db, prefix);
  }
  if (keys) {
    return requestByKeys(db, keys);
  }

  loggerQuery.warn(
    'cannot handle request ',
    db.customDbName,
    prefix,
    view,
    keys
  );
}

function requestByPrefix(db, prefix) {
  return handleResponse(
    db.allDocs({
      startkey: prefix,
      endkey: prefix + '\uffff',
      include_docs: true
    })
  ); //jshint ignore:line
}

function requestByKeys(db, keys) {
  return handleResponse(db.allDocs({ keys: keys, include_docs: true })); //jshint ignore:line
}

function requestByView(db, view, keys) {
  return handleResponse(
    db.query('schema/' + view, { keys: keys, include_docs: true })
  ); //jshint ignore:line
}

function handleResponse(promise) {
  return promise.then(tools.pouch.extractDocs).catch(function(e) {
    //status 0 - offline
    if (e.status !== 0 && e.status !== 404) {
      logger.warn(e);
    }
    return [];
  });
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// some user functions

/**
 *
 */
async function setUserInfo(info) {
  const id = 'login-info';
  let res;
  try {
    res = await getDb(agentConfig.dbNames.user).get(id);
  } catch (error) {
    logger.info(error);
    res = tools.pouch.default({})(error);
  }
  try {
    var photoHash = (info.photo && info.photo.fileHash) || info.photo;
    var userInfo = {
      _id: id,
      _rev: res && res._rev,
      id: info.id || res.id,
      userId: info.id || res.id, //TODO check
      type: 'info',
      name: info.name,
      created: info.registeredAt || info.created || res.created,
      emailConfirmationStatus:
        info.emailConfirmationStatus || res.emailConfirmationStatus,
      hasPassword:
        (info.passwordHash && info.passwordHash.length !== 0) ||
        info.hasPassword ||
        res.hasPassword,
      email: Array.isArray(info.email) ? info.email : [info.email],
      photo: photoHash ? { fileHash: photoHash } : res.photo || '',
      photoLink: info.photoLink || res.photoLink,
      external: info.externaluserid || info.external || res.external,
      roles: (info.roles ? info.roles : getUserRole(info)) || res.roles,
      status: info.active || res.status,
      updated: Date.now()
    };
    const response = await getDb(agentConfig.dbNames.user).put(userInfo);
    return response;
  } catch (error) {
    if (error && error.name === 'conflict') {
      return;
    }
    error.type = AgentErrorEnum.SET_USER_INFO_ERROR;
    throw error;
  }
}

function getUserRole(obj) {
  var roles = [];
  if (obj.adminRole) {
    roles.push('admin');
  }
  if (obj.editorRole) {
    roles.push('editor');
  }
  if (obj.studentRole) {
    roles.push('student');
  }

  return roles.length === 0 ? ['user'] : roles;
}

//////////////////////////////////////////////////////
/**
 * counter for data flow. just to make id for it
 * @type {number}
 * @private
 */
var _dataFlowCounter = 0;

/**
 * @type {boolean}
 */
var authorized = false;

/**
 * @type {Promise}
 */
var authorizePromise;

/***
 * @class DataFlow

  * @param {String} type (sync|replicate)
  * @param {String} mode (live|query|timer)
  * @param {String} dbName
  * @param {Object} params: {interval:<int> }
  * @param {Object} opts extra pouch options
  */
function DataFlow(type, mode, dbName, params, opts) {
  var self = this;

  var dataFlowlogger = LoggerFactory.getLogger(module.id + '.DataFlow');

  if (type !== 'sync' && type !== 'replicate') {
    throw new Error('Invalid type: ' + type);
  }

  if (mode !== 'live' && mode !== 'timer') {
    throw new Error('Invalid mode: ' + mode);
  }

  if (mode === 'query') {
    dataFlowlogger.warn('query mode suspended');
    return;
  }

  //
  params = params || {};
  var syncMode = mode;
  var syncInterval = params.interval || 10 * 60 * 1000; // 10 min
  var delayMultiplier = params.delay || 1;
  /** @type {string} */
  var _id = '' + _dataFlowCounter++;

  opts = opts || {};
  var getIDsForSync = function() {
    if (dbName !== agentConfig.dbNames.query) {
      return Promise.resolve();
    } else {
      return getDb(agentConfig.dbNames.query)
        .allDocs()
        .then(function(res) {
          return res.rows.map(function(row) {
            return row.id;
          });
        })
        .catch(function() {
          return [];
        });
    }
  };
  // merge opts and properties by default
  if (typeof opts.live !== 'undefined') {
    throw new Error('"live" parameter cannot be changed');
  }

  opts.live = syncMode === 'live';
  // 'retry' and 'back_off_function' are applicable only when options.live is also true
  opts.retry = opts.retry || opts.live;
  opts.back_off_function = opts.back_off_function || _pouchBackOff; // jshint ignore:line

  _printTraceMessage();

  // runtime
  var sync;
  var _timer;
  var _timerTargetAt;

  /**
   * Begin synchronization
   */
  self.start = function() {
    const isStart = true;
    return _initDataFlow(isStart);
  };

  /**
   * Stop synchronization
   */
  self.stop = function() {
    if (_timer) {
      clearTimeout(_timer);
      _timer = null;
    }

    // sync.cancel(); // whenever you want to cancel
    if (sync) {
      // cancel will cause 'complete' event
      if (sync.off) {
        sync.off('complete', _onComplete);
      }
      if (sync.cancel) {
        sync.cancel();
      }
      sync = null;
    }
  };

  /**
   *
   */
  self.getInterval = function() {
    return syncInterval;
  };

  /**
   *
   */
  self.updateInterval = function(milliseconds) {
    if (syncInterval === milliseconds) {
      return;
    }

    dataFlowlogger.info(
      'Update timer for ' + dbName + ': ' + syncInterval + ' -> ' + milliseconds
    );
    syncInterval = milliseconds;

    if (syncMode === 'timer' && _timer) {
      var timeElapsed = _timerTargetAt - tools.now();
      if (timeElapsed > syncInterval) {
        // update timer to lower value.
        scheduleNextSync(syncInterval);
      }
    }
  };

  /**
   *
   */
  self.immediateSync = function() {
    scheduleNextSync(1);
  };

  self.promiseSync = function() {
    return _initDataFlow();
  };

  /**
   *
   */
  function _printTraceMessage() {
    var localDb = _localDbURI(dbName);
    var remoteDb = _remoteDbURI(dbName);

    // this is a general trick for string templating. In this case it looks not so impressive as it can
    dataFlowlogger.info(
      '[%id] Creating %mode%2 %type: %name %remoteuri %5 %localuri'
        .replace('%id', _id)
        .replace('%mode', opts.live ? 'live' : syncMode)
        .replace(
          '%2',
          syncMode === 'timer' ? '(' + syncInterval / 1000 + 's)' : ''
        )
        .replace('%type', type)
        .replace('%name', dbName)
        .replace('%remoteuri', tools.safeUrl(remoteDb))
        .replace('%5', type === 'sync' ? ' <=> ' : ' => ')
        .replace('%localuri', localDb)
    );
  }

  function _printStartMessage() {
    dataFlowlogger.info(
      '[%id] Run %mode %type %name'
        .replace('%id', _id)
        .replace('%mode', opts.live ? 'live' : syncMode)
        .replace('%type', type)
        .replace('%name', dbName)
    );
  }

  var availabilityCache = [];
  /**
   * Make sure db exist
   * Prevent appearing basic authorization dialog.
   */
  function checkDB(url, _defer) {
    var defer = _defer || tools.generateDeferredPromise(); //jshint ignore:line

    var authRequired = url.indexOf('@') > -1 || url.indexOf('user_') !== -1;
    if (!authRequired || availabilityCache.indexOf(url) >= 0) {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest(); //jshint ignore:line
        req.open('GET', url, true);
        req.responseType = 'text';
        req.send();
        req.onreadystatechange = function() {
          if (req.readyState === 4) {
            if (req.status === 200 || req.status === 401) {
              resolve(req.response);
              return;
            } else if (req.status > 401) {
              reject(req.response);
              return;
            }
          }
        };
        req.ontimeout = function(error) {
          reject(error);
        };
        req.onerror = function(error) {
          reject(error);
        };
      });
    }

    var req = new XMLHttpRequest(); //jshint ignore:line
    req.withCredentials = agentConfig._useCookieAuth;
    req.open('GET', url, true);
    if (authRequired) {
      // well, in general we should use credentials from url
      req.setRequestHeader('Access-Control-Allow-Headers', 'Authorization');
      req.setRequestHeader(
        'Authorization',
        'Basic ' + btoa(agentConfig.cred.user + ':' + agentConfig.cred.pass)
      ); //jshint ignore:line
    }

    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        if (req.status === 200 || req.status === 401) {
          defer.resolve(req.response);
          return;
        } else if (req.status > 401) {
          defer.reject(req.response);
          return;
        }
        // var interval = agentConfig.retryInterval;
        // if (req.status === 0) {
        //   interval = agentConfig.retryInterval * 2;
        // }
        // setTimeout(checkDB.bind(this, url, defer), interval); //jshint ignore:line
      }
    };
    req.ontimeout = function(error) {
      defer.reject(error);
    };
    req.onerror = function(error) {
      defer.reject(error);
    };
    logger.trace('Check availability of ', tools.safeUrl(url));
    req.responseType = 'text';
    req.send();

    return defer.promise.then(function() {
      if (!authRequired) {
        availabilityCache.push(url);
      }
      dataFlowlogger.info('    Available: ', tools.safeUrl(url));
      return true;
    });
  }

  /**
   * Start synchronization
   */
  function _initDataFlow(isStart = false) {
    if (
      !agentConfig.cred ||
      agentConfig.cred.user !== localStorage['_pouch_owner']
    ) {
      //jshint ignore:line
      dataFlowlogger.warn(
        'Attempt to sync',
        agentConfig.cred.user,
        localStorage['_pouch_owner']
      ); //jshint ignore:line
      return Promise.resolve();
    }

    var remoteDb = _remoteDbURI(dbName);

    //
    return (function() {
      // can skip checking when uses cookie-based auth
      if (agentConfig._useCookieAuth) {
        return authorize();
      } else {
        return checkDB(remoteDb);
      }
    })()
      .then(function() {
        return loadDump(dbName);
      })
      .then(() => _runSync(isStart))
      .catch(function(e) {
        // something went wrong
        dataFlowlogger.warn('dataFlow failed:', e);
        scheduleNextSync(agentConfig.retryInterval);
        return e;
      });
  }

  function _sync(remoteDb, localDb, syncType, isStart) {
    var onChangeCallback =
      syncType === 'sync' ? _onChangeSync : _onChangeReplication;
    return new Promise((resolve, reject) => {
      if (sync?.cancel && sync?.removeAllListeners) {
        sync.removeAllListeners();
        sync.cancel();
      }
      sync = PouchDB[syncType](remoteDb, localDb, opts)

        // more info about events: https://pouchdb.com/api.html#replication
        .on('change', function(info) {
          dataFlowlogger.trace('change', dbName /*, info */); // info
          // handle change
          onChangeCallback(info);
        })
        .on('paused', function() {
          dataFlowlogger.trace('paused', dbName);
          if (isStart) {
            trigger('start_sync_finished', dbName);
          }
          isStart = false;
          // replication paused (e.g. user went offline)
        })
        .on('active', function() {
          dataFlowlogger.trace('active', dbName);
          // replicate resumed (e.g. user went back online)
        })
        .on('denied', function(info) {
          dataFlowlogger.warn('denied', dbName, info);
          // a document failed to replicate (e.g. due to permissions)
        })
        .on('complete', function(info) {
          resolve(info);
        })
        .on('error', function(err) {
          // dataFlowlogger.trace('error', dbName, err && err.message);
          // handle constant error (occurs when retry==false)
          reject(err);
        })
        .on('requestError', function(err) {
          dataFlowlogger.trace('requestError', dbName, err && err.message);
          // handle requestError error (when retry==true)
          reject(err);
        });
    });
  }

  /**
   * Start synchronization (pure)
   */
  function _runSync(isStart) {
    var localDb = _localDbURI(dbName);
    var remoteDb = _remoteDbURI(dbName);

    // update parameters right before sync to prevent some bugs
    document.body.setAttribute('data-sync-' + dbName.toLowerCase(), 'started');
    opts.live = syncMode === 'live';
    opts.retry = opts.live;
    opts.skip_setup = true; // jshint ignore:line

    opts.ajax = opts.ajax || {};
    opts.ajax.withCredentials = agentConfig._useCookieAuth;
    opts.ajax.xhr = CustomHttpRequest;
    opts.style = 'main_only';
    if (
      dbName === agentConfig.dbNames.userRW &&
      agentConfig._useBasicHeaderAuthorization
    ) {
      opts.auth = {
        username: agentConfig.cred.user,
        password: agentConfig.cred.pass
      };
    }

    _printStartMessage();
    return getIDsForSync()
      .then(function(ids) {
        if (Array.isArray(ids)) {
          opts.doc_ids = ids; // jshint ignore:line
        }
      })
      .then(function() {
        // do not swap source and destination! (remoteDb and localDb)

        return _sync(remoteDb, localDb, type, isStart)
          .then(info => {
            _onComplete(info);
            return info;
          })
          .catch(error => {
            if (error?.result?.status !== 'aborting') {
              logger.error(`Get error on start sync error: ${error}`, {
                sendError: true
              });
            }
            document.body.setAttribute(
              'data-sync-' + dbName.toLowerCase(),
              'error'
            );
            scheduleNextSync(agentConfig.retryInterval);
          });
      });
  }

  /**
   *
   */
  function _onComplete(info) {
    dataFlowlogger.trace('complete', dbName, info);
    trigger('on_complete_data');
    if (syncMode === 'timer' || !info.ok) {
      // schedule next sync
      var isOk =
        info.pull && info.push ? info.pull.ok && info.push.ok : info.ok;
      document.body.setAttribute(
        'data-sync-' + dbName.toLowerCase(),
        'completed'
      );
      scheduleNextSync(
        isOk ? syncInterval * delayMultiplier : agentConfig.retryInterval
      );
    }
  }

  /**
   *
   */
  function scheduleNextSync(timeout) {
    if (_timer) {
      clearTimeout(_timer);
      _timer = null;
    }

    if (syncMode === 'timer' && timeout > 0) {
      _timer = setTimeout(_initDataFlow.bind(self), timeout);
      _timerTargetAt = tools.now() + timeout;
      dataFlowlogger.info(
        '[%id] Scheduled [%dbname] sync in %sec sec'
          .replace('%id', _id)
          .replace('%dbname', dbName)
          .replace('%sec', timeout / 1000)
      );
    }
  }

  /**
   * @typedef {object} PouchChanges
   * @property {'true'|'false'} ok
   * @property {Array<PouchDoc>} docs
   * @property {Array<PouchDoc>} errors
   *
   * @property {number} docs_read
   * @property {number} docs_written
   * @property {number} doc_write_failures
   * @property {number} last_seq
   * @property {string} start_time "2017-01-10T20:51:17.152Z"
   */

  /**
   * onChange for replication
   * @param {PouchChanges} info
   * @private
   */
  function _onChangeReplication(info) {
    trigger('update_received', info, dbName);

    // check for errors
    // replication
    if (typeof info.ok !== 'undefined' && !info.ok) {
      dataFlowlogger.warn(
        '[%id] Replication error occurred'.replace('%id', _id),
        info
      );
    }
  }

  /**
   * onChange for synchronization
   *
   * @param {object} info
   * @param {PouchChanges} info.change    // ?
   * @param {PouchChanges} info.changes   // ?
   * @param {PouchChanges} info.push
   * @param {PouchChanges} info.pull
   * @param {'push'|'pull'} info.direction
   * @private
   */
  function _onChangeSync(info) {
    if (info && info.direction === 'pull') {
      // client send changes
      trigger('update_sent', info.change, dbName);
    }
    if (info && info.direction === 'push') {
      // client receive changes
      trigger('update_received', info.change, dbName);
    }

    // check for errors
    // sync
    if (
      info.changes &&
      typeof info.changes.ok !== 'undefined' &&
      !info.changes.ok
    ) {
      dataFlowlogger.warn(
        '[%id] Sync error occurred'.replace('%id', _id),
        info
      );
    }
    if (info.push && typeof info.push.ok !== 'undefined' && !info.push.ok) {
      dataFlowlogger.warn(
        '[%id] Sync push error occurred'.replace('%id', _id),
        info
      );
    }
    if (info.pull && typeof info.pull.ok !== 'undefined' && !info.pull.ok) {
      dataFlowlogger.warn(
        '[%id] Sync pull error occurred'.replace('%id', _id),
        info
      );
    }
  }

  /**
   *
   */
  function _pouchBackOff(/*delay*/) {
    // TODO: manage online/offline state?
    // trigger('offline');
    return agentConfig.retryInterval;
  }

  /**
   * @param {dbAlias} dbName
   * @return {Promise}
   */
  function loadDump(_dbName) {
    if (_dbName !== agentConfig.dbNames.public) {
      return Promise.resolve();
    }

    logger.trace('Loading dump for ' + _dbName);
    return getDb(_dbName)
      .info()
      .then(function(info) {
        if (info.update_seq === 0) {
          //jshint ignore:line
          return _loadDump(_dbName)
            .then(function(res) {
              if (res) {
                var data = parseDump(res);
                opts.since = data.lastSeq;
                return getDb(_dbName).bulkDocs({
                  docs: data.docs,
                  new_edits: false
                }); //jshint ignore:line
              }
              return null;
            })
            .catch(function(req) {
              logger.warn(
                'Loading dump for ' +
                  _dbName +
                  ' failed with status ' +
                  req.status
              );
              return null;
            });
        } else {
          return Promise.resolve();
        }
      });
  }
} //-DataFlow

/**
 * @param {dbAlias} dbName
 * @return {Promise<string>}
 * @private
 */
function _loadDump(dbName) {
  if (Context.native) {
    return _requestDump('data/public.json').catch(_requestRemote);
  } else {
    return _requestRemote();
  }

  function _requestRemote() {
    return _requestDump(Context.serverUrl + 'dump/dump_version').then(function(
      hash
    ) {
      return _requestDump(Context.downloadUrl + 'dump/' + dbName + hash);
    });
  }
}

/**
 * @param {string} data
 * @return {{docs:Array<CouchDoc>,lastSeq:number}|{err:Error}}
 */
function parseDump(data) {
  var docs = [];
  var lastSeq = 0;
  try {
    var res = JSON.parse(data);
    docs = res.docs;
    lastSeq = res.seq;
  } catch (err) {
    return { err: err };
  }
  return { docs: docs, lastSeq: lastSeq };
}

/**
 * @ param {boolean} [forceUpdate] - pass true to make authorization even if we are already authorized
 * @return {Promise}
 */
function authorize(/*forceUpdate*/) {
  if (
    tools.now() >= agentConfig.cookieExpiresAt &&
    agentConfig.cookieExpiresAt >= 0
  ) {
    authorized = false;
    authorizePromise = null;
  }

  if (authorized /*&& !forceUpdate*/ || !agentConfig._useCookieAuth) {
    return Promise.resolve();
  }

  if (!authorizePromise) {
    authorizePromise = _authorize(agentConfig.cred.user, agentConfig.cred.pass)
      .then(function() {
        authorized = true;
        agentConfig.cookieExpiresAt = tools.now() + agentConfig.cookieLifetime;
        authorizePromise = null;
      })
      .catch(function(e) {
        authorizePromise = null;
        return Promise.reject(e);
      });
  }
  return authorizePromise;
}

/**
 * Authorize in remote database using credentials.
 *
 * Note: we use user database for auth, however cookie will be set for the whole domain
 *
 * @param {string} login
 * @param {string} pass
 * @return {Promise} resolves when auth granted
 */
function _authorize(login, pass) {
  logger.info('_authorize with ' + login);

  var url = _remoteDbURI(agentConfig.dbNames.userRW) + '/../_session';
  var data = 'name=' + login + '&password=' + pass;

  // return Promise.reject('test response fail');
  var defer = tools.generateDeferredPromise(); //jshint ignore:line

  var req = new CustomHttpRequest(); //jshint ignore:line
  req.withCredentials = agentConfig._useCookieAuth;
  req.open('POST', url, true);
  req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  req.onreadystatechange = function() {
    if (req.readyState === 4) {
      if (req.status > 0 && req.status < 400) {
        defer.resolve(req.response);
      } else {
        defer.reject(req); // TODO: find a way to prevent logging: [Q] Unhandled rejection reasons (should be empty): ["(no stack) [object XMLHttpRequest]"]
      }
    }
  };
  req.responseType = 'text';
  req.send(data);

  return defer.promise;
}

/**
 * @param {String} url
 * @returns {Promise<string>}
 * @private
 */
function _requestDump(url) {
  var defer = tools.generateDeferredPromise(); //jshint ignore:line

  var req = new XMLHttpRequest();
  req.withCredentials = false; // fix cors issue: https://irls.isd.dp.ua/redmine/issues/4869
  req.open('GET', url, true);
  trigger('dump_loading_start');

  req.onprogress = function(e) {
    if (e.lengthComputable) {
      logger.trace('loaded ', e.loaded, '  total', e.total);
      trigger('dump_loading_progress', parseInt((e.loaded / e.total) * 100));
    }
  };
  req.onreadystatechange = function() {
    if (req.readyState === 4) {
      if (req.status > 0 && req.status < 400) {
        defer.resolve(req.response);
      } else {
        defer.reject(req);
      }
    }
  };
  req.responseType = 'text';
  req.send();

  return defer.promise;
}

/**
 *
 */
function immediateSync(dbs) {
  dbs = dbs || [agentConfig.dbNames.public, agentConfig.dbNames.userRW];

  dbs.forEach(function(db) {
    if (syncPool[db]) {
      syncPool[db].immediateSync();
    }
  });
}

function checkDbInstance() {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest(); //jshint ignore:line
    req.open('GET', agentConfig.url, true);
    req.timeout = 3000;
    req.send();
    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        if (req.status === 200) {
          resolve(req.response);
          return;
        } else if (req.status > 400) {
          reject(req.response);
          return;
        }
      }
    };
    req.ontimeout = function(error) {
      reject(error);
    };
    req.onerror = function(error) {
      reject(error);
    };
  });
}

async function syncImmediate(dbs) {
  await checkDbInstance();
  dbs = dbs || [agentConfig.dbNames.userRW];

  const promises = dbs.map(db => {
    if (syncPool[db]) {
      return syncPool[db].promiseSync();
    }
    return Promise.resolve();
  });
  return Promise.all(promises);
}
if (process.client) {
  window.syncNow = immediateSync; //jshint ignore:line
}

//////////////////////////////////////////////////////
export default {
  init,
  initStorage,
  fillStorage,
  logout,
  setUserInfo: setUserInfo,
  dbNames: agentConfig.dbNames,

  syncNow: syncImmediate,

  db: {
    public: function() {
      return getDb(agentConfig.dbNames.public);
    },
    query: function() {
      return getDb(agentConfig.dbNames.query);
    },
    user: function() {
      return getDb(agentConfig.dbNames.user);
    },
    userRW: function() {
      return getDb(agentConfig.dbNames.userRW);
    },
    course: function(generator) {
      return function(courseId) {
        return getDb(generator(courseId));
      };
    }
  },

  initSync: initSync,
  initCopy: initSync,
  startSync: startSync,
  stopSync: stopSync,
  destroyLocalDB: destroyDb,

  // event emitter
  on: bind,
  off: unbind,

  // private section for testing purposes. don't use it on production
  _private: {
    __addSmSuffix: __addSmSuffix,
    __removeSmSuffix: __removeSmSuffix,

    _pouchProxyGet: _pouchProxyGet,
    _pouchProxyAllDocs: _pouchProxyAllDocs,
    _pouchProxyPut: _pouchProxyPut,
    _pouchProxyErrorDetails: _pouchProxyErrorDetails
  }
};
