import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PoolService.js');
import CustomErrorEnum from '@/enums/CustomErrorEnum';
const MAX_LEN = 30;

const queue = [];
const processingQueue = {};

let counter = 0;
let inProgress = false;
/**
 * add in queue  command by add function name and run execute this command with bind arguments
 * @param {function} command is function with bind arguments
 * @param {string} fnName is function 'push' or 'unshift'
 * @param {string} id is a uniq file name
 */
function add(command, fnName, id, cancelToken) {
  var index = queue.find(function(item) {
    return item.id === id;
  });
  if (index >= 0) {
    return queue[index].deferred.promise;
  }
  var deferred = createDefer();
  queue[fnName]({
    cancelToken: cancelToken || createCancelToken(),
    command: command,
    deferred: deferred,
    id: id
  });
  return deferred.promise;
}

function createDefer() {
  return (function() {
    var resolve;
    var reject;

    var promise = new Promise(function(res, rej) {
      // jshint ignore:line
      resolve = res;
      reject = rej;
    });

    return {
      isRejected: false,
      promise: promise,
      reject: reject,
      resolve: resolve
    };
  })();
}

/**
 * push in end of queue
 * @param  {function} command
 * @return {promise}
 */
function push(command, id, cancelToken) {
  return add(command, 'push', id, cancelToken);
}

/**
 * push in begin of queue
 * @param  {function} command
 * @return {promise}
 */
function unshift(command, id, cancelToken) {
  return add(command, 'unshift', id, cancelToken);
}

/**
 * check queue and compare max number operations if  params is valid  then run command
 */
function flush() {
  if (inProgress) {
    return;
  }
  inProgress = true;
  _fillQueue();
}

function _fillQueue() {
  var freePlaceInQueue = MAX_LEN - counter;
  for (var i = 0; i < freePlaceInQueue; i++) {
    _execute();
  }
}

function _execute() {
  if (queue.length === 0) {
    inProgress = false;
  }
  if (queue.length === 0 || counter >= MAX_LEN) {
    return;
  }
  var qItem = queue.shift();
  processingQueue[qItem.id] = qItem;
  counter += 1;
  qItem
    .command()
    .then(
      function(response) {
        if (!qItem.deferred.isRejected) {
          delete processingQueue[qItem.id];
          qItem.deferred.resolve(response);
        }
      },
      function(error) {
        delete processingQueue[qItem.id];
        qItem.deferred.reject(error);
      },
      qItem.deferred.notify
    )
    .then(function() {
      counter -= 1;
      return _fillQueue();
    })
    .catch(function(err) {
      logger.error('Error in Pool Service function flush ' + err);
      counter -= 1;
      return _fillQueue();
    });
}

/**
 * remove items from the queue by ids
 * @param {array} ids is array of uniq file names for remove
 */
function clearByIds(ids) {
  var idsMap = {};
  for (var i = 0; i < ids.length; i++) {
    idsMap[ids[i]] = i;
  }
  let j;
  for (j = queue.length - 1; j >= 0; j--) {
    const queueItem = queue[j];
    if (idsMap.hasOwnProperty(queueItem.id)) {
      const message = `Clear file ${queueItem.id} from download queue`;
      _cancel(queueItem, message);
      queue.splice(j, 1);
    }
  }
  const processingQueueKeys = Object.keys(processingQueue);
  for (j = processingQueueKeys.length - 1; j >= 0; j--) {
    const processingQueueKey = processingQueueKeys[j];
    const queueItem = processingQueue[processingQueueKey];
    if (idsMap.hasOwnProperty(queueItem.id)) {
      const message = `Clear file ${queueItem.id} from download processing queue`;
      _cancel(queueItem, message);
      delete processingQueue[processingQueueKey];
    }
  }
  inProgress = false;
}

function _cancel(queueItem, message = '') {
  queueItem.deferred.isRejected = true;
  queueItem.cancelToken.cancel();
  queueItem.deferred.reject({
    type: CustomErrorEnum.CLEAR_QUEUE,
    message
  });
}

/**
 * clear all queue
 */
function clear(excludeEntries) {
  const filteredEntries = excludeEntries.filter(
    excludeEntry => excludeEntry.length
  );
  const excludeEntriesRe = new RegExp(`(${filteredEntries.join('|')})`);
  const ids = queue.filter(function(queueItem) {
    return !excludeEntriesRe.test(queueItem.id);
  });

  Object.keys(processingQueue).forEach(function(id) {
    if (excludeEntriesRe.test(id)) {
      return;
    }
    ids.push(id);
  });
  clearByIds(ids);
}

function createCancelToken(msg) {
  return {
    id: new Date().getTime(),
    message: msg || '',
    isCanceled: false,
    cancel() {
      this.isCanceled = true;
    }
  };
}

export default {
  push,
  unshift,
  flush,
  clear,
  clearByIds,
  createCancelToken
};
