function isRtl(lang) {
  const rtlLangs = ['fa', 'ar'];
  return rtlLangs.includes(lang);
}

function getDirection(lang) {
  return isRtl(lang) ? 'rtl' : 'ltr';
}

module.exports = {
  isRtl,
  getDirection
};
