<template>
  <div class="menu-sections-list">
    <div
      v-for="menuItem in menuSections"
      :key="menuItem.id"
      :test-id="menuItem.autoTestId"
      class="menu-section"
    >
      <BaseButton
        class="menu-item"
        elevation="0"
        block
        text
        :color="menuItem.color"
        :disabled="menuItem.disabled"
        @click="selectMenuItem(menuItem)"
      >
        <span class="menu-item-icon">
          <img
            v-if="menuItem.imgSrc"
            class="user-pic"
            :src="menuItem.imgSrc"
            alt="user photo"
          />
          <BaseSpriteIcon
            v-else
            :icon-name="menuItem.icon"
            :custom-class="menuItem.customClass"
          />
        </span>
        <span class="menu-item-text">
          <span>{{ menuItem.label }}</span>
          <span v-if="menuItem.description" class="menu-item-description">{{
            menuItem.description
          }}</span>
        </span>
        <span v-if="menuItem.arrow" class="menu-item-arrow">
          <BaseSpriteIcon icon-name="ico-arrow-front-long" />
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import SectionsMenuEventEnum from '@/enums/SectionsMenuEventEnum';

export default {
  name: 'SectionsMenu',
  components: { BaseButton, BaseSpriteIcon },
  props: {
    menuSections: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectMenuItem(menuItem) {
      menuItem.handler();
      this.$emit('sectionsMenuEvent', {
        type: SectionsMenuEventEnum.ITEM_SELECTED,
        selectedItem: menuItem
      });
    }
  }
};
</script>

<style scoped lang="less" src="./SectionsMenu.less"></style>
