import MaterialsUtils from '@/services/utils/MaterialsUtils';
import RestService from '@/services/RestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('VocabularyAssessmentService.js');
import AssessmentSettingsFactory from '@/classes/factories/Assessment/AssessmentSettingsFactory';

const maxPlayerWidth = '710';

function getImage(imageId) {
  return MaterialsUtils.getTestFileSource(imageId);
}

function getAudio(audioId) {
  return MaterialsUtils.getTestFileSource(audioId);
}

async function getQuestions(payload) {
  let res;
  try {
    res = await RestService.restRequest(
      'get',
      'Vocabulary',
      'getQuestions',
      payload
    );
  } catch (err) {
    logger.error(err.message);
    return [];
  }
  if (!res.data || !res.data.data) {
    res = { data: { data: [] } };
  }
  return res.data.data;
}

function getSettings(id) {
  const assessmentSettingsBuilder = AssessmentSettingsFactory.createSettingsBuilder();
  const assessmentSettings = assessmentSettingsBuilder
    .setTestId(id)
    .setMaxWidth(maxPlayerWidth)
    .setIsShownAssessmentProgress(true)
    .build();
  return assessmentSettings;
}

async function getBncTestsByLevel({ level, amount }) {
  const defaultResponse = [];
  try {
    const response = await RestService.restRequest(
      'get',
      'ManageTests',
      'getBncTestsByLevel',
      {
        level,
        amount
      }
    );
    return response.data || defaultResponse;
  } catch (error) {
    logger.error(`getBncTestsByLevel failed with error: ${error}`);
    return defaultResponse;
  }
}

async function getAllUserResults() {
  const defaultResponse = [];
  try {
    const response = await RestService.restRequest(
      'get',
      'AssessmentResults',
      'getAllUserResults'
    );
    return response.data || defaultResponse;
  } catch (error) {
    const message = error?.message || '';
    const isNetwokError = message.includes('Network Error');
    if (isNetwokError) {
      logger.warn(`Network error: ${error}`);
      return defaultResponse;
    }
    logger.error(`getAllUserResults failed with error: ${error}`);
    return defaultResponse;
  }
}

async function getLatestUserResult() {
  const defaultResponse = {};
  try {
    const response = await RestService.restRequest(
      'get',
      'AssessmentResults',
      'getLatestUserResult'
    );
    return response.data || defaultResponse;
  } catch (error) {
    logger.error(`getLatestUserResult failed with error: ${error}`);
    return defaultResponse;
  }
}

async function fetchIsDemographicDataSaved() {
  const defaultResponse = null;
  try {
    const response = await RestService.restRequest(
      'get',
      'AssessmentResults',
      'fetchIsDemographicDataSaved'
    );
    return response.data || defaultResponse;
  } catch (error) {
    logger.error(`fetchIsDemographicDataSaved failed with error: ${error}`);
    return defaultResponse;
  }
}

async function saveDemographicData(userData) {
  const defaultResponse = {};
  try {
    const response = await RestService.restRequest(
      'post',
      'AssessmentResults',
      'saveDemographicData',
      userData
    );
    return response.data || defaultResponse;
  } catch (error) {
    logger.error(`saveDemographicData failed with error: ${error}`);
    return defaultResponse;
  }
}

async function getUserCountryCode() {
  const defaultResponse = {};
  try {
    const response = await RestService.restRequest(
      'get',
      'AssessmentResults',
      'getUserCountryCode'
    );
    return response.data || defaultResponse;
  } catch (error) {
    logger.error(`getUserCountryCode failed with error: ${error}`);
    return defaultResponse;
  }
}

async function saveAssessmentResult(assessmentResult) {
  try {
    await RestService.restRequest(
      'post',
      'AssessmentResults',
      'saveAssessmentResult',
      assessmentResult
    );
  } catch (error) {
    logger.error(`saveAssessmentResult failed with error: ${error}`);
  }
}

export default {
  getImage,
  getAudio,
  getQuestions,
  getSettings,
  getBncTestsByLevel,
  getAllUserResults,
  getLatestUserResult,
  fetchIsDemographicDataSaved,
  saveDemographicData,
  getUserCountryCode,
  saveAssessmentResult
};
