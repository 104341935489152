<template>
  <div
    class="search-inside-book-wrapper"
    :class="{ 'is-book-search-visible': isBookSearchShown }"
    @click="toggleSearchInsideBook"
  >
    <BaseButton
      elevation="0"
      fab
      class="mini-navigate-icon"
      outlined
      :title="$t('Accessibility.header.search.tooltip')"
      :aria-label="$t('Accessibility.header.search')"
    >
      <BaseSpriteIcon
        icon-name="ico-book-search"
        :color="isBookSearchShown ? 'white' : 'black'"
      />
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

export default {
  name: 'SearchInsideBookControl',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  computed: {
    isBookSearchShown() {
      return this.$store.getters['ContextStore/getIsBookSearchShown'];
    },
    isFromSearchShown() {
      return this.$store.getters['ContextStore/getIsFromSearchShown'];
    },
    publicationType() {
      return this.$store.getters['OpenParameterStore/getPublicationType'];
    },
    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    }
  },
  watch: {
    isBookSearchShown() {
      if (!this.isBookSearchShown) {
        this.$_destroySearchNavigation();
      }
    }
  },
  mounted() {
    if (this.isFromSearchShown) {
      this.$store.commit('ContextStore/setIsBookSearchShown', true);
    }
  },
  methods: {
    toggleSearchInsideBook() {
      const isShown = !this.isBookSearchShown;
      this.$store.commit('ContextStore/setIsBookSearchShown', isShown);
      if (isShown) {
        this.$_createBookNavigation();
      }
    },
    $_createBookNavigation() {
      const bookId = this.$store.getters['OpenParameterStore/getPublicationId'];
      this.$store.commit('BookStore/setSearchNavigation', {
        bookId
      });
      this.$store.dispatch('SwContextStore/setOpenedPublicationId', bookId);
    },
    $_destroySearchNavigation() {
      this.$store.commit('BookStore/resetSearchNavigation');
      this.$store.commit('ContextStore/setIsFromSearchShown', false);
    }
  }
};
</script>

<style lang="less" src="./SearchInsideBookControl.less"></style>
