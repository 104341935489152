import get from 'lodash/get';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LibraryProgressDao.js');

import DB from '@/services/Agent/dao/DB';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

const errorCodes = {
  NOT_FOUND: 404
};
const TYPE = 'libraryProgress';

class LibraryProgressData {
  constructor(buildData) {
    this.userId = buildData.userId;
    this.createdAt = buildData.createdAt;
    this.progressSummary = buildData.progressSummary;
    this.type = TYPE;
  }

  updatePublicationProgress(
    publicationId,
    readPercent,
    totalWordsPerBook,
    readWords
  ) {
    if (!this.progressSummary.hasOwnProperty(publicationId)) {
      this.progressSummary[publicationId] = new BookSummaryItem({
        readPercent: 0,
        completed: false,
        totalWordsPerBook: 0,
        readWords: 0,
        startAt: Date.now()
      });
    }
    const bookSummaryItem = this.progressSummary[publicationId];
    bookSummaryItem.updateReadPercent(
      readPercent,
      totalWordsPerBook,
      readWords
    );
  }
}

class LibraryProgressDataBuilder {
  setUserId(val) {
    this.userId = val;
    return this;
  }

  setCreatedAt(val) {
    this.createdAt = val;
    return this;
  }

  setProgressSummary(val) {
    this.progressSummary = val;
    return this;
  }

  build() {
    return new LibraryProgressData(this);
  }
}

class BookSummaryItem {
  constructor(rawItem) {
    const now = Date.now();
    this.readPercent = 0;
    this.completed = this._calcCompleted(
      rawItem.readPercent,
      rawItem.completed
    );
    this.totalWordsPerBook = rawItem.totalWordsPerBook;
    this.readWords = rawItem.readWords;
    this.startAt = rawItem.startAt || now;
    this.endAt = this.completed ? rawItem.endAt : null;
    this._setReadPercent(rawItem.readPercent);
  }

  updateReadPercent(readPercent, totalWordsPerBook, readWords) {
    this._setReadPercent(readPercent);
    this.completed = this._calcCompleted(readPercent, this.completed);
    this.totalWordsPerBook = totalWordsPerBook;
    this.readWords = readWords;
    this.endAt = this.completed ? Date.now() : null;
  }

  _setReadPercent(readPercent) {
    this.readPercent = Math.min(readPercent, 100);
  }

  _calcCompleted(readPercent, completed) {
    return completed
      ? completed
      : readPercent >= AppConstantsUtil.COMPLETED_PERCENT_BORDER;
  }
}

function updateLibraryProgress(userId, updateData) {
  const _id = _getLibProgId(userId);
  return _updateDocument(_id, updateData);
}

function _getLibProgId(userId) {
  return DB.id.libraryProgress(userId);
}

async function _updateDocument(id, data) {
  const doc = await _findDocument(id);
  if (!doc) {
    return _putDocument(id, data);
  } else {
    return _putDocument(id, data, doc._rev);
  }
}

function _putDocument(id, data, revision) {
  Object.assign(data, { _id: id, _rev: revision });
  return DB.userRW().put(data);
}

async function getLibraryProgress(userId) {
  const _id = _getLibProgId(userId);
  const rawDoc = await _findDocument(_id);

  const progressUserId = get(rawDoc, 'userId', userId);
  const createdAt = get(rawDoc, 'createdAt', new Date().getTime());
  const progressSummary = _createProgressSummary(
    get(rawDoc, 'progressSummary', [])
  );
  const builder = new LibraryProgressDataBuilder();
  return builder
    .setUserId(progressUserId)
    .setCreatedAt(createdAt)
    .setProgressSummary(progressSummary)
    .build();
}

function _createProgressSummary(rawProgressSummary) {
  return Object.keys(rawProgressSummary).reduce((summaryMap, bookId) => {
    const rawItem = rawProgressSummary[bookId];
    summaryMap[bookId] = new BookSummaryItem(rawItem);
    return summaryMap;
  }, {});
}

function _findDocument(id) {
  return DB.userRW()
    .get(id)
    .catch(err => {
      if (err.status === errorCodes.NOT_FOUND) {
        return null;
      }
      logger.error(err);
    });
}

export default {
  updateLibraryProgress,
  getLibraryProgress
};
