import CompilationsService from '@/services/CompilationsService';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import Utils from '@/services/utils/Utils';

class CompilationTOCItem {
  constructor(section = {}) {
    this.id = section.paraId;
    this.text = section.title;
  }
}

class CompilationInfoView {
  constructor(compilation, selections = [], content = []) {
    this._id = compilation._id; // TODO: check if this is needed
    this.id = compilation.id;
    this.title = compilation.title;
    this.audio = compilation.audio;
    this.description = compilation.description;
    this.modifiedAtLabel = CompilationsService.convertModifiedAtToLocaleString(
      compilation.modifiedAt
    );
    this.default = compilation.default;
    this.readingTime = Utils.estimateReadingTime(compilation.wordsCount);
    this.sections = selections.reduce((sections, selection, index) => {
      const contentItem = content[index] || {};
      if (selection.type === CompilationSelectionTypes.SECTION) {
        sections.push({ ...selection, paraId: contentItem.id || '' });
      }
      return sections;
    }, []);
    const items = compilation.items.filter(item => !!item);
    this.selectionsCount = selections.length
      ? items.length - this.sections.length
      : CompilationsService.getSelectionsCount(items);
    this.coverFileName = compilation.coverFileName;
    this.toc = this.sections.map(section => new CompilationTOCItem(section));
  }
}

function create(compilation, selections, content) {
  return new CompilationInfoView(compilation, selections, content);
}

export default {
  create
};
