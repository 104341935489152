<template>
  <BaseButton
    elevation="0"
    fab
    class="mini-navigate-icon"
    outlined
    :aria-label="$t('Accessibility.toAbout')"
    @click="toAbout"
  >
    <BaseSpriteIcon icon-name="ico-about-round"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToAboutIconControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toAbout() {
      if (this.isBlogApp) {
        const clientReaderUrl = this.$store.getters[
          'ContextStore/getClientReaderUrl'
        ];
        this.$store.dispatch('ContextStore/openUrl', {
          url: `${clientReaderUrl}library`
        });
        return;
      }
      this.$router.push({ name: AppStateEnum.MANAGE_ABOUT });
    }
  }
};
</script>
