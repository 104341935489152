export default {
  TO_LOGIN_BY_EMAIL: 'toLoginByEmail',
  FORGOT_PASSWORD_BACK: 'forgotPasswordBack',
  TO_FORGOT_PASSWORD: 'toForgotPassword',
  TO_FORGOT_PASSWORD_DONE: 'toForgotPasswordDone',
  TO_REGISTRATION: 'toRegistration',
  REGISTRATION_DONE: 'registrationDone',

  LOGIN_BY_GMAIL: 'loginByGmail',
  LOGIN_BY_FACEBOOK: 'loginByFacebook',
  LOGIN_BY_APPLE: 'loginByApple',
  LOGIN_BY_EMAIL: 'loginByEmail',
  LOGIN_BY_EMAIL_ERROR: 'loginByEmailError',
  REGISTRATION: 'registration',
  FORGOT_PASSWORD: 'forgotPassword',
  TO_LOGIN: 'toLogin',
  LOGGED_IN: 'loggedIn'
};
