import shuffle from 'lodash/shuffle';
import Utils from '@/services/utils/Utils';

class TestAssessmentView {
  constructor(test) {
    this.id = test.id || '';
    this.name = test.name || '';
    this.testQuestions = test.testQuestions.map(question =>
      createTestQuestionView(question)
    );
    this.testQuestionsCount = test.testQuestions.length;
    this.correctAnswersCount = 0;
    this.incorrectAnswersCount = 0;
  }

  increaseCorrectAnswersCount() {
    this.correctAnswersCount++;
  }

  increaseIncorrectAnswersCount() {
    this.incorrectAnswersCount++;
  }

  setNewTestQuestions(questions) {
    const preparedQuestions = questions.map(question =>
      createTestQuestionView(question)
    );
    this.testQuestions = preparedQuestions;
    this.testQuestionsCount = this.testQuestions.length;
  }

  clearAnswersCount() {
    this.correctAnswersCount = 0;
    this.incorrectAnswersCount = 0;
  }
}

class TestQuestionView {
  constructor(testQuestion) {
    const correctAnswerIndex = 0;
    const answer = testQuestion.answer;
    const answers = [answer, ...Object.values(testQuestion.incorrectAnswers)];
    this.question = testQuestion.question;
    this.id = testQuestion.id || Utils.uuid();
    this.answers = shuffle(
      testQuestion.answers || _prepareAnswers(answers, correctAnswerIndex)
    );
    this.audio = testQuestion.audio;
    this.image = testQuestion.image;
  }
}

function _prepareAnswers(answers, correctAnswerIndex) {
  return answers.map((answer, index) => {
    let correct = index === correctAnswerIndex;
    return {
      text: answer,
      correct
    };
  });
}

class TestAssessmentViewBuilder {
  setTestQuestions(testQuestions) {
    this.testQuestions = testQuestions;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  build() {
    if (!this.testQuestions) {
      throw new Error('Questions must be set');
    }
    return new TestAssessmentView(this);
  }
}

function createTestQuestionView(question) {
  return new TestQuestionView(question);
}

function createTestAssessmentViewBuilder() {
  return new TestAssessmentViewBuilder();
}

export default {
  createTestAssessmentViewBuilder,
  createTestQuestionView
};
