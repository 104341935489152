<template>
  <Popup
    :close-on-content-click="false"
    primary-label="Delete"
    secondary-label="Cancel"
    content-class="delete-tag-popup"
    :primary-footer-handler="deleteTag"
    :secondary-footer-handler="close"
    :header-divider="false"
    :footer-divider="false"
    :content-image="contentImage"
    :content-title="contentTitle"
    :content-message="contentMessage"
    @popupEvent="close"
  >
    <template #additional-content>
      <span class="remark">{{ $t('DeleteCategoryPopup.content.remark') }}</span>
    </template>
    <template #custom-footer></template>
  </Popup>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CreateAnnotationPopup.vue');

import Popup from '@/components/base/Popup/Popup.vue';

import images from '@/assets/images';

import PopupNamesEnum from '@/enums/PopupNamesEnum';

export default {
  name: PopupNamesEnum.DELETE_TAG,
  components: { Popup },
  data() {
    return {
      contentImage: images.picGarbage
    };
  },
  computed: {
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.DELETE_TAG
      );
    },
    contentTitle() {
      const categoryName =
        this.popupContext.category.name || this.popupContext.category.label;
      return this.$t('DeleteCategoryPopup.content.title', {
        categoryName
      });
    },
    getDefaultCategory() {
      return this.$store.getters['CategoriesStore/getDefaultCategory'];
    },
    contentMessage() {
      const defaultCategoryName = this.getDefaultCategory.name;
      return this.$t('DeleteCategoryPopup.content.message', {
        defaultCategoryName
      });
    }
  },
  methods: {
    close() {
      if (this.popupContext.parentPopup) {
        this.$store.dispatch(
          'ManagePopupStore/unhidePopup',
          this.popupContext.parentPopup
        );
      }

      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.DELETE_TAG
      });
    },
    async changeAnnotationsCategoryToDefault() {
      await this.$store.dispatch(`AnnotationsStore/changeCategoryToDefault`, {
        categoryId: this.popupContext.category.id
      });
    },
    async deleteCategory() {
      await this.$store.dispatch('CategoriesStore/deleteCategory', {
        categoryId: this.popupContext.category.id
      });
    },
    async deleteTag() {
      try {
        this.changeAnnotationsCategoryToDefault();
        this.deleteCategory();
        this.close();
      } catch (err) {
        this.$_errorHandler(err);
      }
    },
    $_errorHandler(error) {
      logger.warn(`Error in DeleteTagPopup: ${error.stack}`);
      this.close();
    }
  }
};
</script>

<style lang="less" scopped src="./DeleteTagPopup.less" />
