import CampaignsService from '@/services/CampaignsService';
import CampaignsFactory from '@/classes/factories/CampaignsFactory';

const initState = () => ({
  campaignsListByUserId: {},
  usersListHasCampaigns: [],
  affiliatesFilterText: ''
});

const storeGetters = {
  getCampaignsListByUserId: state => userId => {
    return state.campaignsListByUserId[userId] || [];
  },
  getUsersListHasCampaigns(state) {
    return state.usersListHasCampaigns;
  },
  getAffiliatesFilterText(state) {
    return state.affiliatesFilterText;
  }
};

const actions = {
  async persistCampaign(_, campaign) {
    return await CampaignsService.persistCampaign(campaign);
  },

  async getCampaignsListByUserId({ commit }, userId) {
    const response = await CampaignsService.getCampaignsListByUserId(userId);
    if (response) {
      const campaigns = response.map(campaign =>
        CampaignsFactory.createCampaign(campaign)
      );
      commit('setCampaignsListByUserId', { userId, campaigns });
    }
  },

  async updateCampaignsWithGoogleAnalyticsData() {
    return await CampaignsService.updateCampaignsWithGoogleAnalyticsData();
  },

  async getUsersListHasCampaigns({ commit }) {
    const response = await CampaignsService.getUsersListHasCampaigns();
    if (response) {
      const usersHasCampaigns = response.map(campaign =>
        CampaignsFactory.createUserHasCampaign(campaign)
      );
      commit('setUsersListHasCampaigns', usersHasCampaigns);
    }
  }
};

const mutations = {
  setCampaignsListByUserId(state, payload) {
    const { userId, campaigns } = payload;
    state.campaignsListByUserId = Object.assign(
      {},
      state.campaignsListByUserId,
      { [userId]: campaigns }
    );
  },

  setUsersListHasCampaigns(state, usersListHasCampaigns) {
    state.usersListHasCampaigns = usersListHasCampaigns;
  },

  setAffiliatesFilterText(state, affiliatesFilterText) {
    state.affiliatesFilterText = affiliatesFilterText;
  }
};

export default {
  name: 'CampaignsStore',
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
