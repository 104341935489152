import PublicationLocator from './publication-locator.mjs';

/**
 * Locator attached to the start of Publication
 *
 * @constructor
 * @extends {PublicationLocator}
 */
class PublicationStartLocator extends PublicationLocator {
  constructor() {
    super();
  }
  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    return _comparePublicationStartWith(locator);
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareBasisTo(locator) {
    return _comparePublicationStartWith(locator);
  }
}

/**
 *
 * @param {PublicationLocator} locator
 * @returns {number}
 * @private
 */
function _comparePublicationStartWith(locator) {
  return locator.constructor === PublicationStartLocator ? 0 : -1;
}

export default PublicationStartLocator;
