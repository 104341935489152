<template>
  <div
    class="context-menu essay-popup"
    :class="{ completed: essay.isCompleted }"
  >
    <v-dialog
      ref="contextDialog"
      v-model="dialog"
      :content-class="'reader-context-menu'"
      persistent
    >
      <div class="essay-popup-header">
        <span class="essay-popup-header-dummy" />
        <span class="essay-popup-header-title">{{ $t('Essay.label') }}</span>
        <span class="essay-popup-header-close" @click="closePopup()">
          <BaseSpriteIcon icon-name="ico-close" />
        </span>
      </div>
      <v-card>
        <div>
          <span>{{ essay.topic }}</span>
        </div>
        <div>
          <span>{{ essay.comment }}</span>
        </div>
        <v-textarea
          v-model.trim="essayText"
          auto-grow
          :disabled="essay.isCompleted"
          :class="{ completed: essay.isCompleted }"
          @input="onEssayTextInput"
        />
        <span class="words">{{ symbolsNumLabel }}</span>
        <span v-if="essay.isCompleted">{{ completedAt }}</span>
      </v-card>
      <div v-if="!isSubmittingMode" class="essay-popup-footer">
        <div v-if="!essay.isCompleted" class="two-buttons">
          <v-btn @click="saveForLater()">
            {{
              $t('PresentPublicationEssayTaskReaderTemplate.saveForLater.label')
            }}
          </v-btn>
          <v-btn :disabled="!allowSubmit" @click="saveEssay()">
            {{
              $t('PresentPublicationEssayTaskReaderTemplate.submitEssay.label')
            }}
          </v-btn>
        </div>
        <div v-if="essay.isCompleted" class="one-button" @click="closePopup()">
          <v-btn>
            {{ $t('Close') }}
          </v-btn>
        </div>
      </div>
      <div v-if="isSubmittingMode" class="essay-popup-footer">
        <span class="footer-message">
          {{
            $t('PresentPublicationEssayTaskReaderTemplate.submitTemplate.text')
          }}
        </span>
        <div class="two-buttons">
          <v-btn @click="cancelSubmit()">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn @click="submit()">
            {{
              $t('PresentPublicationEssayTaskReaderTemplate.submitEssay.label')
            }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

const DEBOUNCE_TIME = 200;

export default {
  name: 'EssayPopup',
  components: { BaseSpriteIcon },
  props: {
    isShown: Boolean,
    essay: Object,
    essayWordsCount: Number,
    getCompletedAtLabelFn: Function,
    getSymbolsNumLabelFn: Function
  },
  data() {
    const debouncedInput = debounce(
      this.$_cutEssayWordsIfNeeded,
      DEBOUNCE_TIME
    );
    return {
      dialog: this.isShown,
      maxEssayLength: this.essay.wordsLimit * 10,
      essayText: '',
      isSubmittingMode: false,
      debouncedInput,
      allowSubmit: false
    };
  },
  computed: {
    completedAt() {
      return this.getCompletedAtLabelFn(this.essay.completedAt);
    },
    symbolsNumLabel() {
      return this.getSymbolsNumLabelFn(this.essayWordsCount);
    }
  },
  watch: {
    essayWordsCount() {
      this.allowSubmit = this.essayWordsCount >= this.essay.wordsLimit;
    }
  },
  mounted() {
    this.essayText = this.essay.text;
  },
  methods: {
    onEssayTextInput() {
      this.debouncedInput();
    },
    $_cutEssayWordsIfNeeded() {
      this.$emit('essayPopupEvent', {
        text: this.essayText,
        isCompleted: false,
        shouldRecalculateSymbols: true
      });
      if (this.essayWordsCount > this.maxEssayLength) {
        this.essayText = this.$_removeExtraWords(
          this.essayText,
          this.maxEssayLength
        );
      }
    },
    saveForLater() {
      this.$emit('essayPopupEvent', {
        text: this.essayText,
        isCompleted: false
      });
    },
    cancelSubmit() {
      this.isSubmittingMode = false;
    },
    submit() {
      this.$emit('essayPopupEvent', {
        text: this.essayText,
        isCompleted: true
      });
      this.isSubmittingMode = false;
    },
    saveEssay() {
      if (!this.allowSubmit) {
        return;
      }
      this.isSubmittingMode = true;
    },
    closePopup() {
      this.$emit('essayPopupEvent', { isCompleted: false });
    },
    $_removeExtraWords(text, maxLength) {
      return text
        .trim()
        .split(' ')
        .slice(0, maxLength)
        .join(' ');
    },
    $_wordsCount(text) {
      let trimmedText = text.trim();
      if (trimmedText.length === 0) {
        return 0;
      }
      return trimmedText.split(' ').length;
    }
  }
};
</script>

<style lang="less" src="./EssayPopup.less"></style>
