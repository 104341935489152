<template>
  <v-dialog
    v-model="isShown"
    persistent
    :fullscreen="narrow"
    :scrollable="true"
    :width="500"
  >
    <v-card class="popup-content" :tile="narrow">
      <v-card-title class="popup-header" primary-title>
        <span class="back-wrapper" />
        <span class="popup-header-wrapper">
          {{ $t('Popup.error.title.label') }}
        </span>
        <span class="close-wrapper">
          <button class="button-close" @click="closeAndGoToLibrary()">
            <BaseSpriteIcon icon-name="ico-close" />
          </button>
        </span>
      </v-card-title>
      <v-card-text>
        <div class="message-content">
          <div class="pic-wrapper">
            <BaseSpriteIcon
              icon-name="pic-cup-of-coffe-with-heart"
              view-box="0 0 426 426"
            />
          </div>
          <div>
            {{ $t('DownloadIncorrect.message.remove') }}
          </div>
          <div>
            {{ $t('DownloadIncorrect.message.reopen') }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="popup-footer">
        <v-btn color="primary" dark :tile="narrow" @click="closeAndGoToLibrary">
          {{ buttonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: PopupNamesEnum.BOOK_DOWNLOADED_INCORRECT,
  components: {
    BaseSpriteIcon
  },
  data() {
    return {
      isShown: true,
      buttonLabel: this.$t('Popup.button.ok.label')
    };
  },
  computed: {
    narrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    }
  },
  methods: {
    closeAndGoToLibrary() {
      this.isShown = false;
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.BOOK_NOT_DOWNLOADED_POPUP }
      });
      // go back
      try {
        this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
      } catch (err) {
        const text = `BookNotDownloadedPopup: error occurred - ${err}`;
        this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import 'BookDownloadedIncorrect.less';
</style>
