import CustomErrorEnum from '@/enums/CustomErrorEnum';

import AssetsManager from '@/services/AssetsManager/AssetsManager';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookDownload.js');

async function downloadBookById(bookId, store) {
  const isViewMode = store.getters['ContextStore/isViewMode'];
  if (isViewMode) {
    return;
  }
  const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
    bookId
  );
  const book = store.getters['LibraryStore/getPublicationById'](bookId);
  const needDownload =
    !trackingItemView.outdatedData && !trackingItemView.isContentDownloaded;
  if (!needDownload) {
    const needActualize =
      book &&
      trackingItemView &&
      (book.isContentDownloaded !== trackingItemView.isContentDownloaded ||
        book.isAudioDownloaded !== trackingItemView.isAudioDownloaded);
    if (needActualize) {
      await store.dispatch('LibraryStore/updatePublicationSource', { bookId });
    }
    return;
  }
  const assetsNames = await AssetsManager.getAssetNameContentList(book.audio);
  await _downloadAssets(bookId, assetsNames);
  await store.dispatch('LibraryStore/updatePublicationSource', { bookId });
}

async function _downloadAsset(bookId, assetName, store) {
  try {
    if (!(await AssetsManager.isAssetDownloaded(bookId, assetName))) {
      const response = await AssetsManager.downloadAsset(bookId, assetName);
      return response;
    }
  } catch (error) {
    if (error && error.type === CustomErrorEnum.INVALID_ASSET_VERSION_FILE) {
      logger.fatal(error);
      AssetsManager.cancelDownload(bookId);
      let text = 'Oops, something went wrong with downloading content';
      store.dispatch('ManagePopupStore/openErrorToaster', { text });
      return;
    }
    throw error;
  }
}

async function _downloadAssets(bookId, assetsNames, store) {
  return Promise.all(
    assetsNames.map(assetName => _downloadAsset(bookId, assetName, store))
  );
}

function getDefaultBooks(store) {
  return store.getters['ContextStore/getLibraryLanguages'].reduce(
    (result, lang) => {
      const foundBooks = store.getters[
        'LibraryStore/getDefaultPublicationsByLanguage'
      ](lang);
      return foundBooks && foundBooks.length
        ? [...result, ...foundBooks]
        : result;
    },
    []
  );
}
function getDefaultBooksIds(store) {
  return getDefaultBooks(store).map(book => book.id);
}

async function downloadDefaultBooks(store) {
  const isReaderApp = store.getters['ContextStore/isReaderApp'];
  if (!isReaderApp) {
    return;
  }

  const defaultBooks = getDefaultBooksIds(store);
  if (!defaultBooks.length) {
    return;
  }
  try {
    await Promise.all(defaultBooks.map(bookId => downloadBookById(bookId)));
  } catch (err) {
    logger.error(err);
  }
}

export {
  getDefaultBooks,
  getDefaultBooksIds,
  downloadDefaultBooks,
  downloadBookById
};
