'use strict';
var languageUtils;
import('./../contentAnalyzer/languageUtils.mjs').then(
  module => (languageUtils = module.default)
);
function stemming(token, lang) {
  return languageUtils.stemming(token, lang);
}

module.exports = {
  stemming: stemming
};
