<template>
  <div
    :class="className"
    class="recent-book-control"
    @click="openRecentBookPopup"
  >
    <slot name="recent-book"></slot>
  </div>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';

export default {
  name: 'RecentBookControl',
  components: {},
  data() {
    return {
      className: `control-id-${this._uid}`
    };
  },
  methods: {
    openRecentBookPopup() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.RECENT_BOOKS_WRAPPER,
        popupContext: {
          controlClassName: this.className
        }
      });
    }
  }
};
</script>

<style lang="less" src="./RecentBookControl.less"></style>
