<template>
  <v-menu v-bind="computedAttributes">
    <slot name="content"></slot>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseMenu',
  components: {},
  data() {
    return {
      defaultAttributes: {
        ['content-class']: '',
        ['offset-y']: true
      }
    };
  },
  computed: {
    computedAttributes() {
      return {
        ...this.defaultAttributes,
        ...this.$attrs
      };
    }
  }
};
</script>

<style lang="less" src="./BaseMenu.less"></style>
