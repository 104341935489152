import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import MaterialTypes from '@shared/enums/MaterialTypes';
import BookUtils from '@shared/publication/book-utils.mjs';
import AnnotationDomUtils from '@shared/publication/annotation/AnnotationDomUtils';
import Annotation from '@/services/utils/Annotation';
import BookmarksRequestService from '@/services/BookmarksRequestService';

const initState = () => ({
  bookmarks: [],
  bookmarkId: ''
});

const storeGetters = {
  getBookBookmarks: state => ({ bookId }) => {
    const publicationBookmarks = state.bookmarks[bookId];
    if (!publicationBookmarks) {
      return [];
    }
    const flattenBookmarks = [].concat(...Object.values(publicationBookmarks));
    return flattenBookmarks || [];
  },
  getBookmarksByBlockId: state => ({ bookId, blockId, paraId }) => {
    const bookmarks = state.bookmarks[bookId] || {};
    const bookmarkBlockId =
      !blockId && paraId ? BookUtils.findBlockIdByParaId(paraId) : blockId;

    const paraBookmarks = bookmarks[bookmarkBlockId] || bookmarks[paraId];
    if (!paraBookmarks?.length) {
      return [];
    }
    return paraBookmarks;
  },
  getBookmarkId: state => {
    return state.bookmarkId;
  }
};

function _buildBookmark({ bookmark = {}, locator, paraNum = '' }) {
  const blockId =
    bookmark.blockId ||
    BookUtils.findBlockIdByParaId(locator?.startLocator?.prefixedParagraphId);

  if (!blockId) {
    throw new Error(
      `No blockId. Empty bookmark blockId: ${bookmark.blockId}, or not locator: ${locator}`
    );
  }

  const bookmarkBuilder = MaterialsFactory.createBuilderByType(
    MaterialTypes.BOOKMARK
  );
  const buildedBookmark = bookmarkBuilder
    .setId(bookmark.id || null)
    .setBlockId(blockId)
    .setLocator(locator)
    .setParaNum(paraNum)
    .build();

  return buildedBookmark;
}

const actions = {
  initBookmarks({ commit }, { materials, bookId }) {
    commit('clearStore');
    let { bookmarks } = materials;
    const bookmarksByParaId = Annotation.initMaterialsByBlockId(
      bookmarks || []
    );
    Object.entries(bookmarksByParaId).forEach(([blockId, blockBookmarks]) => {
      commit('setBookmarks', { blockId, blockBookmarks, bookId });
    });
  },
  processAddBookmarkRequest(_, { bookmark, reqParams }) {
    return BookmarksRequestService.processAddBookmarkRequest({
      bookmark,
      reqParams
    });
  },
  processDeleteBookmarkRequest(_, { bookmark, reqParams }) {
    return BookmarksRequestService.processDeleteBookmarkRequest({
      bookmark,
      reqParams
    });
  },
  async addBookmarkByLocator({ commit, dispatch, rootGetters }, { locator }) {
    const { paraNum } = rootGetters['BookStore/getParaMetaByLocator'](locator);
    const bookmark = _buildBookmark({
      locator,
      paraNum
    });

    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    const bookmarkRequestResult = await dispatch('processAddBookmarkRequest', {
      bookmark,
      reqParams
    });
    const isBookmarkCreated = bookmarkRequestResult?.statusText === 'OK';
    if (isBookmarkCreated) {
      commit('addBookmark', {
        bookmark,
        bookId
      });
    }
    return bookmarkRequestResult;
  },
  async deleteBookmark({ commit, dispatch, rootGetters }, { bookmark }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;

    const bookmarkRequestResult = await dispatch(
      'processDeleteBookmarkRequest',
      {
        bookmark,
        reqParams
      }
    );
    const isBookmarkDeleted = bookmarkRequestResult?.statusText === 'OK';
    if (isBookmarkDeleted) {
      commit('deleteBookmarkProcess', { bookmark, bookId });
    }
  },
  injectBookmarkStyles(_, payload) {
    const { styleContainer } = payload;
    const styles = MaterialsFactory.getBookmarkCategoryStyles();
    AnnotationDomUtils.clearCategoryStyles({ styleContainer, NS: 'bookmark' });
    AnnotationDomUtils.injectCategoryStyles({
      styleContainer,
      styles,
      NS: 'bookmark'
    });
  },
  async addHighlightToBookmarks({ state, dispatch, commit }, { bookId }) {
    const stateBookmarksByBookId = state.bookmarks[bookId];
    const bookmarks = [].concat(...Object.values(stateBookmarksByBookId));

    if (bookmarks?.length) {
      for (let index = 0; index < bookmarks.length; index++) {
        const bookmarkData = bookmarks[index];
        if (bookmarkData?.highlightedQuote) {
          continue;
        }
        const highlightedQuoteData = await dispatch(
          'MaterialsStore/getHighlightedQuote',
          {
            bookId,
            materialItem: bookmarkData
          },
          { root: true }
        );
        commit('addHighlightToBookmark', {
          bookId,
          blockId: bookmarkData.blockId,
          bookmarkId: bookmarkData.id,
          highlightedQuote: highlightedQuoteData?.text || ''
        });
      }
    }
  },
  setBookmarkId({ commit }, bookmarkId) {
    commit('setBookmarkId', bookmarkId);
  }
};

function _updateBlockBookmarks(state, bookId, blockId, blockBookmarks) {
  if (!state.bookmarks.hasOwnProperty(bookId)) {
    state.bookmarks = { ...state.bookmarks, [bookId]: {} };
  }
  const bookBookmarks = {
    ...state.bookmarks[bookId],
    [blockId]: [...blockBookmarks]
  };
  state.bookmarks[bookId] = {
    ...state.bookmarks[bookId],
    ...bookBookmarks
  };
}

const mutations = {
  setBookmarks(state, { blockId, blockBookmarks, bookId }) {
    _updateBlockBookmarks(state, bookId, blockId, blockBookmarks);
  },
  addBookmark(state, { bookmark, bookId }) {
    const blockId = bookmark.blockId;
    const bookmarks = state.bookmarks[bookId] || {};
    if (!bookmarks[blockId]) {
      bookmarks[blockId] = [];
    }

    const blockBookmarks = bookmarks[blockId];
    const isEmpty = !blockBookmarks.length;
    const index = isEmpty
      ? 0
      : blockBookmarks.findIndex(
          mark =>
            mark.locator.startLocator.logicalCharOffset >
            bookmark.locator.startLocator.logicalCharOffset
        );
    const indexToInsert = index >= 0 ? index : blockBookmarks.length;
    bookmarks[blockId].splice(indexToInsert, 0, bookmark);

    _updateBlockBookmarks(state, bookId, blockId, blockBookmarks);
  },
  deleteBookmarkProcess(state, { bookmark, bookId }) {
    const blockId = bookmark.blockId;
    const bookmarks = state.bookmarks[bookId] || {};
    delete bookmarks[blockId];
    state.bookmarks[bookId] = {
      ...bookmarks
    };
  },
  clearStore(state) {
    const newState = initState();
    Object.keys(newState).forEach(key => {
      const val = newState[key];
      state[key] = val;
    });
  },
  addHighlightToBookmark(
    state,
    { bookId, blockId, bookmarkId, highlightedQuote = '' }
  ) {
    if (
      !bookId ||
      !blockId ||
      !(state.bookmarks[bookId] && state.bookmarks[bookId][blockId])
    ) {
      return;
    }
    const bookmarkToHighlight = state.bookmarks[bookId][blockId].find(
      mark => mark.id === bookmarkId
    );
    if (bookmarkToHighlight) {
      bookmarkToHighlight.highlightedQuote = highlightedQuote;
    }
  },
  setBookmarkId(state, bookmarkId) {
    state.bookmarkId = bookmarkId;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
