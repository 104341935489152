const TypesEnum = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual'
};
const GroupTypesEnum = {
  PERSONAL: 'personal',
  FAMILY: 'family'
};

const StatusesEnum = {
  ACTIVE: 'active',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid'
};

module.exports = {
  TypesEnum,
  StatusesEnum,
  GroupTypesEnum
};
