const state = () => ({
  externalBlogsList: [],
  blogSettings: {}
});

const getters = {
  getExternalBlogsList() {},
  getExternalBlogUrl() {},
  getExternalBlogSettings() {}
};

const actions = {
  initBlogSettings() {},
  retrieveExternalBlogsList() {},
  saveExternalBlogSettings() {},
  changeAccount() {},
  createNewBlog() {},
  handleAppMenuClick() {},
  openBlogSharing() {}
};

const mutations = {};

export default {
  name: 'MediumStore',
  state,
  getters,
  actions,
  mutations
};
