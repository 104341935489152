import AppService from '@/services/AppService';

import ToasterEvents from '@/enums/ToasterEvents';
import AppStateEnum from '@/enums/AppStateEnum';

import PopupEventEnum from '@/enums/PopupEventEnum';
import ConfirmationActions from '@/enums/ConfirmationActionsEnum';

export default {
  computed: {
    showToolbar: function() {
      return (
        ![
          AppStateEnum.LOGIN,
          AppStateEnum.FORBIDDEN_IN_BROWSER,
          AppStateEnum.RESET_PASSWORD,
          AppStateEnum.CONFIRMATION
        ].includes(this.$route.name) && !this.isViewMode
      );
    },
    isViewMode() {
      return this.$store.getters['ContextStore/isViewMode'];
    },
    openedPopups() {
      const openedPopups = this.$store.getters[
        'ManagePopupStore/getOpenedPopups'
      ];
      return openedPopups.filter(popup => popup.shown);
    }
  },
  async created() {
    if (process.client) {
      const body = document.querySelector('body');
      body.setAttribute('js-parsed', true);
    }
    await AppService.init(this.$store, this.$router, this.$i18n, this.$vuetify);

    this.$_initDefaultSystemLanguage();

    if (process.client) {
      await this.$store.dispatch('LibraryStore/transformToClientData');
    }
  },
  methods: {
    toasterEventHandler({ type }) {
      switch (type) {
        case ToasterEvents.WAS_HIDDEN:
          this.currentToaster = null;
          break;
      }
    },
    popupEventHandler({ type, data }) {
      switch (type) {
        case ConfirmationActions.CANCEL:
        case PopupEventEnum.CLOSE:
          this.$_closeCurrentPopup(data.name);
          break;
        case ConfirmationActions.CONFIRM:
          this.$_processConfirm(data);
          this.$_closeCurrentPopup(data.name);
          break;
        default:
          break;
      }
    },
    $_closeCurrentPopup(popupName) {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: popupName
      });
    },
    $_initDefaultSystemLanguage() {
      this.$store.dispatch('ContextStore/initDefaultSystemLanguage');
    }
  }
};
