const loaderContext = {
  serverUrl: ''
};

function init(Context) {
  //TODO maybe remove service
  loaderContext.serverUrl = Context.serverUrl;
  return this;
}

async function getPublicationsData() {
  return { publicationsData: {} };
}

export default {
  init,
  getPublicationsData
};
