import Annotation from '@/services/utils/Annotation';
import AppModes from '@/enums/AppModeEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import DictionaryPopupTabIds from '@/enums/DictionaryPopupTabIds';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import Utils from '@/services/utils/Utils';
import AnnotationCategoriesEnum from '@shared/enums/AnnotationCategoriesEnum.mjs';

import AnnotationDomUtils from '@shared/publication/annotation/AnnotationDomUtils';
import MaterialsUtils from '@/services/utils/MaterialsUtils';
import MaterialsRequestService from '@/services/MaterialsRequestService';

import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';

const MIN_CUSTOM_CATEGORY_ID = 100;

const WIKI_CATEGORY_NAMES = [
  AppConstantsUtil.WIKI_CATEGORY_NAME,
  AppConstantsUtil.DEFINITION_CATEGORY_NAME,
  AppConstantsUtil.TRANSLATOR_CATEGORY_NAME
];

const initState = () => ({
  categories: {}
});

function _filterNoteCategories(categories) {
  return categories.filter(
    category => !WIKI_CATEGORY_NAMES.includes(category.name)
  );
}

function _getBookCategory(state, bookId, showDeleted = true) {
  const categories = state.categories[bookId] || [];
  if (showDeleted || !categories.length) {
    return categories;
  }

  return categories.filter(c => !c.isDeleted);
}

const storeGetters = {
  getDefaultCategory: () => {
    const defaultCategories = MaterialsUtils.getDefaultCategories();
    const mainDefaultCategory = defaultCategories.find(
      category => category.defaultCategory
    );
    return mainDefaultCategory;
  },
  getAllDefaultCategories: (state, getters) => () => {
    const categories = getters.getAllCategories;
    return categories.filter(
      category =>
        category.isDefault && !WIKI_CATEGORY_NAMES.includes(category.name)
    );
  },
  getCustomCategories: (state, getters) => () => {
    const categories = getters.getAllCategories;
    return categories.filter(
      category =>
        !category.isDefault && !WIKI_CATEGORY_NAMES.includes(category.name)
    );
  },
  isCategoriesExist: (state, getters) => {
    const categories = getters.getCategories;
    return categories.length !== 0;
  },
  getAllCategories: (state, getters, rootState, rootGetters) => {
    const publicationId = rootGetters['OpenParameterStore/getPublicationId'];
    return _getBookCategory(state, publicationId);
  },
  getCategories: (state, getters, rootState, rootGetters) => {
    const publicationId = rootGetters['OpenParameterStore/getPublicationId'];
    return _getBookCategory(state, publicationId, false);
  },
  getShownCategories: (state, getters) => {
    const categories = getters.getCategories;
    const noteCategories = _filterNoteCategories(categories);
    return noteCategories;
  },
  // eslint-disable-next-line no-unused-vars
  getAnnClassByCategoryId: state => categoryId => {
    const catId = categoryId.toString();
    return (
      Annotation.getGenericAnnotationClass() + '-cat-' + Utils.encodeHex(catId)
    );
  },
  getNewCategoryId: (state, getters) => {
    const categories = getters.getAllCategories;
    return getNewCategoryId(categories);
  },
  getCategoryNameById: (state, getters) => categoryId => {
    const categories = getters.getCategories;
    const category = categories.find(cat => cat.id === categoryId);
    if (!category) {
      return '';
    }
    return category.name;
  },
  getCategoryById: (state, getters) => categoryId => {
    const categories = getters.getAllCategories;
    const category = categories.find(cat => cat.id === categoryId);
    return category || {};
  },
  getCategoryLabelById: (state, getters) => categoryId => {
    const categories = getters.getCategories;
    const category = categories.find(cat => cat.id === categoryId);
    if (!category) {
      return '';
    }
    return category.label;
  },
  getCategoryColorById: (
    state,
    getters,
    rootState,
    rootGetters
  ) => categoryId => {
    const themeName = rootGetters['ReadingSettingsStore/getThemeName'];
    const categories = getters.getCategories;
    const category = categories.find(cat => cat.id === categoryId);
    const defaultColor = '';
    if (!category) {
      return defaultColor;
    }
    switch (themeName) {
      case ThemeClassNamesEnum.LIGHT:
      case ThemeClassNamesEnum.SEPIA:
        return category.color || defaultColor;
      case ThemeClassNamesEnum.NIGHT:
        return category.nightColor || defaultColor;
      default:
        return defaultColor;
    }
  }
};

const actions = {
  initCategories(
    { commit, rootGetters },
    { categories, bookId, categoryOrder }
  ) {
    const appMode = rootGetters['ContextStore/appModeGetter'];
    commit('setCategories', { categories, appMode, bookId, categoryOrder });
  },
  clearCategory({ commit }) {
    commit('clearStore');
  },
  injectCategoryStyle({ getters }, { category, styleContainer }) {
    const className = '.' + getters.getAnnClassByCategoryId(category.id);
    const styles = Annotation.getCategoryStyle(category, className);
    AnnotationDomUtils.injectCategoryStyles({ styleContainer, styles });
  },
  async deleteCategory(
    { commit, dispatch, getters, rootGetters },
    { categoryId }
  ) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookCategories = getters.getAllCategories;
    const indexToDelete = bookCategories.findIndex(
      cat => cat.id === categoryId
    );
    if (indexToDelete < 0) {
      return;
    }
    commit('deleteCategory', {
      indexToDelete,
      bookId: reqParams.bookId
    });
    const categories = getters.getAllCategories;
    await MaterialsRequestService.processCategoriesRequest({
      categories,
      reqParams
    });
    dispatch(
      'AnnotationsStore/deleteAnnotationsByCategory',
      { categoryId, reqParams },
      { root: true }
    );
  },
  async createDictionaryCategory(
    { dispatch, getters, rootGetters },
    { tabId }
  ) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const categories = getters.getAllCategories;
    const categoryName = getCategoryNameByWikiTabId(tabId);
    const existingCategory = categories.find(cat => cat.name === categoryName);
    if (existingCategory) {
      return existingCategory.id;
    }
    const category = createCategoryByTabId({ tabId, categories });
    dispatch('addCategory', { category, reqParams });
    return category.id;
  },
  async addCategory({ commit, getters, rootGetters }, { category }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    commit('addCategoryProcess', { category, bookId: reqParams.bookId });
    const categories = getters.getAllCategories;
    await MaterialsRequestService.processCategoriesRequest({
      categories,
      reqParams
    });
  },
  async changeCategory({ commit, getters, rootGetters }, { category }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    commit('changeCategory', { category, bookId: reqParams.bookId });
    const categories = getters.getAllCategories;
    await MaterialsRequestService.processCategoriesRequest({
      categories,
      reqParams
    });
  }
};

function createCategoryByTabId({ tabId, categories }) {
  const categoryBuilder = MaterialsFactory.createCategoryBuilder();
  const id = getNewCategoryId(categories);
  const name = getCategoryNameByWikiTabId(tabId);
  categoryBuilder.setId(id).setName(name);
  switch (tabId) {
    case DictionaryPopupTabIds.DICTIONARY:
      categoryBuilder
        .setUnderline(true)
        .setColor('#ffcdd2')
        .setNightColor('#9a3741');
      break;
    case DictionaryPopupTabIds.WIKI:
      categoryBuilder.setUnderline(true).setColor('#bdbdbd');
      break;
    case DictionaryPopupTabIds.TRANSLATOR:
      categoryBuilder
        .setUnderline(true)
        .setColor('#cae2ff')
        .setNightColor('#3d4c80');
      break;
  }
  return categoryBuilder.build();
}

function getCategoryNameByWikiTabId(tabId) {
  switch (tabId) {
    case DictionaryPopupTabIds.DICTIONARY:
      return 'En-En Dictionary';
    case DictionaryPopupTabIds.WIKI:
      return 'Wiki';
    case DictionaryPopupTabIds.TRANSLATOR:
      return 'Translation';
  }
}

function getNewCategoryId(categories) {
  let max = Math.max.apply(
    Math,
    categories.map(cat => cat.id)
  );
  return max >= MIN_CUSTOM_CATEGORY_ID ? ++max : MIN_CUSTOM_CATEGORY_ID;
}

function createCategory(cat, isDeleted = false) {
  const categoryBuilder = MaterialsFactory.createCategoryBuilder();
  const defaultCategoryNames = Object.values(AnnotationCategoriesEnum);
  const isCategoryDefault = defaultCategoryNames.includes(cat.name);
  const label = isCategoryDefault ? `Annotations.type.${cat.name}` : cat.name;
  categoryBuilder
    .setId(cat.id)
    .setName(cat.name)
    .setLabel(label)
    .setUnderline(cat.underline)
    .setColor(cat.color)
    .setNightColor(cat.nightColor)
    .setVersion(cat.version)
    .setModifiedAt(cat.modifiedAt)
    .setIsDeleted(isDeleted || cat.isDeleted)
    .setDefault(isCategoryDefault);
  return categoryBuilder.build();
}

const mutations = {
  clearStore(state) {
    const newState = initState();
    Object.keys(newState).forEach(key => {
      const val = newState[key];
      state[key] = val;
    });
  },
  setCategories(state, { categories, appMode, bookId, categoryOrder }) {
    const defaultCategories = MaterialsUtils.getDefaultCategories();
    const defaultCategoriesIds = defaultCategories.map(category => category.id);
    const activeCategories = categories.filter(
      category =>
        !!category.id &&
        !category.isDefault &&
        !defaultCategoriesIds.includes(category.id)
    );
    categories = [...activeCategories, ...defaultCategories];
    const isEditor = appMode === AppModes.EDITOR;
    const catObjs = categories.reduce((catObjsArr, cat) => {
      if (isEditor || !cat.editorOnly) {
        const catObj = createCategory(cat);
        catObjsArr.push(catObj);
      }
      return catObjsArr;
    }, []);
    if (categoryOrder) {
      const orderMap = categoryOrder.reduce((oMap, catOrder, index) => {
        oMap[catOrder] = index;
        return oMap;
      }, {});
      catObjs.sort((catA, catB) => {
        const indexA = orderMap[catA.id] ?? catA.id * 10000;
        const indexB = orderMap[catB.id] ?? catB.id * 10000;
        return indexA - indexB;
      });
    }

    state.categories = { ...state.categories, [bookId]: [] };
    state.categories[bookId] = [...state.categories[bookId], ...catObjs];
  },
  moveCategoryToBeginning(state, { categoryId, bookId }) {
    const bookCategories = _getBookCategory(state, bookId);
    const categoryIndex = bookCategories.findIndex(
      cat => cat.id === categoryId
    );
    const category = bookCategories.splice(categoryIndex, 1)[0];
    bookCategories.unshift(category);
    state.categories[bookId] = bookCategories;
  },
  deleteCategory(state, { indexToDelete, bookId }) {
    const bookCategories = _getBookCategory(state, bookId);
    bookCategories.splice(indexToDelete, 1);
    state.categories = { ...state.categories, [bookId]: bookCategories };
  },
  addCategoryProcess(state, { category, bookId }) {
    const bookCategories = _getBookCategory(state, bookId);
    const isExists = !!bookCategories.find(cat => cat.id === category.id);
    if (isExists) {
      return;
    }
    bookCategories.push(category);
    state.categories[bookId] = bookCategories;
  },
  changeCategory(state, { category, bookId }) {
    const bookCategories = _getBookCategory(state, bookId);
    let currentCategoryIndex = bookCategories.findIndex(
      cat => cat.id === category.id
    );
    bookCategories.splice(currentCategoryIndex, 1, category);
    state.categories[bookId] = bookCategories;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
