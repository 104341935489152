'use strict';

// import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isRegExp from 'lodash/isRegExp';
import MessageBuffer from './MessageBuffer';
import { captureException } from '@sentry/browser';

var levels = [];
let loggerParams = {};

var Level = function(name, consoleFn, level) {
  this.name = name;
  this.consoleFn = consoleFn;
  this.level = level;
  levels.push(this);
};

Level.ALL = new Level('ALL  ', 'log', Number.MIN_VALUE);
Level.LOG = new Level('LOG  ', 'log', 9000);
Level.TRACE = new Level('TRACE', 'debug', 10000);
Level.DEBUG = new Level('DEBUG', 'debug', 20000);
Level.INFO = new Level('INFO ', 'info', 30000);
Level.WARN = new Level('WARN ', 'warn', 40000);
Level.ERROR = new Level('ERROR', 'error', 50000);
Level.FATAL = new Level('FATAL', 'error', 60000);
Level.OFF = new Level('OFF  ', 'dummy', Number.MAX_VALUE);

////////////////////////////////////////////////////////////////////////////

// generate unique browser window/tab id
var windowId = '';
while (windowId.length < 10) {
  windowId += String.fromCharCode(97 + Math.round(Math.random() * 26));
}

////////////////////////////////////////////////////////////////////////////

/**
 *
 * logLevel - log level will be displayed in console
 * sendLogLevel - log level will be send to the server
 *
 * defaults:
 *   logLevel "off" for prod and "warn" for dev
 *   sendLogLevel "all"
 * Examples:
 *   ?logLevel=all&sendLogLevel=warn
 *   ?sendLogLevel=error
 *   ?logLevel=warn
 */
let _defaultLevel;
if (process.env.IS_PROD && process.client) {
  _defaultLevel = Level.OFF;
} else if (process.server) {
  _defaultLevel = Level.INFO;
} else {
  _defaultLevel = Level.WARN;
}
const queryParams = new URLSearchParams(process.client ? location.search : '');
const customLogLevelName = queryParams.get('logLevel');
const customSendLogLevelName = queryParams.get('sendLogLevel');
const customLevel = customLogLevelName
  ? Level[customLogLevelName.toUpperCase()]
  : null;
const customSendLevel = customSendLogLevelName
  ? Level[customSendLogLevelName.toUpperCase()]
  : null;
_defaultLevel = customLevel || _defaultLevel;
const _defaultSendLevel = customSendLevel || Level.ALL;

////////////////////////////////////////////////////////////////////////////

var loggerFactory = {
  initParams(options) {
    loggerParams = Object.assign(loggerParams, options);
  },
  getLogger: function(loggerName) {
    loggerName = loggerName.replace(/[/:]/g, '.');
    const level = _defaultLevel;
    return {
      all: _logFn(Level.ALL, level, loggerName), // regardless of actual level
      log: _logFn(Level.LOG, level, loggerName), // regardless of actual level

      trace: _logFn(Level.TRACE, level, loggerName),
      debug: _logFn(Level.DEBUG, level, loggerName),
      info: _logFn(Level.INFO, level, loggerName),
      warn: _logFn(Level.WARN, level, loggerName),
      error: _logFn(Level.ERROR, level, loggerName),
      fatal: _logFn(Level.FATAL, level, loggerName)
    };
  }
};

////////////////////////////////////////////////////////////////////////////

function createLogMessage(text, params) {
  return new MessageBuffer.Message('Log', text, params);
}

function TS() {
  return new Date().toLocaleString('sv', { timeZone: 'UTC' }) + 'Z';
}

////////////////////////////////////////////////////////////////////////////

function _logFn(level, actLevel, loggerName) {
  var consoleFn = _console(level);
  var levelName = level.name.trim();
  var loggerText = '[' + loggerName + '] ';
  var levelAndLoggerText = '[' + level.name + '] [' + loggerName + '] ';

  /**
   * @param {Object.stubError} if set true for last parameter,
   * error will _never_ be send to Sentry
   *
   * @param {Object.sendError} if set true for last parameter,
   * log message will be sent to sentry (if is not disabled with option stubError)
   */
  return function(...args) {
    const lastItem = args[args.length - 1];
    const sendSentryMessage = _sendSentryMessage(lastItem, level);

    let needSendMessageImmediately = false;
    if (
      _isObject(lastItem) &&
      (lastItem.hasOwnProperty('stubError') ||
        lastItem.hasOwnProperty('sendError'))
    ) {
      const option = args.pop();
      needSendMessageImmediately = option.sendError;
    }

    args = args
      .map(function(a) {
        if (isString(a)) {
          return a;
        }
        if (isNumber(a)) {
          return a.toString();
        }
        if (isRegExp(a)) {
          return a.toString();
        }
        if (isUndefined(a)) {
          return 'undefined';
        }
        if (a instanceof Error) {
          return _formatError(a);
        }
        return JSON.stringify(a);
      })
      .join(' ');

    const dt = new Date();
    const message = dt.toISOString() + ' ' + levelAndLoggerText + args;
    if (level.level >= actLevel.level) {
      consoleFn(message);
    }

    if (process.client && level.level >= Level.WARN.level) {
      const cons = console;
      if (!cons.savedLogs) {
        cons.savedLogs = [];
      }
      const savedLogs = cons.savedLogs;
      const err = new Error('stack');
      const logvalue = levelAndLoggerText + args;
      const value =
        typeof logvalue === 'string'
          ? logvalue.replace(`[${level.name}]`, '').trim()
          : '';
      savedLogs.push({
        type: level.name,
        timeStamp: TS(),
        value,
        stack: err.stack
      });
      if (savedLogs.length > 200) {
        savedLogs.shift();
      }
    }
    if (level.level >= _defaultSendLevel.level) {
      const params = {
        ...loggerParams,
        level: levelName,
        windowId: windowId
      };
      if (process.client) {
        params.url = location.href;
      }
      const logMessage = createLogMessage(loggerText + args, params);
      if (process.client) {
        MessageBuffer.push(logMessage);
      }

      if (sendSentryMessage) {
        const textLogMessage = JSON.stringify(logMessage);
        captureException(new Error(textLogMessage));
      }
    }
    if (process.client && needSendMessageImmediately) {
      MessageBuffer.flushSync();
    }
  };
}

// code duplication with PresentSystemException.js
// idea stolen from angular $log
function _formatError(error) {
  var message = error.message;
  var stack = error.stack;

  var res = message;

  if (stack) {
    var messageInStack = message && stack.indexOf(message) !== -1;
    res = messageInStack ? stack : 'Error: ' + message + '\n' + stack;
  } else if (error.sourceURL) {
    res = message + '\n' + error.sourceURL + ':' + error.line;
  }

  return res;
}

function stdOut(args) {
  process.stdout.write(args + '\n');
}

// stolen from angular $log
function _console(level) {
  var logFn = process.server
    ? stdOut
    : // eslint-disable-next-line no-console
      console[level.consoleFn] || console.log || _noop;

  if (logFn.apply) {
    return function() {
      return logFn.apply(console, arguments);
    };
  }

  // we are IE which either doesn't have window.console => this is noop and we do nothing,
  // or we are IE where console.log doesn't have apply so we log just first arg.
  return function(arg) {
    logFn(arg);
  };
}

function _sendSentryMessage(param, level) {
  if (_isDisabledSendSentryMessage(param)) {
    return false;
  }

  if (_isRequiredSendSentryMessage(param)) {
    return true;
  }

  const sendSentryMessage =
    level.name === Level.FATAL.name || level.name === Level.ERROR.name;

  return sendSentryMessage;
}

function _isRequiredSendSentryMessage(param) {
  const isObject = _isObject(param);

  return isObject && param.sendError;
}

function _isDisabledSendSentryMessage(param) {
  const isObject = _isObject(param);

  return isObject && param.stubError;
}

function _isObject(param) {
  const isObject =
    typeof param === 'object' && !Array.isArray(param) && param !== null;

  return isObject;
}

function _noop() {}

////////////////////////////////////////////////////////////////////////////

export default loggerFactory;

////////////////////////////////////////////////////////////////////////////
