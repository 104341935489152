<template>
  <BaseShareControl
    fab
    elevation="0"
    outlined
    small
    class="share-control"
  ></BaseShareControl>
</template>

<script>
import BaseShareControl from '@/components/controls/BaseShareControl/BaseShareControl';

export default {
  name: 'ShareControl',
  components: {
    BaseShareControl
  }
};
</script>

<style lang="less" src="./ShareControl.less"></style>
