export default {
  WELCOME: 'welcome',
  USER_DATA_COLLECTION: 'userDataCollection',
  ASSESSMENT_INITIAL_INTRO: 'assessmentInitialIntro',
  ASSESSMENT_RETAKE_ALLOWED: 'assessmentRetakeAllowed',
  ASSESSMENT_RETAKE_NOT_ALLOWED: 'assessmentRetakeNotAllowed',
  ASSESSMENT_IN_PROCESS: 'assessmentInProcess',
  ASSESSMENT_COMPLETED_SUCCESSFULLY: 'assessmentCompletedSuccessfully',
  ASSESSMENT_COMPLETED_UNSUCCESSFULLY: 'assessmentCompletedUnsuccessfully',
  ASSESSMENT_INTERRUPTED: 'assessmentInterrupted',
  ASSESSMENT_MAX_BOOK_LEVEL_REACHED: 'assessmentMaxBookLevelReached',
  OPEN_LIBRARY: 'openLibrary'
};
