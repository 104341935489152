import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AssetsManager.vue');

import PromiseUtil from '@/services/utils/PromiseUtil';
import FileTransport from './FileTransport/FileTransport';
import DownloadQueueService from '@/services/DownloadQueueService';
import AssetReaderFactory from '@/classes/factories/AssetReader/AssetReaderFactory';
import AssetStrategyEnum from '@/enums/AssetStrategyEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';

let currentAssetReader;
let initDefer = PromiseUtil.createDeferred();

async function getAssetReader() {
  await initDefer.promise;
  return currentAssetReader;
}

function init(Context, runId) {
  return process.server
    ? serverSideInit(Context, runId)
    : clientSideInit(Context, runId);
}

async function clientSideInit(Context, runId) {
  const errors = [];
  try {
    const { error } = await initFileTransport(Context, runId);
    if (error) {
      errors.push(error);
    }
    const coverExtension = await _getDefaultCoverExtension();
    currentAssetReader =
      Context.assetStretagy === AssetStrategyEnum.REMOTE_SOURCE
        ? AssetReaderFactory.createRemoteAssetReader(coverExtension)
        : AssetReaderFactory.createLocalAssetReader(coverExtension);
  } catch (error) {
    logger.fatal(
      `Error while initializing AssetsManager on client. Error: ${error}`
    );
  }
  initDefer.resolve();
  logger.info('Client side asset manager initialized');
  return {
    errors
  };
}

async function initFileTransport(Context, runId) {
  let error;
  try {
    await FileTransport.init(Context, runId);
  } catch (err) {
    logger.error(`Error while initializing FileTransport. Error: ${err}`);
    error = err;
  }
  return { error };
}

async function serverSideInit(Context, runId) {
  const errors = [];
  try {
    const { error } = await initFileTransport(Context, runId);
    if (error) {
      errors.push(error);
    }
    const coverExtension = '.webp';
    currentAssetReader = AssetReaderFactory.createRemoteAssetReader(
      coverExtension
    );
  } catch (error) {
    logger.fatal(
      `Error while initializing AssetsManager on server. Error: ${error}`
    );
  }
  initDefer.resolve();
  return {
    errors
  };
}

function setRunId(runId) {
  FileTransport.setRunId(runId);
}

async function fileTransportAvailable() {
  const assetReader = await getAssetReader();
  return assetReader.fileTransportAvailable();
}

function getAssetQueueById(publicationId) {
  const queue = DownloadQueueService.getQueue();
  return queue.reduce((assetNames, queueItem) => {
    if (
      queueItem.constructor === DownloadQueueService.AssetQueueItem &&
      queueItem.getPubId() === publicationId
    ) {
      assetNames.push(queueItem.getAssetName());
    }
    return assetNames;
  }, []);
}

function _getDefaultCoverExtension() {
  return new Promise(function(resolve) {
    const img = window.document.createElement('img');
    img.onload = function() {
      if (img.width > 0 && img.height > 0) {
        resolve('.webp');
      } else {
        resolve('.png');
      }
    };
    img.onerror = function() {
      resolve('.png');
    };
    img.src =
      'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
  });
}

async function isAvailable(accessType) {
  const assetReader = await getAssetReader();
  return assetReader.isAvailable(accessType);
}

async function initBookAssets(bookId, forced) {
  const assetReader = await getAssetReader();
  return assetReader.initBookAssets(bookId, forced);
}

async function checkInternalMemory(accessType, excludeEntries) {
  const assetReader = await getAssetReader();
  return assetReader.checkInternalMemory(accessType, excludeEntries);
}

async function removeAll(accessType, excludeEntries) {
  const assetReader = await getAssetReader();
  return assetReader.removeAll(accessType, excludeEntries);
}

async function destroyPublicationAssetState(params) {
  const assetReader = await getAssetReader();
  return assetReader.destroyTrackingItem(params);
}

async function readFile(bookId, fileName) {
  const assetReader = await getAssetReader();
  return assetReader.readFile(bookId, fileName);
}

async function readLibraryFile(fileName, accessType) {
  const assetReader = await getAssetReader();
  return assetReader.readLibraryFile(fileName, accessType);
}

async function writeFile(filePath, data, source) {
  const assetReader = await getAssetReader();
  return assetReader.writeFile(filePath, data, source);
}

async function readFileByChunk(bookId, fileName, chunkOffset) {
  const assetReader = await getAssetReader();
  return assetReader.readFileByChunk(bookId, fileName, chunkOffset);
}

async function readBookMetaFile(bookId) {
  const assetReader = await getAssetReader();
  return assetReader.readBookMetaFile(bookId);
}

async function readContentIndexes(bookId) {
  const assetReader = await getAssetReader();
  return assetReader.readContentIndexes(bookId);
}

async function readAlignmentIndex(bookId) {
  const assetReader = await getAssetReader();
  return assetReader.readAlignmentIndex(bookId);
}

async function readerPauseMapFile(bookId) {
  const assetReader = await getAssetReader();
  return assetReader.readerPauseMapFile(bookId);
}

async function readAudioFile(bookId, fileName) {
  const assetReader = await getAssetReader();
  return assetReader.readAudioFile(bookId, fileName);
}

async function bookContentByChunk(bookId, chunkOffset) {
  const assetReader = await getAssetReader();
  return assetReader.bookContentByChunk(bookId, chunkOffset);
}

async function bookAlignmentByChunk(bookId, chunkOffset) {
  const assetReader = await getAssetReader();
  return assetReader.bookAlignmentByChunk(bookId, chunkOffset);
}

async function getFileSourcePath(bookId, fileName, assetType) {
  const assetReader = await getAssetReader();
  return assetReader.getFileSourcePath(bookId, fileName, assetType);
}

async function getAssetSource(bookId, assetType) {
  const assetReader = await getAssetReader();
  return assetReader.getAssetSource(bookId, assetType);
}

async function getAudioSourcePath(bookId, fileName) {
  const assetReader = await getAssetReader();
  return assetReader.getFileSourcePath(bookId, fileName, AssetTypeEnum.AUDIO);
}

async function getRealFileName(bookId, fileName) {
  const assetReader = await getAssetReader();
  return assetReader.getRealFileName(bookId, fileName);
}

async function isAssetDownloaded(publicationId, assetName) {
  const assetReader = await getAssetReader();
  return assetReader.isAssetDownloaded(publicationId, assetName);
}

async function downloadAsset(publicationId, assetName) {
  DownloadQueueService.putFileInQueue(publicationId, assetName);
  let assetActionResponses;
  try {
    const assetReader = await getAssetReader();
    assetActionResponses = await assetReader.downloadAsset(
      publicationId,
      assetName
    );
  } catch (err) {
    return Promise.reject(err);
  }

  DownloadQueueService.deleteFileFromQueue(publicationId, assetName);
  return assetActionResponses;
}

function isPublicationInQueue(publicationId) {
  return DownloadQueueService.isPublicationInQueue(publicationId);
}

async function downloadPublication(publicationId) {
  DownloadQueueService.putFileInQueue(publicationId);
  let assetActionResponses;
  try {
    const assetReader = await getAssetReader();
    assetActionResponses = await assetReader.downloadPublication(publicationId);
  } catch (err) {
    return Promise.reject(err);
  }

  DownloadQueueService.deleteFileFromQueue(publicationId);
  return assetActionResponses;
}

async function removePublication(publicationId) {
  const assetReader = await getAssetReader();
  return assetReader.removePublication(publicationId);
}

async function removeAssets(publicationId, assetNames) {
  const assetReader = await getAssetReader();
  return assetReader.removeAssets(publicationId, assetNames);
}

async function cancelDownload(publicationId) {
  const assetReader = await getAssetReader();
  await assetReader.cancelDownload(publicationId);
  DownloadQueueService.deleteFileFromQueue(publicationId);
}

async function cancelDownloadingAsset(publicationId, assetName) {
  const assetReader = await getAssetReader();
  await assetReader.cancelDownloadAsset(publicationId, assetName);
  DownloadQueueService.deleteFileFromQueue(publicationId, assetName);
}

async function updatePublication(publicationId, excludeAssetNames = []) {
  const assetReader = await getAssetReader();
  const assetActionResponses = await assetReader.updatePublication(
    publicationId,
    excludeAssetNames
  );
  return assetActionResponses;
}

/**
 * resume downloading if interrupted (went offline, reload etc.)
 */
async function resumeDownloadIfNeeded() {
  const queue = DownloadQueueService.getQueue();
  if (!queue.length) {
    return Promise.resolve();
  }
  const assetsQueueMapById = {};
  const resumeDownloadPromises = [];
  queue.forEach(queueItem => {
    if (queueItem.constructor === DownloadQueueService.QueueItem) {
      resumeDownloadPromises.push(downloadPublication(queueItem.getPubId()));
    } else if (queueItem.constructor === DownloadQueueService.AssetQueueItem) {
      const pubId = queueItem.getPubId();
      if (!assetsQueueMapById.hasOwnProperty(pubId)) {
        assetsQueueMapById[pubId] = [];
      }
      assetsQueueMapById[pubId].push(queueItem);
    }
  });

  Object.keys(assetsQueueMapById).forEach(pubId => {
    const queueItems = assetsQueueMapById[pubId];

    const assetResumePromise = initBookAssets(pubId).then(() => {
      const assetPromises = queueItems.map(queueItem => {
        const assetName = queueItem.getAssetName();
        return downloadAsset(pubId, assetName).catch(err =>
          logger.error(
            `On resume download ${pubId} and asset name ${assetName} get error: ${err}`
          )
        );
      });
      return Promise.all(assetPromises);
    });
    resumeDownloadPromises.push(assetResumePromise);
  });

  await Promise.all(resumeDownloadPromises);
}

async function getPublicationTrackingItemView(publicationId) {
  const assetReader = await getAssetReader();
  return assetReader.getPublicationTrackingItemView(publicationId);
}

async function getMapperById(publicationId) {
  const assetReader = await getAssetReader();
  return assetReader.getMapperById(publicationId);
}

async function addChangeListener(publicationId, listener, id) {
  const assetReader = await getAssetReader();
  return assetReader.addChangeListener(publicationId, listener, id);
}

async function removeDownloadListener(publicationId, id) {
  const assetReader = await getAssetReader();
  return assetReader.removeDownloadListener(publicationId, id);
}

async function getCoverPath(publicationId, size) {
  const assetReader = await getAssetReader();
  return assetReader.getCoverPath(publicationId, size);
}

async function buildBookCoverPath(id, cover, size, accessType, options) {
  const assetReader = await getAssetReader();
  return assetReader.buildBookCoverPath(id, cover, size, accessType, options);
}

async function switchAssetInfo(id, fromAssetName, toAssetName, assetName) {
  const assetReader = await getAssetReader();
  return assetReader.switchAssetInfo(id, fromAssetName, toAssetName, assetName);
}

async function replaceAssetInfo(id, fromAssetName, toAssetName, assetName) {
  const assetReader = await getAssetReader();
  return assetReader.replaceAssetInfo(
    id,
    fromAssetName,
    toAssetName,
    assetName
  );
}

async function checkPublicationAssetsOnSources(bookId, assetsNames, sources) {
  const assetReader = await getAssetReader();
  return assetReader.checkPublicationAssetsOnSources(
    bookId,
    assetsNames,
    sources
  );
}

async function removeAssetInfo(id, assetName) {
  const assetReader = await getAssetReader();
  return assetReader.removeAssetInfo(id, assetName);
}

async function deleteAssetFile(id, assetFileName, assetName) {
  const assetReader = await getAssetReader();
  return assetReader.deleteAssetFile(id, assetFileName, assetName);
}

async function getAssetNameContentList(isAudioBook) {
  const assetReader = await getAssetReader();
  return assetReader.getAssetNameContentList(isAudioBook);
}
async function getAssetNameAudioList() {
  const assetReader = await getAssetReader();
  return assetReader.getAssetNameAudioList();
}

async function getAssetNameList(isAudioBook) {
  const assetReader = await getAssetReader();
  return assetReader.getAssetNameList(isAudioBook);
}

export default {
  replaceAssetInfo,
  removeAssetInfo,
  switchAssetInfo,
  deleteAssetFile,
  isAvailable,
  init,
  setRunId,
  initBookAssets,
  checkInternalMemory,
  removeAll,
  readFile,
  readLibraryFile,
  writeFile,
  readFileByChunk,
  readBookMetaFile,
  readAudioFile,
  readAlignmentIndex,
  readerPauseMapFile,
  readContentIndexes,
  bookContentByChunk,
  bookAlignmentByChunk,
  getFileSourcePath,
  getAssetSource,
  getCoverPath,
  getAudioSourcePath,
  getRealFileName,
  buildBookCoverPath,
  downloadPublication,
  isPublicationInQueue,
  isAssetDownloaded,
  downloadAsset,
  removeAssets,
  removePublication,
  cancelDownload,
  cancelDownloadingAsset,
  updatePublication,
  resumeDownloadIfNeeded,
  getPublicationTrackingItemView,
  getMapperById,
  getAssetQueueById,
  addChangeListener,
  destroyPublicationAssetState,
  removeDownloadListener,
  fileTransportAvailable,
  checkPublicationAssetsOnSources,
  getAssetNameContentList,
  getAssetNameAudioList,
  getAssetNameList
};
