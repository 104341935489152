import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FavouritePublicationsStore.js');

import RequestService from '@/services/RequestService';
import FavouritePublication from '@shared/classes/FavouritePublication';

const getDefaultPubs = () => ({});

const initState = () => ({
  value: false,
  favouritePublications: getDefaultPubs()
});

const storeGetters = {
  getFavorites(state) {
    return state.favouritePublications;
  },
  getFavoritesPublicationsIds(state) {
    return Object.keys(state.favouritePublications || {});
  },
  isFavouritePublication: state => publicationId => {
    return state.favouritePublications.hasOwnProperty(publicationId);
  }
};

const actions = {
  async initFavouritePublications({ commit }, favourites) {
    if (Object.keys(favourites?.favouritePubsMap || {}).length) {
      commit('setFavouritePublications', favourites.favouritePubsMap);
      return;
    }
    try {
      const response = await RequestService.request(
        'get',
        'FavouritePublications',
        'getFavouritePublications'
      );
      commit('setFavouritePublications', response.data?.favouritePubsMap);
    } catch (error) {
      logger.error(`Init Favourite Publications failed with: ${error}`);
    }
  },
  async toggleFavourites({ getters, dispatch }, publicationId) {
    const isFavouritePublication = getters['isFavouritePublication'](
      publicationId
    );

    if (isFavouritePublication) {
      dispatch('removeFromFavourites', publicationId);
    } else {
      dispatch('addToFavourites', publicationId);
    }
  },
  async addToFavourites({ commit, rootGetters }, publicationId) {
    try {
      const publication = rootGetters['LibraryStore/getPublicationById'](
        publicationId
      );

      await RequestService.request(
        'post',
        'FavouritePublications',
        'addPublicationToFavourites',
        publication
      );
      commit('addToFavourites', publication);
    } catch (error) {
      logger.error(
        `Add publication ${publicationId} to Favourites failed with: ${error}`
      );
    }
  },
  async removeFromFavourites({ commit }, publicationId) {
    try {
      await RequestService.request(
        'post',
        'FavouritePublications',
        'removePublicationFromFavourites',
        publicationId
      );
      commit('removeFromFavourites', publicationId);
    } catch (error) {
      logger.error(
        `Remove publication ${publicationId} from Favourites failed with: ${error}`
      );
    }
  }
};

const mutations = {
  setFavouritePublications(state, publications) {
    state.favouritePublications = publications || getDefaultPubs();
  },
  addToFavourites(state, publication) {
    const pub = new FavouritePublication(publication);
    state.favouritePublications = {
      ...state.favouritePublications,
      [pub.id]: pub
    };
  },
  removeFromFavourites(state, publicationId) {
    if (state.favouritePublications.hasOwnProperty(publicationId)) {
      delete state.favouritePublications[publicationId];
      state.favouritePublications = {
        ...state.favouritePublications
      };
    }
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
