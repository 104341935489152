import get from 'lodash/get';
import publicationUtils from '@/services/utils/publicationUtils';

class BookInfoTabView {
  constructor(info) {
    this.id = info.fileName;
    this.name = info.name;
    this.level = info.level;
    this.cover = info.cover;
    this.author = info.author;
    this.difficulty = info.difficulty;
    this.isAudioBook = info.audio;
    this.category = info.category;
    this.readingTime = info.readingTime;
    this.shortDescription = info.shortDescription || info.description;
    this.isAuthorInTitle = publicationUtils.isAuthorInTitle(info);
    this.version = get(info, 'publish.version.value', '');
    const date = info.date;
    this.date = publicationUtils.getPublishDate(date);
    this.toc = info.toc || [];
  }

  setToc(toc) {
    this.toc = [...toc];
  }
}

function create(info) {
  return new BookInfoTabView(info);
}

export default {
  create
};
