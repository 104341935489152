<template>
  <popup
    :content-class="'move-to-section-popup'"
    scrollable
    persistent
    :content-image="contentImage"
    :content-title="
      $t('CompilationSelectionActionMenu.moveToSectionPopup.noSelections.label')
    "
    :header-title="
      $t('CompilationSelectionActionMenu.moveToSectionPopup.title')
    "
    :footer-divider="false"
    width="575"
    @popupEvent="popupEventHandler"
  >
    <template v-if="sections.length" #custom-content>
      <v-card-text class="move-to-section-content">
        <div class="section-list-block">
          <div
            v-for="(section, index) of sections"
            :key="index"
            class="section-list-item"
            :class="{ 'selected-section': index === activeSectionIndex }"
            @click="chooseSection(index)"
          >
            <div class="section-title-wrapper">
              <div class="section-title">
                {{ section.section.title }}
              </div>
            </div>
            <div class="section-count">
              <span>{{
                `${$t(
                  'CompilationSelectionActionMenu.moveToSectionPopup.selections.label'
                )}`
              }}</span>
              <span>{{ `${section.selectionsNum}` }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
    </template>
  </popup>
</template>

<script>
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup';
import images from '@/assets/images';

export default {
  name: PopupNamesEnum.MOVE_TO_SECTION_POPUP,
  components: {
    Popup
  },
  props: {
    popupContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeSectionIndex: -1,
      paraId: this.popupContext.paraId
    };
  },
  computed: {
    sections() {
      return this.$store.getters['CompilationsStore/getSections']();
    },
    contentImage() {
      return images.picTocEmpty;
    }
  },
  methods: {
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
        default:
          break;
      }
    },
    $_close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.MOVE_TO_SECTION_POPUP }
      });
    },
    $_moveToSection(index) {
      if (index < 0) {
        return;
      }

      const section = this.sections[index].section;
      this.$store.dispatch('CompilationsStore/moveToSection', {
        paraId: this.paraId,
        sectionId: section._id
      });
    },
    chooseSection(index) {
      this.activeSectionIndex = index;
      this.$_moveToSection(index);
      this.$_close();
    }
  }
};
</script>

<style scoped lang="less">
@import 'MoveToSectionPopup.less';
</style>
