<template>
  <Popup
    width="588"
    persistent
    :header-divider="false"
    :footer-divider="false"
    :content-image="picClosedBook"
    :content-title="$t('StudyPublication.sorryTheBookWasNotDownloaded')"
    :primary-label="$t('Popup.button.return.label')"
    :primary-footer-handler="goBackHandler"
    @popupEvent="goBackHandler"
  />
</template>

<script>
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import images from '@/assets/images';

import Popup from '@/components/base/Popup/Popup.vue';

export default {
  name: PopupNamesEnum.BOOK_NOT_DOWNLOADED_POPUP,
  components: {
    Popup
  },
  props: {
    popupContext: Object
  },
  data() {
    return {
      picClosedBook: images.picClosedBook
    };
  },
  watch: {
    $route() {
      this.closePopup();
    }
  },
  methods: {
    closePopup() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.BOOK_NOT_DOWNLOADED_POPUP }
      });
    },
    goBackHandler() {
      this.closePopup();
      // go back
      try {
        const prevRoute = this.$store.getters[
          'PublicationStore/getPreviousRoute'
        ];
        this.$router.push(prevRoute);
      } catch (err) {
        const text = `BookNotDownloadedPopup: error occurred - ${err}`;
        this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
      }
    }
  }
};
</script>
