<template>
  <v-card-text class="popup-content">
    <div class="popup-content-block">
      <div class="content-pic">
        <img v-if="contentImage" height="144" width="144" :src="contentImage" />
      </div>
      <div
        v-if="contentTitle"
        class="content-title"
        v-html="contentTitle"
      ></div>
      <div
        v-if="contentMessage"
        class="content-text"
        v-html="contentMessage"
      ></div>
      <slot name="apply-additional-block" />
    </div>
    <div class="popup-content-bottom">
      <slot name="apply-additional-content" />
    </div>
  </v-card-text>
</template>

<script>
export default {
  components: {},
  props: {
    contentImage: {
      type: String,
      required: false,
      default: ''
    },
    contentTitle: {
      type: String,
      required: false,
      default: ''
    },
    contentMessage: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="less" src="./PopupContent.less"></style>
