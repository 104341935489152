import Http from '@/services/utils/Http';
import PromiseUtil from '@/services/utils/PromiseUtil';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('RestPaymentsService.js');

let initDefer = PromiseUtil.createDeferred();

const requestContext = {};

function init(Context) {
  initDefer.resolve();
  Object.assign(requestContext, {
    paymentServerUrl: Context.paymentServerUrl
  });
}

function setRunId(runId) {
  requestContext.runId = runId;
}

async function restRequest(method, controller, params, data) {
  await initDefer.promise;
  const url = `rest/${controller.replace(/^\//, '')}`;
  try {
    const response = await Http.http({
      method,
      baseURL: requestContext.paymentServerUrl,
      url,
      params: {
        ...(params || {}),
        RunId: requestContext.runId
      },
      data
    });
    return response.data;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

export default { init, restRequest, setRunId };
