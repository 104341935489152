<script>
import SVGSprite from '@/assets/sprite.symbol.svg';

export default {
  name: 'BaseSpriteIcon',
  functional: true,
  props: {
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'black'
    },
    fill: {
      type: String,
      default: 'currentColor'
    },
    iconName: String
  },
  render(createElement, context) {
    const icon = context.props.iconName;
    if (!icon) {
      return;
    }
    const link = `${SVGSprite}#${icon}`;
    return createElement(
      'svg',
      {
        attrs: {
          xmlns: 'http://www.w3.org/2000/svg',
          class: `svg-ico ${context.props.customClass}`,
          viewBox: context.props.viewBox,
          disabled: context.props.isDisabled,
          fill: context.props.color
        }
      },
      [
        createElement('use', {
          attrs: {
            href: link,
            'xlink:href': link
          }
        })
      ]
    );
  }
};
</script>

<style lang="less" src="./BaseSpriteIcon.less"></style>
