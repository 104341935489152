<template>
  <Popup
    content-class="confirmation-popup"
    :header-title="popupState.headerText"
    :header-divider="false"
    :footer-divider="false"
    :content-image="popupState.contentImage"
    :content-title="popupState.contentTitle"
    hide-overlay
    width="588"
    scrollable
    :primary-footer-handler="
      popupState.primaryFooterHandler ? popupState.primaryFooterHandler : null
    "
    :primary-label="
      popupState.primaryFooterHandler ? popupState.primaryLabel : ''
    "
    @popupEvent="popupEventHandler"
  >
    <template v-if="popupState.component" #custom-content>
      <v-card-text class="popup-confirmation-content">
        <component
          :is="popupState.component"
          :hash-code="popupContext.hashCode"
          @resetPasswordEvent="resetPasswordEventHandler"
        />
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ConfirmationTaskPopup');

import ResetPassword from '@/components/views/LoginForgotPassword/ResetPassword';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ConfirmationTaskEnum from '@/enums/ConfirmationTaskEnum';
import PopupStateFactory from '@/classes/factories/PopupStateFactory';
import ResetPasswordEvent from '@/enums/ResetPasswordEvent';
import Popup from '@/components/base/Popup/Popup.vue';

import images from '@/assets/images';

export default {
  name: PopupNamesEnum.CONFIRMATION_TASK_POPUP,
  components: {
    ResetPassword,
    Popup
  },
  props: {
    popupContext: Object
  },
  data() {
    return {
      currentState: null
    };
  },
  computed: {
    popupState() {
      return this.$_getConfirmationTaskPopupState();
    }
  },
  async mounted() {
    try {
      const showConfirmState = this.$store.getters[
        'ContextStore/isNeedShowConfirmState'
      ];
      if (!showConfirmState) {
        this.$_changeState(ConfirmationTaskEnum.EMPTY);
      }
      if (!this.popupContext) {
        logger.error('No data in response from server');
        this.$_errorHandler('No data in response from server');
        return;
      }
      if (!this.popupContext.data && this.popupContext.message) {
        logger.error(this.popupContext.message);
        this.$_changeState(ConfirmationTaskEnum.NOT_FOUND);
        return;
      }
      this.$_processStateByTaskType(this.popupContext.data.taskType);
    } catch (error) {
      logger.error(`ConfirmationTaskWizard error:${error}`);
      this.$_errorHandler(error);
    }
  },
  methods: {
    openLibrary() {
      this.$router.push({ name: 'library' });
      this.$_close();
    },
    $_close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.CONFIRMATION_TASK_POPUP }
      });
      if (typeof this.popupContext.closePopupCallback === 'function') {
        this.popupContext.closePopupCallback();
      }
    },
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
      }
    },
    $_changeState(state) {
      this.currentState = state;
    },
    $_processStateByTaskType(taskType) {
      switch (taskType) {
        case 'RegisterUserProfile':
          this.$_processRegistrationConfirmation(this.popupContext.data);
          break;
        case 'ResetPassword':
          this.$_processPasswordReset(this.popupContext.data);
          break;
        default:
          logger.error(`Task ${taskType} was not found`);
          this.$_errorHandler(`Task ${taskType} was not found`);
          break;
      }
    },
    $_processRegistrationConfirmation({ status }) {
      switch (status) {
        case ConfirmationTaskEnum.CONFIRM_EMAIL:
        case ConfirmationTaskEnum.PROCESSED:
          this.$_changeState(ConfirmationTaskEnum.CONFIRM_PROFILE_HASH_CODE);
          break;
        case ConfirmationTaskEnum.EXPIRED_LINK:
          this.$_changeState(ConfirmationTaskEnum.EXPIRED_LINK);
          break;
        default:
          logger.error('Confirmation code was not found or is expired');
          break;
      }
    },
    $_processPasswordReset({ status }) {
      switch (status) {
        case ConfirmationTaskEnum.CONFIRM_EMAIL: {
          this.$_changeState(ConfirmationTaskEnum.CONFIRM_PASSWORD_HASH_CODE);
          break;
        }
        case ConfirmationTaskEnum.PROCESSED:
          this.$_changeState(ConfirmationTaskEnum.ALREADY_USED_LINK);
          break;
        case ConfirmationTaskEnum.EXPIRED_LINK:
          this.$_changeState(ConfirmationTaskEnum.EXPIRED_LINK);
          break;
        default:
          logger.warn('Confirmation code was not found or is expired');
          break;
      }
    },
    resetPasswordEventHandler(payload) {
      const { type, data } = payload;
      switch (type) {
        case ResetPasswordEvent.PASSWORD_CHANGED:
          this.$store.dispatch('ManagePopupStore/openPopup', {
            name: PopupNamesEnum.CONNECT,
            popupContext: {}
          });
          this.$_close();
          break;
        case ResetPasswordEvent.ERROR:
          this.$_errorHandler(data.error);
          break;
        default:
          break;
      }
    },
    $_errorHandler(error) {
      const text = this.$t('ConfirmationPopup.error');
      this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
      this.delayClose();
      logger.error(`Get error on confirmation task error ${error}`);
    },
    delayClose() {
      setTimeout(() => {
        this.$_close();
      }, 4000);
    },
    $_getConfirmationTaskPopupState() {
      const state = this.currentState;
      const popupState = PopupStateFactory.createState();
      popupState.setHeaderTitle('').setContentImage(images.picAccept);

      switch (state) {
        case ConfirmationTaskEnum.EMPTY:
          popupState.setContentTitle('');
          break;
        case ConfirmationTaskEnum.CONFIRM_EMAIL:
          popupState.setContentTitle(
            this.$t('RegisterUserProfile.label.emailConfirmationMessage')
          );
          break;
        case ConfirmationTaskEnum.EXPIRED_LINK:
          popupState.setContentTitle(this.$t('Confirmation.linkExpired'));
          break;
        case ConfirmationTaskEnum.ALREADY_USED_LINK:
          popupState.setContentTitle(this.$t('Confirmation.linkAlreadyUsed'));
          break;
        case ConfirmationTaskEnum.NOT_FOUND:
          popupState.setContentTitle(
            this.$t('server.auth.hashCode.notFound.error')
          );
          break;
        case ConfirmationTaskEnum.CONFIRM_PROFILE_HASH_CODE:
          popupState
            .setContentTitle(
              this.$t('RegisterUserProfile.label.emailConfirmationMessage')
            )
            .setPrimaryLabel(
              this.$t('Confirmation.confirmProfileHashCode.button.label')
            )
            .setPrimaryFooterHandler(this.openLibrary);
          this.delayClose();
          break;
        case ConfirmationTaskEnum.CONFIRM_PASSWORD_HASH_CODE:
          popupState.setComponent(ResetPassword);
          break;
        default:
          break;
      }
      return popupState;
    }
  }
};
</script>

<style src="./ConfirmationTaskPopup.less" lang="less"></style>
