const state = {
  Context: {}
};

function init(_context) {
  state.Context = _context;
}

function getStudyProjectConfig() {
  return state.Context.parameters.studyProjectConfig;
}

function getDaysOfWeek() {
  return state.Context.parameters.daysOfWeek;
}

function getMembershipStatus() {
  return state.Context.parameters.studyProjectConfig.membershipStatus;
}

function getCancelledState() {
  return state.Context.parameters.studyProjectConfig.studyClassStatus.cancelled;
}

export default {
  init,
  getStudyProjectConfig,
  getDaysOfWeek,
  getMembershipStatus,
  getCancelledState
};
