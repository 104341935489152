import get from 'lodash/get';

import ClientNodeContext from '@/context/ClientNodeContext';

function openPopup(Context, needBrowserUpdate) {
  const brand = get(Context, 'parameters.brand', '').toLowerCase();
  const artifactsUrls = getArtifactsUrl(Context, brand);
  const openContext = {
    needBrowserUpdate,
    isProd: Context.parameters.isProd,
    browserName: ClientNodeContext.userAgentParams.browser.name,
    brandName: Context.parameters.brandName,
    customClass: `${brand}-icon`,
    os: ClientNodeContext.os,
    artifactsUrls: artifactsUrls,
    storeLink: {
      ios: Context.parameters.iosStoreUrl,
      android: Context.parameters.androidStoreUrl
    },
    portalPageUrl: Context.parameters.portalPageUrl
  };
  window.openUnsupportedBrowserPopup(openContext);
}

function getArtifactsUrl(Context, brand) {
  try {
    const artifactsUrls = get(Context, `parameters[${brand}].artifacts`, {});
    return artifactsUrls;
  } catch (error) {
    // eslint-disable-next-line no-console
  }
  return {};
}

export default {
  openPopup
};
