<template>
  <Popup
    content-class="popup-language"
    hide-overlay
    width="588"
    :header-divider="false"
    :footer-divider="false"
    @popupEvent="eventHandler"
  >
    <template #custom-content>
      <v-card-text>
        <div class="content-default-width">
          <div class="language-title">Choose interface language</div>
          <div class="language-list">
            <div
              v-for="language in languageOptions"
              :key="language.name"
              class="language-list-item"
            >
              <BaseButton
                v-bind="language.getAttrs(selectedLanguage)"
                block
                x-large
                elevation="0"
                @click="changeLanguage(language)"
              >
                {{ language.title }}
              </BaseButton>
            </div>
          </div>
        </div>
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import Popup from '@/components/base/Popup/Popup.vue';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';

import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LanguageSelectionControl.vue');

class LanguageSelectionControl {
  constructor(languageOptions) {
    this.title = languageOptions.title;
    this.name = languageOptions.name;
  }

  getAttrs(selectedLanguage) {
    return this.name === selectedLanguage?.name
      ? { color: 'primary' }
      : { outlined: true };
  }
}

export default {
  name: PopupNamesEnum.LANGUAGE_POPUP,
  components: {
    Popup,
    BaseButton
  },
  data() {
    const supportedLangs = this.$store.getters[
      'ContextStore/supportedLanguagesByName'
    ];

    return {
      selectedLanguage: null,
      supportedLangs,
      languageOptions: supportedLangs.map(this.$_createLanguageControl)
    };
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    }
  },
  mounted() {
    this.createOptions();
  },
  methods: {
    eventHandler() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.LANGUAGE_POPUP }
      });
    },
    changeLanguage(lang) {
      GoogleAnalyticsUtils.trackEvent('preferences', 'changeLanguage');
      this.$store.dispatch('ContextStore/changeSystemLanguage', {
        lang: lang.name
      });
      this.selectedLanguage = lang;
    },

    createOptions() {
      try {
        const systemLanguage = this.$store.getters[
          'ContextStore/getSystemLanguage'
        ];
        const selectedLang = this.$_findSelectedLang(
          this.supportedLangs,
          systemLanguage
        );
        this.selectedLanguage = this.$_createLanguageControl(selectedLang);
      } catch (error) {
        logger.error(`get error on create lang options error ${error}`);
        const defaultSupportedLang = { name: 'en', title: 'English' };
        this.languageOptions = [
          this.$_createLanguageControl(defaultSupportedLang)
        ];
      }
    },
    $_createLanguageControl(lang) {
      return new LanguageSelectionControl(lang);
    },
    $_findSelectedLang(supportedLangs, systemLanguage) {
      return (
        supportedLangs.find(
          supportedLang => supportedLang.name === systemLanguage
        ) || supportedLangs[0]
      );
    }
  }
};
</script>

<style src="./LanguagePopup.less" lang="less"></style>
