<template>
  <BaseButton
    elevation="0"
    class="mini-navigate-icon"
    fab
    outlined
    @click="toCompilation"
  >
    <BaseSpriteIcon icon-name="ico-extract"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToCompilationIconControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  methods: {
    toCompilation() {
      this.$router.push({ name: AppStateEnum.MANAGE_COMPILATION });
    }
  }
};
</script>
