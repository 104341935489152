export default {
  ERROR: 'bookScrollError',
  ERROR_EMPTY_CONTENT: 'bookScrollErrorEmptyContent',
  METABLOCK_ERROR: 'metablockError',
  METABLOCK_EVENT: 'metablockEvent',
  UPDATED_BOOK: 'updatedBook',
  ON_ITEM_CLICK: 'onItemClick',
  ON_READING_AREA_CHANGE: 'onReadingAreaChange',
  OPEN_COMPILATION_EDIT_MENU: 'openCompilationEditMenu',
  MATERIAL_EVENT: 'materialEvent',
  REOPEN_ON_ROTATE: 'reopenOnRotate',
  DATA_FETCHED: 'dataFetched',
  // START_PREP_PARA: 'startPrepareParagraph',
  // END_PREP_PARA: 'endPrepareParagraph',
  STABLE: 'stable',
  STOP: 'stop',
  COPY_EVENT: 'copyHotkeyEvent',
  PLAY_PARA: 'playParagraph',
  SPACE_PRESSED_EVENT: 'spaceHotkeyEvent'
};
