'use strict';

import arabicUtils from './arabic/arabicUtils.mjs';
import farsiWordsUtils from './farsi/farsiUtils.mjs';
import englishUtils from './english/englishUtils.mjs';

function getStopwordsByLang(lang) {
  if (lang === 'en') {
    return englishUtils.getStopWordsList();
  } else if (lang === 'ar') {
    return farsiWordsUtils.getStopWordsList();
  } else if (lang === 'fa') {
    return arabicUtils.getStopWordsList();
  }
}

function filterByStopWords(words, lang) {
  var stopWords = getStopwordsByLang(lang);
  return words.filter(function(rawWord) {
    var word = replaceDiacritic(rawWord.toLowerCase(), lang);
    return !stopWords.hasOwnProperty(word);
  });
}

function tokenizing(sentence, lang) {
  if (lang === 'en') {
    sentence = englishUtils.tokenizer(sentence);
  } else if (lang === 'fa') {
    sentence = farsiWordsUtils.tokenizer(sentence);
  } else if (lang === 'ar') {
    sentence = arabicUtils.tokenizer(sentence);
  }

  return sentence;
}

function replaceDiacritic(sentence, lang) {
  if (lang === 'en') {
    sentence = englishUtils.replaceDiacritic(sentence);
  } else if (lang === 'fa') {
    sentence = farsiWordsUtils.replaceDiacritic(sentence);
  } else if (lang === 'ar') {
    sentence = arabicUtils.replaceDiacritic(sentence);
  }
  return sentence;
}

function stemming(token, lang) {
  var stem;
  if (lang === 'en') {
    stem = englishUtils.stemmer(token);
  } else if (lang === 'fa') {
    stem = farsiWordsUtils.stemmer(token);
  } else if (lang === 'ar') {
    stem = arabicUtils.stemmer(token);
  }

  return stem;
}

function getPhoneme(stem, lang) {
  var phoneme;
  if (lang === 'en') {
    phoneme = englishUtils.soundEx(stem);
  } else if (lang === 'fa') {
    phoneme = farsiWordsUtils.soundEx(stem);
  } else if (lang === 'ar') {
    phoneme = arabicUtils.soundEx(stem);
  }

  return phoneme;
}

const languagesConfig = {
  ar: 'Arabic',
  fa: 'Arabic',
  fr: 'Latin',
  en: 'Latin',
  defaultLang: 'en'
};

export default {
  generateWords: arabicUtils.generateWords,
  filterByStopWords: filterByStopWords,
  tokenizing: tokenizing,
  replaceDiacritic: replaceDiacritic,
  stemming: stemming,
  getPhoneme: getPhoneme,
  languagesConfig
};
