<template>
  <span class="visibility-checker" />
</template>
<script>
export default {
  name: 'VisibilityChecker',
  mounted() {
    window.addEventListener('visibilitychange', this.onVisibilityChange);
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.onVisibilityChange);
  },
  methods: {
    onVisibilityChange() {
      this.$store.commit(
        'ContextStore/setWebBackgroundMode',
        document.visibilityState !== 'visible'
      );
    }
  }
};
</script>

<style scoped>
.visibility-checker {
  display: none;
}
</style>
