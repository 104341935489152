import DB from '@/services/Agent/dao/DB';

export default {
  POST: {},
  GET: {
    getStudyCourses
  }
};

async function getStudyCourses() {
  await DB.query().getAllByPrefix(DB.prefix.course);
}
