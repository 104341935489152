import get from 'lodash/get';
import debounce from 'lodash/debounce';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';

import searchHighlighter from '@shared/publication/search-highlighter';

function highlightStems(stems, element) {
  if (!stems || stems.length === 0) {
    return;
  }
  searchHighlighter.decorateSearchWords(
    stems,
    element,
    AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS
  );
}

function highlightQuotes(quotes, element) {
  if (!quotes || quotes.length == 0) {
    return;
  }
  searchHighlighter.decorateSearchQuotes(
    quotes,
    element,
    AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS
  );
}

function highlightSelection(selectionHighlight, element) {
  if (!selectionHighlight) {
    return;
  }
  const stableOffsets = [
    [
      selectionHighlight.start.logicalCharOffset,
      selectionHighlight.end.logicalCharOffset
    ]
  ];
  searchHighlighter.decorateSearchWordsByOffsets(
    stableOffsets,
    element,
    AppConstantsUtil.SENTENCE_DECORATOR_CLASS
  );
}

function undecorateSearchResults(element) {
  searchHighlighter.undecorateByClass(
    AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS,
    element
  );
  searchHighlighter.undecorateByClass(
    AppConstantsUtil.SENTENCE_DECORATOR_CLASS,
    element
  );
}

const debHighlight = debounce(function(items, stems, quotes) {
  const element = window.document.createElement('div');
  items.forEach(function(item) {
    element.innerHTML = item.paragraph;
    stems.forEach(function(stem) {
      highlightStems(stem, element);
    });
    highlightQuotes(quotes, element);
    item.paragraphHighlight = element.innerHTML;
  });
}, 100);

function viewportChanged(context) {
  const oldHeight = get(context, 'oldViewport.height', null);
  const newHeight = get(context, 'viewport.height', null);

  const oldWidth = get(context, 'oldViewport.width', null);
  const newWidth = get(context, 'viewport.width', null);

  const wightChanged = oldWidth && newWidth && newWidth !== oldWidth;
  const heightChanged = oldHeight && newHeight && newHeight !== oldHeight;
  return wightChanged || heightChanged;
}

function getStartIndex(paragraphs, paraNum, firstContentParagraph) {
  let startIndex = 0;
  if (_isInTheBeginOrEnd(paragraphs, paraNum)) {
    startIndex =
      paragraphs[0].id === firstContentParagraph ? 0 : paragraphs.length - 1;
  }
  if (_isCloseToTheStartOrEnd(paragraphs, paraNum)) {
    startIndex =
      paragraphs[0].id === firstContentParagraph
        ? paragraphs.length - paraNum
        : paraNum;
  }
  if (_isInTheBookMiddle(paragraphs, paraNum)) {
    startIndex = paragraphs.length / 2;
  }
  return startIndex;
}

function getStartIndexByParaId(paragraphs, paraId) {
  const startIndex = paragraphs.findIndex(paragraph => paragraph.id === paraId);
  return Math.max(startIndex, 0);
}

function _isInTheBookMiddle(paragraphs, paraNum) {
  return paragraphs.length === paraNum * 2;
}

function _isInTheBeginOrEnd(paragraphs, paraNum) {
  return paragraphs.length === paraNum;
}

function _isCloseToTheStartOrEnd(paragraphs, paraNum) {
  return paragraphs.length > paraNum && paragraphs.length < paraNum * 2;
}

//based on https://gist.github.com/andjosh/6764939
function animatedScroll(animationElementSelector, offset, durationMs) {
  const element = document.querySelector(animationElementSelector);
  let animateScroll;
  const promise = new Promise((resolve, reject) => {
    animateScroll = scrollTo(
      element,
      offset,
      durationMs,
      function complete() {
        resolve({
          isAnimated: true
        });
      },
      reject
    );
  });
  return {
    promise,
    animateScroll
  };
}

function animatedScrollToSelector(scrollSelector, elementSelector) {
  const SCROLL_SPEED = 7;
  const scrollTop = getScrollTop(scrollSelector);
  const top = _getElementTop(elementSelector);
  const animationElementSelector = scrollSelector;
  const elementPosition =
    top -
    SelectionConstantUtils.TOOLBAR_HEIGHT -
    AppConstantsUtil.LIBRARY_TOOLBAR_HEIGHT -
    1;
  const delta = scrollTop + elementPosition;
  const offset = delta < 5 ? elementPosition : delta;
  const delayMs = Math.abs(Math.round(offset / SCROLL_SPEED));
  return animatedScroll(animationElementSelector, offset, delayMs);
}

function _getElementTop(elementSelector) {
  const element = document.querySelector(elementSelector);
  const rect = element.getBoundingClientRect();
  return rect.y;
}

function getScrollTop(scrollSelector) {
  const scroll = _getScroll(scrollSelector);
  if (scroll) {
    return scroll.scrollTop;
  }
  return null;
}

function _getScroll(scrollSelector) {
  return document.querySelector(scrollSelector);
}

function scrollTo(element, to, duration, completeCallBack, failCallBack) {
  try {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = function() {
      if (currentTime !== Infinity) {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
      }
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
        return;
      }
      completeCallBack();
    };

    const stop = function() {
      currentTime = Infinity;
    };

    animateScroll();

    return {
      stop
    };
  } catch (error) {
    failCallBack(error);
  }
}

//t = current time
//b = start value
//c = change in value
//d = duration
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

export default {
  highlightStems,
  highlightQuotes,
  highlightSelection,
  undecorateSearchResults,
  debHighlight,
  viewportChanged,
  getStartIndex,
  getStartIndexByParaId,
  getScrollTop,
  animatedScroll,
  animatedScrollToSelector
};
