import DB from '@/services/Agent/dao/DB';

const DEFAULT_COURSE_ID = 'userdefault';

async function getAll() {
  return DB.userRW().getAllByPrefix(DB.prefix.usernotes);
}

/**
 *
 * @param {String} publicationId
 * @param {String} [courseId]
 */
async function get(publicationId, courseId) {
  const docId = _getDocId(publicationId, courseId);
  return DB.userRW().get(docId);
}

/**
 *
 * @param {Object} annotations
 */
function write(annotations) {
  return DB.userRW().put(annotations);
}

/**
 *
 * @param {String} publicationId
 * @param {String} [courseId]
 */
function getDefaultDoc(publicationId, courseId) {
  return {
    _id: _getDocId(publicationId, courseId),
    type: 'usernotes',
    content: {
      title: undefined,
      cover: undefined,
      pubId: publicationId,
      courseId: courseId || false
    },
    notes: [],
    tags: []
  };
}

/**
 *
 * @param {String} publicationId
 * @param {String} [courseId]
 */
function _getDocId(publicationId, courseId = DEFAULT_COURSE_ID) {
  return DB.id.usernotes(`${publicationId}-${courseId}`);
}

export default {
  getAll,
  get,
  write,
  getDefaultDoc
};
