import AppModes from '@/enums/AppModeEnum';
class MaterialReqParams {
  constructor(params) {
    this.editor = params.appMode === AppModes.EDITOR;
    this.bookId = params.bookId;
    this.classId = params.classId;
    this.isDownloaded = true;
    this.studyGuide = false;
    this.materials = null;
  }

  setMaterials(materials) {
    this.materials = materials;
  }

  toJSON() {
    return {
      editor: this.editor,
      bookId: this.bookId,
      classId: this.classId,
      isDownloaded: this.isDownloaded,
      studyGuide: this.studyGuide,
      materials: this.materials
    };
  }

  getContentProviderParams() {
    return new ContentProviderReqParams(this);
  }
}

class ContentProviderReqParams {
  constructor(params) {
    this.editor = params.editor;
    this.publicationId = params.bookId;
    this.isDownloaded = params.isDownloaded;
  }
}

class MaterialReqParamsBuilder {
  setAppMode(appMode) {
    this.appMode = appMode;
    return this;
  }

  setBookId(bookId) {
    this.bookId = bookId;
    return this;
  }

  setClassId(classId) {
    this.classId = classId;
    return this;
  }

  build() {
    return new MaterialReqParams(this);
  }
}

function create({ isEditor, bookId, classId }) {
  return new MaterialReqParams(isEditor, bookId, classId);
}

function createBuilder() {
  return new MaterialReqParamsBuilder();
}

export default {
  create,
  createBuilder
};
