import dayJS from '@/dayJS';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LibraryStoreService.js');

import RestService from '@/services/RestService';

const persistCampaign = async campaign => {
  try {
    const response = await RestService.restRequest(
      'post',
      'Campaigns',
      'persistCampaign',
      { campaign }
    );
    return response.data;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

const getCampaignsListByUserId = async userId => {
  try {
    const response = await RestService.restRequest(
      'get',
      'Campaigns',
      'getCampaignsListByUserId',
      { userId }
    );
    return response.data;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

const updateCampaignsWithGoogleAnalyticsData = async () => {
  try {
    const response = await RestService.restRequest(
      'post',
      'Campaigns',
      'updateCampaignsWithGoogleAnalyticsData'
    );
    return response.data;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

const getUsersListHasCampaigns = async () => {
  try {
    const response = await RestService.restRequest(
      'get',
      'Campaigns',
      'getUsersListHasCampaigns'
    );
    return response.data;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

const getTotalUsersCount = usersByDates => {
  return Object.keys(usersByDates).reduce((totalUsers, date) => {
    totalUsers += usersByDates[+date];
    return totalUsers;
  }, 0);
};

const getUsersCountByTimeSpanInDays = (usersByDates, timeSpanInDays) => {
  const startDate = dayJS
    .get()
    .subtract(timeSpanInDays, 'day')
    .valueOf();

  return Object.keys(usersByDates).reduce((totalUsers, date) => {
    if (date >= startDate) {
      totalUsers += usersByDates[+date];
    }
    return totalUsers;
  }, 0);
};

const getFormattedDate = timeInMs => {
  return timeInMs ? dayJS.get(timeInMs).format('DD MMM YYYY') : '';
};

export default {
  persistCampaign,
  getCampaignsListByUserId,
  updateCampaignsWithGoogleAnalyticsData,
  getUsersListHasCampaigns,

  getTotalUsersCount,
  getUsersCountByTimeSpanInDays,
  getFormattedDate
};
