'use strict';

import englishStopWords from './englishStopWords.mjs';
import englishWordsUtils from './englishWordsUtils.mjs';
var exp = {};
[englishStopWords, englishWordsUtils].forEach(function(module) {
  Object.keys(module).forEach(function(methodName) {
    exp[methodName] = module[methodName];
  });
});

export default exp;
