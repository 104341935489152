// import FilesHandlingFs from './FilesHandlingFs';
import FilesHandlingSdCard from './FilesHandlingSdCard';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';

import { FilesHandlingRemote } from '@/classes/factories/AssetReader/FilesHandlingRemote';
import { FilesHandlingFs } from '@/classes/factories/AssetReader/FilesHandlingFs';
import { FilesHandlingTempFs } from '@/classes/factories/AssetReader/FilesHandlingTempFs';
import { FilesHandlingLocalDb } from '@/classes/factories/AssetReader/FilesHandlingLocalDb';
const filesHandlingFs = new FilesHandlingFs();
const filesHandlingTemp = new FilesHandlingTempFs();
const filesHandlingRemote = new FilesHandlingRemote();
const filesHandlingLocalDb = new FilesHandlingLocalDb();

function init(Context, runId) {
  return Promise.all([
    filesHandlingRemote.init(Context, runId),
    filesHandlingFs.init(),
    filesHandlingTemp.init(),
    FilesHandlingSdCard.init(),
    filesHandlingLocalDb.init()
  ]);
}

function setRunId(runId) {
  filesHandlingRemote.setRunId(runId);
}

async function getFileAssetDriver(source) {
  const fileAssetDriver = _getFileAssetDriverBySource(source);
  const available = await fileAssetDriver.isAvailable();
  if (!available) {
    throw fileAssetDriver.getUnavailableError();
  }
  return fileAssetDriver;
}

function _getFileAssetDriverBySource(source) {
  let fileAssetDriver = null;
  switch (source) {
    case AssetResourcesEnum.REMOTE:
      fileAssetDriver = filesHandlingRemote;
      break;
    case AssetResourcesEnum.FS:
      fileAssetDriver = filesHandlingFs;
      break;
    case AssetResourcesEnum.FS_TEMP:
      fileAssetDriver = filesHandlingTemp;
      break;
    case AssetResourcesEnum.SD_CARD:
      fileAssetDriver = FilesHandlingSdCard;
      break;
    case AssetResourcesEnum.LOCAL_DB:
      fileAssetDriver = filesHandlingLocalDb;
      break;
    default:
      throw new Error(
        `Get unsupported accessType: ${source} in getFileAssetDriver`
      );
  }
  return fileAssetDriver;
}

function isAvailable(fileAccess) {
  const fileAssetDriver = _getFileAssetDriverBySource(fileAccess.source);
  return fileAssetDriver.isAvailable();
}

async function readFile(fileAccess, readOptions) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.readFile(fileAccess.path, readOptions);
}

async function writeFile(fileAccess, data) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.writeFile(fileAccess.path, data);
}

async function isExist(fileAccess) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.isExist(fileAccess.path);
}

async function getInternalMemory(fileAccess, excludeEntries) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.getInternalMemory(excludeEntries);
}

async function getFileInfo(fileAccess) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.getFileInfo(fileAccess.path);
}

async function removeAll(fileAccess, excludeEntries) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.removeAll(excludeEntries);
}

function createFileSourcePath(fileAccess, readOptions) {
  const fileAssetDriver = _getFileAssetDriverBySource(fileAccess.source);
  return fileAssetDriver.createFileSourcePath(
    fileAccess.path,
    readOptions.accessType
  );
}

async function removeFile(fileAccess) {
  const fileAssetDriver = await getFileAssetDriver(fileAccess.source);
  return fileAssetDriver.removeFile(fileAccess.path);
}

export default {
  init,
  setRunId,
  isAvailable,
  readFile,
  writeFile,
  isExist,
  removeFile,
  createFileSourcePath,
  getInternalMemory,
  removeAll,
  getFileInfo,
  getFileAssetDriver
};
