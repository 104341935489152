<script>
import Sizeable from 'vuetify/lib/mixins/sizeable';

export default {
  name: 'SizeableOverrides',
  extends: Sizeable,
  props: {
    xxLarge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizeableClasses() {
      return {
        'v-size--x-small': this.xSmall,
        'v-size--small': this.small,
        'v-size--default': this.medium && !this.xxLarge,
        'v-size--large': this.large,
        'v-size--x-large': this.xLarge,
        'v-size--xx-large': this.xxLarge
      };
    }
  }
};
</script>
