import { localize as $t } from '@/i18n';
import last from 'lodash/last';

import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';

import PublicationService from '@/services/PublicationService';
import PromiseUtil from '@/services/utils/PromiseUtil';
import VocabularyAssessmentService from '@/services/Assessment/VocabularyAssessmentService';

import AssessmentSettingsFactory from '@/classes/factories/Assessment/AssessmentSettingsFactory';
import AssessmentViewFactory from '@/classes/factories/Assessment/AssessmentViewFactory';

import AssessmentTypes from '@/enums/AssessmentTypes';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('VocabularyAssessmentStore.js');

const SUCCESS_LIMIT_PERCENTS = 80;
const TIMER_FOR_ANSWER = 30000;
const RETRY_GET_QUESTIONS_INTERVAL = 5 * 1000;
const MAX_INTEGER_LEVEL = 12;
const MAX_LEVEL = 12.9;

/**
 * Due to requirements:
 * https://isddesign.atlassian.net/wiki/spaces/FFA/pages/893059417/User+vocabulary+assessment
 */
const USER_LEVEL_BY_CORPUS = {
  1: [1],
  2: [1],
  3: [1],
  4: [2],
  5: [2],
  6: [3],
  7: [4],
  8: [5],
  9: [6],
  10: [7, 8],
  11: [9, 10],
  12: [11],
  13: [12],
  14: [12]
};
const AMOUNT_OF_QUESTIONS_BY_CORPUS = {
  1: 4,
  2: 3,
  3: 3,
  4: 5,
  5: 5,
  6: 10,
  7: 10,
  8: 10,
  9: 10,
  10: 10,
  11: 10,
  12: 10,
  13: 5,
  14: 5
};

const AMOUNT_OF_QUESTIONS_PER_GRADE_LEVEL = {
  1: 10,
  2: 10,
  3: 10,
  4: 10,
  5: 10,
  6: 10,
  7: 5,
  8: 5,
  9: 5,
  10: 5,
  11: 10,
  12: 10
};

const DEFAULT_START_LEVEL = 1;
const DEFAULT_START_BOOK_LEVEL = 1;

const NON_CLEARABLE_STATE_FIELDS = ['allUserResults', 'latestResult'];

const initialState = {
  currentCorpusLevel: DEFAULT_START_LEVEL,
  startLevel: DEFAULT_START_LEVEL,
  currentBookLevel: DEFAULT_START_BOOK_LEVEL,
  startBookLevel: DEFAULT_START_BOOK_LEVEL,
  shouldStopAssessment: false,
  isEndOfLevelByCorrectAnswers: false,
  masteryLevel: 0,
  correctAnswersByBookLevel: 0,
  answersAmountByBookLevel: 0,
  currentAssessment: null,
  currentAssessmentState: null,
  isFirstQuestion: false,
  currentQuestion: {},
  assessmentPageHeader: null,
  latestResult: null,
  isAssessmentInProgress: false,
  allUserResults: []
};

const initState = () => ({ ...initialState });

const storeGetters = {
  getSettings() {
    const assessmentSettingsBuilder = AssessmentSettingsFactory.createSettingsBuilder();
    const assessmentSettings = assessmentSettingsBuilder
      .setIsWithTimer(true)
      .setIsShownAssessmentProgress(true)
      .setMaxWidth('395')
      .setTimeForAnswer(TIMER_FOR_ANSWER)
      .build();
    return assessmentSettings;
  },
  getCurrentTestType() {
    return AssessmentTypes.VOCABULARY_ASSESSMENT;
  },
  getTestView: state => {
    return state.currentAssessment;
  },
  getCurrentQuestion: state => {
    if (state.isFirstQuestion) {
      const assessment = state.currentAssessment;
      return assessment.testQuestions[0];
    }
    return state.currentQuestion;
  },
  getIsLastQuestion: state => {
    return !state.currentQuestion && !state.isFirstQuestion;
  },
  getQuestionsCountLabel: (state, getters) => {
    const totalQuestionsAmount = getters.getQuestionsCountPerBookLevel;
    if (getters.getIsLastQuestion) {
      return totalQuestionsAmount + '/' + totalQuestionsAmount;
    }
    return state.answersAmountByBookLevel + '/' + totalQuestionsAmount;
  },
  getCorrectAnswersCountLabel: (state, getters) => {
    const correctAnswers = state.correctAnswersByBookLevel;
    const totalQuestionsAmount = getters.getQuestionsCountPerBookLevel;
    return `${correctAnswers} / ${totalQuestionsAmount}`;
  },
  getHeaderTitle() {
    return $t('VocabularyAssessment.title');
  },
  getImage() {
    return null;
  },
  getAudio() {
    return null;
  },
  getShouldStopAssessment(state) {
    return state.shouldStopAssessment;
  },
  getUserBookLevel: state => {
    return state.latestResult?.bookLevel;
  },
  getPassedBookLevel(state) {
    return state.currentBookLevel > MAX_INTEGER_LEVEL
      ? MAX_INTEGER_LEVEL
      : state.currentBookLevel || 1;
  },
  getMasteryLevel(state) {
    return state.masteryLevel;
  },
  isSuccessfullyPassed(state, getters) {
    const passedBookLevels = getters.getPassedBookLevel;
    return passedBookLevels > state.startBookLevel;
  },
  getAssessmentLevelLabel(state) {
    let currentBookLevel =
      state.currentBookLevel > MAX_INTEGER_LEVEL
        ? MAX_INTEGER_LEVEL
        : state.currentBookLevel;
    return `${currentBookLevel}/${getMaxIntegerLevel()}`;
  },
  getMinCorrectAnswersPerBookLevel(state, getters) {
    return (
      getters.getQuestionsCountPerBookLevel * (SUCCESS_LIMIT_PERCENTS / 100)
    );
  },
  getMaxLevel() {
    return getMaxIntegerLevel() + 0.9;
  },
  getMaxIntegerLevel() {
    return getMaxIntegerLevel();
  },
  isMaxBookLevel(state, getters) {
    if (!Array.isArray(state.allUserResults)) {
      return false;
    }
    const sortedUsersResults = [...state.allUserResults];
    sortedUsersResults.sort((x, y) => x.passedAt - y.passedAt);

    const lastResult = last(sortedUsersResults);
    return (
      lastResult &&
      lastResult.completed &&
      lastResult.bookLevel === getters.getMaxLevel
    );
  },
  getIsEndOfLevel(state, getters) {
    return (
      state.isEndOfLevelByCorrectAnswers ||
      state.answersAmountByBookLevel === getters.getQuestionsCountPerBookLevel
    );
  },
  getCalculatedBookLevel(state, getters) {
    let integerPart = state.currentBookLevel;
    integerPart = Math.max(integerPart, 1);
    if (integerPart > MAX_LEVEL) {
      return MAX_LEVEL;
    }

    let minCorrectAnswersByLevel =
      getters.getQuestionsCountPerBookLevel * (SUCCESS_LIMIT_PERCENTS / 100);
    if (minCorrectAnswersByLevel === 0) {
      minCorrectAnswersByLevel = 1;
    }

    let fractionalPart =
      Math.floor(
        (state.correctAnswersByBookLevel / minCorrectAnswersByLevel) * 100
      ) / 100;
    fractionalPart = fractionalPart > 1 ? 1 : fractionalPart;

    return integerPart + fractionalPart;
  },
  getQuestionsCountPerBookLevel(state) {
    const currentCorpusLevel = state.currentCorpusLevel;
    return AMOUNT_OF_QUESTIONS_PER_GRADE_LEVEL[
      USER_LEVEL_BY_CORPUS[currentCorpusLevel][0]
    ];
  },
  getAllUserResults(state) {
    return state.allUserResults;
  },
  getFinishedLevelsUserResults(state, getters) {
    if (!Array.isArray(state.allUserResults)) {
      return [];
    }

    return state.allUserResults.filter(
      userResult =>
        (userResult.completed === false &&
          userResult.completedMandatoryQuestions === true) ||
        (userResult.completed && userResult.bookLevel === getters.getMaxLevel)
    );
  },
  getLatestCompletedResult(state) {
    return state.latestResult || last(state.allUserResults);
  },
  getAssessmentPageHeader: state => {
    return state.assessmentPageHeader;
  },
  getCurrentAssessmentState: state => {
    return state.currentAssessmentState;
  }
};

const actions = {
  async initAssessment({ state, commit, dispatch }) {
    if (state.isAssessmentInProgress) {
      return;
    }

    const latestResult = state.latestResult || {};
    const isLastLevelPassed =
      latestResult.completed && latestResult.completedMandatoryQuestions;
    let bookLevel = latestResult.bookLevel;
    const startBookLevel = bookLevel ? parseInt(bookLevel) : 1;
    dispatch('setStartBookLevel', { startBookLevel, isLastLevelPassed });

    const assessmentInfo = await dispatch('getAssessmentInfo');
    commit('setCurrentAssessment', assessmentInfo);
    commit('setIsAssessmentInProgress', true);
    commit('setIsFirstQuestion', true);
  },
  clearAssessment({ commit }) {
    commit('clearAssessment');
  },
  async getAssessmentInfo({ state }) {
    let testQuestions = [];
    try {
      testQuestions = await getQuestionsByLevel(state.startLevel);
    } catch (err) {
      logger.error('Couldn`t get questions');
    }
    return { testQuestions };
  },

  async finishAssessment(
    { state, getters, commit, dispatch },
    { completed, completedMandatoryQuestions, assessmentInfo, manualStop }
  ) {
    if (manualStop) {
      commit('setIsAssessmentInProgress', false);
    }

    try {
      const level = state.currentCorpusLevel;
      const bookLevel = getters.getCalculatedBookLevel;
      if (assessmentInfo) {
        dispatch('calcMasteryLevel', assessmentInfo);
      }
      const masteryLevel = state.masteryLevel;
      await VocabularyAssessmentService.saveAssessmentResult({
        level,
        bookLevel,
        masteryLevel,
        completed,
        completedMandatoryQuestions
      });

      if (manualStop) {
        commit('clearAnswersByBookLevel');
        commit('setShouldStopAssessment', false);
      }

      if (completedMandatoryQuestions) {
        await PublicationService.generateSuggestions();
      }
      await dispatch(
        'LibraryStore/reinitState',
        {
          managePublicationsState: ManagePublicationsStates.LIBRARY,
          forceRemote: true
        },
        { root: true }
      );
      return Promise.resolve();
    } catch (error) {
      const message = $t('AssessmentPlayer.internetError.label');
      dispatch(
        'ManagePopupStore/openErrorToaster',
        { message },
        { root: true }
      );
      logger.info(`Couldn't save assessment result. No internet connection`);
    }
  },
  async processCorrectAnswer({ state, commit, dispatch }) {
    if (state.isFirstQuestion) {
      commit('setIsFirstQuestion', false);
    }

    commit('increaseCorrectAnswersCount');
    commit('increaseCorrectAnswersByBookLevel');
    commit('increaseAnswersAmountByBookLevel');

    const isLevelPassed = await dispatch(
      'checkIsEndOfBookLevel',
      state.currentAssessment
    );
    await dispatch('saveResults', isLevelPassed);

    await dispatch('setNewTestQuestionsIfNeeded');
    dispatch('setNextQuestion');
  },
  async processIncorrectAnswer({ state, commit, dispatch }) {
    commit('increaseIncorrectAnswersCount');

    if (state.isFirstQuestion) {
      commit('setIsFirstQuestion', false);
    }

    commit('increaseAnswersAmountByBookLevel');
    const isLevelPassed = await dispatch(
      'checkIsEndOfBookLevel',
      state.currentAssessment
    );
    await dispatch('saveResults', isLevelPassed);
    await dispatch('setNewTestQuestionsIfNeeded');
    dispatch('setNextQuestion');
  },
  async setNewTestQuestionsIfNeeded({ getters, commit, dispatch }) {
    const shouldStopAssessment = getters.getShouldStopAssessment;
    if (shouldStopAssessment) {
      commit('setNewTestQuestions', []);
      return;
    }

    const newQuestions = await dispatch('getNewQuestionsIfNeeded');
    if (!newQuestions?.length) {
      return;
    }

    const isEndOfLevel = getters.getIsEndOfLevel;
    if (isEndOfLevel) {
      commit('clearCurrentAssessmentCount');
    }

    commit('setNewTestQuestions', newQuestions);
  },
  saveResults({ dispatch }, isLevelPassed) {
    if (isLevelPassed === undefined) {
      return;
    }

    if (isLevelPassed) {
      dispatch('finishAssessment', {
        completed: true,
        completedMandatoryQuestions: true
      });
      return;
    }
    dispatch('finishAssessment', {
      completed: false,
      completedMandatoryQuestions: true
    });
  },
  getAllUserResults() {
    return VocabularyAssessmentService.getAllUserResults();
  },
  async fetchAllUserResults({ state, commit, dispatch }) {
    if (!state.latestResult) {
      await dispatch('initLatestResult');
    }

    const allUsersResults = await VocabularyAssessmentService.getAllUserResults();
    commit('setAllUserResults', allUsersResults);
  },
  getNewQuestionsIfNeeded({ state, dispatch }) {
    const currentCorpusLevel = state.currentCorpusLevel;
    const assessmentInfo = state.currentAssessment;
    const isEndOfCorpusLevel = getIsEndOfCorpusLevel(
      assessmentInfo,
      currentCorpusLevel
    );
    const isEndOfLevel = state.isEndOfLevelByCorrectAnswers;
    if (isEndOfCorpusLevel || isEndOfLevel) {
      return dispatch('proccessEndOfLevel');
    }
  },
  async proccessEndOfLevel({ dispatch }) {
    try {
      const questions = await dispatch('goToNextLevel');
      return questions;
    } catch (err) {
      logger.error(`Error on process end of level: ${err}`);
      return [];
    }
  },
  async goToNextLevel({ state, dispatch }) {
    const currentLevel = state.currentCorpusLevel;
    const _isBookLevelLastInCorpus = isBookLevelLastInCorpus(
      state.currentBookLevel,
      currentLevel
    );
    let nextLevel;
    if (
      _isBookLevelLastInCorpus &&
      USER_LEVEL_BY_CORPUS[currentLevel].length > 1
    ) {
      nextLevel = currentLevel;
    } else {
      nextLevel = currentLevel + 1;
    }
    const availableLevels = Object.keys(USER_LEVEL_BY_CORPUS);
    if (!availableLevels.includes('' + nextLevel)) {
      return [];
    }
    const nextLevelQuestions = await dispatch(
      'getQuestionsWithRetry',
      nextLevel
    );

    if (nextLevelQuestions.length) {
      dispatch('setCurrentLevel', nextLevel);
    }
    return nextLevelQuestions;
  },
  async getQuestionsWithRetry({ state, dispatch }, level, count = 10) {
    if (!state.isAssessmentInProgress) {
      return [];
    }
    try {
      return await getQuestionsByLevel(level);
    } catch (err) {
      if (!count) {
        throw err;
      }
      count--;
      await PromiseUtil.wait(RETRY_GET_QUESTIONS_INTERVAL);
      return dispatch('getQuestionsWithRetry', level, count);
    }
  },
  async checkIsEndOfBookLevel(
    { state, commit, dispatch, getters },
    assessmentInfo
  ) {
    const isEndOfBookLevel =
      getters.getQuestionsCountPerBookLevel === state.answersAmountByBookLevel;
    const availableCorpuses = Object.keys(USER_LEVEL_BY_CORPUS);
    const isLastAvailableCorpus =
      +availableCorpuses[availableCorpuses.length - 1] ===
      state.currentCorpusLevel;

    const successfullyPassed = await dispatch('calcEndOfLevelByCorrectAnswers');
    dispatch('calcMasteryLevel', assessmentInfo);

    if (successfullyPassed) {
      dispatch('increaseBookLevel');
      if (state.currentBookLevel > MAX_INTEGER_LEVEL) {
        commit('setShouldStopAssessment', true);
      }
      return successfullyPassed;
    } else if (
      isEndOfBookLevel ||
      (isEndOfBookLevel && isLastAvailableCorpus)
    ) {
      commit('setShouldStopAssessment', true);
      return successfullyPassed;
    }
  },
  calcEndOfLevelByCorrectAnswers({ state, commit, getters }) {
    const correctAnswersPercent =
      (state.correctAnswersByBookLevel /
        getters.getQuestionsCountPerBookLevel) *
      100;
    const isEndOfLevelByCorrectAnswers =
      correctAnswersPercent >= SUCCESS_LIMIT_PERCENTS;
    commit('setEndOfLevelByCorrectAnswers', isEndOfLevelByCorrectAnswers);
    return isEndOfLevelByCorrectAnswers;
  },
  calcMasteryLevel({ state, commit }, assessmentInfo) {
    const masteryLevel =
      Math.trunc(state.currentBookLevel) * 1000 +
      assessmentInfo.correctAnswersCount * 100;
    commit('setMasteryLevel', masteryLevel);
  },
  updateBookLevelIfNeeded({ dispatch }, { prevCorpusLevel, newCorpusLevel }) {
    const prevBookLevels = USER_LEVEL_BY_CORPUS[prevCorpusLevel];
    let newBookLevel;
    if (prevCorpusLevel !== newCorpusLevel) {
      newBookLevel = USER_LEVEL_BY_CORPUS[newCorpusLevel][0];
    } else {
      newBookLevel =
        USER_LEVEL_BY_CORPUS[newCorpusLevel][
          USER_LEVEL_BY_CORPUS[newCorpusLevel].length - 1
        ];
    }
    const prevBookLevel = prevBookLevels[prevBookLevels.length - 1];
    const shouldSetNewBookLevel = newBookLevel !== prevBookLevel;
    if (shouldSetNewBookLevel) {
      dispatch('setBookLevel', newBookLevel);
    }
  },
  setBookLevel({ commit }, level) {
    commit('setCurrentBookLevel', level);
    commit('clearAnswersByBookLevel');
  },
  setCurrentLevel({ state, commit, dispatch }, level) {
    const prevCorpusLevel = state.currentCorpusLevel;
    commit('setCurrentCorpusLevel', level);
    dispatch('updateBookLevelIfNeeded', {
      prevCorpusLevel,
      newCorpusLevel: level
    });
  },
  setStartLevel({ commit }, startLevel) {
    commit('setCurrentCorpusLevel', startLevel);
    commit('setStartLevel', startLevel);
  },
  setStartBookLevel(
    { commit, dispatch },
    { startBookLevel, isLastLevelPassed }
  ) {
    commit('setCurrentBookLevel', startBookLevel);
    commit('setStartBookLevel', startBookLevel);

    let bookLevelForCorpus = startBookLevel;
    if (isLastLevelPassed && startBookLevel < MAX_INTEGER_LEVEL) {
      bookLevelForCorpus = startBookLevel + 1;
    }
    const startLevel = getStartCorpusLevelByBookLevel(bookLevelForCorpus);
    dispatch('setStartLevel', startLevel);
  },
  increaseBookLevel({ commit }) {
    commit('increaseCurrentBookLevel');
    commit('clearAnswersByBookLevel');
  },
  async initLatestResult({ commit }) {
    let result = await VocabularyAssessmentService.getLatestUserResult();
    let latestResult = result || {
      level: 1
    };
    commit('setLatestResult', latestResult);
  },
  setNextQuestion({ state, commit }) {
    const currentCorpusLevel = state.currentCorpusLevel;
    const nextQuestion = getNextQuestion(
      state.currentAssessment,
      currentCorpusLevel
    );
    commit('setCurrentQuestion', nextQuestion);
  },
  setAssessmentPageHeader({ commit }, header) {
    commit('setAssessmentPageHeader', header);
  },
  setCurrentAssessmentState({ commit }, currentAssessmentState) {
    commit('setCurrentAssessmentState', currentAssessmentState);
  }
};

const mutations = {
  clearAssessment(state) {
    Object.keys(state).forEach(key => {
      if (NON_CLEARABLE_STATE_FIELDS.includes(key)) {
        return;
      }
      state[key] = initialState[key];
    });
  },
  setCurrentCorpusLevel(state, corpusLevel) {
    state.currentCorpusLevel = corpusLevel;
  },
  setCurrentBookLevel(state, currentBookLevel) {
    state.currentBookLevel = currentBookLevel;
  },
  increaseCurrentBookLevel(state) {
    state.currentBookLevel++;
  },
  setStartLevel(state, startLevel) {
    state.startLevel = startLevel;
  },
  setStartBookLevel(state, startBookLevel) {
    state.startBookLevel = startBookLevel;
  },
  increaseAnswersAmountByBookLevel(state) {
    state.answersAmountByBookLevel++;
  },
  increaseCorrectAnswersByBookLevel(state) {
    state.correctAnswersByBookLevel++;
  },
  clearAnswersByBookLevel(state) {
    state.correctAnswersByBookLevel = 0;
    state.answersAmountByBookLevel = 0;
  },
  setMasteryLevel(state, masteryLevel) {
    state.masteryLevel = masteryLevel;
  },
  setEndOfLevelByCorrectAnswers(state, isEndOfLevelByCorrectAnswers) {
    state.isEndOfLevelByCorrectAnswers = isEndOfLevelByCorrectAnswers;
  },
  setShouldStopAssessment(state, shouldStopAssessment) {
    state.shouldStopAssessment = shouldStopAssessment;
  },
  setLatestResult(state, latestResult) {
    state.latestResult = latestResult;
  },
  setIsAssessmentInProgress(state, isInProgress) {
    state.isAssessmentInProgress = isInProgress;
  },
  setIsFirstQuestion(state, isFirstQuestion) {
    state.isFirstQuestion = isFirstQuestion;
  },
  setCurrentQuestion(state, currentQuestion) {
    state.currentQuestion = currentQuestion;
  },
  setAssessmentPageHeader(state, header) {
    state.assessmentPageHeader = header;
  },
  setAllUserResults(state, allUserResults) {
    state.allUserResults = allUserResults;
  },
  setCurrentAssessment(state, assessmentInfo) {
    const assessmentBuilder = AssessmentViewFactory.createTestAssessmentViewBuilder();
    state.currentAssessment = assessmentBuilder
      .setTestQuestions(
        assessmentInfo.testQuestions || assessmentInfo.questions
      )
      .setName(assessmentInfo.name)
      .setId(assessmentInfo._id)
      .build();
  },
  setCurrentAssessmentState(state, currentAssessmentState) {
    state.currentAssessmentState = currentAssessmentState;
  },
  clearCurrentAssessmentCount(state) {
    state.currentAssessment.clearAnswersCount();
  },
  setNewTestQuestions(state, newQuestions) {
    state.currentAssessment.setNewTestQuestions(newQuestions);
  },
  increaseCorrectAnswersCount(state) {
    state.currentAssessment.increaseCorrectAnswersCount();
  },
  increaseIncorrectAnswersCount(state) {
    state.currentAssessment.increaseIncorrectAnswersCount();
  }
};

async function getQuestionsByLevel(level) {
  return VocabularyAssessmentService.getBncTestsByLevel({
    level,
    amount: AMOUNT_OF_QUESTIONS_BY_CORPUS[level]
  });
}

function getMaxIntegerLevel() {
  const bookLevels = Object.values(USER_LEVEL_BY_CORPUS);
  const maxBookLevels = bookLevels[bookLevels.length - 1];
  return maxBookLevels[maxBookLevels.length - 1];
}

function getIsEndOfCorpusLevel(assessmentInfo, currentCorpusLevel) {
  const answeredQuestions = getAnsweredQuestions(
    assessmentInfo,
    currentCorpusLevel
  );
  const answeredQuestionsPreviousCorpusLevels = calculateAnsweredQuestions(
    currentCorpusLevel
  );
  return (
    assessmentInfo.testQuestionsCount ===
    answeredQuestions - answeredQuestionsPreviousCorpusLevels
  );
}

function getNextQuestion(assessmentInfo, currentCorpusLevel) {
  const answeredQuestionsByBookLevel = getAnsweredQuestions(
    assessmentInfo,
    currentCorpusLevel
  );
  const answeredQuestionsPreviousCorpusLevels = calculateAnsweredQuestions(
    currentCorpusLevel
  );
  let index =
    answeredQuestionsByBookLevel - answeredQuestionsPreviousCorpusLevels;
  if (index === AMOUNT_OF_QUESTIONS_BY_CORPUS[currentCorpusLevel]) {
    index = 0;
  }

  return assessmentInfo.testQuestions[index];
}

function calculateAnsweredQuestions(currentCorpusLevel) {
  let totalQuestions = 0;

  const userLevel = USER_LEVEL_BY_CORPUS[currentCorpusLevel][0];
  const corpusLevelsOfUserLevel = Object.keys(USER_LEVEL_BY_CORPUS).filter(
    corpusLevel => USER_LEVEL_BY_CORPUS[corpusLevel][0] === userLevel
  );

  corpusLevelsOfUserLevel.forEach(level => {
    if (Number(level) < currentCorpusLevel) {
      totalQuestions += AMOUNT_OF_QUESTIONS_BY_CORPUS[level] || 0;
    }
  });

  return totalQuestions;
}

function getAnsweredQuestions(assessmentInfo, currentCorpusLevel) {
  let answeredQuestions =
    assessmentInfo.correctAnswersCount + assessmentInfo.incorrectAnswersCount;
  while (
    answeredQuestions >
    AMOUNT_OF_QUESTIONS_PER_GRADE_LEVEL[
      USER_LEVEL_BY_CORPUS[currentCorpusLevel][0]
    ]
  ) {
    answeredQuestions =
      answeredQuestions -
      AMOUNT_OF_QUESTIONS_PER_GRADE_LEVEL[
        USER_LEVEL_BY_CORPUS[currentCorpusLevel][0]
      ];
  }
  return answeredQuestions;
}

function isBookLevelLastInCorpus(bookLevel, corpusLevel) {
  const corpusLevelArray = USER_LEVEL_BY_CORPUS[corpusLevel];
  let levelIndex = corpusLevelArray.indexOf(bookLevel);
  return levelIndex + 1 === corpusLevelArray.length;
}

function getStartCorpusLevelByBookLevel(startBookLevel) {
  for (const [corpusLevel, bookLevels] of Object.entries(
    USER_LEVEL_BY_CORPUS
  )) {
    if (bookLevels.includes(startBookLevel)) {
      return +corpusLevel;
    }
  }
  return 1;
}

export default {
  name: 'VocabularyAssessmentStore',
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
