import StudyClass from '../dao/StudyClassMiddleContorller';

function searchStudents(req) {
  return StudyClass.searchStudents(req.classId, req.filter, req.itemsCount);
}

function searchClasses() {
  return StudyClass.getAllActiveClasses();
}

function info(req) {
  return StudyClass.getClassInfoById(req.classId);
}

function persist(req) {
  return StudyClass.save(req.studyClass, req.reqParams);
}

function cancel(req) {
  return StudyClass.cancel(req.classId, req.comment);
}

function invite(req) {
  return StudyClass.invite(
    req.classId,
    req.userIds,
    req.comment,
    req.reqParams
  );
}

function searchStudentsForClass(req) {
  return StudyClass.searchStudentsForClass(
    req.classId,
    req.filter,
    req.itemsCount
  );
}

function setStudentStatus(req) {
  return StudyClass.setStudentStatus(
    req.userId,
    req.classId,
    req.studentIds,
    req.status,
    req.comment,
    req.reqParams
  );
}

function searchByPublication(req) {
  return StudyClass.searchByPublication(
    req.publicationId,
    req.filter,
    req.itemsCount
  );
}

function searchTeachers(req) {
  return StudyClass.searchTeachers(req.classId, req.filter, req.itemsCount);
}

function setTeacherStatus(req) {
  return StudyClass.setTeacherStatus(
    req.classId,
    req.teacherIds,
    req.status,
    req.comment,
    req.reqParams
  );
}

export default {
  GET: {
    searchstudyclasses: searchClasses,
    info: info,
    searchstudents: searchStudents,
    searchstudentsforclass: searchStudentsForClass,
    searchbypublication: searchByPublication,
    searchTeachers: searchTeachers
  },
  POST: {
    persist: persist,
    cancelstudyclass: cancel,
    invitestudents: invite,
    persiststudentstatus: setStudentStatus,
    persistteachersstatus: setTeacherStatus
  }
};
