import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('StudyClass.js');

import DB from '@/services/Agent/dao/DB';
import StudyClassDao from '@/services/Agent/dao/StudyClassDao';
import AgentContext from '@/services/Agent/AgentContext';

import tools from '../tools';
import get from 'lodash/get';

function save(studyClassDiff) {
  return _saveDiff(studyClassDiff);
}

async function _saveDiff(studyClassDiff) {
  const studyId = studyClassDiff.classId || DB.id.course();
  let mergedDoc;
  let studyClass;
  try {
    mergedDoc = await StudyClassDao.readMergedDoc(studyId);
  } catch (error) {
    mergedDoc = StudyClassDao.createMergedDoc(studyClassDiff);
  }

  let diffStudyClass = tools.diff.diff(studyClassDiff, mergedDoc);
  studyClass = tools.diff.joinPatches(diffStudyClass, mergedDoc);

  return StudyClassDao.writeUserDoc(studyClass);
}

//tab Study
async function getAllActiveClasses() {
  const classes = await StudyClassDao.getAll();

  var curUserId = DB.userId();
  var pubIds = [];
  var userIds = [];
  var courseIds = [];
  var activeClasses = [];

  classes.forEach(function(classItem) {
    var active = _isActiveClass(classItem, curUserId);
    if (!active) {
      return;
    }
    pubIds.push(classItem.publicationId);
    [].push.apply(userIds, getActiveTeachers(classItem));
    courseIds.push(classItem.classId);
    activeClasses.push(classItem);
  });
  return activeClasses;
}

async function cancel(classId, comment) {
  const mergedDoc = await StudyClassDao.readMergedDoc(classId);
  return StudyClassDao.deleteMergedDoc(mergedDoc, comment);
}

function _isActiveClass(classItem, curUserId) {
  const declinedStatus = getStatus().declined;
  const acceptedStatus = getStatus().accepted;
  const cancelledState = AgentContext.getCancelledState();

  const hasParticipants = classItem.teachers;
  const isActive = classItem.status !== cancelledState;

  const studentStatuses = get(classItem, 'students[' + curUserId + ']', {});
  const isDeclinedStudentStatus =
    studentStatuses.confirmationStatus === declinedStatus;
  const isAcceptedTeacherStatus =
    studentStatuses.teacherConfirmationStatus === acceptedStatus;

  const isValidClass =
    !classItem.invalidPublicationFound && hasParticipants && isActive;
  const isAvailableForStudent =
    !isDeclinedStudentStatus && isAcceptedTeacherStatus;
  const isTeacherInClass = get(
    classItem,
    'teachers[' + curUserId + '].active',
    false
  );

  const active = Boolean(
    isValidClass && (isAvailableForStudent || isTeacherInClass)
  );
  return active;
}

function getActiveTeachers(course) {
  if (!course.teachers) {
    logger.error(
      'Teacherless course found: id ' + course._id + ', rev ' + course._rev
    );
    return [];
  }
  return Object.keys(course.teachers).filter(function(id) {
    return course.teachers[id].active;
  });
}

function getStatus() {
  return AgentContext.getMembershipStatus();
}

function getClassInfoById(id) {
  return StudyClassDao.getClassById(id);
}

export default {
  save,
  getAllActiveClasses,
  getClassInfoById,
  cancel
};
