/**
 * TODO: when Lighthouse is running, it clears the filesystem,
 * so we need to re-check if files are available on FS after Lighthouse is done
 * and re-download them if needed.
 */

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FilesHandlingFs.js');
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import FileAccessTypeEnum from '@/enums/FileAccessTypeEnum';
import ClientNodeContext from '@/context/ClientNodeContext';
import responseParser from '@/services/AssetsManager/FileTransport/responseParser';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import EncodingEnum from '@/enums/EncodingEnum';
import FsErrorsEnum from '@/enums/FsErrorsEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';

import { Base64 } from 'js-base64';

import { FilesHandlingInterface } from './FilesHandlingInterface';

function _keyToVal(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    const val = obj[key];
    newObj[val] = key;
    return newObj;
  }, {});
}
class FilesHandlingFs extends FilesHandlingInterface {
  constructor() {
    super(AssetResourcesEnum.FS);
    this.localFileSystemPrefix = null;
    this.namespacePrefix = '';
    this.available = false;

    this.fileSystem = null;
    this.fsType = 0;
    this.fsSize = 0;
    this.fsErrors = _keyToVal(FsErrorsEnum);
  }

  async init() {
    this.available = await FeatureDetectorService.filesystem();
    if (!this.available) {
      if (process.client) {
        logger.warn(
          `File system is not available for ${ClientNodeContext.userAgentParams.browser.name} browser ${ClientNodeContext.userAgentParams.browser.version}.`
        );
      }
      return Promise.resolve({
        available: false
      });
    }
    if (ClientNodeContext.isSafari && !window.cordova) {
      logger.error('Unsupported environment for FS.');
      return Promise.resolve();
    }
    return this._initFileSystem();
  }

  isAvailable() {
    return this.available;
  }

  getUnavailableError() {
    const err = new Error(`FileAssetDriver: ${this.type} is not available`);
    err.type = CustomErrorEnum.FS_UNAVAILABLE;
    return err;
  }

  _initFileSystem() {
    const self = this;
    this.namespacePrefix = '1306091163/';
    return self._getFs().then(function() {
      return self._initState();
    });
  }

  _getFs() {
    const self = this;
    return Promise.resolve()
      .then(function name() {
        if (self.fileSystem) {
          return;
        }
        window.PersistentStorage =
          window.PersistentStorage || window.webkitPersistentStorage;
        window.requestFileSystem =
          window.requestFileSystem || window.webkitRequestFileSystem;
        if (!window.requestFileSystem) {
          throw 'Local filesystem is not supported by self browser';
        }
        if (!window.PersistentStorage) {
          return -1;
        }

        return new Promise(function(resolve, reject) {
          window.PersistentStorage.requestQuota(self.fsSize, resolve, reject);
        });
      })
      .then(function(persistentStorageGrantedBytes) {
        if (persistentStorageGrantedBytes >= 0) {
          self.fsSize = persistentStorageGrantedBytes;
        }

        if (window.cordova) {
          // eslint-disable-next-line no-undef
          self.fsType = LocalFileSystem.PERSISTENT;
        }
        if (ClientNodeContext.isElectron) {
          self.fsType = window.PERSISTENT;
        }
        return new Promise(function(resolve, reject) {
          window.requestFileSystem(self.fsType, self.fsSize, resolve, reject);
        });
      })
      .then(function(fs) {
        self.fileSystem = fs;
      })
      .catch(function(e) {
        logger.error(e);
        self.available = false;
        const fsError = self._parseError(e, 'On init fs error.');
        throw fsError;
      });
  }

  _initState() {
    const self = this;
    return new Promise(function(resolve, reject) {
      if (self.available && self.fileSystem) {
        self.fileSystem.root.getFile(
          'testfile.txt',
          {
            create: true
          },
          function(fileEntry) {
            const localFileSystemPrefix = fileEntry
              .toURL()
              .replace('testfile.txt', '');
            self.localFileSystemPrefix = localFileSystemPrefix;

            fileEntry.remove(resolve, resolve);
          },
          resolve
        );
      } else {
        reject(
          `Not all fs params Available isFSAvailable: ${self.available}, fileSystem: ${self.fileSystem}`
        );
      }
    });
  }

  createFileSourcePath(filePath) {
    return this._getLocalPath() + filePath;
  }

  _getLocalPath() {
    const localPath = this.localFileSystemPrefix + this.namespacePrefix;
    if (ClientNodeContext.os === 'iOS' && window.cordova) {
      return window.WkWebView.convertFilePath(localPath);
    }
    return localPath;
  }

  readFile(fileSourcePath, readOptions) {
    const self = this;
    return Promise.resolve().then(function() {
      switch (readOptions.accessType) {
        case FileAccessTypeEnum.FULL:
          return self._readFile(fileSourcePath, readOptions);
        case FileAccessTypeEnum.CHUNKED:
          return self._readFileByChunk(fileSourcePath, readOptions);
        default:
          throw new Error(
            `Get unsupported accessType: ${readOptions.accessType} in _getAccessTypeSuffix`
          );
      }
    });
  }

  _getFilePath(fileAccess) {
    return fileAccess;
  }

  _readFile(fileSourcePath, readOptions) {
    const self = this;
    return self
      ._readFileFromFs(fileSourcePath)
      .then(function(file) {
        return self._parseFile(file, readOptions);
      })
      .then(function(resp) {
        return responseParser.parse(resp, readOptions);
      })
      .catch(function(error) {
        throw self._fsErrorHanler(error, fileSourcePath);
      });
  }

  _readFileFromFs(fileSourcePath) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.fileSystem.root.getFile(
        self.namespacePrefix + fileSourcePath,
        { create: false },
        function onSuccess(fileEntry) {
          fileEntry.file(function(file) {
            resolve(file);
          }, reject);
        },
        reject
      );
    });
  }

  _parseFile(file, readOptions) {
    return new Promise(function(resolve, reject) {
      // jshint ignore:line
      // as iOS doesn't support encoding parameter in the FileReader ( UTF-8 by default ),
      // we have encoding error when trying to read arabic or farsi files.
      // so for iOS we don't read file as text, we read it as data file and then decode it;
      const isIOS = ClientNodeContext.os === 'iOS';
      if (file.size === 0) {
        var error = 'The size of ' + file.name + ' is 0. We cannot read it';
        reject(error);
      }
      const reader = new FileReader();
      reader.onloadend = function() {
        try {
          if (
            !isIOS ||
            readOptions.encoding === EncodingEnum.ARRAY_BUFFER ||
            readOptions.encoding === EncodingEnum.BLOB
          ) {
            resolve(this.result);
          } else {
            // eslint-disable-next-line no-unused-vars
            var encodedFile = this.result;
            var decodedFile;
            if (ClientNodeContext.isIos13) {
              var _tidyB64 = s => s.replace(/[^A-Za-z0-9\\+\\/]/g, '');
              var _atob = asc => window.atob(_tidyB64(asc));
              decodedFile = _atob(encodedFile.replace(/.*base64,/, ''));
            } else {
              decodedFile = Base64.decode(encodedFile.replace(/.*base64,/, ''));
            }
            resolve(decodedFile);
          }
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = function(err) {
        reject(err);
      };

      if (
        readOptions.encoding === EncodingEnum.ARRAY_BUFFER ||
        readOptions.encoding === EncodingEnum.BLOB
      ) {
        reader.readAsArrayBuffer(file);
      } else if (isIOS) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file);
      }
    });
  }

  _fsErrorHanler(error, fileSourcePath) {
    const fsError = this._parseError(
      error,
      'Error in fn getFile for path: ' + fileSourcePath + '. '
    );
    return fsError;
  }

  _parseError(e, message) {
    var msg = 'Unknown FS Error. ';
    if (e.code) {
      msg += e.code;
      if (this.fsErrors.hasOwnProperty(e.code)) {
        msg = this.fsErrors[e.code] + '. ';
      }
    }
    if (message) {
      msg += message;
    }
    if (e.message) {
      msg += e.message;
    }
    const err = new Error(msg);
    err.code = e.code;
    return err;
  }

  _readFileByChunk(fileSourcePath, readOptions) {
    const self = this;
    return self
      ._readFilePartial(fileSourcePath, readOptions)
      .then(function(file) {
        return self._parseFile(file, readOptions);
      })
      .then(function(resp) {
        return responseParser.parse(resp, readOptions);
      })
      .catch(function(error) {
        throw self._fsErrorHanler(error, fileSourcePath);
      });
  }

  _readFilePartial(fileSourcePath, readOptions) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.fileSystem.root.getFile(
        self.namespacePrefix + fileSourcePath,
        { create: false },
        function onSuccess(fileEntry) {
          fileEntry.file(function(file) {
            const { chunkOffset } = readOptions;

            const chunk = file.slice(chunkOffset.start, chunkOffset.end);
            resolve(chunk);
          }, reject);
        },
        reject
      );
    });
  }

  async writeFile(fileAccess, data) {
    const filePath = this._getFilePath(fileAccess);
    const fullPath = this.namespacePrefix + filePath;
    const parts = fullPath.split('/');
    parts.pop();
    const folderPath = parts.join('/');

    await this.mkDir(folderPath);
    try {
      await this.removeFile(filePath);
    } catch (error) {
      const isMissing = error ? error.code === 8 : false;
      if (!isMissing) {
        logger.error(
          `Get error on remove file from FS before download error: ${error}`
        );
      }
    }
    return this.saveFile(data, filePath);
  }

  isExist(fileSourcePath) {
    return new Promise(resolve => {
      this.fileSystem.root.getFile(
        this.namespacePrefix + fileSourcePath,
        { create: false },
        function onSuccess() {
          resolve(true);
        },
        function onError() {
          resolve(false);
        }
      );
    });
  }

  mkDir(folderPath) {
    const self = this;
    return new Promise(function(resolve, reject) {
      const folders = folderPath.split('/');
      function createDir(dirEntry) {
        var folder = folders.shift();
        dirEntry.getDirectory(
          folder,
          { create: true },
          function(_dirEntry) {
            if (folders.length) {
              createDir(_dirEntry);
            } else {
              resolve(_dirEntry);
            }
          },
          reject
        );
      }

      if (folders.length) {
        createDir(self.fileSystem.root);
      } else {
        resolve();
      }
    });
  }

  saveFile(data, filePath) {
    const self = this;
    let fileEntry;
    return self
      ._createFileEntry(filePath)
      .then(function(_fileEntry) {
        fileEntry = _fileEntry;
        return self._createWriter(fileEntry);
      })
      .then(function(writer) {
        return self._writeData(writer, data);
      })
      .then(function() {
        return fileEntry;
      })
      .catch(err => {
        if (err instanceof ProgressEvent && err.type === 'error') {
          logger.warn(
            `get ProgressEvent error ${err.currentTarget.error} on write file ${filePath}`
          );
          return;
        }
        throw err;
      });
  }

  _createFileEntry(fileSourcePath) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.fileSystem.root.getFile(
        self.namespacePrefix + fileSourcePath,
        { create: true, exclusive: false },
        resolve,
        function(error) {
          const fsError = self._fsErrorHanler(
            error,
            `Error in _createFileEntry fn fileSourcePath ${fileSourcePath}`
          );
          reject(fsError);
        }
      );
    });
  }

  _createWriter(fileEntry) {
    return new Promise(function(resolve, reject) {
      fileEntry.createWriter(resolve, function(err) {
        reject(err);
      });
    });
  }

  _writeData(writer, data) {
    return new Promise(function(resolve, reject) {
      if (
        !(data instanceof Blob) &&
        typeof data === 'object' &&
        data !== null
      ) {
        data = new Blob([JSON.stringify(data)], {
          type: 'application/json'
        });
      }
      const isJsonExt = writer.localURL
        ? writer.localURL.indexOf('.json') !== -1
        : false;
      if (isJsonExt && typeof data === 'string') {
        data = new Blob([data], {
          type: 'application/json'
        });
      }
      if (typeof data === 'string' || data instanceof ArrayBuffer) {
        data = new Blob([data], { type: 'text/plain' });
      }
      var written = 0;
      var BLOCK_SIZE = 1024 * 1024;

      function writeNext() {
        var sz = Math.min(BLOCK_SIZE, data.size - written);
        var sub = data.slice(written, written + sz);

        function done() {
          written += sz;
          if (written < data.size) {
            writeNext();
          } else {
            resolve();
          }
        }

        writer.onwrite = done;
        writer.onerror = reject;
        writer.write(sub);
      }
      writeNext();
    });
  }

  removeFile(fileAccess) {
    const self = this;
    const filePath = self._getFilePath(fileAccess);
    return self._createFileEntry(filePath).then(function(fileEntry) {
      return self._removeFileEntry(fileEntry);
    });
  }

  _removeFileEntry(fileEntry) {
    const self = this;
    return new Promise(function(resolve, reject) {
      return fileEntry.remove(resolve, function(error) {
        const isMissing =
          error && self.fsErrors[error.code] === self.fsErrors[8];
        if (isMissing) {
          resolve();
          return;
        }
        const fsError = self._fsErrorHanler(
          error,
          `Error in removeFileEntry fn. fileEntry name ${fileEntry.name}`
        );
        reject(fsError);
      });
    });
  }

  _removeDirEntry(dirEntry) {
    const self = this;
    return new Promise(function(resolve, reject) {
      return dirEntry.removeRecursively(resolve, function(error) {
        if (error && error.code === self.fsErrors[8]) {
          resolve();
          return;
        }
        const fsError = self._fsErrorHanler(
          error,
          `Error in removeFileEntry fn. fileEntry name ${dirEntry.name}`
        );
        reject(fsError);
      });
    });
  }

  async getInternalMemory(excludeEntries) {
    try {
      const dirEntry = await this._getDirEntry(this.namespacePrefix);
      return this._getDirEntrySize(dirEntry, excludeEntries);
    } catch (error) {
      logger.error(
        `get error on getInternalMemory set 0 as default error:${error}`
      );
      return this._createEntryInfo();
    }
  }

  _getDirEntry(dirPath) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.fileSystem.root.getDirectory(
        dirPath,
        { create: false },
        resolve,
        reject
      );
    });
  }

  _createEntryInfo() {
    return {
      directories: {},
      size: 0
    };
  }

  _getDirEntrySize(dirEntry, excludeEntries) {
    const entryInfo = this._createEntryInfo();
    const self = this;
    return self
      ._scanDirEntry(dirEntry, excludeEntries)
      .then(allEntries => {
        const infoPromises = [];
        allEntries.forEach(entry => {
          if (entry.isFile) {
            infoPromises.push(self._getFileInfo(entry));
          }
        });
        return Promise.all(infoPromises);
      })
      .then(files => {
        files.forEach(file => {
          const size = file.size;
          const dirName = file.fullPath.split('/')[2];
          if (!entryInfo.directories.hasOwnProperty(dirName)) {
            entryInfo.directories[dirName] = {
              size: 0
            };
          }
          entryInfo.directories[dirName].size += size;
          entryInfo.size += size;
        });
        return entryInfo;
      });
  }

  _parseFullPath(fullPath) {
    return fullPath;
  }

  _scanDirEntry(dirEntry, excludeEntries, allEntries) {
    const self = this;
    allEntries = allEntries || [];
    return self
      ._readEntries(dirEntry)
      .then(function(entries) {
        const promises = [];
        entries.forEach(entry => {
          if (entry.isDirectory && !excludeEntries.includes(entry.name)) {
            promises.push(
              self._scanDirEntry(entry, excludeEntries, allEntries)
            );
          }
          if (!excludeEntries.includes(entry.name)) {
            allEntries.push(entry);
          }
        });
        return Promise.all(promises);
      })
      .then(() => {
        return allEntries;
      });
  }

  _readEntries(dirEntry) {
    return new Promise(function(resolve) {
      const results = [];
      const directoryReader = dirEntry.createReader();
      _read();
      function _read() {
        directoryReader.readEntries(function(entries) {
          if (entries.length > 0) {
            [].push.apply(results, entries);
            _read();
          } else {
            resolve(results);
          }
        });
      }
    });
  }

  _getFileInfo(entry) {
    return new Promise(function(resolve, reject) {
      entry.file(info => {
        info.fullPath = entry.fullPath;
        resolve(info);
      }, reject);
    });
  }

  getFileInfo(fileSourcePath) {
    const self = this;
    return new Promise(function(resolve, reject) {
      self.fileSystem.root.getFile(
        self.namespacePrefix + fileSourcePath,
        { create: false },
        function onSuccess(fileEntry) {
          self._getFileInfo(fileEntry).then(resolve, reject);
        },
        reject
      );
    });
  }

  removeAll(excludeEntries) {
    const self = this;
    return self
      ._getDirEntry(self.namespacePrefix)
      .then(function(dirEntry) {
        return self._readEntries(dirEntry);
      })
      .then(function(entries) {
        const entriesForRemove = entries.filter(entry => {
          return !excludeEntries.includes(entry.name);
        });
        const promises = entriesForRemove.map(entry => {
          if (entry.isFile) {
            return self._removeFileEntry(entry);
          }
          return self._removeDirEntry(entry);
        });
        return Promise.all(promises);
      });
  }
}

export { FilesHandlingFs };
