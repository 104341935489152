<template>
  <v-card-title
    class="popup-header"
    :class="{ divider: headerDivider }"
    primary-title
  >
    <span class="back-wrapper" />
    <span class="popup-header-wrapper">
      {{ headerTitle }}
    </span>
    <span class="close-wrapper">
      <button class="button-close" @click.prevent="closeHandler">
        <BaseSpriteIcon icon-name="ico-close" />
      </button>
    </span>
  </v-card-title>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  components: { BaseSpriteIcon },
  props: {
    headerTitle: {
      type: String,
      required: false,
      default: ''
    },
    headerDivider: {
      type: Boolean,
      required: false,
      default: true
    },
    closeHandler: {
      type: Function,
      required: true,
      default() {
        return function() {};
      }
    }
  }
};
</script>

<style lang="less" src="./PopupHeader.less"></style>
