import { $t } from '@/i18n';

export default {
  NAME_IS_EMPTY: $t('ValidationError.Register.name.empty'),
  NAME_IS_NON_LATIN: $t('ValidationError.Register.name.wrongFormat'),
  EMAIL_IS_EMPTY: $t('ValidationError.loginEmail'),
  EMAIL_IS_INVALID: $t('ValidationError.email'),
  EMAIL_IS_EXISTS: $t('ValidationError.Register.email.alreadyRegistered'),
  EMAIL_IS_ADDED: $t('ValidationError.email.alreadyAdded'),
  EMAIL_IS_NOT_FOUND: $t('ValidationError.resetPasswordServerCheck.label'),
  PASSWORD_IS_INCORRECT: $t('ValidationError.password'),
  PASSWORD_IS_EMPTY: $t('ValidationError.loginPassword'),
  PASSWORD_MISMATCH: $t('ValidationError.passwordsMismatch.label'),
  COMBINATION_IS_INVALID: $t('ValidationError.invalidLoginPasswordCombination')
};
