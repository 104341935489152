import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getParaDirection: 'BookStore/getParaDirection',
      getParaText: 'BookStore/getParaTextByParaId',
      getHighlightedQuoteData: 'AnnotationsStore/getHighlightedQuoteData'
    })
  },
  methods: {
    async $_getHighlightedQuote(bookId, materialItem) {
      const paraId = materialItem.paragraphId || materialItem.blockId;
      await this.$store.dispatch('BookStore/getContentByParagraphId', {
        bookId,
        paraId
      });
      const dir = this.getParaDirection(paraId);
      const paraText = this.getParaText(bookId, paraId);
      return this.getHighlightedQuoteData(materialItem, paraText, dir);
    }
  }
};
