<template>
  <BaseButton v-bind="$attrs" @click="openSignIn">
    <slot name="sign-in">Sign in</slot>
  </BaseButton>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'SingInControl',
  components: {
    BaseButton
  },
  methods: {
    openSignIn() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CONNECT,
        popupContext: {}
      });
    }
  }
};
</script>

<style lang="less" src="./SingInControl.less"></style>
