import FavouritePublicationsDao from '@/services/Agent/dao/FavouritePublicationsDao';

export default {
  POST: {
    removePublicationFromFavourites,
    addPublicationToFavourites
  },
  GET: {
    getFavouritePublications
  }
};

function removePublicationFromFavourites(publication) {
  return FavouritePublicationsDao.removePublicationFromFavourites(publication);
}

function addPublicationToFavourites(publication) {
  return FavouritePublicationsDao.addPublicationToFavourites(publication);
}

function getFavouritePublications() {
  return FavouritePublicationsDao.getFavouritePublications();
}
