import Utils from '@/services/utils/Utils';

const EMPTY_META_INFO = [];
const EMPTY_LINKS_INFO = [];
const EMPTY_TITLE = '';

class HeadInfo {
  constructor(data = {}) {
    this.meta = data.meta || EMPTY_META_INFO;
    this.link = data.link || EMPTY_LINKS_INFO;
    this.title = data.title || EMPTY_TITLE;
  }
}

class HeadInfoBuilder {
  setMeta(meta) {
    this.meta = meta;
    return this;
  }
  setLink(link) {
    this.link = link;
    return this;
  }
  setTitle(title) {
    this.title = title;
    return this;
  }
  build() {
    return new HeadInfo(this);
  }
}

const createHeadInfoBuilder = () => {
  return new HeadInfoBuilder();
};

const emptyHeadInfo = () => {
  return createHeadInfoBuilder().build();
};

const getPageUrl = (clientReaderUrl, pageName) => {
  const urlObj = new URL(clientReaderUrl);
  urlObj.pathname = Utils.pathJoin([urlObj.pathname, pageName]);
  return urlObj.href + '/';
};

const getCanonicalLinkSetting = url => {
  return {
    hid: 'canonical',
    rel: 'canonical',
    href: url
  };
};

const applyDescriptionSEORules = (description = '') => {
  if (description.length <= 155) {
    return description;
  }
  const firstSentence = description.split(/[.]\s*/)[0];
  if (firstSentence.length <= 155) {
    return firstSentence + '.';
  }
  const subDescription = description.substring(0, 155);
  const lastIndexOfSpace = subDescription.lastIndexOf(' ');
  return subDescription.substring(0, lastIndexOfSpace);
};

export default {
  createHeadInfoBuilder,
  emptyHeadInfo,
  getPageUrl,
  getCanonicalLinkSetting,
  applyDescriptionSEORules
};
