import debounce from 'lodash/debounce';

import axios from 'axios';

// IMPORTANT!
// Implementation should strictly avoid pushing messages
// to MessageBuffer (in particular, should not use logger).
// Otherwise infinite message loop is possible.

const DEBOUNCE_TIMEOUT = 5000; // ms // subsequent messages during this time frame will not be flushed
const DEBOUNCE_TIMEOUT_FOR_FATALS = 100; // wait 100ms for the second message with traces
const BUFFER_SIZE_THRESHOLD = 100; // flush buffer when its size (without pending messages) exceeds this value
const BUFFER_SIZE_MAX = 1000; // remove REMOVE_WHEN_OVERFLOWED top messages when buffer size exceeds this value
const REMOVE_WHEN_OVERFLOWED = 200;
const RETRY_AFTER_ERROR_TIMEOUT = 20000; // ms

const _buffer = [];
let _pending = 0; // number of messages sent and request is not completed yet
let _removed = 0; // number of messages removed while current request is not completed yet

let _lastFlush = Promise.resolve();
// jQuery promises used to ensure true "silence".
// Angular promises prohibited here as they require digest cycle -> watch expressions re-evaluation.

let _closed;

let _retryTimer;

let context = {
  runId: '',
  serverUrl: ''
};

function init(Context, runId) {
  context.runId = runId;
  context.serverUrl = Context.serverUrl;
}

function setRunId(runId) {
  context.runId = runId;
}
/**
 * Flush all accumulated messages to server.
 * Return promise.
 */
function flush() {
  return _flush();
}

/**
 * Flush all accumulated messages to server synchronously.
 * Pending messages are taken also. So duplication is possible.
 * Hold execution until completed.
 */
function flushSync() {
  return _flushSync();
}

/**
 * Put message to buffer.
 * Schedule flush to server.
 */
function push(message) {
  if (_closed) {
    return;
  }
  message.runId = context.runId || '';
  if (_buffer.length === BUFFER_SIZE_MAX) {
    _buffer.splice(0, REMOVE_WHEN_OVERFLOWED);
    _removed += REMOVE_WHEN_OVERFLOWED;
    _log(
      'MessageBufferService: buffer overflowed, top',
      REMOVE_WHEN_OVERFLOWED,
      'messages removed'
    );
  }

  _buffer.push(message);

  if (message.params && message.params.level === 'FATAL') {
    _flushWhenFatal();
  } else {
    _flushWhenIdle();
    _flushWhenSize();
  }
}

const _flushWhenIdle = debounce(function() {
  _flushApply();
}, DEBOUNCE_TIMEOUT);

const _flushWhenSize = function() {
  if (_buffer.length - _pending === BUFFER_SIZE_THRESHOLD) {
    // 'window.setTimeout' is used to
    // - ensure non-angular context regardless who invokes 'push'
    // - separate 'flushing' from current execution stack
    setTimeout(function() {
      _flushApply();
    }, 0);
  }
};

const _flushWhenFatal = debounce(function() {
  _flushApply();
}, DEBOUNCE_TIMEOUT_FOR_FATALS);

function _flushApply() {
  if (!_closed) {
    _flush();
  }
}

function _flush() {
  clearTimeout(_retryTimer);
  return _lastFlush.then(__flush, __flush);
}

function __flush() {
  if (_buffer.length === 0) {
    return Promise.resolve();
  }

  _pending = _buffer.length;
  _removed = 0;
  //       _log('MessageBufferService:', _pending, 'messages to be flushed');

  return axios({
    method: 'PUT',
    //            url: 'rest/message',
    url: context.serverUrl + 'rest/message', // temporary
    data: _buffer
  }).then(
    function() {
      _log('MessageBufferService:', _pending, 'messages flushed');
      if (_pending > _removed) {
        _buffer.splice(0, _pending - _removed);
      }
      _pending = 0;
    },
    function(response) {
      var error = JSON.stringify({
        status: response.status,
        data: response.data
      });
      if (response.swFatal || response.swError) {
        // server received messages but failed to process them
        _log('MessageBufferService: flushing failed:', error);
        _buffer.length = 0;
        if (response.swFatal) {
          _closed = true;
        }
      } else {
        // server did not receive messages
        _log(
          'MessageBufferService: flushing failed:',
          error,
          '- will retry in',
          RETRY_AFTER_ERROR_TIMEOUT,
          'ms',
          'buffer size:',
          _buffer.length
        );
        clearTimeout(_retryTimer);
        _retryTimer = setTimeout(function() {
          _flushApply();
        }, RETRY_AFTER_ERROR_TIMEOUT);
        _pending = 0;
      }
    }
  );
}

function _flushSync() {
  if (_buffer.length > 0) {
    return axios({
      method: 'PUT',
      //               url: 'rest/message',
      url: context.serverUrl + 'rest/message', // temporary
      data: _buffer
    }).then(function() {
      _removed = _buffer.length;
      _buffer.length = 0;
    });
  }
}

function _log() {
  /* eslint-disable no-console */
  if (console && console.log && !process.env.IS_PROD) {
    const args = [].slice.call(arguments);
    console.log(args.join(' '));
  }
  /* eslint-enable no-console */
}

export default {
  init,
  flush,
  flushSync,
  push,
  setRunId
};
