var repeat = function(str, n) {
  var res = '';
  while (n) {
    if (n % 2 === 1) {
      res += str;
    }
    if (n > 1) {
      str += str;
    }
    n >>= 1;
  }
  return res;
};
function generateComponentTrace(vueInstance) {
  if (vueInstance._isVue && vueInstance.$parent) {
    var tree = [];
    var currentRecursiveSequence = 0;
    while (vueInstance) {
      if (tree.length > 0) {
        var last = tree[tree.length - 1];
        if (last.constructor === vueInstance.constructor) {
          currentRecursiveSequence++;
          vueInstance = vueInstance.$parent;
          continue;
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence];
          currentRecursiveSequence = 0;
        }
      }
      tree.push(vueInstance);
      vueInstance = vueInstance.$parent;
    }
    return (
      '\n\nfound in\n\n' +
      tree
        .map(function(vm, i) {
          return (
            '' +
            (i === 0 ? '---> ' : repeat(' ', 5 + i * 2)) +
            (Array.isArray(vm)
              ? formatComponentName(vm[0]) +
                '... (' +
                vm[1] +
                ' recursive calls)'
              : formatComponentName(vm))
          );
        })
        .join('\n')
    );
  } else {
    return '\n\n(found in ' + formatComponentName(vueInstance) + ')';
  }
}

function formatComponentName(vm, includeFile) {
  if (vm.$root === vm) {
    return '<Root>';
  }
  var options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm;
  var name = options.name || options._componentTag;
  var file = options.__file;
  if (!name && file) {
    var match = file.match(/([^/\\]+)\.vue$/);
    name = match && match[1];
  }

  return (
    (name ? '<' + classify(name) + '>' : '<Anonymous>') +
    (file && includeFile !== false ? ' at ' + file : '')
  );
}
var classifyRE = /(?:^|[-_])(\w)/g;
var classify = function(str) {
  return str
    .replace(classifyRE, function(c) {
      return c.toUpperCase();
    })
    .replace(/[-_]/g, '');
};

export default {
  generateComponentTrace
};
