<template>
  <v-btn
    v-bind="computedAttributes"
    @click="click"
    @touchstart="touchstart"
    @touchend="touchend"
  >
    <slot />
  </v-btn>
</template>

<script>
const CUSTOM_ATTRS_NAMES_MAP = {
  disabled: 'custom-disabled'
};
import { VBtn } from 'vuetify/lib';
import SizeableOverrides from './SizeableOverrides.vue';

export default {
  name: 'BaseButton',
  components: { VBtn: VBtn.extend(SizeableOverrides) },
  props: {
    xXLarge: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultAttributes: {
        class: 'text-none'
      }
    };
  },
  computed: {
    computedAttributes() {
      const incomingAttrs = { ...this.$attrs };

      if (incomingAttrs.disabled) {
        incomingAttrs[CUSTOM_ATTRS_NAMES_MAP.disabled] = true;
        delete incomingAttrs.disabled;
      }

      return {
        ...this.defaultAttributes,
        ...incomingAttrs
      };
    }
  },
  methods: {
    click(e) {
      return this.$emit('click', e);
    },
    touchstart(e) {
      return this.$emit('touchstart', e);
    },
    touchend(e) {
      return this.$emit('touchend', e);
    }
  }
};
</script>

<style lang="less" src="./BaseButton.less"></style>
