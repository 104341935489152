const mock = {
  getItem() {
    return '{}';
  },
  setItem() {},
  removeItem() {}
};
const localStorage = process.client ? window.localStorage : mock;

function getStorage(key) {
  var storage = localStorage.getItem(key);
  try {
    return JSON.parse(storage);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message, key, storage);
    return null;
  }
}

function setStorage(key, storage) {
  storage = JSON.stringify(_prepareValue(storage));
  localStorage.setItem(key, storage);
}

function _prepareValue(value) {
  // JSON.stringify|parse work inconsistently with 'undefined'
  return value === undefined ? null : value;
}

export default {
  get: getStorage,
  set: setStorage
};
