<template>
  <BaseButton
    class="px-3"
    :aria-label="$t('Accessibility.header.search')"
    text
    @click.native.prevent="openSearch"
  >
    <slot name="search-control-wrapper">{{
      $t('ApplicationMenuItem.Search.label')
    }}</slot>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import AppStateEnum from '@/enums/AppStateEnum';

export default {
  name: 'OpenSearchControlMid',
  components: {
    BaseButton
  },
  props: {
    to: {
      type: Object,
      default: () => ({ name: AppStateEnum.SEARCH })
    }
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toRoute() {
      this.$router.push({ ...this.to });
    },
    toLink() {
      const clientReaderUrl = this.$store.getters[
        'ContextStore/getClientReaderUrl'
      ];
      this.$store.dispatch('ContextStore/openUrl', {
        url: `${clientReaderUrl}search/`
      });
    },
    openSearch() {
      if (this.isBlogApp) {
        this.toLink();
        return;
      }
      this.toRoute();
    }
  }
};
</script>

<style lang="less" src="./OpenSearchControlMidBlock.less"></style>
