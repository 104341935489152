import BrandsEnum from '../enums/BrandsEnum.mjs';
import MarkerUtils from './dom-utils/marker-utils.mjs';
import HighlightUtils from './HighlightUtils';

function buildPublicationLink({
  clientUrl,
  identifier,
  userId,
  locator,
  brand
}) {
  let link = `${clientUrl}${identifier}/?`;
  if (brand && brand === BrandsEnum.FFA) {
    link += `uid=${userId}&`;
  }

  if (!locator) {
    return link;
  }

  const paraId = locator.startLocator.prefixedParagraphId;
  link += `paraId=${paraId}&selectionString=`;
  const startBlockId =
    MarkerUtils.getBlockIdByLocator(locator.startLocator) ||
    locator.startLocator.paragraphId;
  const endBlockId =
    MarkerUtils.getBlockIdByLocator(locator.endLocator) ||
    locator.endLocator.paragraphId;

  link += HighlightUtils.createSelectionStringWithBlockId(
    locator,
    startBlockId,
    endBlockId
  );

  return link;
}

export { buildPublicationLink };
