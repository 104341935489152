<template>
  <div
    ref="chapterBlock"
    class="chapter-block"
    :class="chapterBlockClasses"
    @click="toggleChapterAnnotations()"
  >
    <div class="chapter-name">
      <span class="chapter-title" v-html="chapterTitle"></span>
      <span class="chapter-title-tags ml-1" v-html="chapterTitleTags"></span>
    </div>
    <div v-if="!isOneChapter" class="chapter-handler ml-2">
      <BaseSpriteIcon icon-name="ico-arrow-chevron" />
    </div>
  </div>
</template>

<script>
import NotesTabActionsEnum from '@/enums/NotesTabActionsEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'ChapterItem',
  components: { BaseSpriteIcon },
  props: { externalData: Object },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    getChapterName() {
      return this.$store.getters['BookStore/getChapterNameByParaId'];
    },
    readingArea() {
      return this.$store.getters['ProgressStore/getReadingArea'];
    },
    activeChapterName() {
      const topParaId = this.readingArea?.startLocator?.paragraphId;
      if (!topParaId) {
        return '';
      }
      return this.getChapterName(this.bookId, topParaId);
    },
    chapterTitle() {
      return `${this.externalData.chapterName}`;
    },
    chapterTitleTags() {
      return `(${this.externalData.chapterAnnNumber})`;
    },
    isChapterActive() {
      return this.activeChapterName === this.externalData.chapterName;
    },
    chapterBlockClasses() {
      return {
        expanded: this.expanded,
        active: this.isChapterActive,
        pointer: !this.isOneChapter
      };
    },
    isOneChapter() {
      return this.externalData.chaptersNumber === 1;
    }
  },
  methods: {
    toggleChapterAnnotations() {
      if (this.isOneChapter) {
        return;
      }
      this.expanded = !this.expanded;

      this.$emit('onAction', {
        type: NotesTabActionsEnum.TOGGLE_ANNS,
        id: this.externalData.id,
        chapterEl: this.$refs.chapterBlock
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import 'ChapterItem.less';
</style>
