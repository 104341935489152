import compact from 'lodash/compact';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
import ReadingSettingsFactory from '@/classes/factories/ReadingSettingsFactory';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import Utils from './utils/Utils';

const MIN_PLAYBACK_RATE = 0.5;
const MAX_PLAYBACK_RATE = 1.5;

const AUDIO_SETTINGS = 'AudioSettings';
const EXPANDED_NOTES_SETTINGS = 'ExpandedNotesSettings';
const READER_SETTINGS = 'ReaderSettings';

async function setAudioSpeedByBookId(bookId, audioSpeed) {
  await UnifiedSettingsService.setSetting(AUDIO_SETTINGS, bookId, audioSpeed);
}

async function setFontName(setting, skipSync) {
  await _setSetting('fontName', setting, skipSync);
}

async function setFontSize(setting, skipSync) {
  await _setSetting('fontSize', setting, skipSync);
}
async function setLineSpacing(setting, skipSync) {
  await _setSetting('lineSpacing', setting, skipSync);
}

async function setReadingThemeName(setting, skipSync) {
  await _setSetting('readingThemeName', setting, skipSync);
}

async function setExpandedNotesModeByBookId(bookId, mode) {
  await UnifiedSettingsService.setSetting(
    EXPANDED_NOTES_SETTINGS,
    bookId,
    mode
  );
}

async function setExpandedFootnotesMode(setting, skipSync) {
  await _setSetting('expandedFootnotesMode', setting, skipSync);
}

async function setGoogleTranslateMode(setting, skipSync) {
  await _setSetting('googleTranslate', setting, skipSync);
}

async function setDictionaryMode(setting, skipSync) {
  await _setSetting('dictionaryMode', setting, skipSync);
}

async function setWikiMode(setting, skipSync) {
  await _setSetting('wikiMode', setting, skipSync);
}

async function setDictionaryAutoOpen(setting, skipSync) {
  await _setSetting('dictionaryAutoOpen', setting, skipSync);
}

async function setHighlightMode(setting, skipSync) {
  await _setSetting('highlightMode', setting, skipSync);
}

async function setReadingPositionTracking(setting, skipSync) {
  await _setSetting('readingPositionTracking', setting, skipSync);
}

async function setScrollingMode(setting, skipSync) {
  await _setSetting('scrollingMode', setting, skipSync);
}

/**
 * get audio rate for audioPlayer; must be in range [0.5;2.0]
 * @param {Number} audioSpeed
 * @param {Number} defaultSpeed
 */
function getPlaybackRate(audioSpeed, defaultSpeed) {
  const rate = audioSpeed / defaultSpeed;

  if (rate < MIN_PLAYBACK_RATE) {
    return MIN_PLAYBACK_RATE;
  }

  if (rate > MAX_PLAYBACK_RATE) {
    return MAX_PLAYBACK_RATE;
  }

  return rate;
}

function getAudioSpeed(bookId, defaultSpeed) {
  const audioSpeedByBookId = _getAudioSpeedByBookId(bookId);
  return audioSpeedByBookId || defaultSpeed;
}

function getFont(readingFonts, lang, currentCategorySetting) {
  const potentialFonts = compact([
    readingFonts.find(font => font.name === _getSetting(_getFontKey(lang))),
    readingFonts.find(font => font.name === _getFontName())
  ]);

  if (potentialFonts.length) {
    return potentialFonts[0];
  }

  if (currentCategorySetting?.fontName) {
    const categoryFont = readingFonts.find(
      font => font.name === currentCategorySetting?.fontName
    );
    return categoryFont || readingFonts[0];
  }

  return readingFonts[0];
}

function getReadingSettings({
  bookId,
  defaultSpeed,
  defaultHighlightMode,
  defaultScrollingMode,
  lang,
  allFonts,
  hasRtl,
  currentCategorySetting
}) {
  const readingFonts = _getReadingFontsByLang(allFonts, lang);
  const font = getFont(readingFonts, lang, currentCategorySetting);
  const fontSize =
    _getFontSize() ||
    currentCategorySetting?.fontSize ||
    AppConstantsUtil.DEFAULT_FONT_SIZE;
  const currentLineSpacing = _getLineSpacing();
  let lineSpacing = AppConstantsUtil.DEFAULT_LINE_SPACING;
  if (Utils.isRtl(lang) || hasRtl) {
    if (
      currentLineSpacing &&
      currentLineSpacing >=
        AppConstantsUtil.MIN_LINE_SPACING +
          AppConstantsUtil.RTL_SHIFT_LINE_SPACING
    ) {
      lineSpacing = currentLineSpacing;
    } else {
      lineSpacing =
        AppConstantsUtil.DEFAULT_LINE_SPACING +
        AppConstantsUtil.RTL_SHIFT_LINE_SPACING;
    }
  } else if (
    currentLineSpacing &&
    currentLineSpacing <= AppConstantsUtil.MAX_LINE_SPACING
  ) {
    lineSpacing = currentLineSpacing;
  } else if (currentCategorySetting?.lineSpacing) {
    lineSpacing = currentCategorySetting?.lineSpacing;
  }

  const themeName = _getReadingThemeName();
  const readingSettingsFactory = ReadingSettingsFactory.createReadingSettingsFactory();
  const settingsGoogleTranslateMode = _getGoogleTranslateMode();
  const settingsDictionaryMode = _getDictionaryMode();
  const settingsWikiMode = _getWikiMode();
  const settingsDictionaryAutoOpen = _getDictionaryAutoOpen();
  const audioSpeed = getAudioSpeed(bookId, defaultSpeed);

  const googleTranslateMode =
    typeof settingsGoogleTranslateMode === 'undefined' ||
    settingsGoogleTranslateMode === null
      ? false
      : !!settingsGoogleTranslateMode;

  const dictionaryMode =
    typeof settingsDictionaryMode === 'undefined' ||
    settingsDictionaryMode === null
      ? true
      : !!settingsDictionaryMode;

  const wikiMode =
    typeof settingsWikiMode === 'undefined' || settingsWikiMode === null
      ? true
      : !!settingsWikiMode;

  const dictionaryAutoOpen =
    typeof settingsDictionaryAutoOpen === 'undefined' ||
    settingsDictionaryAutoOpen === null
      ? true
      : !!settingsDictionaryAutoOpen;
  const readingSettings = readingSettingsFactory
    .setReadingFonts(readingFonts)
    .setFont(font)
    .setFontSize(fontSize)
    .setLineSpacing(lineSpacing)
    .setThemeName(themeName)
    .setAudioSpeed(audioSpeed)
    .setDefaultAudioSpeed(defaultSpeed)
    .setHighlightMode(_getHighlightMode() || defaultHighlightMode)
    .setScrollingMode(_getScrollingMode() || defaultScrollingMode)
    .setReadingPositionTracking(!!_getReadingPositionTrackingMode())
    .setExpandedNotesMode(!!_getExpandedNotesByBookId(bookId))
    .setExpandedFootnotesMode(!!_getExpandedFootnotesMode())
    .setGoogleTranslate(googleTranslateMode)
    .setDictionaryMode(dictionaryMode)
    .setWikiMode(wikiMode)
    .setDictionaryAutoOpen(dictionaryAutoOpen)
    .build();

  return readingSettings;
}

function getLocalStoredThemeName() {
  return _getReadingThemeName();
}

function _getReadingFontsByLang(fonts, lang) {
  return fonts.filter(font => !font.langs || font.langs.includes(lang));
}

function _getFontName() {
  return _getSetting('fontName');
}

function _getFontSize() {
  return _getSetting('fontSize');
}

function _getLineSpacing() {
  return _getSetting('lineSpacing');
}

function _getReadingThemeName() {
  return _getSetting('readingThemeName') || ThemeClassNamesEnum.LIGHT;
}

function _getAudioSpeedByBookId(bookId) {
  let audioSpeedByBookId = UnifiedSettingsService.getSetting(
    AUDIO_SETTINGS,
    bookId
  );
  return audioSpeedByBookId;
}

function _getExpandedNotesByBookId(bookId) {
  let expandedNotes = UnifiedSettingsService.getSetting(
    EXPANDED_NOTES_SETTINGS,
    bookId
  );
  return expandedNotes;
}

function _getHighlightMode() {
  return _getSetting('highlightMode');
}

function _getScrollingMode() {
  return _getSetting('scrollingMode');
}

function _getReadingPositionTrackingMode() {
  return _getSetting('readingPositionTracking');
}

function _getExpandedFootnotesMode() {
  return _getSetting('expandedFootnotesMode');
}

function _getGoogleTranslateMode() {
  return _getSetting('googleTranslate');
}

function _getDictionaryMode() {
  return _getSetting('dictionaryMode');
}

function _getWikiMode() {
  return _getSetting('wikiMode');
}

function _getDictionaryAutoOpen() {
  return _getSetting('dictionaryAutoOpen');
}

function _getFontKey(lang) {
  let settingKey = 'fontName';
  return lang ? settingKey + '.' + lang : settingKey;
}

function _getSetting(key) {
  return UnifiedSettingsService.getSetting('ReaderSettings', key);
}

async function _setSetting(key, value, skipSync) {
  await UnifiedSettingsService.setSetting(
    READER_SETTINGS,
    key,
    value,
    skipSync
  );
}

export default {
  setAudioSpeedByBookId,
  setFontName,
  setFontSize,
  setLineSpacing,
  setReadingThemeName,
  setExpandedNotesModeByBookId,
  setExpandedFootnotesMode,
  setGoogleTranslateMode,
  setDictionaryMode,
  setWikiMode,
  setDictionaryAutoOpen,
  setHighlightMode,
  setScrollingMode,
  setReadingPositionTracking,
  getPlaybackRate,
  getReadingSettings,
  getLocalStoredThemeName,
  isSettingsInstance: ReadingSettingsFactory.isSettingsInstance
};
