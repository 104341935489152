class OfflineSearchDetails {
  constructor(buildData) {
    this.size = buildData.size || 0;
    this.esIndexName = buildData.esIndexName || '';
    this.lang = buildData.lang || 'en';
    this.searchId = buildData.searchId || '';
    this.version = buildData.version || '';
    this.isValid = Boolean(buildData.searchId);
  }

  setIsValid(isValid) {
    this.isValid = isValid;
  }
}

class OfflineSearchDetailsBuilder {
  setBookSize(size) {
    this.size = size;
    return this;
  }
  setEsIndexName(esIndexName) {
    this.esIndexName = esIndexName;
    return this;
  }
  setLang(lang) {
    this.lang = lang;
    return this;
  }
  setSearchId(searchId) {
    this.searchId = searchId;
    return this;
  }
  setVersion(version) {
    this.version = version;
    return this;
  }

  build() {
    return new OfflineSearchDetails(this);
  }
}

class OfflineSearchDetailsView {
  constructor(offlineSearchDetails) {
    this.size = offlineSearchDetails.size;
    this.lang = offlineSearchDetails.lang;
    this.searchId = offlineSearchDetails.searchId;
  }
}

function getDetailsBuilder() {
  return new OfflineSearchDetailsBuilder();
}

function createOfflineSearchDetailsView(offlineSearchDetails) {
  return new OfflineSearchDetailsView(offlineSearchDetails);
}

export default {
  getDetailsBuilder,
  createOfflineSearchDetailsView
};
