import { VTextarea } from 'vuetify/lib';

export default {
  name: 'VTextareaOverrides',
  extends: VTextarea,
  props: {
    rowHeight: {
      type: [Number, String],
      default: 28,
      validator: v => !isNaN(parseFloat(v))
    },
    maxRows: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    calculateInputHeight() {
      const input = this.$refs.input;
      if (!input) {
        return;
      }
      input.style.height = '0';
      const height = input.scrollHeight;
      const minHeight = parseInt(this.rows, 10) * parseFloat(this.rowHeight);
      if (this.maxRows) {
        input.style.overflow = 'auto';
        input.style.height =
          Math.min(
            Math.max(minHeight, height),
            parseInt(this.maxRows, 10) * parseFloat(this.rowHeight)
          ) + 'px';
        return;
      }
      input.style.height = Math.max(minHeight, height) + 'px';
    }
  }
};
