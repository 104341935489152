class MenuItem {
  constructor(buildData) {
    this.id = buildData.id;
    this.handler = buildData.handler || function defaultHandler() {};
    this.label = buildData.label || '';
    this.description = buildData.description || '';
    this.icon = buildData.icon || '';
    this.isVisible = buildData.isVisible;
    this.customClass = buildData.customClass || '';
    this.arrow = buildData.arrow || false;
    this.color = buildData.color || '';
    this.imgSrc = buildData.imgSrc || '';
    this.disabled = buildData.disabled || false;
    this.autoTestId = buildData.autoTestId || false;
  }
}

class MenuItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setHandler(handler) {
    this.handler = handler;
    return this;
  }

  setLabel(label) {
    this.label = label;
    return this;
  }

  setDescription(val) {
    this.description = val;
    return this;
  }

  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  setArrow(val) {
    this.arrow = val;
    return this;
  }

  setIsVisible(isVisible) {
    this.isVisible = isVisible;
    return this;
  }

  setColor(val) {
    this.color = val;
    return this;
  }

  setCustomClass(customClass) {
    this.customClass = customClass;
    return this;
  }

  setImgSrc(val) {
    this.imgSrc = val;
    return this;
  }

  setDisabled(disabled) {
    this.disabled = disabled;
    return this;
  }

  setAutoTestId(autoTestId) {
    this.autoTestId = autoTestId;
    return this;
  }

  build() {
    return new MenuItem(this);
  }
}

function getMenuItemBuilder() {
  return new MenuItemBuilder();
}

export default {
  getMenuItemBuilder
};
