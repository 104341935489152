<template>
  <Popup
    :content-class="'confirm-popup'"
    persistent
    :width="588"
    :content-image="contentImage"
    :content-title="contentTitle"
    :content-message="contentMessage"
    :secondary-footer-handler="cancelHandler"
    :secondary-label="$t('Popup.button.cancel.label')"
    :primary-footer-handler="confirmHandler"
    :primary-label="primaryLabel"
    :header-divider="false"
    :footer-divider="false"
    @popupEvent="popupEventHandler"
  >
  </Popup>
</template>

<script>
import Popup from '@/components/base/Popup/Popup';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ConfirmationActions from '@/enums/ConfirmationActionsEnum';

export default {
  name: PopupNamesEnum.CONFIRM_POPUP,
  components: {
    Popup
  },
  props: {
    popupContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    contentImage() {
      return this.popupContext.contentImage || '';
    },
    contentTitle() {
      return (
        this.popupContext.title ||
        this.$t('ConfirmPopup.contentTile.default.label')
      );
    },
    contentMessage() {
      return (
        this.popupContext.message ||
        this.$t('ConfirmPopup.contentMessage.default.label')
      );
    },
    primaryLabel() {
      return this.popupContext.primaryLabel || this.$t('Popup.button.ok.label');
    }
  },
  methods: {
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
        default:
          break;
      }
    },
    cancelHandler() {
      this.$_close();
    },
    confirmHandler() {
      this.$_emit(ConfirmationActions.CONFIRM, {
        data: this.popupContext.data,
        type: this.popupContext.type
      });
      this.$_close();
    },
    $_close() {
      this.$_emit(PopupEventEnum.CLOSE, { name: PopupNamesEnum.CONFIRM_POPUP });
    },
    $_emit(type, data) {
      this.$emit('popupEvent', {
        type,
        data
      });
    }
  }
};
</script>

<style lang="less" src="./ConfirmPopup.less"></style>
