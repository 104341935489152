import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FeatureDetectorService.js');

import PromiseUtil from '@/services/utils/PromiseUtil';

const initDefer = PromiseUtil.createAutoDropDefer(
  100_000,
  'wait init FeatureDetectorService'
);
const detections = {};

let modernizrLoaded = false;
async function loadModernizr() {
  if (!modernizrLoaded) {
    await import('@/services/FeatureDetector/modernizr.custom.js');
  }
  modernizrLoaded = true;
}

async function init() {
  if (process.client) {
    const needWait = ['indexeddb'];
    await loadModernizr();
    const promises = needWait.map(testName => waiteTest(testName));
    await Promise.all(promises);
    detections.filesystem = Modernizr.filesystem;
    detections.indexDB = Modernizr.indexeddb;
    detections.hasTouchEvents = _checkTouchEvents();
  }
  initDefer.resolve();
}

function _checkTouchEvents() {
  return (
    process.client &&
    ('ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      window.navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0)
  );
}

function waiteTest(testName) {
  return new Promise(resolve => {
    let timer = null;
    const MAX_TIME = 200;
    Modernizr.on(testName, function(result) {
      clearTimeout(timer);
      resolve({
        [testName]: result
      });
    });

    timer = setTimeout(() => {
      logger.warn(
        `waite ${MAX_TIME}ms for test ${testName} set false as default.`
      );
      resolve({
        [testName]: false
      });
    }, 200);
  });
}

async function getDetections() {
  if (process.server) {
    initDefer.resolve({});
  }

  await initDefer.promise;
  return detections;
}

async function filesystem() {
  if (process.server) {
    return false;
  }
  await loadModernizr();
  return process.client ? Modernizr.filesystem : false;
}

export default {
  init,
  filesystem,
  getDetections
};
