/*jshint unused: vars*/
'use strict';

var dictTools = require('./dictTools.js');
var searchEngine = require('./searchEngine.js');
var dictUtils = require('./dictUtils.js');
var indexUtils = require('./indexUtils.js');

var wordIndex = {
  dictNames: []
};

function getDictionaryIndexes(dictIds, mapperMap) {
  return indexUtils
    .getDictionaryIndexes(dictIds, mapperMap)
    .then(function(reponse) {
      wordIndex = reponse.wordIndex;
      return reponse;
    });
}

function getTermNames(word, searchDictIndex, numberTerm) {
  var termNames;
  var stem;
  var letter = searchEngine.removeDiacritic(word)[0];
  var wordSearchIndex = searchDictIndex[''][letter];
  var stemSearchIndex = searchDictIndex.stem[letter];
  var wordsIndex = searchEngine.findWordsIndexByPrefix(word, wordSearchIndex);
  if (wordsIndex.length === 0) {
    stem = dictUtils.stemming(word, 'en').toUpperCase();
    wordsIndex = searchEngine.findWordsIndexByPrefix(stem, stemSearchIndex);
  }

  if (wordsIndex.length === 0) {
    return [];
  }

  termNames = [];
  var uniqueWords = {};
  wordsIndex.forEach(function(wordIndexItem) {
    var termName = searchEngine.getTermFromWordIndex(wordIndexItem);
    if (
      termNames.length !== numberTerm &&
      !uniqueWords.hasOwnProperty(termName)
    ) {
      uniqueWords[termName] = null;
      termNames.push(termName);
    }
  });
  return termNames;
}

function getDictionaryTermsByPrefix(word, dictNames, numberTerm) {
  var res = {
    termNames: []
  };
  dictNames.forEach(function(dictName) {
    var searchDictIndex = wordIndex[dictName];
    [].push.apply(
      res.termNames,
      getTermNames(word, searchDictIndex, numberTerm)
    );
  });
  return res;
}

function createDefMap(
  definitions,
  wordsIndex,
  query,
  currenDictName,
  dictMeta
) {
  var definitionsResp = [];
  var partOfspeech = query.partOfspeech || '';
  // var dictionaryId = query.dictionaryId || ''; //TODO: used in flash cards
  definitions.forEach(function(definition, index) {
    definition = dictTools.remapPropety(definition, dictMeta);
    var currentPartOfspeech = dictTools.getPartOfSpeech(definition);
    var obj = {};

    var term = searchEngine.getTermFromWordIndex(wordsIndex[index]);
    obj[term] = definition;
    definition.dictionaryId = currenDictName;

    Object.assign(definition, {
      inFlashcards: false,
      index: index,
      dictType: dictMeta.dictType
    });
    definition = dictTools.reGroupProp(definition);

    if (currentPartOfspeech.indexOf(partOfspeech) === 0) {
      definitionsResp.push(definition);
    }
  });
  return {
    definition: definitionsResp,
    dictName: dictMeta.name
  };
}

function readDifinitions(
  word,
  searchDictIndex,
  currenDictName,
  query,
  dictMeta
) {
  var stem;
  var letter = searchEngine.removeDiacritic(word)[0];
  var wordSearchIndex = searchDictIndex[''][letter];
  var stemSearchIndex = searchDictIndex.stem[letter];
  var wordsIndex = [];

  if (wordSearchIndex) {
    wordsIndex = searchEngine.findWordsIndexExactMatch(word, wordSearchIndex);
  }

  if (wordsIndex.length === 0 && stemSearchIndex) {
    stem = dictUtils.stemming(word, 'en').toUpperCase();
    wordsIndex = searchEngine.findWordsIndexExactMatch(stem, stemSearchIndex);
  }

  if (wordsIndex.length === 0) {
    return Promise.resolve({
      definition: [],
      dictName: dictMeta.name
    });
  }

  var rangesArray = wordsIndex.map(searchEngine.getOffsetFromWordIndex);
  var definitionFileName = 'definition.txt';

  return indexUtils
    .readByRange(definitionFileName, currenDictName, rangesArray)
    .then(function(response) {
      var isValid = indexUtils.checkIsValidResponse(response);
      if (isValid) {
        return createDefMap(
          response,
          wordsIndex,
          query,
          currenDictName,
          dictMeta
        );
      } else {
        return response;
      }
    });
}

function getDifinition(query, dictNames, dictsMeta) {
  var word = query.dictionaryTermName.trim().toUpperCase();
  var defPromises = dictNames.map(function(dictName) {
    var searchDictIndex = wordIndex[dictName];
    return readDifinitions(
      word,
      searchDictIndex,
      dictName,
      query,
      dictsMeta[dictName]
    );
  });

  return Promise.all(defPromises).then(function(definitionsResp) {
    var term = dictTools.getTermFromDefinitionsResp(definitionsResp);
    var validResp = [];
    var invalidResponses = [];
    definitionsResp.forEach(function(defResp) {
      var isValid = indexUtils.checkIsValidResponse(defResp);
      var isEmpty = defResp.definition.length === 0;
      if (isValid && !isEmpty) {
        validResp.push(defResp);
      } else if (!isEmpty) {
        invalidResponses.push([defResp]);
      }
    });
    var dictionaryDefinition = {
      definitions: validResp,
      term: term,
      searchTerm: word,
      count: validResp.length
    };
    return {
      dictionaryDefinition: dictionaryDefinition,
      invalidResponses: invalidResponses
    };
  });
}

function searchDictionaryTerms(word, numberTerms, dictNames) {
  return getDictionaryTermsByPrefix(word, dictNames, numberTerms);
}

function getDictionaryDefinition(query, dictNames, dictsMeta) {
  return getDifinition(query, dictNames, dictsMeta);
}

function getDictNames() {
  return wordIndex.dictNames;
}

function setDataProvider(provider) {
  indexUtils.setProvider(provider);
}

module.exports = {
  setDataProvider: setDataProvider,
  getDictionaryIndexes: getDictionaryIndexes,
  searchDictionaryTerms: searchDictionaryTerms,
  getDictionaryDefinition: getDictionaryDefinition,
  getDictNames: getDictNames
};
