<template>
  <div class="assessment-page-title">{{ title }}</div>
</template>

<script>
export default {
  name: 'VocabularyAssessmentPageTitleControl',
  computed: {
    title() {
      return this.$store.getters[
        'VocabularyAssessmentStore/getAssessmentPageHeader'
      ]?.title;
    }
  }
};
</script>

<style lang="less" scoped>
.assessment-page-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
</style>
