import CustomErrorEnum from '@/enums/CustomErrorEnum';

function parseStringToJson(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    const newError = new Error(error.message);
    newError.type = CustomErrorEnum.PARSE_ERROR;
    throw newError;
  }
}

function htmlToBlob(data) {
  return new Blob([new TextEncoder().encode(data)], {
    type: 'text/html'
  });
}

function bufferToArrayBuffer(buf) {
  var ab = new ArrayBuffer(buf.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
}

function objToBlob(data) {
  return new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json'
  });
}

function objToString(data) {
  return JSON.stringify(data);
}

function utf8ArrayToStr(content) {
  // http://www.onicos.com/staff/iz/amuse/javascript/expert/utf.txt
  var array = new Uint8Array(content);
  var out = '',
    i = 0,
    len = array.length,
    c;
  var char2, char3;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}

export default {
  bufferToArrayBuffer,
  parseStringToJson,
  objToString,
  utf8ArrayToStr,
  htmlToBlob,
  objToBlob
};
