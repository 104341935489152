const middleware = {}

middleware['initApp'] = require('../src/app-reader/middleware/initApp.js')
middleware['initApp'] = middleware['initApp'].default || middleware['initApp']

middleware['redirect'] = require('../src/app-reader/middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['router'] = require('../src/app-reader/middleware/router.js')
middleware['router'] = middleware['router'].default || middleware['router']

middleware['theme'] = require('../src/app-reader/middleware/theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
