<template>
  <div class="avatar-control-wrapper" @click="toggleAvatarMenu">
    <BaseButton
      class="rounded-pill mini-navigate-icon"
      outlined
      elevation="0"
      :title="$t('Accessibility.mainMenu.tooltip')"
      :aria-label="$t('Accessibility.mainMenu')"
    >
      <BaseSpriteIcon
        v-if="isOpenAppMenu"
        icon-name="ico-close"
        custom-class="list-icon"
      />
      <BaseSpriteIcon
        v-else
        icon-name="ico-menu"
        custom-class="burger-menu-icon"
      />
      <UserAvatar />
    </BaseButton>
  </div>
</template>

<script>
import UserAvatar from '@/components/views/UserAvatar/UserAvatar';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

const popupName = PopupNamesEnum.APP_MENU_WRAPPER;

export default {
  name: 'AvatarControl',
  components: {
    UserAvatar,
    BaseButton,
    BaseSpriteIcon
  },
  computed: {
    isOpenAppMenu() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](popupName);
    }
  },
  methods: {
    toggleAvatarMenu() {
      const action = this.isOpenAppMenu
        ? 'ManagePopupStore/closePopup'
        : 'ManagePopupStore/openPopup';

      this.$store.dispatch(action, {
        name: popupName
      });
    }
  }
};
</script>

<style lang="less" src="./AvatarControl.less"></style>
