'use strict';
import XRegExp from 'xregexp';
const RegEx = XRegExp.default || XRegExp;

var tokenizArRe = RegEx('[\\p{Arabic}\\p{M}]+', 'gi');
var diacriticalMarks;

String.prototype.equals = function(somestring) {
  // jshint ignore:line
  /*Compares this string to the specified object. 
      The result is true if and only if the argument 
      is not null and is a String object that represents
      the same sequence of characters as this object.*/
  var arg = String.prototype.toString.call(this, arguments);
  var somestringType = typeof somestring;
  if (somestringType === undefined || somestringType === null) {
    return false;
  }
  return arg === somestring;
};

String.prototype.delete = function(start, end) {
  // jshint ignore:line
  /*Removes the characters in a substring of this sequence.
       The substring begins at the specified start and extends 
       to the character at index end - 1 or to the end of the 
       sequence if no such character exists. If start is equal 
       to end, no changes are made.*/
  var arg = Array.prototype.slice.call(this, arguments);
  if (
    typeof start === 'number' &&
    typeof end === 'number' &&
    start < end &&
    start >= 0 &&
    end >= 0
  ) {
    arg.splice(start, end - start);
  }
  return arg.join('');
};

String.prototype.deleteCharAt = function(index) {
  // jshint ignore:line
  /*Removes the char at the specified position in
       this sequence. This sequence is shortened by one
       char.*/
  var arg = Array.prototype.slice.call(this, arguments);
  if (typeof index === 'number' && index >= 0) {
    arg.splice(index, 1);
  }
  return arg.join('');
};

function PersianStemmerLight() {
  /**
   * A cache of words and their stems
   */
  var cache = {};
  /**
   * A buffer of the current word being stemmed
   */
  var sb = '';
  /**
   * Default constructor
   */
  //not nessesary in js case

  this.getStem = function(word) {
    var result = cache[word];

    if (result !== undefined) {
      return result;
    }

    sb = sb.delete(0, sb.length);

    sb += word;

    sb = removeKasra(sb);

    sb = removeSuffix(sb);

    sb = removeKasra(sb);

    result = sb.toString();
    cache[word] = result;

    return result;
  };

  var removeSuffix = function(word) {
    var bullit =
      '\\u064b\\u064c\\u064d\\u064e\\u064f\\u0650\\u0651\\u0652\\u0653\\u0670';
    var shadda =
      '\\ufc5t\\ufc5f\\ufc60\\ufc61\\ufc62\\ufc63\\uft70\\uft72\\uft76\\uft74\\uft78\\uft7A\\uft7c\\uft7t';
    var kashida =
      '\\ufcf2\\ufcf3\\ufcf4\\uft71\\uft77\\uft79\\uft7B\\uft7D\\uft7f';
    var diacriticalMarksRe = new RegExp(
      '[' + bullit + shadda + kashida + ']+',
      'gi'
    );
    word = word.replace(diacriticalMarksRe, '');

    var len = word.length - 1;
    if (len > 6) {
      if (
        word.substring(len - 3, len + 1).equals('\u062A\u0631\u06CC\u0646') ||
        word.substring(len - 3, len + 1).equals('\u0622\u0628\u0627\u062F') ||
        word.substring(len - 3, len + 1).equals('\u062A\u0631\u064A\u0646')
      ) {
        word = word.delete(len - 3, len + 1);
        return word;
      }
      if (
        word.substring(len - 3, len + 1).equals('\u06AF\u064A\u0631\u064A') ||
        word.substring(len - 3, len + 1).equals('\u0647\u0627\u064A\u064A') ||
        word.substring(len - 3, len + 1).equals('\u0647\u0627\u06CC\u06CC') ||
        word.substring(len - 3, len + 1).equals('\u06AF\u06CC\u0631\u06CC') ||
        word.substring(len - 3, len + 1).equals('\u0633\u0627\u0632\u064A') ||
        word.substring(len - 3, len + 1).equals('\u0633\u0627\u0632\u06CC') ||
        word.substring(len - 3, len + 1).equals('\u0631\u064A\u0632\u064A') ||
        word.substring(len - 3, len + 1).equals('\u0631\u06CC\u0632\u06CC') ||
        word.substring(len - 3, len + 1).equals('\u0628\u0646\u062F\u064A') ||
        word.substring(len - 3, len + 1).equals('\u0628\u0646\u062F\u06CC') ||
        word.substring(len - 3, len + 1).equals('\u0622\u0628\u0627\u062F') ||
        word.substring(len - 3, len + 1).equals('\u0628\u0627\u0631\u0647')
      ) {
        word = word.delete(len - 3, len + 1);
        return word;
      }
    } /* end if len > 6 */
    if (len > 5) {
      if (
        word.substring(len - 2, len + 1).equals('\u0647\u0627\u064A') ||
        word.substring(len - 2, len + 1).equals('\u0647\u0627\u06CC') ||
        word.substring(len - 2, len + 1).equals('\u0627\u0646\u062F') ||
        word.substring(len - 2, len + 1).equals('\u0627\u064A\u0645') ||
        word.substring(len - 2, len + 1).equals('\u0627\u06CC\u0645') ||
        word.substring(len - 2, len + 1).equals('\u0634\u0627\u0646')
      ) {
        word = word.delete(len - 2, len + 1);
        return word;
      }
    } /* end if len > 5 */
    if (len > 4) {
      if (word.substring(len - 1, len + 1).equals('\u0627\u0646')) {
        word = word.delete(len - 1, len + 1);
        word = normalize1(word);
        return word;
      }
      if (
        word.substring(len - 1, len + 1).equals('\u0647\u0627') ||
        word.substring(len - 1, len + 1).equals('\u06CC\u0646') ||
        word.substring(len - 1, len + 1).equals('\u064A\u0646') ||
        word.substring(len - 1, len + 1).equals('\u0627\u062A') ||
        word.substring(len - 1, len + 1).equals('\u0647\u0621') ||
        word.substring(len - 1, len + 1).equals('\u0627\u0634') ||
        word.substring(len - 1, len + 1).equals('\u062A\u0631') ||
        word.substring(len - 1, len + 1).equals('\u0631\u0627') ||
        word.substring(len - 1, len + 1).equals('\u0648\u0646') ||
        word.substring(len - 1, len + 1).equals('\u0627\u0645')
      ) {
        word = word.delete(len - 1, len + 1);
        return word;
      }
    } /* end if len > 4 */
    if (len > 2) {
      if (
        word.charAt(len) === '\u0647' ||
        word.charAt(len) === '\u06CC' ||
        word.charAt(len) === '\u064A' ||
        word.charAt(len) === '\u0645' ||
        word.charAt(len) === '\u062A' ||
        word.charAt(len) === '\u0634'
      ) {
        word = word.deleteCharAt(len);
        return word;
      }
    }
    return word;
  };
  var normalize1 = function(word) {
    var len = word.length - 1;
    if (len > 2) {
      if (
        word.charAt(len) === '\u0649' ||
        word.charAt(len) === '\u06AF' ||
        word.charAt(len) === '\u0645' ||
        word.charAt(len) === '\u062A' ||
        word.charAt(len) === '\u0631' ||
        word.charAt(len) === '\u0634'
      ) {
        word = word.deleteCharAt(len);
        word = normalize2(word);
      }
    }
    return word;
  };
  var removeKasra = function(word) {
    var len = word.length - 1;
    if (len > 3) {
      if (word.charAt(len) === '\u0650') {
        word = word.deleteCharAt(len);
        return word;
      }
    } /* end if len > 3 */
    return word;
  };
  var normalize2 = function(word) {
    var len = word.length - 1;
    if (len > 2) {
      if (word.charAt(len) === '\u06CC' || word.charAt(len) === '\u064A') {
        word = word.deleteCharAt(len);
        return word;
      }
    }
    return word;
  };
}

function stemmer(token) {
  var persianStemmerLight = new PersianStemmerLight();
  return persianStemmerLight.getStem(token);
}

function tokenizer(str) {
  var tokens = str.match(tokenizArRe);
  return tokens || [];
}

var _createDiacriticRe = function() {
  var bullit =
    '\\u064b\\u064c\\u064d\\u064e\\u064f\\u0650\\u0651\\u0652\\u0653\\u0670';
  var shadda =
    '\\ufc5e\\ufc5f\\ufc60\\ufc61\\ufc62\\ufc63\\ufe70\\ufe72\\ufe76\\ufe74\\ufe78\\ufe7A\\ufe7c\\ufe7e';
  var kashida =
    '\\ufcf2\\ufcf3\\ufcf4\\ufe71\\ufe77\\ufe79\\ufe7B\\ufe7D\\ufe7f';
  diacriticalMarks = new RegExp('[' + bullit + shadda + kashida + ']+', 'gi');
};

function replaceDiacritic(someString) {
  var diacriticalMarksRe = diacriticalMarks || _createDiacriticRe();
  someString = someString
    .replace(diacriticalMarksRe, '')
    .replace(/\s{2,}/, ' ')
    .trim();
  return someString;
}

function soundEx(s) {
  //http://research.ijcaonline.org/volume34/number10/pxc3876054.pdf
  var a = s
    .replace(/^[إآأا]/g, '')
    .replace(/^[aeiouy']{1,}/g, '')
    .split('');
  var r = '',
    codes = {
      ف: 1,
      ب: 1,
      خ: 2,
      ج: 2,
      ز: 2,
      س: 2,
      ص: 2,
      ظ: 2,
      ق: 2,
      ك: 2,
      ت: 3,
      ث: 3,
      د: 3,
      ذ: 3,
      ض: 3,
      ط: 3,
      ل: 4,
      م: 5,
      ن: 5,
      ر: 6,
      b: 1,
      f: 1,
      p: 1,
      v: 1,
      w: 1,
      c: 2,
      g: 2,
      j: 2,
      k: 2,
      q: 2,
      s: 2,
      x: 2,
      z: 2,
      d: 3,
      t: 3,
      l: 4,
      m: 5,
      n: 5,
      r: 6
    };
  var previuseChar = '';
  a.forEach(function(character) {
    if (
      codes.hasOwnProperty(character) &&
      (previuseChar.length === 0 || previuseChar !== codes[character])
    ) {
      previuseChar = codes[character];
      r += codes[character];
    }
  });

  if (r.length !== 0) {
    r = (r + '000').slice(0, 4);
  }
  return r;
}

export default {
  stemmer: stemmer,
  tokenizer: tokenizer,
  replaceDiacritic: replaceDiacritic,
  soundEx: soundEx
};
