import images from '@/assets/images';

export default {
  data() {
    return {
      emptyCover: images.emptyCover
    };
  },
  directives: {
    'cover-fallback': {
      bind: function(element) {
        element.onerror = function() {
          if (element.getAttribute('src') !== images.emptyCover) {
            element.setAttribute('src', images.emptyCover);
          }
        };
      },
      inserted: function(element) {
        if (element.getAttribute('src') === null) {
          element.setAttribute('src', images.emptyCover);
        }
      }
    }
  }
};
