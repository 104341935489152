const sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'extralarge'
};

const sizeMap = {
  small: '96x126',
  medium: '146x194',
  large: '192x252',
  extralarge: '304x400'
};

module.exports = { sizes, sizeMap };
