import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';
import TrackingItem from './TrackingItem';
import { sizeMap } from '@shared/enums/ThumbSizesEnum';
import { FileAccess } from './FileAccess';
import PromiseUtil from '@/services/utils/PromiseUtil';
import { ReadOptionsHelper } from './ReadOptions';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AbstractAssetReader.js');

import FileTransport from '@/services/AssetsManager/FileTransport/FileTransport';
class AbstractAssetReader {
  constructor(coverExtension, audioExtension) {
    this.audioExtension = audioExtension || '.m4a';
    this.coverExtension = coverExtension || '.png';
    this.trackingItemsMap = {};
    this.readOptionsHelper = new ReadOptionsHelper();
    this.allResources = [];

    this.getAssetNameContentList = getAssetNameContentList;
    this.getAssetNameAudioList = getAssetNameAudioList;
    this.getAssetNameList = getAssetNameList;
  }

  async fileTransportAvailable() {
    const promises = this.allResources.map(accessType =>
      this.isAvailable(accessType)
    );
    const resAvailable = await Promise.all(promises);
    return resAvailable.every(isAvailable => isAvailable);
  }

  isAvailable(accessType) {
    const fileAccess = this._createFileAccess(null, accessType);
    const available = FileTransport.isAvailable(fileAccess);
    return available;
  }

  async getRealFileName(publicationId, fileName) {
    const publicationTrackingItem = await this._getTrackingItemById(
      publicationId
    );
    return publicationTrackingItem.getFileRealFileName(fileName);
  }

  getPublicationTrackingItemView(publicationId) {
    return this._getTrackingItemById(publicationId).then(function(
      trackingItem
    ) {
      const viewBuilder = TrackingItem.getPublicationTrackingItemViewBuilder();

      viewBuilder
        .setId(trackingItem.id)
        .setIsContentDownloaded(isContentDownloaded(trackingItem))
        .setHasAudio(trackingItem.isAudioPublication)
        .setIsAudioDownloaded(isAudioDownloaded(trackingItem))
        .setIsAudioOnSDCard(isAudioOnSDCard(trackingItem))
        .setOutdatedData(checkOutdatedData(trackingItem))
        .setIsStartedDownload(false)
        .setTotalFiles(trackingItem.mapper.assetInfo.totalFiles)
        .setProgress(trackingItem.progress);
      return viewBuilder.build();
    });
  }

  getMapperById(publicationId) {
    return this._getTrackingItemById(publicationId).then(function(
      trackingItem
    ) {
      return trackingItem.mapper;
    });
  }

  getCoverPath(pubId, size) {
    const logicalName = this._getCoverLogicalNameBySize(size);
    return this.getFileSourcePath(pubId, logicalName, AssetTypeEnum.COVER);
  }

  _getCoverLogicalNameBySize(size) {
    return `cover_${sizeMap[size]}`;
  }
  /**
   *
   *
   * @param {String} publicationId
   * @param {coverMap} coverMap map with key logical name and value physical name
   * @param {String} size from ThumbSizesEnum
   * @param {String} accessType from accessTypeEnum
   * @param Object options additional options
   * @returns
   * @memberof AbstractAssetReader
   */
  buildBookCoverPath(publicationId, coverMap, size, accessType, options = {}) {
    const logicalName = this._getCoverLogicalNameBySize(size);
    const fullFileName = this._createFullFileNameByAsset(
      logicalName,
      AssetTypeEnum.COVER,
      options['fileFormat']
    );
    if (!coverMap) {
      logger.warn(`${publicationId} doesn't have a cover`);
      return '';
    }
    const filePath = [publicationId, coverMap[fullFileName]].join('/');
    const fileAccess = this._createFileAccess(filePath, accessType);
    const readOptions = this.readOptionsHelper.getBinaryOptions();

    return FileTransport.createFileSourcePath(fileAccess, readOptions);
  }

  readLibraryFile(fileName, accessType) {
    if (
      !this.allResources.includes(accessType) &&
      !this.tempSources.includes(accessType)
    ) {
      return Promise.reject(
        new Error(
          `unsuported accessType: ${accessType} for read readLibraryFile fileName:${fileName}`
        )
      );
    }
    const fileAccess = this._createFileAccess(fileName, accessType);
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();

    return FileTransport.readFile(fileAccess, readOptions);
  }

  checkInternalMemory(accessType, excludeEntries) {
    const fileAccess = this._createFileAccess(null, accessType);
    return FileTransport.getInternalMemory(fileAccess, excludeEntries);
  }

  getFileInfo(fileName, accessType) {
    const fileAccess = this._createFileAccess(fileName, accessType);
    return FileTransport.getFileInfo(fileAccess);
  }

  getFileSourcePath(bookId, fileName, assetType) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        const fullFileName = self._createFullFileNameByAsset(
          fileName,
          assetType
        );
        return self._getFilePath(fullFileName, publicationTrackingItem);
      });
  }

  getAssetSource(bookId, assetName) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        return publicationTrackingItem.getAssetSourceByName(assetName);
      });
  }

  // eslint-disable-next-line no-unused-vars
  initBookAssets(bookId) {
    throw new Error('need implement initBookAssets');
  }

  // eslint-disable-next-line no-unused-vars
  readFile(bookId, fileName) {
    throw new Error('need implement readFile');
  }

  // eslint-disable-next-line no-unused-vars
  readFileByChunk(bookId, fileName, chunkOffset) {
    throw new Error('need implement readFileByChunk');
  }
  // eslint-disable-next-line no-unused-vars
  readBookMetaFile(bookId) {
    throw new Error('need implement readBookMetaFile');
  }
  // eslint-disable-next-line no-unused-vars
  readAudioFile(bookId, fileName) {
    throw new Error('need implement readAudioFile');
  }
  // eslint-disable-next-line no-unused-vars
  readAlignmentIndex(bookId) {
    throw new Error('need implement readAlignmentIndex');
  }
  // eslint-disable-next-line no-unused-vars
  pauseMapFileName(bookId) {
    throw new Error('need implement pauseMapFileName');
  }
  // eslint-disable-next-line no-unused-vars
  isAssetDownloaded(publicationId, assetName) {
    throw new Error('need implement isAssetDownloaded');
  }
  // eslint-disable-next-line no-unused-vars
  downloadAsset(publicationId, assetName) {
    throw new Error('need implement downloadAsset');
  }
  // eslint-disable-next-line no-unused-vars
  readContentIndexes(bookId) {
    throw new Error('need implement readContentIndexes');
  }
  // eslint-disable-next-line no-unused-vars
  bookContentByChunk(bookId, chunkOffset) {
    throw new Error('need implement bookContentByChunk');
  }
  // eslint-disable-next-line no-unused-vars
  bookAlignmentByChunk(bookId, chunkOffset) {
    throw new Error('need implement bookAlignmentByChunk');
  }

  removeAll() {}

  // eslint-disable-next-line no-unused-vars
  downloadPublication(publicationId) {}

  // eslint-disable-next-line no-unused-vars
  removePublication(publicationId) {}

  // eslint-disable-next-line no-unused-vars
  cancelDownload(publicationId) {}

  // eslint-disable-next-line no-unused-vars
  updatePublication(publicationId) {}

  // eslint-disable-next-line no-unused-vars
  addChangeListener(listener, id) {}

  // eslint-disable-next-line no-unused-vars
  removeDownloadListener(id) {}

  // eslint-disable-next-line no-unused-vars
  writeFile(filePath, data, source) {}

  _createFileAccess(filePath, source) {
    return new FileAccess(filePath, source);
  }

  _resetTrackingItemDefer(bookId) {
    const mapObj = this.getTrackingItemMapObj(bookId);
    mapObj.defer.reject(new Error(`Tracking item dropped ${bookId}`));
  }

  _setTrackingItem(bookId, trackingItem) {
    try {
      const mapObj = this.getTrackingItemMapObj(bookId);
      this.trackingItemsMap[bookId].trackingItem = trackingItem;
      mapObj.defer.resolve();
    } catch (error) {
      error.message = `This error can be produced because call _setTrackingItem before _addOrUpdateTrackingItemsMapObj or by some reason we already call destroyAllTrackingItem Error:${error.message}`;
      throw error;
    }
  }

  _addOrUpdateTrackingItemsMapObj(id) {
    const defer = PromiseUtil.createDeferred();
    const mapObj = this.getTrackingItemMapObj(id);
    if (mapObj) {
      mapObj.defer = defer;
      return;
    }
    this.trackingItemsMap[id] = {
      defer,
      trackingItem: null
    };
  }

  destroyTrackingItem(id) {
    const mapObj = this.getTrackingItemMapObj(id);
    if (mapObj) {
      mapObj.trackingItem = null;
    }
  }

  destroyAllTrackingItem() {
    this.trackingItemsMap = {};
  }

  async _getTrackingItemById(id) {
    const mapObj = this.getTrackingItemMapObj(id);
    if (mapObj) {
      await mapObj.defer.promise;
      if (mapObj.trackingItem) {
        return mapObj.trackingItem;
      }
    }

    return this.initBookAssets(id);
  }

  hasTrackingItemById(id) {
    const mapObj = this.getTrackingItemMapObj(id);
    return mapObj?.trackingItem;
  }

  getTrackingItemMapObj(id) {
    return this.trackingItemsMap[id];
  }

  _createFullFileNameByAsset(fileName, assetType, fileFormat = null) {
    switch (assetType) {
      case AssetTypeEnum.IMAGE:
      case AssetTypeEnum.COVER:
        return fileName + (fileFormat || this.coverExtension);
      case AssetTypeEnum.AUDIO:
        return fileName + this.audioExtension;
      case AssetTypeEnum.ALIGNMENT:
      case AssetTypeEnum.CONTENT:
        return fileName;
      default:
        throw new Error(
          `Get unsupported assetType: ${assetType} in _createFullFileNameByAsset`
        );
    }
  }
}

function getAssetNameContentList(isAudioBook) {
  const assetNameContentList = [
    AssetTypeEnum.CONTENT,
    AssetTypeEnum.COVER,
    AssetTypeEnum.IMAGE
  ];
  if (isAudioBook) {
    assetNameContentList.push(AssetTypeEnum.ALIGNMENT);
  }
  return assetNameContentList;
}

function getAssetNameAudioList() {
  return [AssetTypeEnum.AUDIO];
}

function getAssetNameList(isAudioBook) {
  const contentAssetNameList = getAssetNameContentList(isAudioBook);
  if (isAudioBook) {
    return [...contentAssetNameList, ...getAssetNameAudioList()];
  }
  return contentAssetNameList;
}

function isContentDownloaded(trackingItem) {
  const contentAssetNameList = getAssetNameContentList(
    trackingItem.isAudioPublication
  );
  return contentAssetNameList.every(function(assetName) {
    return _isDownloaded(trackingItem, assetName);
  });
}

function isAudioDownloaded(trackingItem) {
  if (!trackingItem.isAudioPublication) {
    return false;
  }
  const audioAssetNameList = getAssetNameAudioList();
  return audioAssetNameList.every(function(assetName) {
    return _isDownloaded(trackingItem, assetName);
  });
}

function _isDownloaded(trackingItem, assetName) {
  return (
    trackingItem.isAssetAvailable(assetName, AssetResourcesEnum.FS) ||
    trackingItem.isAssetAvailable(assetName, AssetResourcesEnum.SD_CARD)
  );
}

function isAudioOnSDCard(trackingItem) {
  if (!trackingItem.isAudioPublication) {
    return false;
  }
  return trackingItem.isAssetAvailable(
    AssetTypeEnum.AUDIO,
    AssetResourcesEnum.SD_CARD
  );
}

function checkOutdatedData(trackingItem) {
  return trackingItem.updateInfo.hasUpdates();
}

export { AbstractAssetReader };
