<template>
  <div>
    <div v-if="showLookUp">
      <LookUp
        :word="word"
        :phrase="phrase"
        @lookUpEvent="lookUpEventHandler"
        @popupEvent="popupEventHandler"
      />
    </div>
  </div>
</template>

<script>
import PopupEventEnum from '@/enums/PopupEventEnum';
import LookUp from '@/components/views/LookUp/LookUp';
import lookUpEventEnum from '@/enums/lookUpEventEnum';

export default {
  name: 'Dictionary',
  components: {
    LookUp
  },
  data() {
    return {
      showLookUp: false,
      word: null,
      currentDefinitions: '',
      currentFullDefinitions: '',
      endSubscription: null
    };
  },
  computed: {},
  watch: {
    $route() {
      this.showLookUp = false;
    }
  },
  async mounted() {
    await this.$store.dispatch('DictionaryStore/downloadDict');
    await this.$store.dispatch('DictionaryStore/uploadToMemoryIndexes');
    this.endSubscription = this.$store.subscribeAction({
      after: action => {
        const type = action.type;
        switch (type) {
          case 'DictionaryStore/findShortDefinitionBySelection':
            this.showLookUp = false;
            this.$nextTick(() => {
              this.word = this.$store.getters['DictionaryStore/getCurrentWord'];
              this.phrase = this.$store.getters[
                'DictionaryStore/getCurrentPhrase'
              ];
              this.showLookUp = true;
            });
            break;
          default:
            break;
        }
      }
    });
  },
  destroyed() {
    if (this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    lookUpEventHandler(payload) {
      const { type } = payload;
      switch (type) {
        case lookUpEventEnum.EMPTY_REPONSE:
          this.showLookUp = false;
          break;
        default:
          break;
      }
    },
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.showLookUp = false;
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import 'Dictionary.less';
</style>
