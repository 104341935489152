<template>
  <popup
    :content-class="'popup-make-default-compilation'"
    scrollable
    persistent
    :content-image="contentImage"
    :content-title="contentTitle"
    :content-message="contentDescription"
    :primary-label="primaryLabel"
    :secondary-label="$t('Popup.button.cancel.label')"
    :primary-footer-handler="makeAsDefaultCompilation"
    :secondary-footer-handler="cancelMakeAsDefaultCompilation"
    :header-divider="false"
    :footer-divider="false"
    width="588"
    @popupEvent="popupEventHandler"
  ></popup>
</template>

<script>
import Popup from '@/components/base/Popup/Popup';
import images from '@/assets/images';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';

export default {
  name: PopupNamesEnum.COMPILATION_MAKE_DEFAULT_POPUP,
  components: { Popup },
  props: {
    popupContext: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      contentImage: '',
      compilation: {},
      contentTitle: '',
      contentDescription: '',
      primaryLabel: ''
    };
  },
  mounted() {
    this.compilation = this.popupContext.compilation || {};

    this.$_setTranslations();
    this.$_setContentImage();
  },
  methods: {
    makeAsDefaultCompilation() {
      this.$store.dispatch('CompilationsStore/changeDefaultCompilation', {
        compilationId: this.compilation.id,
        isDefault: !this.compilation.default
      });
      this.$_close();
    },
    cancelMakeAsDefaultCompilation() {
      this.$_close();
    },
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
        default:
          break;
      }
    },
    $_setTranslations() {
      const translationMarker = this.compilation.default
        ? 'makeNotDefault'
        : 'makeDefault';
      this.contentTitle = this.$t(
        `CompilationMakeDefault.popup.${translationMarker}.title`,
        {
          compilationTitle: this.compilation.title
        }
      );
      this.contentDescription = this.$t(
        `CompilationMakeDefault.popup.${translationMarker}.description`
      );
      this.primaryLabel = this.$t(
        `CompilationMakeDefault.popup.${translationMarker}.button.label`
      );
    },
    $_setContentImage() {
      this.contentImage = this.compilation.default
        ? images.picCancel
        : images.picBulb;
    },
    $_close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.COMPILATION_MAKE_DEFAULT_POPUP }
      });
    }
  }
};
</script>

<style scoped></style>
