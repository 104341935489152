// import zipObject from 'lodash/zipObject';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import identity from 'lodash/identity';
import extend from 'lodash/extend';
import each from 'lodash/each';
import keys from 'lodash/keys';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import reduce from 'lodash/reduce';
import LocalStorageService from './LocalStorageService';
import RequestService from '@/services/RequestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UnifiedSettingService.js');

var _cache = {};
var _definitions = {};

var DEFAULT_DEFINITION = {
  $version: 0,
  $writable: true,
  $saveOnSet: false,
  $entityType: null,
  $defaultValue: null,
  $localStorage: false,
  $groupMapper: identity,
  $customProcessor: identity,
  $versionConverter: identity
};

function init(context) {
  try {
    const definitions = context.parameters.SettingsDefinition;
    const configureData = { definitions, $groupMapper: _detectKey };
    _configure(configureData);
  } catch (err) {
    logger.error(
      `Error while configuring Unified Settings Service. Error: ${err}`
    );
  }
}

function _detectKey(key) {
  // TODO: add key with branchName & userID
  return identity(key);
}

function _configure(configureData) {
  const definitions = _prepareDefinitions(configureData);
  extend(_definitions, definitions);
}

function SettingGroup(groupName) {
  var definitions = _definitions[groupName];
  var defaultData =
    definitions &&
    Object.keys(definitions).reduce(
      (acc, key) => ({ ...acc, [key]: null }),
      {}
    );
  var storageKey = _detectKeyForGroup(groupName);
  var storageData = _readGroupFromLocalStorage(storageKey);
  this.$groupName = groupName;
  var data = extend(defaultData, storageData);
  _setPropForGroup(this, groupName, data);
  _cache[storageKey] = this;
}

function _prepareDefinitions(options) {
  var definitions = options.definitions || {};

  each(definitions, function(definition) {
    var defaultKeys = intersection(keys(DEFAULT_DEFINITION), keys(options));
    each(defaultKeys, key => {
      if (_isEmpty(definition[key])) {
        // $localStorage, $saveOnSet, $groupMapper, etc
        definition[key] = options[key];
      }
    });
  });
  return definitions;
}

function _setPropForGroup(settingGroup, groupName, data) {
  var props = {
    $groupName: {
      value: groupName
    }
  };

  props = reduce(data, _propertiesReducer, props);

  Object.defineProperties(settingGroup, props);

  function _propertiesReducer(memo, value, settingName) {
    var writable = _definitionForProp('$writable', groupName, settingName);
    if (
      settingName[0] === '$' ||
      (has(settingGroup, settingName) && !writable)
    ) {
      return memo;
    }

    if (_isEmpty(value)) {
      value = _definitionForProp('$defaultValue', groupName, settingName);
    }

    value = _wrapToEntity(/*groupName, settingName,*/ value);

    value = _customProcessor(groupName, settingName, value);

    memo[settingName] = {
      value: value,
      enumerable: true,
      writable: writable
    };
    return memo;
  }
}

function _wrapToEntity(/*groupName, settingName,*/ value) {
  return value;
  // var type = _definitionForProp('$entityType', groupName, settingName);
  // if (!type) {
  //   return value;
  // }

  // var _wrapper = partial(swEntityFactory.create, type);

  // if (Array.isArray(value)) {
  //   return _.map(value, _wrapper);
  // }
  // return _wrapper(value);
}

function _customProcessor(groupName, settingName, value) {
  var processor = _definitionForProp(
    '$customProcessor',
    groupName,
    settingName
  );
  return processor(value);
}

SettingGroup.prototype.set = async function(settingName, value, skipSync) {
  var groupName = this.$groupName;
  this[settingName] = value;

  //if (_definitionForProp('$saveOnSet', groupName, settingName)) {
  var data = {};
  data[settingName] = value;
  return await _storeSettings(groupName, data, skipSync);
  //}
};

SettingGroup.prototype.get = function(settingName) {
  return this[settingName];
};

SettingGroup.prototype.remove = function(settingName) {
  delete this[settingName];
};

SettingGroup.prototype.save = function() {
  let storageKey = _detectKeyForGroup(this.$groupName);
  LocalStorageService.set(storageKey, this);
};

SettingGroup.prototype.resetProperties = function() {
  const items = Object.keys(this);
  for (const key of items) {
    if (!/^\$/.test(key)) {
      this[key] = null;
    }
  }
};

function getGroup(groupName) {
  // concat groupName because we need unique cache-key for single group
  var storageKey = _detectKeyForGroup(groupName) + '_' + groupName;
  _cache[storageKey] = _cache[storageKey] || new SettingGroup(groupName);
  return _cache[storageKey];
}

function _detectKeyForGroup(groupName) {
  var _groupMapper = _definitionForProp('$groupMapper', groupName);
  return _groupMapper(groupName) + '_' + groupName;
}

function _definitionForProp(definitionKey, groupName, settingName) {
  var groupDefinition = _definitions[groupName] || {};
  var result;
  if (!_isEmpty(settingName)) {
    var settingDefinition = groupDefinition[settingName] || {};
    result = settingDefinition[definitionKey];
  }
  if (_isEmpty(result)) {
    result = groupDefinition[definitionKey];
  }
  if (_isEmpty(result)) {
    result = DEFAULT_DEFINITION[definitionKey];
  }
  return result;
}

function _readGroupFromLocalStorage(groupName) {
  return LocalStorageService.get(groupName) || {};
}

function syncRequest(userSettings, groupName) {
  return RequestService.request('post', 'UserSettingsController', 'set', {
    userSettings,
    groupName
  });
}

async function syncSettings(groupName, groupFromStorage) {
  try {
    const groupByName = _definitions[groupName];
    if (groupByName) {
      const userSettings = Object.keys(groupByName).reduce((acc, key) => {
        const isSettingExistInLocal =
          groupFromStorage.hasOwnProperty(key) &&
          groupFromStorage[key] !== undefined;
        if (isSettingExistInLocal && groupByName[key]?.sync) {
          acc[key] = groupFromStorage[key];
        }

        return acc;
      }, {});

      if (!_isEmpty(userSettings)) {
        return await syncRequest(userSettings, groupName);
      }
    }
  } catch (err) {
    logger.error(`Error while sync settings. Error: ${err}`);
  }
}

async function _storeSettings(groupName, data, skipSync = false) {
  let storageKey = _detectKeyForGroup(groupName);
  let groupFromStorage = getGroup(groupName) || {};
  each(data, (value, key) => {
    groupFromStorage[key] = value;
  });

  LocalStorageService.set(storageKey, groupFromStorage);
  if (!skipSync) {
    return await syncSettings(groupName, groupFromStorage);
  }
}

function _isEmpty(val) {
  return isUndefined(val) || isNull(val);
}

function getSetting(groupName, settingName) {
  return getGroup(groupName).get(settingName);
}

async function setSetting(groupName, settingName, value, skipSync) {
  let settingGroup = getGroup(groupName);
  return await settingGroup.set(settingName, value, skipSync);
}

function clearGroup(groupName) {
  const settingGroup = getGroup(groupName);
  settingGroup.resetProperties();
  delete _cache[groupName];
}

export default {
  init,
  clearGroup,
  getGroup,
  getSetting,
  setSetting
};
