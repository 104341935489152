import DB from '@/services/Agent/dao/DB';

async function read(bookId) {
  const docId = _getDocId(bookId);
  return DB.userRW().get(docId);
}

function write(categories) {
  return DB.userRW().put(categories);
}

async function getAll() {
  return DB.userRW().getAllByPrefix(DB.prefix.tags);
}

function createDefaultDoc(categories, bookId) {
  return {
    _id: _getDocId(bookId),
    type: 'tags',
    values: categories
  };
}

function _getDocId(bookId) {
  return DB.id.tags(bookId);
}

export default {
  read,
  write,
  getAll,
  createDefaultDoc
};
