export default {
  CONFIRM_EMAIL: 'Confirm',
  PROCESSED: 'Processed',
  EXPIRED_LINK: 'Expired',
  NOT_FOUND: 'NotFound',
  ALREADY_USED_LINK: 'AlreadyUsed',
  EMPTY: 'empty',
  TASK_HASH_CODE_CHANGE: 'taskHashCodeChange',
  CONFIRM_PROFILE_HASH_CODE: 'confirmProfileHashCode',
  CONFIRM_PASSWORD_HASH_CODE: 'confirmPasswordHashCode',
  ERROR: 'ConfirmationTaskWizardError'
};
