import ExternalBlogTypesEnum from '@/enums/ExternalBlogTypesEnum';

class BlogSettings {
  constructor(settings) {
    this.id = settings.id;
    this.name = settings.name;
    this.url = settings.url;
    this.selfLink = settings.selfLink;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      selfLink: this.selfLink
    };
  }
}

class BloggerSettings extends BlogSettings {
  constructor(settings) {
    super(settings);
    this.type = ExternalBlogTypesEnum.BLOGGER;
    this.email = settings.email;
    this.userName = settings.userName;
    this.userPhotoUrl = settings.userPhotoUrl;
  }
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      selfLink: this.selfLink,
      type: this.type,
      email: this.email,
      userName: this.userName,
      userPhotoUrl: this.userPhotoUrl
    };
  }

  setEmail(email) {
    this.email = email;
    return this;
  }

  setUserName(userName) {
    this.userName = userName;
    return this;
  }

  setUserPhotoUrl(userPhotoUrl) {
    this.userPhotoUrl = userPhotoUrl;
    return this;
  }
}

function createBloggerSettings(settings) {
  return new BloggerSettings(settings);
}

function createBlogSettings(settings) {
  return new BlogSettings(settings);
}

export default {
  createBloggerSettings,
  createBlogSettings
};
