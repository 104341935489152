'use strict';

import XRegExp from 'xregexp';
import arabicWordsUtils from './arabicWordsUtils.mjs';
import arabicStopWords from './arabicStopWords.mjs';

const RegEx = XRegExp.default || XRegExp;
const arWordre = RegEx('^[\\p{Arabic}]+$');

function isArabicWord(word) {
  return arWordre.test(word);
}

function isArabicWords(words) {
  return words.every(isArabicWord);
}
var exp = {
  isArabicWords: isArabicWords
};

[arabicWordsUtils, arabicStopWords].forEach(function(module) {
  Object.keys(module).forEach(function(methodName) {
    exp[methodName] = module[methodName];
  });
});

export default exp;
