import vocabularyCover from '@/assets/images/webp-images/book_cover.webp';
import defaultBookImage from '@/assets/images/webp-images/default_book_image.webp';
import sectionCover from '@/assets/images/webp-images/book_cover2.webp';
import booknoteCover from '@/assets/images/webp-images/booknotes_cover.webp';
import booknoteCover42 from '@/assets/images/webp-images/booknotes_cover42x55.webp';
import booknoteCover73 from '@/assets/images/webp-images/booknotes_cover73x97.webp';
import booknoteCover152 from '@/assets/images/webp-images/booknotes_cover152x200.webp';
import collectionCover from '@/assets/images/webp-images/collection_cover.webp';
import collectionCover42 from '@/assets/images/webp-images/collection_cover42x55.webp';
import collectionCover73 from '@/assets/images/webp-images/collection_cover73x97.webp';
import collectionCover152 from '@/assets/images/webp-images/collection_cover152x200.webp';
import emptyCover from '@/assets/images/webp-images/empty_book_cover.webp';
import dummyCover from '@/assets/images/webp-images/dummy_book_cover.webp';
import syllabusCover from '@/assets/images/webp-images/studycourse_cover.webp';
import syllabusCover42 from '@/assets/images/webp-images/studycourse_cover42x55.webp';
import syllabusCover73 from '@/assets/images/webp-images/studycourse_cover73x97.webp';
import syllabusCover152 from '@/assets/images/webp-images/studycourse_cover152x200.webp';
import librarySetCover42 from '@/assets/images/webp-images/bookset_cover42x55.webp';
import librarySetCover73 from '@/assets/images/webp-images/bookset_cover73x97.webp';
import librarySetCover152 from '@/assets/images/webp-images/bookset_cover152x200.webp';
import courseCover from '@/assets/images/webp-images/studyclass_cover.webp';
import courseCover42 from '@/assets/images/webp-images/studyclass_cover42x55.webp';
import courseCover73 from '@/assets/images/webp-images/studyclass_cover73x97.webp';
import courseCover152 from '@/assets/images/webp-images/studyclass_cover152x200.webp';
import compilationCoverFfa from '@/assets/images/svg-images/pictures/compilation-cover-ffa.svg';
import compilationCoverOcean from '@/assets/images/svg-images/pictures/compilation-cover-ocean.svg';
import compilationCover42 from '@/assets/images/webp-images/compilation_cover42x55.webp';
import compilationCover73 from '@/assets/images/webp-images/compilation_cover73x97.webp';
import profileDummyPhoto from '@/assets/images/svg-images/pictures/userpic.svg';
import carouselFrame1 from '@/assets/images/carousel/frame1.png';
import carouselFrame2 from '@/assets/images/carousel/frame2.png';
import carouselFrame3 from '@/assets/images/carousel/frame3.png';
import compilationDefaultCover from '@/assets/images/svg-images/pictures/compilation-default-cover.svg';
import compilationDefaultCover42 from '@/assets/images/webp-images/compilation_default_cover42x55.webp';
import compilationDefaultCover73 from '@/assets/images/webp-images/compilation_default_cover73x97.webp';
import compilationSharedCover42 from '@/assets/images/webp-images/compilation_shared_cover42x55.webp';
import compilationSharedCover73 from '@/assets/images/webp-images/compilation_shared_cover73x97.webp';
import fireworkAnimation from '@/assets/images/webp-images/pic-firework.webp';
import aboutCarouselItem1 from '@/assets/images/webp-images/pic-slide-item1.webp';
import aboutCarouselItem3 from '@/assets/images/webp-images/pic-slide-item3.webp';
import aboutBenefitBooklet1 from '@/assets/images/webp-images/pic-booklet1.webp';
import aboutBenefitBooklet2 from '@/assets/images/webp-images/pic-booklet2.webp';
import aboutBenefitBooklet3 from '@/assets/images/webp-images/pic-booklet3.webp';
import aboutOceanIMac from '@/assets/images/webp-images/iMac-ocean.webp';
import aboutOceanPromo from '@/assets/images/webp-images/promo.webp';
import aboutOceanPromoPortrait from '@/assets/images/webp-images/promo_portrait.webp';
import aboutOceanPromoSm from '@/assets/images/webp-images/promo_sm.webp';
import aboutOceanHeadphones from '@/assets/images/webp-images/1.webp';
import aboutOceanHeadphonesSm from '@/assets/images/webp-images/1_sm.webp';
import aboutOceanTabletSearch from '@/assets/images/webp-images/2.webp';
import aboutOceanTabletSearchSm from '@/assets/images/webp-images/2_sm.webp';
import aboutOceanTabletBook from '@/assets/images/webp-images/3.webp';
import aboutOceanTabletBookSm from '@/assets/images/webp-images/3_sm.webp';
import aboutOceanTabletCompilation from '@/assets/images/webp-images/4.webp';
import aboutOceanTabletCompilationSm from '@/assets/images/webp-images/4_sm.webp';
import vocabularyAssessment from '@/assets/images/webp-images/voc_assessment.webp';
import emptyFolder from '@/assets/images/webp-images/empty_folder.webp';
import ffaLogo from '@/assets/images/svg-images/qr-icons/ico-logo-ffa.svg';
import ffaAppName from '@/assets/images/svg-images/qr-icons/pic-app-name-ffa.svg';
import oceanLogo from '@/assets/images/svg-images/qr-icons/ico-logo-ocean.svg';
import oceanAppName from '@/assets/images/svg-images/qr-icons/pic-app-name-ocean.svg';
import deleteBin from '@/assets/images/svg-images/pictures/ico-delete-bin.svg';
import picAccept from '@/assets/images/svg-images/pictures/pic-accept.svg';
import picLock from '@/assets/images/svg-images/pictures/pic-lock.svg';
import picMail from '@/assets/images/svg-images/pictures/pic-mail.svg';
import resetPasswordImg from '@/assets/images/svg-images/pictures/pic-essay.svg';
import picAlertTriangle from '@/assets/images/svg-images/pictures/pic-alert-triangle.svg';
import picArrowBanner from '@/assets/images/svg-images/pictures/pic-arrow.svg';
import picGarbage from '@/assets/images/svg-images/pictures/pic-garbage.svg';
import picSearch from '@/assets/images/svg-images/pictures/pic-search.svg';
import picRead from '@/assets/images/svg-images/pictures/pic-read.svg';
import picBulb from '@/assets/images/svg-images/pictures/pic-bulb.svg';
import picBook from '@/assets/images/svg-images/pictures/pic-book2.svg';
import picBookmark from '@/assets/images/svg-images/pictures/pic-bookmark.svg';
import picCancel from '@/assets/images/svg-images/pictures/pic-cancel.svg';
import picTocEmpty from '@/assets/images/svg-images/pictures/pic-toc-empty.svg';
import picOpenBook from '@/assets/images/svg-images/pictures/pic-open-book.svg';
import picCards from '@/assets/images/svg-images/pictures/pic-cards.svg';
import picBooks2 from '@/assets/images/svg-images/pictures/pic-books2.svg';
import picWarn from '@/assets/images/svg-images/pictures/pic-warn.svg';
import picOff from '@/assets/images/svg-images/pictures/pic-off.svg';
import picTest from '@/assets/images/svg-images/pictures/pic-test.svg';
import picTime from '@/assets/images/svg-images/pictures/pic-time.svg';
import picWin from '@/assets/images/svg-images/pictures/pic-win.svg';
import picFlyingBooks from '@/assets/images/svg-images/pictures/pic-flying-books.svg';
import picAlert from '@/assets/images/svg-images/pictures/pic-alert.svg';
import picClosedBook from '@/assets/images/svg-images/pictures/pic-closed-book.svg';
import picCardBackSide from '@/assets/images/svg-images/pictures/pic-card-back-side.svg';
import multiCircles from '@/assets/images/svg-images/pictures/pic-multi-circles.svg';
import multiCirclesDark from '@/assets/images/svg-images/pictures/pic-multi-circles-dark.svg';
import headerPic1 from '@/components/views/AboutView/ffa/img/header-pic1.webp';
import picNotification from '@/assets/images/svg-images/pictures/pic-notification.svg';
import picPaySystemAffirm from '@/assets/images/svg-images/pictures/pic-paySystem-Affirm.svg';
import picPaySystemAliPay from '@/assets/images/svg-images/pictures/pic-paySystem-AliPay.svg';
import picPaySystemAmazon from '@/assets/images/svg-images/pictures/pic-paySystem-Amazon.svg';
import picPaySystemAmericanExpress from '@/assets/images/svg-images/pictures/pic-paySystem-AmericanExpress.svg';
import picPaySystemApplePay from '@/assets/images/svg-images/pictures/pic-paySystem-ApplePay.svg';
import picPaySystemBanContact from '@/assets/images/svg-images/pictures/pic-paySystem-BanContact.svg';
import picPaySystemBitcoin from '@/assets/images/svg-images/pictures/pic-paySystem-Bitcoin.svg';
import picPaySystemBitcoinCash from '@/assets/images/svg-images/pictures/pic-paySystem-BitcoinCash.svg';
import picPaySystemBitPay from '@/assets/images/svg-images/pictures/pic-paySystem-BitPay.svg';
import picPaySystemCitadele from '@/assets/images/svg-images/pictures/pic-paySystem-Citadele.svg';
import picPaySystemDinersClub from '@/assets/images/svg-images/pictures/pic-paySystem-DinersClub.svg';
import picPaySystemDiscover from '@/assets/images/svg-images/pictures/pic-paySystem-Discover.svg';
import picPaySystemElo from '@/assets/images/svg-images/pictures/pic-paySystem-Elo.svg';
import picPaySystemEthereum from '@/assets/images/svg-images/pictures/pic-paySystem-Ethereum.svg';
import picPaySystemForbrugsforeningen from '@/assets/images/svg-images/pictures/pic-paySystem-ForbrugsForeningen.svg';
import picPaySystemGiroPay from '@/assets/images/svg-images/pictures/pic-paySystem-GiroPay.svg';
import picPaySystemGooglePay from '@/assets/images/svg-images/pictures/pic-paySystem-GooglePay.svg';
import picPaySystemIdeal from '@/assets/images/svg-images/pictures/pic-paySystem-Ideal.svg';
import picPaySystemInterac from '@/assets/images/svg-images/pictures/pic-paySystem-Interac.svg';
import picPaySystemJCB from '@/assets/images/svg-images/pictures/pic-paySystem-JCB.svg';
import picPaySystemKlarna from '@/assets/images/svg-images/pictures/pic-paySystem-Klarna.svg';
import picPaySystemLightcoin from '@/assets/images/svg-images/pictures/pic-paySystem-Lightcoin.svg';
import picPaySystemMaestro from '@/assets/images/svg-images/pictures/pic-paySystem-Maestro.svg';
import picPaySystemMasterCard from '@/assets/images/svg-images/pictures/pic-paySystem-Mastercard.svg';
import picPaySystemPayoneer from '@/assets/images/svg-images/pictures/pic-paySystem-Payoneer.svg';
import picPaySystemPayPal from '@/assets/images/svg-images/pictures/pic-paySystem-PayPal.svg';
import picPaySystemPaysafe from '@/assets/images/svg-images/pictures/pic-paySystem-Paysafe.svg';
import picPaySystemSepa from '@/assets/images/svg-images/pictures/pic-paySystem-Sepa.svg';
import picPaySystemSkrill from '@/assets/images/svg-images/pictures/pic-paySystem-Skrill.svg';
import picPaySystemStripe from '@/assets/images/svg-images/pictures/pic-paySystem-Stripe.svg';
import picPaySystemUnionPay from '@/assets/images/svg-images/pictures/pic-paySystem-UnionPay.svg';
import picPaySystemVerifone from '@/assets/images/svg-images/pictures/pic-paySystem-Verifone.svg';
import picPaySystemVisa from '@/assets/images/svg-images/pictures/pic-paySystem-Visa.svg';
import picPaySystemWebmoney from '@/assets/images/svg-images/pictures/pic-paySystem-Webmoney.svg';
import picPaySystemWechat from '@/assets/images/svg-images/pictures/pic-paySystem-Wechat.svg';
import picBarChart from '@/assets/images/svg-images/qr-icons/ico-bar-chart-2-line.svg';
import picFire from '@/assets/images/svg-images/qr-icons/ico-fire-line.svg';
import picLibrary from '@/assets/images/svg-images/qr-icons/ico-library-outline.svg';
import picSandClock from '@/assets/images/svg-images/qr-icons/ico-sand-clock.svg';
import icoFireGray from '@/assets/images/svg-images/qr-icons/ico-fire-gray.svg';
import icoFireRed from '@/assets/images/svg-images/qr-icons/ico-fire-red.svg';
import icoHeadset from '@/assets/images/svg-images/qr-icons/ico-headset-wire.svg';
import donationBgOcean from '@/assets/images/svg-images/pictures/pic-lotus.svg';
import donationBgFfa from '@/assets/images/webp-images/donation-bg-ffa.webp';
import faqPicFfa from '@/assets/images/webp-images/faq-pic-ffa.webp';
import faqPicOcean from '@/assets/images/webp-images/faq-pic-ocean.webp';

export default {
  vocabularyCover,
  defaultBookImage,
  sectionCover,
  booknoteCover,
  booknoteCover42,
  booknoteCover73,
  booknoteCover152,
  collectionCover,
  collectionCover42,
  collectionCover73,
  collectionCover152,
  emptyCover,
  dummyCover,
  syllabusCover,
  syllabusCover42,
  syllabusCover73,
  syllabusCover152,
  librarySetCover42,
  librarySetCover73,
  librarySetCover152,
  courseCover,
  courseCover42,
  courseCover73,
  courseCover152,
  compilationCoverFfa,
  compilationCoverOcean,
  compilationCover42,
  compilationCover73,
  profileDummyPhoto,
  carouselFrame1,
  carouselFrame2,
  carouselFrame3,
  compilationDefaultCover,
  compilationDefaultCover42,
  compilationDefaultCover73,
  compilationSharedCover42,
  compilationSharedCover73,
  fireworkAnimation,
  aboutCarouselItem1,
  aboutCarouselItem3,
  aboutBenefitBooklet1,
  aboutBenefitBooklet2,
  aboutBenefitBooklet3,
  aboutOceanIMac,
  aboutOceanPromo,
  aboutOceanPromoPortrait,
  aboutOceanPromoSm,
  aboutOceanHeadphones,
  aboutOceanHeadphonesSm,
  aboutOceanTabletSearch,
  aboutOceanTabletSearchSm,
  aboutOceanTabletBook,
  aboutOceanTabletBookSm,
  aboutOceanTabletCompilation,
  aboutOceanTabletCompilationSm,
  vocabularyAssessment,
  emptyFolder,
  ffaLogo,
  ffaAppName,
  picAccept,
  oceanLogo,
  oceanAppName,
  picLock,
  deleteBin,
  picMail,
  resetPasswordImg,
  picArrowBanner,
  picGarbage,
  picAlertTriangle,
  picSearch,
  picRead,
  picBulb,
  picBook,
  picBookmark,
  picCancel,
  picTocEmpty,
  picOpenBook,
  picCards,
  picBooks2,
  picOff,
  picWarn,
  picTest,
  picTime,
  picWin,
  picFlyingBooks,
  picAlert,
  picClosedBook,
  multiCircles,
  multiCirclesDark,
  picCardBackSide,
  picNotification,
  headerPic1,
  picPaySystemAffirm,
  picPaySystemAliPay,
  picPaySystemAmazon,
  picPaySystemAmericanExpress,
  picPaySystemApplePay,
  picPaySystemBanContact,
  picPaySystemBitcoin,
  picPaySystemBitcoinCash,
  picPaySystemBitPay,
  picPaySystemCitadele,
  picPaySystemDinersClub,
  picPaySystemDiscover,
  picPaySystemElo,
  picPaySystemEthereum,
  picPaySystemForbrugsforeningen,
  picPaySystemGiroPay,
  picPaySystemGooglePay,
  picPaySystemIdeal,
  picPaySystemInterac,
  picPaySystemJCB,
  picPaySystemKlarna,
  picPaySystemLightcoin,
  picPaySystemMaestro,
  picPaySystemMasterCard,
  picPaySystemPayoneer,
  picPaySystemPayPal,
  picPaySystemPaysafe,
  picPaySystemSepa,
  picPaySystemSkrill,
  picPaySystemStripe,
  picPaySystemUnionPay,
  picPaySystemVerifone,
  picPaySystemVisa,
  picPaySystemWebmoney,
  picPaySystemWechat,
  picBarChart,
  picFire,
  picLibrary,
  picSandClock,
  icoFireGray,
  icoFireRed,
  icoHeadset,
  donationBgOcean,
  donationBgFfa,
  faqPicFfa,
  faqPicOcean
};
