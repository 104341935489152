import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CompilationsDao.js');
import Utils from '@/services/utils/Utils';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';

import DB from '@/services/Agent/dao/DB';

const COPY_PREFIX = 'Copy of ';

export default {
  getCompilationById,
  getAllCompilations,
  getSelectionsByIds,
  persistCompilation,
  appendSelection,
  removeCompilation,
  copyCompilation,
  persistSelection,
  removeSelection,
  moveSelectionUp,
  moveSelectionDown,
  changeDefaultCompilation,
  changeCompilationCover,
  editSectionTitle
};

async function getCompilationById(id) {
  return await DB.userRW().get(id);
}

function getAllCompilations() {
  return DB.userRW().getAllByPrefix(DB.prefix.compilation);
}

function getSelectionById(selectionId) {
  return DB.userRW().get(selectionId);
}

function getSelectionsByIds(selectionsIds) {
  return DB.userRW().getByKeys(selectionsIds);
}

async function persistCompilation(data, selectionsData = []) {
  data._id = data._id || getCompilationId(data._id);
  data.items = data.items || [];
  data.wordsCount = data.wordsCount || 0;
  data.slug = data.slug || Utils.shortUuid();

  try {
    let existing = await getCompilationById(data._id);
    data._rev = existing._rev;
  } catch (e) {
    delete data._rev;
  }

  if (selectionsData.length) {
    const selectionPromises = selectionsData.map(selectionData => {
      data.wordsCount += selectionData.wordsCount;
      return persistSelection(data._id, selectionData);
    });
    const results = await Promise.all(selectionPromises);
    const selectionsIds = results.map(result => result._id);
    data.items = data.items.concat(selectionsIds);
    data.audio = selectionsData.some(selectionData => {
      return _isAudioSelection(selectionData);
    });
  }

  data.type = PublicationsTypesEnum.COMPILATION;
  data.language = 'common';
  data.createdAt = data.createdAt || Date.now();
  data.modifiedAt = Date.now();
  data.selectionsCount = data.items.length;
  return DB.userRW()
    .put(data)
    .then(() => {
      return getCompilationById(data._id);
    });
}

function _isAudioSelection(selectionData) {
  return (
    selectionData.audio &&
    selectionData.audio.length &&
    selectionData.alignment &&
    selectionData.alignment[0].length !== 0
  );
}

async function appendSelection(compilationId, selectionData, position) {
  const compilation = await getCompilationById(compilationId);
  const selections = Array.isArray(selectionData)
    ? selectionData
    : [selectionData];
  let persistSelections = [];
  for (const selection of selections) {
    const persistSelectionData = await persistSelection(
      compilationId,
      selection
    );
    const selectionPosition =
      position === undefined ? compilation.items.length : position;
    compilation.items.splice(selectionPosition, 0, persistSelectionData._id);
    compilation.wordsCount += persistSelectionData.wordsCount;
    persistSelections.push(persistSelectionData);
  }

  compilation.audio = compilation.audio
    ? true
    : _isAudioSelection(persistSelections[0]);
  compilation.modifiedAt = Date.now();
  await DB.userRW().put(compilation);
  return persistSelections;
}

async function persistSelection(compilationId, selectionData) {
  selectionData.type =
    selectionData.type || CompilationSelectionTypes.SELECTION;
  selectionData._id =
    selectionData._id ||
    getCompilationItemId(selectionData._id, selectionData.type);
  selectionData.compilationId = compilationId;
  selectionData.createdAt = selectionData.createdAt || Date.now();
  selectionData.modifiedAt = Date.now();
  await DB.userRW().put(selectionData);
  selectionData._id = _parseDbItemId(selectionData._id);
  return selectionData;
}

function _parseDbItemId(id) {
  const separator = '_';
  return id.split(separator)[0];
}

async function removeSelection(compilationId, selectionId) {
  const [compilation, selection] = await Promise.all([
    getCompilationById(compilationId),
    getSelectionById(selectionId)
  ]);

  const index = compilation.items.indexOf(selectionId);
  selection._deleted = true;

  compilation.wordsCount -= selection.wordsCount;
  compilation.items.splice(index, 1);
  compilation.selectionsCount = compilation.items.length;
  const selectionHasAudio = _isAudioSelection(selection);
  if (selectionHasAudio) {
    const selections = await getSelectionsByIds(compilation.items);
    compilation.audio = selections.some(_isAudioSelection);
  }
  return Promise.all([
    DB.userRW().put(compilation),
    DB.userRW().put(selection)
  ]);
}

async function moveSelectionDown(compilationId, selectionId) {
  const compilation = await getCompilationById(compilationId);
  const index = compilation.items.indexOf(selectionId);
  if (index < compilation.items.length - 1) {
    const tmp = compilation.items[index];
    compilation.items[index] = compilation.items[index + 1];
    compilation.items[index + 1] = tmp;
    return DB.userRW().put(compilation);
  }
}

async function moveSelectionUp(compilationId, selectionId) {
  const compilation = await getCompilationById(compilationId);
  const index = compilation.items.indexOf(selectionId);
  if (index > 0) {
    const tmp = compilation.items[index];
    compilation.items[index] = compilation.items[index - 1];
    compilation.items[index - 1] = tmp;
    return DB.userRW().put(compilation);
  }
}

async function removeCompilation(compilationId) {
  let compilation;
  try {
    compilation = await getCompilationById(compilationId);
  } catch (error) {
    logger.error(
      `Get error on getCompilationById compilationId:${compilationId} error: ${error}`
    );
  }

  if (!compilation) {
    return Promise.resolve();
  }
  compilation._deleted = true;
  const promises = [DB.userRW().put(compilation)];

  const selections = await getSelectionsByIds(compilation.items);
  selections.forEach(selection => {
    selection._deleted = true;
    promises.push(DB.userRW().put(selection));
  });

  return Promise.all(promises);
}

async function copyCompilation({ compilationId, coverFileName }) {
  const compilation = await getCompilationById(compilationId);
  const selections = await getSelectionsByIds(compilation.items);
  delete compilation._id;
  delete compilation._rev;
  delete compilation._selections;
  delete compilation.isShared;
  delete compilation.authorId;
  compilation.items = [];
  compilation.wordsCount = 0;
  compilation.slug = Utils.shortUuid();
  compilation.coverFileName = coverFileName || null;
  compilation.default = false;

  selections.forEach(selection => {
    delete selection._id;
    delete selection._rev;
    delete selection.compilationId;
  });

  compilation.title = COPY_PREFIX + compilation.title;
  return persistCompilation(compilation, selections);
}

function getCompilationId(id) {
  return DB.id.compilation(id);
}

function getCompilationItemId(id, type) {
  return type === CompilationSelectionTypes.SELECTION
    ? DB.id.compilationItem(id)
    : DB.id.compilationSectionItem(id);
}

async function changeDefaultCompilation(compilationId, isDefault) {
  const compilation = await getCompilationById(compilationId);
  compilation.default = isDefault;
  return DB.userRW().put(compilation);
}

async function changeCompilationCover(compilationId, coverFileName) {
  const compilation = await getCompilationById(compilationId);
  compilation.coverFileName = coverFileName;
  return DB.userRW().put(compilation);
}

async function editSectionTitle(sectionId, title) {
  const section = await getSelectionById(sectionId);
  section.title = title;
  return DB.userRW().put(section);
}

// function _removeIdPrefix(id) {
//   const prefix = DB.prefix.compilation;
//   return id.indexOf(prefix) === 0 ? id.replace(prefix + '-', '') : id;
// }
