<template>
  <div class="learners-dictionary">
    <div v-if="dictType === DictionaryTypeEnum.SHORT">
      <div class="word-block">
        <div class="title-word-block">
          <div class="title-word-block-wrapper">
            <span class="title-word-text">{{ definition.id }}</span>
          </div>
        </div>
      </div>

      <div class="dictionary-content-block">
        <div class="word-pronunciation">
          <span
            class="word-pronunciation-item"
            v-html="definition.learnersTermInfo.pronunciation"
          />
        </div>
        <div class="definition-block">
          <div class="definition-text">
            <span
              class="part-of-speech"
              v-html="definition.learnersTermInfo.PoS"
            />&nbsp;
            <span @click="openCrossReference" v-html="definition.definition" />
          </div>
        </div>
      </div>
      <button
        v-if="isOnline"
        class="more-link"
        @click="showFullDefinition()"
        v-html="$t('ShortLearnersDictionaryContent.showMore.label')"
      />
    </div>
    <div v-else-if="dictType === DictionaryTypeEnum.FULL">
      <div class="word-block">
        <div class="title-word-block">
          <span class="title-word-text">
            <sup class="title-superscript">{{ defIndex }}</sup>
            {{ definition.id }}
          </span>
        </div>
        <div class="flashcard-link">
          <button
            v-if="showFlashCard"
            class="flash-card-btn text-none"
            @click="addToFlashCard()"
          >
            <i class="flash-card-icon">
              <BaseSpriteIcon icon-name="ico-plus-solid" />
            </i>
            <span>{{ $t('Flashcard.Add.label') }}</span>
          </button>
        </div>
      </div>
      <div class="dictionary-content-block">
        <div class="word-pronunciation">
          <span
            v-for="(pronunciation, index) in definition.learnersTermInfo
              .pronunciation"
            :key="index + 'pronunciation'"
            class="word-pronunciation-wrapp"
          >
            <span class="word-pronunciation-item" v-html="pronunciation" />
          </span>
        </div>

        <div class="word-stats">
          <span
            class="part-of-speech"
            v-html="definition.learnersTermInfo.PoS"
          />
          <span
            v-for="(inflation, index) in definition.learnersTermInfo.inflation"
            :key="index + 'inflation'"
            class="inflation"
          >
            <span
              class="inflation-label italic -nobold"
              v-html="inflation.inflationLabel"
            />
            <span
              class="inflation-form italic"
              v-html="inflation.inflationForm"
            />
          </span>
        </div>

        <div class="definition-block">
          <span
            class="definition-grammar-block"
            v-html="definition.learnersTermInfo.definitionBlock.grammar"
          />
          &nbsp;
          <span
            v-html="
              definition.learnersTermInfo.definitionBlock.subjectableLabel
            "
          />
        </div>
        <div v-if="definition.learnersTermInfo.artRef">
          <img :src="definition.learnersTermInfo.artRef" />
        </div>
        <div class="word-usage-examples">
          <ul class="word-usage-examples-list">
            <li
              v-for="(termDefinition, indexLearnersTermInfo) in definition
                .learnersTermInfo.definitionBlock.termDefinition"
              :key="indexLearnersTermInfo + 'usage-examples'"
            >
              <span class="word-usage-number-wrapper">
                <span
                  class="word-usage-sense-number"
                  v-html="termDefinition.senseNumber"
                />
                <span
                  class="word-usage-sense-subnumber"
                  v-html="termDefinition.senseSubNumber"
                />
                <span
                  class="sense-specific-label italic -nobold"
                  v-html="termDefinition.senseSpecificLabel"
                />
                <span class="bold-italic-note" v-html="termDefinition.bnote" />
                <span
                  class="wsgram -inline"
                  v-html="termDefinition.sgramWsgram"
                />
                <span
                  v-for="(sgramForm, indexSgram) in termDefinition.sgramForms"
                  :key="indexSgram + 'wsgram'"
                >
                  <span class="italic -nobold" v-html="sgramForm.label" />
                  <span class="italic" v-html="sgramForm.form" />
                </span>
              </span>
              <!-- phraseVerb -->
              <span
                v-for="(phraseVerb, indexTermDef) in termDefinition.phraseVerb"
                :key="indexTermDef + 'phrase-verb'"
              >
                <span v-html="phraseVerb.phraseLabel" />
                <span v-html="phraseVerb.phraseVerb" />
              </span>
              <span v-html="termDefinition.definition" />
              <span
                @click="openCrossReference($event)"
                v-html="termDefinition.synonymsCrossRef"
              />

              <!-- verbalIllustration -->
              <ul>
                <li
                  v-for="(verbalIllustrationGroup,
                  indexVerbIllPhraseVerb) in termDefinition.verbalIllustration"
                  :key="indexVerbIllPhraseVerb + 'verbal-illustration'"
                  class="no-marker"
                >
                  <span
                    v-if="showWsgram(termDefinition)"
                    class="wsgram"
                    v-html="verbalIllustrationGroup.wsgram"
                  />

                  <ul class="verbal-illustrations">
                    <li
                      v-for="(verbalIllustration,
                      indexVerbIllWsgram) in verbalIllustrationGroup.verbalIllustrations"
                      :key="indexVerbIllWsgram + 'wsgram-verbal-illustration'"
                    >
                      <span v-html="verbalIllustration.example" />
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- usage note -->
              <ul class="usage-note">
                <li
                  v-for="(usageNote,
                  indexTermDefUsageNote) in termDefinition.usageNote"
                  :key="indexTermDefUsageNote + 'usage-note'"
                >
                  <span
                    class="direction-cross-reference-text"
                    @click="openCrossReference($event)"
                    v-html="usageNote.text"
                  />

                  <!-- verbalIllustration -->
                  <ul>
                    <li
                      v-for="(verbalIllustrationGroup,
                      indexUsageNoteVerbIll) in usageNote.verbalIllustration"
                      :key="
                        indexUsageNoteVerbIll + 'usage-note-verbal-illustration'
                      "
                      class="no-marker"
                    >
                      <span
                        class="wsgram"
                        v-html="verbalIllustrationGroup.wsgram"
                      />

                      <ul class="verbal-illustrations">
                        <li
                          v-for="(verbalIllustration,
                          indexVerbIllGroupIll) in verbalIllustrationGroup.verbalIllustrations"
                          :key="
                            indexVerbIllGroupIll +
                              'wsgram-usage-note-verbal-illustration'
                          "
                        >
                          <span v-html="verbalIllustration.example" />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  v-for="(directionCrossReference,
                  indexternDefCrossRef) in termDefinition.directionCrossReference"
                  :key="indexternDefCrossRef + 'direction-cross-reference'"
                >
                  <span
                    @click="openCrossReference($event)"
                    v-html="directionCrossReference.text"
                  />
                </li>
              </ul>

              <!-- snote -->
              <ul
                v-for="(snote, indexTernDefSnote) in termDefinition.snote"
                :key="indexTernDefSnote + 'term-definition-usage-note'"
                class="usage-note"
              >
                <span v-html="snote.text" />

                <ul class="verbal-illustrations">
                  <li
                    v-for="(verbalIllustration,
                    indexSnoteverbIll) in snote.verbalIllustration"
                    :key="
                      indexSnoteverbIll +
                        'term-definition-usage-note-verbal-illustration'
                    "
                    class="verbal-illustration"
                  >
                    <span v-html="verbalIllustration.example" />
                  </li>
                </ul>
              </ul>
            </li>
            <ul>
              <li
                v-for="(cognateCrossEntry, index) in definition.learnersTermInfo
                  .cognateCrossEntrys"
                :key="index + 'cognate-cross-entry'"
              >
                <span v-html="cognateCrossEntry.cognateLabel" />
                <span v-html="cognateCrossEntry.cognateTargetWord" />
              </li>
            </ul>
          </ul>

          <ul>
            <li
              v-for="(definedRunOnEntry,
              index) in definition.definedRunOnEntrys"
              :key="index + 'defined-run-on-entry'"
            >
              <span class="run-on-entry-name" v-html="definedRunOnEntry.name" />
              <span
                class="speach-part -inline"
                v-html="definedRunOnEntry.PoS"
              />
              <span
                class="italic -nobold"
                v-html="definedRunOnEntry.definitionBlock.subjectableLabel"
              />
              <span
                v-for="(variantSpelling,
                indexDefRunOnEntryVarSpel) in definedRunOnEntry.variantSpelling"
                :key="indexDefRunOnEntryVarSpel + 'variant-spelling'"
              >
                <span
                  class="italic -nobold"
                  v-html="variantSpelling.variantLabel"
                />
                <span
                  class="run-on-entry-name"
                  v-html="variantSpelling.variantForm"
                />
              </span>
              <ul class="sense-blocks">
                <li
                  v-for="(termDefinition, indexDefRunEntry) in definedRunOnEntry
                    .definitionBlock.termDefinition"
                  :key="indexDefRunEntry + 'sense-blocks'"
                >
                  <span class="word-usage-number-wrapper">
                    <span
                      class="word-usage-sense-number"
                      v-html="termDefinition.senseNumber"
                    />
                    <!--     <span class="bold-italic-note" v-html="termDefinition.bnote"></span>
                    <span class="wsgram -inline" v-html="termDefinition.sgramWsgram"></span>-->
                    <span
                      class="word-usage-sense-subnumber"
                      v-html="termDefinition.senseSubNumber"
                    />
                  </span>
                  <!-- phraseVerb -->
                  <span
                    v-for="(phraseVerb,
                    indexPhresVerb) in termDefinition.phraseVerb"
                    :key="indexPhresVerb + 'sense-blocks-phrase-verb'"
                    class="phrase-verb"
                  >
                    <span v-html="phraseVerb.phraseLabel" />
                    <span
                      class="run-on-entry-name"
                      v-html="phraseVerb.phraseVerb"
                    />
                  </span>

                  <span class="roe-sence">
                    <span
                      class="italic -nobold"
                      v-html="termDefinition.senseSpecificLabel"
                    />
                    <span
                      class="roe-definition"
                      v-html="termDefinition.definition"
                    />
                  </span>
                  <span
                    @click="openCrossReference($event)"
                    v-html="termDefinition.synonymsCrossRef"
                  />
                  <!-- verbalIllustration -->
                  <ul>
                    <li
                      v-for="(verbalIllustrationGroup,
                      indexPhraseVerbIllGroup) in termDefinition.verbalIllustration"
                      :key="
                        indexPhraseVerbIllGroup +
                          'sense-blocks-verbal-illustration'
                      "
                      class="no-marker"
                    >
                      <span
                        class="wsgram"
                        v-html="verbalIllustrationGroup.wsgram"
                      />

                      <ul class="verbal-illustrations">
                        <li
                          v-for="(verbalIllustration,
                          indexVerbIllGroup) in verbalIllustrationGroup.verbalIllustrations"
                          :key="
                            indexVerbIllGroup +
                              'wsgram-sense-blocks-verbal-illustration'
                          "
                          class="verbal-illustration"
                        >
                          <span v-html="verbalIllustration.example" />
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <!-- snote -->
                  <ul
                    v-for="(snote, indexTermDef) in termDefinition.snote"
                    :key="indexTermDef + 'snote'"
                    class="usage-note"
                  >
                    <span v-html="snote.text" />

                    <ul class="verbal-illustrations">
                      <li
                        v-for="(verbalIllustration,
                        indexVervIllSnote) in snote.verbalIllustration"
                        :key="indexVervIllSnote + 'snote-verbal-illustration'"
                        class="verbal-illustration"
                      >
                        <span v-html="verbalIllustration.example" />
                      </li>
                    </ul>
                  </ul>

                  <!-- usage note -->
                  <ul class="usage-note">
                    <li
                      v-for="(usageNote,
                      indexTermDefUsageNote) in termDefinition.usageNote"
                      :key="
                        indexTermDefUsageNote + 'usage-note-term-definition'
                      "
                    >
                      <span
                        class="direction-cross-reference-text"
                        @click="openCrossReference($event)"
                        v-html="usageNote.text"
                      />

                      <!-- verbalIllustration -->
                      <ul>
                        <li
                          v-for="(verbalIllustrationGroup,
                          indexVerbIllGroup) in usageNote.verbalIllustration"
                          :key="
                            indexVerbIllGroup +
                              'verbal-illustration-term-definition'
                          "
                          class="no-marker"
                        >
                          <span
                            class="wsgram"
                            v-html="verbalIllustrationGroup.wsgram"
                          />

                          <ul class="verbal-illustrations">
                            <li
                              v-for="(verbalIllustration,
                              indexVerbIllWsgram) in verbalIllustrationGroup.verbalIllustrations"
                              :key="
                                indexVerbIllWsgram +
                                  'wsgram-verbal-illustration-term-definition'
                              "
                              class="verbal-illustration"
                            >
                              <span v-html="verbalIllustration.example" />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      v-for="(directionCrossReference,
                      indexTernDefCrossReference) in termDefinition.directionCrossReference"
                      :key="
                        indexTernDefCrossReference +
                          'usage-note-direction-cross-reference'
                      "
                    >
                      <span
                        @click="openCrossReference($event)"
                        v-html="directionCrossReference.text"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <span
                v-for="(directionalCrossReference,
                indexDefRunEntry) in definedRunOnEntry.directionalCrossReference"
                :key="
                  indexDefRunEntry +
                    'usage-note-direction-cross-reference-defined-run-on-entry'
                "
              >
                <span
                  @click="openCrossReference($event)"
                  v-html="directionalCrossReference.text"
                />
              </span>
              <span v-html="definedRunOnEntry.sNoteBox" />
            </li>
          </ul>

          <div>
            <span
              class="direction-cross-ref-section"
              @click="openCrossReference($event)"
              v-html="definition.dirCrossRefSect"
            />
          </div>

          <ul>
            <li
              v-for="(undefinedRunOnEntry,
              index) in definition.undefinedRunOnEntrys"
              :key="index + 'undefined-run-on-entry'"
            >
              <span
                class="run-on-entry-name"
                v-html="undefinedRunOnEntry.name"
              />
              <div class="word-pronunciation derivation-form">
                <span
                  v-for="(pronunciation,
                  indexPron) in undefinedRunOnEntry.pronunciation"
                  :key="indexPron + 'word-pronunciation'"
                  class="word-pronunciation-wrapp"
                >
                  <span
                    class="word-pronunciation-item"
                    v-html="pronunciation"
                  />
                </span>
              </div>
              <span class="part-of-speech" v-html="undefinedRunOnEntry.PoS" />
              <span
                v-for="(inflation,
                indexRunOnenrty) in undefinedRunOnEntry.inflation"
                :key="indexRunOnenrty + 'undefined-run-on-entry-inflation'"
                class="inflation"
              >
                <span v-html="inflation.inflationLabel" />
                <span class="inflation-form" v-html="inflation.inflationForm" />
              </span>

              <div class="definition-block">
                <span v-html="undefinedRunOnEntry.grammar" />
              </div>
              <!-- verbalIllustration -->
              <ul>
                <li
                  v-for="(verbalIllustrationGroup,
                  indexVerbIll) in undefinedRunOnEntry.verbalIllustrations"
                  :key="
                    indexVerbIll + 'undefined-run-on-entry-verbal-illustrations'
                  "
                >
                  <span
                    class="wsgram"
                    v-html="verbalIllustrationGroup.wsgram"
                  />

                  <ul class="verbal-illustrations">
                    <li
                      v-for="(verbalIllustration,
                      indexVerb) in verbalIllustrationGroup.verbalIllustrations"
                      :key="
                        indexVerb +
                          'undefined-run-on-entry-verbal-illustrations-wsgram'
                      "
                      class="verbal-illustration"
                    >
                      <span v-html="verbalIllustration.example" />
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      Unsupported dictionaty type
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';

import DictionaryTypeEnum from '@/enums/DictionaryTypeEnum';
import DefinitionEventEnum from '@/enums/DefinitionEventEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'LearnersDefinition',
  components: { BaseSpriteIcon },
  props: {
    definition: Object,
    dictType: String,
    allowFlashCard: {
      type: Boolean,
      required: false,
      default: false
    },
    isOnline: {
      type: Boolean,
      required: false,
      default: true
    },
    defIndex: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      DictionaryTypeEnum
    };
  },
  computed: {
    showFlashCard() {
      return this.isOnline && this.allowFlashCard;
    }
  },
  methods: {
    showFullDefinition() {
      const dictionaryId = this.definition.dictionaryId;
      this.$emit('definitionEvent', {
        type: DefinitionEventEnum.SHOW_FULL_DEFINITION,
        data: {
          dictionaryId
        }
      });
    },
    addToFlashCard() {
      const dictionaryId = this.definition.dictionaryId;

      this.$emit('definitionEvent', {
        type: DefinitionEventEnum.ADD_TO_FLASH_CARD,
        data: {
          dictionaryId
        }
      });
    },
    openCrossReference(event) {
      const crossReference = this._getCrossReferenceFromEvent(event);
      const dictionaryId = this.definition.dictionaryId;
      this.$emit('definitionEvent', {
        type: DefinitionEventEnum.OPEN_CROSS_REFERNCE,
        data: {
          crossReference,
          dictionaryId
        }
      });
    },
    showWsgram(termDefinition) {
      return termDefinition && termDefinition.sgramWsgram.length === 0;
    },
    _getCrossReferenceFromEvent(event) {
      var className = get(event, 'target.className', null);
      if (className !== 'cross-ref-link') {
        return '';
      }

      return event.target.innerText;
    }
  }
};
</script>

<style lang="less" src="./LearnersDefinition.less"></style>
