import EncodingEnum from '@/enums/EncodingEnum';
import FileAccessTypeEnum from '@/enums/FileAccessTypeEnum';

class ReadOptions {
  constructor(builderData) {
    this.encoding = builderData.encoding || EncodingEnum.BLOB;
    this.chunkOffset = builderData.chunkOffset || null;
    this.accessType = builderData.accessType || FileAccessTypeEnum.FULL;
    this.useCache = builderData.useCache || false;
  }
}

class ReadOptionsBuilder {
  setEncoding(val) {
    this.encoding = val;
    return this;
  }
  setChunkOffset(val) {
    this.chunkOffset = val;
    return this;
  }
  setAccessType(val) {
    this.accessType = val;
    return this;
  }
  setUseCache(val) {
    this.useCache = val;
    return this;
  }
  build() {
    return new ReadOptions(this);
  }
}

class ReadOptionsHelper {
  getParsedJsonOptions(useCache = false) {
    const builder = new ReadOptionsBuilder();
    return builder
      .setEncoding(EncodingEnum.PARSED_JSON)
      .setUseCache(useCache)
      .build();
  }

  getBinaryOptions() {
    const builder = new ReadOptionsBuilder();
    return builder.setEncoding(EncodingEnum.BINARY).build();
  }

  getArrayBufferChunkOptions(chunkOffset, useCache = false) {
    const builder = new ReadOptionsBuilder();
    return builder
      .setEncoding(EncodingEnum.ARRAY_BUFFER)
      .setChunkOffset(chunkOffset)
      .setAccessType(FileAccessTypeEnum.CHUNKED)
      .setUseCache(useCache)
      .build();
  }

  getBlobOptions(useCache = false) {
    const builder = new ReadOptionsBuilder();

    return builder
      .setEncoding(EncodingEnum.BLOB)
      .setUseCache(useCache)
      .build();
  }

  getUtf8Options() {
    const builder = new ReadOptionsBuilder();
    return builder.setEncoding(EncodingEnum.UTF8).build();
  }

  getUtf8ChunkOptions(chunkOffset) {
    const builder = new ReadOptionsBuilder();
    return builder
      .setEncoding(EncodingEnum.UTF8)
      .setChunkOffset(chunkOffset)
      .setAccessType(FileAccessTypeEnum.CHUNKED)
      .build();
  }
}

export { ReadOptions, ReadOptionsBuilder, ReadOptionsHelper };
