import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('DictionaryStore.vue');
import _str from 'underscore.string';
import groupBy from 'lodash/groupBy';

import DictionaryTypeEnum from '@/enums/DictionaryTypeEnum';
import DictionaryNamesEnum from '@/enums/DictionaryNamesEnum';

import AssetsManager from '@/services/AssetsManager/AssetsManager';
import RestService from '@/services/RestService';

import dictSearch from '@shared/dictionary/dictSearch';

// initial state

class Dictionary {
  constructor(dictsInfo) {
    this.bookSize = dictsInfo.bookSize;
    this.dictType = dictsInfo.dictType;
    this.id = dictsInfo.id;
    this.name = dictsInfo.name;
    this.originalId = dictsInfo.originalId || null;

    this.indexes = null;
  }

  serilize() {
    return JSON.stringify({
      bookSize: this.bookSize,
      dictType: this.dictType,
      id: this.id,
      name: this.name
    });
  }

  setIndexes(indexes) {
    this.indexes = indexes;
  }
}

class DictionaryView {
  constructor() {
    const self = this;
    self.dictMap = {};

    Object.keys(DictionaryTypeEnum).forEach(function(key) {
      const dictType = DictionaryTypeEnum[key];
      self.dictMap[dictType] = {};
    });
  }

  toJSON() {
    return JSON.stringify({
      dictMap: this.dictMap
    });
  }

  addDict(dictionary) {
    if (this.dictMap.hasOwnProperty(dictionary.dictType)) {
      this.dictMap[dictionary.dictType][dictionary.id] = dictionary;
    } else {
      logger.error(`Get unsupported dict type ${dictionary.dictType}`);
    }
  }

  getDictIdsByType(dictType) {
    return Object.keys(this.dictMap[dictType]);
  }

  getAllDictIds() {
    const dictIds = [];
    Object.keys(this.dictMap).forEach(dictType => {
      [].push.apply(dictIds, this.getDictIdsByType(dictType));
    });
    return dictIds;
  }

  getDictsMeta() {
    const self = this;
    const dictMeta = {};
    Object.keys(self.dictMap).forEach(function(dictType) {
      Object.assign(dictMeta, self.dictMap[dictType]);
    });
    return dictMeta;
  }
}

class DefinitionsStructure {
  constructor(dictType) {
    this.meta = {
      count: 0,
      searchTerm: '',
      term: '',
      dictType: dictType
    };
    this.definitions = [];
  }

  getFirstDefId() {
    return this.definitions[0].id;
  }

  hasDefinitions() {
    return this.definitions.length !== 0;
  }

  addDefs(defs) {
    const dictionaryDefinition = defs.dictionaryDefinition;
    const { count, searchTerm, term } = dictionaryDefinition;
    this.meta.count += count;
    this.meta.searchTerm = searchTerm || this.meta.searchTerm;
    this.meta.term = term || this.meta.term;
    if (!dictionaryDefinition || !dictionaryDefinition.definitions) {
      logger.error(
        `Did not get dictionaryDefinition.definitions for add to Definitions, get structure ${JSON.stringify(
          dictionaryDefinition || '{}',
          null,
          2
        )}`
      );
      return;
    }
    const allowFlashCardMap = {};
    dictionaryDefinition.definitions.forEach(dictDef => {
      const definitions = dictDef.definition.map(_addDictName);
      [].push.apply(this.definitions, definitions);
    });

    return;

    function _addDictName(definition, index) {
      if (definition.hasOwnProperty('learnersTermInfo')) {
        const allowFlashCard = false; // TODO: change when add flashcards
        // const allowFlashCard = Boolean(definition.learnersTermInfo.PoS) &&
        // !allowFlashCardMap.hasOwnProperty(definition.learnersTermInfo.PoS);
        definition.index = index;
        definition.dictName = DictionaryNamesEnum.LEARNERS;
        definition.allowFlashCard = allowFlashCard;
        definition.componentName = 'LearnersDefinition';
        allowFlashCardMap[definition.learnersTermInfo.PoS] = true;
      } else {
        logger.error(`Unsuported dictName ${definition}`);
      }
      return definition;
    }
  }
}

const initState = () => ({
  dictionaryView: new DictionaryView(),
  word: null,
  phrase: null,
  currentDefinition: null,
  currentFullDefinition: null
});

// getters
const storeGetters = {
  getAllDictIds(state) {
    return state.dictionaryView.getAllDictIds();
  },
  getCurrentWord(state) {
    return state.word;
  },
  getCurrentPhrase(state) {
    return state.phrase;
  },
  getCurrentDefinition(state) {
    return state.currentDefinition;
  },
  getCurrentFullDefinition(state) {
    return state.currentFullDefinition;
  }
};

async function _initDictsByType(state, dictType) {
  const dictIds = state.dictionaryView.getDictIdsByType(dictType);
  const promises = dictIds.map(function(dictId) {
    return AssetsManager.initBookAssets(dictId);
  });
  await Promise.all(promises);
  const viewPromises = dictIds.map(function(dictId) {
    return AssetsManager.getPublicationTrackingItemView(dictId);
  });
  return await Promise.all(viewPromises);
}

async function _downloadOrUpdate(publicationTrackingItemViews) {
  const actualizePromise = publicationTrackingItemViews.map(function(
    publicationTrackingItemView
  ) {
    const id = publicationTrackingItemView.id;
    if (!publicationTrackingItemView.isContentDownloaded) {
      return AssetsManager.downloadPublication(id);
    } else if (
      publicationTrackingItemView.isContentDownloaded &&
      publicationTrackingItemView.outdatedData !== 0
    ) {
      return AssetsManager.updatePublication(id);
    }
    return Promise.resolve();
  });
  return await Promise.all(actualizePromise);
}

class DictIndexProvider {
  readFile(fileName, dictId) {
    return AssetsManager.readFile(dictId, fileName)
      .then(function(data) {
        return {
          data,
          fileName
        };
      })
      .catch(function(err) {
        return {
          err,
          dictId
        };
      });
  }

  readByRange(fileName, dictId, rangesArray) {
    const result = [];
    let iterator = rangesArray.length - 1;
    return read(iterator, rangesArray, result);

    function read(i, rngArr, res) {
      const range = rangesArray[i];
      const chunkOffset = {
        start: range[0],
        end: range[1]
      };

      return AssetsManager.readFileByChunk(dictId, fileName, chunkOffset)
        .then(function(resp) {
          i--;
          res.push(JSON.parse(resp));
          if (i === -1) {
            return res;
          } else {
            return read(i, rngArr, res);
          }
        })
        .catch(function(err) {
          return {
            err,
            dictId
          };
        });
    }
  }
}

async function _remoteSearchDefinition(term, localDictsIds) {
  const query = {
    termName: term,
    useDicts: JSON.stringify(localDictsIds)
  };
  return RestService.restRequest('get', 'Dict', 'definition', query).then(
    function(resp) {
      return {
        dictionaryDefinition: resp.data
      };
    }
  );
}

const simbols = '\n\\.,:;?!(\\)\\”\\“\\‘\\’[\\]<{}\\>-';
const wordProcessingRe = new RegExp(
  '(^[' + simbols + ']+|[' + simbols + ']+$)',
  'g'
);

function wordProcessing(str) {
  str = str.replace(wordProcessingRe, '');
  str = str.replace(/[‘’]+/g, '');
  str = str.replace(/\s{2,}/, '');
  str = str.trim();
  return str;
}

function parsedSelectedText(selectedText) {
  return selectedText
    .trim()
    .split(/\s+/)
    .map(wordProcessing);
}

async function applySearchByWord(word, dictType, state) {
  const dictIds = state.dictionaryView.getDictIdsByType(dictType);
  var query = {
    dictionaryTermName: word
  };
  const trackingItemPromises = dictIds.map(function(dictId) {
    return AssetsManager.getPublicationTrackingItemView(dictId);
  });
  const trackingItemViews = await Promise.all(trackingItemPromises);
  const dictMeta = state.dictionaryView.getDictsMeta();

  const localDictIds = [];
  trackingItemViews.forEach(function(trackingItemView) {
    const dictId = trackingItemView.id;
    if (trackingItemView.isContentDownloaded) {
      localDictIds.push(dictId);
    }
  });

  let localDefs;
  let remoteDefs;
  try {
    localDefs = await dictSearch.getDictionaryDefinition(
      query,
      localDictIds,
      dictMeta
    );
  } catch (error) {
    localDefs = {
      dictionaryDefinition: {
        definitions: []
      }
    };
  }

  try {
    remoteDefs = await _remoteSearchDefinition(word, localDictIds);
  } catch (error) {
    remoteDefs = {
      dictionaryDefinition: {
        definitions: []
      }
    };
  }
  return {
    localDefs,
    remoteDefs
  };
}

// actions
const actions = {
  async downloadDict({ commit, state }) {
    try {
      const dictIndexProvider = new DictIndexProvider();
      dictSearch.setDataProvider(dictIndexProvider);
      const resp = await RestService.restRequest('get', 'Dict', 'info');
      const dictsInfo = resp.data;
      commit('addDictsInfo', { dictsInfo });
      const publicationTrackingItemViews = await _initDictsByType(
        state,
        DictionaryTypeEnum.SHORT
      );
      await _downloadOrUpdate(publicationTrackingItemViews);
    } catch (error) {
      logger.error(`get error on downloadDict error: ${error}`);
    }
  },
  async uploadToMemoryIndexes({ state }) {
    try {
      const dictIds = state.dictionaryView.getDictIdsByType(
        DictionaryTypeEnum.SHORT
      );
      const promises = dictIds.map(function(dictId) {
        return AssetsManager.getMapperById(dictId);
      });
      const mappers = await Promise.all(promises);
      const mappersMap = {};
      dictIds.forEach(function(dictId, index) {
        mappersMap[dictId] = mappers[index];
      });
      await dictSearch.getDictionaryIndexes(dictIds, mappersMap);
    } catch (error) {
      logger.error(`get error on uploadToMemoryIndexes error: ${error}`);
    }
  },
  async searchDictionaryTerms({ state }, payload) {
    const dictIds = state.dictionaryView.getDictIdsByType(
      DictionaryTypeEnum.SHORT
    );
    const { criteria, numberTerms } = payload;
    const res = dictSearch.searchDictionaryTerms(
      criteria,
      numberTerms,
      dictIds
    );
    return Promise.resolve(res);
  },
  // eslint-disable-next-line no-empty-pattern
  findShortDefinitionByWord({ commit }, payload) {
    const { word } = payload;
    commit('addSearchWord', {
      word
    });
  },
  findShortDefinitionBySelection({ commit }, payload) {
    const { selectedText } = payload;
    var PHRASE_MAX_LEN = 4;

    const parsedText = parsedSelectedText(selectedText);
    let word = '';
    let phrase = '';
    if (parsedText.length <= PHRASE_MAX_LEN) {
      word = parsedText[0];
      phrase = parsedText.join(' ').trim();
    }

    commit('addSearchWord', {
      word
    });
    commit('addSearchPhrase', {
      phrase
    });
  },
  async findShortDefinition({ state, commit }, payload) {
    const { word, phrase, dictType } = payload;

    let response = await applySearchByWord(phrase, dictType, state);
    let { localDefs, remoteDefs } = response;
    if (
      localDefs.dictionaryDefinition.count ||
      remoteDefs.dictionaryDefinition.count
    ) {
      commit('addCurrentDefinitions', {
        word: phrase,
        localDefs,
        remoteDefs,
        dictType
      });
      return state.currentDefinition;
    }
    response = await applySearchByWord(word, dictType, state);
    ({ localDefs, remoteDefs } = response);
    commit('addCurrentDefinitions', {
      word,
      localDefs,
      remoteDefs,
      dictType
    });
    return state.currentDefinition;
  },
  async findFullDictionaryDefinition({ commit }, payload) {
    const { searchTerm, dictionaryId } = payload;

    var query = {
      word: searchTerm,
      dictionaryId: JSON.stringify(dictionaryId)
    };
    const serverResp = await RestService.restRequest(
      'get',
      'Dict',
      'fulldefinition',
      query
    );
    const fullDefinition = {
      dictionaryDefinition: serverResp.data
    };
    commit('addCurrentFullDefinition', {
      fullDefinition,
      word: query.word
    });
    return fullDefinition;
  }
};

function _findTargetDefinition(definitions, targetWord) {
  if (!definitions || definitions.length === 0) {
    return [];
  }
  const groupe = groupBy(definitions, 'id');
  const word = getTargetWord(targetWord, Object.keys(groupe));
  return [groupe[word][0]];
}

function getTargetWord(targetWord, words) {
  words.sort(function(wordA, wordB) {
    var levA = _str.levenshtein(targetWord, wordA);
    var levB = _str.levenshtein(targetWord, wordB);
    return levA - levB;
  });
  return words[0];
}

function _sortFullDefinitions(fullDefinition, word) {
  fullDefinition.dictionaryDefinition.definitions.forEach(function(definition) {
    var hasFreqExactMatch = false;
    definition.definition.forEach(function(def) {
      if (!hasFreqExactMatch) {
        hasFreqExactMatch = def.id === word && def.frequencyNumber < 100;
      }
    });
    definition.definition.sort(function(defA, defB) {
      if (
        !hasFreqExactMatch &&
        defA.id === word &&
        defA.frequencyNumber > 100
      ) {
        defA.frequencyNumber = 0;
      }
      if (
        !hasFreqExactMatch &&
        defB.id === word &&
        defB.frequencyNumber > 100
      ) {
        defB.frequencyNumber = 0;
      }
      return defA.frequencyNumber - defB.frequencyNumber;
    });
  });
}

// mutations
const mutations = {
  addDictsInfo(state, payload) {
    const { dictsInfo } = payload;
    Object.keys(dictsInfo).forEach(function(dictId) {
      const dictionary = new Dictionary(dictsInfo[dictId]);
      state.dictionaryView.addDict(dictionary);
    });
  },
  addSearchWord(state, payload) {
    const { word } = payload;
    state.word = word;
  },
  addSearchPhrase(state, payload) {
    const { phrase } = payload;
    state.phrase = phrase;
  },
  addCurrentFullDefinition(state, payload) {
    const { fullDefinition, word } = payload;
    const dictType = DictionaryTypeEnum.FULL;
    const currentDefinition = new DefinitionsStructure(dictType);
    _sortFullDefinitions(fullDefinition, word);
    currentDefinition.addDefs(fullDefinition);
    state.currentFullDefinition = currentDefinition;
  },
  addCurrentDefinitions(state, payload) {
    const { localDefs, remoteDefs, dictType, word } = payload;
    const currentDefinition = new DefinitionsStructure(dictType);

    currentDefinition.addDefs(localDefs);
    currentDefinition.addDefs(remoteDefs);
    currentDefinition.definitions = _findTargetDefinition(
      currentDefinition.definitions,
      word
    );
    state.currentDefinition = currentDefinition;
    return currentDefinition;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
