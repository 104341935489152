<template>
  <Popup
    ref="dialog"
    :content-class="popupContentClass"
    hide-overlay
    scrollable
    :header-title="$t('ReadingSettingsBottomPopup')"
    :header-divider="true"
    :footer-divider="false"
    width="360"
    @popupEvent="closePopup"
  >
    <template #custom-content>
      <v-card-text>
        <ReadingSettingsComponent />
      </v-card-text>
    </template>
    <template #custom-footer></template>
  </Popup>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup.vue';
import ReadingSettingsComponent from '@/components/views/ReadingSettings/ReadingSettingsComponent/ReadingSettingsComponent';

export default {
  name: PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP,
  components: { Popup, ReadingSettingsComponent },
  computed: {
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP
      );
    },
    popupContentClass() {
      const settingsClass = this.isExtrasOpened
        ? 'popup-reading-settings -shifted'
        : 'popup-reading-settings';

      const hiddenClass = this.popupContext?.popupHidden ? 'hidden' : '';

      return `${settingsClass} ${hiddenClass}`;
    },
    isExtrasOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    }
  },
  methods: {
    closePopup() {
      const isCurrentPopupHidden = this.$store.getters[
        'ManagePopupStore/isPopupHidden'
      ](PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP);
      if (isCurrentPopupHidden) {
        return;
      }

      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP
      });
    }
  }
};
</script>

<style src="./ReadingSettingsBottomPopup.less" lang="less"></style>
