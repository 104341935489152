<template>
  <div class="annotations-search-filter">
    <div class="joint-container">
      <FilterInput
        clearable
        :placeholder="$t('Find note...')"
        @onFilter="onFilterByText"
      />
      <v-select
        v-model="category"
        :items="externalData.items"
        :menu-props="{
          bottom: true,
          offsetY: true,
          left: true,
          contentClass: 'tags-filter-popup'
        }"
        item-text="text"
        class="select-block"
        hide-details
        solo
        flat
        @change="onSelectChange"
      >
        <template slot="selection" slot-scope="data">
          <div
            v-if="data.item.value"
            class="circle-icon mr-3"
            :style="{ 'background-color': itemColor(data.item) }"
          ></div>
          <img v-else :src="allTagsIcon" class="all-tags mr-3" />
          <span class="category-name">{{ data.item.text }}</span>
        </template>
        <template #item="{item}">
          <div
            v-if="item.value"
            class="circle-icon mr-3"
            :style="{ 'background-color': itemColor(item) }"
          ></div>
          <img v-else :src="allTagsIcon" class="all-tags mr-3" />
          <span class="category-name">{{ item.text }}</span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import FilterInput from '@/components/views/FilterInput/FilterInput';
import images from '@/assets/images';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';

export default {
  name: 'FilterItem',
  components: { FilterInput },
  props: { externalData: Object },
  data() {
    return {
      allTagsIcon: '',
      clearIcon: 'ico-clear',
      arrowIcon: 'ico-arrow-chevron',
      category: '',
      filterText: ''
    };
  },
  computed: {
    themeName() {
      return this.$store.getters['ReadingSettingsStore/getThemeName'];
    }
  },
  watch: {
    themeName() {
      this.$nextTick(() => {
        this.allTagsIconMode();
      });
    }
  },
  mounted() {
    this.category = this.externalData?.items[0];
    this.allTagsIconMode();
  },
  methods: {
    filterEmit(category) {
      this.$emit('onAction', {
        category,
        filterText: this.filterText
      });
    },
    onFilterByText({ filterText }) {
      this.filterText = filterText;
      const category = this.category.hasOwnProperty('value')
        ? this.category.value
        : this.category;
      this.filterEmit(category);
    },
    onSelectChange(category) {
      this.category = category;
      this.filterEmit(category);
    },
    allTagsIconMode() {
      switch (this.themeName) {
        case ThemeClassNamesEnum.NIGHT:
          return (this.allTagsIcon = images.multiCirclesDark);
        case ThemeClassNamesEnum.SEPIA:
          return (this.allTagsIcon = images.multiCircles);
        default:
          return (this.allTagsIcon = images.multiCircles);
      }
    },
    itemColor(item) {
      return this.themeName === ThemeClassNamesEnum.NIGHT
        ? item.nightColor
        : item.color;
    }
  }
};
</script>

<style lang="less" src="./FilterItem.less"></style>
