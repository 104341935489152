export default {
  SAVE_FOR_OFFLINE: 'saveForOffline',
  DOWNLOAD_AUDIO: 'downloadAudio',
  EDIT: 'edit',
  PRINT: 'print',
  SHARE: 'share',
  CHANGE_COVER: 'changeCover',
  TOGGLE_DEFAULT: 'toggleDefault',
  DUPLICATE: 'duplicate',
  DELETE: 'delete'
};
