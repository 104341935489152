<template>
  <transition name="offline-bar">
    <div v-if="visible" class="offline-bar" :class="barClass">
      {{ $t(barMessage) }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OfflineBar',
  props: {
    isOffline: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    barClass() {
      return this.isOffline ? 'offline' : 'online';
    },
    barMessage() {
      return this.isOffline
        ? 'OfflineBar.offline.label'
        : 'OfflineBar.online.label';
    }
  },
  watch: {
    isOffline() {
      const displayTime = 4_000;
      this.visible = true;

      setTimeout(() => {
        this.visible = false;
      }, displayTime);
    }
  }
};
</script>

<style scoped lang="less">
@import 'OfflineBar.less';
</style>
