'use strict';
const CustomCategoriesEnum = require('../enums/CustomCategoriesEnum');

function parseAudioLink(audioLink) {
  const url = new URL(audioLink);
  const pathname = url.pathname;

  const parsedLink = pathname.split('/').filter(part => part);
  const fileHash = parsedLink.pop();
  const publicationId = parsedLink.pop();
  const audioExt = fileHash.split('.').pop();
  return {
    fileHash,
    publicationId,
    audioExt
  };
}

function getCategoryName(publication, originalCategory = false) {
  const language = publication?.language || 'en';
  let category;
  if (
    originalCategory &&
    publication?.bookCategory &&
    publication.bookCategory !== publication?.category
  ) {
    category = publication.bookCategory;
  } else {
    category = publication?.category || CustomCategoriesEnum.UNKNOWN;
  }
  const categoryName = language === 'en' ? category : `${category}_${language}`;
  return encodeStringToPath(categoryName);
}

function getGenreName(publication, genre) {
  const language = publication?.language || 'en';
  const routeName = encodeStringToPath(genre);
  const genreName = language === 'en' ? routeName : `${routeName}_${language}`;
  return genreName;
}

function createCategoryUrl(publication) {
  const categoryName = getCategoryName(publication);
  return `category/${categoryName}`;
}

function addTrailingSlash(url) {
  const newUrl = new URL(url);
  if (!/(\/$|\.[^.]+$)/.test(newUrl.pathname)) {
    newUrl.pathname += '/';
  }
  return newUrl;
}

function encodeStringToPath(str = '') {
  return str.toLowerCase().replace(/\s/g, '-');
}

module.exports = {
  addTrailingSlash,
  parseAudioLink,
  encodeStringToPath,
  getGenreName,
  getCategoryName,
  createCategoryUrl
};
