import get from 'lodash/get';
import each from 'lodash/each';
import Utils from '@/services/utils/Utils';
import RestPaymentsService from '@/services/RestPaymentsService';
import ElectronService from '@/services/Electron/ElectronService';
import LoggerFactory from '@/services/utils/LoggerFactory';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import { StatusesEnum } from '@shared/enums/SubscriptionTypesEnum';
const logger = LoggerFactory.getLogger('PaymentsService.js');

class StripeService {
  constructor() {
    this.cardElements = {};
  }
  async initPaymentForm(context, elementId, eventHandler, language) {
    const publicKey = context.parameters.stripePublicKey;
    const stripeJsUrl = context.parameters.stripeJsUrl;
    await this._loadStripe(publicKey, language, stripeJsUrl);
    const style = { invalid: { color: '#dc5148' } };
    this.cardElements[elementId] = this.stripe
      .elements()
      .create('card', { style });
    this.cardElements[elementId].mount(`#${elementId}`);
    this.cardElements[elementId].on('change', event => eventHandler(event));
    return this.cardElements[elementId];
  }
  async _loadStripe(publicKey, locale, stripeJsUrl) {
    if (this.stripe && this.stripe._locale === locale) {
      return this.stripe;
    }
    await new Promise((res, rej) => {
      const script = document.createElement('script');
      script.onload = res;
      script.onerror = rej;
      script.src = stripeJsUrl;
      document.head.appendChild(script);
    });
    if (!stripeJsUrl || !window.Stripe) {
      throw new Error("Stripe wasn't load");
    }
    this.stripe = new window.Stripe(publicKey, { locale: locale || 'en' });
    return this.stripe;
  }
  _getElectronPaymentUrl(context, path, searchParams) {
    const url = new URL(context.paymentServerUrl);
    url.pathname = Utils.pathJoin([url.pathname, path]);
    each(searchParams, (param, key) => {
      url.searchParams.append(key, param);
    });
    return url.href;
  }
  async checkout({
    context,
    runId,
    setId,
    type,
    groupType,
    promoCode,
    language
  }) {
    const publicKey = context.parameters.stripePublicKey;
    if (context.isElectron) {
      ElectronService.openExternal(
        this._getElectronPaymentUrl(context, '/static/payment', {
          setId,
          type,
          groupType,
          promoCode,
          RunId: runId,
          isElectron: true
        })
      );
      return;
    }

    try {
      const { sessionId } = await RestPaymentsService.restRequest(
        'post',
        'session',
        null,
        {
          setId,
          type,
          groupType,
          promoCode,
          successUrl: location.href,
          cancelUrl: location.href
        }
      );
      const stripeJsUrl = context.parameters.stripeJsUrl;
      await this._loadStripe(publicKey, language, stripeJsUrl);
      const redirectError = await this.stripe.redirectToCheckout({
        sessionId
      });
      if (redirectError) {
        logger.error(redirectError);
      }
    } catch (err) {
      logger.error(err);
    }
  }
  togglePaymentForm(isActive = true, elementId) {
    if (!this.cardElements[elementId]) {
      logger.info(`Card element not initialize`);
      return;
    }
    this.cardElements[elementId].update({ disabled: !isActive });
  }
  async subscribe(user, elementId, noPaymentMethod, data) {
    const cardElement = !noPaymentMethod ? this.cardElements[elementId] : null;
    if (!cardElement && !noPaymentMethod) {
      throw new Error('Init the payment form first');
    }
    const latestInvoicePaymentIntentStatus = UnifiedSettingsService.getSetting(
      'payments',
      'latestInvoicePaymentIntentStatus'
    );
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      const invoiceId = UnifiedSettingsService.getSetting(
        'payments',
        'latestInvoiceId'
      );
      // create new payment method & retry payment on invoice with new payment method
      return this._createPaymentMethod(user, invoiceId, cardElement, data);
    } else {
      // create new payment method & create subscription
      return this._createPaymentMethod(user, null, cardElement, data);
    }
  }
  updateSubscriptionPromoCode(subId, promoCode) {
    return RestPaymentsService.restRequest('put', 'subscription-promo', null, {
      subId,
      promoCode
    });
  }
  async processInvoiceCustomerAction(context, id, language) {
    const invoice = await RestPaymentsService.restRequest(
      'get',
      `invoice/${id}`
    );
    if (invoice.paid) {
      logger.log(`Invoice ${id} already paid`);
      return;
    }
    if (get(invoice, 'payment_intent.next_action.type') === 'use_stripe_sdk') {
      const stripeJsUrl = context.parameters.stripeJsUrl;
      await this._loadStripe(
        context.parameters.stripePublicKey,
        language,
        stripeJsUrl
      );
      await this._handlePaymentThatRequiresCustomerAction({
        invoice,
        paymentMethodId: invoice.payment_intent.payment_method
      });
    } else {
      logger.warn('Unknown action type');
      await this.redirectToPortal();
    }
  }
  async _createPaymentMethod(user, invoiceId, cardElement, data) {
    const isPaymentRetry = !!invoiceId;
    const paymentMethodId = await this._setUpCard(cardElement, user);
    if (isPaymentRetry) {
      // TODO implement payment failure flow
      // Update the payment method and retry invoice payment
      // return retryInvoiceWithNewPaymentMethod({
      //   customerId: customerId,
      //   paymentMethodId: result.paymentMethod.id,
      //   invoiceId: invoiceId,
      //   priceId: priceId
      // });
    } else {
      // Create the subscription
      return this._createSubscription({
        paymentMethodId,
        ...data
      });
    }
  }
  async _setUpCard(cardElement, user) {
    let paymentMethodId;
    if (!cardElement) {
      return;
    }
    const result = await this.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: user.name
      }
    });
    if (result.error) {
      throw result.error;
    }
    paymentMethodId = result.paymentMethod.id;
    const {
      paymentMethodId: newPaymentMethodId
    } = await RestPaymentsService.restRequest('post', 'setup-card', null, {
      paymentMethodId
    });

    paymentMethodId = newPaymentMethodId || paymentMethodId;
    return paymentMethodId;
  }
  async _createSubscription({
    setId,
    type,
    email,
    groupType,
    promoCode,
    paymentMethodId
  }) {
    const subscription = await RestPaymentsService.restRequest(
      'post',
      'subscription',
      null,
      {
        setId,
        type,
        email,
        groupType,
        promoCode,
        paymentMethodId
      }
    );

    await this._handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId
    });
    await this._handleRequiresPaymentMethod(subscription);
    return subscription;
  }
  async _handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    paymentMethodId,
    isRetry
  }) {
    if (subscription && subscription.status === StatusesEnum.ACTIVE) {
      // Subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }

    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.paymentIntent;
    paymentIntent = paymentIntent || {};
    const subId = invoice ? invoice?.subscription : subscription?.subId;
    if (
      subId &&
      (paymentIntent.status === 'requires_action' ||
        (isRetry === true &&
          paymentIntent.status === 'requires_payment_method'))
    ) {
      const result = await this.stripe.confirmCardPayment(
        paymentIntent.clientSecret ?? paymentIntent.client_secret,
        {
          payment_method: paymentMethodId
        }
      );
      if (result.error) {
        // Start code flow to handle updating the payment details.
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc).
        throw result.error;
      }
      if (result.paymentIntent.status === 'succeeded') {
        const MAX_RETRY = 10;
        let retry = 0;
        const loadSubscriptionInfo = () => {
          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              const sub = await this.getSubscription(subId);
              if (sub?.active) {
                return resolve(sub);
              }
              if (retry > MAX_RETRY) {
                return reject("Payment wasn't confirmed");
              }
              retry++;
              await loadSubscriptionInfo();
            }, 2000);
          });
        };
        subscription = await loadSubscriptionInfo();
        // Show a success message to your customer.
        // There's a risk of the customer closing the window before the callback.
        // We recommend setting up webhook endpoints later in this guide.
        return {
          subscription,
          invoice,
          paymentMethodId
        };
      }
    } else {
      // No customer action needed.
      return { subscription, paymentMethodId };
    }
  }
  _handleRequiresPaymentMethod(subscription) {
    if (
      subscription.status === StatusesEnum.ACTIVE ||
      subscription.status === StatusesEnum.TRIALING ||
      subscription.status === StatusesEnum.INCOMPLETE
    ) {
      // subscription is active, no customer actions required.
      return subscription;
    } else if (
      subscription.paymentIntent?.status === 'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      UnifiedSettingsService.setSetting(
        'payments',
        'latestInvoiceId',
        subscription.latestInvoiceId
      );
      UnifiedSettingsService.setSetting(
        'payments',
        'latestInvoicePaymentIntentStatus',
        subscription.paymentIntent.status
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return subscription;
    }
  }
  async getSubscription(subId) {
    try {
      const sub = await RestPaymentsService.restRequest(
        'get',
        `subscription/${subId}`
      );
      return sub;
    } catch (error) {
      logger.error(error);
    }
  }
  async openAccountDashboard() {
    const url = await RestPaymentsService.restRequest(
      'get',
      'account-login-link'
    );
    window.open(url, '_self');
  }
  async calcDiscount(setId, promoCode) {
    return RestPaymentsService.restRequest('get', 'discount', {
      setId,
      promoCode
    });
  }
  async getPromoCodeInfo(code) {
    try {
      const promoCode = await RestPaymentsService.restRequest(
        'get',
        'promo-code-info',
        {
          promoCode: code
        }
      );
      return promoCode;
    } catch (error) {
      logger.warn(error);
      return null;
    }
  }
  saveUserPromoCode(promoCode) {
    return RestPaymentsService.restRequest('post', 'user-promo-code', null, {
      promoCode
    });
  }
  async removeUserPromoCode() {
    try {
      await RestPaymentsService.restRequest('delete', 'user-promo-code');
    } catch (error) {
      logger.warn(error);
    }
  }
  async redirectToPortal(context, isElectron) {
    try {
      const returnUrl = isElectron
        ? this._getElectronPaymentUrl(context, '/static/cancel')
        : location.href;
      const { url } = await RestPaymentsService.restRequest(
        'post',
        'session/portal',
        null,
        { returnUrl }
      );
      if (isElectron) {
        ElectronService.openExternal(url);
      } else {
        window.open(url, '_self');
      }
    } catch (error) {
      logger.error(error);
    }
  }
  async getCards() {
    try {
      const resp = await RestPaymentsService.restRequest('get', 'cards');
      return resp;
    } catch (error) {
      logger.error(error);
    }
  }
  async getPaymentHistory() {
    try {
      const resp = await RestPaymentsService.restRequest('get', 'payments');
      return resp;
    } catch (error) {
      logger.error(error);
    }
  }
}

export default new StripeService();
