<template>
  <v-dialog
    v-model="dialog"
    :content-class="'search-dummy-popup'"
    width="1440px"
    @click:outside="setStopSearchLoading"
  >
    <div class="search-dummy-content">
      <div class="search-dummy-content-wrapper">
        <div class="search-criteria-block" data-test="top-bar">
          <div class="progress-bar-progress"><div class="color"></div></div>
          <div class="search-field-container">
            <span class="search-element logo-block"
              ><BaseSpriteIcon icon-name="ico-search"
            /></span>
            <div class="search-field-wrapper">
              <input
                type="text"
                placeholder="Search Library"
                name="searchInput"
                required="required"
                autofocus="autofocus"
              />
            </div>
          </div>
          <BaseButton
            outlined
            x-large
            class="search-element -search-filter-icon disabled"
          >
            <span class="icon-container">
              <BaseSpriteIcon
                custom-class="filter-icon"
                icon-name="ico-filter"/>
              <div class="filter-label">
                <span class="label-show">Show filters</span
                ><BaseSpriteIcon icon-name="ico-arrow-chevron" /></div
            ></span>
          </BaseButton>
          <BaseButton
            data-test="tooltip-icon"
            icon
            x-large
            class="search-element -tooltip-icon"
          >
            <span class="icon-container"
              ><BaseSpriteIcon icon-name="ico-info-app"
            /></span>
          </BaseButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: PopupNamesEnum.SEARCH_DUMMY,
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    setStopSearchLoading() {
      this.$store.commit('ContextStore/stopSearchLoading', false);
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.SEARCH_DUMMY
      });
    }
  }
};
</script>

<style lang="less" src="./SearchDummy.less"></style>
