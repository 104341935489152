import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PublicationsLoaderFSService.js');

import PromiseUtil from '@/services/utils/PromiseUtil';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import IndexedDBWrapper from '@/services/utils/IndexedDBWrapper';
import RequestService from '@/services/RequestService';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import StoreKeys from '@/enums/StoreKeyEnum';

const loaderContext = {
  serverUrl: '',
  userId: ''
};
let initDefer = PromiseUtil.createDeferred();
let dbWrapper;

function init(Context) {
  loaderContext.serverUrl = Context.serverUrl;
  return this;
}

async function setUserId(userId) {
  loaderContext.userId = userId;
  await initIndexedDB(loaderContext.userId);
  initDefer.resolve();
}

async function initIndexedDB(name) {
  const tables = {};
  for (const tableName of Object.values(StoreKeys)) {
    tables[tableName] = 'id';
  }
  const suffix = '';
  const version = 1;
  const { indexDB } = await FeatureDetectorService.getDetections();
  dbWrapper = new IndexedDBWrapper(name, suffix, indexDB).initTables(
    tables,
    version
  );
}

async function getDbWrapper() {
  await initDefer.promise;
  return dbWrapper;
}

async function getAllStudyClasses() {
  try {
    const data = {};
    const response = await RequestService.request(
      'get',
      'StudyClass',
      'searchstudyclasses',
      data
    );
    return response.data;
  } catch (error) {
    logger.error(`Get error on search study classes error:${error}`);
    return [];
  }
}

async function getPublicationsData({ publicationsTypes, errorHandler }) {
  let response = _createPubDataResp({});
  try {
    response = await loadPublicationsData(publicationsTypes);
  } catch (error) {
    const message = 'LibraryStore.FsBooks.notFound';
    errorHandler(error, message);
  }
  return response;
}

async function loadPublicationsData(publicationsTypes) {
  const _dbWrapper = await getDbWrapper();
  const promisesList = publicationsTypes.map(pubType => {
    if (PublicationsTypes.STUDY_COURSE === pubType) {
      return getAllStudyClasses();
    }
    if (PublicationsTypes.SUGGESTED_BOOK === pubType) {
      return [];
    }
    const tableName = StoreKeys[pubType];
    return _dbWrapper.getTableData(tableName);
  });

  const pubData = await Promise.all(promisesList);
  const fsData = _mergeArrayData(pubData);
  const publicationsData = {};

  for (const pub of fsData) {
    const language = pub.language || 'common';
    publicationsData[language] = publicationsData[language] || [];
    publicationsData[language].push(pub);
  }
  return _createPubDataResp(publicationsData);
}

function _createPubDataResp(data) {
  return { publicationsData: data };
}

async function getPublicationsByIds(tableName, publicationsIds) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.getByIds(tableName, publicationsIds);
}

async function savePublications(tableName, publications) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.savePublications(tableName, publications);
}

async function savePublicationData(tableName, publication) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.savePublication(tableName, publication);
}

async function removePublicationById(tableName, publicationId) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.removeById(tableName, publicationId);
}

async function removeTable(tableName) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.removeTable(tableName);
}

function _mergeArrayData(data) {
  const fsData = [];
  for (const items of data) {
    [].push.apply(fsData, items);
  }

  return fsData;
}

export default {
  init,
  setUserId,
  savePublications,
  savePublicationData,
  removePublicationById,
  removeTable,
  getPublicationsData,
  getPublicationsByIds
};
