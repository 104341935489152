<template>
  <Popup
    scrollable
    content-class="payment-offline-popup"
    :header-divider="false"
    :footer-divider="false"
    :content-image="picAlert"
    :content-title="$t('Payment.offline.popup.text')"
    :primary-label="$t('OK')"
    :primary-footer-handler="closePopup"
    @popupEvent="closePopup"
  />
</template>

<script>
import images from '@/assets/images';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup.vue';

export default {
  name: PopupNamesEnum.PAYMENT_OFFLINE_POPUP,
  components: {
    Popup
  },
  props: {
    popupContext: Object
  },
  data() {
    return {
      picAlert: images.picAlert
    };
  },
  methods: {
    closePopup() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.PAYMENT_OFFLINE_POPUP }
      });
      if (typeof this.popupContext?.closeCallback === 'function') {
        this.popupContext.closeCallback();
      }
    }
  }
};
</script>

<style lang="less">
.payment-offline-popup {
  .popup-header {
    box-shadow: none;
    margin-bottom: -25px;
  }
}
</style>

<style lang="less" scoped>
@import '~@/assets/styles/base.less';

.payment-offline-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 0 20px;

  .pic-block {
    width: 80px;
    height: 92px;
    background-image: @pic-sales;
    background-size: contain;
  }

  .text-block {
    max-width: 270px;
    text-align: center;
    font-size: calc(var(--h2FontSize) + 1px);
    line-height: 1.5;
    padding: 15px 0 45px;
  }
}

.popup-footer {
  button {
    font-family: var(--sansFontFamily);
    font-size: var(--h2FontSize);
  }
}
</style>
