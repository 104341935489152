import dayJS from '@/dayJS';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import cloneDeep from 'lodash/cloneDeep';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import { DashboardSettings } from '@/classes/factories/Dashboard/DashboardSettingsFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
import AchievementCalculator from '@shared/userActivity/AchievementCalculator';
const logger = LoggerFactory.getLogger('UserActivityDao.js');

import DB from '@/services/Agent/dao/DB';

const errorCodes = {
  NOT_FOUND: 404
};

const TYPE = 'userActivity';

function _getFormattedDate(date) {
  return dayJS.get(date).format(AppConstantsUtil.USER_ACTIVITY_DATE_FORMAT);
}

class ActivityBuilder {
  constructor(data) {
    this.goal = data.goal;
    this.totalWords = data.totalWords;
    this.minutesRead = data.minutesRead;
    this.date = data.date;
    this.achievedGoal = data.achievedGoal;
    this.publications = data.publications || {};
  }
  setGoal(goal) {
    this.goal = goal;
    return this;
  }
  setTotalWords(totalWords) {
    this.totalWords = totalWords;
    return this;
  }
  setMinutesRead(minutesRead) {
    this.minutesRead = minutesRead;
    return this;
  }
  setDate(date) {
    this.date = date;
    return this;
  }
  setAchievedGoal(achievedGoal) {
    this.achievedGoal = achievedGoal;
    return this;
  }
  setPublicationWords(pubId, readWords) {
    this.publications[pubId] = { readWords, changedAt: Date.now() };
    return this;
  }
  build() {
    return {
      goal: this.goal,
      totalWords: this.totalWords,
      minutesRead: this.minutesRead,
      date: this.date,
      achievedGoal: this.achievedGoal,
      publications: this.publications,
      changedAt: Date.now()
    };
  }
}

class ActivityCalculator {
  constructor(data = []) {
    this.activity = data;
    this._updatePublicationMap();
  }
  updateActivity(goal, date, pubId, readWords) {
    const formattedDate = _getFormattedDate(date);
    const currentActivityIndex = this.activity.findLastIndex(
      a => a.date === formattedDate
    );
    const isFound = currentActivityIndex > -1;
    const currentActivity = this.activity[currentActivityIndex] || {};
    const totalWords = isFound
      ? Object.entries(currentActivity.publications || {}).reduce(
          (result, [pId, item]) => {
            if (pubId === pId) {
              return result + readWords;
            }
            return result + (item?.readWords ?? 0);
          },
          0
        )
      : readWords;
    const minutesRead = totalWords / AppConstantsUtil.DEFAULT_AUDIO_SPEED;
    const activityBuilder = new ActivityBuilder(currentActivity);
    const isAchievedGoal = Boolean(goal && minutesRead >= goal);
    const newActivityBuilder = activityBuilder
      .setGoal(goal)
      .setTotalWords(totalWords)
      .setMinutesRead(minutesRead)
      .setDate(currentActivity.date || formattedDate)
      .setAchievedGoal(isAchievedGoal);
    if (pubId && readWords) {
      newActivityBuilder.setPublicationWords(pubId, readWords);
    }
    const newActivity = newActivityBuilder.build();
    if (isFound) {
      this.activity.splice(currentActivityIndex, 1, newActivity);
    } else {
      this.activity.push(newActivity);
    }
    this._updatePublicationMap();
    return this;
  }
  deleteActivitiesAfterDate(date) {
    const formattedDate = _getFormattedDate(date);
    const index = this.activity.findIndex(a => a.date > formattedDate);
    if (index > -1) {
      this.activity.splice(index);
      this._updatePublicationMap();
    }
  }
  getActivityByDate(date) {
    const formattedDate = _getFormattedDate(date);
    return this.activity.find(a => a.date === formattedDate);
  }
  hasPublicationInActivity(pubId) {
    return this._publicationMap.has(pubId);
  }
  _updatePublicationMap() {
    const pubIdsSet = new Set();
    this.activity.forEach(activity => {
      Object.entries(activity.publications).forEach(([pId]) =>
        pubIdsSet.add(pId)
      );
    });
    this._publicationMap = pubIdsSet;
  }
  build() {
    return sortBy(this.activity, ['date']);
  }
}

async function getActivity(userId) {
  const _id = _getId(userId);
  const rawDoc = (await _findDocument(_id)) || _calcNewActivity({});
  return {
    activity: rawDoc.activity,
    achievement: rawDoc.achievement,
    settings: rawDoc.settings
  };
}

async function updateActivity(
  userId,
  publicationId,
  studyItemWordsCountByDates,
  progressSummary
) {
  const _id = _getId(userId);
  const { activity, achievement, settings } = await getActivity(userId);
  const {
    activity: newActivity,
    achievement: newAchievement,
    settings: newSettings
  } = _calcNewActivity({
    publicationId,
    studyItemWordsCountByDates,
    progressSummary,
    activity,
    achievement,
    settings
  });
  const customizer = (a, b, name) => {
    // ignore changedAt which generates for each calculation of newActivity
    if (name === 'changedAt' && a !== b) {
      return true;
    }
  };
  if (isEqualWith(activity, newActivity, customizer)) {
    return;
  }
  return _updateDocument(_id, {
    userId,
    type: TYPE,
    activity: newActivity,
    achievement: newAchievement,
    settings: newSettings
  });
}

async function updateActivitySettings(userId, newSettings) {
  const _id = _getId(userId);
  const newSettingsBuilder = new DashboardSettings(newSettings);
  if (!newSettingsBuilder.isValid()) {
    logger.error("Activity settings are invalid. Settings wasn't saved");
    return;
  }
  const { activity, achievement, settings: oldSettings } = await getActivity(
    userId
  );
  const settings = newSettingsBuilder.build();
  if (
    oldSettings &&
    oldSettings.goal === settings.goal &&
    isEqual(oldSettings.weekdays, settings.weekdays)
  ) {
    return;
  }
  const {
    activity: newActivity,
    achievement: newAchievement
  } = _calcNewActivity({ activity, achievement, settings });
  await _updateDocument(_id, {
    userId,
    type: TYPE,
    activity: newActivity,
    achievement: newAchievement,
    settings
  });
}

function _calcNewActivity({
  publicationId,
  studyItemWordsCountByDates,
  progressSummary,
  activity,
  achievement,
  settings
}) {
  const date = dayJS.get().subtract(4, 'hours');
  const shortFormattedDate = date.format('YY.MM.DD');
  const readWords =
    studyItemWordsCountByDates?.[shortFormattedDate]?.wordsCount ?? 0;

  const newSettingsBuilder = new DashboardSettings(settings);
  const currentGoal = newSettingsBuilder.getGoalForDate(date);
  const newSettings = newSettingsBuilder.build();
  const goal = currentGoal ? currentGoal.goal : 0;
  const activityCalculator = new ActivityCalculator(cloneDeep(activity));
  const achievementCalculator = new AchievementCalculator(achievement);
  activityCalculator.updateActivity(goal, date, publicationId, readWords);

  const completedBooks = Object.entries(progressSummary || {}).reduce(
    (result, [pubId, val]) => {
      if (val.completed && activityCalculator.hasPublicationInActivity(pubId)) {
        result.push(pubId);
      }
      return result;
    },
    []
  );

  // add future activity to interrupt streak chain if the user doesn't use the app for some time
  const nextActivityDate = newSettingsBuilder.getNextActivityDate(date);
  const nextActivity = nextActivityDate
    ? activityCalculator.getActivityByDate(nextActivityDate)
    : null;
  if (!nextActivity) {
    const formattedNextActivityDate = nextActivityDate
      ? _getFormattedDate(nextActivityDate)
      : null;
    activityCalculator.deleteActivitiesAfterDate(date);
    if (formattedNextActivityDate) {
      const nextGoal = newSettingsBuilder.getGoalForDate(nextActivityDate);
      activityCalculator.updateActivity(
        nextGoal.goal ?? newSettings.goal,
        formattedNextActivityDate,
        null,
        0
      );
    }
  }

  const newActivity = activityCalculator.build();
  const today = _getFormattedDate(date);
  const newAchievementsCalc = achievementCalculator.calcAchievements(
    newActivity.filter(a => today >= a.date)
  );

  if (progressSummary) {
    newAchievementsCalc.setCompletedBooks(completedBooks);
  }
  const newAchievements = newAchievementsCalc.build();
  return {
    activity: newActivity,
    achievement: newAchievements,
    settings: newSettings
  };
}

function _getId(userId) {
  return DB.id.userActivity(userId);
}

async function _updateDocument(id, data) {
  const doc = await _findDocument(id);
  if (!doc) {
    return _putDocument(id, data);
  } else {
    return _putDocument(id, data, doc._rev);
  }
}

function _putDocument(id, data, revision) {
  Object.assign(data, { _id: id, _rev: revision });
  return DB.userRW().put(data);
}

function _findDocument(id) {
  return DB.userRW()
    .get(id)
    .catch(err => {
      if (err.status === errorCodes.NOT_FOUND) {
        return null;
      }
      logger.error(err);
    });
}

export default {
  getActivity,
  updateActivity,
  updateActivitySettings
};
