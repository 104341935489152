import Utils from '@/services/utils/Utils';
import AppStateEnum from '@/enums/AppStateEnum';

import QueryParamService from '@/services/QueryParamService';

const routes = [
  // ? @yareg
  // {
  //   path: '/login_subscribe',
  //   name: AppStateEnum.LOGIN_SUBSCRIBE,
  //   props: route => {
  //     const email = Object.keys(route.query)[0];
  //     if (!email) {
  //       return;
  //     }

  //     return {
  //       subscribeEmail: email
  //     };
  //   }
  // },
  {
    path: '/affiliates',
    name: AppStateEnum.AFFILIATES,
    beforeEnter(to, from, store) {
      const isAdmin = store.getters['UserStore/isUserAdmin'];
      const isCampaignLinksEnabled =
        store.getters['ContextStore/getIsCampaignLinksEnabled'];
      const isSalesPerson = store.getters['UserStore/isUserSalesPerson'];
      if ((!isAdmin && !isSalesPerson) || !isCampaignLinksEnabled) {
        return { name: AppStateEnum.MANAGE_PUBLICATION };
      }
    }
  },
  {
    path: '/resetPassword',
    name: AppStateEnum.RESET_PASSWORD,
    beforeEnter(to, from, store) {
      const taskConfirmationHashCode = QueryParamService.getQueryParamByRoute(
        'taskConfirmationHashCode',
        to
      );
      if (!taskConfirmationHashCode) {
        const name = store.getters['ContextStore/getDefaultRoute'];
        return { name };
      }
    }
  },
  {
    path: '/login.*',
    name: AppStateEnum.LOGIN,
    beforeEnter(to, from, store) {
      const isAuthenticated = store.getters['UserStore/isAuthenticated'];
      if (isAuthenticated) {
        const afterLoginRoute =
          store.getters['ContextStore/getAfterLoginRoute'];
        return { name: afterLoginRoute };
      }
    }
  },
  {
    path: '/state=.*',
    beforeEnter(to, from, store) {
      const query = Utils.parseUrlQuery(to.path);
      const oauthRoute = store.getters['ContextStore/getAfterOauthRoute'];
      return { name: oauthRoute, query };
    }
  },
  {
    path: '/access_token.*',
    beforeEnter(to, from, store) {
      const query = Utils.parseUrlQuery(to.path);
      const oauthRoute = store.getters['ContextStore/getAfterOauthRoute'];
      return { name: oauthRoute, query };
    }
  },
  {
    path: '/pricing',
    name: AppStateEnum.PRICING,
    beforeEnter(to, from, store) {
      const query = Utils.parseUrlQuery(to.path);
      const isPurchaseEnabled = store.getters['ContextStore/isPurchaseEnabled'];
      if (!isPurchaseEnabled) {
        return { name: AppStateEnum.MANAGE_PUBLICATION, query };
      }
    }
  }
];

async function beforeEnter(to, from, store) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const routeRegex = new RegExp(route.path);
    if (routeRegex.test(to.path) && route.beforeEnter) {
      return route.beforeEnter(to, from, store);
    }
  }
}

export default async function(context) {
  const { store, from = {}, route, redirect } = context;
  const redirectParams = await beforeEnter(route, from, store);

  if (redirectParams && route.name !== redirectParams.name) {
    redirect({
      name: redirectParams.name,
      query: redirectParams.query,
      params: redirectParams.params
    });
    return;
  }
}
