export default {
  LIBRARY: 'library',
  LIBRARY_SET: 'library_set',
  BOOK_LIST: 'bookList',
  RECENT_BOOKS: 'recent_books',
  NEW_BOOKS: 'new_books',
  FAVORITES: 'favorites',
  LIBRARY_EDITOR: 'library_editor',
  INSIDE_SYLLABUS: 'inside_syllabus',
  INSIDE_LIBRARY_SET: 'inside_library_set',
  STUDY: 'study',
  COMPILATIONS: 'compilations',
  MY_MATERIALS: 'my_materials',
  ALL_PUBLICATIONS: 'allPublications',
  COLLECTION: 'Collection',
  USERS: 'Users',
  ABOUT: 'about',
  PRICING: 'pricing',
  VOCABULARY_ASSESSMENT: 'vocabulary_assessment'
};
