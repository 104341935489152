<template>
  <BaseButton large outlined @click="openDialog">
    <span class="button-text">{{
      $t('VocabularyAssessmentControl.Back.label')
    }}</span>
    <span class="button-icon"><BaseSpriteIcon icon-name="ico-close"/></span>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import AppStateEnum from '@/enums/AppStateEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import images from '@/assets/images';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger(
  'VocabularyAssessmentBackButtonControl.vue'
);

export default {
  name: 'VocabularyAssessmentBackButtonControl',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  data() {
    return {
      contentImage: images.picOff,
      contentTitle: this.$t('VocabularyAssessmentControl.Dialog.Title.text'),
      contentMessage: this.$t(
        'VocabularyAssessmentControl.Dialog.Message.text'
      ),
      secondaryLabel: this.$t('VocabularyAssessmentControl.Back.label'),
      primaryLabel: this.$t('VocabularyAssessmentControl.Dialog.Close.label')
    };
  },
  methods: {
    openDialog() {
      try {
        this.$store.dispatch('ManagePopupStore/openPopup', {
          name: PopupNamesEnum.VOCABULARY_ASSESSMENT_DIALOG,
          popupContext: {
            contentImage: this.contentImage,
            contentTitle: this.contentTitle,
            contentMessage: this.contentMessage,
            secondaryHandler: this.toLibrary,
            secondaryLabel: this.secondaryLabel,
            primaryHandler: null,
            primaryLabel: this.primaryLabel
          }
        });
      } catch (err) {
        this.$_errorHandler(err, 'Vocabulary Assessment popup opening failed');
      }
    },

    toLibrary() {
      this.$router.push({
        name: AppStateEnum.MANAGE_PUBLICATION
      });
    },

    $_errorHandler(error, errorMessage) {
      logger.error(`${errorMessage}: ${error}`);
    }
  }
};
</script>
<style lang="less" src="./VocabularyAssessmentBackButtonControl.less"></style>
