const initState = () => ({
  recentScrollPosition: {}
});

const storeGetters = {
  getRecentScrollPosition: state => key => {
    return state.recentScrollPosition[key] ?? 0;
  }
};

const actions = {
  reset({ commit }) {
    commit('setDefaultState', initState());
  }
};

const mutations = {
  setRecentScrollPosition(state, { key, position }) {
    state.recentScrollPosition[key] = position;
  },
  setDefaultState(state, defaultState) {
    Object.keys(state).forEach(key => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
