<template>
  <v-dialog
    v-model="isLoading"
    width="250px"
    hide-overlay
    persistent
    content-class="loader-wrapper"
  >
    <v-card>
      <div class="loading-box">
        <div class="progress">
          <div class="loadingBar" :style="{ width: `${loadingPercentage}%` }" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingView',
  props: {
    popupContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingPercentage: 0,
      fakePercentageTimeout: null,
      isLoading: true
    };
  },
  watch: {
    'popupContext.isResolved': function() {
      clearTimeout(this.fakePercentageTimeout);
      this.loadingPercentage = 100;
    }
  },
  mounted() {
    this.loadingPercentage = 25;
    this.fakePercentageTimeout = setTimeout(() => {
      this.loadingPercentage = 75;
    }, 1000);
  }
};
</script>

<style lang="less" src="./LoadingView.less"></style>
