<template>
  <Popup
    v-click-outside="close"
    content-class="ann-info-popup"
    hide-overlay
    :fullscreen="false"
    :header-divider="false"
    :footer-divider="false"
    :width="popupWidth"
    @popupEvent="close"
  >
    <template #custom-header>
      <div v-if="isMultipleAnnotations" class="count-block">
        <button
          :disabled="isFirstAnn"
          class="d-flex"
          :class="{ disabled: isFirstAnn }"
          @click="activeAnnIndex--"
        >
          <BaseSpriteIcon icon-name="ico-arrow-left-line" />
        </button>
        <span class="count mx-2">
          {{ `${activeAnnIndex + 1}/${annotations.length}` }}
        </span>
        <button
          :disabled="isLastAnn"
          class="d-flex"
          :class="{ disabled: isLastAnn }"
          @click="activeAnnIndex++"
        >
          <BaseSpriteIcon icon-name="ico-arrow-right-line" />
        </button>
      </div>
      <div v-else></div>
    </template>

    <template #custom-content>
      <ExtendedAnnotation
        v-if="activeAnn"
        editable
        :annotation="activeAnn"
        :max-note-length="42"
      />
      <v-btn
        v-if="isOpenFromExtras"
        class="view-note"
        text
        color="primary"
        @click="changeCurrentNoteHandler"
      >
        {{ $t('AnnInfoPopup.button.view.label') }}
      </v-btn>
    </template>
  </Popup>
</template>

<script>
import Locator from '@shared/publication/locator.mjs';
import Popup from '@/components/base/Popup/Popup.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import ExtendedAnnotation from '@/components/views/AnnotationSidebar/ExtendedAnnotation/ExtendedAnnotation';
import materialsContextMenuMixin from '@/components/mixins/materialsContextMenuMixin';

import AnnotationInfoPopupEvents from '@/enums/AnnotationInfoPopupEvents';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import VueErrorHelper from '@/services/utils/VueErrorHelper';
import LoggerFactory from '@/services/utils/LoggerFactory';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';

const logger = LoggerFactory.getLogger('AnnInfoPopup.vue');

const INFO_POPUP_CLASS = 'ann-info-popup';

export default {
  name: PopupNamesEnum.ANNOTATION_INFO_POPUP,
  components: { ExtendedAnnotation, Popup, BaseSpriteIcon },
  mixins: [materialsContextMenuMixin],
  data() {
    return {
      activeAnnIndex: 0,
      infoPopup: null,
      infoPopupClass: INFO_POPUP_CLASS,
      isOpenFromExtras: false
    };
  },
  computed: {
    popupWidth() {
      return this.narrow
        ? AppConstantsUtil.ANN_INFO_POPUP_NARROW_WIDTH
        : AppConstantsUtil.ANN_INFO_POPUP_BASE_WIDTH;
    },
    openParaId() {
      return this.$store.getters['OpenParameterStore/getParaId'];
    },
    narrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.ANNOTATION_INFO_POPUP
      );
    },
    annotations() {
      return this.getAnnotationViewsByBlockId.filter(annotation => {
        return this.popupContext.annotationIds.includes(annotation.id);
      });
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    getAnnotationViewsByBlockId() {
      return this.$store.getters[
        'AnnotationsStore/getAnnotationViewsByBlockId'
      ]({
        bookId: this.bookId,
        blockId: this.popupContext.blockId,
        paraId: this.popupContext.paraId
      });
    },
    isMultipleAnnotations() {
      return this.annotations.length && this.annotations.length > 1;
    },
    activeAnn() {
      if (
        !this.annotations.length ||
        this.annotations[this.activeAnnIndex].isDeleted
      ) {
        return null;
      }
      return this.annotations[this.activeAnnIndex];
    },
    isFirstAnn() {
      return this.activeAnnIndex === 0;
    },
    isLastAnn() {
      return this.activeAnnIndex === this.annotations.length - 1;
    },
    windowHalfHight() {
      const toolbarHeight = SelectionConstantUtils.TOOLBAR_HEIGHT;
      const progressToolbarHeight =
        SelectionConstantUtils.PROGRESS_TOOLBAR_HEIGHT;
      const windowHalfHight =
        window.innerHeight / 2 - toolbarHeight - progressToolbarHeight;
      return -Math.round(windowHalfHight);
    }
  },
  watch: {
    annotations(anns) {
      if (!anns.length) {
        this.close();
        return;
      }
      this.setActiveIndex();
    }
  },
  async mounted() {
    this.isOpenFromExtras = this.popupContext?.openFromExtras;
    this.$_addVuetifyDisableScrollClass();
    await this.$nextTick();
    this.$_setCorrectTopPosition();
  },
  destroyed() {
    if (!this.isInfoPopupOpened()) {
      return;
    }
    this.close();
  },
  errorCaptured(error, vm) {
    const trace = VueErrorHelper.generateComponentTrace(vm);
    logger.error(`Error in Annotation info popup: ${error}\n${trace}`);
    this.$emit('annotationInfoPopupEvent', {
      type: AnnotationInfoPopupEvents.ERROR,
      data: { error }
    });
  },
  methods: {
    isInfoPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.ANNOTATION_INFO_POPUP
      );
    },
    firstAttachableElement() {
      const firstAttachableElementClass = this.popupContext
        ?.firstAttachableElementClass;

      if (!firstAttachableElementClass) {
        return null;
      }
      return document.querySelector(`.${firstAttachableElementClass}`);
    },
    secondAttachableElement() {
      const secondAttachableElementClass = this.popupContext
        ?.secondAttachableElementClass;

      if (!secondAttachableElementClass) {
        return null;
      }
      return document.querySelector(`.${secondAttachableElementClass}`);
    },
    changeCurrentNoteHandler() {
      const locator = this.annotations[0].start;
      const isInsideReadingArea = this.$store.getters[
        'ProgressStore/isInsideReadingArea'
      ](locator);

      if (!isInsideReadingArea) {
        this.$_changePublication();
      }

      if (this.narrow) {
        this.close();
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.EXTRAS_POPUP
        });
      }
    },
    openSamePara(openLocator) {
      return this.openParaId === openLocator;
    },
    $_changePublication() {
      const annotation = this.annotations?.length ? this.annotations[0] : null;
      if (!annotation) {
        return;
      }
      if (
        !(annotation.start instanceof Locator.InTextLocator) ||
        !(annotation.end instanceof Locator.InTextLocator)
      ) {
        return;
      }

      try {
        const openLocator = new Locator.InTextRangeLocator(
          annotation.start,
          annotation.end
        ).toJSON();
        const openSamePara = this.openSamePara(openLocator);
        PublicationNavigateLogicService.openPublication(
          this.$store,
          this.$router,
          {
            paragraphId: openLocator,
            openSamePara,
            openOffset: this.windowHalfHight
          }
        );
      } catch (err) {
        logger.error(
          `Get error while navigate in book (${this.bookId}): ${this.popupContext.paraId}, error: ${err}`
        );
        if (this.popupContext?.paraId) {
          PublicationNavigateLogicService.openPublication(
            this.$store,
            this.$router,
            {
              paragraphId: this.popupContext.paraId
            }
          );
        }
      }
    },
    reduceActiveAnnIndex() {
      this.activeAnnIndex = this.isFirstAnn ? 0 : this.activeAnnIndex - 1;
    },
    setActiveIndex() {
      if (!this.isMultipleAnnotations) {
        this.activeAnnIndex = 0;
        return;
      }
      this.reduceActiveAnnIndex();
    },
    isClickedsOnPreventClosing(event) {
      return event?.target?.closest(`.${INFO_POPUP_CLASS}`);
    },
    closeAnnInfoPopup() {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.ANNOTATION_INFO_POPUP
      });
    },
    close(e) {
      if (e && this.isClickedsOnPreventClosing(e)) {
        return;
      }

      if (!this.isOpenFromExtras) {
        const firstEl = this.firstAttachableElement();
        const lastEl = this.secondAttachableElement();
        if (!firstEl || !lastEl) {
          this.closeAnnInfoPopup();
          return;
        }
        firstEl?.classList.remove(
          `${this.popupContext?.firstAttachableElementClass}`
        );
        lastEl?.classList.remove(
          `${this.popupContext?.secondAttachableElementClass}`
        );
      }

      this.$_removeVuetifyDisableScrollClass();
      this.closeAnnInfoPopup();
    },
    $_setCorrectTopPosition() {
      const firstEl = this.firstAttachableElement();
      const lastEl = this.secondAttachableElement();
      if (!firstEl || !lastEl) {
        logger.error(
          `No first or last attachable elements: firstEl(${firstEl}), lastEl(${lastEl})`
        );
        return;
      }

      const firstAttachableElementRect = firstEl.getBoundingClientRect();
      const firstAttachableElementTop = firstAttachableElementRect.top;
      const secondAttachableElementRect = lastEl.getBoundingClientRect();
      const secondAttachableElementBottom = secondAttachableElementRect.bottom;

      const infoPopup = this.$_getInfoPopup();
      const infoPopupStyles = window.getComputedStyle(infoPopup, null);
      const popupMarginTop =
        infoPopupStyles.getPropertyValue('margin-top') || 0;
      const popupMarginTopNum = parseFloat(popupMarginTop);
      const popupMarginBottom =
        infoPopupStyles.getPropertyValue('margin-bottom') || 0;
      const popupMarginBottomNum = parseFloat(popupMarginBottom);
      const popupMarginY = popupMarginTopNum + popupMarginBottomNum;
      const popupHeight = infoPopup?.offsetHeight + popupMarginY;
      const windowHeight = window.innerHeight;

      const bottomAvailableSpace =
        windowHeight -
        secondAttachableElementBottom -
        SelectionConstantUtils.PROGRESS_TOOLBAR_HEIGHT;
      const topAvailableSpace =
        firstAttachableElementTop - SelectionConstantUtils.TOOLBAR_HEIGHT;

      if (bottomAvailableSpace > popupHeight) {
        infoPopup.style['top'] = `${secondAttachableElementBottom -
          popupMarginTopNum}px`;
      } else if (topAvailableSpace > popupHeight) {
        infoPopup.style['bottom'] = `${windowHeight -
          firstAttachableElementTop -
          popupMarginBottomNum}px`;
      }

      if (this.isOpenFromExtras && !this.narrow) {
        const infoPopupMarginLeft = infoPopupStyles.getPropertyValue(
          'margin-left'
        );
        if (!infoPopupMarginLeft) {
          return;
        }
        infoPopup.style['left'] = infoPopupMarginLeft;
      }

      if (firstEl?.closest('.annotation-sidebar')) {
        let position = 'left';
        let infoPopupXPosition = firstAttachableElementRect.x;

        const popupMarginRight =
          infoPopupStyles.getPropertyValue('margin-right') || 0;
        const popupMarginRightNum = parseFloat(popupMarginRight);
        const popupMarginLeft =
          infoPopupStyles.getPropertyValue('margin-left') || 0;
        const popupMarginLeftNum = parseFloat(popupMarginLeft);
        const popupMarginX = popupMarginRightNum + popupMarginLeftNum;
        const popupWidth = infoPopup.offsetWidth + popupMarginX;

        const isInfoPopupAbroad =
          popupWidth + infoPopupXPosition > window.innerWidth;
        if (isInfoPopupAbroad) {
          infoPopupXPosition = 0;
          position = 'right';
        }

        infoPopup.style[position] = `${infoPopupXPosition}px`;
      }
    },
    $_getInfoPopup() {
      if (!this.infoPopup) {
        this.infoPopup = document.querySelector(`.${this.infoPopupClass}`);
      }
      return this.infoPopup;
    },
    getScrollableParent() {
      return this.isOpenFromExtras ? '.notes-wrapper' : 'html';
    },
    $_addVuetifyDisableScrollClass() {
      const scrollableParent = this.getScrollableParent();
      document
        .querySelector(scrollableParent)
        ?.classList.add(AppConstantsUtil.VUETIFY_DISABLE_SCROLL_CLASS);
    },
    $_removeVuetifyDisableScrollClass() {
      const scrollableParent = this.getScrollableParent();
      document
        .querySelector(scrollableParent)
        ?.classList.remove(AppConstantsUtil.VUETIFY_DISABLE_SCROLL_CLASS);
    }
  }
};
</script>

<style lang="less" src="./AnnInfoPopup.less" />
