<template>
  <Popup
    content-class="bookmark-popup"
    scrollable
    :header-title="$t('BookmarkPopup.header')"
    :header-divider="true"
    :footer-divider="false"
    height="520"
    :content-image="emptyStateImage"
    :content-title="emptyStateContentTitle"
    :content-message="emptyStateContentMessage"
    @popupEvent="closeBookmarkPopup"
  >
    <template #custom-content>
      <v-card-text>
        <BookmarksList
          :popup-context="popupContext"
          @closeHandler="closeBookmarkPopup()"
        />
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup.vue';
import BookmarksList from '@/components/views/Extras/BookmarksListTab/BookmarksListTab';

import images from '@/assets/images';

export default {
  name: PopupNamesEnum.BOOKMARK_POPUP,
  components: { Popup, BookmarksList },
  props: {
    popupContext: Object
  },
  computed: {
    emptyStateImage() {
      return images.picBookmark;
    },
    emptyStateContentTitle() {
      return this.$t('BookmarkPopup.empty.header');
    },
    emptyStateContentMessage() {
      return this.$t('BookmarkPopup.empty.content');
    }
  },
  methods: {
    closeBookmarkPopup() {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.BOOKMARK_POPUP
      });
    }
  }
};
</script>

<style src="./BookmarkPopup.less" lang="less"></style>
