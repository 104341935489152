<template>
  <span>
    <Popup
      :content-class="contentClass"
      hide-overlay
      transition="slide-y-transition"
      scrollable
      width="360"
      @popupEvent="eventHandler"
    >
      <template #custom-header>
        <div></div>
      </template>

      <template #custom-content>
        <v-card-text class="pt-5 pb-0">
          <component
            :is="componentName"
            class="menu-sections-wrapper"
            :display-context="displayContext"
          />
        </v-card-text>
      </template>

      <template v-if="!isEditorApp" #custom-footer>
        <BaseButton
          :disabled="!isOnline"
          color="#64748B"
          class="language-button"
          text
          x-large
          @click="openLanguagePopup"
        >
          <BaseSpriteIcon custom-class="me-1" icon-name="ico-global-line" />
          <span v-text="getCurrentLanguage"></span>
        </BaseButton>
      </template>
    </Popup>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AppMenu.vue');
import VueErrorHelper from '@/services/utils/VueErrorHelper';

import AppModeEnum from '@/enums/AppModeEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';

import Popup from '@/components/base/Popup/Popup.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

import AppReaderMenu from '@/components/views/AppMenu/AppReaderMenu/AppReaderMenu';
import AppEditorMenu from '@/components/views/AppMenu/AppEditorMenu/AppEditorMenu';
import AppAdminMenu from '@/components/views/AppMenu/AppAdminMenu/AppAdminMenu';
import LanguagePopup from '@/components/views/LanguagePopup/LanguagePopup.vue';

const popupName = PopupNamesEnum.APP_MENU_WRAPPER;

export default {
  name: popupName,
  components: {
    AppReaderMenu,
    AppEditorMenu,
    AppAdminMenu,
    Popup,
    LanguagePopup,
    BaseButton,
    BaseSpriteIcon
  },

  data() {
    const supportedLangs = this.$store.getters[
      'ContextStore/supportedLanguagesByName'
    ];
    return {
      componentName: null,
      supportedLangs
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isEditorApp']),
    ...mapGetters('UserStore', ['getUser', 'getUserPhoto']),
    mediaSize() {
      return this.$store.getters['MediaDetectorStore/mediaSize'];
    },
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    contentClass() {
      const isNarrow = this.mediaSize.narrow;
      const maskShadow =
        isNarrow && this.$route.name === AppStateEnum.SEARCH
          ? 'mask-shadow'
          : '';
      const menuMode = isNarrow ? '' : 'menu-mod';
      const contentClass = `app-menu-wrapper ${maskShadow} ${menuMode}`;
      return contentClass;
    },
    isServicesReady() {
      return this.$store.getters['ContextStore/isServicesReady'];
    },
    userPicture() {
      let fileHash = this.getUser?.photo?.fileHash;
      if (this.isOnline && this.isServicesReady && fileHash) {
        return this.getUserPhoto(fileHash);
      }
      return null;
    },
    displayContext() {
      const isGuestUser = this.$store.getters['UserStore/isGuestUser'];
      const brand = this.$store.getters['ContextStore/brand'];
      const appMode = this.$store.getters['ContextStore/appModeGetter'];
      const appState = this.$store.getters['ContextStore/appState'];
      const isAdmin = this.$store.getters['UserStore/isUserAdmin'];
      const isEditor = this.$store.getters['UserStore/isUserEditor'];
      const isLibrarySetSupported = this.$store.getters[
        'ContextStore/isLibrarySetSupported'
      ];
      const clientNodeContext = this.$store.getters[
        'ContextStore/clientNodeContextGetter'
      ];
      const isCampaignLinksEnabled = this.$store.getters[
        'ContextStore/getIsCampaignLinksEnabled'
      ];
      const isAffiliate = this.$store.getters['UserStore/isUserAffiliate'];
      const isSalesPerson = this.$store.getters['UserStore/isUserSalesPerson'];
      const isElectron = this.$store.getters['ContextStore/isElectron'];
      const isDevice = this.$store.getters['ContextStore/isDevice'];

      const blogIcoClassName = 'ico-blogger-outline';
      const blogDescription = this.$store.getters[
        'ExternalBlogStore/getAppMenuBlogDescription'
      ];

      const isPurchaseEnabled = this.$store.getters[
        'ContextStore/isPurchaseEnabled'
      ];
      const currentRouteName = this.$route.name;

      return {
        currentRouteName,
        mediaSize: this.mediaSize,
        isGuestUser,
        isOnline: this.isOnline,
        isPurchaseEnabled,
        userPicture: this.userPicture,
        userDescription: this.getUser?.email,
        userName: this.getUser?.name,
        isEditorApp: this.isEditorApp,
        brand,
        appMode,
        appState,
        isAdmin,
        isEditor,
        isLibrarySetSupported,
        isNative: clientNodeContext.native,
        isCampaignLinksEnabled,
        isAffiliate,
        isSalesPerson,
        isElectron,
        isDevice,
        blogIcoClassName,
        blogDescription
      };
    },
    getCurrentLanguage() {
      const currentLanguage = this.supportedLangs.find(
        lang =>
          lang.name === this.$store.getters['ContextStore/getSystemLanguage']
      );

      return currentLanguage.title || 'Language';
    }
  },
  watch: {
    $route() {
      this.close();
    }
  },
  mounted() {
    this.componentName = this.getComponentName();
  },
  errorCaptured(error, vm) {
    const trace = VueErrorHelper.generateComponentTrace(vm);
    logger.fatal(`Error occurred in AppMenu: ${error}\n${trace}`);
    const text = this.$t('AppMenu.error');
    this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
  },

  methods: {
    getComponentName() {
      const currentMode = this.$store.getters['ContextStore/appModeGetter'];
      switch (currentMode) {
        case AppModeEnum.READER:
          return AppReaderMenu.name;
        case AppModeEnum.EDITOR:
          return AppEditorMenu.name;
        case AppModeEnum.ADMIN:
          return AppAdminMenu.name;
        default:
          break;
      }
    },
    eventHandler() {
      this.close();
    },
    openLanguagePopup() {
      this.close();
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.LANGUAGE_POPUP,
        popupContext: {}
      });
    },
    close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: popupName }
      });
    }
  }
};
</script>

<style lang="less" src="./AppMenuWrapper.less"></style>
