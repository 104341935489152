'use strict';
// var unidecode = require('unidecode');
let unidecode;
import('../unidecode.mjs').then(module => (unidecode = module.default));

function removeDiacritic(word) {
  return unidecode(word)
    .replace(/[\d+'"”“[\](),.:;!?]+/g, '')
    .replace(/\s{2,}/g, '');
}

function createPrefixTermRe(dictWord) {
  return new RegExp('^' + dictWord, 'i');
}

function createExactTermRe(dictWord) {
  return new RegExp('^' + dictWord + '$', 'i');
}

function getTermFromWordIndex(wordIndexObj) {
  return Object.keys(wordIndexObj)[0];
}

function getOffsetFromWordIndex(wordIndexObj) {
  var term = getTermFromWordIndex(wordIndexObj);
  return wordIndexObj[term];
}

function getTermWithoutDiacritic(wordIndexObj) {
  return removeDiacritic(getTermFromWordIndex(wordIndexObj));
}

function binarySearch(word, dictIndex, searchTermRe, getIndexedWordFn) {
  var startIndex = 0;
  var endIndex = dictIndex.length - 1;
  var currentIndex;
  var indexedWord;
  while (startIndex <= endIndex) {
    currentIndex = Math.floor((startIndex + endIndex) / 2);
    indexedWord = getIndexedWordFn(dictIndex[currentIndex]);

    if (searchTermRe.test(indexedWord)) {
      return currentIndex;
    } else if (word < indexedWord) {
      endIndex = currentIndex - 1;
    } else {
      startIndex = currentIndex + 1;
    }
  }
  return -1;
}

function addWordsBefore(dictIndex, wordIndex, searchTermRe, words) {
  var indexedWord;
  var termWithoutDiacritic;
  var i = wordIndex;
  while (i >= 0) {
    indexedWord = getTermFromWordIndex(dictIndex[i]);
    termWithoutDiacritic = removeDiacritic(indexedWord);

    if (searchTermRe.test(termWithoutDiacritic)) {
      words.unshift(dictIndex[i]);
    } else {
      break;
    }
    i--;
  }
}

function addWordsAfter(dictIndex, wordIndex, searchTermRe, words) {
  var indexedWord;
  var termWithoutDiacritic;
  var i = wordIndex + 1;
  var dictIndexLen = dictIndex.length;
  while (i <= dictIndexLen - 1) {
    indexedWord = getTermFromWordIndex(dictIndex[i]);
    termWithoutDiacritic = removeDiacritic(indexedWord);

    if (searchTermRe.test(termWithoutDiacritic)) {
      words.push(dictIndex[i]);
    } else {
      break;
    }
    i++;
  }
}

function findAllWordsFromDictIndex(searchTermRe, dictIndex, wordIndex) {
  var words = [];

  addWordsBefore(dictIndex, wordIndex, searchTermRe, words);
  addWordsAfter(dictIndex, wordIndex, searchTermRe, words);
  return words;
}

function findWordsIndexByPrefix(searchWord, dictIndex) {
  var words = [];
  var dictWord = removeDiacritic(searchWord);
  var prefixTermRe = createPrefixTermRe(dictWord);

  var wordIndex = binarySearch(
    dictWord,
    dictIndex,
    prefixTermRe,
    getTermWithoutDiacritic
  );
  if (wordIndex === -1) {
    return words;
  }
  words = findAllWordsFromDictIndex(prefixTermRe, dictIndex, wordIndex);
  return words;
}

function findWordsIndexExactMatch(searchWord, dictIndex) {
  var words = [];
  var dictWord = removeDiacritic(searchWord);
  var exactTermRe = createExactTermRe(dictWord);

  var wordIndex = binarySearch(
    dictWord,
    dictIndex,
    exactTermRe,
    getTermWithoutDiacritic
  );
  if (wordIndex === -1) {
    return words;
  }
  words = findAllWordsFromDictIndex(exactTermRe, dictIndex, wordIndex);
  return words;
}

module.exports = {
  removeDiacritic: removeDiacritic,
  getTermFromWordIndex: getTermFromWordIndex,
  getOffsetFromWordIndex: getOffsetFromWordIndex,
  findWordsIndexByPrefix: findWordsIndexByPrefix,
  findWordsIndexExactMatch: findWordsIndexExactMatch
};
