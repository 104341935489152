import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AboutFooter.vue');
import OSTypesEnum from '@/enums/OSTypesEnum';

export default {
  data() {
    return {
      remoteArtifactUrlDetails: null
    };
  },
  computed: {
    osArtifactUrlDetails() {
      const urlDetails = process.client
        ? this.artifactUrlDetails[this.OS.toLowerCase()]
        : {};

      if (!urlDetails) {
        logger.error(`No artifact url details provided for ${this.OS} OS`);
        return {};
      }
      return urlDetails;
    },
    artifactUrlDetails() {
      const brand = this.$store.getters['ContextStore/brand'];
      const isProd = this.$store.getters['ContextStore/isProd'];
      const artifactUrlConfig = this.$store.getters[
        'ContextStore/getArtifactUrlConfig'
      ];
      const artifacts = artifactUrlConfig
        ? artifactUrlConfig[brand].artifacts || {}
        : {};
      return {
        [OSTypesEnum.LINUX]: {
          iconName: 'ico-portal-lin',
          url: artifacts['electron_linux'],
          visibility: true
        },
        [OSTypesEnum.ANDROID]: {
          iconName: 'ico-portal-and',
          url: isProd
            ? this.$store.getters['ContextStore/getAppStoreLinks'].android
            : artifacts.android,
          visibility: true
        },
        [OSTypesEnum.OSX]: {
          iconName: 'ico-portal-ios',
          url: artifacts['electron_mac'],
          visibility: true
        },
        [OSTypesEnum.IOS]: {
          iconName: 'ico-portal-ios',
          url: isProd
            ? this.$store.getters['ContextStore/getAppStoreLinks'].ios
            : artifacts.ios,
          visibility: false
        },
        [OSTypesEnum.WINDOWS]: {
          iconName: 'ico-portal-win',
          url: artifacts['electron_win'],
          visibility: false
        }
      };
    }
  },
  mounted() {
    if (!this.$store.getters['ContextStore/getArtifactUrlConfig']) {
      this.$store.dispatch('ContextStore/loadArtifactUrlConfig');
    }
  }
};
