<template>
  <div>
    <SectionsMenu
      :menu-sections="filteredItems"
      @sectionsMenuEvent="sectionsMenuEventHandler"
    />
  </div>
</template>

<script>
import AppMenuItemsEnum from '@/enums/AppMenuItemsEnum';
import SectionsMenu from '@/components/views/AppMenu/SectionsMenu/SectionsMenu';
import MenuItemBuilderService from '@/services/MenuItemBuilderService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import SectionsMenuEventEnum from '@/enums/SectionsMenuEventEnum';

const menuSectionsSchema = [
  // <!-- hide till full implementation according to task FV-763 -->
  // AppMenuItemsEnum.CREATE_NEW_COURSE_SYLLABUS,
  AppMenuItemsEnum.CREATE_NEW_LIBRARY_SET,
  AppMenuItemsEnum.PROFILE,
  AppMenuItemsEnum.LIBRARY_SET,
  AppMenuItemsEnum.MANAGE_USERS,
  AppMenuItemsEnum.BOOKS_LIST,
  AppMenuItemsEnum.GOOGLE_ANALYTICS,
  AppMenuItemsEnum.AFFILIATES,
  AppMenuItemsEnum.AFFILIATE_DASHBOARD,
  AppMenuItemsEnum.MY_CAMPAIGNS,
  AppMenuItemsEnum.TESTS,
  AppMenuItemsEnum.LOGOUT,
  AppMenuItemsEnum.LOGIN
];

export default {
  name: 'AppEditorMenu',
  components: {
    SectionsMenu
  },
  props: {
    displayContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    filteredItems() {
      const allMenuItems = MenuItemBuilderService.createMenuSections(
        menuSectionsSchema,
        this.displayContext
      );
      const filteredItems = allMenuItems.reduce((sections, menuItem) => {
        if (menuItem.isVisible) {
          sections.push(menuItem);
        }
        return sections;
      }, []);
      return filteredItems;
    }
  },
  methods: {
    selectMenuItem(menuItem) {
      menuItem.handler();
    },
    sectionsMenuEventHandler(event) {
      switch (event.type) {
        case SectionsMenuEventEnum.ITEM_SELECTED:
          this.$store.dispatch('ManagePopupStore/closePopup', {
            name: PopupNamesEnum.APP_MENU_WRAPPER
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import 'AppEditorMenu.less';
</style>
