import AppUrlConfig from './utils/AppUrlConfig';
// var parse = require('url-parse')
const urlLib = require('url');

function _baseDir(url) {
  var base = url.resolve(url.protocol, url.host);
  var dir = url.path;
  var baseDir = base + dir;
  if (baseDir && baseDir.charAt(baseDir.length - 1) !== '/') {
    baseDir += '/';
  }
  return baseDir;
}

function _withoutFragment(source) {
  // http://tools.ietf.org/html/rfc3986#page-24
  // A fragment identifier component is indicated by the presence of a
  // number sign ("#") character and terminated by the end of the URI.

  var s = source || '';
  var i = s.indexOf('#');
  return i !== -1 ? s.substring(0, i) : s;
}

function _parse(source) {
  var Url = urlLib.parse(source);
  return {
    modeNative: !!window.AppUrl,
    modeWeb: !window.AppUrl,

    source: urlLib.resolve(urlLib.resolve(Url.protocol, Url.host), Url.path),
    base: urlLib.resolve(Url.protocol, Url.host),
    protocol: Url.protocol,
    authority: Url.host,
    userInfo: Url.userInfo,
    user: Url.user,
    password: Url.password,
    host: Url.host,
    port: Url.port,
    relative: Url.path,
    path: Url.pathname,
    directory: Url.path,
    file: Url.file,
    query: Url.query,
    fragment: Url.hash,
    params: urlLib.parse(Url.query),
    baseDir: _baseDir(Url),
    withoutFragment: _withoutFragment(source)
  };
}

function getUrl() {
  ////////////////////////////////////////////////////////////////////////////

  // If application is run in native container (phonegap, for example)
  // when url cannot be specified via browser address bar
  // then, by convention, the global 'swAppUrl' should be defined

  var source = window.AppUrl || window.location.href;
  // var source = 'https://current.build.ocean.isddesign.com/develop-ffa/reader/?web#state=google&access_token=ya29.Gly3Bghqlds75LEVFYNCQ8ZLmTc4YVG0b00AWRR5egD1Af0rb3_RZFi2knPpbdiYeVrvv4IwmhHkf7uY3PgVvdAYfEN6zCpjTyj86KIvtvYHvDa8_r-LqpOhIYynUA&token_type=Bearer&expires_in=3600&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email'
  // let "swAppUrlConfig" to pre|post-process url parsing
  // see "swAppUrlConfigDefault"
  // see "swAppUrlConfig" mapping in main.js
  return AppUrlConfig.parse(source, _parse);
}

export default {
  get: getUrl
};
