import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('OpenPublicationService.js');

import AppStateEnum from '@/enums/AppStateEnum';
import PublicationStatusEnum from '@/enums/PublicationStatusEnum';

const context = {
  appMode: null,
  router: null
};

function init(Context, router) {
  context.mode = Context.mode;
  context.router = router;
}

function openPublication(openParams, router) {
  let _router = context.router || router;
  _router.push({
    name: AppStateEnum.PRESENT_PUBLICATION,
    params: { ...openParams }
  });
}

function openCollection(openParams, router) {
  let _router = context.router || router;
  _router.push({
    name: AppStateEnum.COLLECTION,
    params: { id: openParams.collectionId }
  });
}

function openSyllabus(syllabusId) {
  context.router.push({
    name: AppStateEnum.DEVELOP_STUDYCOURSE,
    params: {
      syllabusId
    }
  });
}

function openLibrarySetById(setId) {
  context.router.push({
    name: AppStateEnum.DEVELOP_LIBRARY_SET,
    params: {
      setId
    }
  });
}

async function openEditLibrarySetById(setId, store) {
  let librarySet = store.getters['LibraryStore/getPublicationById'](setId);
  const hasCopy = librarySet.copyId;
  if (hasCopy) {
    librarySet = store.getters['LibraryStore/getPublicationById'](
      librarySet.copyId
    );
  }

  const isCopySet =
    librarySet.publicationStatus === PublicationStatusEnum.DRAFT ||
    !librarySet.isOriginal;
  const isOriginSetWithoutCopy = librarySet.isOriginal && !librarySet.copyId;

  if (isCopySet) {
    openLibrarySetById(librarySet.id);
  } else if (isOriginSetWithoutCopy) {
    const copyId = await store.dispatch('LibraryStore/createCopy', {
      originalSetId: librarySet.id
    });
    openLibrarySetById(copyId);
  } else {
    logger.error(
      `Get unsupported case for open library set setId: ${setId} status: ${librarySet.publicationStatus}, isOriginal: ${librarySet.isOriginal} copyId: ${librarySet.copyId}`
    );
  }
}

async function resumeReading(store) {
  const recentBookItem = store.getters['RecentBookStore/getLastRecentBookItem'];
  const openParams = await store.dispatch(
    'OpenParameterStore/buildOpenParameters',
    {
      publicationId: recentBookItem.getId()
    }
  );
  openPublication(openParams);
}

export default {
  init,
  openPublication,
  openCollection,
  resumeReading,
  openSyllabus,
  openLibrarySetById,
  openEditLibrarySetById
};
