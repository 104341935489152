import DB from '@/services/Agent/dao/DB';

/**
 *
 * @param {String} publicationId
 */
async function get(publicationId) {
  const docId = _getDocId(publicationId);
  return DB.userRW().get(docId);
}

async function getAll() {
  return DB.userRW().getAllByPrefix(DB.prefix.bookmarks);
}

/**
 *
 * @param {String} publicationId
 * @param {String} [courseId]
 */
function getDefaultDoc(publicationId) {
  return {
    _id: _getDocId(publicationId),
    type: 'bookmarks',
    bookmarks: [],
    createdAt: Date.now()
  };
}

/**
 *
 * @param {String} publicationId
 * @param {String} [courseId]
 */
function _getDocId(publicationId) {
  return DB.id.bookmarks(`${publicationId}`);
}

/**
 *
 * @param {Object} bookmarks
 */
function write(bookmarks) {
  return DB.userRW().put(bookmarks);
}

export default {
  get,
  getAll,
  getDefaultDoc,
  write
};
