const DurationEnum = {
  FOREVER: 'forever',
  ONCE: 'once',
  SIX_MONTHS: '6 months',
  TWELVE_MONTHS: '12 months'
};

const StripeDurationEnum = {
  REPEATING: 'repeating',
  ONCE: 'once',
  FOREVER: 'forever'
};

const DurationMonthsEnum = {
  [DurationEnum.SIX_MONTHS]: 6,
  [DurationEnum.TWELVE_MONTHS]: 12
};

const DiscountEnum = {
  15: [DurationEnum.FOREVER],
  30: [
    DurationEnum.FOREVER,
    DurationEnum.ONCE,
    DurationEnum.SIX_MONTHS,
    DurationEnum.TWELVE_MONTHS
  ],
  50: [
    DurationEnum.FOREVER,
    DurationEnum.ONCE,
    DurationEnum.SIX_MONTHS,
    DurationEnum.TWELVE_MONTHS
  ],
  75: [
    DurationEnum.FOREVER,
    DurationEnum.ONCE,
    DurationEnum.SIX_MONTHS,
    DurationEnum.TWELVE_MONTHS
  ],
  100: [
    DurationEnum.FOREVER,
    DurationEnum.ONCE,
    DurationEnum.SIX_MONTHS,
    DurationEnum.TWELVE_MONTHS
  ]
};

module.exports = {
  DiscountEnum,
  DurationEnum,
  StripeDurationEnum,
  DurationMonthsEnum
};
