<template>
  <div class="select-wrapper">
    <div v-if="modifiedAttributes.labelName" class="select-title">
      <label :for="modifiedAttributes.labelName"
        ><span>{{ modifiedAttributes.label }}</span></label
      >
    </div>
    <v-select
      v-bind="computedAttributes"
      :name="modifiedAttributes.labelName"
      v-on="inputListeners"
    >
      <!-- Dynamically inherit slots from parent -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" :props="props" />
      </template>
    </v-select>
  </div>
</template>
<script>
import { ModifiedAttributes } from '@/classes/factories/BaseComponentsFactory';
export default {
  name: 'BaseSelect',
  props: {
    props: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      defaultAttributes: {
        outlined: true,
        dense: true
      },
      modifiedAttributes: new ModifiedAttributes()
    };
  },
  computed: {
    computedAttributes() {
      const incomingAttrs = this.$attrs;
      if (incomingAttrs?.label) {
        this.modifiedAttributes.setLabel(incomingAttrs.label);
        this.modifiedAttributes.setLabelName(incomingAttrs.label);
        delete incomingAttrs.label;
      }
      return {
        ...this.defaultAttributes,
        ...incomingAttrs
      };
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: value => {
          this.$emit('input', value);
        },
        change: value => {
          this.$emit('change', value);
        }
      });
    }
  }
};
</script>
<style lang="less" src="./BaseSelect.less"></style>
