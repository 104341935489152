<template>
  <div class="burger-control-wrapper" @click="openBurgerMenu">
    <BaseButton class="ms-2" elevation="0" fab small color="primary">
      <BaseSpriteIcon
        v-if="isOpenMenu"
        :icon-name="'ico-close'"
        custom-class="list-icon"
      />
      <BaseSpriteIcon v-else :icon-name="'ico-list'" custom-class="list-icon" />
    </BaseButton>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
const popupName = PopupNamesEnum.BURGER_POPUP;

export default {
  name: 'BurgerControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  computed: {
    isOpenMenu() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](popupName);
    }
  },

  methods: {
    openBurgerMenu() {
      if (!this.isOpenMenu) {
        this.$store.dispatch('ManagePopupStore/openPopup', {
          name: popupName,
          popupContext: {}
        });
      } else {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: popupName,
          popupContext: {}
        });
      }
    }
  }
};
</script>

<style lang="less" src="./BurgerControl.less"></style>
