import UserNotesController from '@/services/Agent/dao/UserNotesController';
import CategoriesController from '@/services/Agent/dao/CategoriesController';

export default {
  POST: { update },
  GET: {}
};

function update(req) {
  const bookId = req.bookId;
  const classId = req.classId;
  const annotations = req.materials.annotations;
  if (annotations) {
    return UserNotesController.set(bookId, classId, annotations);
  }
  const categories = req.materials.categories;
  if (categories) {
    const id = classId || bookId;
    return CategoriesController.set(categories, id);
  }
}
