<template>
  <Popup
    :content-class="'create-section-popup'"
    scrollable
    :header-title="$t('Compilation.popup.createSection.label')"
    :footer-divider="false"
    :primary-label="$t('Compilation.createSection.button.label')"
    :primary-footer-handler="createSection"
    width="575"
    @popupEvent="popupEventHandler"
  >
    <template #custom-content>
      <v-card-text>
        <div class="create-section-content content-default-width pt-4">
          <BaseTextField
            id="compilation-title"
            v-model.trim="title"
            :label="$t('Compilation.newSection.label')"
            :placeholder="$t('Compilation.newSection.placeholder')"
            :counter="MAX_COMPILATION_SECTION_NAME_LENGTH"
            :maxlength="MAX_COMPILATION_SECTION_NAME_LENGTH"
          ></BaseTextField>
        </div>
      </v-card-text>
    </template>
  </Popup>
</template>
<script>
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CreateSectionPopup.vue');

import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import Popup from '@/components/base/Popup/Popup';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import Utils from '@/services/utils/Utils';

export default {
  name: PopupNamesEnum.CREATE_SECTION_POPUP,
  components: { Popup, BaseTextField },
  props: {
    popupContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: '',
      MAX_COMPILATION_SECTION_NAME_LENGTH:
        AppConstantsUtil.MAX_COMPILATION_SECTION_NAME_LENGTH
    };
  },
  methods: {
    popupEventHandler(event) {
      switch (event.type) {
        case PopupEventEnum.CLOSE:
          this.$_close();
          break;
        default:
          break;
      }
    },
    async createSection() {
      if (!this.title) {
        logger.info(
          'Empty section name will not be applied to the compilation'
        );
        this.$_close();
        return;
      }

      try {
        await this.$store.dispatch('CompilationsStore/appendSection', {
          compilationId: this.popupContext.compilationId,
          selection: {
            type: CompilationSelectionTypes.SECTION,
            compilationId: this.popupContext.compilationId,
            title: this.title,
            clientSortId: Utils.shortUuid()
          }
        });
      } catch (err) {
        logger.fatal(`Error while appending section: ${err.stack}`);
        this.$_showErrorToaster();
      } finally {
        this.$_close();
      }
    },
    $_showErrorToaster() {
      const text = this.$t('Compilation.appendSection.errorMessage');
      this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
    },
    $_close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.CREATE_SECTION_POPUP }
      });
    }
  }
};
</script>

<style lang="less" src="./CreateSectionPopup.less"></style>
