import { localize as $t } from '@/i18n';
import AppSettingsService from '@/services/AppSettingsService';
import ClientNodeContext from '@/context/ClientNodeContext';

const ZoomSizeEnum = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large'
};

const options = {
  [ZoomSizeEnum.SMALL]: {
    label: $t('ApplicationZoomService.small'),
    value: 0.7
  },
  [ZoomSizeEnum.NORMAL]: {
    label: $t('ApplicationZoomService.normal'),
    value: 1
  },
  [ZoomSizeEnum.LARGE]: {
    label: $t('ApplicationZoomService.large'),
    value: [1.1, 1.5, 1.3]
  }
};

// initial state
const initState = () => ({
  options,
  zoomType: null,
  MIN_WIDTH: 320 * options.large.value[1]
});

/**
 *
 * @returns {boolean}
 */
function _isNarrowScreen(state) {
  return window.screen.width <= state.MIN_WIDTH;
}

function _isLenovo() {
  var str = window.navigator.userAgent;
  return str.indexOf('Lenovo') + 1;
}

// getters
const storeGetters = {
  getOptions(state) {
    return state.options;
  },
  getZoomType(state) {
    return state.zoomType;
  },
  getZoomFactor(state) {
    return state.zoomType === ZoomSizeEnum.LARGE
      ? state.options.large.value[
          _isNarrowScreen(state) ? 0 : _isLenovo() ? 2 : 1
        ]
      : state.options[state.zoomType].value;
  }
};

/** Apply zoom to viewport */

function _persistZoom(state) {
  AppSettingsService.setZoomType(state.zoomType);
}

// actions
const actions = {
  async init({ dispatch, commit }) {
    const zoomType = AppSettingsService.getZoomType() || ZoomSizeEnum.NORMAL;
    commit('setZoomType', zoomType);
    await dispatch('_applyZoom');
  },
  async changeZoomType({ commit, dispatch, state }, payload) {
    const { type, errorHandler } = payload;
    commit('setZoomType', type);
    await dispatch('_applyZoom', errorHandler);
    _persistZoom(state);
  },
  async onOrientationChange({ dispatch, getters }) {
    const zoomType = getters['getZoomType'];
    if (zoomType === ZoomSizeEnum.LARGE) {
      await dispatch('_applyZoom');
    }
  },
  async _applyZoom({ getters }, errorHandler) {
    return new Promise((resolve, reject) => {
      errorHandler = errorHandler || reject;
      try {
        let el = window.document.querySelector('meta[name="viewport"]');
        if (!el) {
          el = document.createElement('meta');
          el.setAttribute('name', 'viewport');
          document.head.appendChild(el);
        }
        const scale = getters['getZoomFactor'];
        const isDevice =
          ClientNodeContext.isDevice ||
          ClientNodeContext.isAndroidBrowser ||
          ClientNodeContext.isIosBrowser ||
          ClientNodeContext.isElectron;
        const maxScale = isDevice ? scale : 5;
        const scalable = isDevice ? 'no' : 'yes';
        const width = ClientNodeContext.isDevice ? '100%' : 'device-width';
        el.setAttribute(
          'content',
          `width=${width}, initial-scale=1.0, viewport-fit=cover, minimum-scale=${scale}, maximum-scale=${maxScale}, user-scalable=${scalable}, minimal-ui`
        );
        resolve();
      } catch (error) {
        errorHandler(error);
      }
    });
  }
};

// mutations
const mutations = {
  setZoomType(state, zoomType) {
    state.zoomType = zoomType;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
