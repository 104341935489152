import LoggerFactory from '@/services/utils/LoggerFactory';

const logger = LoggerFactory.getLogger('ExternalBlogStore.js');

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import ExternalBlogService from '@/services/ExternalBlogService';

import ExternalBlogTypesEnum from '@/enums/ExternalBlogTypesEnum';

import debounce from 'lodash/debounce';
import PostMessagesEnum from '@shared/enums/PostMessagesEnum';
import ClientNodeContext from '@/context/ClientNodeContext';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Utils from '@/services/utils/Utils';
import i18n from '@/i18n';
import ExternalBlogFactory from '@/classes/factories/ExternalBlogFactory';
import ExternalBlogPostFactory from '@/classes/factories/ExternalBlogPostFactory';

const BLOGGER = ExternalBlogTypesEnum.BLOGGER;

let authDialog;
let authDialogListener;

const initState = () => ({
  externalBlogsList: [],
  blogSettings: {},
  selectedBlog: {},
  cachedBlogPost: null
});

const storeGetters = {
  getExternalBlogsList(state) {
    return state.externalBlogsList;
  },
  getAppMenuBlogDescription(state) {
    if (!state.blogSettings.email) {
      return i18n.localize('Blogger.appMenu.description.default.label');
    }

    if (state.blogSettings.url) {
      return Utils.getParsedBlogUrl(state.blogSettings.url);
    }

    if (!state.externalBlogsList.length) {
      return i18n.localize('Blogger.appMenu.description.noBlogExists.label');
    }

    return i18n.localize('Blogger.appMenu.description.noBlogSelected.label');
  },
  getExternalBlogUrl(state) {
    return get(state, 'blogSettings.url', '');
  },
  getExternalBlogSettings(state) {
    return state.blogSettings;
  },
  getSelectedBlog(state) {
    return state.selectedBlog;
  },
  getCachedBlogPost(state) {
    return state.cachedBlogPost;
  },
  getBlogPostEditContext(state) {
    return state.cachedBlogPost
      ? cloneDeep(state.cachedBlogPost)
      : ExternalBlogPostFactory.createBlogPost();
  }
};

const actions = {
  initBlogSettings({ commit }, blogSettings) {
    commit('setExternalBlogSettings', blogSettings);
  },
  initBlogPost({ commit, getters, rootGetters }, context) {
    const { locator, bookId, linkToShare } = context;
    const cachedBlogPost = getters.getCachedBlogPost;
    const blogPost = cachedBlogPost ? { ...cachedBlogPost } : {};

    if (locator && bookId) {
      const quote = rootGetters['PublicationStore/getSelectedTextMeta'](
        locator,
        bookId
      );
      blogPost.quote = quote;

      const tags = [quote.publicationTitle];
      if (quote.publicationAuthor) {
        tags.push(quote.publicationAuthor);
      }
      blogPost.tags = tags;
    } else {
      blogPost.quote = linkToShare || '';
    }
    commit('setCachedBlogPost', blogPost);
  },
  async openBlogSettings({ dispatch }) {
    await dispatch('retrieveExternalBlogsList');

    return dispatch(
      'ManagePopupStore/openPopup',
      { name: PopupNamesEnum.EXTERNAL_BLOG_POPUP, popupContext: {} },
      {
        root: true
      }
    );
  },
  async retrieveExternalBlogsList({ commit, getters }) {
    const externalBlogsList = await ExternalBlogService.retrieveExternalBlogsList(
      BLOGGER
    );
    commit('setExternalBlogsList', externalBlogsList);

    const blogSettings = getters.getExternalBlogSettings;
    if (blogSettings.id) {
      const selectedBlog = externalBlogsList.find(
        item => item.id === blogSettings.id
      );
      commit('setSelectedBlogSettings', selectedBlog);
    }
  },
  saveSelectedBlogSettings({ getters, commit }) {
    const selectedBlog = getters.getSelectedBlog;
    const blogSettings = getters.getExternalBlogSettings;
    saveExternalBlogSettings({ ...blogSettings, ...selectedBlog });
    commit('setExternalBlogSettings', { ...blogSettings, ...selectedBlog });
  },
  changeAccount({ dispatch, rootGetters }) {
    const isDevice = rootGetters['ContextStore/isDevice'];

    addCloseLoginPopupListener(async () => {
      await dispatch('resetSettings');
      await dispatch('processSettingsAfterLogin');
      await dispatch('openBlogSettings');
    }, isDevice);

    const isSelectAccount = true;
    dispatch('openBloggerOAuthDialog', isSelectAccount);
  },
  createNewBlog({ rootGetters }) {
    const externalBlogsConfig =
      rootGetters['ContextStore/getExternalBlogsConfig'];
    const createBlogUri = externalBlogsConfig[BLOGGER].createBlogUri;
    ExternalBlogService.openExternalBlogCreatePage({
      blogType: BLOGGER,
      createBlogUri
    });
  },
  async handleAppMenuClick({ dispatch }, params) {
    const isTokenActive = await ExternalBlogService.isAccessTokenActive(
      BLOGGER
    );
    if (!isTokenActive) {
      dispatch('blogLogin', {
        context: params,
        callbackName: 'handleAppMenuClick'
      });
      return;
    }
    await dispatch('openBlogSettings');
  },
  async handleConnectFromAccountSettings({ dispatch, getters }) {
    const blogSettings = getters.getExternalBlogSettings;
    if (!blogSettings.email) {
      dispatch('blogLogin', {
        context: {},
        options: { isSelectAccount: true },
        callbackName: 'handleConnectFromAccountSettings'
      });
    }
  },
  handleDisconnectFromAccountSettings({ dispatch }) {
    dispatch('resetSettings');
  },
  async openBlogSharing({ getters, dispatch }, popupContext) {
    try {
      const isTokenActive = await ExternalBlogService.isAccessTokenActive(
        BLOGGER
      );
      if (!isTokenActive) {
        dispatch('blogLogin', {
          context: popupContext,
          callbackName: 'openBlogSharing'
        });
        return;
      }

      const blogSettings = getters['getExternalBlogSettings'];
      if (!blogSettings.name) {
        await dispatch('openBlogSettings');
        return;
      }

      await dispatch(
        'ManagePopupStore/openPopup',
        { name: PopupNamesEnum.EXTERNAL_BLOG_POST_POPUP, popupContext },
        {
          root: true
        }
      );
    } catch (error) {
      logger.error(`Handle App Menu click failed with ${error}`);
    }
  },
  blogLogin({ rootGetters, dispatch }, { context, callbackName, options }) {
    const isDevice = rootGetters['ContextStore/isDevice'];
    addCloseLoginPopupListener(async () => {
      await dispatch('processSettingsAfterLogin');
      dispatch(callbackName, context);
    }, isDevice);
    const isSelectAccount = options?.isSelectAccount;
    dispatch('openBloggerOAuthDialog', isSelectAccount);
  },
  openBloggerOAuthDialog({ getters, rootGetters, dispatch }, isSelectAccount) {
    const blogSettings = getters['getExternalBlogSettings'];
    const isDevice = rootGetters['ContextStore/isDevice'];
    const clientReaderUrl = rootGetters['ContextStore/getClientReaderUrl'];

    const bloggerOAuthUri = ExternalBlogService.getBloggerOAuthUri({
      email: blogSettings.email,
      isSelectAccount,
      clientReaderUrl
    });

    if (isDevice) {
      dispatch('openDeviceLogin', isSelectAccount);
      return;
    }

    let aTag = document.createElement('a');
    aTag.rel = 'noopener';
    // NOTE: debounce here as iOS Safari trick
    aTag.onclick = debounce(function() {
      authDialog = window.open(bloggerOAuthUri);
    });
    aTag.click();
  },
  openDeviceLogin({ dispatch }, isSelectAccount) {
    if (isSelectAccount) {
      dispatch('deviceSelectAccount');
      return;
    }
    dispatch('deviceLogin');
  },
  deviceLogin({ getters, rootGetters }) {
    const blogSettings = getters['getExternalBlogSettings'];
    const externalBlogsConfig =
      rootGetters['ContextStore/getExternalBlogsConfig'];
    const bloggerConfig = externalBlogsConfig[BLOGGER];
    const scopes = bloggerConfig.scopes;
    const isAndroid = rootGetters['ContextStore/isAndroid'];
    const bloggerWebClientId = rootGetters['ContextStore/bloggerWebClientId'];
    const email = blogSettings.email;

    const errorCodes = {
      12501: 'user cancelled'
    };
    const loginOptions = {
      scopes: scopes
    };
    if (isAndroid && bloggerWebClientId) {
      loginOptions.webClientId = bloggerWebClientId;
    }
    if (email) {
      loginOptions.loginHint = email;
    }

    window.plugins.googleplus.login(
      loginOptions,
      async res => {
        await ExternalBlogService.performExternalBlogLogin(
          ExternalBlogTypesEnum.BLOGGER,
          res
        );
        const event = new CustomEvent('message', {
          detail: {
            type: PostMessagesEnum.EXTERNAL_BLOG_AUTH_WINDOW_CLOSED,
            blogType: ExternalBlogTypesEnum.BLOGGER
          }
        });

        window.dispatchEvent(event);
        window.removeEventListener('message', authDialogListener);
      },
      err => {
        window.removeEventListener('message', authDialogListener);

        if (errorCodes.hasOwnProperty(err)) {
          logger.warn(`Google+ : ${errorCodes[err]}`);
          return;
        }
        logger.fatal(`Can't login with Google+ into Blogger: ${err}`);
        throw new Error(err);
      }
    );
  },

  deviceSelectAccount({ dispatch }) {
    const loginFn = () => dispatch('deviceLogin');

    if (ClientNodeContext.isAndroid) {
      window.plugins.googleplus.trySilentLogin(
        {},
        () => window.plugins.googleplus.disconnect(loginFn, loginFn),
        () => loginFn
      );
    } else {
      window.plugins.googleplus.disconnect(loginFn, loginFn);
    }
  },
  setSelectedBlog({ commit }, selectedBlog) {
    commit('setSelectedBlogSettings', selectedBlog);
  },
  async processSettingsAfterLogin({ dispatch }) {
    await saveSettingsIfNeeded();
    await dispatch('ExternalBlogStore/initBlogSettings', null, {
      root: true
    });
  },
  async resetSettings({ commit }) {
    await ExternalBlogService.resetExternalBlogSettings(BLOGGER);
    commit('setExternalBlogSettings', {});
  },
  async saveBlogPost({ dispatch, getters }) {
    const isTokenActive = await ExternalBlogService.isAccessTokenActive(
      ExternalBlogTypesEnum.BLOGGER
    );

    if (!isTokenActive) {
      dispatch('openBloggerOauthDialog');
      return;
    }

    const blogPost = getters['getCachedBlogPost'];
    return ExternalBlogService.postToExternalBlog(blogPost);
  },
  cacheBlogPost({ commit }, blogPost) {
    const cacheableBlogPost = cloneDeep(blogPost);
    commit('setCachedBlogPost', cacheableBlogPost);
  }
};

const mutations = {
  setExternalBlogsList(state, externalBlogsList) {
    externalBlogsList = Array.isArray(externalBlogsList)
      ? externalBlogsList
      : [];
    state.externalBlogsList = externalBlogsList.map(
      ExternalBlogFactory.createBlogSettings
    );
  },
  setExternalBlogSettings(state, blogSettings) {
    if (!blogSettings) {
      state.blogSettings = ExternalBlogFactory.createBlogSettings(blogSettings);
      return;
    }
    const userInfo = get(blogSettings, 'externalUserInfo', {});
    const settings = ExternalBlogFactory.createBloggerSettings(blogSettings);
    if (userInfo.email) {
      settings
        .setEmail(userInfo.email)
        .setUserName(userInfo.userName)
        .setUserPhotoUrl(userInfo.userPhotoUrl);
    }
    state.blogSettings = settings;
  },
  setSelectedBlogSettings(state, selectedBlog) {
    state.selectedBlog = selectedBlog || {};
  },
  setCachedBlogPost(state, blogPost) {
    if (!blogPost) {
      state.cachedBlogPost = null;
      return;
    }
    state.cachedBlogPost = ExternalBlogPostFactory.createBlogPost(blogPost);
  }
};

function addCloseLoginPopupListener(callback, isDevice) {
  authDialogListener = function(event) {
    if (event) {
      const eventData = event.data || event.detail;
      if (
        !eventData?.query?.['#error'] &&
        eventData.type === PostMessagesEnum.EXTERNAL_BLOG_AUTH_WINDOW_CLOSED
      ) {
        callback();
      }
    }
  };
  window.addEventListener('message', authDialogListener);

  const authDialogOpenChecker = setInterval(function() {
    if (!isDevice && (!authDialog || authDialog.closed)) {
      clearInterval(authDialogOpenChecker);
      window.removeEventListener('message', authDialogListener);
    }
  }, 1000);
}

function saveExternalBlogSettings(blogSettings = {}) {
  return ExternalBlogService.saveExternalBlogSettings({
    ...blogSettings,
    type: BLOGGER
  });
}

async function saveSettingsIfNeeded() {
  const blogSettings = await ExternalBlogService.getExternalBlogSettings();

  if (
    blogSettings?.name &&
    blogSettings?.externalUserInfo?.userName &&
    blogSettings?.externalUserInfo?.email
  ) {
    return;
  }

  let additionalSettings = {};
  if (!blogSettings?.name) {
    const externalBlogsList = await ExternalBlogService.retrieveExternalBlogsList(
      BLOGGER
    );
    additionalSettings =
      externalBlogsList?.length === 1 ? externalBlogsList[0] : {};
  }
  await saveExternalBlogSettings({ ...blogSettings, ...additionalSettings });
}

export default {
  name: 'BloggerStore',
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
