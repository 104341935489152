<template>
  <div v-if="isSearchLoaded">
    <slot />
  </div>
</template>

<script>
import Vue from 'vue';

let isLoaded = false;

export default {
  name: 'SearchLoader',
  props: {
    loadByEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bindLoadOnEvent: null,
      wasEventLoadTriggered: false,
      isSearchLoaded: false
    };
  },
  async mounted() {
    if (this.loadByEvent) {
      this.bindLoadOnEvent = this.loadOnEvent.bind(this);
      document.addEventListener('mousemove', this.bindLoadOnEvent);
      document.addEventListener('touchstart', this.bindLoadOnEvent);
    } else {
      await this.loadSearchModule();
    }
  },
  methods: {
    async loadOnEvent() {
      if (this.wasEventLoadTriggered || !this.bindLoadOnEvent) {
        return;
      }
      this.wasEventLoadTriggered = true;
      await this.loadSearchModule();
      document.removeEventListener('mousemove', this.bindLoadOnEvent);
      document.removeEventListener('touchstart', this.bindLoadOnEvent);
    },
    async loadSearchModule() {
      if (!isLoaded) {
        const [{ default: SearchWidget }] = await Promise.all([
          import('search-widget'),
          import('search-widget/dist/search-widget.min.css')
        ]);
        Vue.use(SearchWidget, this.$store, this.$i18n);
        isLoaded = true;
      }
      this.isSearchLoaded = true;
    }
  }
};
</script>

<style></style>
