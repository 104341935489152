export default {
  LOGO: 'logo',
  APP_NAME: 'appName',
  LIBRARY: 'library',
  LIBRARY_ICON: 'library_icon',
  BOOK_ICON: 'book_icon',
  COMPILATIONS: 'compilations',
  COMPILATIONS_ICON: 'compilations_icon',
  PRICING: 'pricing',
  PRICING_ICON: 'pricing_icon',
  BLOG: 'blog',
  ABOUT: 'about',
  ABOUT_ICON: 'about_icon',
  ACTIVITY_PROGRESS: 'activityProgress',
  RECENTLY_OPENED_LABEL: 'recentlyOpenedLabel',
  RECENTLY_OPENED_ICON: 'recentlyOpenedIcon',
  VOCABULARY_ASSESSMENT_BACK_BUTTON: 'vocabularyAssessmentBackButton',
  VOCABULARY_ASSESSMENT_PAGE_TITLE: 'vocabularyAssessmentPageTitle',
  VOCABULARY_ASSESSMENT_PREVIOUS_BUTTON: 'vocabularyAssessmentPreviousButton',
  ONBOARDING_BACK_BUTTON: 'onboardingBackButton',
  ONBOARDING_PAGE_TITLE: 'onboardingPageTitle',
  AVATAR: 'avatar',
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
  ADVANCED_SEARCH: 'advancedSearch',
  ADVANCED_SEARCH_MID: 'advancedSearchMid',
  SEARCH_INSIDE_BOOK: 'searchInsideBook',
  SEARCH_HEADER: 'searchHeader',
  SPACER: 'spacer',
  TO_LIBRARY: 'toLibrary',
  BURGER_MENU: 'burgerMenu'
};
