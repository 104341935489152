import tools from '../tools';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import extend from 'lodash/extend';
import has from 'lodash/extend';
const Context = {};
/**
 * @typedef {Object} ShortPublication
 * @property {String} _id
 * @property {String} name
 * @property {String} author
 * @property {String} cover
 * @property {String} type
 * @property {Number} difficulty
 * @property {Number} progress
 * @property {Number} readingTime
 * @property {Number} lastReadingTime
 * @property {String} publicationType
 */

/**
 * @typedef {Object} ExtendedPublication
 * @property {Object} book
 * @property {String} currentStudyGuideId
 * @property {String} defaultStudyGuideId
 * @property {Array} relatedStudyGuides
 * @property {String} id
 * @property {Array} tableOfContents
 */

/**
 * Extract book ID from object
 * @param {Object} publication
 * @returns {String}
 */
function convertId(publication) {
  return (
    (publication &&
      (publication.content.originalId || publication._id.split('-')[1])) ||
    publication.content.id
  );
}

/**
 * get sole author or concat several authors
 * @param {Object} publication
 * @returns {String}
 */
function convertAuthor(publication) {
  if (!publication.content.authors) {
    return publication.content.author;
  }

  var authors = publication.content.authors;
  var isConsistsObjects = authors.some(function(author) {
    return author && typeof author === 'object';
  });
  if (!isConsistsObjects) {
    return authors.join(', ') || '';
  }

  return publication.content.author;
}

/**
 * Check item in list
 * @param {Array<Object>} items collection items
 * @param {Array<String>} myBooks my books list
 * @returns {boolean} true, if any item is in my books list
 * @private
 */
function _hasAnyItemInMyBooks(items, myBooks) {
  if (items && myBooks) {
    return intersection(map(items, 'id'), myBooks).length > 0;
  }
  return false;
}

/**
 * Convert book to the common format
 * @param {Object} book publication object
 * @param {Array<String>} [_myBooks] my books list
 * @param {Object} [bookSummary] publication activity
 * @returns {Object} converted object
 */
function convertBook(book, _myBooks, bookSummary) {
  if (!book || !book._id) {
    return {};
  }

  _myBooks = _myBooks || [];
  bookSummary = bookSummary || {};
  book.content = book.content || {};
  book.distribution = book.distribution || {};

  var name;
  var result = {};

  // copy all content fields
  for (name in book.content) {
    if (book.content.hasOwnProperty(name)) {
      result[name] = book.content[name];
    }
  }

  // some special fields
  var extra = {
    _id: convertId(book),
    id: convertId(book),
    author: convertAuthor(book),
    name: book.content.title,
    type: getPublicationType(book),

    personal: _myBooks.indexOf(convertId(book)) >= 0,
    paragraphsNumber: book.content.paraCount || book.content.paragraphsNumber, //TODO: remove paraCount (old param)

    tableOfContents: book.content.toc,

    readingProgress: bookSummary.readingProgress || 0,
    progress: bookSummary.readingProgress || 0, // OLD

    readingDuration: bookSummary.readingDuration || 0,
    lastReadingTime: bookSummary.lastOpenedAt || 0,
    wordsNumber: book.content.wordsCount,
    defaultStudyGuideId: book.content.defaultNotes
  };

  if (bookSummary.currentStudyGuideId) {
    extra.currentStudyGuideId = bookSummary.currentStudyGuideId;
  }

  for (name in extra) {
    if (extra.hasOwnProperty(name)) {
      result[name] = extra[name];
    }
  }

  result.publicationType = result.publicationType || result.type;

  if (book.pubType === 'syllabus') {
    //TODO unify with collection
    result.items =
      tools.getValue(book, 'distribution.collection.contains') || [];
    result.matches = result.items.length;
    result.userId = book.content.authorId;
  }

  if (book.pubType === 'collection') {
    result.items = (
      tools.getValue(book, 'distribution.collection.contains') || []
    ).map(function(item) {
      return convertBook(item);
    });
    result.personal = _hasAnyItemInMyBooks(result.items, _myBooks);
    result.matches = result.items.length;

    result.difficulty = tools.getValue(
      book,
      'distribution.collection.topDifficulty'
    );
  }

  if (book.pubType === 'notes') {
    if (result.essays) {
      result.essayTask = tools.clone(result.essays).map(function(e) {
        e.type = 'EssayTask';
        return e;
      });
    }

    var quizCount = _countQuizzes(book);
    result.test = convertTests(book);
    result.isOriginal = book.content.isOriginal;

    _setStudyGuideItems(result.notes);
    _setStudyGuideItems(result.comments);
    _setStudyGuideItems(result.discussionTasks);

    result.discussionTasks = result.discussionTasks || [];
    result.discussionTasks.forEach(function(i) {
      i.type = 'discussion task';
    });
    var numberExercises =
      (result.test || []).length +
      (result.discussionTasks || []).length +
      (result.essayTask || []).length;
    result.exercises = {
      numberExercises: numberExercises,
      numberQuizQusetions: quizCount,
      flashcards: result.test.length - quizCount,
      essaysWordLimit: _countEssaysWordLimit(result.essayTask),
      microJournaling: result.exercises.microJournaling,
      discussionTask: result.discussionTasks.length
    };

    delete result.bookmarks;

    delete result.quizzes;
    delete result.essays;
  }

  // delete fields
  delete result.originalId;
  delete result.authors;
  delete result.title;
  delete result.content;
  delete result.toc;

  return result;
}

/**
 * Count objects with type 'Quiz'
 * @param {Object} pub publication object
 * @returns {Number} number of quizzes
 * @private
 */
function _countQuizzes(pub) {
  return (pub.content.quizzes || []).filter(function(test) {
    return test.type === 'Quiz';
  }).length;
}

/**
 * Set study guide identifier for each item in array
 * @param {Array<Object>} items study guide items
 * @private
 */
function _setStudyGuideItems(items) {
  if (items) {
    items.forEach(_setStudyGuideIdentifier);
  }
}

/**
 * Set study guide identifier
 * @param {Object} item
 * @private
 */
function _setStudyGuideIdentifier(item) {
  item.studyGuide = true;
}

/**
 * Count word limit for all essays tasks
 * @param {Array<Object>} essays
 * @returns {number} sum of wordsLimit for essays
 * @private
 */
function _countEssaysWordLimit(essays) {
  var count = 0;
  (essays || []).forEach(function(e) {
    count += parseInt(e.wordsLimit, 10);
  });

  return count;
}

/**
 * Get publication type
 * @param {Object} publication
 * @returns {String} type
 */
function getPublicationType(publication) {
  switch (publication.pubType) {
    case 'book':
      return 'Book';
    case 'collection':
      return 'Collection';
    case 'syllabus':
      return 'StudyCourse';
    case 'notes':
      return 'StudyGuide';
  }
}

/**
 * Convert array of publications
 * @param {Array<Object>} books list of publications
 * @param {Array<String>} [_myBooks] my books list
 * @param {Object} [_bookSummaries] hash of publication activities
 * @returns {Array<Object>} list of converted publications
 */
function convertBooks(books, _myBooks, _bookSummaries) {
  books = books || [];
  _bookSummaries = _bookSummaries || {};
  return books
    .map(function(book) {
      return convertBook(book, _myBooks, _bookSummaries[convertId(book)]);
    })
    .filter(Boolean);
}

/**
 * Convert collection
 * @param {Object} collection
 * @returns {{collection: {Object}, books: {Array}}}
 */
function collectionInfo(collection) {
  extend(
    collection,
    collection.items.reduce(
      function(r, item) {
        r.booksNumber++;
        r.wordsNumber += item.wordsNumber;
        r.paragraphsNumber += item.paragraphsNumber;
        r.difficulty += item.difficulty;
        return r;
      },
      {
        booksNumber: 0,
        wordsNumber: 0,
        paragraphsNumber: 0,
        difficulty: 0
      }
    )
  );
  collection.difficulty /= collection.booksNumber;

  return {
    collection: collection,
    books: collection.items
  };
}

/**
 * Convert publication authors
 * @param {Object} publication
 * @returns {String}
 */
function convertAuthors(publication) {
  return (
    publication.content.author ||
    (publication.content.authors && publication.content.authors.join(', ')) ||
    ''
  );
}

/**
 * Convert tests
 * @param {Object} publication
 * @returns {Array<Object>} list of tests
 */
function convertTests(publication) {
  return tools.clone(publication.content.quizzes || []).map(function(test) {
    test.testQuestionsCount = test.questions.length;
    test.testType = test.type;
    test.type = 'Test';
    test.publicationId = publication.content.originalId;

    return test;
  });
}

/**
 * Convert publication into extended format
 * @param {Array<Object|Array>} res input parameters
 * @returns {ExtendedPublication} converted object
 */
function getInfo(res) {
  var book = convertBook(res[0]);
  var guides = res[1];
  var activity = res[2];
  var guide = convertBook(res[3]);
  var editors = res[4];
  var relatedCustomizedBook = res[5];
  // workaround
  var toc = book.tableOfContents;
  delete book.tableOfContents;

  book.userPublication = {
    //TODO
    personal: activity.personal,
    lastTouchedAt: activity.lastOpenedAt || 0,
    readingDuration: activity.readingDuration || 0,
    readingProgress: activity.readingProgress || 0,
    completed: activity.completed || false
  };

  var relatedStudyGuides = convertBooks(guides).map(_getRelatedStudyGuideInfo);

  var info = {
    book: book,
    currentStudyGuideId: activity.currentStudyGuideId || '',
    defaultStudyGuideId:
      (relatedCustomizedBook && relatedCustomizedBook.defaultStudyGuideId) ||
      '',
    relatedStudyGuides: relatedStudyGuides,
    id: book.id,
    tableOfContents: toc
  };

  if (guide.id) {
    info.currentStudyGuideId = guide.id;
    info.id = guide.id;
    info.editors = editors.map(_getEditorView(guide.editors));
    info.studyGuide =
      info.relatedStudyGuides.filter(function(_guide) {
        return _guide.id === activity.currentStudyGuideId;
      })[0] || {};
  }

  return info;
}

/**
 * Calculate publication reading time
 * @param {Object} exercises exercises metadata
 * @returns {Number} calculated time
 * @private
 */
function _calculateReadingTime(exercises) {
  var readingTime = 0;
  if (exercises) {
    var config = Context.parameters.timeExercises || {};
    readingTime +=
      config.quizQuestion * exercises.numberQuizQusetions +
      config.flashcard * exercises.flashcards +
      config.essay * Math.round(exercises.essaysWordLimit / 10) +
      config.microJournaling * exercises.microJournaling;
  }
  return readingTime;
}

/**
 * Recalculate reading time
 * @param {Object} guide study guide
 * @returns {Object} study guide
 * @private
 */
function _getRelatedStudyGuideInfo(guide) {
  guide.readingTime =
    guide.readingTime + _calculateReadingTime(guide.exercises);

  return guide;
}

/**
 * Convert editors
 * @param {Object} editors study guide editors
 * @returns {Function} convert editor
 * @private
 */
function _getEditorView(editors) {
  return function(user) {
    return {
      user: {
        userId: user.userId,
        name: user.name || user.firstName + ' ' + user.lastName,
        photo: has(user, 'photo') ? user.photo.fileHash || user.photo : ''
      },
      editorStatus: editors[user.userId] && editors[user.userId].status
    };
  };
}

/**
 * Convert publication into short format
 * @param {Object} pub publication
 * @param {Object} activity publication activity
 * @returns {ShortPublication} converted object
 */
function getBasicInfo(pub, activity) {
  return {
    _id: pub.content.originalId,
    name: pub.content.title,
    author: convertAuthors(pub),
    cover: pub.content.cover,
    type: getPublicationType(pub),
    difficulty: pub.content.difficulty,
    progress: activity.readingProgress || 0,
    readingTime: pub.content.readingTime,
    lastReadingTime: activity.lastOpenedAt,
    publicationType: getPublicationType(pub)
  };
}

function createPubMap(pubs) {
  var pubMap = {};
  pubs.forEach(function(pub) {
    var id = convertId(pub);
    pubMap[id] = pub;
  });
  return pubMap;
}

export default {
  common: convertBook,
  id: convertId,
  many: convertBooks,
  collection: collectionInfo,
  author: convertAuthors,
  test: convertTests,
  type: getPublicationType,
  extended: getInfo,
  short: getBasicInfo,
  createPubMap: createPubMap
};
