<template>
  <v-dialog v-bind="computedAttributes" :fullscreen="narrow">
    <v-card
      class="popup-wrapper"
      :height="height"
      :tile="narrow"
      :style="popupStyles"
    >
      <slot name="header" />
      <slot name="content" />
      <slot name="footer" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: 'auto'
    },
    popupStyles: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    computedAttributes() {
      const contentClasses = this.$attrs['content-class'];
      return {
        ...this.$attrs,
        'content-class': contentClasses
      };
    },
    narrow() {
      const narrowAttr = this.$attrs.hasOwnProperty('fullscreen');
      return narrowAttr
        ? this.$attrs.fullscreen
        : this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    }
  }
};
</script>

<style lang="less" src="./PopupWrapper.less"></style>
