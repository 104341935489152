<template>
  <div class="circle-progress" :style="styles">
    <svg :class="{ clockwise: clockwise }" :height="size" :width="size">
      <circle
        stroke="var(--current-color)"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset }"
        :stroke-width="strokeWidth"
        fill="transparent"
        :r="normalizedRadius"
        :cx="size / 2"
        :cy="size / 2"
      />
    </svg>
    <div class="progress-count">
      <span class="progress-text">{{ text }}</span>
      <span v-if="textDescription" class="progress-description">{{
        textDescription
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircleProgress',
  props: {
    size: {
      type: Number,
      default: 20
    },
    progress: {
      type: Number,
      default: 0
    },
    clockwise: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: Number,
      default: 4
    },
    strokeColor: {
      type: String,
      default: '#000'
    },
    text: {
      type: [String, Number],
      default: '0'
    },
    textDescription: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: null
    },
    shadeColor: {
      type: String,
      default: null
    }
  },
  data() {
    const normalizedRadius = this.size / 2 - this.strokeWidth / 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference
    };
  },
  computed: {
    styles() {
      return {
        '--current-color': this.strokeColor,
        '--text-color': this.textColor ? this.textColor : this.strokeColor,
        '--stroke-width': this.strokeWidth + 'px',
        '--transparent-color': this.transparentColor,
        '--width': this.size + 'px'
      };
    },
    transparentColor() {
      return this.shadeColor
        ? this.shadeColor
        : this.doOpacity(this.strokeColor);
    },
    strokeDashoffset() {
      const progress = Math.min(this.progress, 100, Math.max(this.progress, 0)); // limit progress 0-100
      return this.circumference - (progress / 100) * this.circumference;
    }
  },
  methods: {
    doOpacity(hex, alpha) {
      const isValidHex = /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

      if (!isValidHex) {
        throw new Error('Invalid HEX');
      }

      const getChunksFromString = (st, chunkSize) =>
        st.match(new RegExp(`.{${chunkSize}}`, 'g'));

      const convertHexUnitTo256 = hexStr =>
        parseInt(hexStr.repeat(2 / hexStr.length), 16);

      const getAlphaFloat = a => {
        if (typeof a !== 'undefined') {
          return a / 255;
        }
        if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
          return 0.5;
        }
        return alpha;
      };

      const chunkSize = Math.floor((hex.length - 1) / 3);
      const hexArr = getChunksFromString(hex.slice(1), chunkSize);
      const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
      return `rgba(${r}, ${g}, ${b}, ${getAlphaFloat(a, alpha)})`;
    }
  }
};
</script>

<style lang="less">
.circle-progress {
  display: flex;
  flex-shrink: 0;
  position: relative;
  width: var(--width);

  svg {
    border-radius: 50%;
    width: auto;
    height: auto;
    box-shadow: inset 0 0 0 var(--stroke-width) var(--transparent-color);
    transform: rotate(-90deg);

    &:not(.clockwise) {
      transform: rotate(90deg) scaleX(-1);
    }
  }

  .progress-count {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: inherit;
    line-height: 1em;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .progress-text {
      text-transform: capitalize;
    }

    .progress-description {
      font-size: 0.584em;
      display: block;
      opacity: 0.8;
      font-weight: 400;
    }
  }
}
</style>
