import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger(
  'PublicationErrorHandlingLogicService.js'
);

import AppStateEnum from '@/enums/AppStateEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import BookScrollEnum from '@/enums/BookScrollEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import FsErrorsEnum from '@/enums/FsErrorsEnum';

import AssetsManager from '@/services/AssetsManager/AssetsManager';

async function openBookErrorHandling(store, $t, error) {
  let handled = false;
  try {
    handled = await _checkInvalidBookOnFs(store, error);
  } catch (err) {
    logger.info(`Get error check Invalid book on fs ${err}`);
  }
  if (handled) {
    return;
  }

  const errType = error?.type;
  const errStack = error?.stack;
  const errCode = error?.code;

  if (errType === BookScrollEnum.ERROR_EMPTY_CONTENT) {
    handled = await _handleEmptyContentOnFs(store);
  }
  if (handled) {
    return;
  }
  if (errCode === FsErrorsEnum.MISSING_FILE_CODE) {
    logger.info(`Missing file on fs on open publication ${errStack}`);
    await _handleInvalidContentOnFs(store);
    return;
  }

  if (errType === CustomErrorEnum.NETWORK_ERROR) {
    const text = $t('PresentPublication.error.network');
    store.dispatch('ManagePopupStore/openErrorToaster', {
      text,
      toasterIcon: 'ico-no-wifi',
      toasterClose: false
    });
  }
  if (errType === CustomErrorEnum.EMPTY_DATA) {
    logger.info(`Get empty data error on open publication ${errStack}`);
    _openDownloadedPopup(store);
    return;
  }
  if (errType === CustomErrorEnum.READ_FILE_MAP_ERROR) {
    logger.info(`Get error on read file map error: ${error}`);
    _openDownloadedPopup(store);
    return;
  }

  _openDownloadedPopup(store);
  logger.error(`Get error on open publication ${errStack}`);
}

function _openDownloadedPopup(store) {
  const appState = store.getters['ContextStore/appState'];
  if (appState !== AppStateEnum.PRESENT_PUBLICATION) {
    return;
  }
  store.dispatch('ManagePopupStore/openPopup', {
    name: PopupNamesEnum.BOOK_NOT_DOWNLOADED_POPUP,
    popupContext: {}
  });
}

async function _checkInvalidBookOnFs(store, error) {
  let handled = false;
  let assetList = [];
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];

  const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
    publicationId
  );
  if (trackingItemView.isContentDownloaded) {
    const contentAssetNameList = await AssetsManager.getAssetNameContentList();
    [].push.apply(assetList, contentAssetNameList);
  }
  if (trackingItemView.isAudioDownloaded) {
    const audioAssetNameList = await AssetsManager.getAssetNameAudioList();
    [].push.apply(assetList, audioAssetNameList);
  }
  if (assetList.length === 0) {
    return handled;
  }
  const responses = await AssetsManager.checkPublicationAssetsOnSources(
    publicationId,
    assetList,
    [AssetResourcesEnum.FS]
  );
  const isValidAssets = responses.every(response => {
    return response.valid;
  });
  if (!isValidAssets) {
    logger.error(
      `Book asset status:${JSON.stringify(
        responses,
        null,
        2
      )} provide error:${error}`
    );
    handled = await _handleInvalidContentOnFs(store);
  }
  return handled;
}

async function _isDownloaded(store) {
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];

  const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
    publicationId
  );
  return (
    trackingItemView.isContentDownloaded || trackingItemView.isAudioDownloaded
  );
}

async function _handleEmptyContentOnFs(store) {
  try {
    const isDownloaded = await _isDownloaded();
    if (!isDownloaded) {
      return false;
    }
    await _handleInvalidContentOnFs(store);
    return true;
  } catch (error) {
    logger.error(
      `Get error to handle empty downloaded content on fs error: ${error}`
    );
    return false;
  }
}

async function _handleInvalidContentOnFs(store) {
  try {
    const publicationId = store.getters['OpenParameterStore/getPublicationId'];

    await AssetsManager.removePublication(publicationId);
    store.dispatch('LibraryStore/updatePublicationSource', {
      bookId: publicationId
    });
    store.dispatch('ManagePopupStore/openPopup', {
      name: PopupNamesEnum.BOOK_DOWNLOADED_INCORRECT,
      popupContext: {}
    });
    return true;
  } catch (error) {
    logger.error(`Get error to handle invalid content on fs error: ${error}`);
    return false;
  }
}

export default {
  openBookErrorHandling
};
