import Utils from '@/services/utils/Utils';

class ModifiedAttributes {
  constructor(attributes = {}) {
    this.label = attributes.label || '';
    this.labelName = attributes.labelName || '';
    this.counter = attributes.counter || 0;
    this.inputTextLength = attributes.inputTextLength || 0;
  }

  setLabel(value) {
    this.label = value;
  }

  setLabelName(value) {
    this.labelName = `name-${Utils.shortUuid(value)}`;
  }

  setCounter(value) {
    this.counter = value;
  }

  setInputTextLength(value) {
    this.inputTextLength = value;
  }
}

export { ModifiedAttributes };
