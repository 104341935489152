import UserBookmarksDao from '../dao/UserBookmarksDao';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserNotesController.js');
const errorStatuses = {
  NOT_FOUND: 404
};

async function getAll() {
  let bookmarks;
  try {
    bookmarks = await UserBookmarksDao.getAll();
  } catch (err) {
    bookmarks = [];
    if (err.status !== errorStatuses.NOT_FOUND) {
      logger.error(`Error getting all bookmarks: ${err}`);
    }
  }
  return bookmarks;
}

async function put(doc) {
  try {
    Object.assign(doc, { _id: doc._id, _rev: doc?._rev });
    await UserBookmarksDao.write(doc);
  } catch (err) {
    logger.error(`Error while setting bookmarks: ${err.message}`);
  }
}

async function set({ bookId, materials }) {
  const bookmark = materials?.bookmark;
  if (!bookmark) {
    return;
  }
  const doc = await getBookmarksDataOrDefault(bookId);
  doc.bookmarks.push(bookmark);
  put(doc);
}

async function deleteBookmark({ bookId, materials }) {
  const bookmarkId = materials?.bookmarkId;
  if (!bookmarkId) {
    return;
  }
  const doc = await getBookmarksDataOrDefault(bookId);
  const index = doc.bookmarks.findIndex(mark => mark.id === bookmarkId);
  if (index === -1) {
    return;
  }
  doc.bookmarks.splice(index, 1);
  put(doc);
}

async function get(publicationId) {
  const res = await getBookmarksDataOrDefault(publicationId);
  return res.bookmarks;
}

async function getBookmarksDataOrDefault(publicationId) {
  try {
    return await UserBookmarksDao.get(publicationId);
  } catch (err) {
    return UserBookmarksDao.getDefaultDoc(publicationId);
  }
}

export default {
  POST: { set },
  GET: {},
  DELETE: { deleteBookmark },
  get,
  getAll
};
