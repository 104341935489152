import UserNotesDao from './UserNotesDao';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserNotesController.js');
const errorStatuses = {
  NOT_FOUND: 404
};

async function getAll() {
  let notes;
  try {
    notes = await UserNotesDao.getAll();
  } catch (err) {
    notes = [];
    if (err.status !== errorStatuses.NOT_FOUND) {
      logger.error(`Error getting all notes: ${err}`);
    }
  }
  return notes;
}

async function set(publicationId, courseId, annotations) {
  const doc = await _get(publicationId, courseId);
  doc.notes = annotations;
  try {
    await UserNotesDao.write(doc);
  } catch (err) {
    logger.error(`Error while setting annotations: ${err.message}`);
  }
}

async function get(publicationId, courseId) {
  const res = await _get(publicationId, courseId);
  return res.notes;
}

async function _get(publicationId, courseId) {
  let res;
  try {
    res = await UserNotesDao.get(publicationId, courseId);
  } catch (err) {
    res = UserNotesDao.getDefaultDoc(publicationId, courseId);
    if (err.status === errorStatuses.NOT_FOUND) {
      await UserNotesDao.write(res);
    } else {
      logger.error(`Couldn't get notes: ${err}`);
    }
  }
  return res;
}

export default {
  get,
  getAll,
  set
};
