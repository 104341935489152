class SearchNavigation {
  constructor(data = {}) {
    this.bookId = data.bookId || '';
    this.docId = data.docId || '';
    this.isTitle = !!data.isTitle;
    this.navigateIndex = data.navigateIndex || 0;
    this.navigateList = data.navigateList || [];
    this.searchQuery = data.searchQuery || '';
    this.totalResults = data.totalResults || 0;
  }

  setBookId(bookId) {
    this.bookId = bookId;
    return this;
  }

  setNavigateIndex(navigateIndex) {
    this.navigateIndex = navigateIndex;
    return this;
  }

  setDocId(docId) {
    this.docId = docId;
    return this;
  }
}

function createSearchNavigation(data) {
  return new SearchNavigation(data);
}

function createEmptySearchNavigation() {
  return new SearchNavigation();
}

export default {
  createSearchNavigation,
  createEmptySearchNavigation
};
