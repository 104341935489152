<template>
  <div class="open-app-container">
    <button class="open-app-btn" @click="openInApp">
      {{ $t('OpenOrDownload.label') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    hashcode: String
  },
  methods: {
    openInApp() {
      let deepLink = `${this.$store.getters['ContextStore/getBaseDeeplink']}://`;
      if (this.hashcode) {
        deepLink += `?taskConfirmationHashCode=${this.hashcode}&confirm=true`;
      }
      setTimeout(this.openLandingPage, 5000);
      window.location.href = deepLink;
    },
    openLandingPage() {
      const name = this.$store.getters['ContextStore/getDefaultRoute'];
      this.$router.push({
        name
      });
    }
  }
};
</script>

<style scoped lang="less">
@import './OpenInApp.less';
</style>
