import OfflineModeUtils from '@/services/utils/OfflineModeUtils';
import axios from 'axios';
import OnlineStatusEnum from '@/enums/OnlineStatusEnum';
import PromiseUtil from '@/services/utils/PromiseUtil';
import BuildValidateService from '@/services/BuildValidateService';

const context = {
  interval: null,
  requests: []
};

const statusItems = [];

class RequestItem {
  constructor(url) {
    this.url = url;
    this.time = new Date().getTime();
  }

  isPending() {
    return new Date().getTime() - this.time > 5 * 1000;
  }
}

class HttpOfflineChecker {
  constructor(httpClient) {
    this.httpClient = httpClient;
    this.checkUrl = `${process.env.CLIENT_CONFIG?.serverUrl}healthcheck`;
    this.checkExecutionTime = new Date().getTime();
    this.inquiryTimer = 0;
    this.isOffline = false;
  }
  async startChecking(callback) {
    if (this.isOffline) {
      return;
    }
    this.isOffline = true;

    this.requestsAmount = 0;

    while (this.isOffline) {
      this.increaseTimer();
      await PromiseUtil.wait(this.inquiryTimer);
      const responseItem = await this.checkConnection();
      callback(responseItem);
    }
  }
  stopChecking() {
    this.isOffline = false;
    this.inquiryTimer = 0;
  }
  async checkConnection() {
    const resultTemplate = { offline: true };
    try {
      const startQueryTime = new Date().getTime();
      const response = await this.httpClient.get(
        `${this.checkUrl}?t=${Date.now()}`
      );

      const calculatedData = this.calculateValues(startQueryTime, response);
      const result = { ...resultTemplate, ...calculatedData };

      return result;
    } catch (err) {
      return resultTemplate;
    }
  }
  calculateValues(startQueryTime, response) {
    const currentTime = new Date().getTime();
    const queryDuration = currentTime - startQueryTime;

    const responseLength = new TextEncoder().encode(
      JSON.stringify(response.data)
    ).length;
    const speedKBPS = (responseLength / 1024 / (queryDuration / 1000)).toFixed(
      2
    );
    const offline = response.data.status !== 'OK';

    const timeInterval = currentTime - this.checkExecutionTime;
    this.checkExecutionTime = currentTime;

    return { queryDuration, speedKBPS, offline, timeInterval };
  }
  increaseTimer() {
    const TIMER_INCREASING_STEP = 10_000;
    const MAX_TIMER_VALUE = 60_000;

    this.inquiryTimer =
      this.inquiryTimer < MAX_TIMER_VALUE
        ? this.inquiryTimer + TIMER_INCREASING_STEP
        : MAX_TIMER_VALUE;
  }
}

class StatusItem {
  constructor(params) {
    const type = params.offline
      ? OnlineStatusEnum.OFFLINE
      : OnlineStatusEnum.ONLINE;
    this.type = type;
    this.speedKBPS = params.speedKBPS || null;
    this.queryDuration = params.queryDuration || null;
    this.timeInterval = params.timeInterval || null;
    this.date = new Date();
  }
}

function setRequest(request) {
  const url = request.url;
  context.requests.push(new RequestItem(url));
}

function setResponse(response) {
  const url = response.url || response.config.url;
  const removed = _removeItemByUrl(url);
  if (!removed) {
    const error = new Error(`Try remove from list unexpected url: ${url}`);
    error.config = { url };
    BuildValidateService.logNetworkError(error);
    setOfflineStatus(true);
  }
}

function _removeItemByUrl(url) {
  const index = context.requests.findIndex(req => req.url === url);
  if (index === -1) {
    return false;
  }
  context.requests.splice(index, 1);

  return true;
}

/* function for detecting requests in hanged state
  possibly will be helpful for futher offline/online algorithm improvements 
  may be removed as unnecessary
*/
//eslint-disable-next-line no-unused-vars
function _monitoringRequest() {
  context.interval = setInterval(() => {
    const pendingRequest = context.requests.filter(reqItem =>
      reqItem.isPending()
    );
    if (pendingRequest.length !== 0) {
      setOfflineStatus(true);
      context.requests = [];
    }
  }, 500);
}

function getErrorResponse(error) {
  const config = error.config;
  BuildValidateService.logNetworkError(error);
  setOfflineStatus(true);
  if (!config) {
    return;
  }
  _removeItemByUrl(config.url);
}

function getErrorRequest(error) {
  const config = error.config;
  BuildValidateService.logNetworkError(error);
  setOfflineStatus(true);
  if (!config) {
    return;
  }
  _removeItemByUrl(config.url);
}

let httpOfflineChecker = new HttpOfflineChecker(axios);

/**
 *
 * @param {Boolean} offline
 */
function setOfflineStatus(offline) {
  if (!isChangedStatus(offline)) {
    return;
  }

  if (offline) {
    OfflineModeUtils.setOffline();
    httpOfflineChecker.startChecking(saveOfflineStatistics);
  }
  saveOfflineStatistics({ offline });
}

function saveOfflineStatistics(statusItem) {
  if (!isChangedStatus(statusItem.offline)) {
    return;
  }

  const item = new StatusItem(statusItem);
  statusItems.push(item);

  if (checkIsOnline()) {
    setOnline();
  }
}

function checkIsOnline() {
  const lastItem = statusItems.slice(-1).pop();
  const isOnline = lastItem.type === OnlineStatusEnum.ONLINE;

  return isOnline;
}

function setOnline() {
  OfflineModeUtils.setOnline();
  httpOfflineChecker.stopChecking();
  statusItems.length = 0;
}

function isChangedStatus(offline) {
  const type = offline ? OnlineStatusEnum.OFFLINE : OnlineStatusEnum.ONLINE;
  const lastItem = statusItems.slice(-1).pop();
  const isSameStatus = lastItem && type === lastItem.type;

  return !isSameStatus;
}

export default {
  setRequest,
  setResponse,
  getErrorResponse,
  getErrorRequest
};
