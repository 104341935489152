import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
import AppStateEnum from '@/enums/AppStateEnum';

export default function({ store, route, app }) {
  const isDarkMode =
    route.name === AppStateEnum.PRESENT_PUBLICATION &&
    store.getters['ReadingSettingsStore/getLocalStoredThemeName'] ===
      ThemeClassNamesEnum.NIGHT;
  store.commit('ContextStore/setVuetifyDarkMode', isDarkMode);
  if (route.name !== AppStateEnum.PRESENT_PUBLICATION) {
    app.vuetify.framework.theme.isDark = isDarkMode;
  }
}
