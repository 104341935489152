// import tools from '@/services/Agent/tools';
// import Publication from '@/services/Agent/dao/Publication';
// import UserStudy from '@/services/Agent/dao/UserStudy';
// import MyBooks from '@/services/Agent/dao/MyBooks';
// import Converter from '@/services/Agent/converter/publication';
// var Promise = tools.Promise;
import recentBooksFactory from '@shared/recentBooks/recentBooksFactory';
import DB from '@/services/Agent/dao/DB';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserPublication.js');

export default {
  POST: {
    remove: removeRecentBook,
    update: updateRecentBooks
  },
  GET: {
    getRecentBooks: getRecentBooks
  }
};

function getRecentBooks() {
  return DB.userRW()
    .getAllByPrefix(DB.prefix.recentBook)
    .then(function(response) {
      return response[0];
    });
}

function removeRecentBook(publicationData) {
  return getRecentBooks().then(currentRecentBooks => {
    if (!currentRecentBooks) {
      logger.warn(`Trying to remove recent item when no recent data in DB.`);
      return;
    }
    currentRecentBooks = currentRecentBooks || {};
    const updateItemFn = (recentBook, publication) =>
      recentBook.removeRecentItem(publication);
    return update(publicationData, currentRecentBooks, updateItemFn);
  });
}

function updateRecentBooks(updateData) {
  return getRecentBooks().then(function(currentRecentBooks) {
    currentRecentBooks = currentRecentBooks || {};
    const updateItemFn = (recentBook, publication) =>
      recentBook.addRecentItem(publication);
    return update(updateData, currentRecentBooks, updateItemFn);
  });
}

async function _deleteObsoleteDoc(doc) {
  doc._deleted = true;
  try {
    await DB.userRW().post(doc);
  } catch (err) {
    logger.error(`Error deleting obsolete doc: ${err}`);
  }
}

async function update(updateData, currentRecentBooks, updateItemFn) {
  const userId = DB.userId();
  const userPublication = updateData.userPublication;
  const recentBookId = DB.id.recentBook(userId);

  if (currentRecentBooks._id && currentRecentBooks._id !== recentBookId) {
    _deleteObsoleteDoc(currentRecentBooks);
  }

  currentRecentBooks._id = recentBookId;
  const recentBook = recentBooksFactory.createRecentBook(
    userId,
    currentRecentBooks
  );
  const recentItem = updateItemFn(recentBook, userPublication);
  const saveData = recentBook.toJSON();
  if (currentRecentBooks._rev) {
    saveData._rev = currentRecentBooks._rev;
  }
  return DB.userRW()
    .bulkDocs({ docs: [saveData] })
    .then(function() {
      return recentItem;
    });
}
