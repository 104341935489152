import Locator from '@shared/publication/locator.mjs';

const SELECTION_RANGE_SEPARATOR = '~';

function createSelectionHighlightByParaId(strSelection) {
  let selection = [];
  try {
    selection = strSelection.split(SELECTION_RANGE_SEPARATOR);
    return {
      start: Locator.deserialize(selection[0]),
      end: Locator.deserialize(selection[1])
    };
  } catch (e) {
    return null;
  }
}

function createSelectionHighlightByBlockId(strSelection) {
  let selection = [];
  let selectionStart = [];
  let selectionEnd = [];
  try {
    selection = decodeURIComponent(strSelection).split(
      SELECTION_RANGE_SEPARATOR
    );
    selectionStart = selection[0].split('.');
    selectionEnd = selection[1].split('.');
    return {
      start: {
        blockId: selectionStart[0],
        logicalCharOffset: +selectionStart[1]
      },
      end: {
        blockId: selectionEnd[0],
        logicalCharOffset: +selectionEnd[1]
      }
    };
  } catch (e) {
    return null;
  }
}

function createSelectionHighlightFromString(queryString) {
  if (
    !queryString ||
    typeof queryString !== 'string' ||
    queryString.trim().length === 0
  ) {
    return null;
  }
  const selection = queryString.split(SELECTION_RANGE_SEPARATOR);
  const startSelection = selection[0];
  const isBlockSelection = isNaN(+startSelection);

  if (isBlockSelection) {
    return createSelectionHighlightByBlockId(queryString);
  } else {
    return createSelectionHighlightByParaId(queryString);
  }
}

function createSelectionStringWithBlockId(locator, startBlockId, endBlockId) {
  const startLocator = locator.startLocator;
  const endLocator = locator.endLocator;

  if (startBlockId) {
    endBlockId = endBlockId || startBlockId;
    return `${startBlockId}.${startLocator.logicalCharOffset}${SELECTION_RANGE_SEPARATOR}${endBlockId}.${endLocator.logicalCharOffset}`;
  }
  // fallback for old locator
  return createSelectionStringFromLocator(locator);
}

function createSelectionStringFromLocator(locator) {
  const startLocator = locator.startLocator;
  const endLocator = locator.endLocator;

  return `${startLocator.paragraphId}.${startLocator.logicalCharOffset}${SELECTION_RANGE_SEPARATOR}${endLocator.paragraphId}.${endLocator.logicalCharOffset}`;
}

function parseRawStems(rawStems) {
  if (!rawStems) {
    return [];
  }
  return rawStems.filter(parseRawStem);
}

function parseRawStem(rawStem) {
  if (!rawStem) {
    return;
  }
  return rawStem.trim();
}

function parseRawQuote(quote) {
  const parsedQuotes = [];
  if (!quote) {
    return parsedQuotes;
  }
  quote.split(/\s+/).forEach(function(wordForms) {
    if (!wordForms) {
      return;
    }
    parsedQuotes.push([wordForms]);
  });
  return parsedQuotes;
}

function parseRawQuotes(rawQuotes) {
  if (!rawQuotes) {
    return [];
  }
  return rawQuotes.map(parseRawQuote);
}

export default {
  createSelectionHighlightFromString,
  createSelectionStringFromLocator,
  createSelectionStringWithBlockId,
  parseRawStems,
  parseRawQuotes
};
