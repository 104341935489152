'use strict';

var cotext = {};
/* --- api --- */
cotext.systemLanguage = '';
cotext.runtimeParameters = {};
cotext.configMode = 'default';
cotext.serverUrl = '';
cotext.ipAddress = '';

export default cotext;
