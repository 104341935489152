'use strict';

function toBase62(n) {
  if (n === 0) {
    return '0';
  }
  var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  var sign = '';
  if (n < 0) {
    sign = '-';
    n = Math.abs(n);
  }
  while (n > 0) {
    result = digits[n % digits.length] + result;
    n = parseInt(n / digits.length, 10);
  }

  return sign + result;
}

function fromBase62(s) {
  var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = 0;
  var sign = 1;
  if (s.length > 0 && s.charAt(0) === '-') {
    sign = -1;
    s = s.substring(1);
  }
  for (var i = 0; i < s.length; i++) {
    var p = digits.indexOf(s[i]);
    if (p < 0) {
      return NaN;
    }
    result += p * Math.pow(digits.length, s.length - i - 1);
  }
  return sign * result;
}

function stringToBase62(str) {
  if (!str) {
    return '';
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return toBase62(Math.abs(hash));
}

export default {
  toBase62,
  fromBase62,
  stringToBase62
};
