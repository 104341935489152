import get from 'lodash/get';
import AppStateEnum from '@/enums/AppStateEnum';
import LinksHandlerService from '@/services/LinksHandlerService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('router.js');

import QueryParamService from '@/services/QueryParamService';
import pwaService from '@/services/PwaService';

function isExternalBlogAuthRedirect(url) {
  return (
    LinksHandlerService.isOauthRedirect(url.hash) &&
    url.path.includes('externalBlogAuth')
  );
}

let cachePageTimeout = null;

const CACHE_PAGE_TIMEOUT = 2000;

function cacheRouteIfNeeded(from, route, store) {
  if (from?.name !== route?.name) {
    clearTimeout(cachePageTimeout);
    cachePageTimeout = setTimeout(() => {
      pwaService.cachePage({ route, store });
    }, CACHE_PAGE_TIMEOUT);
  }
}

export default async function(context) {
  const { store, from = {}, route, redirect, app } = context;
  const query = QueryParamService.getAllParams(app.router);

  store.commit('ContextStore/setAppState', { to: route, from });

  const routes = get(app.router, 'options.routes', []);
  const isSupportedRoute = routes.some(r => {
    return r.name === route.name || r.path === route.path;
  });
  const defaultRoute = store.getters['ContextStore/getDefaultRoute'];

  if (!isSupportedRoute) {
    return redirect({
      name: defaultRoute
    });
  }

  const isAllowedPage = store.getters['ContextStore/isAllowedPage'](route.name);
  const isAuthenticated = store.getters['UserStore/isAuthenticated'];
  const isGuest = store.getters['UserStore/isGuestUser'];
  if (
    from.path &&
    from.path.includes(AppStateEnum.LOGIN) &&
    store.getters['ContextStore/isPurchaseEnabled'] &&
    store.getters['ContextStore/isDevice'] &&
    isAuthenticated &&
    !isGuest
  ) {
    let loggedOut;
    try {
      await store.dispatch('UserStore/getUserProfile');
      loggedOut = await store.dispatch('PaymentsStore/logoutFreeUserOnDevice');
    } catch (error) {
      loggedOut = true;
      logger.error(error);
    }
    if (loggedOut) {
      return redirect({ name: defaultRoute });
    }
  }
  const isRedirectFromExternalBlogAuth = isExternalBlogAuthRedirect(route);
  if (
    isRedirectFromExternalBlogAuth ||
    isGuest ||
    isAuthenticated ||
    isAllowedPage
  ) {
    cacheRouteIfNeeded(from, route, store);
    return;
  }
  redirect({
    name: defaultRoute,
    query
  });
}
