import { AssetsManagerConstants } from '@shared/enums/AssetsManagerConstants.mjs';
import Mapper from './Mapper';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';

class TrackingItem {
  constructor(id) {
    this.id = id;
    this.version = null;
    this.versionDate = null;
    this.isAudioPublication = null;
    this.files = {};
  }
}

class UpdateInfo {
  constructor() {
    this.processedFileMap = {};
    this.remove = [];
    this.download = [];
  }
  addProcessedFile(logicalName) {
    this.processedFileMap[logicalName] = true;
  }

  isProcessed(logicalName) {
    return this.processedFileMap.hasOwnProperty(logicalName);
  }
  hasUpdates() {
    return this.remove.length || this.download.length;
  }
  optimize() {
    if (!this.hasUpdates()) {
      return;
    }

    const rMap = {};
    const repeatingFiles = [];
    for (const [index, value] of this.remove.entries()) {
      rMap[value.hash] = {
        type: 'remove',
        index
      };
    }

    for (const [index, value] of this.download.entries()) {
      if (rMap.hasOwnProperty(value.hash)) {
        repeatingFiles.unshift({
          type: 'download',
          index
        });
        repeatingFiles.unshift(rMap[value.hash]);
      }
    }

    for (const rep of repeatingFiles) {
      this[rep.type].splice(rep.index, 1);
    }
  }
}

class UpdateItem {
  constructor(publicationId, logicalName, source, assetName, hash) {
    this.publicationId = publicationId;
    this.logicalName = logicalName;
    this.source = source;
    this.assetName = assetName;
    this.hash = hash;
  }
}

class PublicationTrackingItem extends TrackingItem {
  constructor(id) {
    super(id);
    this.mapper = {};
    this.updateMapper = {};
    this.contentsTables = {};
    this.progress = 0;
    this.listeners = {};
    this.updateInfo = new UpdateInfo();
  }

  initFileMap(readFileMap, updateFileMap, contentsTables) {
    const self = this;
    self.mapper = Mapper.createMapper(readFileMap, this.id);
    self.updateMapper = Mapper.createMapper(updateFileMap, this.id);
    self.isAudioPublication =
      self.mapper.getAssetsFile(AssetTypeEnum.AUDIO).length !== 0 &&
      self.mapper.getAssetsFile(AssetTypeEnum.ALIGNMENT).length !== 0;

    const assetsMap = self.mapper.getAssetsMap();
    self._addMapsContentsTable(contentsTables);

    const currentSource = self.mapper.getSourceName();
    const updateSource = self.updateMapper.getSourceName();

    const priorityOder = [
      AssetResourcesEnum.SD_CARD,
      AssetResourcesEnum.FS,
      AssetResourcesEnum.LOCAL_DB,
      AssetResourcesEnum.REMOTE
    ];
    contentsTables.sort(function(contentsTableA, contentsTableB) {
      const priorityA = priorityOder.indexOf(contentsTableA.source);
      const priorityB = priorityOder.indexOf(contentsTableB.source);
      return priorityA - priorityB;
    });

    Object.keys(assetsMap).forEach(function(assetName) {
      const assetFiles = assetsMap[assetName];
      assetFiles.forEach(function(logicalName) {
        const hash = self.mapper.getResource(logicalName);
        const externalPublicationId = self.mapper.getResourceExternalPublicationId(
          logicalName
        );
        const newHash = self.updateMapper.getResource(logicalName);

        let newHashSource = self._findSourceByHash(contentsTables, newHash);
        let source = self._findSourceByHash(contentsTables, hash);
        if (!source) {
          source = AssetResourcesEnum.REMOTE;
        }
        if (!newHashSource) {
          newHashSource = AssetResourcesEnum.REMOTE;
        }

        if (hash !== newHash && source !== newHashSource) {
          if (newHash) {
            self.updateInfo.download.push(
              new UpdateItem(
                self.id,
                logicalName,
                updateSource,
                assetName,
                newHash
              )
            );
          }
          self.updateInfo.remove.push(
            new UpdateItem(self.id, logicalName, currentSource, assetName, hash)
          );
        }

        if (
          source !== AssetResourcesEnum.REMOTE &&
          source !== AssetResourcesEnum.LOCAL_DB
        ) {
          self.progress += 1;
        }

        self.setFileHash(logicalName, hash);
        self.setAssetFileSource(logicalName, source);
        self.setAssetExternalPublicationId(logicalName, externalPublicationId);
        self.updateInfo.addProcessedFile(logicalName);
      });
    });

    const updateAssetMap = self.updateMapper.getAssetsMap();
    Object.keys(updateAssetMap).forEach(function(assetName) {
      const assetFiles = updateAssetMap[assetName];
      assetFiles.forEach(function(logicalName) {
        if (self.updateInfo.isProcessed(logicalName)) {
          return;
        }
        const newHash = self.updateMapper.getResource(logicalName);
        self.updateInfo.addProcessedFile(logicalName);
        let source = self._findSourceByHash(contentsTables, newHash);
        if (!source) {
          source = AssetResourcesEnum.REMOTE;
        }
        self.updateInfo.download.push(
          new UpdateItem(self.id, logicalName, source, assetName, newHash)
        );
        const externalPublicationId = self.updateMapper.getResourceExternalPublicationId(
          logicalName
        );
        self.setAssetExternalPublicationId(logicalName, externalPublicationId);
      });
    });
    self.setContentsTables(contentsTables);
  }

  getAssetSourceByName(assetName) {
    const files = this.getAssetsByName(assetName);
    const targetSource = this.getAssetFileSource(files[0]);
    const isAvailable = this.isAssetAvailable(assetName, targetSource);
    return isAvailable ? targetSource : AssetResourcesEnum.REMOTE;
  }

  getAssetSourceByLogicalName(logicalName) {
    const hash = this._getFileHash(logicalName);
    const contentTable = this.contentsTables.find(function(contentsTable) {
      return contentsTable.files.indexOf(hash) > -1;
    });
    return contentTable ? contentTable.source : AssetResourcesEnum.REMOTE;
  }

  useUpdateMapper() {
    this.mapper = this.updateMapper;
    this.updateMapper = null;
  }

  getRemoveUpdateItems() {
    return this.updateInfo.remove;
  }

  getDownloadUpdateItems() {
    return this.updateInfo.download;
  }

  _addMapsContentsTable(contentsTables) {
    contentsTables.forEach(function(contentsTable) {
      const contentsTablesMap = {};
      contentsTable.files.forEach(function(contentsTableFile) {
        contentsTablesMap[contentsTableFile] = contentsTable.source;
      });
      contentsTable.map = contentsTablesMap;
    });
  }

  _findSourceByHash(contentsTables, hash) {
    const contentsTable = contentsTables.find(function(contTable) {
      return contTable.map.hasOwnProperty(hash);
    });
    return contentsTable ? contentsTable.source : null;
  }

  setContentsTables(contentsTables) {
    this.contentsTables = contentsTables;
  }

  updateContentsTables(contentTable, source) {
    const self = this;
    for (let index = self.contentsTables.length - 1; index > 0; index--) {
      const contentsTable = self.contentsTables[index];
      if (contentsTable.source === source) {
        self.contentsTables.splice(index, 1, contentTable);
      }
    }
  }

  removeFromContentTable(logicalName, source, size) {
    const fileHash = this._getFileHash(logicalName);
    this.removeFromContentTableByFileHash(fileHash, source, size);
  }

  removeFromContentTableByFileHash(fileHash, source, size) {
    const contentsTable = this.findContentsTableBySource(source);
    contentsTable.removeFile(fileHash, size);
    contentsTable.updateAssetInfo(this.mapper.assetInfo);
  }

  updateContentTable(logicalName, source, size) {
    const contentsTable = this.findContentsTableBySource(source);
    const fileHash = this._getFileHash(logicalName);
    contentsTable.addFile(fileHash, size);
    contentsTable.updateAssetInfo(this.mapper.assetInfo);
  }

  findContentsTableBySource(source) {
    return this.contentsTables.find(function(contentsTable) {
      return contentsTable.source === source;
    });
  }

  addListener(listener, id) {
    this.listeners[id] = listener;
  }

  removeListener(id) {
    delete this.listeners[id];
  }

  changeNotify(downloadInfo, changeType) {
    const self = this;
    const nofityInfo = { ...downloadInfo, ...self.mapper.assetInfo };
    Object.keys(self.listeners).forEach(function(listenerName) {
      const listener = self.listeners[listenerName];
      listener(nofityInfo, changeType);
    });
  }

  createFileMapPath() {
    return [this.id, AssetsManagerConstants.fileMapName].join('/');
  }

  getFileMapSource() {
    return this.mapper.assetInfo.source;
  }

  createСontentsTablePath() {
    return [this.id, AssetsManagerConstants.contentsTable].join('/');
  }

  createAssetFilePathBySource(logicalName, source) {
    switch (source) {
      case AssetResourcesEnum.FS:
      case AssetResourcesEnum.LOCAL_DB:
        return [this.id, this._getFileHash(logicalName)].join('/');
      case AssetResourcesEnum.REMOTE: {
        const id = this._getPublicationId(logicalName);
        return [id, this._getFileHash(logicalName)].join('/');
      }
      default:
        break;
    }
  }

  getFileRealFileName(logicalName) {
    return this._getFileHash(logicalName);
  }

  createAssetFilePath(logicalName) {
    const fileItem = this.files[logicalName];
    const source = fileItem?.source;
    if (!source) {
      const err = new Error(
        `Did not get file source by logicalName:${logicalName} in publication with id:${this.id}`
      );
      err.type = CustomErrorEnum.EMPTY_FILE_SOURCE;
      throw err;
    }
    return this.createAssetFilePathBySource(logicalName, fileItem.source);
  }

  createFilePath(fileHash) {
    return [this.id, fileHash].join('/');
  }

  hasVersionFile(logicalName) {
    return Boolean(this._getFileHash(logicalName));
  }

  createDownloadFilePath(logicalName) {
    return [
      this._getPublicationId(logicalName),
      this._getFileHash(logicalName)
    ].join('/');
  }

  _getPublicationId(logicalName) {
    const externalPublicationId = this.files[logicalName].externalPublicationId;
    return externalPublicationId ? externalPublicationId : this.id;
  }

  _getFileHash(logicalName) {
    return this.files[logicalName].hash;
  }

  setFileHash(logicalName, hash) {
    this._initFileAsset(logicalName);
    this.files[logicalName].hash = hash;
  }

  getAssetFileSource(logicalName) {
    return this.files[logicalName].source;
  }

  setAssetFileSource(logicalName, source) {
    this._initFileAsset(logicalName);
    this.files[logicalName].source = source;
  }

  setAssetExternalPublicationId(logicalName, externalPublicationId) {
    this._initFileAsset(logicalName);
    this.files[logicalName].externalPublicationId = externalPublicationId;
  }

  switchAssetInfo(fromLogicalName, toLogicalName) {
    const fromAssetInfo = this.files[fromLogicalName];
    const toAssetInfo = this.files[toLogicalName];

    this.files[fromLogicalName] = toAssetInfo;
    this.files[toLogicalName] = fromAssetInfo;
  }

  replaceAssetInfo(sourceLogicalName, targetLogicalName) {
    const sourceAssetInfo = this.files[sourceLogicalName];
    this.files[targetLogicalName] = sourceAssetInfo;
  }

  removeAssetInfo(targetLogicalName) {
    delete this.files[targetLogicalName];
  }

  deleteAssetFile(logicalName) {
    delete this.files[logicalName];
    this.mapper.removeAssetFile(logicalName);
  }

  _initFileAsset(logicalName) {
    if (!this.files.hasOwnProperty(logicalName)) {
      this.files[logicalName] = {
        hash: '',
        source: '',
        externalPublicationId: ''
      };
    }
  }

  removeAssetFileSource(logicalName) {
    delete this.files[logicalName];
  }

  getAssetsByName(assetName) {
    return this.mapper.getAssetsFile(assetName);
  }

  isAssetAvailable(assetName, targetSource) {
    const self = this;
    const assetsFiles = self.mapper.getAssetsFile(assetName);
    return assetsFiles.every(function(assetsFile) {
      return self.getAssetFileSource(assetsFile) === targetSource;
    });
  }

  getFilesFromTargetSource(targetSource) {
    const self = this;
    const targetSourceFiles = [];
    Object.keys(this.files).forEach(function(logicalName) {
      if (self.getAssetFileSource(logicalName) === targetSource) {
        targetSourceFiles.push(logicalName);
      }
    });
    return targetSourceFiles;
  }

  getAllFiles() {
    return Object.keys(this.files);
  }

  calculateProgress() {
    const contentsTable = this.findContentsTableBySource(AssetResourcesEnum.FS);
    this.progress = contentsTable.getNumberFiles();
  }

  checkAssetsFile(assetName, targetSource) {
    const self = this;
    const logicalNames = self.mapper.getAssetsFile(assetName);
    return logicalNames.some(function(logicalName) {
      return self.getAssetFileSource(logicalName) === targetSource;
    });
  }
}

class PublicationTrackingItemView {
  constructor(buildData) {
    this.id = buildData.id;
    this.isContentDownloaded = buildData.isContentDownloaded;
    this.isAudioDownloaded = buildData.isAudioDownloaded;
    this.isAudioOnSDCard = buildData.isAudioOnSDCard;
    this.outdatedData = buildData.outdatedData;
    this.isStartedDownload = buildData.isStartedDownload;
    this.progress = buildData.progress;
    this.totalFiles = buildData.totalFiles;
    this.hasAudio = buildData.hasAudio;
  }
}

class PublicationTrackingItemViewBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setIsContentDownloaded(isContentDownloaded) {
    this.isContentDownloaded = isContentDownloaded;
    return this;
  }

  setIsAudioDownloaded(isAudioDownloaded) {
    this.isAudioDownloaded = isAudioDownloaded;
    return this;
  }

  setIsAudioOnSDCard(isAudioOnSDCard) {
    this.isAudioOnSDCard = isAudioOnSDCard;
    return this;
  }

  setOutdatedData(outdatedData) {
    this.outdatedData = outdatedData;
    return this;
  }

  setIsStartedDownload(isStartedDownload) {
    this.isStartedDownload = isStartedDownload;
    return this;
  }

  setProgress(progress) {
    this.progress = progress;
    return this;
  }

  setTotalFiles(totalFiles) {
    this.totalFiles = totalFiles;
    return this;
  }

  setHasAudio(hasAudio) {
    this.hasAudio = hasAudio;
    return this;
  }

  build() {
    return new PublicationTrackingItemView(this);
  }
}

class AssetActionResponse {
  constructor(assetName, assetsSource) {
    this.assetName = assetName;
    this.assetsSource = assetsSource;
  }
}

function createPublicationTrackingItem(id) {
  return new PublicationTrackingItem(id);
}

function getPublicationTrackingItemViewBuilder() {
  return new PublicationTrackingItemViewBuilder();
}

function createAssetActionResponse(assetName, assetsSource) {
  return new AssetActionResponse(assetName, assetsSource);
}

export default {
  createPublicationTrackingItem,
  getPublicationTrackingItemViewBuilder,
  createAssetActionResponse
};
