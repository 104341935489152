<template>
  <div>
    <v-dialog
      ref="lookUp"
      v-model="dialog"
      :scrollable="scrollable"
      :content-class="dictClassName"
      :fullscreen="fullScreen"
      :width="popUpWidth"
      :hide-overlay="!isFullDictionary"
    >
      <v-card>
        <v-card-title
          v-show="isFullDictionary"
          class="headline lighten-2 popup-header"
          primary-title
        >
          <span class="back-wrapper" />
          <span class="popup-header-wrapper">Lookup</span>
          <span class="close-wrapper">
            <button class="button-close" @click="closePopup()">
              <BaseSpriteIcon icon-name="ico-close" />
            </button>
          </span>
        </v-card-title>
        <v-card-text ref="lookupBody">
          <div
            v-for="(definition, index) in currentDefinitions.definitions"
            :key="index"
          >
            <div
              :is="definition.componentName"
              :is-online="isOnline"
              :definition="definition"
              :allow-flash-card="definition.allowFlashCard"
              :def-index="createIndex(definition, index)"
              :dict-type="currentDefinitions.meta.dictType"
              @definitionEvent="definitionEventHandler"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LookUp.vue');

import DictionaryTypeEnum from '@/enums/DictionaryTypeEnum';
import DefinitionEventEnum from '@/enums/DefinitionEventEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import lookUpEventEnum from '@/enums/lookUpEventEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import LearnersDefinition from '@/components/views/LearnersDefinition/LearnersDefinition';

const SHOR_DICT_WIDTH = 770;
const FULL_DICT_WIDTH = 480;
const DEFAULT_MAX_FREQ = 1000;

export default {
  name: PopupNamesEnum.LOOK_UP,
  components: {
    BaseSpriteIcon,
    LearnersDefinition
  },
  props: {
    word: String,
    phrase: String
  },
  data() {
    return {
      dialog: null,
      scrollable: true,
      dictClassName: '',
      isFullDictionary: false,
      endSubscription: null,
      currentDefinitions: {
        meta: {},
        definitions: []
      }
    };
  },
  computed: {
    fullScreen() {
      return (
        this.isFullDictionary &&
        this.$store.getters['MediaDetectorStore/mediaSize'].narrow
      );
    },
    popUpWidth() {
      return this.isFullDictionary ? FULL_DICT_WIDTH : SHOR_DICT_WIDTH;
    },
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    }
  },
  watch: {
    dialog(val) {
      const eventType = val ? PopupEventEnum.OPEN : PopupEventEnum.CLOSE;
      this.$emit('popupEvent', {
        type: eventType,
        data: { name: PopupNamesEnum.LOOK_UP }
      });
    }
  },
  async mounted() {
    this.endSubscription = this.$store.subscribeAction({
      after: action => {
        const type = action.type;
        switch (type) {
          case 'DictionaryStore/findShortDefinition':
            this.$_processingShortDefinition();
            break;
          case 'DictionaryStore/findFullDictionaryDefinition':
            this.isFullDictionary = true;
            this.dictClassName = 'full-dictionary-definition';
            this.currentDefinitions = this.$store.getters[
              'DictionaryStore/getCurrentFullDefinition'
            ];
            break;
          default:
            break;
        }
      }
    });
    const dictType = DictionaryTypeEnum.SHORT;
    const word = this.word;
    const phrase = this.phrase;
    if (word.length === 0 || phrase.length === 0) {
      return;
    }
    const response = await this.$store.dispatch(
      'DictionaryStore/findShortDefinition',
      {
        phrase,
        word,
        dictType
      }
    );
    return response;
  },
  destroyed() {
    if (this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    closePopup() {
      this.dialog = false;
    },
    createIndex(definition, index) {
      return definition && definition.frequencyNumber !== DEFAULT_MAX_FREQ
        ? '' + (index + 1)
        : '';
    },
    $_processingShortDefinition() {
      const positionSuffix = this.$_getPositionSuffix();
      this.isFullDictionary = false;
      this.dictClassName = 'short-dictionary-definition ' + positionSuffix;
      this.currentDefinitions = this.$store.getters[
        'DictionaryStore/getCurrentDefinition'
      ];

      if (this.currentDefinitions.hasDefinitions()) {
        this.dialog = true;
      } else {
        this.emit({
          type: lookUpEventEnum.EMPTY_REPONSE
        });
      }
    },
    $_getPositionSuffix() {
      const contexMenuElement = window.document.querySelector(
        '.context-menu-content'
      );
      if (!contexMenuElement) {
        return '';
      }
      const contextMenuTop = contexMenuElement.getBoundingClientRect().top;
      const halfHeight = Math.round(window.innerHeight / 2);
      if (contextMenuTop > halfHeight) {
        return '-top';
      }
      return '-bottom';
    },
    async definitionEventHandler(event) {
      switch (event.type) {
        case DefinitionEventEnum.SHOW_FULL_DEFINITION:
          return this.searchFullDefinition(
            this.currentDefinitions.getFirstDefId(),
            event.data.dictionaryId
          );
        case DefinitionEventEnum.ADD_TO_FLASH_CARD:
          logger.error('Need implement ADD_TO_FLASH_CARD');
          return Promise.reject();
        case DefinitionEventEnum.OPEN_CROSS_REFERNCE:
          this.$_scrollTop();
          return this.searchFullDefinition(
            event.data.crossReference,
            event.data.dictionaryId
          );
        default:
          logger.error(`Unsupported dictionary event ${event.type}`);
          return Promise.reject(`Unsupported dictionary event ${event.type}`);
      }
    },

    $_scrollTop() {
      if (!this.$refs.lookupBody) {
        logger.error('Does not find this.$refs.lookupBody for scroll top.');
        return;
      }
      this.$refs.lookupBody.scrollTop = 0;
    },

    emit(payload) {
      this.$emit('lookUpEvent', payload);
    },

    async searchFullDefinition(searchTerm, dictionaryId) {
      await this.$store.dispatch(
        'DictionaryStore/findFullDictionaryDefinition',
        {
          searchTerm,
          dictionaryId
        }
      );
    }
  }
};
</script>

<style lang="less" src="./LookUp.less"></style>
