import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';

export default {
  [PublicationsTypes.BOOK]: 'books',
  [PublicationsTypes.SUGGESTED_BOOK]: 'suggestedBooks',
  [PublicationsTypes.COLLECTION]: 'books',
  [PublicationsTypes.STUDY_GUIDE]: 'studyGuides',
  [PublicationsTypes.SYLLABUS]: 'syllabuses',
  [PublicationsTypes.LIBRARY_SET]: 'libraryset',
  [PublicationsTypes.STUDY_COURSE]: 'studyCourses',
  RELATED_STUDYGUIDES: 'relatedStudyGuides',
  [PublicationsTypes.COMPILATION]: 'compilations'
};
