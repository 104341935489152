import BookFactory from '@/classes/factories/BookFactory';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import Locator from '@shared/publication/locator.mjs';
import dayJS from '@/dayJS';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import HighlightUtils from '@shared/publication/HighlightUtils';
import Utils from '@/services/utils/Utils';
import Http from '@/services/utils/Http';
import SVGSprite from '@/assets/sprite.symbol.svg';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CompilationsService.js');

export default {
  generateContent,
  computeAudioDuration,
  sliceAndRecalculateLocators,
  convertSelectionToBookItem,
  convertParaIdToIndex,
  convertModifiedAtToLocaleString,
  getNextParaId,
  getPrevParaId,
  moveSelectionsUp,
  moveSelectionsDown,
  convertIndexToParaId,
  convertTextToHtml,
  wrapSelectionContent,
  countWordsInText,
  recomposeAlignment,
  getSelectionsCount,
  getCompilationCoversList
};

//todo make another name?
function generateContent(selections) {
  return Object.keys(selections).map((id, i) => {
    const item = convertSelectionToBookItem(selections[id], i);
    return {
      id: convertIndexToParaId(i),
      index: i,
      start: i,
      offset: i,
      words: i,
      paraNum: '',
      hasContent: true,
      paragraph: item.paragraph
    };
  });
}

function computeAudioDuration(selections) {
  let duration = 0;
  for (let id in selections) {
    const selection = selections[id];
    if (selection.alignment) {
      const length = selection.alignment[0].length;
      const startTime = selections[id].alignment[0][0][0];
      const endTime = selections[id].alignment[0][length - 1][1];
      duration += endTime - startTime;
    }
  }
  return duration;
}

function convertTextToHtml(text) {
  return `<span class="itm-wrap">${text}</span>`;
}

function wrapSelectionContent(selection, paraNum) {
  paraNum = selection.publication.paraNum || paraNum;
  const rawContentPattern = /<span class="itm-wrap">.*<\/span>/;
  let rawContent = selection.content.match(rawContentPattern);
  if (!rawContent) {
    logger.warn(
      `Does not match compilation content by pattern ${rawContentPattern.source} set selection.content as default`
    );
    rawContent = `<span class="itm-wrap">${selection.content}</span>`;
  }
  const selectionLink = _createSelectionLink(selection);
  return `<p data-ww><span class="block-num" data-id="${paraNum}"></span><span class="block-pb"><span class="block-pb is-animated"></span></span>${rawContent}</p>${selectionLink}`;
}

function _createSelectionLink(selection) {
  const publicationInfo = selection.publication;
  const author = publicationInfo.author;
  let text = `${publicationInfo.name}`;
  const blockId = selection.blockId;
  const selectionString = HighlightUtils.createSelectionStringWithBlockId(
    selection.locator,
    blockId
  );
  if (typeof author === 'string' || author instanceof String) {
    text = `${author}, ${text}`;
  }
  const slug = publicationInfo.slug || publicationInfo.id;

  return `<i class="${AppConstantsUtil.SELECTION_LINK_CLASS}"
     data-slug="${slug}"
     data-locator="${publicationInfo.locator}"
     data-selection="${selectionString}"
     data-selectable="none"
     dir="auto"
     data-meta="">${_getHalfBookIconHtml()}
     <span>${text}</span>
     ${_getArrowFrontIconHtml()}</i>`;
}

function _getHalfBookIconHtml() {
  const halfBookIconLink = `${SVGSprite}#ico-book-new`;
  return `<svg xmlns="http://www.w3.org/2000/svg" class="svg-ico ico-book-new" viewBox="0 0 24 24">
        <use href="${halfBookIconLink}" xlink:href="${halfBookIconLink}"></use>
      </svg>`;
}

function _getArrowFrontIconHtml() {
  const arrowFrontIconLink = `${SVGSprite}#ico-arrow-front-long`;
  return `<svg xmlns="http://www.w3.org/2000/svg" class="svg-ico ico-arrow-front-long" viewBox="0 0 24 24">
        <use href="${arrowFrontIconLink}" xlink:href="${arrowFrontIconLink}"></use>
      </svg>`;
}

function convertSelectionToBookItem(selection, index, lastIndex, wordsBefore) {
  const content =
    selection.type === CompilationSelectionTypes.SECTION
      ? `<p>${selection.title}</p>`
      : wrapSelectionContent(selection, `1.${index + 1}`);
  const id = convertIndexToParaId(index);
  const wrapper = document.createElement('div');
  wrapper.innerHTML = content;
  wrapper.children[0].setAttribute('id', id);
  wrapper.children[0].setAttribute('data-before', wordsBefore);

  const paragraph = wrapper.innerHTML;
  const contentObjectBuilder = BookFactory.getContentObjectBuilder();
  const isFirst = index === 0;
  const isLast = index === lastIndex;
  const isSection = selection.type === 'Section';
  const direction = Utils.getDirection(selection.language);
  return contentObjectBuilder
    .setDirection(direction)
    .setHasContent(true)
    .setId(id)
    .setClientSortId(selection.clientSortId)
    .setIndex(index)
    .setIsFirst(isFirst)
    .setIsLast(isLast)
    .setIsSection(isSection)
    .setParagraph(paragraph)
    .setWords(selection.wordsCount)
    .build();
}

function convertModifiedAtToLocaleString(modifiedAt) {
  return dayJS.get(modifiedAt).format(AppConstantsUtil.DATE_TIME_FORMAT);
}

function convertIndexToParaId(i) {
  return _createParaId(i + 1);
}

function _createParaId(paraNum) {
  return `para_${paraNum}`;
}

function getNextParaId(paraId) {
  const paraNum = _getParaNum(paraId);
  return _createParaId(paraNum + 1);
}

function getPrevParaId(paraId) {
  const paraNum = _getParaNum(paraId);
  return _createParaId(paraNum - 1);
}

function _getParaNum(paraId) {
  const paraNum = paraId.match(/\d+$/);
  if (paraNum == null) {
    throw new Error(`get invalid paraId ${paraId} in compilation.`);
  }
  return parseInt(paraNum[0], 10);
}

function convertParaIdToIndex(paraId) {
  return _getParaNum(paraId) - 1;
}

function moveSelectionsUp(editContext, selectionId, clientSortId) {
  const { selections } = editContext;
  const selectionIndex = selectionId
    ? _findSelectionsIndexById(selections, selectionId)
    : _findSelectionsIndexByClientSortId(selections, clientSortId);

  if (selectionIndex === -1) {
    return false;
  }

  const selection = selections.splice(selectionIndex, 1)[0];
  selections.splice(selectionIndex - 1, 0, selection);

  return true;
}

function moveSelectionsDown(editContext, selectionId, clientSortId) {
  const { selections } = editContext;
  const selectionIndex = selectionId
    ? _findSelectionsIndexById(selections, selectionId)
    : _findSelectionsIndexByClientSortId(selections, clientSortId);

  if (selectionIndex === -1) {
    return false;
  }

  const selection = selections.splice(selectionIndex, 1)[0];
  selections.splice(selectionIndex + 1, 0, selection);

  return true;
}

function recomposeAlignment(selections) {
  const rawAlignment = [];
  for (let i = 0; i < selections.length; i++) {
    if (selections[i].alignment) {
      const paraId = convertIndexToParaId(i);
      rawAlignment.push({
        alignment: selections[i].alignment,
        paraId
      });
    }
  }
  return rawAlignment;
}

function _findSelectionsIndexById(selections, selectionId) {
  return selections.findIndex(selection => selection._id === selectionId);
}

function _findSelectionsIndexByClientSortId(selections, clientSortId) {
  return selections.findIndex(
    selection => selection.clientSortId === clientSortId
  );
}

/**
 * Slices piece of locators array (from alignment[1])
 * and shifts its logicalCharOffsets to start from zero
 * @param {Array} fullLocators array of serialized locators from alignment
 * @param {Number} from start index
 * @param {Number} to end index
 * @returns {Array} sliced locators array
 */
function sliceAndRecalculateLocators(fullLocators, from, to) {
  const cutLocators = fullLocators.slice(from, to);
  const firstLocator = Locator.deserialize(cutLocators[0]);
  const deltaOffset = firstLocator.startLocator.logicalCharOffset;

  return cutLocators.map(function(serializedLocator) {
    let locator = Locator.deserialize(serializedLocator);
    return new Locator.InTextRangeLocator(
      new Locator.InTextLocator(
        locator.startLocator.paragraphId,
        locator.startLocator.logicalCharOffset - deltaOffset
      ),
      new Locator.InTextLocator(
        locator.endLocator.paragraphId,
        locator.endLocator.logicalCharOffset - deltaOffset
      )
    ).serialize();
  });
}

function countWordsInText(text) {
  return text.split(' ').length;
}

function getSelectionsCount(items) {
  return (items || []).filter(item => {
    if (!item) {
      return false;
    }
    if (typeof item === 'string') {
      return !item.includes('section');
    }
    // todo: remove when fix invalid items of compilations in PouchDB
    if (typeof item === 'object') {
      return item.type === CompilationSelectionTypes.SELECTION;
    }
    return false;
  }).length;
}

async function getCompilationCoversList(serverUrl) {
  const emptyCoversList = [];
  try {
    const response = await Http.get(
      `${serverUrl}compilation-covers/ffa/compilationCoversList.json`
    );
    return response.data || emptyCoversList;
  } catch (error) {
    logger.warn('Error on get compilation covers list', error);
    return emptyCoversList;
  }
}
