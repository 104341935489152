class ToolbarItem {
  constructor(buildData) {
    this.id = buildData.id;
    this.name = buildData.name;
    this.controlName = buildData.controlName || '';
    this.attrs = buildData.attrs || {};
    this.customClass = buildData.customClass || '';
    this.isSerialized = process.server;
    this.isActiveFn = buildData.isActiveFn || (() => false);
    this.isHiddenFn = buildData.isHiddenFn || (() => false);
    this.isDisabledFn = buildData.isDisabledFn || (() => false);
    this.isDestroyedFn = buildData.isDestroyedFn || (() => false);
  }

  toJSON() {
    return JSON.stringify({
      id: this.id,
      name: this.name,
      controlName: this.controlName,
      attrs: this.attrs,
      customClass: this.customClass,
      isSerialized: this.isSerialized
    });
  }
}

class ToolbarItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setName(val) {
    this.name = val;
    return this;
  }

  setIsActiveFn(fn) {
    this.isActiveFn = fn;
    return this;
  }

  setIsHiddenFn(fn) {
    this.isHiddenFn = fn;
    return this;
  }

  setIsDisabledFn(fn) {
    this.isDisabledFn = fn;
    return this;
  }

  setIsDestroyedFn(fn) {
    this.isDestroyedFn = fn;
    return this;
  }

  setControlName(val) {
    this.controlName = val;
    return this;
  }

  setCustomClass(customClass) {
    this.customClass = customClass;
    return this;
  }

  setAttributes(val) {
    this.attrs = val;
    return this;
  }

  build() {
    return new ToolbarItem(this);
  }
}

function getToolbarItemBuilder() {
  return new ToolbarItemBuilder();
}

export default {
  getToolbarItemBuilder
};
