import UserSettingsDao from '../dao/UserSettingsDao';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserNotesController.js');

async function set({ userSettings, groupName }) {
  if (!userSettings || !groupName) {
    return;
  }
  const doc = await getSettingsDataOrDefault();
  doc.userSettings[groupName] = userSettings;
  doc.modifiedAt = Date.now();
  await put(doc);
}

async function put(doc) {
  try {
    await UserSettingsDao.write(doc);
  } catch (err) {
    logger.error(`Error while setting userSettings: ${err.message}`);
  }
}

async function getSettingsDataOrDefault() {
  try {
    return await UserSettingsDao.get();
  } catch (err) {
    return UserSettingsDao.getDefaultDoc();
  }
}

export default {
  POST: { set }
};
