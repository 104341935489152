export default {
  ANNOTATION: 'Annotation',
  BOOKMARK: 'Bookmark',
  BNC: 'British National Corpus words test',
  PARA_NOTE: 'ParaNote',
  QUIZ: 'Quiz',
  ESSAY_TASK: 'EssayTask',
  ESSAY: 'Essay',
  FLASHCARD: 'Flashcard',
  PARA_SUMMARY: 'ParaSummary',
  VOCABULARY: 'Vocabulary',
  NOTE_HASHTAG: 'NoteHashtag'
};
