<template functional>
  <div class="content-item">
    <div class="item-text">
      <span v-if="props.itemNumber" class="item-number">{{
        props.itemNumber
      }}</span>
      <span v-html="props.text" />
    </div>

    <span
      v-if="props.isContentSelected || props.isReadChapter"
      class="active-icon-block"
    >
      <component
        :is="injections.components.BaseSpriteIcon"
        icon-name="ico-check"
      />
    </span>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

export default {
  name: 'ContentItem',
  inject: {
    components: {
      default: {
        BaseSpriteIcon
      }
    }
  },
  props: {
    itemNumber: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    isContentSelected: {
      type: Boolean,
      required: true
    },
    isReadChapter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" src="./ContentItem.less"></style>
