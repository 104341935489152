<template>
  <Popup
    scrollable
    width="588"
    content-class="congratulation-popup"
    :header-divider="false"
    :footer-divider="false"
    :content-image="picFlyingBooks"
    :content-title="contentTitle"
    :content-message="contentMessage"
    :primary-label="$t('CongratulationPopup.popup.button')"
    :primary-footer-handler="openLibrary"
    @popupEvent="close"
  >
    <template #additional-content>
      <div v-if="isTrial && !subscription" class="content-bottom">
        {{ $t('Payment.common.readyToSub') }}
        <a @click="openSubscribe">{{
          $t('CongratulationPopup.popup.trial.subscribe')
        }}</a>
      </div>

      <div v-if="isUserFamilyGroupOwner" class="content-bottom">
        {{ $t('CongratulationPopup.popup.family.manage') }}
        <NuxtLink
          :to="subscriptionSetting"
          class="manage-family-link"
          @click.native="openFamilySettings"
          >{{ $t('CongratulationPopup.popup.family.manageBtn') }}</NuxtLink
        >
      </div>
    </template>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex';
import Utils from '@/services/utils/Utils';
import AppStateEnum from '@/enums/AppStateEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import CongratulationTypesEnum from '@/enums/CongratulationTypesEnum';
import { StripeDurationEnum } from '@shared/enums/CouponTypesEnum';
import images from '@/assets/images';

import Popup from '@/components/base/Popup/Popup.vue';

export default {
  name: PopupNamesEnum.CONGRATULATION_POPUP,
  components: {
    Popup
  },
  props: {
    popupContext: Object
  },
  data() {
    const popupContextObj = this.popupContext || {};
    return {
      popupContextObj,
      subscriptionSetting: { name: AppStateEnum.SETTINGS_PLANS },
      picFlyingBooks: images.picFlyingBooks,
      isTrial: popupContextObj.type === CongratulationTypesEnum.TRIAL,
      isSubscription:
        popupContextObj.type === CongratulationTypesEnum.SUBSCRIPTION,
      isPromoCode: popupContextObj.type === CongratulationTypesEnum.PROMO_CODE
    };
  },
  computed: {
    ...mapGetters('UserStore', [
      'getUser',
      'isFreeUser',
      'isUserFamilyGroupOwner'
    ]),
    ...mapGetters('PaymentsStore', ['subscription']),
    freeAccessEnds() {
      if (!this.isFreeUser) {
        return '';
      }
      return Utils.dateFormat(this.getUser.freeRoleExpiredAt);
    },
    contentTitle() {
      return this.isPromoCode &&
        this.subscription?.upcomingPrice !== this.subscription?.price
        ? this.$t('CongratulationPopup.popup.promoLink.success')
        : this.$t('Payment.common.fullAccess');
    },
    contentMessage() {
      if (
        this.subscription?.discount?.duration === StripeDurationEnum.FOREVER
      ) {
        return this.$t('CongratulationPopup.popup.forever');
      } else if (
        this.isPromoCode &&
        this.subscription?.upcomingPrice !== this.subscription?.price
      ) {
        return this.$t('CongratulationPopup.popup.promoLink.nextInvoice', {
          date: Utils.dateFormat(this.popupContextObj.nextInvoice)
        });
      } else if (this.isTrial) {
        return this.$t('CongratulationPopup.popup.until', {
          date: this.freeAccessEnds
        });
      } else {
        return this.$t('CongratulationPopup.popup.until', {
          date:
            this.popupContextObj.nextInvoice ?? this.popupContextObj.discountEnd
        });
      }
    }
  },
  methods: {
    openLibrary() {
      this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
      this.close();
    },
    close() {
      this.$emit('popupEvent', {
        type: PopupEventEnum.CLOSE,
        data: { name: PopupNamesEnum.CONGRATULATION_POPUP }
      });
    },
    openSubscribe() {
      this.close();
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.SALES_POPUP,
        popupContext: {}
      });
    },
    openFamilySettings() {
      this.close();
    }
  }
};
</script>

<style lang="less">
.congratulation-popup {
  .manage-family-link {
    text-decoration: none;
  }
}
</style>
