import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FilesHandlingLocalDbService.js');

import urlUtils from '@shared/utils/url';

import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';

import RequestService from '@/services/RequestService';
import { AssetsManagerConstants } from '@shared/enums/AssetsManagerConstants.mjs';

async function generatePublicationJSON(compilationId) {
  const compilation = await getFullСompilation(compilationId);
  const pubJson = generateBasePublicationJSON(compilation);
  addLocalDbAudioLink(pubJson, compilation);
  return pubJson;
}

function generateBasePublicationJSON(compilation) {
  const metaHash = compilation._rev;
  const contentHash = compilation._rev;
  const alignmentHash = compilation._rev;
  const pubJson = {
    map: {
      [AssetsManagerConstants.contentFileName]: `${contentHash}__${AssetsManagerConstants.contentFileName}`,
      [AssetsManagerConstants.bookMeta]: `${metaHash}__${AssetsManagerConstants.bookMeta}`,
      [AssetsManagerConstants.alignmentFileName]: `${alignmentHash}__${AssetsManagerConstants.alignmentFileName}`
    },
    assets: {
      audio: [],
      alignment: [AssetsManagerConstants.alignmentFileName],
      content: [
        AssetsManagerConstants.contentFileName,
        AssetsManagerConstants.bookMeta
      ],
      cover: [],
      image: []
    },
    type: PublicationsTypesEnum.COMPILATION,
    bookSize: 0,
    mediaSize: 0,
    version: compilation._rev,
    generation: 1,
    date: getVersionTimeFormat(compilation.modifiedAt)
  };

  return pubJson;
}

function addLocalDbAudioLink(pubJson, compilation) {
  compilation.selections.forEach(function(selection, index) {
    if (!selection.audio) {
      return;
    }
    const parsedLink = urlUtils.parseAudioLink(selection.audio);
    if (parsedLink.fileHash.indexOf('.') === -1) {
      logger.error(
        `Fround invalid audio file name in compilation ${parsedLink.fileHash} in link ${selection.audio}`
      );
      return;
    }
    const logicalAudioFileName = getAudioFileLogicalName(
      index + 1,
      parsedLink.audioExt
    );
    pubJson.map[logicalAudioFileName] = {
      fileHash: parsedLink.fileHash,
      externalPublicationId: parsedLink.publicationId
    };
    pubJson.assets.audio.push(logicalAudioFileName);
  });
}

function getParaIdByIndex(index) {
  return `para_${index}`;
}

function getAudioFileLogicalName(index, audioExt) {
  const paraId = getParaIdByIndex(index);
  return `${paraId}.${audioExt}`;
}

async function generateLibraryManifestJSON(compilationId) {
  const pubJson = await generatePublicationJSON(compilationId);
  return Promise.resolve({
    date: pubJson.date,
    files: [
      pubJson.map[AssetsManagerConstants.contentFileName],
      pubJson.map[AssetsManagerConstants.bookMeta],
      pubJson.map[AssetsManagerConstants.alignmentFileName]
    ],
    size: 0,
    version: pubJson.version
  });
}

async function readContent(compilationId) {
  const compilation = await getFullСompilation(compilationId);
  return createContent(compilation);
}

async function readAlignment(compilationId) {
  const compilation = await getFullСompilation(compilationId);
  return createAlignment(compilation);
}

function createContent(compilation) {
  return compilation.selections;
}

function createAlignment(compilation) {
  const alignment = [];
  const selections = compilation.selections;
  let selection;
  for (let i = 0; i < selections.length; i++) {
    selection = selections[i];
    if (
      selection.type !== CompilationSelectionTypes.SECTION &&
      selection.audio &&
      selection.audio.length
    ) {
      alignment.push({
        alignment: selection.alignment,
        paraId: getParaIdByIndex(i + 1)
      });
    }
  }
  return alignment;
}

async function readMeta(compilationId) {
  const compilation = await getFullСompilation(compilationId);
  const compilationMeta = createCompilationMeta(compilation);
  return compilationMeta;
}

async function getFullСompilation(compilationId) {
  const compilation = await getCompilationById(compilationId);
  const selections = await getSelectionsByIds(compilation.items);
  compilation.selections = selections;
  return compilation;
}

function createCompilationMeta(compilation) {
  compilation.audio = _hasAudioInSelections(compilation.selections);
  delete compilation.selections;
  return compilation;
}

function getVersionTimeFormat(timeMs) {
  return new Date(timeMs)
    .toISOString()
    .replace(/T/, ' ')
    .replace(/\..+/, '')
    .replace(/:\d+$/, '');
}

function getCompilationById(compilationId) {
  return RequestService.request(
    'get',
    'Compilations',
    'getCompilationById',
    compilationId
  ).then(({ data }) => {
    return data;
  });
}

function getSelectionsByIds(selectionsIds) {
  return RequestService.request('get', 'Compilations', 'getSelectionsByIds', {
    selectionsIds
  }).then(({ data }) => {
    return data;
  });
}

function _hasAudioInSelections(selections) {
  return selections.some(
    selection =>
      selection.audio &&
      selection.audio.length &&
      selection.alignment &&
      selection.alignment[0].length !== 0
  );
}

export default {
  generatePublicationJSON,
  generateLibraryManifestJSON,
  readContent,
  readAlignment,
  readMeta
};
