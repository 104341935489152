<template>
  <nav
    ref="appToolbar"
    class="application-toolbar"
    :class="{
      disabled: disabled,
      'toolbar-invisible': !toolbarVisible,
      '-search-page': isSearchPage,
      '-search-results-initialized': searchPageResultsInitialized,
      '-segment-scrolled-down': isSegmentScrollDown
    }"
  >
    <div class="application-toolbar-content" :class="toolbarCustomClass">
      <div
        v-for="(toolbarSection, index) in toolbarItems"
        :key="index"
        class="toolbar-item-wrapper"
      >
        <div
          v-for="toolbarItem in toolbarSection"
          :key="toolbarItem.id"
          class="toolbar-item"
          :class="[
            {
              active: toolbarItem.isActiveFn(displayContext),
              disabled: toolbarItem.isDisabledFn(displayContext)
            },
            toolbarItem.customClass
          ]"
        >
          <component :is="toolbarItem.controlName" v-bind="toolbarItem.attrs" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LoggerFactory from '@/services/utils/LoggerFactory';

const logger = LoggerFactory.getLogger('ApplicationToolbar.vue');

import toolbarMixin from '@/components/mixins/toolbarMixin';

import VueErrorHelper from '@/services/utils/VueErrorHelper';

import AppStateEnum from '@/enums/AppStateEnum';
import ApplicationToolbarItemsEnum from '@/enums/ApplicationToolbarItemsEnum';

const toolbarItemsOrderByType = [
  [
    ApplicationToolbarItemsEnum.LOGO,
    ApplicationToolbarItemsEnum.APP_NAME,
    ApplicationToolbarItemsEnum.TO_LIBRARY
  ],

  [
    ApplicationToolbarItemsEnum.LIBRARY,
    ApplicationToolbarItemsEnum.COMPILATIONS,
    ApplicationToolbarItemsEnum.PRICING,
    ApplicationToolbarItemsEnum.ABOUT,
    ApplicationToolbarItemsEnum.ADVANCED_SEARCH_MID,
    ApplicationToolbarItemsEnum.RECENTLY_OPENED_LABEL
  ],

  [
    ApplicationToolbarItemsEnum.SIGN_IN,
    ApplicationToolbarItemsEnum.SIGN_UP,
    // ApplicationToolbarItemsEnum.RECENTLY_OPENED_ICON,
    ApplicationToolbarItemsEnum.LIBRARY_ICON,
    ApplicationToolbarItemsEnum.PRICING_ICON,
    ApplicationToolbarItemsEnum.COMPILATIONS_ICON,
    ApplicationToolbarItemsEnum.ABOUT_ICON,
    ApplicationToolbarItemsEnum.SEARCH_INSIDE_BOOK,
    ApplicationToolbarItemsEnum.ADVANCED_SEARCH,
    ApplicationToolbarItemsEnum.ACTIVITY_PROGRESS,
    ApplicationToolbarItemsEnum.BOOK_ICON,
    ApplicationToolbarItemsEnum.AVATAR
    // ApplicationToolbarItemsEnum.BURGER_MENU
  ]
];

const vocabularyAssessmentPageItemsOrderedByType = [
  [ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_PREVIOUS_BUTTON],
  [ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_PAGE_TITLE],
  [ApplicationToolbarItemsEnum.VOCABULARY_ASSESSMENT_BACK_BUTTON]
];

const onboardingPageItemsOrderedByType = [
  [],
  [ApplicationToolbarItemsEnum.ONBOARDING_PAGE_TITLE],
  [ApplicationToolbarItemsEnum.ONBOARDING_BACK_BUTTON]
];

const searchPageItemsOrderedByType = [
  [ApplicationToolbarItemsEnum.LIBRARY_ICON],
  [ApplicationToolbarItemsEnum.SEARCH_HEADER],
  [
    ApplicationToolbarItemsEnum.ACTIVITY_PROGRESS,
    ApplicationToolbarItemsEnum.BOOK_ICON,
    ApplicationToolbarItemsEnum.AVATAR
  ]
];

export default {
  name: 'ApplicationToolbar',
  mixins: [toolbarMixin],
  props: {
    disabled: Boolean
  },
  data() {
    return {
      customAttrs: {
        [ApplicationToolbarItemsEnum.SIGN_IN]: { color: 'primary', text: '' }
      },
      toolbarItemsOrderByType,
      vocabularyAssessmentPageItemsOrderedByType,
      onboardingPageItemsOrderedByType,
      searchPageItemsOrderedByType,
      lastScrollPosition: 0,
      isScrollingDown: false
    };
  },
  fetch() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getAssessmentPageHeader:
        'VocabularyAssessmentStore/getAssessmentPageHeader'
    }),
    ...mapState('ContextStore', ['searchPageResultsInitialized']),
    toolbarItems() {
      const items = this.mixinFilterByContext(
        this.allToolbarItems,
        this.displayContext,
        this.customAttrs
      );
      return items;
    },
    toolbarVisible() {
      return (
        this.$route.name !== AppStateEnum.PRESENT_PUBLICATION &&
        this.$route.name !== AppStateEnum.BLOG_ARTICLE
      );
    },
    isSearchPage() {
      return this.$route.name === AppStateEnum.SEARCH;
    },
    allToolbarItems() {
      switch (true) {
        case this.isSearchPage:
          return this.mixinBuildAllItems(
            this.searchPageItemsOrderedByType,
            this.customAttrs
          );
        case this.$route.name === AppStateEnum.ASSESSMENT &&
          !!this.getAssessmentPageHeader?.title:
          return this.mixinBuildAllItems(
            this.vocabularyAssessmentPageItemsOrderedByType,
            this.customAttrs
          );
        case this.$route.name === AppStateEnum.ONBOARDING:
          return this.mixinBuildAllItems(
            this.onboardingPageItemsOrderedByType,
            this.customAttrs
          );
        default:
          return this.mixinBuildAllItems(
            this.toolbarItemsOrderByType,
            this.customAttrs
          );
      }
    },
    toolbarCustomClass() {
      switch (true) {
        case this.isSearchPage:
          return '-search';
        case this.$route.name === AppStateEnum.ASSESSMENT &&
          !!this.getAssessmentPageHeader?.title:
          return '-assessment';
        case this.$route.name === AppStateEnum.ONBOARDING:
          return '-onboarding';
        case this.$route.name === AppStateEnum.PRESENT_PUBLICATION:
          return '-publication';
        default:
          return '';
      }
    },
    isSegmentScrollDown() {
      return (
        this.$store.getters['ContextStore/getSearchSegmentScroll']
          ?.direction === 'down'
      );
    }
  },
  errorCaptured(error, vm) {
    const trace = VueErrorHelper.generateComponentTrace(vm);
    this.errorHandler(`${error}\n${trace}`);
  },
  mounted() {
    this.addTouchEndEventHandler();
    this.init();
  },
  destroyed() {
    this.removeTouchEndEventHandler();
  },
  methods: {
    init() {
      this.$store.dispatch('ContextStore/toolbarLoaded');
    },

    errorHandler(error) {
      logger.fatal(
        `Error occurred in ApplicationToolbar: ${error.stack || error}`
      );
      this.showErrorToaster();
    },
    showErrorToaster() {
      const text = this.$t('Compilation.editErrorToaster.message');
      this.$store.dispatch('ManagePopupStore/openErrorToaster', { text });
    },

    addTouchEndEventHandler() {
      const appToolbar = this.$refs.appToolbar;
      if (!appToolbar) {
        return;
      }
      appToolbar.addEventListener('touchend', this.$_onTouchEnd, false);
    },
    removeTouchEndEventHandler() {
      const appToolbar = this.$refs.appToolbar;
      if (!appToolbar) {
        return;
      }
      appToolbar.removeEventListener('touchend', this.$_onTouchEnd, false);
    },
    $_onTouchEnd() {
      this.$store.dispatch('PlaybackStore/pause');
    }
  }
};
</script>

<style lang="less" src="./ApplicationToolbar.less"></style>
