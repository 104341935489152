<template>
  <Popup
    v-click-outside="clickHandler"
    :width="popupWidth"
    :content-class="currentPopupClass"
    :fullscreen="false"
    hide-overlay
  >
    <template #custom-header><div></div></template>

    <template #custom-content>
      <div class="popup-content">
        <div class="color-picker">
          <BaseTextarea
            ref="categoryName"
            v-model.trim="$v.currentCategory.name.$model"
            :class="{ invalid: !nameValidation.isValid }"
            label="Title"
            :maxlength="maxCategoryNameLength"
            counter="20"
            rows="1"
            :placeholder="$t('ContextNote.myMark')"
            :disabled="popupContext.categoryToEdit.preset"
            no-resize
          />
          <div v-if="!nameValidation.isValid" class="error--text">
            <span v-if="!$v.currentCategory.name.isUniqueName">
              {{ $t('ReaderFramework.categoryNameError.label') }}
            </span>
            <span v-if="!$v.currentCategory.name.required">
              {{ $t('ReaderFramework.categoryNoNameError.label') }}
            </span>
          </div>
        </div>
        <div class="color-picker-content">
          <div
            v-for="(colorView, index) in customColors"
            :key="index"
            @mousedown.stop="selectColorByIndex(index)"
          >
            <div
              v-if="!colorView.underline"
              class="custom-color"
              :class="{ active: isActiveColor(colorView) }"
              :style="{ background: colorView.color }"
            >
              <BaseSpriteIcon
                v-if="isActiveColor(colorView)"
                icon-name="ico-check"
              />
            </div>
            <div
              v-else
              class="custom-color color-picker-character"
              :class="{ active: isActiveColor(colorView) }"
            >
              <span>Abc</span>
              <i
                class="underline-block"
                :style="{ background: colorView.color }"
              />
              <div class="active-shadow">
                <BaseSpriteIcon
                  v-if="isActiveColor(colorView)"
                  icon-name="ico-check"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #custom-footer>
      <v-card-actions class="popup-footer">
        <button class="btn-secondary">
          <span class="btn-name" @click="cancelCreateCategory">
            {{ $t('Cancel') }}
          </span>
        </button>
        <button class="btn-primary">
          <span class="btn-name" @click="processSavingCategory">
            {{ $t('Save') }}
          </span>
        </button>
      </v-card-actions>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/base/Popup/Popup.vue';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CategoryPopup.vue');

import { required } from 'vuelidate/lib/validators';
import isEmpty from 'lodash/isEmpty';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';

import BaseTextarea from '@/components/base/BaseTextarea/BaseTextarea';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PromiseUtil from '@/services/utils/PromiseUtil';

class ColorView {
  constructor(color, colorIndex, underline) {
    this.color = color;
    this.colorIndex = colorIndex;
    this.underline = underline || false;
  }

  isEqualColor(currentCategory) {
    return (
      (this.color === currentCategory.color ||
        this.color === currentCategory.nightColor) &&
      this.underline === currentCategory.underline
    );
  }
}

export default {
  name: PopupNamesEnum.CREATE_CATEGORY,
  components: { Popup, BaseTextarea, BaseSpriteIcon },
  data() {
    return {
      colorMap: {
        defaultThemeTemplate: [
          '#fefe3c',
          '#b9e7ff',
          '#b8e5cb',
          '#ffd6e8',
          '#ffcdd2',
          '#f8bbD0',
          '#e1bee7',
          '#c8c7f4',
          '#cae2ff',
          '#a9d4fb'
        ],
        nightThemeTemplate: [
          '#8b640e',
          '#1f709a',
          '#366d40',
          '#8c3d60',
          '#9a3741',
          '#963355',
          '#703c79',
          '#5c5ba1',
          '#3d4c80',
          '#0f4b90'
        ]
      },
      currentPopupClass: 'category-popup',
      customColors: [],
      customUnderlineColors: [],
      currentCategory: {
        id: '',
        name: '',
        color: '',
        nightColor: '',
        underline: false
      },
      nameValidation: {
        isValid: true,
        errorMessage: 'ReaderFramework.categoryNoNameError.label'
      },
      maxCategoryNameLength: AppConstantsUtil.MAX_CATEGORY_NAME_LENGTH,
      defaultWidth: AppConstantsUtil.SMALL_POPUP_NARROW_WIDTH,
      preventPopupClosing: false,
      subscribeMutation: null,
      popup: null
    };
  },
  validations() {
    return {
      currentCategory: {
        name: {
          required,
          isUniqueName: this.$_isUniqueName
        }
      }
    };
  },
  computed: {
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.CREATE_CATEGORY
      );
    },
    categories() {
      return this.$store.getters['CategoriesStore/getShownCategories'];
    },
    popupWidth() {
      const element = document.querySelector(
        `.${this.popupContext?.attachedToElClass}`
      );
      return element?.getBoundingClientRect().width || this.defaultWidth;
    }
  },
  async mounted() {
    this.subscribe();
    this.getCustomColors();
    this.prepareCategory();
    await this.$nextTick();
    this.popup = document.querySelector(`.${this.currentPopupClass}`);
    if (this.popup) {
      this.popup.style['opacity'] = `0`;
    }
    this.setCorrectPosition();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    subscribe() {
      this.subscribeMutation = this.$store.subscribe(({ type }) => {
        switch (type) {
          case 'MediaDetectorStore/setMedia':
            this.setCorrectPosition();
            break;
        }
      });
    },
    unsubscribe() {
      this.subscribeMutation = null;
    },
    clickHandler(e) {
      if (e?.target?.closest(`.${this.currentPopupClass}`)) {
        return;
      }
      this.preventPopupClosing = false;
      this.close();
    },
    async setCorrectPosition() {
      await PromiseUtil.wait(200);
      const filterEl = document.querySelector(
        `.${this.popupContext.attachedToElClass}`
      );
      if (!this.popup || !filterEl) {
        return;
      }
      const { bottom, height, left, width } = filterEl.getBoundingClientRect();
      const popupBottom = window.innerHeight - bottom + height;

      this.popup.style['bottom'] = `${popupBottom}px`;
      this.popup.style['left'] = `${left}px`;
      this.popup.style['width'] = `${width}px`;
      this.popup.style['opacity'] = `1`;
    },
    close() {
      if (this.preventPopupClosing && !this.nameValidation.isValid) {
        this.preventPopupClosing = false;
        return;
      }

      if (this.popupContext?.parentPopup) {
        this.$store.dispatch(
          'ManagePopupStore/unhidePopup',
          this.popupContext.parentPopup
        );
      }

      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.CREATE_CATEGORY
      });
    },
    isActiveColor(colorView) {
      return colorView.isEqualColor(this.currentCategory);
    },
    selectColorByIndex(index) {
      const selectedColor = this.customColors[index];
      this.currentCategory = {
        ...this.currentCategory,
        underline: selectedColor.underline,
        color: this.colorMap.defaultThemeTemplate[selectedColor.colorIndex],
        nightColor: this.colorMap.nightThemeTemplate[selectedColor.colorIndex]
      };
    },
    onInput() {
      this.$_resetLazyValidation();
    },
    cancelCreateCategory() {
      this.preventPopupClosing = false;
      this.close();
    },
    async processSavingCategory() {
      this.$_resetLazyValidation();
      if (!this.$_isValidOnAdding(this.$v.currentCategory)) {
        this.preventPopupClosing = true;
        return;
      }

      const categoryBuilder = MaterialsFactory.createCategoryBuilder();
      const category = categoryBuilder
        .setId(this.currentCategory.id)
        .setName(this.currentCategory.name)
        .setUnderline(this.currentCategory.underline)
        .setColor(this.currentCategory.color)
        .setDefault(this.currentCategory.isDefault)
        .setNightColor(this.currentCategory.nightColor)
        .setModifiedAt(Date.now());

      try {
        if (this.popupContext.isEditMode) {
          await this.$_changeCategory(category);
        } else {
          await this.$_addCategory(category);
        }

        this.close();
      } catch (error) {
        this.$_errorHandler(error);
      }
    },
    async $_changeCategory(category) {
      try {
        if (this.popupContext.categoryToEdit.version) {
          category.setVersion(this.popupContext.categoryToEdit.version + 1);
        }
        const categoryLabel = this.$store.getters[
          'CategoriesStore/getCategoryLabelById'
        ](category.id);
        const newLabel =
          categoryLabel === category.name ? categoryLabel : category.name;
        category = category
          .setId(this.popupContext.categoryToEdit.id)
          .setLabel(newLabel)
          .build();
        await this.$store.dispatch('CategoriesStore/changeCategory', {
          category
        });
      } catch (error) {
        this.$_errorHandler(error);
      }
    },
    async $_addCategory(category) {
      try {
        const categoryId = this.$store.getters[
          'CategoriesStore/getNewCategoryId'
        ];
        const categoryLabel = this.$store.getters[
          'CategoriesStore/getCategoryLabelById'
        ](categoryId);
        category = category
          .setId(categoryId)
          .setLabel(categoryLabel || category.name)
          .build();
        await this.$store.dispatch('CategoriesStore/addCategory', {
          category
        });
      } catch (error) {
        this.$_errorHandler(error);
      }
    },
    prepareCategory() {
      const openedCategory = this.popupContext.categoryToEdit;
      if (isEmpty(openedCategory)) {
        this.currentCategory.color = this.colorMap.defaultThemeTemplate[0];
        this.currentCategory.nightColor = this.colorMap.nightThemeTemplate[0];
        return;
      }
      this.currentCategory = {
        id: openedCategory.id,
        color: openedCategory.color,
        name: openedCategory.name,
        underline: !!openedCategory.underline,
        nightColor: openedCategory.nightColor,
        isDefault: openedCategory.isDefault
      };
    },
    $_isUniqueName(category) {
      return (
        this.popupContext.isEditMode ||
        !this.categories.some(cat => cat.name === category)
      );
    },
    $_isValidOnAdding(validationInfo) {
      if (validationInfo.$invalid) {
        this.nameValidation.isValid = false;
        return false;
      }
      return true;
    },
    $_errorHandler(error) {
      logger.error(`Error in CategoryPopup: ${error.stack}`);
      this.preventPopupClosing = false;
      this.close();
    },
    $_resetLazyValidation() {
      this.nameValidation.isValid = true;
    },
    $_isNightMode() {
      return (
        this.$store.getters['ReadingSettingsStore/getThemeName'] ===
        ThemeClassNamesEnum.NIGHT
      );
    },
    getCustomColors() {
      const colorPalette = this.$_isNightMode()
        ? this.colorMap.nightThemeTemplate
        : this.colorMap.defaultThemeTemplate;
      const customColors = colorPalette.map(
        (color, index) => new ColorView(color, index)
      );
      const customUnderlineColors = colorPalette
        .slice(0, 5)
        .map((color, index) => new ColorView(color, index, true));
      this.customColors = [...customColors, ...customUnderlineColors];
    }
  }
};
</script>

<style lang="less" src="./CategoryPopup.less" />
