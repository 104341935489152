<template>
  <div class="text-field-wrapper">
    <div
      v-if="modifiedAttributes.labelName || modifiedAttributes.counter"
      class="text-field-title pb-1"
    >
      <label :for="modifiedAttributes.labelName"
        ><span>{{ modifiedAttributes.label }}</span></label
      >
      <div v-if="modifiedAttributes.counter" class="text-field-counter">
        {{ inputTextLength }} / {{ modifiedAttributes.counter }}
      </div>
    </div>
    <v-text-field
      v-bind="computedAttributes"
      :name="modifiedAttributes.labelName"
      v-on="inputListeners"
    >
      <!-- Dynamically inherit slots from parent -->
      <template v-for="slot in parentSlots" #[slot]>
        <slot :name="slot" :props="props" />
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { ModifiedAttributes } from '@/classes/factories/BaseComponentsFactory';

export default {
  name: 'BaseTextField',
  props: {
    props: {
      type: Object,
      required: false,
      default: () => ({})
    },
    trim: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      defaultAttributes: {
        'single-line': true,
        outlined: true,
        dense: true
      },
      modifiedAttributes: new ModifiedAttributes()
    };
  },
  computed: {
    computedAttributes() {
      const incomingAttrs = this.$attrs;

      if (incomingAttrs?.label) {
        this.modifiedAttributes.setLabel(incomingAttrs.label);
        this.modifiedAttributes.setLabelName(incomingAttrs.label);
        delete incomingAttrs.label;
      }

      if (incomingAttrs?.counter) {
        this.modifiedAttributes.setCounter(incomingAttrs.counter);
        delete incomingAttrs.counter;
      }

      return {
        ...this.defaultAttributes,
        ...incomingAttrs
      };
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: value => {
          const trimVal =
            this.trim && typeof value === 'string' ? value.trim() : value;
          this.$emit('input', trimVal);
        }
      });
    },
    parentSlots() {
      return Object.keys(this.$slots);
    },
    inputTextLength() {
      return this.computedAttributes.value.length;
    }
  }
};
</script>

<style lang="less" src="./BaseTextField.less"></style>
