import i18n from '@/i18n';
import dayJS from '@/dayJS';
import { StripeDurationEnum } from '@shared/enums/CouponTypesEnum';
import {
  GroupTypesEnum,
  TypesEnum,
  StatusesEnum
} from '@shared/enums/SubscriptionTypesEnum';
import PaymentsService from '@/services/PaymentsService';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import LoginStatesEnum from '@/enums/LoginStatesEnum';
import ManagePublicationsStatesEnum from '@/enums/ManagePublicationsStatesEnum';

const SHOW_ACTION_POPUP_PERIOD = 2 * 60 * 60 * 1000;
const PAYMENTS = 'payments';

export default {
  name: 'PaymentsStore',
  state: () => ({
    timerId: null,
    promoCode: null,
    originalPromoCode: null
  }),
  getters: {
    subscription(state, getters, rootState, rootGetters) {
      return rootGetters['UserStore/getSubscription'];
    },
    isSubscriptionActive(state, getters, rootState, rootGetters) {
      return rootGetters['UserStore/isSubscriptionActive'];
    },
    isFreePromoCode(_, getters) {
      return getters.isSubscriptionActive && getters.subscription.price === 0;
    },
    isSalesPopupAllowed(state, getters, rootState, rootGetters) {
      const isReaderApp = rootGetters['ContextStore/isReaderApp'];
      const isSubscriptionActive =
        rootGetters['UserStore/isSubscriptionActive'];
      const isPurchaseAvailable =
        rootGetters['ContextStore/isPurchaseAvailable'];

      return isPurchaseAvailable && isReaderApp && !isSubscriptionActive;
    },
    isUserActionRequired(state, getters, rootState, rootGetters) {
      return (
        rootGetters['ContextStore/isPurchaseAvailable'] &&
        (getters.isSubscriptionActive ||
          getters.subscription?.status === StatusesEnum.INCOMPLETE) &&
        getters.subscription?.paymentError
      );
    },
    getPromoCode(state) {
      return state.promoCode;
    },
    getOriginalPromoCode(state) {
      return state.originalPromoCode;
    }
  },
  mutations: {
    fillPromoCode(state) {
      state.promoCode = UnifiedSettingsService.getSetting(
        PAYMENTS,
        'promoCode'
      );
    },
    setTimerId(state, id) {
      state.timerId = id;
    },
    setPromoCode(state, promoCode) {
      state.promoCode = promoCode;
      UnifiedSettingsService.setSetting(PAYMENTS, 'promoCode', promoCode);
    },
    setOriginalPromoCode(state, promoCode) {
      state.originalPromoCode = promoCode;
    }
  },
  actions: {
    checkout(
      { rootGetters, rootState },
      { setId, type, groupType, promoCode }
    ) {
      const runId = rootGetters['UserStore/getRunId'];
      const context = rootState.ContextStore.Context;
      const language = rootGetters['ContextStore/getSystemLanguage'];
      PaymentsService.checkout({
        context,
        runId,
        setId,
        type,
        groupType,
        promoCode,
        language
      });
    },
    initPaymentForm({ rootState, rootGetters }, { elementId, eventHandler }) {
      if (!rootGetters['ContextStore/isPurchaseAvailable']) {
        return;
      }
      const context = rootState.ContextStore.Context;
      const language = rootGetters['ContextStore/getSystemLanguage'];
      return PaymentsService.initPaymentForm(
        context,
        elementId,
        eventHandler,
        language
      );
    },
    togglePaymentForm(context, { isActive, elementId }) {
      return PaymentsService.togglePaymentForm(isActive, elementId);
    },
    subscribe(
      { rootGetters },
      { setId, type, groupType, promoCode, email, elementId, noPaymentMethod }
    ) {
      const user = rootGetters['UserStore/getUser'];
      return PaymentsService.subscribe(user, elementId, noPaymentMethod, {
        setId,
        type,
        email,
        groupType,
        promoCode
      });
    },
    calcDiscount(context, { setId, promoCode }) {
      return PaymentsService.calcDiscount(setId, promoCode);
    },
    redirectToPortal({ rootState, rootGetters }) {
      const context = rootState.ContextStore.Context;
      const isElectron = rootGetters['ContextStore/isElectron'];
      return PaymentsService.redirectToPortal(context, isElectron);
    },
    getCards() {
      return PaymentsService.getCards();
    },
    getPaymentHistory() {
      return PaymentsService.getPaymentHistory();
    },
    openPaymentActionPopupIfNeed({ dispatch, getters }) {
      const paymentActionPopupShowedAt =
        UnifiedSettingsService.getSetting(
          'session',
          'paymentActionPopupShowedAt'
        ) || 0;
      if (
        !getters.isUserActionRequired ||
        paymentActionPopupShowedAt + SHOW_ACTION_POPUP_PERIOD > Date.now()
      ) {
        return;
      }
      dispatch('PlaybackStore/pause', null, {
        root: true
      });
      dispatch(
        'ManagePopupStore/openPopup',
        {
          name: PopupNamesEnum.PAYMENT_ACTION_POPUP,
          popupContext: {
            paymentError: getters.subscription.paymentError
          }
        },
        {
          root: true
        }
      );
      UnifiedSettingsService.setSetting(
        'session',
        'paymentActionPopupShowedAt',
        Date.now()
      );
    },
    async processInvoice({ dispatch, rootState, rootGetters }, id) {
      const context = rootState.ContextStore.Context;
      const language = rootGetters['ContextStore/getSystemLanguage'];
      await PaymentsService.processInvoiceCustomerAction(context, id, language);
      UnifiedSettingsService.setSetting(
        'session',
        'paymentActionPopupShowedAt',
        0
      );
      await dispatch('UserStore/getUserProfile', null, { root: true });
    },
    processGuestEvent({ dispatch, rootGetters }) {
      if (rootGetters['ContextStore/isPurchaseEnabled']) {
        dispatch('openSubscribePopup');
      } else {
        dispatch(
          'ManagePopupStore/openPopup',
          {
            name: PopupNamesEnum.CONNECT,
            popupContext: {}
          },
          { root: true }
        );
      }
    },
    wrappWithGuestLogic({ dispatch, rootGetters }, { handler }) {
      if (rootGetters['UserStore/isGuestUser']) {
        dispatch('processGuestEvent');
      } else {
        if (typeof handler === 'function') {
          handler();
        }
      }
    },
    async openSubscribePopup(
      { dispatch, getters, rootGetters },
      salePopupContext = null
    ) {
      if (
        !getters.isSalesPopupAllowed ||
        process.server ||
        rootGetters['ContextStore/isViewMode']
      ) {
        return;
      }
      const popupToOpen = rootGetters['UserStore/isGuestUser']
        ? {
            name: PopupNamesEnum.CONNECT,
            popupContext: { initState: LoginStatesEnum.REGISTRATION }
          }
        : { name: PopupNamesEnum.SALES_POPUP, popupContext: salePopupContext };

      dispatch('ManagePopupStore/openPopup', popupToOpen, { root: true });
    },
    async openSalesPopup({ dispatch, getters }) {
      await dispatch('UserStore/getUserProfile', null, { root: true });
      if (getters.isSalesPopupAllowed) {
        dispatch(
          'ManagePopupStore/openPopup',
          {
            name: PopupNamesEnum.SALES_POPUP,
            popupContext: null
          },
          { root: true }
        );
      }
    },
    checkSubscriptionOffline({ dispatch, commit, getters }) {
      if (!getters.isSubscriptionActive) {
        return;
      }
      const subscription = getters.subscription;
      const now = Date.now();
      const gracePeriod = process.env.IS_PROD
        ? AppConstantsUtil.DAY_IN_MS * 7
        : 300000;
      const showPopupAt = subscription.periodEnd + gracePeriod;
      if (now > showPopupAt) {
        dispatch(
          'ManagePopupStore/openPopup',
          {
            name: PopupNamesEnum.PAYMENT_OFFLINE_POPUP,
            popupContext: {
              closeCallback: () => dispatch('logoutFreeUserOnDevice')
            }
          },
          {
            root: true
          }
        );
        commit('UserStore/deactivateSubscription', null, {
          root: true
        });
      }
    },
    startWatcher({ commit, dispatch, getters }) {
      if (!getters.isSubscriptionActive) {
        return;
      }
      const watcher = () => {
        const id = setTimeout(() => {
          dispatch('checkSubscriptionOffline');
          watcher();
        }, AppConstantsUtil.CHECK_SUBSCRIPTION_INTERVAL);
        commit('setTimerId', id);
      };
      watcher();
    },
    stopWatcher({ state, commit, dispatch }) {
      clearTimeout(state.timerId);
      dispatch(
        'ManagePopupStore/closePopup',
        {
          name: PopupNamesEnum.PAYMENT_OFFLINE_POPUP,
          popupContext: null
        },
        {
          root: true
        }
      );
      commit('setTimerId', null);
    },
    async logoutFreeUserOnDevice({ getters, rootGetters, dispatch }) {
      if (
        rootGetters['ContextStore/isPurchaseEnabled'] &&
        rootGetters['ContextStore/isDevice'] &&
        !getters.isSubscriptionActive &&
        !rootGetters['UserStore/isFreeUser']
      ) {
        await dispatch('UserStore/logout', null, { root: true });
        return true;
      }
    },
    openAccountDashboard() {
      return PaymentsService.openAccountDashboard();
    },
    async applyPromoCode(
      { dispatch, getters, rootGetters, commit },
      isRegistered = false
    ) {
      const promoCode = getters.getPromoCode;
      if (
        !promoCode?.active ||
        (promoCode.expiresAt !== 0 && Date.now() > promoCode.expiresAt)
      ) {
        commit('setPromoCode', null);
        if (isRegistered) {
          dispatch('ManagePopupStore/openCongratulationPopup', null, {
            root: true
          });
        }
        return;
      }
      const isGuest = rootGetters['UserStore/isGuestUser'];
      if (!isGuest) {
        await dispatch('UserStore/getUserProfile', null, { root: true });
      }
      if (rootGetters['UserStore/isUserFamilyGroupMember']) {
        return;
      }
      const promoCodeData = await dispatch('getPromoCodeInfo', promoCode.code);
      const user = rootGetters['UserStore/getUser'];
      const promoEndsAt = dayJS
        .get()
        .add(promoCodeData.durationMonths || 1, 'months')
        .valueOf();
      if (
        promoCodeData.duration !== StripeDurationEnum.FOREVER &&
        promoCodeData.percentOff === 100 &&
        user.freeRoleExpiredAt > promoEndsAt
      ) {
        dispatch(
          'ManagePopupStore/openErrorToaster',
          { text: i18n.localize('Payment.common.errorToaster') },
          { root: true }
        );
        return;
      }
      const currentSubscription = rootGetters['UserStore/getSubscription'];
      let set = rootGetters['LibraryStore/getLibrarySet'];
      if (!set) {
        await dispatch(
          'LibraryStore/initState',
          {
            managePublicationsState: ManagePublicationsStatesEnum.PRICING
          },
          { root: true }
        );
        set = rootGetters['LibraryStore/getLibrarySet'];
      }
      if (isGuest) {
        dispatch(
          'ManagePopupStore/openPopup',
          {
            name: PopupNamesEnum.CONNECT,
            popupContext: {
              initState: LoginStatesEnum.REGISTRATION
            }
          },
          {
            root: true
          }
        );
        return;
      } else if (rootGetters['UserStore/isSubscriptionActive']) {
        await PaymentsService.updateSubscriptionPromoCode(
          currentSubscription.subId,
          promoCode.code
        );
        commit('setPromoCode', null);
        dispatch(
          'ManagePopupStore/openPromoCongratulationPopup',
          {
            nextInvoice: dayJS.get(currentSubscription.periodEnd).format('ll')
          },
          {
            root: true
          }
        );
        return true;
      }

      const { newPrices } = await dispatch('calcDiscount', {
        setId: set.id,
        promoCode: promoCode.code
      });
      const monthly = newPrices.find(
        p =>
          p.type === TypesEnum.MONTHLY &&
          p.groupType === GroupTypesEnum.PERSONAL
      );

      if (monthly.price !== 0) {
        const closeCallBack = () =>
          dispatch('ManagePopupStore/openCongratulationPopup', null, {
            root: true
          });
        dispatch(
          'ManagePopupStore/openPopup',
          {
            name: PopupNamesEnum.SALES_POPUP,
            popupContext: {
              closeHandler:
                isRegistered && rootGetters['UserStore/isFreeUser']
                  ? closeCallBack
                  : null
            }
          },
          { root: true }
        );
        await dispatch('removeUserPromoCode');
        return;
      }
      const subscription = await dispatch('subscribe', {
        setId: set.id,
        type: currentSubscription?.type || TypesEnum.MONTHLY,
        groupType: currentSubscription?.groupType || GroupTypesEnum.PERSONAL,
        promoCode: promoCode.code,
        email:
          currentSubscription?.email || rootGetters['UserStore/getUser'].email,
        noPaymentMethod: true
      });
      await dispatch('UserStore/getUserProfile', null, { root: true });
      commit('setPromoCode', null);
      dispatch(
        'ManagePopupStore/openPromoCongratulationPopup',
        {
          discountEnd: subscription.discount?.end
            ? dayJS.get(subscription.discount.end).format('ll')
            : null
        },
        {
          root: true
        }
      );
      return true;
    },
    async getPromoCodeInfo({ commit }, code) {
      const promoCode = await PaymentsService.getPromoCodeInfo(code);
      commit('setOriginalPromoCode', code);
      if (!promoCode) {
        return;
      }
      commit('setPromoCode', promoCode);
      return promoCode;
    },
    saveUserPromoCode({ getters }) {
      const promoCode = getters.getPromoCode;
      if (promoCode) {
        return PaymentsService.saveUserPromoCode(promoCode.code);
      }
    },
    removeUserPromoCode() {
      return PaymentsService.removeUserPromoCode();
    }
  }
};
