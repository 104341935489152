function getUtcTime() {
  return new Date().toISOString();
}

function timeDurationFilter(timeInMs, localizator, labeType, hoursMark) {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let date;
  const BOUNDARY_TIME_IN_MS = 600000;
  labeType = labeType || '';
  localizator =
    localizator ||
    function(val) {
      switch (val) {
        case 'Duration.hours.short':
          return 'h';
        case 'Duration.minutes.short':
          return 'm';
        case 'Duration.hours.long':
          return 'hours';
        case 'Duration.minutes.long':
          return 'minutes';
      }
      return val;
    };
  const _format = format.bind(this);
  if (!timeInMs) {
    return _format(minutes, seconds, 'minutes');
  }
  timeInMs = parseInt(timeInMs);
  date = new Date(timeInMs);
  minutes = date.getMinutes();
  seconds = date.getSeconds();

  if (hoursMark) {
    hours = Math.floor(timeInMs / (1000 * 60 * 60));
    return _format(hours, minutes, 'hours', hoursMark);
  } else if (timeInMs <= BOUNDARY_TIME_IN_MS) {
    return _format(minutes, seconds, 'minutes');
  } else {
    hours = Math.floor(timeInMs / (1000 * 60 * 60));
    return _format(hours, minutes, 'hours');
  }
  function format(lValue, rValue, label, hMark) {
    let formattedTime;
    const localLabel = labeType
      ? ' ' + localizator('Duration.' + label + '.' + labeType)
      : '';
    if (hMark) {
      formattedTime = lValue;
    } else {
      formattedTime =
        lValue +
        ':' +
        (rValue - (rValue % 10)) / 10 +
        (rValue % 10) +
        localLabel;
    }
    return formattedTime;
  }
}

module.exports = {
  timeDurationFilter,
  getUtcTime
};
