import CompilationsDao from '@/services/Agent/dao/CompilationsDao';

export default {
  GET: {
    getCompilationById,
    getAllCompilations,
    getSelectionsByIds
  },
  POST: {
    persistCompilation,
    persistSelection,
    appendSelection,
    removeCompilation,
    removeSelection,
    copyCompilation,
    moveSelectionDown,
    moveSelectionUp,
    changeDefaultCompilation,
    changeCompilationCover,
    editSectionTitle
  }
};

function getCompilationById(compilationId) {
  return CompilationsDao.getCompilationById(compilationId);
}

function getAllCompilations() {
  return CompilationsDao.getAllCompilations();
}

function getSelectionsByIds(data) {
  return CompilationsDao.getSelectionsByIds(data.selectionsIds);
}

function persistCompilation(data) {
  return CompilationsDao.persistCompilation(data.compilation, data.selections);
}

function persistSelection(data) {
  return CompilationsDao.persistSelection(
    data.compilationId,
    data.selectionData
  );
}

function appendSelection(data) {
  return CompilationsDao.appendSelection(
    data.compilationId,
    data.selection,
    data.position
  );
}

function removeCompilation(compilationId) {
  return CompilationsDao.removeCompilation(compilationId);
}

function copyCompilation({ compilationId, coverFileName }) {
  return CompilationsDao.copyCompilation({ compilationId, coverFileName });
}

function removeSelection({ compilationId, selectionId }) {
  return CompilationsDao.removeSelection(compilationId, selectionId);
}

function moveSelectionDown({ compilationId, selectionId }) {
  return CompilationsDao.moveSelectionDown(compilationId, selectionId);
}

function moveSelectionUp({ compilationId, selectionId }) {
  return CompilationsDao.moveSelectionUp(compilationId, selectionId);
}

function changeDefaultCompilation({ compilationId, isDefault }) {
  return CompilationsDao.changeDefaultCompilation(compilationId, isDefault);
}

function changeCompilationCover({ compilationId, coverFileName }) {
  return CompilationsDao.changeCompilationCover(compilationId, coverFileName);
}

function editSectionTitle({ sectionId, title }) {
  return CompilationsDao.editSectionTitle(sectionId, title);
}
