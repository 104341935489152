import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ExternalBlogService.js');

import UserService from '@/services/UserService';
import RestService from '@/services/RestService';

import ExternalBlogTypesEnum from '@/enums/ExternalBlogTypesEnum';
import PostMessagesEnum from '@shared/enums/PostMessagesEnum';

const externalBlogController = 'ExternalBlog';

async function handleExternalBlogLogin(blogType, query) {
  try {
    await performExternalBlogLogin(blogType, query);

    if (process.client && window.opener) {
      window.opener.postMessage({
        type: PostMessagesEnum.EXTERNAL_BLOG_AUTH_WINDOW_CLOSED,
        blogType,
        query
      });
      window.close();
    }
  } catch (error) {
    logger.error(`Handle external blog login failed with ${error}`);
  }
}

async function performExternalBlogLogin(blogType, query) {
  try {
    return await RestService.restRequest(
      'post',
      externalBlogController,
      'performLogin',
      { blogType, authData: query }
    );
  } catch (error) {
    logger.error(`Login to external blog failed with ${error}`);
  }
}

function saveExternalBlogSettings(blogSettings) {
  try {
    return RestService.restRequest(
      'post',
      externalBlogController,
      'saveExternalBlogSettings',
      { blogSettings }
    );
  } catch (error) {
    logger.error(`Retrieve external blogs list failed with ${error}`);
  }
}

async function retrieveExternalBlogsList(blogType) {
  try {
    const response = await RestService.restRequest(
      'get',
      externalBlogController,
      'retrieveExternalBlogsList',
      { blogType }
    );
    return response.data;
  } catch (error) {
    logger.error(`Retrieve external blogs list failed with ${error}`);
  }
}

async function getExternalBlogSettings() {
  try {
    const response = await RestService.restRequest(
      'get',
      externalBlogController,
      'getExternalBlogSettings'
    );
    return response.data;
  } catch (error) {
    logger.error(`Get external blog settings failed with ${error}`);
  }
}

async function resetExternalBlogSettings(blogType) {
  try {
    const response = await RestService.restRequest(
      'post',
      externalBlogController,
      'resetExternalBlogSettings',
      { blogType }
    );
    return response.data;
  } catch (error) {
    logger.error(`Reset external blog settings failed with ${error}`);
  }
}

async function postToExternalBlog(blogPost) {
  try {
    const response = await RestService.restRequest(
      'post',
      externalBlogController,
      'postToExternalBlog',
      { blogPost }
    );
    return response.data;
  } catch (error) {
    logger.error(`Post to external blog failed with ${error}`);
  }
}

async function isAccessTokenActive(blogType) {
  try {
    const response = await RestService.restRequest(
      'get',
      externalBlogController,
      'isAccessTokenActive',
      { blogType }
    );
    return response.data;
  } catch (error) {
    logger.error(`Get external blog settings failed with ${error}`);
  }
}

function openExternalBlogCreatePage({ blogType, createBlogUri }) {
  try {
    if (!createBlogUri) {
      throw new Error(`URI for blogType ${blogType} not found`);
    }
    window.open(createBlogUri, '_blank', 'location=no');
  } catch (error) {
    logger.error(`Open external blog create page failed with ${error}`);
  }
}

function _getReturnAuthUri(blogType, clientReaderUrl) {
  switch (blogType) {
    case ExternalBlogTypesEnum.BLOGGER:
      return `${clientReaderUrl}bloggerAuth`;
    default:
      return `${clientReaderUrl}`;
  }
}

function getBloggerOAuthUri({ email, isSelectAccount, clientReaderUrl }) {
  const provider = ExternalBlogTypesEnum.BLOGGER;
  const returnUri = _getReturnAuthUri(
    ExternalBlogTypesEnum.BLOGGER,
    clientReaderUrl
  );
  let oauthUri = UserService.getOAuthURI(provider, returnUri);
  if (email) {
    oauthUri += `&loginHint=${email}`;
  }
  if (isSelectAccount) {
    oauthUri += `&isSelectAccount=${isSelectAccount}`;
  }
  return oauthUri;
}

export default {
  handleExternalBlogLogin,
  performExternalBlogLogin,
  saveExternalBlogSettings,
  getExternalBlogSettings,
  retrieveExternalBlogsList,
  resetExternalBlogSettings,
  postToExternalBlog,
  isAccessTokenActive,
  openExternalBlogCreatePage,
  getBloggerOAuthUri
};
