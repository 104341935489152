import Highlighter from './highlighter.mjs';
import Locator from './locator.mjs';
import LayoutUtils from './dom-utils/layout-utils.mjs';
import unidecode from '../unidecode.mjs';

import XRegExp from 'xregexp';
const RegEx = XRegExp.default || XRegExp;

const DATA_PARA_ATTR = 'data-ww';
const WORD_ELEMENT_CLASS_PREFIX = 'word-element-';
var BookUtils = {
  isAuthorInTitle(author, title, lang) {
    if (
      !title ||
      !author ||
      typeof author !== 'string' ||
      typeof title !== 'string'
    ) {
      return false;
    }
    let result = false;
    if (lang === 'en') {
      const signsRe = new RegEx('\\P{Latin}+', 'g');
      title = unidecode(title)
        .replace(signsRe, '')
        .toLowerCase();
      author = unidecode(author)
        .replace(signsRe, '')
        .toLowerCase();
      result =
        title.indexOf(author) !== -1 &&
        title.length !== 0 &&
        author.length !== 0;
    }
    return result;
  },
  /**
   * @param  {InTextRangeLocator} locator
   * @param {String} [customClass]
   * @return {Element} wordDomWrapper - Converted DOM element of locator.
   * This method for frontend only!
   */
  getWordDomWrapper: function(locator, customClass, parentEl) {
    let paraElement = {};

    if (!locator || locator instanceof Locator.PublicationStartLocator) {
      return null;
    }

    paraElement =
      parentEl?.id === locator.endLocator.prefixedParagraphId
        ? parentEl
        : (parentEl || window.document).querySelector(
            `#${locator.endLocator.prefixedParagraphId}`
          );

    if (!paraElement) {
      return null;
    }
    var className = this._getWordElementClassName(locator, customClass);
    var wordDomWrapper = paraElement.querySelector(`.${className}`);
    if (wordDomWrapper) {
      return wordDomWrapper;
    }
    Highlighter.decorateInTextRangeLocatorInPara(
      locator,
      paraElement,
      className
    );
    const wordDomWrappers = paraElement.querySelectorAll(`.${className}`) || [];
    if (wordDomWrappers.length <= 1) {
      return wordDomWrappers[0];
    }
    this._wrapSameClassElements(wordDomWrappers, className);
    return paraElement.querySelector(`.${className}`);
  },
  _wrapSameClassElements(elements, decoratorClassName) {
    const siblings = this._getSiblings(elements, decoratorClassName);
    Highlighter.wrapSiblingElements(siblings, decoratorClassName);
  },
  _getSiblings(elements, className) {
    const siblings = [];
    const parentElement = this._getParentNodeForSiblings(elements, className);
    const firstDirectChildSibling = this._getDirectChild(
      elements[0],
      parentElement
    );
    const lastDirectChildSibling = this._getDirectChild(
      elements[elements.length - 1],
      parentElement
    );
    let siblingNode = firstDirectChildSibling;
    while (siblingNode && !siblingNode.isEqualNode(lastDirectChildSibling)) {
      siblings.push(siblingNode);
      siblingNode = siblingNode.nextSibling;
    }
    siblings.push(lastDirectChildSibling);
    return siblings;
  },
  _getParentNodeForSiblings(elements, className) {
    const firstElement = elements[0];
    const elementsLength = elements.length;
    let parentElement = firstElement.parentNode;
    while (
      parentElement.querySelectorAll(`.${className}`).length !== elementsLength
    ) {
      parentElement = parentElement.parentNode;
    }
    return parentElement;
  },
  _getDirectChild(element, parentElement) {
    let directChild = element;
    while (!Array.from(parentElement.children).includes(directChild)) {
      directChild = directChild.parentNode;
    }
    return directChild;
  },
  _getWordElementClassName(locator, customClass) {
    const classPrefix = customClass || WORD_ELEMENT_CLASS_PREFIX;
    return `${classPrefix}${locator.toJSON().replace(/\./g, '-')}`;
  },
  getSerializedLocatorFromWordElement(wordElement) {
    let className;
    for (let index = 0; index < wordElement.classList.length; index++) {
      className = wordElement.classList[index];
      if (className.indexOf(WORD_ELEMENT_CLASS_PREFIX) === 0) {
        break;
      }
    }
    const serializedLocator = className
      .replace(WORD_ELEMENT_CLASS_PREFIX, '')
      .replace(/-/g, '.');
    return serializedLocator;
  },
  findWordDomWrapperByPosition(x, y) {
    const paraElement = this.findParaItemByPoint(x, y);
    if (!paraElement) {
      return null;
    }
    const locator = this._findWordLocator(paraElement, x, y);
    return this.getWordDomWrapper(locator);
  },
  findBlockIdByParaId(paraId) {
    const paraElement = document.getElementById(paraId);
    return paraElement?.dataset?.ilmid || paraId || null;
  },
  findParaItemByPoint(x, y) {
    const elements = document.elementsFromPoint(x, y);
    return elements.find(element => element.hasAttribute(DATA_PARA_ATTR));
  },
  _findWordLocator(paraElement, x, y) {
    const elements = [paraElement];
    const container = paraElement.parentNode;
    const position = LayoutUtils.findLogicalPositionByPoint(
      x,
      y,
      elements,
      container,
      {
        forceLayoutUsage: false
      }
    );

    if (position === null) {
      return null;
    }
    let isEmptyBlock = true;
    if (position.element) {
      isEmptyBlock = position.element.innerText.length === 0;
    }
    if (isEmptyBlock) {
      return null;
    }
    return LayoutUtils.convertPositionToRangeLocator(position, elements);
  },
  _isClickInRects(rects, x, y) {
    for (var i = 0; i < rects.length; ++i) {
      var r = rects[i];
      if (r.left < x && r.right > x && r.top < y && r.bottom > y) {
        return r;
      }
    }
    return false;
  },
  _createInTextRangeLocatorByOffset(paraId, start, end) {
    const paraNum = paraId.replace('para_', '');
    return Locator.deserialize([paraNum, start, end].join('.'));
  }
};

export default BookUtils;
