import AssetResourcesEnum from '@/enums/AssetResourcesEnum';

class AssetInfo {
  constructor(fileMap) {
    this.version = fileMap.version || '';
    this.date = fileMap.date || '';
    this.mediaSize = fileMap.mediaSize || 0;
    this.bookSize = fileMap.bookSize || 0;
    this.totalFiles = Object.keys(fileMap.map || {}).length;
    this.source = fileMap.source || null;
    this.isValid = Boolean(fileMap.version && fileMap.date && fileMap.source);
  }

  compareReadSources(assetInfo) {
    const equalVersions = this._isEquallVersions(assetInfo);
    const equalAssets = equalVersions && this.source === assetInfo.source;
    const currentReadPriorityHigh = this._isCurrentReadPriorityHigh(assetInfo);
    const currentAssetNewest = this._isCurrentAssetNewest(assetInfo);
    if (equalAssets) {
      return 0;
    } else if (currentReadPriorityHigh) {
      return 1;
    } else if (currentAssetNewest) {
      return 1;
    }
    return -1;
  }

  _isCurrentReadPriorityHigh(assetInfo) {
    const readPriority = this._getReadAssetPriority(assetInfo);
    const currentReadPriority = this._getReadAssetPriority(this);
    return currentReadPriority > readPriority;
  }

  compareUpdateSources(assetInfo) {
    const equalVersions = this._isEquallVersions(assetInfo);
    const equalAssets = equalVersions && this.source === assetInfo.source;
    const currentUpdatePriorityHigh = this._isCurrentUpdatePriorityyHigh(
      assetInfo
    );

    const currentAssetNewest = this._isCurrentAssetNewest(assetInfo);
    if (equalAssets) {
      return 0;
    } else if (currentUpdatePriorityHigh) {
      return 1;
    } else if (currentAssetNewest) {
      return 1;
    }
    return -1;
  }

  _isCurrentUpdatePriorityyHigh(assetInfo) {
    const updatePriority = this._getUpdateAssetPriority(assetInfo);
    const currentUpdatePriority = this._getUpdateAssetPriority(this);
    return currentUpdatePriority > updatePriority;
  }

  _isEquallVersions(assetInfo) {
    return (
      this.version !== '' &&
      assetInfo.version !== '' &&
      this.version === assetInfo.version &&
      this.date === assetInfo.date
    );
  }

  _isCurrentAssetNewest(assetInfo) {
    return (
      this.version !== assetInfo.version &&
      new Date(this.date).getTime() > new Date(assetInfo.date).getTime()
    );
  }

  _getReadAssetPriority(assetInfo) {
    switch (assetInfo.source) {
      case AssetResourcesEnum.FS:
        return 4;
      case AssetResourcesEnum.SD_CARD:
        return 3;
      case AssetResourcesEnum.LOCAL_DB:
        return 2;
      case AssetResourcesEnum.REMOTE:
        return 1;
      default:
        return 0;
    }
  }

  _getUpdateAssetPriority(assetInfo) {
    switch (assetInfo.source) {
      case AssetResourcesEnum.SD_CARD:
        return 4;
      case AssetResourcesEnum.LOCAL_DB:
        return 3;
      case AssetResourcesEnum.REMOTE:
        return 2;
      case AssetResourcesEnum.FS:
        return 1;
      default:
        return 0;
    }
  }
}

function createAssetInfo(fileMap) {
  return new AssetInfo(fileMap);
}

export default {
  createAssetInfo
};
