class Country {
  constructor(options = {}) {
    this.countryCode = options.countryCode || null;
    this.flag = options.flag || null;
    this.name = options.name || null;
    this.nativeName = options.nativeName || null;
  }
}

export { Country };
