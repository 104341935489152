<template>
  <BaseButton
    v-show="isOnline"
    v-bind="computedAttributes"
    :class="isIconDisabled ? 'disabled disabled-icon' : ''"
    @click="shareBookLink"
  >
    <BaseSpriteIcon
      custom-class="share-icon"
      icon-name="ico-share-icon"
    ></BaseSpriteIcon>
    <slot></slot>
  </BaseButton>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

export default {
  name: 'BaseShareControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    computedAttributes() {
      const attributes = { ...this.$attrs };
      return attributes;
    },
    selectedLocator() {
      return this.$store.getters['MaterialsStore/getSelectedLocator'];
    },
    isIconDisabled() {
      return this.$attrs['icon-disabled'];
    }
  },
  methods: {
    shareBookLink() {
      if (this.isIconDisabled) {
        this.$store.dispatch('ManagePopupStore/openToaster', {
          text: this.$t('ShareControl.toaster.disabled.message', {
            paraNum: AppConstantsUtil.MAX_SELECTION_PARAS_NUMBER
          }),
          toasterClose: false
        });
        return;
      }
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.SOCIAL_SHARING_POPUP,
        popupContext: { bookId: this.bookId, locator: this.selectedLocator }
      });
    }
  }
};
</script>
