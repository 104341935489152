import AssetInfo from './AssetInfo';

class Mapper {
  constructor(fileMap, publicationId) {
    this.publicationId = publicationId;
    this.fileMap = fileMap;
    this.assetInfo = AssetInfo.createAssetInfo(fileMap);
  }

  getAssetsFile(assetName) {
    return this.fileMap.assets[assetName] || [];
  }

  removeAssetFile(logicalName) {
    let targetAssetName;
    let index;
    for (const assetsName in this.fileMap.assets) {
      const assetsFiles = this.fileMap.assets[assetsName];
      index = assetsFiles.indexOf(logicalName);
      if (index !== -1) {
        targetAssetName = assetsName;
        break;
      }
    }
    if (index !== -1) {
      this.fileMap.assets[targetAssetName].splice(index, 1);
    }
  }

  getResource(fileName) {
    var resource = this.fileMap.map[fileName];
    if (!resource) {
      return null;
    }
    if (resource.hasOwnProperty('fileHash')) {
      return resource.fileHash;
    }
    return resource;
  }

  getResourceExternalPublicationId(fileName) {
    var resource = this.fileMap.map[fileName];
    if (!resource || !resource.hasOwnProperty('externalPublicationId')) {
      return '';
    }
    return resource.externalPublicationId;
  }

  getFilesMap() {
    return this.fileMap.map;
  }

  getAssetsMap() {
    return this.fileMap.assets;
  }

  getSourceName() {
    return this.assetInfo.source;
  }

  getAssetInfo() {
    return this.assetInfo;
  }
}

function createMapper(fileMap, publicationId) {
  return new Mapper(fileMap, publicationId);
}

export default {
  createMapper
};
