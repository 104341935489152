<script>
import images from '@/assets/images';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';

export default {
  name: 'CacheOfflineImages',
  functional: true,
  props: {
    productName: {
      type: String,
      default: ''
    }
  },
  render(createElement, context) {
    const productName = context.props.productName;
    const brand = context.props.brand;
    const IMAGES_URLS = [
      { src: images.emptyCover, alt: `${productName}. Empty cover` },
      { src: images.picClosedBook, alt: `${productName}. Book is closed` },
      { src: images.picSearch, alt: `${productName}. Filter` },
      {
        src:
          brand === BrandsEnum.FFA
            ? images.compilationCoverFfa
            : images.compilationCoverOcean,
        alt: `${productName}. Compilation cover`
      }
    ];
    const elementsMap = IMAGES_URLS.map(({ src, alt }) =>
      createElement('img', { attrs: { src, alt } })
    );
    return createElement('div', { style: { display: 'none' } }, elementsMap);
  }
};
</script>
