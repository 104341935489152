import find from 'lodash/find';
import each from 'lodash/each';
import clone from 'lodash/clone';
import remove from 'lodash/remove';

const context = {
  viewport: {}
};
const _layouters = [];

let _events = {};
let rafId;

function init() {}

function register(layouter) {
  if (process.server) {
    return;
  }
  const currentLayouter = find(_layouters, function(_layouter) {
    return _layouter.id === layouter.id;
  });
  if (currentLayouter) {
    return;
  }
  _layouters.push(layouter);
}

function unregister(id) {
  if (process.server) {
    return;
  }
  remove(_layouters, function(layoter) {
    return layoter.id === id;
  });
}

function layout(event) {
  _events[event || '?'] = true;
  _layout();
}

function _layout() {
  if (rafId) {
    window.cancelAnimationFrame(rafId);
  }
  rafId = window.requestAnimationFrame(function() {
    const newContext = _updateContext();
    newContext.events = clone(_events);
    _events = {};

    each(_layouters, function(layouter) {
      layouter.layout(newContext);
    });
  });
}

function _updateContext() {
  context.oldViewport = {
    width: context.viewport.width || 0,
    height: context.viewport.height || 0
  };
  context.viewport.width = window.innerWidth;
  context.viewport.height = window.innerHeight;
  // context.touch = swFeatureDetector.isTouchInput();
  // context.scrollTop = swApplicationScroll.getScrollTop();
  return context;
}

const delay = 100;
let resizeTaskId = null;

if (process.client) {
  _updateContext();
  window.addEventListener('resize', function() {
    if (resizeTaskId !== null) {
      clearTimeout(resizeTaskId);
    }
    resizeTaskId = setTimeout(() => {
      resizeTaskId = null;
      _events.resizing = true;
      _layout();
    }, delay);
  });

  window.addEventListener('orientationchange', function() {
    // // http://stackoverflow.com/questions/11444838/getting-wrong-screen-height-when-trigger-orientationchange-event
    // _win.one('resize', function () {
    _events.orienting = true;
    _layout();
    // });
  });
}

// _win.on('keyboardWillShow', function () {
//    _events.keyboardDidShow = true;
//    _layout();
// });
// _win.on('keyboardDidHide', function () {
//    _events.keyboardDidHide = true;
//    _layout();
// });

// $rootScope.$watch(function () {
//    _events.digest = true;
//    _layout();
// });

export default {
  init,
  register,
  unregister,
  layout,
  context
};
