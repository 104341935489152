class TextCopyContext {
  constructor(params) {
    this.quote = '';
    this.shareLink = '';
    this.isAuthorInTitle = params.isAuthorInTitle;
    this.author = params.author;
    this.paraNum = params.paraNum;
    this.bookName = params.bookName;
  }

  setQuote(quote) {
    this.quote = quote;
  }

  setShareLink(shareLink) {
    this.shareLink = shareLink;
  }

  getCopyRepresentation() {
    const text = `${this.quote}
    
${this._getBookInfoRepresentation()}
    
${this.shareLink}`;
    const html = `${this.quote}<br><br><a href="${
      this.shareLink
    }">${this._getBookInfoRepresentation()}</a>`;
    return { text, html };
  }

  _getBookInfoRepresentation() {
    let presentation = '';
    if (!this.isAuthorInTitle) {
      presentation += `${this.author}, `;
    }
    presentation += `"${this.bookName}"`;

    if (this.paraNum) {
      presentation += `, ${this.paraNum}`;
    }
    return presentation;
  }
}

class TextCopyContextBuilder {
  setIsAuthorInTitle(isAuthorInTitle) {
    this.isAuthorInTitle = isAuthorInTitle;
    return this;
  }

  setAuthor(author) {
    this.author = author;
    return this;
  }

  setParaNum(paraNum) {
    this.paraNum = paraNum;
    return this;
  }

  setBookName(bookName) {
    this.bookName = bookName;
    return this;
  }

  build() {
    return new TextCopyContext(this);
  }
}

function createBuilder() {
  return new TextCopyContextBuilder();
}

export default {
  createBuilder
};
