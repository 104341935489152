<template>
  <div class="loginform-content reset-password-block">
    <PopupContent
      v-if="currentStep === 'ResetPassword'"
      :content-title="contentTitle"
      :content-message="contentMessage"
    >
      <template #apply-additional-block>
        <div class="loginform-fields mt-4">
          <BaseTextField
            v-model="password"
            class="app-forgotpassword-field"
            :placeholder="$t('ResetPassword.newPassword.label')"
            :type="passwordShow ? 'text' : 'password'"
            tabindex="0"
            :maxlength="passwordMaxLength"
            :error-messages="passwordValidationRule"
            :append-icon="
              passwordShow ? '$hidePasswordIcon' : '$showPasswordIcon'
            "
            autocomplete="new-password"
            @focus="onFocus"
            @input="onInput"
            @keydown.enter="resetPassword"
            @click:append="passwordShow = !passwordShow"
          >
            <template #prepend-inner>
              <BaseSpriteIcon :icon-name="'ico-lock-locked'" />
            </template>
          </BaseTextField>
          <BaseTextField
            v-model="confirmPassword"
            class="app-forgotpassword-field"
            :placeholder="$t('ResetPassword.confirm.label')"
            :type="confirmPasswordShow ? 'text' : 'password'"
            :maxlength="passwordMaxLength"
            :error-messages="confirmPasswordValidationRule"
            :append-icon="
              confirmPasswordShow ? '$hidePasswordIcon' : '$showPasswordIcon'
            "
            autocomplete="new-password"
            @focus="onFocus"
            @input="onInput"
            @keydown.enter="resetPassword"
            @click:append="confirmPasswordShow = !confirmPasswordShow"
          >
            <template #prepend-inner>
              <BaseSpriteIcon :icon-name="'ico-lock-locked'" />
            </template>
          </BaseTextField>
        </div>
      </template>
      <template #apply-additional-content>
        <BaseButton
          class="text-none"
          color="primary"
          block
          large
          :disabled="disableReset"
          @click="resetPassword()"
        >
          {{ $t('ResetPassword.button.changePassword.label') }}
        </BaseButton>
      </template>
    </PopupContent>
    <PopupContent
      v-else-if="currentStep === 'PasswordChanged'"
      :content-image="contentImage"
      :content-message="$t('App.Login.ResetPassword.success')"
    >
      <template #apply-additional-content>
        <OpenInApp />
      </template>
    </PopupContent>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import images from '@/assets/images';
import PopupContent from '@/components/base/PopupContent/PopupContent.vue';
import ResetPasswordEvent from '@/enums/ResetPasswordEvent';
import ValidationError from '@/enums/ValidationErrorMessagesEnum';
import OpenInApp from '@/components/views/OpenInApp/OpenInApp';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

import {
  passwordMaxLength,
  isWithoutSpaces,
  hasPasswordValidFormat
} from '@/services/ValidationService';
export default {
  components: {
    BaseSpriteIcon,
    BaseTextField,
    OpenInApp,
    PopupContent,
    BaseButton
  },
  props: {
    hashCode: {
      type: String,
      default: ''
    }
  },
  data() {
    let currentStep = 'ResetPassword';
    const taskConfirmationHashCode = this.hashCode;

    return {
      currentStep,
      resetPasswordValidation: {
        confirmValid: {
          isValid: true,
          errorMessage: ''
        },
        passwordValid: {
          isValid: true,
          errorMessage: ValidationError.PASSWORD_IS_INCORRECT
        }
      },
      password: '',
      taskConfirmationHashCode,
      confirmPassword: '',
      passwordMaxLength,
      passwordChangedIcon: 'ico-check',
      passwordShow: false,
      confirmPasswordShow: false,
      contentImage: images.resetPasswordImg,
      contentTitle: this.$t('ConfirmationPopup.contentTitle.createPassword'),
      contentMessage: this.$t('ConfirmationPopup.contentMessage.createPassword')
    };
  },
  computed: {
    disableReset() {
      return !this.password || !this.confirmPassword;
    },
    passwordValidationRule() {
      return !this.resetPasswordValidation.passwordValid.isValid
        ? this.$t(this.resetPasswordValidation.passwordValid.errorMessage)
        : '';
    },
    confirmPasswordValidationRule() {
      return !this.resetPasswordValidation.confirmValid.isValid
        ? this.$t(this.resetPasswordValidation.confirmValid.errorMessage)
        : '';
    }
  },
  methods: {
    onFocus() {},
    onInput() {
      this.$_resetValidation();
    },
    $_resetValidation() {
      this.resetPasswordValidation.confirmValid.isValid = true;
      this.resetPasswordValidation.passwordValid.isValid = true;
    },
    $_validatePasswordConfirmation() {
      if (
        !this.password ||
        !isWithoutSpaces(this.password) ||
        !hasPasswordValidFormat(this.password)
      ) {
        this.resetPasswordValidation.passwordValid.isValid = false;
        this.resetPasswordValidation.passwordValid.errorMessage =
          ValidationError.PASSWORD_IS_INCORRECT;
      }
      if (!this.confirmPassword || !isWithoutSpaces(this.confirmPassword)) {
        this.resetPasswordValidation.confirmValid.isValid = false;
        this.resetPasswordValidation.confirmValid.errorMessage =
          ValidationError.PASSWORD_IS_INCORRECT;
      }
      if (this.password !== this.confirmPassword) {
        this.resetPasswordValidation.confirmValid.isValid = false;
        this.resetPasswordValidation.confirmValid.errorMessage =
          ValidationError.PASSWORD_MISMATCH;
      }
    },
    async resetPassword() {
      try {
        this.$_validatePasswordConfirmation();
        if (
          !this.resetPasswordValidation.confirmValid.isValid ||
          !this.resetPasswordValidation.passwordValid.isValid
        ) {
          return;
        }
        var params = {
          password: this.password,
          taskConfirmationHashCode: this.taskConfirmationHashCode
        };

        const result = await this.$store.dispatch(
          'UserStore/resetPassword',
          params
        );

        if (result.data && result.data.status && result.data.status === 'OK') {
          this.currentStep = 'PasswordChanged';
          this.emit(ResetPasswordEvent.PASSWORD_CHANGED);
        }
      } catch (error) {
        this.emit(ResetPasswordEvent.ERROR, { error });
      }
    },
    emit(type, data) {
      this.$emit('resetPasswordEvent', {
        type,
        data
      });
    }
  }
};
</script>

<style lang="less">
// @import './LoginForgotPassword.less';
</style>
