import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('RequestService.js');

import AppModeEnum from '@/enums/AppModeEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';

import RestService from '@/services/RestService';
import AgentService from '@/services/Agent/AgentService';

const serviceContext = {
  mode: ''
};

function init(context) {
  serviceContext.mode = context.mode;
}

async function request(method, controller, action, data = {}, params = {}) {
  if (process.server) {
    return AgentService.createFakeAjaxResponse()(null);
  }
  const isAllow = await AgentService.isAllowRequest(method, controller, action);
  if (
    isAllow &&
    !params.forceOnline &&
    serviceContext.mode !== AppModeEnum.EDITOR
  ) {
    return sendLocalRequest(method, controller, action, data, params);
  } else {
    return sendRemoteRequest(method, controller, action, data, params);
  }
}

async function sendLocalRequest(method, controller, action, data, params) {
  try {
    const resp = await AgentService.localRequest(
      method,
      controller,
      action,
      data
    );
    return resp;
  } catch (error) {
    logger.error(
      `Get error on request ${method}, ${controller}, ${action}, with data: ${data} and params ${params}, error: ${error}`
    );
    if (error && error.name === 'indexed_db_went_bad') {
      window.location.reload(true);
      return;
    }
    return sendRemoteRequest(method, controller, action, data, params);
  }
}

async function sendRemoteRequest(method, controller, action, data, params) {
  try {
    const response = await RestService.restRequest(
      method,
      controller,
      action,
      data,
      params
    );
    return response;
  } catch (error) {
    if (error && error.type === CustomErrorEnum.UNSUPPORTED_REST_REQUEST) {
      if (process.client) {
        logger.warn(error.message);
      }
      return AgentService.createFakeAjaxResponse()(null);
    } else {
      logger.error(error);
      throw error;
    }
  }
}

export default {
  init,
  request
};
