// import LoggerFactory from '@/services/utils/LoggerFactory';
// const logger = LoggerFactory.getLogger('OfflineSearchStore.js');

import OfflineSearchService from '@/services/OfflineSearch/OfflineSearchService';

import OfflineSearchFactory from '@/classes/factories/OfflineSearchFactory';

const initState = () => ({
  details: []
});

// getters
const storeGetters = {
  getIndexDetailsView(state) {
    return state.details.map(detail => {
      return OfflineSearchFactory.createOfflineSearchDetailsView(detail);
    });
  },
  getSearchIds(state) {
    return state.details.map(detail => {
      return detail.searchId;
    });
  }
};

// actions
const actions = {
  async init({ commit }, initParams) {
    const { libraryLanguages } = initParams;
    const remoteDetails = await OfflineSearchService.getRemoteIndexDetails();
    commit('setDetails', {
      remoteDetails,
      libraryLanguages
    });
  }
};

// mutations
const mutations = {
  setDetails(state, payload) {
    const { remoteDetails, libraryLanguages } = payload;
    const details = [];
    const detailsArr = Object.values(remoteDetails);
    const builder = OfflineSearchFactory.getDetailsBuilder();
    libraryLanguages.forEach(lang => {
      const rawDetail = detailsArr.find(detal => detal.lang === lang) || {};
      const detail = builder
        .setBookSize(rawDetail.bookSize)
        .setEsIndexName(rawDetail.esIndexName)
        .setLang(lang)
        .setSearchId(rawDetail.searchId)
        .setVersion(rawDetail.version)
        .build();
      details.push(detail);
    });

    state.details = details;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
