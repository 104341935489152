import UserSegmentationDataService from '@/services/UserSegmentationDataService';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserDemographicDataStore.js');

const initState = () => ({
  segmentationData: {
    roles: [],
    ageRange: [],
    language: ''
  }
});

const storeGetters = {
  getUserRoles: state => state.segmentationData.roles,
  getUserAgeRange: state => state.segmentationData.ageRange,
  getUserLanguage: state => state.segmentationData.language,
  getUserSegmentationData: state => state.segmentationData
};

const actions = {
  async initUserSegmentationData({ dispatch, rootGetters, commit }) {
    await dispatch('UserStore/fetchUserSegmentationData', null, {
      root: true
    });

    const { roles, ageRange, language } = rootGetters[
      'UserStore/getUserSegmentationData'
    ];
    commit('setUserRoles', roles);
    commit('setUserAgeRange', ageRange);
    if (!language) {
      await dispatch('initUserLanguage');
    } else {
      commit('setUserLanguage', language.toLowerCase());
    }
  },
  async initUserLanguage({ commit, getters, rootGetters }) {
    const userLanguage = getters.getUserLanguage.toLowerCase();
    if (userLanguage) {
      commit('setUserLanguage', userLanguage);
      return;
    }

    let browserLanguage = 'en';

    if (process.client) {
      browserLanguage = (
        navigator.language || navigator.userLanguage
      ).substring(0, 2);
    }

    const languageObjects = rootGetters['WikiStore/translatorLanguages'];

    const userLanguageObj = languageObjects.find(
      langObj => langObj.language === browserLanguage
    ) || { language: 'en', name: 'English' };

    commit('setUserLanguage', userLanguageObj.language);
  },
  async saveOnboardData({ dispatch, getters, rootGetters }) {
    try {
      const name = rootGetters['UserStore/getTempUserData']?.name;
      const userLanguage = getters.getUserLanguage;

      await dispatch(
        'WikiStore/addTranslation',
        { targetLang: userLanguage },
        { root: true }
      );
      await dispatch('saveSegmentationData');
      await dispatch('UserStore/fetchUserSegmentationData', null, {
        root: true
      });

      if (name) {
        await dispatch(
          'UserStore/persistPersonalProfile',
          { name },
          { root: true }
        );
      }
    } catch (error) {
      const text = this.$t('Onboarding.Save.error');
      await dispatch(
        'ManagePopupStore/openErrorToaster',
        { text },
        { root: true }
      );
      logger.error(
        `Error occurred during saving user segmentation data ${error}`
      );
    }
  },
  async saveSegmentationData({ state, rootGetters }) {
    const { segmentationData } = state;
    const userId = rootGetters['UserStore/getUserId'];
    const language = segmentationData.language.toUpperCase();

    const dataToPersist = {
      ...segmentationData,
      userId,
      language
    };

    await UserSegmentationDataService.persistUserSegmentationData(
      dataToPersist
    );
  }
};

const mutations = {
  setUserRoles(state, roles) {
    state.segmentationData.roles = roles;
  },
  setUserAgeRange(state, ageRange) {
    state.segmentationData.ageRange = ageRange;
  },
  setUserLanguage(state, language) {
    state.segmentationData.language = language;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
