export const AssetsManagerConstants = {
  fileMapName: 'publication.json',
  contentsTable: 'libraryManifest.json',
  contentIndexName: 'index.json',
  contentFileName: 'content.html',
  bookMeta: 'meta.json',
  alignmentMap: 'offsetMap.json',
  alignmentFileName: 'alignment.dat',
  pauseMapFileName: 'pauseAfter.json',
  wordIdsMapFileName: 'wordIdsMap.json',
  assets: {
    CONTENT: 'content',
    COVER: 'cover',
    IMAGE: 'image',
    AUDIO: 'audio',
    ALIGNMENT: 'alignment'
  }
};
