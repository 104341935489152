class FilesHandlingInterface {
  constructor(type) {
    this.type = type;
  }
  init() {
    throw new Error('Need implement init for file handling');
  }

  isAvailable() {
    throw new Error('Need implement isAvailable for file handling');
  }

  getUnavailableError() {
    return new Error(`FileAssetDriver: ${this.type} is not available`);
  }

  createFileSourcePath() {
    throw new Error('Need implement createFileSourcePath for file handling');
  }
  readFile() {
    throw new Error('Need implement readFile for file handling');
  }
  writeFile() {
    throw new Error('Need implement writeFile for file handling');
  }

  isExist() {
    throw new Error('Need implement isExist for file handling');
  }

  removeFile() {
    throw new Error('Need implement removeFile for file handling');
  }
  getInternalMemory() {
    throw new Error('Need implement getInternalMemory for file handling');
  }
  removeAll() {
    throw new Error('Need implement removeAll for file handling');
  }

  getFileInfo() {
    throw new Error('Need implement getFileInfo for file handling');
  }
}

export { FilesHandlingInterface };
