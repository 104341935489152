'use strict';
const get = require('lodash/get');
const set = require('lodash/set');
const map = require('lodash/map');
const chain = require('lodash/chain');
/**
 * get part of speech from definition from diff dicts
 * @param  {object} definition
 * @return {string}
 */
function getPartOfSpeech(definition) {
  var learnersPos = get(definition, 'learnersTermInfo.PoS', null);
  var websterPos = get(definition, 'definition.grammar.partOfspeech', null);
  return learnersPos || websterPos || 'none';
}

/**
 * get learners definition for flash card
 * @param  {object} definition
 * @return {string}
 */
function getLearnersDef(definition) {
  var termDefinitions = get(
    definition,
    'learnersTermInfo.definitionBlock.termDefinition',
    []
  );
  var learnersDef =
    termDefinitions.find(function(termDefinition) {
      return termDefinition.definition.trim();
    }) || {};
  return learnersDef.definition || '';
}

function getShortLearnersDef(definition) {
  var termDefinitions = get(definition, 'definition', '').trim();
  return termDefinitions;
}

/**
 * get webster definition for flash card
 * @param  {object} definition
 * @return {string}
 */
function getWebsterDef(definition) {
  var definitions = get(definition, 'definitions', []);
  var websterDef =
    definitions.find(function(_definition) {
      return _definition.text;
    }) || {};
  return websterDef.text || '';
}

/**
 * get definition text
 * @param  {object} definition
 * @return {string}
 */
function getDefinitionText(definition) {
  var learnersDef = getLearnersDef(definition);
  var shortLearnersDef = getShortLearnersDef(definition);
  var websterDef = getWebsterDef(definition);
  return learnersDef || shortLearnersDef || websterDef;
}

/**
 * check definition text in definition
 * @param  {object} definition
 * @return {boolean}
 */
function hasDefinitionText(definition) {
  var firstDef = getDefinitionText(definition);
  return firstDef.length !== 0;
}

function reGroupVerbalIllustration(verbalIllustrations) {
  verbalIllustrations = chain(verbalIllustrations)
    .groupBy(function(viItem) {
      return viItem.wsgram;
    })
    .toPairs()
    .map(function(viItem) {
      return {
        wsgram: viItem[0],
        verbalIllustrations: viItem[1]
      };
    })
    .value();
  return verbalIllustrations;
}

function reGroupTermDefinitions(definition) {
  var termDefinitionsPath = 'learnersTermInfo.definitionBlock.termDefinition';
  var termDefinitions = get(definition, termDefinitionsPath, []);
  termDefinitions = map(termDefinitions, function(termDefinition) {
    termDefinition.verbalIllustration = reGroupVerbalIllustration(
      termDefinition.verbalIllustration
    );
    termDefinition.usageNote = map(termDefinition.usageNote, function(
      usageNote
    ) {
      usageNote.verbalIllustration = reGroupVerbalIllustration(
        usageNote.verbalIllustration
      );
      return usageNote;
    });

    return termDefinition;
  });
  set(definition, termDefinitionsPath, termDefinitions);
  return definition;
}

function reGroupDefinedRunOnEntrys(definition) {
  definition.definedRunOnEntrys = map(definition.definedRunOnEntrys, function(
    definedRunOnEntry
  ) {
    var termDefinitions = definedRunOnEntry.definitionBlock.termDefinition;

    termDefinitions = map(termDefinitions, function(termDefinition) {
      termDefinition.verbalIllustration = reGroupVerbalIllustration(
        termDefinition.verbalIllustration
      );
      termDefinition.usageNote = map(termDefinition.usageNote, function(
        usageNote
      ) {
        usageNote.verbalIllustration = reGroupVerbalIllustration(
          usageNote.verbalIllustration
        );
        return usageNote;
      });

      return termDefinition;
    });
    definedRunOnEntry.definitionBlock.termDefinition = termDefinitions;
    return definedRunOnEntry;
  });

  return definition;
}

function reGroupUndefinedRunOnEntrys(definition) {
  definition.undefinedRunOnEntrys = map(
    definition.undefinedRunOnEntrys,
    function(undefinedRunOnEntry) {
      undefinedRunOnEntry.verbalIllustrations = reGroupVerbalIllustration(
        undefinedRunOnEntry.verbalIllustrations
      );
      return undefinedRunOnEntry;
    }
  );
  return definition;
}

/**
 * need regroup verbal illustration by wsgram
 * @param  {object} definition
 * @return {object}
 */
function reGroupLearners(definition) {
  definition = reGroupTermDefinitions(definition);
  definition = reGroupDefinedRunOnEntrys(definition);
  definition = reGroupUndefinedRunOnEntrys(definition);

  return definition;
}

/**
 * set real propety name for short Learners dictionary
 * @param  {object} definition
 * @return {object}
 */
function remapLearnersPropety(definition) {
  var learnersTermInfo = {
    name: definition.l.n,
    PoS: definition.l.s,
    pronunciation: definition.l.p
  };
  return {
    id: definition.id,
    learnersTermInfo: learnersTermInfo,
    definition: definition.d
  };
}

/**
 * regroup some propertys
 * @param  {object} definition
 * @return {object}
 */
function reGroupProp(definition) {
  if (definition.learnersTermInfo) {
    return reGroupLearners(definition);
  }
  return definition;
}

/**
 * set real propety name for short dictionary
 * @param  {object} definition
 * @param  {object} meta
 * @return {object}
 */
function remapPropety(definition, meta) {
  if (meta.dictType === 'Short' && definition.l && definition.d) {
    return remapLearnersPropety(definition);
  }
  return definition;
}

/**
 * get term from definition reponse
 * @param  {objct} definitionsResp
 * @return {string}
 */
function getTermFromDefinitionsResp(definitionsResp) {
  return get(definitionsResp, '[0].definition[0].id', '');
}

module.exports = {
  getPartOfSpeech: getPartOfSpeech,
  hasDefinitionText: hasDefinitionText,
  reGroupProp: reGroupProp,
  remapPropety: remapPropety,
  getDefinitionText: getDefinitionText,
  getTermFromDefinitionsResp: getTermFromDefinitionsResp
};
