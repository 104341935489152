import UnifiedSettingsService from '@/services/UnifiedSettingsService';

const SETTING_KEY = 'AppSettings';

const defauldSettingsVal = {
  systemLanguage: 'en'
};

const appSettingsEnum = {
  SYSTEM_LANGUAGE: 'SystemLanguage',
  ZOOM_TYPE: 'Zoomtype'
};

function setAppSettings(settingName, value) {
  UnifiedSettingsService.setSetting(SETTING_KEY, settingName, value);
}

function getAppSettings(settingName) {
  return UnifiedSettingsService.getSetting(SETTING_KEY, settingName);
}

function getSystemLanguage() {
  return (
    getAppSettings(appSettingsEnum.SYSTEM_LANGUAGE) ||
    defauldSettingsVal.systemLanguage
  );
}

function setSystemLanguage(lang) {
  return setAppSettings(appSettingsEnum.SYSTEM_LANGUAGE, lang);
}

function getZoomType() {
  return getAppSettings(appSettingsEnum.ZOOM_TYPE);
}
function setZoomType(zoomType) {
  return setAppSettings(appSettingsEnum.ZOOM_TYPE, zoomType);
}

export default {
  getSystemLanguage,
  setSystemLanguage,
  getZoomType,
  setZoomType
};
