<template>
  <div class="onboarding-page-title">{{ title }}</div>
</template>

<script>
export default {
  name: 'OnboardingPageTitleControl',
  data() {
    return {
      title: this.$t('OnboardingPageTitle.text')
    };
  }
};
</script>

<style lang="less" scoped>
.onboarding-page-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
</style>
