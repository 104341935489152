import RestService from './RestService';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserSegmentationDataService.js');

function persistUserSegmentationData(data) {
  try {
    return RestService.restRequest(
      'post',
      'UserSegmentationData',
      'saveUserSegmentationData',
      data
    );
  } catch (error) {
    logger.error(`Persist user segmentation data failed with ${error}`);
  }
}

function getUserSegmentationData() {
  try {
    return RestService.restRequest(
      'get',
      'UserSegmentationData',
      'getUserSegmentationData',
      {}
    );
  } catch (error) {
    logger.error(`Get user segmentation data failed with ${error}`);
  }
}

export default {
  persistUserSegmentationData,
  getUserSegmentationData
};
