const PublicationsTypesEnum = require('../enums/PublicationsTypesEnum');

class RecentItem {
  constructor(updateData, now) {
    this.publicationId = updateData.publicationId;
    this.lastOpenedAt = now;
    this.publicationType = updateData.publicationType;
  }

  getId() {
    return this.publicationId;
  }
}

class StudyGuideItem extends RecentItem {
  constructor(updateData, now) {
    super(updateData, now);
    this.studyGuideId = updateData.studyGuideId;
  }

  getId() {
    return this.studyGuideId;
  }
}

class StudyClassesItem extends RecentItem {
  constructor(updateData, now) {
    super(updateData, now);
    this.studyClassId = updateData.studyClassId;
  }

  getId() {
    return this.studyClassId;
  }
}

class CompilationItem extends RecentItem {
  constructor(updateData, now) {
    super(updateData, now);
    this.compilationId = updateData.compilationId;
  }

  getId() {
    return this.compilationId;
  }
}

class RecentBooks {
  constructor(userId, buildData) {
    this._id = buildData._id;
    this.type = 'RecentBook';
    this.userId = userId;
    this.books = this.deserializeRecentItems(buildData.books || []);
    this.studyGuides = this.deserializeRecentItems(buildData.studyGuides || []);
    this.studyCourse = this.deserializeRecentItems(buildData.studyCourse || []);
    this.studyClass = this.deserializeRecentItems(buildData.studyClass || []);
    this.compilations = this.deserializeRecentItems(
      buildData.compilations || []
    );
  }

  addRecentItem(updateData) {
    const updateFn = this._insertItem;
    return this._updateItem(updateData, updateFn);
  }

  removeRecentItem(publicationData) {
    const updateFn = this._removeItem;
    return this._updateItem(publicationData, updateFn);
  }

  _updateItem(updateData, updateFn) {
    let item = createRecentItem(updateData);
    switch (updateData.publicationType) {
      case PublicationsTypesEnum.BOOK:
        updateFn(this.books, item);
        break;
      case PublicationsTypesEnum.STUDY_GUIDE:
        updateFn(this.studyGuides, item);
        break;
      case PublicationsTypesEnum.SYLLABUS:
        updateFn(this.studyCourse, item);
        break;
      case PublicationsTypesEnum.STUDY_COURSE:
        updateFn(this.studyClass, item);
        break;
      case PublicationsTypesEnum.COMPILATION:
        updateFn(this.compilations, item);
        break;
    }
    return item;
  }

  _insertItem(recentItems, item) {
    const index = recentItems.findIndex(
      recentItem => recentItem.getId() === item.getId()
    );
    if (index !== -1) {
      recentItems.splice(index, 1);
    }
    recentItems.unshift(item);
    recentItems.splice(7); //max len
  }

  _removeItem(recentItems, item) {
    const index = recentItems.findIndex(
      recentItem => recentItem.getId() === item.getId()
    );
    if (index === -1) {
      return;
    }
    recentItems.splice(index, 1);
  }

  toJSON() {
    return Object.getOwnPropertyNames(this).reduce((a, b) => {
      a[b] = this[b];
      return a;
    }, {});
  }

  deserializeRecentItems(items) {
    // convert old recent books format "type" to new "publicationType"
    // consider ability to use current factory in RecentBookStore
    return items
      .filter(item => !!item)
      .map(item =>
        createRecentItem({
          ...item,
          publicationType: item.publicationType || item.type
        })
      );
  }
}

function createRecentBook(userId, buildData) {
  return new RecentBooks(userId, buildData);
}

function createRecentItem(data) {
  let item;
  const now = Date.now();
  const lastOpenedAt = data.lastOpenedAt || now;
  switch (data.publicationType) {
    case PublicationsTypesEnum.BOOK:
      item = new RecentItem(data, lastOpenedAt);
      break;
    case PublicationsTypesEnum.STUDY_GUIDE:
      item = new StudyGuideItem(data, lastOpenedAt);
      break;
    case PublicationsTypesEnum.SYLLABUS:
      item = new RecentItem(data, lastOpenedAt);
      break;
    case PublicationsTypesEnum.STUDY_COURSE:
      item = new StudyClassesItem(data, lastOpenedAt);
      break;
    case PublicationsTypesEnum.COMPILATION:
      item = new CompilationItem(data, lastOpenedAt);
      break;
    default:
      // statements_def
      break;
  }
  return item;
}

module.exports = {
  createRecentBook,
  createRecentItem
};
