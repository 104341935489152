const CACHE_LIMIT = 1000 * 1000000; //bytes
const CACHE_CHECK_INTERVAL = 1 * 60 * 1000;

class Electron {
  getElectron() {
    return window.require('electron');
  }
  getRemote() {
    return window.require('@electron/remote');
  }
  sendMessage(message) {
    this.getElectron().ipcRenderer.send(message);
  }
  sendMainProcessMessage(message) {
    const { ipcRenderer } = this.getElectron();
    ipcRenderer.send('electron-menu-message', message);
  }
  openExternal(url) {
    return this.getElectron().shell.openExternal(url);
  }
  openExternalPopup(url, options) {
    //TODO: replace remote to https://www.electronjs.org/docs/latest/api/ipc-renderer
    const BrowserWindow = this.getRemote().BrowserWindow;
    const win = new BrowserWindow(options);
    win.loadURL(url);
    return win;
  }
  checkForUpdates() {
    this.sendMessage('check-for-updates');
  }
  provokeUpdate() {
    this.sendMessage('do-update');
  }

  //?
  iniCache() {
    const webFrame = this.getElectron().webFrame;
    window.setInterval(() => {
      const resourceUsage = webFrame.getResourceUsage();
      const totalUsage = Object.values(resourceUsage).reduce(function(
        total,
        entry
      ) {
        return total + entry.liveSize;
      },
      0);

      if (totalUsage > CACHE_LIMIT) {
        webFrame.clearCache();
      }
    }, CACHE_CHECK_INTERVAL);
  }

  attachRedirectListener(win, callback) {
    win.webContents.on('will-navigate', function() {
      callback(arguments[1]);
    });
    win.webContents.on('did-get-redirect-request', function() {
      callback(arguments[2]);
    });
  }
}

export default new Electron();
