<template>
  <RecentBookControl :title="$t('Accessibility.recentBooks.tooltip')">
    <template #recent-book>
      <div>{{ recentBookLabel }}</div>
      <BaseSpriteIcon
        :custom-class="arrowClass"
        icon-name="ico-arrow-chevron"
      />
    </template>
  </RecentBookControl>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import RecentBookControl from '@/components/controls/RecentBookControl/RecentBookControl';

import PopupNamesEnum from '@/enums/PopupNamesEnum';

export default {
  name: 'RecentBookLabelControl',
  components: {
    RecentBookControl,
    BaseSpriteIcon
  },
  data() {
    const defaultRecentBookView = this.$store.getters[
      'ContextStore/isEditorApp'
    ]
      ? `${this.$t('ProductName')} Administration`
      : this.$t('ProductName');
    return {
      defaultRecentBookView
    };
  },
  computed: {
    recentBookLabel() {
      const recentBookItem = this.$store.getters[
        'RecentBookStore/getLastRecentBookItem'
      ];
      return recentBookItem
        ? this.$_createRecentBookView(recentBookItem)
        : this.defaultRecentBookView;
    },
    arrowClass() {
      const isRecentPopupOpen = this.$store.getters[
        'ManagePopupStore/isPopupOpened'
      ](PopupNamesEnum.RECENT_BOOKS_WRAPPER);
      const svgClass = 'recent-book-ico';
      return isRecentPopupOpen ? `${svgClass} dropped` : svgClass;
    }
  },
  methods: {
    $_createRecentBookView(meta) {
      const items = [];
      if (meta.name) {
        items.push(meta.name);
      }
      if (meta.author) {
        items.push(meta.author);
      }
      return items.join(', ');
    }
  }
};
</script>

<style lang="less" src="./RecentBookLabelControl.less"></style>
