/**
 * Base locator
 *
 * @constructor
 */
class PublicationLocator {
  /**
   * @abstract
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    throw new Error(
      locator.constructor.name +
        ': comparison algorithm must be implemented by subclass'
    );
  }

  /**
   * @abstract
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareBasisTo(locator) {
    throw new Error(
      locator.constructor.name +
        ': basis comparison algorithm must be implemented by subclass'
    );
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  equals(locator) {
    return this.compareTo(locator) === 0;
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  equalsByBasis(locator) {
    return this.compareBasisTo(locator) === 0;
  }
  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  follows(locator) {
    return this.compareTo(locator) > 0;
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  precedes(locator) {
    return this.compareTo(locator) < 0;
  }
}

export default PublicationLocator;
