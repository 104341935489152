<template>
  <BaseButton
    fab
    elevation="0"
    outlined
    :title="backToTitle"
    :aria-label="backToLabel"
    class="mini-navigate-icon"
    @click="goBackFromPublication"
  >
    <BaseSpriteIcon :icon-name="backIconByCondition"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import AppStateEnum from '@/enums/AppStateEnum';

export default {
  name: 'BackFromPublicationControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  props: {
    to: Object
  },
  computed: {
    isCompilationOpen() {
      return this.$store.getters['PublicationStore/isCompilationOpen'];
    },
    backIconByCondition() {
      return this.isCompilationOpen ? 'ico-extract' : 'ico-library-outline';
    },
    backToLabel() {
      return this.$t('Accessibility.backArrow', {
        routeName: this.backTo.name
      });
    },
    backToTitle() {
      const backToName = this.isCompilationOpen ? 'compilations' : 'library';
      return this.$t(`Accessibility.backArrow.${backToName}.tooltip`);
    },
    backTo() {
      const routeName = this.isCompilationOpen
        ? AppStateEnum.MANAGE_COMPILATION
        : AppStateEnum.MANAGE_PUBLICATION;
      return { name: routeName };
    }
  },
  methods: {
    goBackFromPublication() {
      this.$router.push(this.backTo);
    }
  }
};
</script>
