<template>
  <BaseButton v-bind="computedAttributes" @click="openSignUp">
    <slot name="sign-up">Sign up</slot>
  </BaseButton>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import LoginStatesEnum from '@/enums/LoginStatesEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'SingUpControl',
  components: {
    BaseButton
  },
  data() {
    return {
      defaultAttributes: {
        color: 'primary'
      }
    };
  },
  computed: {
    computedAttributes() {
      return {
        ...this.defaultAttributes,
        ...this.$attrs
      };
    }
  },
  methods: {
    openSignUp() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CONNECT,
        popupContext: {
          initState: LoginStatesEnum.REGISTRATION
        }
      });
    }
  }
};
</script>

<style lang="less" src="./SingUpControl.less"></style>
