<template>
  <img
    v-if="isOnline && userPicture"
    class="user-pic"
    :src="userPicture"
    alt="user photo"
  />
  <BaseSpriteIcon v-else icon-name="ico-profile" custom-class="dummy-avatar" />
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

export default {
  name: 'UserAvatar',
  components: { BaseSpriteIcon },
  props: {
    useTempData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('UserStore', ['getUser', 'getUserPhoto', 'getTempUserData']),
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    isGuest() {
      return this.$store.getters['UserStore/isGuestUser'];
    },
    isServicesReady() {
      return this.$store.getters['ContextStore/isServicesReady'];
    },
    userPicture() {
      if (!this.isServicesReady || !this.isOnline) {
        return null;
      }
      let fileHash = this.getTempUserData?.photo?.fileHash;
      if (fileHash && this.useTempData) {
        return this.getUserPhoto(fileHash);
      }
      fileHash = this.getUser?.photo?.fileHash;
      if (fileHash) {
        return this.getUserPhoto(fileHash);
      }
      return null;
    }
  }
};
</script>

<style lang="less" src="./UserAvatar.less"></style>
