import AppSettingsService from '@/services/AppSettingsService';

function createCommonAppInitContext(language) {
  if (process.server) {
    return { systemLanguage: language };
  }
  const fontCheckerContainer = document.querySelector('#fontChecker');
  const spriteContainer = document.querySelector('#spriteContainer');
  const systemLanguage = AppSettingsService.getSystemLanguage();
  return {
    fontCheckerContainer,
    spriteContainer,
    systemLanguage,
    runId: null
  };
}

export default {
  createCommonAppInitContext
};
