<template>
  <span class="media-detector-common">
    <span ref="mediaDetector" class="media-detector">
      <span class="height" />
      <span class="size" />
    </span>

    <span ref="mediaDetectorLibrary" class="media-detector-library">
      <span />
      <span />
    </span>
  </span>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('MediaDetector.vue');

import LayoutManager from '@/services/utils/LayoutManager';

const MIXIN_LAYOUT_ID = 'LayoutInMixin';

export default {
  name: 'MediaDetector',
  components: {},

  props: {},
  data() {
    return {
      element: null,
      elementLibrary: null,
      layoutId: null,
      previousScreenHeight: 0,
      isRotate: false,
      isKeyboardMayBeOpen: false
    };
  },
  async mounted() {
    await this.$nextTick();
    this.previousScreenHeight = window.innerHeight;
    this.element = this.$refs.mediaDetector;
    this.elementLibrary = this.$refs.mediaDetectorLibrary;
    this.layoutId = MIXIN_LAYOUT_ID;
    this.$_checkSize();
    this.subscribeOnLayoutInMixin(this.layoutId);

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange
    );
    this.unsubscribeOnLayoutInMixin(this.layoutId);
  },
  methods: {
    handleOrientationChange() {
      this.isRotate = true;
    },
    handleResize() {
      const screenHeight = window?.visualViewport?.height || window.innerHeight;
      const screenHeightChanged =
        screenHeight < this.previousScreenHeight ||
        screenHeight > this.previousScreenHeight;
      this.isKeyboardMayBeOpen = screenHeightChanged && !this.isRotate;
      this.isRotate = false;
      this.previousScreenHeight = screenHeight;
    },
    getPropVal(element, propName) {
      return window.getComputedStyle(element).getPropertyValue(propName);
    },
    detect() {
      var media = this.getPropVal(this.element, 'font-family');
      const mediaLibrary = this.getPropVal(this.elementLibrary, 'font-family');

      logger.trace(media);
      logger.trace(mediaLibrary);

      const result = {
        common: {
          narrow: media === 'narrow',
          normal: media === 'normal',
          wide: media === 'wide'
        },
        library: {
          libraryColumnTwo: mediaLibrary === 'library-column-two',
          libraryColumnFour: mediaLibrary === 'library-column-four'
        },
        isKeyboardMayBeOpen: this.isKeyboardMayBeOpen
      };

      return result;
    },
    subscribeOnLayoutInMixin(id) {
      LayoutManager.register({
        layout: this.$_onLayoutChange,
        id: id
      });
    },
    unsubscribeOnLayoutInMixin(id) {
      LayoutManager.unregister(id);
    },
    $_onLayoutChange() {
      this.$_checkSize();
    },
    $_checkSize() {
      const media = this.detect();
      this.$store.commit('MediaDetectorStore/setMedia', media);
    }
  }
};
</script>

<style lang="less" src="./MediaDetector.less"></style>
