const initState = () => ({
  common: {
    narrow: false,
    normal: false,
    wide: false
  },
  library: {
    libraryColumnTwo: false,
    libraryColumnFour: false
  }
});

// getters
const storeGetters = {
  mediaSize(state) {
    return state.common;
  },
  additionalMediaSize(state) {
    return state.library;
  }
};

// actions
const actions = {};

// mutations
const mutations = {
  setMedia(state, media) {
    Object.assign(state.common, media.common);
    Object.assign(state.library, media.library);
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
