<template>
  <Popup
    content-class="popup-toc"
    hide-overlay
    scrollable
    :header-title="headerTitle"
    :header-divider="true"
    :footer-divider="false"
    :content-image="contentImage"
    :content-title="contentTitle"
    @popupEvent="closeTOCPopup"
  >
    <template #custom-content>
      <v-card-text>
        <TableOfContents />
      </v-card-text>
    </template>
    <template #custom-footer></template>
  </Popup>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Popup from '@/components/base/Popup/Popup.vue';
import TableOfContents from '@/components/views/Extras/TableOfContentsTab/TableOfContentsTab';

import images from '@/assets/images';

export default {
  name: PopupNamesEnum.TABLE_OF_CONTENTS_POPUP,
  components: { Popup, TableOfContents },
  props: {
    popupContext: Object
  },
  data() {
    return {
      selectedChapterIndex: 0
    };
  },
  computed: {
    isCompilationOpen() {
      return this.$store.getters['PublicationStore/isCompilationOpen'];
    },
    headerTitle() {
      return this.isCompilationOpen
        ? this.bookView.title
        : this.$t('TableOfContentsPopup.title');
    },
    contentImage() {
      return this.isCompilationOpen ? images.picTocEmpty : '';
    },
    contentTitle() {
      return this.isCompilationOpen
        ? this.$t('TableOfContentsPopup.compilation.contentMessage')
        : this.$t('TableOfContentsPopup.book.contentMessage');
    }
  },
  methods: {
    closeTOCPopup() {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.TABLE_OF_CONTENTS_POPUP
      });
    }
  }
};
</script>

<style src="./TableOfContentsPopup.less" lang="less"></style>
