<template>
  <div>
    <SectionsMenu
      :menu-sections="menuSections"
      @sectionsMenuEvent="sectionsMenuEventHandler"
    />
  </div>
</template>

<script>
import AppMenuItemsEnum from '@/enums/AppMenuItemsEnum';

import SectionsMenu from '@/components/views/AppMenu/SectionsMenu/SectionsMenu';
import MenuItemBuilderService from '@/services/MenuItemBuilderService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import SectionsMenuEventEnum from '@/enums/SectionsMenuEventEnum';

const menuSectionsSchema = [
  [
    AppMenuItemsEnum.LIBRARY,
    AppMenuItemsEnum.ADMIN_PANEL,
    AppMenuItemsEnum.REPORTS
  ],
  [AppMenuItemsEnum.LIBRARY_CONTENT_EDITOR, AppMenuItemsEnum.PROFILE],
  [AppMenuItemsEnum.ABOUT],
  [AppMenuItemsEnum.LOGOUT]
];

export default {
  name: 'AppAdminMenu',
  components: {
    SectionsMenu
  },
  props: {
    displayContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    menuSections() {
      return MenuItemBuilderService.createMenuSections(
        menuSectionsSchema,
        this.displayContext
      );
    }
  },
  mounted() {
    MenuItemBuilderService.init({ router: this.$router, store: this.$store });
  },
  methods: {
    selectMenuItem(menuItem) {
      menuItem.handler();
    },
    sectionsMenuEventHandler(event) {
      switch (event.type) {
        case SectionsMenuEventEnum.ITEM_SELECTED:
          this.$store.dispatch('ManagePopupStore/closePopup', {
            name: PopupNamesEnum.APP_MENU_WRAPPER
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
@import 'AppAdminMenu.less';
</style>
