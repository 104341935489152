import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetsManager from '@/services/AssetsManager/AssetsManager';

const offlineSearchFiles = {
  INDEX_SUMMARY: 'indexSummary.json',

  BOOK_META: 'BookMeta.json',
  DOC_IDS: 'DocIds.json',
  WORDS: 'Words.json',
  STEM_WORDS: 'StemWords.json',
  BOOK_PARA_MAP: 'BookParaMap.json',

  WORD_DOCUMENT: 'WordDocument.json',
  STEM_WORD_DOCUMENT: 'StemWordDocument.json'
};

function getRemoteIndexDetails() {
  return AssetsManager.readLibraryFile(
    offlineSearchFiles.INDEX_SUMMARY,
    AssetResourcesEnum.REMOTE
  );
}

export default {
  getRemoteIndexDetails
};
