<template>
  <BackToAboutControl :title="$t('Accessibility.appName.tooltip')">
    <template #back-to-about>
      <BaseSpriteIcon
        custom-class="logo-name -big"
        :icon-name="icoName"
        :view-box="viewBox"
      />
      <BaseSpriteIcon
        custom-class="logo-name -small"
        icon-name="pic-app-name-ocean-short"
        view-box="0 0 107 20"
      />
    </template>
  </BackToAboutControl>
</template>

<script>
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import BackToAboutControl from '@/components/controls/BackToAboutControl/BackToAboutControl';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

export default {
  name: 'AppNameControl',
  components: { BaseSpriteIcon, BackToAboutControl },
  data() {
    return {
      icoName: '',
      viewBox: '0 0 24 24'
    };
  },
  mounted() {
    const brand = this.$store.getters['ContextStore/brand'];
    this.icoName =
      brand === BrandsEnum.FFA ? 'pic-app-name-ffa' : 'pic-app-name-ocean';
    this.viewBox = brand === BrandsEnum.FFA ? '0 0 160 21' : '0 0 190 20';
  }
};
</script>

<style lang="less" src="./AppNameControl.less"></style>
