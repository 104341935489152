import { render, staticRenderFns } from "./ParagraphNote.vue?vue&type=template&id=c131ccfe&scoped=true"
import script from "./ParagraphNote.vue?vue&type=script&lang=js"
export * from "./ParagraphNote.vue?vue&type=script&lang=js"
import style0 from "./ParagraphNote.vue?vue&type=style&index=0&id=c131ccfe&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c131ccfe",
  null
  
)

export default component.exports