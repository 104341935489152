<template>
  <div class="field-block">
    <BaseTextField
      ref="filterInput"
      v-model="filterText"
      type="text"
      hide-details
      rounded
      prepend-inner-icon="$search"
      :clearable="clearable"
      :placeholder="placeholder"
      @input="processChange"
    >
      <template #prepend-inner>
        <BaseSpriteIcon icon-name="ico-search" />
      </template>
    </BaseTextField>
    <div
      v-if="false"
      style="position:absolute; left:0; right:0; top:0; bottom:0;"
      @click="removeDisable"
    />
  </div>
</template>

<script>
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import debounce from 'lodash/debounce';

export default {
  name: 'FilterInput',
  components: { BaseTextField, BaseSpriteIcon },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    delay: {
      type: Number,
      required: false,
      default: 0
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    const debounceInput = debounce(this.emit.bind(this), this.delay);
    return {
      disabled: true,
      filterText: this.text,
      shouldEmit: true,
      debounceInput
    };
  },
  methods: {
    processChange(filterVal) {
      const filterText = filterVal || '';
      this.shouldEmit = true;
      this.debounceInput(filterText);
    },
    emit(newFilter) {
      if (!this.shouldEmit) {
        return false;
      }
      this.$emit('onFilter', {
        filterText: newFilter
      });
    },
    async removeDisable() {
      if (!this.disabled) {
        return;
      }
      this.disabled = false;
      await this.$nextTick();
      this.$refs.filterInput.focus();
    }
  }
};
</script>

<style lang="less" src="./FilterInput.less"></style>
