import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';

export default {
  [BrandsEnum.FFA]: [
    CustomCategoriesEnum.RECENT,
    CustomCategoriesEnum.SUGGESTED_BOOK,
    CustomCategoriesEnum.NEW,

    'Children',
    'Short Stories',
    'Novels',
    'Drama',
    'Verse',
    'Ideas',
    'History',
    'Science',
    'Religion'
  ],
  [BrandsEnum.OCEAN]: [
    CustomCategoriesEnum.RECENT,
    CustomCategoriesEnum.NEW,

    'Hindu',
    'Zoroastrian',
    'Judaism',
    'Jainism',
    'Confucian',
    'Buddhist',
    'Tao',
    'Christian',
    'Islam',
    'Bahá’í'
  ]
};
