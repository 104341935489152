'use strict';

var indexProvider;
var wordIndex = {
  dictNames: []
};

function setProvider(_indexProvider) {
  indexProvider = _indexProvider;
}

function parseFileIndex(fileName) {
  var parts = fileName.split('_');
  return {
    prefix: parts[1],
    letter: parts[2].split('.')[0]
  };
}

function checkIsValidResponses(response) {
  return response.some(checkIsValidResponse);
}

function checkIsValidResponse(resp) {
  return !resp.hasOwnProperty('err');
}

function updateWordIndexes(response, dictId) {
  response.forEach(function(resp) {
    var indexFileMeta = parseFileIndex(resp.fileName);
    var prefix = indexFileMeta.prefix;
    var letter = indexFileMeta.letter;
    if (!wordIndex.hasOwnProperty(dictId)) {
      wordIndex[dictId] = {};
    }
    if (!wordIndex[dictId].hasOwnProperty(prefix)) {
      wordIndex[dictId][prefix] = {};
    }
    if (!wordIndex[dictId][prefix].hasOwnProperty(letter)) {
      wordIndex[dictId][prefix][letter] = {};
    }
    wordIndex[dictId][prefix][letter] = JSON.parse(resp.data);
  });
  wordIndex.dictNames.push(dictId);
}

function getDictionaryIndex(dictId, mapper) {
  var indexFilesKeys;

  var dicFiles = mapper.getAssetsFile('content');

  indexFilesKeys = dicFiles.filter(function(dicFile) {
    return dicFile.indexOf('index_') !== -1;
  });
  var readPromise = indexFilesKeys.map(function(indexFilesKey) {
    return indexProvider.readFile(indexFilesKey, dictId, 'utf-8');
  });

  return Promise.all(readPromise);
}

function getDictionaryIndexes(dictIds, mapperMap) {
  var notLoadedDictIds = dictIds.filter(function(dictId) {
    return !wordIndex.hasOwnProperty(dictId);
  });

  if (notLoadedDictIds.length === 0) {
    return Promise.resolve({
      wordIndex: wordIndex,
      invalidResponses: []
    });
  }

  var indexPromises = dictIds.map(function(dictId) {
    var mapper = mapperMap[dictId];
    return getDictionaryIndex(dictId, mapper);
  });
  return Promise.all(indexPromises).then(function(responses) {
    var invalidResponses = [];
    responses.forEach(function(respons, index) {
      var isValidResponses = checkIsValidResponses(respons);
      if (isValidResponses) {
        updateWordIndexes(respons, dictIds[index]);
      } else {
        invalidResponses.push(respons);
      }
    });

    return {
      wordIndex: wordIndex,
      invalidResponses: invalidResponses
    };
  });
}

function readByRange(filename, currenDictName, rangesArray) {
  return indexProvider.readByRange(filename, currenDictName, rangesArray);
}

module.exports = {
  setProvider: setProvider,
  getDictionaryIndexes: getDictionaryIndexes,
  readByRange: readByRange,
  checkIsValidResponse: checkIsValidResponse
};
