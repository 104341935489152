var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Popup',{attrs:{"content-class":'popup-delete-compilation',"scrollable":"","persistent":"","content-image":_vm.contentImage,"content-title":_vm.compilation.default
      ? _vm.$t('CompilationDelete.popup.default.title')
      : _vm.$t('CompilationDelete.popup.regular.title', {
          compilationTitle: _vm.compilation.title
        }),"content-message":_vm.compilation.default
      ? _vm.$t('CompilationDelete.popup.default.description')
      : _vm.$t('CompilationDelete.popup.regular.description'),"primary-label":_vm.$t('Popup.button.delete.label'),"secondary-label":_vm.$t('Popup.button.cancel.label'),"primary-footer-handler":_vm.deleteCompilation,"secondary-footer-handler":_vm.cancelDeleteCompilation,"header-divider":false,"footer-divider":false,"width":"588"},on:{"popupEvent":_vm.popupEventHandler}})
}
var staticRenderFns = []

export { render, staticRenderFns }