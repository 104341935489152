<template>
  <SearchLoader class="search-page-header-loader">
    <sw-page-header
      v-if="isMounted"
      :language="currentLanguage"
      :interface-language="systemLang"
      :profile="brand"
      :environment="env"
      :full-access="!isUserGuest"
      @searchWidgetEvent="searchWidgetEventHandler"
    />
  </SearchLoader>
</template>

<script>
import SearchLoader from '@/components/SearchLoader.vue';
export default {
  name: 'SearchHeaderControl',
  components: {
    SearchLoader
  },
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters['ContextStore/currentLanguageGetter'];
    },
    systemLang() {
      return this.$store.getters['ContextStore/getSystemLanguage'];
    },
    isUserGuest() {
      return this.$store.getters['UserStore/isGuestUser'];
    },
    brand() {
      return this.$store.getters['ContextStore/brand'];
    },
    env() {
      return this.$store.getters['ContextStore/env'];
    },
    searchLanguage() {
      return this.$store.getters['SwContextStore/getLang'];
    }
  },
  watch: {
    searchLanguage(val) {
      if (val) {
        this.$store.commit('ContextStore/setCurrentLanguage', val);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    searchWidgetEventHandler(event) {
      this.$emit('searchHeaderControlEvent', event);
    }
  }
};
</script>

<style lang="less">
.search-page-header-loader {
  display: flex;
  flex-grow: 1;

  .error-window-block {
    display: none;
  }
}
</style>
