class PopupState {
  constructor(options = {}) {
    this.headerTitle = options.headerTitle || null;
    this.primaryLabel = options.primaryLabel || '';
    this.primaryFooterHandler = options.primaryFooterHandler || null;
    this.primaryFooterHandlerDisabled =
      options.primaryFooterHandlerDisabled || false;
    this.secondaryLabel = options.secondaryLabel || '';
    this.secondaryFooterHandler = options.secondaryFooterHandler || null;
    this.secondaryFooterHandlerDisabled =
      options.secondaryFooterHandlerDisabled || false;
    this.component = options.component || null;
    this.state = options.state || null;

    this.contentImage = options.contentImage || null;
    this.contentTitle = options.contentTitle || '';
    this.contentMessage = options.contentMessage || '';

    this.additionalContentMessage = options.additionalContentMessage || '';
    this.additionalContentComponent =
      options.additionalContentComponent || null;
  }

  setHeaderTitle(headerTitle) {
    this.headerTitle = headerTitle;
    return this;
  }

  setPrimaryLabel(primaryFooterLabel) {
    this.primaryLabel = primaryFooterLabel;
    return this;
  }

  setPrimaryFooterHandler(primaryFooterHandler) {
    this.primaryFooterHandler = primaryFooterHandler;
    return this;
  }

  setPrimaryFooterHandlerDisabled(primaryFooterHandlerDisabled) {
    this.primaryFooterHandlerDisabled = primaryFooterHandlerDisabled;
    return this;
  }

  setSecondaryLabel(secondaryFooterLabel) {
    this.secondaryLabel = secondaryFooterLabel;
    return this;
  }

  setSecondaryFooterHandler(secondaryFooterHandler) {
    this.secondaryFooterHandler = secondaryFooterHandler;
    return this;
  }

  setSecondaryFooterHandlerDisabled(secondaryFooterHandlerDisabled) {
    this.secondaryFooterHandlerDisabled = secondaryFooterHandlerDisabled;
    return this;
  }

  setComponent(component) {
    this.component = component;
    return this;
  }

  setState(state) {
    this.state = state;
    return this;
  }

  setContentImage(contentImage) {
    this.contentImage = contentImage;
    return this;
  }

  setContentTitle(contentTitle) {
    this.contentTitle = contentTitle;
    return this;
  }

  setContentMessage(contentMessage) {
    this.contentMessage = contentMessage;
    return this;
  }

  setAdditionalContentMessage(additionalContentMessage) {
    this.additionalContentMessage = additionalContentMessage;
    return this;
  }

  setAdditionalContentComponent(additionalContentComponent) {
    this.additionalContentComponent = additionalContentComponent;
    return this;
  }
}

const createState = options => new PopupState(options);

export default { createState };
