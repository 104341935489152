import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CompilationsStore.js');

async function copyToClipboard(text) {
  if (window.cordova) {
    window.cordova.plugins.clipboard.copy(text);
    return true;
  }
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    logger.warn(err);
    return false;
  }
}

function isPossibleToCopyImage() {
  return (
    window.ClipboardItem && typeof navigator.clipboard.write === 'function'
  );
}

async function copyImageToClipboard(url) {
  try {
    let blob;
    if (url instanceof Blob) {
      blob = url;
    } else {
      const data = await fetch(url);
      blob = await data.blob();
    }
    if (!window.ClipboardItem) {
      return false;
    }
    await navigator.clipboard.write([
      new window.ClipboardItem({
        [blob.type]: blob
      })
    ]);
    return true;
  } catch (err) {
    logger.warn(err);
    return false;
  }
}

async function copyToClipboardWithHtml(event, copyObj) {
  if (!Object.keys(event).length) {
    copyToClipboard(copyObj.text);
    return;
  }
  event.clipboardData.setData('text/plain', copyObj.text);
  event.clipboardData.setData('text/html', copyObj.html);
  event.preventDefault();
}

async function readFromClipboard(event) {
  try {
    const clipboardText = await navigator.clipboard.readText(event);
    return clipboardText;
  } catch (err) {
    logger.warn(err);
    return '';
  }
}

export default {
  copyToClipboard,
  isPossibleToCopyImage,
  copyImageToClipboard,
  copyToClipboardWithHtml,
  readFromClipboard
};
