const isWithoutSpaces = value => !value.match(/\s/);
const isNameValid = value => Boolean(value.match(/^[A-Za-z- ]{1,100}$/gm));
const isPriceValid = value => !!value.match(/^[0-9]{1,2}\.[0-9]{2}$/);
const hasPasswordValidFormat = value =>
  /^[0-9a-zA-Z!@#$%^&*]{8,}$/.test(value) && /[A-Z]{1,}/.test(value);
const passwordMaxLength = 25;
const nameMaxLength = 100;
const emailMaxLength = 100;
const campaignTitleMaxLength = 40;

export {
  isWithoutSpaces,
  isNameValid,
  hasPasswordValidFormat,
  isPriceValid,
  passwordMaxLength,
  nameMaxLength,
  emailMaxLength,
  campaignTitleMaxLength
};
