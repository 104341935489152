'use strict';

var context = {};
/* --- api --- */
context.parameters = {};
context.serverUrl = '';
context.applicationUrl = ''; // web-version url

export default context;

/* --- impl --- */
