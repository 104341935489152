const mock = {
  getItem() {
    return '{}';
  },
  key() {},
  setItem() {},
  removeItem() {}
};
const localStorage = process.client ? window.localStorage : mock;

function removeStorageKey(key) {
  localStorage.removeItem(key);
}

function removeExcept(exceptionsMap = {}) {
  let index = 0;
  let key;
  while ((key = localStorage.key(index))) {
    if (!exceptionsMap.hasOwnProperty(key)) {
      removeStorageKey(key);
    } else {
      index++;
    }
  }
}

export default {
  removeStorageKey,
  removeExcept
};
