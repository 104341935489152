import AssessmentTypes from '@/enums/AssessmentTypes';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

import VocabularyAssessmentStore from '@/store/VocabularyAssessmentStore';

const storeByType = {
  [AssessmentTypes.VOCABULARY_ASSESSMENT]: VocabularyAssessmentStore,
  [AssessmentTypes.FLASHCARD]: null
};

const initState = () => ({
  storeName: '',
  isAssessmentHistoryActualized: false,
  intervalId: null
});

const storeGetters = {
  getAssessmentSettings: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getSettings`];
  },
  getImage: (state, getters, rootState, rootGetters) => imageId => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getImage`](imageId);
  },
  getAudio: (state, getters, rootState, rootGetters) => audioId => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getAudio`](audioId);
  },
  getTestView: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getTestView`];
  },
  getAssessmentLevelLabel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getAssessmentLevelLabel`];
  },
  getMaxLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getMaxLevel`];
  },
  getMaxIntegerLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getMaxIntegerLevel`];
  },
  isMaxBookLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/isMaxBookLevel`];
  },
  getMasteryLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getMasteryLevel`];
  },
  getUserBookLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getUserBookLevel`];
  },
  getPassedBookLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getPassedBookLevel`];
  },
  isSuccessfullyPassed: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/isSuccessfullyPassed`];
  },
  getCurrentQuestion: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getCurrentQuestion`];
  },
  getIsLastQuestion: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getIsLastQuestion`];
  },
  getQuestionsCountLabel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getQuestionsCountLabel`];
  },
  getMinCorrectAnswersPerBookLevel: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getMinCorrectAnswersPerBookLevel`];
  },
  getQuestionsPerBookLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getQuestionsPerBookLevel`];
  },
  getCorrectAnswersCountLabel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getCorrectAnswersCountLabel`];
  },
  getHeaderTitle: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getHeaderTitle`];
  },
  getQuestionsCountPerBookLevel: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getQuestionsCountPerBookLevel`];
  },
  getFinishedLevelsUserResults: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getFinishedLevelsUserResults`];
  },
  getAllUserResults: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getAllUserResults`];
  },
  isSuggestedAssessmentBlockVisible(state, getters, rootState, rootGetters) {
    return (
      rootGetters['ContextStore/isOnline'] &&
      getters.isAssessmentHistoryActualized &&
      rootGetters['ProgressStore/getIsAssessmentAvailable'] &&
      rootGetters['LibraryStore/isManagePublicationsStateInit'](
        ManagePublicationsStates.LIBRARY
      )
    );
  },
  isSuggestedAssessmentButtonVisible: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const isAssessmentAvailable =
      rootGetters['ProgressStore/getIsAssessmentAvailable'];
    const isAssessmentAllowed = rootGetters['ContextStore/isAssessmentAllowed'];
    return isAssessmentAllowed && isAssessmentAvailable;
  },
  getLatestCompletedResult: (state, getters, rootState, rootGetters) => {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getLatestCompletedResult`];
  },
  isAssessmentHistoryActualized(state) {
    return state.isAssessmentHistoryActualized;
  },
  getCurrentTestType(state, getters, rootState, rootGetters) {
    const storeName = state.storeName;
    return rootGetters[`${storeName}/getCurrentTestType`];
  }
};

const actions = {
  async startActualizeAssessmentHistory({ commit, dispatch }) {
    await dispatch('VocabularyAssessmentStore/fetchAllUserResults', null, {
      root: true
    });

    const updateInterval = AppConstantsUtil.UPDATE_ASSESSMENT_HISTORY_INTERVAL;
    let intervalId = setTimeout(function actualize() {
      dispatch('VocabularyAssessmentStore/fetchAllUserResults', null, {
        root: true
      });
      intervalId = setTimeout(actualize, updateInterval);
    }, updateInterval);
    commit('setIntervalId', intervalId);
    commit('setAssessmentHistoryActualized', true);
  },
  stopActualizeAssessmentHistory({ commit, state }) {
    clearTimeout(state.intervalId);
    commit('setIntervalId', null);
  },
  clearAssessment({ dispatch, state }) {
    const storeName = state.storeName;
    dispatch(`${storeName}/clearAssessment`, null, { root: true });
  },
  initAssessmentStore({ commit }, { testType }) {
    commit('setStoreName', testType);
  },
  async initAssessment({ state, dispatch }) {
    const storeName = state.storeName;
    return dispatch(`${storeName}/initAssessment`, null, { root: true });
  },
  async initLatestResult({ state, dispatch }) {
    const storeName = state.storeName;
    return dispatch(`${storeName}/initLatestResult`, null, { root: true });
  },
  fetchAllUserResults({ state, dispatch }) {
    const storeName = state.storeName;
    return dispatch(`${storeName}/fetchAllUserResults`, null, { root: true });
  },
  async finishAssessment(
    { dispatch, state },
    { completed, completedMandatoryQuestions }
  ) {
    const storeName = state.storeName;
    await dispatch(
      `${storeName}/finishAssessment`,
      { completed, completedMandatoryQuestions, manualStop: true },
      { root: true }
    );
  },
  processCorrectAnswer({ state, dispatch }) {
    const storeName = state.storeName;
    dispatch(`${storeName}/processCorrectAnswer`, null, { root: true });
  },
  processIncorrectAnswer({ state, dispatch }) {
    const storeName = state.storeName;
    dispatch(`${storeName}/processIncorrectAnswer`, null, { root: true });
  }
};

const mutations = {
  setStoreName(state, testType) {
    switch (testType) {
      case AssessmentTypes.VOCABULARY_ASSESSMENT:
        state.storeName =
          storeByType[AssessmentTypes.VOCABULARY_ASSESSMENT].name;
        break;
      default:
        state.storeName =
          storeByType[AssessmentTypes.VOCABULARY_ASSESSMENT].name;
        break;
    }
  },
  setIntervalId(state, intervalId) {
    state.intervalId = intervalId;
  },
  setAssessmentHistoryActualized(state, isAssessmentHistoryActualized) {
    state.isAssessmentHistoryActualized = isAssessmentHistoryActualized;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
