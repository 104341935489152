<template>
  <div>
    <div v-if="!isProd" class="error-content">
      <div class="error-content-wrapper">
        <div class="error-text">
          <div>
            <h2>{{ $t('PresentSystemException.error.text') }}</h2>
            <p>{{ $t('PresentSystemException.error.instruction.text') }}</p>
            <p>{{ $t('PresentSystemException.message.instruction.text') }}</p>
          </div>
          <div>
            <p>{{ error.message }}</p>
            <p>{{ error.info }}</p>
            <p>{{ error.stack }}</p>
          </div>
        </div>
        <div class="error-footer">
          <v-btn class="text-none" @click="reload">
            {{ $t('PresentSystemException.continue.button.label') }}
          </v-btn>
        </div>
      </div>
    </div>
    <UserFriendlyErrorPresentation v-else />
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ErrorPresentation.vue');

import UserFriendlyErrorPresentation from '@/components/views/UserFriendlyErrorPresentation/UserFriendlyErrorPresentation';

export default {
  name: 'ErrorPresentation',
  components: {
    UserFriendlyErrorPresentation
  },
  props: {
    error: {
      type: Object,
      required: false
    },
    isProd: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    logger.fatal(this.error);
  },
  methods: {
    reload() {
      window.location.reload(true);
    }
  }
};
</script>

<style scoped lang="less">
@import 'ErrorPresentation.less';
</style>
