<template>
  <v-dialog
    v-model="isShown"
    hide-overlay
    persistent
    :content-class="contentClass"
  >
    <v-card>
      <v-card-text>
        <div class="toaster-content">
          <div v-if="toasterAttributes.toasterIcon" class="toaster-icon">
            <BaseSpriteIcon :icon-name="toasterAttributes.toasterIcon" />
          </div>
          <div class="toaster-text">
            <template v-if="toasterAttributes.compilationId">
              <div>{{ $t('CreateCompilation.addedToCompilation.label') }}</div>
              <a class="compilation-link" @click="openCompilation">
                {{ compilation.title }}
              </a>
            </template>
            <div
              v-else-if="toasterAttributes.returnToReadingPosition"
              class="return-to-reading"
            >
              <div>
                {{ $t('SearchInsideBook.Toaster.returnToReadingPosition') }}
              </div>
              <a class="previous-reading-link" @click="openLastReadingPosition">
                Go
              </a>
            </div>
            <template v-else>
              <div v-html="toasterAttributes.text" />
            </template>
          </div>
          <BaseButton
            v-if="!!toasterContext.clickHandler"
            dark
            text
            class="toaster-action-button"
            @click="toasterContext.clickHandler"
          >
            <span v-html="toasterAttributes.clickLabel" />
          </BaseButton>
          <BaseButton
            v-if="toasterAttributes.toasterClose"
            icon
            :color="isNightTheme ? 'black' : 'white'"
            @click="closeByClick()"
          >
            <BaseSpriteIcon icon-name="ico-close" />
          </BaseButton>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ToasterEvents from '@/enums/ToasterEvents';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';

const DEFAULT_CLASS = 'toaster-popup';
const HIDDEN_POPUP_CLASS = 'hidden-toaster';

const TOASTER_STATES = {
  HIDDEN: 'hidden',
  PRESENTED: 'presented'
};

const TOASTER_DEFAULTS = {
  shouldPresent: false,
  text: 'Oops! Something went wrong',
  customClass: '',
  duration: 3000,
  persistent: false,
  toasterIcon: 'ico-reports',
  toasterClose: true,
  clickHandler: null,
  clickLabel: null
};

export default {
  name: 'Toaster',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  props: {
    toasterContext: {
      type: Object,
      default: () => TOASTER_DEFAULTS
    }
  },
  data() {
    return {
      toasterState: TOASTER_STATES.HIDDEN,
      isShown: false,
      compilationId: null,
      toasterAttributes: TOASTER_DEFAULTS
    };
  },
  computed: {
    contentClass() {
      return (
        DEFAULT_CLASS +
        ` ${this.toasterContext.customClass || ''}` +
        (this.isHidden ? ` ${HIDDEN_POPUP_CLASS}` : '')
      );
    },
    isHidden() {
      return this.toasterState === TOASTER_STATES.HIDDEN;
    },
    isPresented() {
      return this.toasterState === TOASTER_STATES.PRESENTED;
    },
    compilation() {
      if (!this.toasterAttributes.compilationId) {
        return {};
      }
      return this.$store.getters['LibraryStore/getPublicationById'](
        this.toasterAttributes.compilationId
      );
    },
    isNightTheme() {
      return this.$vuetify.theme.dark;
    }
  },
  watch: {
    isPresented(val) {
      if (val) {
        this.$_triggerToasterDisappear();
      }
    },
    isShown(val) {
      if (val) {
        this.$_triggerToasterPresentation();
      }
    },
    compilation(val) {
      if (val && val.id) {
        this.$_triggerToasterDisappear();
      }
    }
  },
  mounted() {
    this.assignToasterAttributes();
    setTimeout(() => {
      this.isShown = this.toasterContext.shouldPresent;
    });
  },
  methods: {
    async openCompilation() {
      await PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        {
          publicationId: this.toasterAttributes.compilationId
        }
      );
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.EXTRAS_POPUP
      });
      this.close();
    },
    async openLastReadingPosition() {
      await PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        { publicationId: this.toasterAttributes.publicationId }
      );
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.EXTRAS_POPUP
      });
      this.close();
    },
    assignToasterAttributes() {
      this.toasterAttributes = {
        ...TOASTER_DEFAULTS,
        ...this.toasterContext
      };
    },
    $_triggerToasterPresentation() {
      setTimeout(() => {
        this.toasterState = TOASTER_STATES.PRESENTED;
      }, 100);
    },
    $_triggerToasterDisappear() {
      if (this.toasterAttributes.persistent) {
        return;
      }
      setTimeout(() => {
        this.close();
      }, this.$_mathMaxDuration());
    },
    $_mathMaxDuration() {
      return Math.max(
        +this.toasterAttributes.duration || 0,
        TOASTER_DEFAULTS.duration
      );
    },
    close() {
      this.toasterState = TOASTER_STATES.HIDDEN;
      this.$emit('toasterEvent', {
        type: ToasterEvents.WAS_HIDDEN,
        data: {}
      });
    },
    closeByClick() {
      if (typeof this.toasterAttributes.onCloseHandler === 'function') {
        this.toasterAttributes.onCloseHandler();
      }
      this.close();
    }
  }
};
</script>

<style lang="less" src="./Toaster.less"></style>
