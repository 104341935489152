import Utils from '@/services/utils/Utils';
import StudyClassTypes from '@/enums/StudyClassTypes';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';

class WizardInfoItem {
  constructor() {
    this.isValid = false;
  }

  validate() {
    throw new Error('Need Implement validate function for WizardInfoItem');
  }
}

class CourseInfo extends WizardInfoItem {
  constructor(studyClass, user) {
    super();
    this.classType = studyClass.classType || StudyClassTypes.INDEPENDENT_STUDY;
    this.name = studyClass.name || this._createCourseName();
    this.user = user;
    this.description = studyClass.description || '';
  }

  validate() {
    const classNameValid = this.name.length !== 0;
    this.isValid = classNameValid;
    return this.isValid;
  }

  _createCourseName() {
    return `${this.user.name || ''} Study of`;
  }
}

class ContentInfo extends WizardInfoItem {
  constructor(studyClass) {
    super();
    this.publicationId = studyClass.publicationId;
    this.publicationType = studyClass.publicationType;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
  }
  setPublicationType(publicationType) {
    this.publicationType = publicationType;
  }

  validate() {
    this.isValid = Boolean(this.publicationId);
  }
}

class DurationInfo extends WizardInfoItem {
  constructor(studyClass) {
    super();
    this.isSelfStudy =
      studyClass.classType === StudyClassTypes.INDEPENDENT_STUDY;

    this.publicationId = studyClass.publicationId;
    this.studyWeekDays = studyClass.studyWeekDays;
    this.allowDiscussions = studyClass.allowDiscussions;
    this.allowSkipBooks = studyClass.allowSkipBooks;
    this.expectedDailyWork = studyClass.expectedDailyWork;
    this.joinEndDate = studyClass.joinEndDate;
    this.scheduledAt = studyClass.scheduledAt;
  }

  setExpectedDailyWork(expectedDailyWork) {
    this.expectedDailyWork = expectedDailyWork;
  }

  setScheduledAt(scheduledAt) {
    this.scheduledAt = scheduledAt;
  }

  setJoinEndDate(joinEndDate) {
    this.joinEndDate = joinEndDate;
  }

  validate() {
    this.isValid =
      this.studyWeekDays.length !== 0 && Boolean(this.expectedDailyWork);
  }
}

class StudyClass {
  constructor() {
    this.classId = Utils.uuid();
    this.publicationId = null;
    this.publicationType = null;
    this.registeredAt = new Date().getTime();
    this.classType = null;
    this.name = null;
    this.description = '';
    this.studyWeekDays = null;
    this.allowDiscussions = false;
    this.allowSkipBooks = false;
    this.expectedDailyWork = null;
    this.joinEndDate = null;
    this.scheduledAt = null;
    this.type = PublicationsTypes.STUDY_COURSE;
  }

  applyCourseInfo(courseInfo) {
    this.classType = courseInfo.classType;
    this.name = courseInfo.name;
    this.description = courseInfo.description;
  }

  createCourseInfo(user) {
    return new CourseInfo(this, user);
  }

  applyContentInfo(сontentInfo) {
    this.publicationId = сontentInfo.publicationId;
    this.publicationType = сontentInfo.publicationType;
  }

  createContentInfo() {
    return new ContentInfo(this);
  }

  applyDurationInfo(durationInfo) {
    this.studyWeekDays = durationInfo.studyWeekDays;
    this.allowDiscussions = durationInfo.allowDiscussions;
    this.allowSkipBooks = durationInfo.allowSkipBooks;
    this.expectedDailyWork = durationInfo.expectedDailyWork;
    this.joinEndDate = durationInfo.joinEndDate;
    this.scheduledAt = durationInfo.scheduledAt;
  }

  createDurationInfo() {
    return new DurationInfo(this);
  }
}

class PersistQuery {
  constructor(studyClass) {
    this.classId = studyClass.classId;
    this.publicationId = studyClass.publicationId;
    this.publicationType = studyClass.publicationType;
    this.registeredAt = studyClass.registeredAt;
    this.classType = studyClass.classType;
    this.name = studyClass.name;
    this.description = studyClass.description;
    this.studyWeekDays = studyClass.studyWeekDays;
    this.allowDiscussions = studyClass.allowDiscussions;
    this.allowSkipBooks = studyClass.allowSkipBooks;
    this.expectedDailyWork = studyClass.expectedDailyWork;
    this.joinEndDate = studyClass.joinEndDate;
    this.scheduledAt = studyClass.scheduledAt;
  }
}

function createStudyClass() {
  return new StudyClass();
}

function createPersistQuery(studyClass) {
  return new PersistQuery(studyClass);
}

export default {
  createStudyClass,
  createPersistQuery
};
