import ActivityReportTypesEnum from '@shared/enums/ActivityReportTypesEnum';

class SubscriptionQueryBuilder {
  constructor(userId) {
    this.userId = userId;
    this.updateRequest = {};
  }

  setTeacherEmailsUpdatesActions(teacherEmailsUpdatesActions) {
    this.updateRequest.teacherEmailsUpdatesActions = teacherEmailsUpdatesActions;
    return this;
  }

  setStudentsUpdatesActions(studentEmailsUpdatesActions) {
    this.updateRequest.studentEmailsUpdatesActions = studentEmailsUpdatesActions;
    return this;
  }

  setSelfNotify(selfNotify) {
    this.updateRequest.selfNotify = selfNotify;
    return this;
  }

  setSelfNotifyReportType(reportType) {
    this.updateRequest.selfNotifyReportType = reportType;
    return this;
  }

  setNewsNotify(newsNotify) {
    this.updateRequest.newsNotify = newsNotify;
    return this;
  }

  setNewsNotifyReportType(reportType) {
    this.updateRequest.newsNotifyReportType = reportType;
    return this;
  }

  build() {
    return this;
  }
}

class TeacherSubscriptionView {
  constructor(buildData) {
    this.email = buildData.email || '';
    this.reportType = buildData.reportType || ActivityReportTypesEnum.WEEKLY;
  }
}

class TeacherSubscriptionViewBuilder {
  setEmail(val) {
    this.email = val;
    return this;
  }

  setReportType(val) {
    this.reportType = val;
    return this;
  }

  build() {
    return new TeacherSubscriptionView(this);
  }
}

class UserSubscriptionView {
  constructor(buildData) {
    this.emailList = buildData.emailList || [];
    this.selfNotify = buildData.selfNotify || false;
    this.selfNotifyReportType =
      buildData.selfNotifyReportType || ActivityReportTypesEnum.WEEKLY;
    this.newsNotify = buildData.newsNotify || false;
    this.newsNotifyReportType =
      buildData.newsNotifyReportType || ActivityReportTypesEnum.WEEKLY;
  }
}

class UserSubscriptionViewBuilder {
  setEmails(val) {
    this.emailList = val;
    return this;
  }

  setSelfNotify(val) {
    this.selfNotify = val;
    return this;
  }

  setSelfNotifyReportType(val) {
    this.selfNotifyReportType = val;
    return this;
  }

  setNewsNotify(val) {
    this.newsNotify = val;
    return this;
  }

  setNewsNotifyReportType(val) {
    this.newsNotifyReportType = val;
    return this;
  }

  build() {
    return new UserSubscriptionView(this);
  }
}

function _createUpdatesAction(email, action, reportType) {
  return {
    email,
    action,
    reportType
  };
}

function _createAddActions(emails) {
  const action = 'add';
  const reportType = ActivityReportTypesEnum.WEEKLY;
  const updatesActions = emails.map(email => {
    return _createUpdatesAction(email, action, reportType);
  });
  return updatesActions;
}

function createAddEmailRequest(emails, userId) {
  const updatesActions = _createAddActions(emails);
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setTeacherEmailsUpdatesActions(updatesActions).build();
}

function createDeleteEmailRequest(emails, userId) {
  const action = 'delete';
  const updatesActions = emails.map(email => {
    return _createUpdatesAction(email, action);
  });
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setTeacherEmailsUpdatesActions(updatesActions).build();
}

function createSelfNotifyRequest(selfNotify, userId) {
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setSelfNotify(selfNotify).build();
}

function createNewsNotifyRequest(newsNotify, userId) {
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setNewsNotify(newsNotify).build();
}

function createSelfNotifyReportTypeRequest(reportType, userId) {
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setSelfNotifyReportType(reportType).build();
}

function createNewsNotifyReportTypeRequest(reportType, userId) {
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setNewsNotifyReportType(reportType).build();
}

function createTeacherReportTypeRequest(email, reportType, userId) {
  const action = 'update';
  const updatesActions = [_createUpdatesAction(email, action, reportType)];
  const builder = new SubscriptionQueryBuilder(userId);
  return builder.setTeacherEmailsUpdatesActions(updatesActions).build();
}

function createUserSubscribeRequest(emails, selfNotify, newsNotify, userId) {
  const updatesActions = _createAddActions(emails);
  const builder = new SubscriptionQueryBuilder(userId);
  return builder
    .setTeacherEmailsUpdatesActions(updatesActions)
    .setSelfNotify(selfNotify)
    .setNewsNotify(newsNotify)
    .build();
}

function getUserSubscriptionViewBuilder() {
  return new UserSubscriptionViewBuilder();
}

function getTeacherSubscriptionViewBuilder() {
  return new TeacherSubscriptionViewBuilder();
}

export default {
  createAddEmailRequest,
  createDeleteEmailRequest,
  createSelfNotifyRequest,
  createNewsNotifyRequest,
  createSelfNotifyReportTypeRequest,
  createNewsNotifyReportTypeRequest,
  createTeacherReportTypeRequest,
  createUserSubscribeRequest,
  getUserSubscriptionViewBuilder,
  getTeacherSubscriptionViewBuilder
};
