import ClientNodeContext from './../context/ClientNodeContext';
import ApplicationContext from './../context/ApplicationContext';
import ExecutionContext from './../context/ExecutionContext';
import Context from './../context/Context';

import AppConfigService from '@/services/AppConfigService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ContextService.js');

function isObject(value) {
  return !!(
    value && Object.prototype.toString.call(value) === '[object Object]'
  );
}

function deepMerge(obj) {
  var sources = Array.prototype.slice.call(arguments, 1);
  sources.forEach(function(source) {
    Object.keys(source).forEach(function(key) {
      if (obj.hasOwnProperty(key) && isObject(obj[key])) {
        obj[key] = deepMerge(obj[key], source[key]);
      } else if (typeof source[key] !== 'undefined') {
        obj[key] = source[key];
      }
    });
  });
  return obj;
}

function _mergeJSONs(results) {
  return deepMerge.apply(null, results);
}

function _detectLanguage(parameters) {
  return _getLangs(parameters).then(_filter);

  function _getLangs(params) {
    return new Promise(function(resolve) {
      if (ApplicationContext.application.toLowerCase() === 'editor') {
        resolve(params.defaultLanguage);
        return;
      }

      var storedLang = window.localStorage.getItem('systemLanguage'); // jshint ignore:line
      if (storedLang) {
        resolve(storedLang);
        return;
      }

      var navigator = window.navigator;
      // if (window.cordova) {
      //   navigator.globalization.getPreferredLanguage(
      //     function(currentLang) {
      //       resolve(currentLang.value || currentLang);
      //     },
      //     function() {
      //       resolve(params.defaultLanguage);
      //     }
      //   );
      // } else {
      var currentLang =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language || navigator.userLanguage;
      resolve(currentLang);
      // }
    });
  }

  function _filter(currentLang) {
    // "en"|"en-US"|"en_US" -> "en"
    currentLang = currentLang.substr(0, 2);
    return parameters.supportedLanguages.indexOf(currentLang) !== -1
      ? currentLang
      : parameters.defaultLanguage;
  }
}

function _loadParamsAndMerge(listOfPromises) {
  return Promise.all(listOfPromises).then(function(results) {
    const hasEmpty = results.some(item => !item);
    if (hasEmpty) {
      const msg = '<<< Some options missed in configs!!! >>>';
      logger.error(msg);
      //eslint-disable-next-line no-console
      console.error('------------');
      //eslint-disable-next-line no-console
      console.error(msg);
      //eslint-disable-next-line no-console
      console.error('------------');
    }
    return _mergeJSONs(results);
  });
}

function _postConfigure(config, application) {
  if (
    config.applicationSpecificConfigs &&
    config.applicationSpecificConfigs.hasOwnProperty(application.toLowerCase())
  ) {
    var specificConfig =
      config.applicationSpecificConfigs[application.toLowerCase()];
    delete config.applicationSpecificConfigs;
    config = Object.assign({}, config, specificConfig);
  }

  config.EpubConfig.ServerUrl = config.serverURL || config.EpubConfig.ServerUrl;
  return config;
}

function _detectApplicationUrl() {
  if (
    ClientNodeContext.native ||
    ApplicationContext.application === 'searcher'
  ) {
    return (
      Context.serverUrl +
      '/' +
      ApplicationContext.application.toLowerCase() +
      '/'
    );
    // var scheme = Context.parameters.branch + Context.parameters.brand;
    // scheme = scheme.toLowerCase().replace(/[^a-z]/g, '');
    // return scheme + ':///';
  }
  var s = window.location.href;
  return s.replace(/(?:\?|#).+$/, '');
}

async function init(application, serverUrl, envParameters) {
  ApplicationContext.application = application;
  // var currentUrl;
  const _Context = JSON.parse(JSON.stringify(Context));
  const envParams = await AppConfigService.loadEnvParams(envParameters);
  ExecutionContext.runtimeParameters = envParams;
  ExecutionContext.configMode = envParams.config || 'default';

  _Context.serverUrl = serverUrl || envParams.serverUrl;
  if (process.server) {
    await AppConfigService.sendHealthCheck(_Context.serverUrl);
  }
  const data = [
    AppConfigService.loadSharedParameters(
      _Context.serverUrl,
      ApplicationContext.application
    ),
    AppConfigService.loadClientParameters(
      _Context.serverUrl,
      ApplicationContext.application
    ),
    envParams
  ];

  const parameters =
    process.env.CLIENT_CONFIG || (await _loadParamsAndMerge(data));
  _Context.parameters = _postConfigure(parameters, application);
  ExecutionContext.serverUrl = Context.serverUrl;
  _Context.paymentServerUrl = _Context.parameters.paymentServerUrl;
  ApplicationContext.applicationReaderUrl = _Context.parameters.clientReaderUrl;
  ApplicationContext.applicationEditorUrl = _Context.parameters.clientEditorUrl;
  _Context.downloadUrl = _Context.serverUrl;

  if (process.server) {
    return _Context;
  }
  const lang = await _detectLanguage(_Context.parameters);
  ExecutionContext.systemLanguage = _Context.systemLanguage = lang;
  _Context.applicationUrl = ApplicationContext.applicationUrl = _detectApplicationUrl();
  if (window.cordova && window.localStorage) {
    var buildVersion = window.localStorage.getItem('currentInstalledVersion');
    if (buildVersion) {
      _Context.parameters.buildVersion = buildVersion;
    }
  }
  if (['ar', 'fa'].indexOf(lang) !== -1) {
    window.document.getElementsByTagName('HTML')[0].dir = 'rtl';
  }
  return _Context;
}

function prepareFontNames(contextFonts) {
  let fontsForWait = [];
  contextFonts.customs.forEach(font => {
    fontsForWait = Array.isArray(font.name)
      ? [...fontsForWait, ...font.name]
      : [...fontsForWait, font.name];
  });
  return fontsForWait;
}

function getContext() {
  return Context;
}

export default {
  init,
  prepareFontNames,
  getContext,
  loadEnvConfig: AppConfigService.loadEnvConfig,
  loadEnvParams: AppConfigService.loadEnvParams,
  getClientConfig: AppConfigService.getClientConfig
};
