import GoogleTrackingMethods from '@/enums/GoogleTrackingMethodsEnum';
import clientNodeContext from '@/context/ClientNodeContext';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('GoogleAnalyticsUtils.js');

let googleAnalyticsContext;
let campaignData = {};

class GoogleAnalyticsContext {
  constructor({ Context }) {
    this.googleId = Context.parameters.googleAnalyitics.googleID;
    this.isAnalyticsEnabled =
      Context.parameters.googleAnalyitics.enabled && this.googleId;
    this.trackingMethod = this._getTrackingMethodByContext();
    this.processInitFn = () => {};
    this.trackEventFn = () => {};
    this.convertArgsFn = () => {};
    this.trackPageViewFn = () => {};
    this.trackCampaign = () => {};
    this.history = [];

    this._setFnDueToTrackingMethod();
  }

  addToHistory({ event }) {
    this.history.push(event);
  }

  changeIsAnalyticsEnabled(val) {
    this.isAnalyticsEnabled = val;
  }

  _getTrackingMethodByContext() {
    if (clientNodeContext.isElectron) {
      return GoogleTrackingMethods.DESKTOP;
    } else if (clientNodeContext.isDevice) {
      return GoogleTrackingMethods.DEVICE;
    } else {
      return GoogleTrackingMethods.WEB;
    }
  }

  _setFnDueToTrackingMethod() {
    switch (this.trackingMethod) {
      case GoogleTrackingMethods.DEVICE:
        this.processInitFn = () => {};
        this.convertArgsFn = null;
        this.trackCampaign = () => {};
        this.trackEventFn = _trackFirebaseEvent;
        this.trackPageViewFn = _trackFirebasePageView;
        this.trackConversionFn = _trackFirebaseEvent;
        break;
      case GoogleTrackingMethods.DESKTOP:
      case GoogleTrackingMethods.WEB:
        this.processInitFn = _processInitWebAnalytics;
        this.trackEventFn = _trackWebEvent;
        this.trackConversionFn = _trackWebEvent;
        this.convertArgsFn = _convertWebArgs;
        this.trackPageViewFn = _trackWebPageView;
        this.trackCampaign = _trackWebCampaign;
        break;
    }
  }
}

function init(Context) {
  try {
    googleAnalyticsContext = new GoogleAnalyticsContext({ Context });

    googleAnalyticsContext.processInitFn();
    window.console.log('Google analytics initialized');
  } catch (err) {
    logger.error(`Unable to init Google Analytics. Error: ${err}`);
  }
}

function changeIsAnalyticsEnabled(val) {
  googleAnalyticsContext.changeIsAnalyticsEnabled(val);
}

function trackEvent(event, category, label) {
  if (!googleAnalyticsContext) {
    window.console.log('GA Context is not initialized');
    return;
  }
  let args = Array.prototype.slice.call(arguments, 0);
  window.console.log('GA Tracking: ', args);
  googleAnalyticsContext.addToHistory({ event: args });

  if (!googleAnalyticsContext.isAnalyticsEnabled) {
    return;
  }

  if (googleAnalyticsContext.convertArgsFn) {
    args = googleAnalyticsContext.convertArgsFn(args);
    googleAnalyticsContext.trackEventFn(args);
  } else {
    googleAnalyticsContext.trackEventFn(event, category, label);
  }
}

function _trackFirebaseEvent(event, category, label) {
  if (!window.cordova.plugins.firebase) {
    window.console.log('Firebase plugin is not initialized');
    return;
  }

  window.console.log(`GA Firebase Tracking: ${event}, ${category}, ${label}`);
  const os = clientNodeContext.os;

  window.cordova.plugins.firebase.analytics.logEvent(event, {
    category,
    label,
    os
  });
}

function _trackFirebasePageView(path, title) {
  if (!window.cordova.plugins.firebase) {
    window.console.log('Firebase plugin is not initialized');
    return;
  }

  window.console.log(`GA Firebase Tracking: ${path}, ${title}`);
  window.cordova.plugins.firebase.analytics.logEvent('page_view', {
    page_title: title,
    page_location: path
  });
}

function trackConversion(value) {
  if (!googleAnalyticsContext) {
    return;
  }
  if (googleAnalyticsContext.isAnalyticsEnabled) {
    googleAnalyticsContext.trackConversionFn([
      'event',
      'conversion',
      {
        send_to: googleAnalyticsContext.googleId,
        value: value,
        currency: 'USD'
      }
    ]);
  }
}

function trackPageView(path, title) {
  if (!googleAnalyticsContext || !googleAnalyticsContext.isAnalyticsEnabled) {
    return;
  }
  googleAnalyticsContext.trackPageViewFn(path, title);
}

function trackCampaign() {
  if (
    !googleAnalyticsContext ||
    !googleAnalyticsContext.isAnalyticsEnabled ||
    !campaignData.source ||
    !campaignData.medium
  ) {
    return;
  }
  googleAnalyticsContext.trackCampaign(campaignData);
}

function _addTag() {
  window.dataLayer.push(arguments);
}

function _processInitWebAnalytics() {
  const googleId = googleAnalyticsContext.googleId;
  const scriptElement = document.createElement('SCRIPT');
  scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${googleId}`;
  document.head.appendChild(scriptElement);
  window.dataLayer = window.dataLayer || [];
  _addTag('js', new Date());
  _addTag('config', googleId);

  const urlSearchObj = _convertUrlSearchToObj(window.location.search);
  if (urlSearchObj.utm_medium && urlSearchObj.utm_source) {
    campaignData = {
      source: urlSearchObj.utm_source,
      medium: urlSearchObj.utm_medium,
      campaign: 'AffiliateRegistration'
    };
  }
}

function _trackWebEvent(args) {
  _addTag.apply(null, args);
}

function _convertWebArgs(args) {
  const retArgs = [],
    names = ['event_action', 'event_label', 'value'];
  retArgs.push(args.shift());
  retArgs.push({});
  for (let i = 0; i < names.length; i++) {
    if (args[i]) {
      retArgs[1][names[i]] = args[i];
    } else {
      break;
    }
  }
  retArgs[1].event_category = retArgs[0];
  retArgs.unshift('event');
  return retArgs;
}

function _trackWebPageView(path, title) {
  _addTag('config', googleAnalyticsContext.googleId, {
    page_title: title,
    page_path: path
  });
}

function _trackWebCampaign(options) {
  logger.info('ga: ', options);
  logger.info('window.location: ', window.location);

  _addTag('config', googleAnalyticsContext.googleId, {
    campaign: {
      source: options.source,
      medium: options.medium,
      campaign: options.campaign
    }
  });
}

function _convertUrlSearchToObj(search) {
  if (!search || !search.length) {
    return {};
  }
  const hashes = search.replace(/^\?/, '').split('&');
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split('=');
    return { ...acc, [key]: val };
  }, {});
}

export default {
  init,
  changeIsAnalyticsEnabled,
  trackEvent,
  trackConversion,
  trackPageView,
  trackCampaign
};
