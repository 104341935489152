<template>
  <v-dialog
    v-model="isShown"
    hide-overlay
    persistent
    :width="300"
    :content-class="DEFAULT_POPUP_CLASS"
  >
    <v-card>
      <v-card-title class="message-title">
        {{ $t('PresentSystemException.title') }}
      </v-card-title>
      <v-card-text class="message-content">
        <i>
          <BaseSpriteIcon icon-name="ico-repeat" />
        </i>
        {{ $t('PresentSystemException.reloading') }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

const DEFAULT_POPUP_CLASS = 'book-not-downloaded-popup';
const DELAY_BEFORE_RELOAD = 2 * 1000;

export default {
  name: 'UserFriendlyErrorPresentation',
  components: {
    BaseSpriteIcon
  },
  props: {},
  data() {
    return {
      DEFAULT_POPUP_CLASS,
      isShown: false
    };
  },
  mounted() {
    this.isShown = true;
    this.startAutoReload();
  },
  methods: {
    reload() {
      window.location.reload(true);
    },
    startAutoReload() {
      setTimeout(this.reload, DELAY_BEFORE_RELOAD);
    }
  }
};
</script>

<style lang="less" src="./UserFriendlyErrorPresentation.less"></style>
