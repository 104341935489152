import each from 'lodash/each';
import tail from 'lodash/tail';
import MessageBufferService from '@/services/MessageBufferService';
class Message {
  constructor(type, text, params) {
    this.type = type;
    this.text = text;
    this.params = params;
    this.timestamp = new Date().getTime();
  }
}
// IMPORTANT!
// Implementation should strictly avoid pushing messages
// to MessageBuffer (in particular, should not use logger).
// Otherwise infinite message loop is possible.

/**
 * Base class (constructor) of messages this buffer accepted.
 */

let _impl;

let _buffer = []; // temporary buffer until implementation is provided
const BUFFER_SIZE_MAX = 1000; // remove REMOVE_WHEN_OVERFLOWED top messages when buffer size exceeds this value
const REMOVE_WHEN_OVERFLOWED = 200;

function init(Context, runId) {
  MessageBufferService.init(Context, runId);
  setImpl(MessageBufferService);
}
/**
 * Provide implementation of the following interface:
 *    push(message)
 *    flush()       // returns promise
 *    flushSync()   // holds execution until completed
 */
function setImpl(impl) {
  _impl = impl;

  each(_buffer, function(m) {
    _impl.push(m);
  });

  _buffer = [];
}

/**
 * Flush all accumulated messages to server.
 * Return promise.
 */
function flush() {
  return _impl && _impl.flush();
}

/**
 * Flush all accumulated messages to server synchronously.
 * Pending messages are taken also. So duplication is possible.
 * Hold execution until completed.
 */
function flushSync() {
  return _impl && _impl.flushSync();
}

/**
 * Push message to buffer.
 */
function push(message) {
  if (_impl) {
    _impl.push(message);
  } else {
    if (_buffer.length === BUFFER_SIZE_MAX) {
      _buffer = tail(_buffer, REMOVE_WHEN_OVERFLOWED);
      if (window.console && window.console.log) {
        window.console.log(
          'MessageBuffer: buffer overflowed, top',
          REMOVE_WHEN_OVERFLOWED,
          'messages removed'
        );
      }
    }
    _buffer.push(message);
  }
}

export default {
  init,
  Message,
  setImpl,
  flush,
  flushSync,
  push
};
