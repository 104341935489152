import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserFavouritePublications.js');

import DB from '@/services/Agent/dao/DB';
import FavouritePublication from '@shared/classes/FavouritePublication';

export default {
  removePublicationFromFavourites,
  addPublicationToFavourites,
  getFavouritePublications
};

const prefix = DB.prefix.favouritePublications;
const TYPE = 'FavouritePublications';

class FavouritePubs {
  constructor(data = {}) {
    this.type = TYPE;
    this.userId = data.userId || _getUserId();
    this.createdAt = data.createdAt || Date.now();
    this.favouritePubsMap = data.favouritePubsMap || {};
  }

  addFavouritePubsItem(item) {
    const favouritePubItem = new FavouritePublication(item);
    this.favouritePubsMap[favouritePubItem.id] = favouritePubItem;
  }

  removeFavouritePubsItem(id) {
    if (this.favouritePubsMap.hasOwnProperty(id)) {
      delete this.favouritePubsMap[id];
    }
  }
}

const errorCodes = {
  NOT_FOUND: 404
};

async function removePublicationFromFavourites(publicationId) {
  try {
    const publications = await getFavouritePublications();
    publications.removeFavouritePubsItem(publicationId);
    return _updateFavouritePublications(publications);
  } catch (error) {
    logger.error(`Remove publication from favourites failed with: ${error}`);
  }
}

async function addPublicationToFavourites(publication) {
  try {
    const publications = await getFavouritePublications();
    publications.addFavouritePubsItem(publication);
    return _updateFavouritePublications(publications);
  } catch (error) {
    logger.error(`Add publication to favourites failed with: ${error}`);
  }
}

async function getFavouritePublications() {
  try {
    const response = await DB.userRW().getAllByPrefix(prefix);
    return new FavouritePubs(response[0]);
  } catch (error) {
    logger.info(error);
    return new FavouritePubs();
  }
}

function _getUserId() {
  return DB.userId();
}

function _getFavouritePubsId(userId) {
  return DB.id.favouritePublications(userId);
}

function _putDocument(id, data, revision) {
  Object.assign(data, { _id: id, _rev: revision });
  return DB.userRW().put(data);
}

function _findDocument(id) {
  return DB.userRW()
    .get(id)
    .catch(error => {
      if (error.status === errorCodes.NOT_FOUND) {
        return null;
      }
      logger.error(error);
    });
}

async function _updateFavouritePublications(data) {
  const userId = _getUserId();
  const favouritePubsId = _getFavouritePubsId(userId);
  const doc = await _findDocument(favouritePubsId);

  if (!doc) {
    return _putDocument(favouritePubsId, data);
  } else {
    return _putDocument(favouritePubsId, data, doc._rev);
  }
}
