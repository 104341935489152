export default {
  NOT_FOUND_ERR: 1,
  SECURITY_ERR: 2,
  ABORT_ERR: 3,
  NOT_READABLE_ERR: 4,
  ENCODING_ERR: 5,
  NO_MODIFICATION_ALLOWED_ERR: 6,
  INVALID_STATE_ERR: 7,
  MISSING_FILE_CODE: 8,
  INVALID_MODIFICATION_ERR: 9,
  QUOTA_EXCEEDED_ERR: 10,
  TYPE_MISMATCH_ERR: 11,
  PATH_EXISTS_ERR: 12,
  EXCEED_STORAGE_QUOTA: 22
};
