import get from 'lodash/get';

import UserService from './UserService';
import AuthTypeEnum from '@/enums/AuthTypeEnum';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import AppUrlService from './AppUrlService';

const authTypes = { EMAIL: 'email', GMAIL: 'google', FACEBOOK: 'facebook' };
const GUEST_GROUP = 'guest_session';

function loginByGmail(returnUri) {
  const url = UserService.getOAuthURI(authTypes.GMAIL, returnUri);
  window.location.href = url;
}

function loginByFacebook(returnUri) {
  const url = UserService.getOAuthURI(authTypes.FACEBOOK, returnUri);
  window.location.href = url;
}

function loginByEmail(loginInfo) {
  return _validateOnLogin(loginInfo).then(function(isValid) {
    if (isValid) {
      return UserService.authenticate(loginInfo, authTypes.EMAIL);
    }
    return Promise.reject();
  });
}

function skipLogin() {
  return UserService.skipLogin();
}

function performLogin(userInfo, authType, promoCode) {
  const userId = UnifiedSettingsService.getSetting(GUEST_GROUP, 'guest_userId');
  if (authType === AuthTypeEnum.OAUTH && userId) {
    userInfo.userId = userId;
  }

  return UserService.authenticate(userInfo, authType, promoCode).then(
    _showLoginResult,
    _showLoginResult
  );

  function _showLoginResult(authResult) {
    const id = get(authResult, 'user.id', '');
    const guestId = UnifiedSettingsService.getSetting(
      GUEST_GROUP,
      'guest_userId'
    );
    if (id === guestId) {
      UnifiedSettingsService.setSetting(GUEST_GROUP, 'guest_userId', null);
    }
    return authResult;
  }
}

function autoLogIn() {
  if (AppUrlService.get.oauth) {
    var oauth = AppUrlService.get.oauth;
    AppUrlService.get.oauth = undefined;
    return performLogin(oauth, 'oauth');
  } else {
    return Promise.resolve();
  }
}

function _validateOnLogin(loginInfo) {
  return Promise.resolve(!!loginInfo);
}

export default {
  loginByGmail,
  loginByFacebook,
  loginByEmail,
  skipLogin,
  performLogin,
  autoLogIn
};
