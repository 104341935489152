<template>
  <BaseButton
    elevation="0"
    :title="$t('Accessibility.backToBook.tooltip')"
    fab
    class="mini-navigate-icon"
    :class="!previousBookRoute ? 'disabled-icon' : ''"
    outlined
    :aria-label="$t('Accessibility.backToBook.tooltip')"
    @click="toLibrary"
  >
    <BaseSpriteIcon icon-name="ico-back-to-book"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';

export default {
  name: 'BackToBookControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    previousBookRoute() {
      const lastRecentBook = this.$store.getters[
        'RecentBookStore/getLastRecentBook'
      ];
      if (!lastRecentBook) {
        return null;
      }
      const publication = this.$store.getters[
        'LibraryStore/getPublicationById'
      ](lastRecentBook.publicationId);
      if (this.isOnline || publication.isContentDownloaded) {
        return publication?.slug || publication?.id;
      }
      return null;
    }
  },
  methods: {
    toLibrary() {
      if (this.previousBookRoute) {
        PublicationNavigateLogicService.openPublication(
          this.$store,
          this.$router,
          {
            slug: this.previousBookRoute
          }
        );
      } else {
        this.$store.dispatch('ManagePopupStore/openErrorToaster', {
          text: this.$t('BackToBook.toaster.message')
        });
      }
    }
  }
};
</script>
<style scoped lang="less">
.mini-navigate-icon {
  svg {
    fill: var(--black-color);
  }
}
</style>
