'use strict';

class PublicationVisibilityStatuses {
  constructor() {
    this.enum = {
      DEFAULT: 'Default',
      AVAILABLE: 'Available',
      NOT_AVAILABLE: 'NotAvailable',
      HIDDEN: 'Hidden',
      RESEARCH: 'Research',
      ENCUMBERED: 'Encumbered',
      NOT_IN_COLLECTION: 'NotInCollection'
    };

    // Don't remove, maybe this functional will be restored
    // this.DEFAULT = this.enum.DEFAULT;
    this.AVAILABLE = this.enum.AVAILABLE;
    this.NOT_AVAILABLE = this.enum.NOT_AVAILABLE;
    this.HIDDEN = this.enum.HIDDEN;
    this.RESEARCH = this.enum.RESEARCH;
    this.ENCUMBERED = this.enum.ENCUMBERED;
    this.NOT_IN_COLLECTION = this.enum.NOT_IN_COLLECTION;
  }

  getStatusesArray() {
    return [
      this.AVAILABLE,
      this.NOT_AVAILABLE,
      this.RESEARCH,
      this.ENCUMBERED,
      this.NOT_IN_COLLECTION
      // this.DEFAULT,
    ];
  }
  getLabelByStatus(publicationStatus, $t) {
    return this.getAllLabelsMap($t)[publicationStatus];
  }
  getAllLabelsMap($t = a => a) {
    const labels = {
      // [this.enum.DEFAULT]: $t('PublicationVisibilityStatus.default.label'),
      [this.enum.AVAILABLE]: $t('PublicationVisibilityStatus.available.label'),
      [this.enum.NOT_AVAILABLE]: $t(
        'PublicationVisibilityStatus.notAvailable.label'
      ),
      [this.enum.HIDDEN]: $t('PublicationVisibilityStatus.hidden.label'),
      [this.enum.RESEARCH]: $t('PublicationVisibilityStatus.research.label'),
      [this.enum.ENCUMBERED]: $t(
        'PublicationVisibilityStatus.encumbered.label'
      ),
      [this.enum.NOT_IN_COLLECTION]: $t(
        'PublicationVisibilityStatus.notInCollection.label'
      )
    };
    return labels;
  }
  getEnum() {
    return this.enum;
  }
  inEnum(status) {
    const statusArray = this.getStatusesArray();
    return statusArray.includes(status);
  }
}

module.exports = new PublicationVisibilityStatuses();
