<template>
  <BaseButton
    class="px-3"
    nuxt
    text
    :to="{ name: PRICING }"
    @click.native.prevent
  >
    <slot name="back-to-pricing">{{
      $t('ApplicationMenuItem.pricing.label')
    }}</slot>
  </BaseButton>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToPricingControl',
  components: {
    BaseButton
  },
  data() {
    return {
      PRICING: AppStateEnum.PRICING
    };
  }
};
</script>

<style lang="less" src="./ToPricingControl.less"></style>
