import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LoginStore.js');

import { localize as $t } from '@/i18n';
import AuthTypeEnum from '@/enums/AuthTypeEnum';
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import SubscriptionEventEnum from '@/enums/SubscriptionEventEnum';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';

import UserService from '@/services/UserService';
import QueryParamService from '@/services/QueryParamService';
import LocalStorageService from '@/services/LocalStorageService';
import LocalStorageUtils from '@shared/utils/LocalStorageUtils.mjs';

const initState = () => ({
  loginInfo: {
    email: '',
    password: ''
  },
  registrationInfo: {
    fullName: '',
    email: '',
    emailStored: '',
    password: ''
  },
  forgotEmail: '',
  needRouteChange: true,
  seoRedirectRoute: null
});

const storeGetters = {
  getLoginEmail(state) {
    return state.loginInfo.email;
  },
  getLoginPassword(state) {
    return state.loginInfo.password;
  },
  getRegistrationFullName(state) {
    return state.registrationInfo.fullName;
  },
  getRegistrationEmail(state) {
    return state.registrationInfo.email;
  },
  getRegistrationEmailStored(state) {
    return state.registrationInfo.emailStored;
  },
  getRegistrationPassword(state) {
    return state.registrationInfo.password;
  },
  getForgotEmail(state) {
    return state.forgotEmail;
  },
  needRouteChange(state) {
    return state.needRouteChange;
  },
  getContentMessage: (state, getters, rootState, rootGetters) => () => {
    const thermOfUseLink = rootGetters['ContextStore/getTermOfUseLink'];
    const privacyPolicyLink = rootGetters['ContextStore/getPrivacyPolicyLink'];
    return $t('App.Login.message')
      .replace(
        '<termsOfUse>',
        `<a
          href="${thermOfUseLink}"
          rel="noopener noreferrer"
          target="_blank"
          >Terms of Use</a>`
      )
      .replace(
        '<privacyPolicy>',
        `<a
          href="${privacyPolicyLink}"
          rel="noopener noreferrer"
          target="_blank"
          >Privacy Policy</a>`
      );
  },
  getSeoRedirectRoute(state) {
    return state.seoRedirectRoute;
  }
};

const actions = {
  cleanLoginInfo({ commit }) {
    commit('clearStoreSection', 'loginInfo');
  },
  cleanRegistrationInfo({ commit }) {
    commit('clearStoreSection', 'registrationInfo');
  },
  cleanForgotPasswordInfo({ commit }) {
    commit('clearStoreSection', 'forgotEmail');
  },
  async applyRegistrationData({ dispatch, commit, getters }) {
    const registrationInfo = {
      email: getters.getRegistrationEmail,
      name: getters.getRegistrationFullName,
      password: getters.getRegistrationPassword,
      confirm: true
    };
    await dispatch('PaymentsStore/saveUserPromoCode', null, { root: true });
    commit('setRegistrationEmailStored', registrationInfo.email);
    const response = await dispatch(
      'UserStore/register',
      {
        registrationInfo
      },
      { root: true }
    );
    return response;
  },
  loginByGoogle({ rootGetters, dispatch }) {
    const isDevice = rootGetters['ContextStore/isDevice'];

    if (isDevice) {
      dispatch('googlePluginAuth');
    } else {
      dispatch('googleLoginByRedirect');
    }
  },
  googlePluginAuth({ dispatch }) {
    const errorCodes = {
      12501: 'user cancelled'
    };
    window.plugins.googleplus.login(
      {},
      async res => {
        const loginInfo = {
          state: 'google',
          access_token: res.accessToken
        };

        const authResult = await dispatch(
          'UserStore/performOauthLogin',
          { loginInfo },
          { root: true }
        );
        await dispatch('handleAuthResult', authResult);
      },
      err => {
        if (errorCodes.hasOwnProperty(err)) {
          logger.warn(`Google+ : ${errorCodes[err]}`);
          return;
        }
        logger.fatal(`CANNOT LOGIN WITH Google+ : ${err}`);
        throw new Error(err);
      }
    );
  },
  googleLoginByRedirect({ dispatch }) {
    dispatch(
      'UserStore/loginOauth',
      {
        provider: AuthTypeEnum.GOOGLE
      },
      { root: true }
    );
  },
  loginByApple({ dispatch, rootGetters }) {
    const isDevice = rootGetters['ContextStore/isDevice'];
    if (isDevice) {
      dispatch('applePluginAuth');
    } else {
      dispatch('handleAppleWebSignIn');
    }
  },
  applePluginAuth({ dispatch }) {
    window.cordova.plugins.SignInWithApple.signin(
      { requestedScopes: [0, 1] },
      pluginInfo => {
        dispatch('appleSignIn', pluginInfo);
      },
      function(err) {
        const USER_CANCELED = ['1000', '1001', '1003'];
        if (USER_CANCELED.includes(err.code)) {
          logger.warn('User cancelled apple sign in');
        } else {
          logger.fatal(`CANNOT LOGIN WITH APPLE: ${JSON.stringify(err)}`);
        }
      }
    );
  },
  async appleSignIn({ dispatch }, pluginInfo) {
    const loginInfo = {
      state: 'apple',
      identityToken: pluginInfo.identityToken,
      name: `${pluginInfo.fullName.givenName} ${pluginInfo.fullName.familyName}`
    };

    const authResult = await dispatch(
      'UserStore/performOauthLogin',
      { loginInfo },
      { root: true }
    );
    await dispatch('handleAuthResult', authResult);
  },
  async handleAppleWebSignIn({ dispatch, rootGetters }) {
    const { authorization, user } = await window.AppleID.auth.signIn();
    const loginInfo = {
      state: 'apple',
      identityToken: authorization.id_token,
      isWeb: true
    };
    const signInState = rootGetters['UserStore/getAppleSignInState'];
    if (user) {
      loginInfo.name = `${user.name.firstName} ${user.name.lastName}`;
    }
    if (signInState !== authorization.state) {
      logger.warn(`Registered states are not equal for Sign in with Apple`);
      return;
    }
    const authResult = await dispatch(
      'UserStore/performOauthLogin',
      {
        loginInfo
      },
      { root: true }
    );
    const isLoginAllowed = await dispatch('handleAuthResult', authResult);
    if (!isLoginAllowed) {
      return;
    }
    if (
      rootGetters['ContextStore/isPurchaseEnabled'] &&
      authResult?.isRegistered
    ) {
      dispatch('PaymentsStore/applyPromoCode', authResult.isRegistered, {
        root: true
      });
    }
  },
  loginByFacebook({ dispatch, rootGetters }) {
    const isDevice = rootGetters['ContextStore/isDevice'];
    if (isDevice) {
      dispatch('facebookPluginAuth');
    } else {
      return dispatch(
        'UserStore/loginOauth',
        {
          provider: AuthTypeEnum.FACEBOOK
        },
        { root: true }
      );
    }
  },

  facebookPluginAuth({ dispatch }) {
    const fbErrorCodes = {
      4201: 'User cancelled dialog'
    };
    // eslint-disable-next-line no-undef
    facebookConnectPlugin.login(
      ['public_profile'],
      async userInfo => {
        const loginInfo = {
          state: 'facebook',
          access_token: userInfo.authResponse.accessToken
        };
        const authResult = await dispatch(
          'UserStore/performOauthLogin',
          { loginInfo },
          { root: true }
        );
        await dispatch('handleAuthResult', authResult);
      },
      err => {
        const errorCode = parseInt(err.errorCode || 0);
        if (errorCode && fbErrorCodes.hasOwnProperty(errorCode)) {
          logger.warn(`Facebook : ${fbErrorCodes[err]}`);
          return;
        } else if (typeof err === 'object' && err !== null) {
          err = JSON.stringify(err, null, 2);
        }
        logger.fatal(`CANNOT LOGIN WITH Facebook : ${err}`);
        throw new Error(err);
      }
    );
  },
  forgotPassword(context, { email }) {
    return UserService.resetPasswordTask(email);
  },
  async loginByEmail({ dispatch, getters, rootGetters }) {
    const loginInfo = {
      userName: getters.getLoginEmail,
      password: getters.getLoginPassword
    };
    const loginResult = await dispatch(
      'UserStore/loginByEmail',
      {
        loginInfo
      },
      { root: true }
    );

    const authResult = { status: loginResult };
    let isLoggedIn = false;
    if (authResult.status === LoginActionsEnum.LOGGED_IN) {
      isLoggedIn = await dispatch('handleAuthResult', authResult);
    }
    dispatch(
      'PaymentsStore/applyPromoCode',
      rootGetters['PaymentsStore/getPromoCode'],
      { root: true }
    );
    return isLoggedIn;
  },
  async handleAuthResult({ dispatch, getters }, authResult) {
    const status = authResult.status;
    const isLoginAllowed = await dispatch('processingAuthResult', status);
    const needRouteChange = getters.needRouteChange;
    if (status === LoginActionsEnum.LOGGED_IN && isLoginAllowed) {
      if (needRouteChange) {
        dispatch('routeAfterLogin', authResult);
      }
      dispatch('showCongratulationPopupIfNeed', {
        confirmResult: null,
        authResult
      });
      dispatch('onLogin');
    }
    return isLoginAllowed;
  },
  async onLogin({ commit, rootGetters, dispatch }) {
    commit('ProgressStore/resetStore', null, { root: true });
    commit('RecentBookStore/resetRecentBooks', null, { root: true });
    const userId = rootGetters['UserStore/getUserId'];
    const isPristine = rootGetters['SubscriptionStore/isPristine'];
    const popupContext = rootGetters['ManagePopupStore/getPopupContext'](
      PopupNamesEnum.CONNECT
    );
    if (typeof popupContext?.loginCallback === 'function') {
      await popupContext.loginCallback();
    }
    dispatch(
      'ManagePopupStore/closePopup',
      {
        name: PopupNamesEnum.CONNECT
      },
      {
        root: true
      }
    );
    if (isPristine) {
      return;
    }
    commit('SubscriptionStore/setUserId', userId, { root: true });
    dispatch(
      'SubscriptionStore/updateSubscribe',
      {
        type: SubscriptionEventEnum.SAVE_STATE,
        data: null
      },
      { root: true }
    );
  },
  async processingAuthResult({ dispatch, rootGetters }, authResult) {
    if (
      !rootGetters['ContextStore/isDevice'] ||
      !rootGetters['ContextStore/isPurchaseEnabled']
    ) {
      return true;
    }
    const isLoggedIn = authResult === LoginActionsEnum.LOGGED_IN;
    if (isLoggedIn) {
      await dispatch('UserStore/getUserProfile', null, { root: true });
      if (rootGetters['UserStore/isContentAvailable']) {
        return true;
      }
    }
    dispatch(
      'ManagePopupStore/openErrorToaster',
      {
        text: $t('App.Login.login.error')
      },
      { root: true }
    );
    dispatch('UserStore/logout', { root: true });
    return false;
  },
  async routeAfterLogin({ dispatch, getters, rootGetters }, authResult) {
    const exclude = ['paraId'];
    const query = QueryParamService.getAllParams(this.$router) || {};
    exclude.forEach(paramName => {
      delete query[paramName];
    });
    const removeHash = true;
    QueryParamService.removeParams(
      this.$router,
      Object.keys(query),
      removeHash
    );
    if (authResult?.isNewUser) {
      await dispatch('fetchSeoRedirectRoute');
    }
    const seoRedirectRoute = getters.getSeoRedirectRoute;
    const isDevice = rootGetters['ContextStore/isDevice'];
    if (isDevice) {
      if (seoRedirectRoute && authResult.isNewUser) {
        this.$router.push({ name: seoRedirectRoute });
      }
    }
    dispatch(
      'ManagePopupStore/closePopup',
      {
        name: PopupNamesEnum.CONNECT
      },
      { root: true }
    );
    if (seoRedirectRoute && authResult.isNewUser) {
      this.$router.push({ name: seoRedirectRoute });
    }
  },
  showCongratulationPopupIfNeed(
    { rootGetters, dispatch },
    { confirmResult, authResult, hashCode }
  ) {
    const isRegistered =
      authResult?.isRegistered ||
      confirmResult?.data?.taskType === 'RegisterUserProfile';
    const closePopupCallback = () => {
      dispatch('tryShowSegmentationPopup');
    };

    if (rootGetters['ContextStore/isPurchaseEnabled'] && isRegistered) {
      dispatch('PaymentsStore/applyPromoCode', isRegistered, { root: true });
    } else if (confirmResult?.data && !authResult?.status) {
      dispatch(
        'ManagePopupStore/openPopup',
        {
          name: PopupNamesEnum.CONFIRMATION_TASK_POPUP,
          popupContext: { ...confirmResult, closePopupCallback, hashCode }
        },
        { root: true }
      );
    } else {
      dispatch('tryShowSegmentationPopup');
    }
  },
  async tryShowSegmentationPopup({ dispatch, rootGetters }) {
    await dispatch('UserStore/fetchUserSegmentationData', null, { root: true });
    const isLoggedIn = rootGetters['UserStore/isLoggedIn'];
    const brand = rootGetters['ContextStore/brand'];
    const userData = rootGetters['UserStore/getUserSegmentationData'];
    const popupClosedInSession = LocalStorageService.get('popupClosed');
    const popupsOpened = rootGetters['ManagePopupStore/getOpenedPopups'].length;
    const shouldShowSegmentationPopup =
      !userData ||
      !userData?.ageRange ||
      userData?.ageRange.length < 2 ||
      !userData?.language;

    const redirectAfterLogin = () => {
      const redirectUrl = LocalStorageService.get('redirectAfterLogin');
      if (!redirectUrl) {
        return;
      }
      LocalStorageUtils.removeStorageKey('redirectAfterLogin');
      dispatch(
        'ContextStore/openUrl',
        { url: redirectUrl, openTarget: '_self' },
        { root: true }
      );
    };
    if (
      brand === BrandsEnum.FFA &&
      isLoggedIn &&
      !popupClosedInSession &&
      !popupsOpened &&
      shouldShowSegmentationPopup
    ) {
      dispatch(
        'ManagePopupStore/openPopup',
        {
          name: PopupNamesEnum.USER_SEGMENTATION_POPUP,
          popupContext: {
            closePopupCallback: redirectAfterLogin
          }
        },
        { root: true }
      );
    } else if (isLoggedIn) {
      redirectAfterLogin();
    }
  },
  fetchSeoRedirectRoute({ commit }) {
    if (process.client) {
      const routeName = LocalStorageService.get('seoRedirectRoute') || null;
      commit('setSeoRedirectRoute', routeName);
    }
  }
};

const mutations = {
  clearStore(state) {
    const newState = initState();
    Object.keys(newState).forEach(key => {
      const val = newState[key];
      if (typeof val === 'object' && val !== null) {
        Object.keys(newState[key]).forEach(objKey => {
          const objVal = val[objKey];
          state[key][objKey] = objVal;
        });
        return;
      }
      state[key] = val;
    });
  },
  clearStoreSection(state, sectionName) {
    const newState = initState();
    const val = newState[sectionName];
    if (!val) {
      return;
    }
    if (typeof val === 'object' && val !== null) {
      Object.keys(newState[sectionName]).forEach(objKey => {
        const objVal = val[objKey];
        state[sectionName][objKey] = objVal;
      });
      return;
    }
    state[sectionName] = val;
  },
  setLoginEmail(state, email) {
    state.loginInfo.email = email;
  },
  setLoginPassword(state, password) {
    state.loginInfo.password = password;
  },
  setRegistrationFullName(state, email) {
    state.registrationInfo.fullName = email;
  },
  setRegistrationEmail(state, email) {
    state.registrationInfo.email = email;
  },
  setRegistrationEmailStored(state, email) {
    state.registrationInfo.emailStored = email;
  },
  setRegistrationPassword(state, password) {
    state.registrationInfo.password = password;
  },
  setForgotEmail(state, email) {
    state.forgotEmail = email;
  },
  setNeedRouteChange(state, needRouteChange) {
    state.needRouteChange = needRouteChange;
  },
  setSeoRedirectRoute(state, routeName) {
    state.seoRedirectRoute = routeName;
    if (process.client) {
      if (routeName) {
        LocalStorageService.set('seoRedirectRoute', routeName);
      } else {
        LocalStorageUtils.removeStorageKey('seoRedirectRoute');
      }
    }
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
