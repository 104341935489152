function normalizeAlignment(arraybuffer, frameSize) {
  const delta = arraybuffer.byteLength % frameSize;
  return arraybuffer.slice(0, arraybuffer.byteLength - delta);
}

function parseRawIndex(arraybuffer) {
  let dataView = new DataView(arraybuffer);

  const FRAME_SIZE = 14;

  let paraId;
  let wordPosition;
  let wordLength;
  let audioStart;
  let audioEnd;
  const alignment = {};

  let para;

  if (arraybuffer.byteLength % FRAME_SIZE) {
    // eslint-disable-next-line no-console
    console.warn(
      `Get invalid audio index buffer size ${arraybuffer.byteLength} to FRAME_SIZE ${FRAME_SIZE}. Remove last frame.`
    );
    arraybuffer = normalizeAlignment(arraybuffer, FRAME_SIZE);
    dataView = new DataView(arraybuffer);
  }

  for (
    let currentPosition = 0, l = arraybuffer.byteLength;
    currentPosition < l;
    currentPosition += FRAME_SIZE
  ) {
    paraId = dataView.getInt16(currentPosition);
    wordPosition = dataView.getInt16(currentPosition + 2);
    wordLength = dataView.getInt16(currentPosition + 4);
    audioStart = dataView.getInt32(currentPosition + 6);
    audioEnd = dataView.getInt32(currentPosition + 10);

    para = 'para_' + paraId;
    if (!alignment.hasOwnProperty(para)) {
      alignment[para] = [[], []];
    }

    alignment[para][0].push([audioStart, audioEnd]);
    alignment[para][1].push([paraId, wordPosition, wordLength].join('.'));
  }
  return alignment;
}

export default parseRawIndex;
